import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../authContext';
import { getContacts } from '../../libs/services/contacts';
import { Contact } from '@book-nestor-monorepo/shared-types';

interface ContactsContextType {
  isLoading: boolean;
  contacts: Contact[];
  fetchContacts: (forceRefresh?: boolean) => Promise<Contact[]>;
}

interface ContactsProviderProps {
  children: ReactNode;
}

const ContactsContext = createContext<ContactsContextType | undefined>(undefined);

export const useContacts = (): ContactsContextType => {
  const context = useContext(ContactsContext);

  if (!context) {
    throw new Error('useContacts must be used within a ContactsProvider');
  }

  return context;
};

export const ContactsProvider: FunctionComponent<ContactsProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const contactsFetched = useRef<boolean>(false);

  const handleFetchContacts = useCallback(
    async (forceRefresh = false): Promise<Contact[]> => {
      if (contactsFetched.current && !forceRefresh) {
        return contacts;
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getContacts(user.id as string, {});
        setContacts(result);
        contactsFetched.current = true;
        return result;
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [contacts, authContext.user],
  );

  useEffect(() => {
    handleFetchContacts();
  }, [handleFetchContacts]);

  return (
    <ContactsContext.Provider
      value={{
        isLoading,
        contacts,
        fetchContacts: handleFetchContacts,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
