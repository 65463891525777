import { Skeleton, Stack } from '@mui/material';

export const MultiPartSkeleton = ({ color = '#333333' }) => {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem', borderRadius: '10px', bgcolor: color }} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} sx={{ bgcolor: color }} />
      <Skeleton
        variant="rounded"
        width={210}
        height={60}
        sx={{ borderRadius: '10px', bgcolor: color }}
      />
      <Skeleton
        variant="rounded"
        width={210}
        height={60}
        sx={{ borderRadius: '10px', bgcolor: color }}
      />
    </Stack>
  );
};
