import { Booking, BookingStatus } from '@book-nestor-monorepo/shared-types';
import { useCancelBooking } from '@calcom/atoms';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactBookingPaymentAction } from '../../components/v2/Contact/ContactBookingPaymentAction';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { getBookings } from '../../libs/services/bookings';
import { durationString } from '../../libs/utils/date.util';
import { truncateText } from '../../libs/utils/string.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

export const BookingsPageV2 = () => {
  const [selectedStatus, setSelectedStatus] = useState<TSelectedStatus>(BookingStatus.UPCOMING);
  const [bookings, setBookings] = useState<Record<TSelectedStatus, Booking[]> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleStatusChange = (status: TSelectedStatus) => {
    setSelectedStatus(status);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id) {
          const [tokenUpcomingResponse, tokenPastResponse, tokenCancelledResponse] =
            await Promise.all([
              getBookings(user?.id, BookingStatus.UPCOMING),
              getBookings(user?.id, BookingStatus.PAST),
              getBookings(user?.id, BookingStatus.CANCELED),
            ]);

          setBookings({
            [BookingStatus.UPCOMING]: tokenUpcomingResponse,
            [BookingStatus.PAST]: tokenPastResponse,
            [BookingStatus.CANCELED]: tokenCancelledResponse,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  const { mutate: cancelBooking } = useCancelBooking({
    onSuccess: async () => {
      const user = authContext.user;
      if (!user) return;
      if (user?.id) {
        const [tokenUpcomingResponse, tokenPastResponse, tokenCancelledResponse] =
          await Promise.all([
            getBookings(user?.id, BookingStatus.UPCOMING),
            getBookings(user?.id, BookingStatus.PAST),
            getBookings(user?.id, BookingStatus.CANCELED),
          ]);
        setBookings({
          [BookingStatus.UPCOMING]: tokenUpcomingResponse,
          [BookingStatus.PAST]: tokenPastResponse,
          [BookingStatus.CANCELED]: tokenCancelledResponse,
        });
      }
    },
  });

  const cancelBookingClick = async (booking: Booking) => {
    cancelBooking({
      id: booking.external_id,
      uid: booking.id,
      cancellationReason: 'User request',
      allRemainingBookings: true,
    });
  };

  const openBookingPaymentModal = (booking: Booking) => {
    openMuiModal(
      <ContactBookingPaymentAction
        booking={booking}
        contact={booking.contact}
        onClose={closeMuiModal}
        // onCreateStripeCustomer={async () => {
        //   await createStripeCustomer(authContext.user?.id as string, booking.contact?.id as string);
        //   return booking.contact as Contact;
        // }}
      />,
    );
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#FFFFFF" />;
  }

  return (
    <div className={`flex flex-col h-full bg-[#D1DEDD] w-full`}>
      <div className="flex h-full w-full">
        <div className="flex-1 flex flex-col h-full pt-2 ml-0">
          <div className="flex-col ">
            <div className="bg-transparent w-full h-full ">
              <h1 className="text-black-alt text-left pb-4 pl-4 font-avenir text-[16px]">
                Your booking history
              </h1>
              <div className="flex overflow-x-auto mb-8 hide-scrollbar px-2">
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.UPCOMING
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.UPCOMING)}
                >
                  Upcoming
                  <span className="ml-[10px]">{bookings?.upcoming.length}</span>
                </button>
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.PAST
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.PAST)}
                >
                  Past
                  <span className="ml-[10px]">{bookings?.past.length}</span>
                </button>
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.CANCELED
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.CANCELED)}
                >
                  Canceled
                  <span className="ml-[10px]">{bookings?.cancelled.length}</span>
                </button>
              </div>
              <div className="overflow-y-auto w-full h-full bg-transparent  hide-scrollbar pt-0 px-1 pb-4">
                {bookings?.[selectedStatus]?.length === 0 ? (
                  <div className="text-center text-black">
                    No {selectedStatus.toLowerCase()} bookings. As soon as you have a{' '}
                    {selectedStatus.toLowerCase()} meeting, it will show up here.
                  </div>
                ) : (
                  bookings?.[selectedStatus]?.map((booking) => (
                    <div
                      key={booking.id}
                      className="flex border-b pb-6 bg-white justify-between items-end mt-1 first:mt-0 min-h-[130px] p-4 rounded-3xl"
                    >
                      <div className="w-auto">
                        <h3 className="text-black-alt font-bold font-inter text-base flex items-center gap-1">
                          <span className="text-ellipsis overflow-hidden truncate text-nowrap font-avenir text-[16px]">
                            {/* {formatBookingTitle(booking.title)} */}
                            {truncateText(
                              booking.attendees.map((attendee) => attendee.name).join(', '),
                              30,
                            )}
                          </span>
                        </h3>
                        <h3 className="text-black-alt  font-avenir flex text-[14px] items-center gap-1">
                          {/* <span className="text-nowrap">{durationString(booking)}</span> */}
                          {new Date(booking.start_time).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          })}{' '}
                        </h3>
                        <div className="flex gap-2">
                          <div className="h-10 rounded-full w-max bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir mt-[26px] cursor-pointer capitalize">
                            {booking.event_type.slug}
                          </div>
                          <div className="h-10 rounded-full w-max bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir mt-[26px] cursor-pointer">
                            {durationString(booking)}
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <p className="text-[#00000080] text-[14px] flex font-avenir text-black-alt ">
                          {new Date(booking.start_time).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                          })}{' '}
                          -{' '}
                          {new Date(booking.end_time).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                          })}
                        </p>
                        <div className="flex items-center gap-2 mt-[26px] justify-end">
                          <div
                            className="h-12 w-12 rounded-full bg-[#D1DEDD] flex items-center justify-center cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/booking-control/${booking.contact?.id}?bookingId=${booking.id}`,
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              color="#000"
                              className="text-[12px] -rotate-45"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
