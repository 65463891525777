import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import HomeSchedule from './home-schedule';

export const HomeV3 = () => {
  const { bgColor } = useAppExperience();

  return (
    <div className={`flex flex-col h-auto w-full bg-[${bgColor}] overflow-hidden`}>
      <div className="flex flex-col h-full w-full">
        <main className="flex-1 overflow-hidden flex justify-center w-full">
          <div className="w-full  flex justify-center">
            <div className="w-full bg-transparent  rounded-2xl  h-full flex flex-col ">
              <div className=" flex-col w-full h-full ">
                <HomeSchedule />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
