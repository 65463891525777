import {
  OnboardingTaskNames,
  Organization,
  SubscriptionPlan,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { SaveButton } from '../components/formLibrary/formSaveButton';
import PrimaryTextField from '../components/formLibrary/primaryTextInput';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { environment } from '../environments/environment';
import { getOrganization, upsertOrganization } from '../libs/services/organization.service';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';
import * as Sentry from '@sentry/react';
import { useQuickStart } from '../contexts/quickStartContext';
import { hasAccess } from '../libs/utils/hasAccess';

export default function BusinessProfile() {
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);
  const { markTaskComplete } = useQuickStart();
  const logoFileInputRef = useRef<any>(null);
  const galleryInputRef = useRef<any>(null);
  const [previewSrc, setPreviewSrc] = useState<any>('');
  const [logoFile, setLogoFile] = useState<any>(undefined);
  const [galleryFiles, setGalleryFiles] = useState<any[]>([]);

  const authContext = useContext(AuthContext);

  const userUploadPhotoLimit = () => {
    switch (authContext.user?.subscription) {
      case SubscriptionPlan.PRO:
        return 8;
      case SubscriptionPlan.STUDIO:
        return 12;
      default:
        return 4;
    }
  };

  const userCanUseBusinessBranding = (): boolean => {
    const token = authContext.token;
    if (!token) return true;

    return hasAccess(token, USER_PERMISSIONS.CAN_USE_BUSINESS_BRANDING);
  };

  const brandingDescription = () => {
    if (!userCanUseBusinessBranding()) {
      return (
        <p className="text-left font-normal text-[10px] text-black font-inter ">
          Upgrade your plan to hide Nestor Branding on your booking page.
        </p>
      );
    }
    return (
      <p className="text-left font-normal text-[10px] text-black font-inter ">
        All Nestor Branding on your booking page will be hidden.
      </p>
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = authContext.user;
      if (!user) return;

      const org = await getOrganization();
      setOrganization(org);

      if (org) {
        formik.setValues(org);

        if (org.logo_image_key) {
          setPreviewSrc(`${environment.s3.bucketUrl}/${org.logo_image_key}`);
        }

        if (org.gallery_image_keys && org.gallery_image_keys.length > 0) {
          const galleryPreviews = org.gallery_image_keys.map(
            (key: string) => `${environment.s3.bucketUrl}/${key}`,
          );
          setGalleryFiles(galleryPreviews);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user]);

  const deleteUploadedImage = (idx: number) => {
    const newGalleries = [...galleryFiles];
    newGalleries.splice(idx, 1);
    setGalleryFiles(newGalleries);
  };

  const handleLogoFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setLogoFile(file);

    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleGalleryFilesChange = (event: any) => {
    const files = Array.from(event.target.files);

    setGalleryFiles([...galleryFiles, ...files]);
  };

  const userHasReachedGalleryLimit = (): boolean => {
    return galleryFiles.length >= userUploadPhotoLimit();
  };

  const userHasReachedMaxGalleryLimit = (): boolean => {
    return galleryFiles.length >= 12;
  };

  const handleLogoFileButtonClick = () => {
    logoFileInputRef?.current?.click();
  };

  const handleGalleryFilesButtonClick = () => {
    if (userHasReachedGalleryLimit()) {
      return;
    }
    galleryInputRef?.current?.click();
  };

  const initialValues: Organization = {
    name: '',
    mailing_address: {
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
    },
    business_type: '',
    spoken_languages: '',
    description: '',
    phone_number: '',
    website_url: '',
    facebook_handle: '',
    instagram_handle: '',
    tiktok_handle: '',
    settings: {
      booking_page: {
        hide_branding: false,
      },
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      mailing_address: Yup.object({
        addressLine1: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
      business_type: Yup.string(),
      spoken_languages: Yup.string(),
      description: Yup.string(),
      phone_number: Yup.string().nullable(),
      website_url: Yup.string().nullable(),
      facebook_handle: Yup.string(),
      instagram_handle: Yup.string(),
      tiktok_handle: Yup.string(),
      settings: Yup.object({
        booking_page: Yup.object({
          hide_branding: Yup.boolean(),
        }),
      }),
    }),
    onSubmit: async (values) => {
      await upsertOrganization(values, logoFile, galleryFiles);
      setLogoFile(null);
    },
  });

  return (
    <ExperimentalAppLayout insightCardsStyles="hidden md:flex" bookings>
      {isLoading ? (
        <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-4 pt-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 70,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="w-full px-2 bg-[rgba(0,0,0,0.8)] h-full overflow-y-auto overflow-x-hidden md:pt-[28px]">
          <div className="flex flex-row items-center gap-4">
            <h1 className="text-[68px] leading-[72px] text-gray-btn pb-4 font-avenir mt-12">
              Business
              <br />
              Profile
            </h1>
            <div className="flex flex-col flex-1 items-end mt-auto pb-8"></div>
          </div>
          <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] px-2 pt-2">
            <div className="flex flex-col h-full md:pt-2">
              <div className="flex flex-col items-center text-center bg-white rounded-3xl h-full">
                <form
                  onSubmit={formik.handleSubmit}
                  className="w-full flex justify-center items-center flex-col md:max-w-[350px] mb-4"
                >
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={logoFileInputRef}
                    onChange={handleLogoFileChange}
                    accept="image/jpeg,image/png,image/heic"
                  />
                  <div
                    onClick={handleLogoFileButtonClick}
                    className={`relative h-[296px] w-full rounded-3xl flex justify-center items-center cursor-pointer ${
                      previewSrc ? 'bg-transparent' : 'bg-black-alt'
                    }`}
                  >
                    {!previewSrc ? (
                      <div className="relative w-full h-full flex items-center justify-center pl-11">
                        <div className="w-1/2 flex items-center justify-between">
                          <p className="text-[14px] text-white">Primary Image</p>
                          <svg
                            width="34"
                            height="30"
                            viewBox="0 0 42 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M38.0728 0.964375C40.2 0.964375 42 2.76434 42 4.89156V33.6909C42 35.9 40.2 37.6181 38.0728 37.6181H4.03719C1.82815 37.6181 0.110001 35.9 0.110001 33.6909V4.89156C0.110001 2.76434 1.82815 0.964375 4.03719 0.964375H38.0728ZM4.03719 36.3091H8.45527L17.4551 27.3093L10.9916 20.8458C10.7461 20.6003 10.3371 20.6003 10.0916 20.8458L1.41906 29.5183V33.6909C1.41906 35.1636 2.56449 36.3091 4.03719 36.3091ZM40.6909 33.6909V24.2821L31.9366 15.6095C31.6911 15.3641 31.2821 15.3641 31.0366 15.6095L10.2552 36.3091H38.0728C39.4637 36.3091 40.6909 35.1636 40.6909 33.6909ZM40.6909 22.4821V4.89156C40.6909 3.50068 39.4637 2.27344 38.0728 2.27344H4.03719C2.56449 2.27344 1.41906 3.50068 1.41906 4.89156V27.7183L9.10981 19.864C9.84615 19.1276 11.0734 19.1276 11.8916 19.864L18.4369 26.4093L30.1366 14.7095C30.873 13.8914 32.1002 13.8914 32.8366 14.7095L40.6909 22.4821ZM10.5825 6.85516C13.037 6.85516 15.1642 8.98238 15.1642 11.4369C15.1642 13.9732 13.037 16.0186 10.5825 16.0186C8.04619 16.0186 6.00078 13.9732 6.00078 11.4369C6.00078 8.98238 8.04619 6.85516 10.5825 6.85516ZM10.5825 14.7095C12.3825 14.7095 13.8552 13.3187 13.8552 11.4369C13.8552 9.63691 12.3825 8.16422 10.5825 8.16422C8.70072 8.16422 7.30984 9.63691 7.30984 11.4369C7.30984 13.3187 8.70072 14.7095 10.5825 14.7095Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={previewSrc}
                        alt="Preview"
                        className="h-full w-full object-cover rounded-3xl"
                      />
                    )}
                    <div className="w-8 h-8 absolute bottom-4 right-4 rounded-full flex items-center justify-center bg-[#FFFFFF33]">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <p className="text-xs text-gray-500">Landscape image for best results</p>
                  <div className="w-full  font-avenir flex flex-col gap-x-4 gap-y-4 pt-8">
                    <div className="w-full inline-flex flex-row h-[48px] rounded-full items-center grow">
                      <PrimaryTextField
                        label="Company Name"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="name"
                        fullWidth={true}
                        required={true}
                        value={formik.values.name}
                      />
                    </div>
                    <div className="flex flex-col gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="Address"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="mailing_address.addressLine1"
                        fullWidth={true}
                        required={true}
                        value={formik.values.mailing_address?.addressLine1}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="City"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="mailing_address.city"
                        required={true}
                        value={formik.values.mailing_address?.city}
                      />
                      <PrimaryTextField
                        label="State"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="mailing_address.state"
                        required={true}
                        value={formik.values.mailing_address?.state}
                      />
                      <PrimaryTextField
                        label="Zip"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="mailing_address.zipCode"
                        required={true}
                        value={formik.values.mailing_address?.zipCode}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="Business Type"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="business_type"
                        value={formik.values.business_type}
                      />
                      <PrimaryTextField
                        label="Languages"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="spoken_languages"
                        value={formik.values.spoken_languages}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="Website"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="website_url"
                        value={formik.values.website_url}
                      />
                      <PrimaryTextField
                        label="Phone Number"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="phone_number"
                        value={formik.values.phone_number}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="Description"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        fullWidth={true}
                        multiline={true}
                        name="description"
                        value={formik.values.description}
                      />
                    </div>

                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="@Facebook"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        name="facebook_handle"
                        fullWidth={true}
                        value={formik.values.facebook_handle}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="@Instagram"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        fullWidth={true}
                        name="instagram_handle"
                        value={formik.values.instagram_handle}
                      />
                    </div>
                    <div className="flex flex-row gap-x-4 gap-y-4">
                      <PrimaryTextField
                        label="@TikTok"
                        color="primary"
                        onChange={formik.handleChange}
                        type="text"
                        fullWidth={true}
                        name="tiktok_handle"
                        value={formik.values.tiktok_handle}
                      />
                    </div>

                    <h4 className="text-[16px] font-avenir font-bold text-black-text text-left mt-4">
                      Hide Nestor Branding
                      <p className="text-left font-normal text-[10px] text-black font-inter ">
                        {brandingDescription()}
                      </p>
                    </h4>

                    <div
                      className={`flex w-[105px] h-10 rounded-full bg-[#EBEBEB] p-[2px] mb-4 ${userCanUseBusinessBranding() ? '' : 'opacity-60 cursor-not-allowed'}`}
                    >
                      <div className="h-full w-[67px] min-w-[67px] rounded-full bg-black flex items-center justify-center">
                        <p
                          className={`text-[12px] font-avenir font-medium ${formik.values.settings?.booking_page?.hide_branding ? 'text-book-green' : 'text-red-notification'}`}
                        >
                          {formik.values.settings?.booking_page?.hide_branding
                            ? 'Hidden'
                            : 'Visible'}
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (!userCanUseBusinessBranding()) return;
                          formik.setFieldValue(
                            'settings.booking_page.hide_branding',
                            !formik.values.settings?.booking_page?.hide_branding,
                          );
                        }}
                        className="w-full flex items-center justify-center"
                      >
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                            fill="#303030"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="flex flex-col gap-y-2">
                      <h5 className="font-inter font-medium text-left ">Gallery</h5>

                      <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={galleryInputRef}
                        onChange={handleGalleryFilesChange}
                        accept="image/jpeg,image/png,image/heic"
                        multiple
                      />
                      <div className="grid grid-cols-2 gap-2 mt-5">
                        {galleryFiles.map((file, index) => (
                          <div
                            key={index}
                            className={`relative w-full h-[133px] rounded-[16px] bg-black-alt overflow-hidden ${index >= userUploadPhotoLimit() ? 'opacity-40 cursor-not-allowed' : ''}`}
                          >
                            <img
                              src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                              alt={`Gallery Preview ${index + 1}`}
                              className="w-full h-full object-cover"
                            />
                            {index < userUploadPhotoLimit() && (
                              <div className="absolute flex flex-col gap-[6px] right-2 top-2">
                                <button
                                  type="button"
                                  onClick={() => deleteUploadedImage(index)}
                                  className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-sm flex items-center justify-center"
                                >
                                  <svg
                                    width="9"
                                    height="11"
                                    viewBox="0 0 9 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.53125 1.8125C8.78516 1.8125 9 2.02734 9 2.28125C9 2.55469 8.78516 2.75 8.53125 2.75H8.29688L7.82812 9.09766C7.76953 9.76172 7.24219 10.25 6.57812 10.25H2.65234C1.98828 10.25 1.46094 9.76172 1.40234 9.09766L0.933594 2.75H0.71875C0.445312 2.75 0.25 2.55469 0.25 2.28125C0.25 2.02734 0.445312 1.8125 0.71875 1.8125H2.06641L2.78906 0.738281C2.98438 0.445312 3.33594 0.25 3.70703 0.25H5.52344C5.89453 0.25 6.24609 0.445312 6.44141 0.738281L7.16406 1.8125H8.53125ZM3.70703 1.1875C3.64844 1.1875 3.58984 1.22656 3.57031 1.26562L3.19922 1.8125H6.03125L5.66016 1.26562C5.64062 1.22656 5.58203 1.1875 5.52344 1.1875H3.70703ZM7.35938 2.75H1.87109L2.33984 9.03906C2.35938 9.19531 2.49609 9.3125 2.65234 9.3125H6.57812C6.73438 9.3125 6.87109 9.19531 6.89062 9.03906L7.35938 2.75Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-md flex items-center justify-center"
                                >
                                  <svg
                                    width="14"
                                    height="11"
                                    viewBox="0 0 14 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.42969 2.06641C4.30859 1.40234 5.40234 0.875 6.75 0.875C8.3125 0.875 9.58203 1.59766 10.5 2.45703C11.418 3.29688 12.0234 4.3125 12.3164 5.01562C12.375 5.17188 12.375 5.34766 12.3164 5.50391C12.0625 6.12891 11.5352 7.02734 10.7539 7.80859L12.8047 9.42969C13.0195 9.58594 13.0586 9.87891 12.8828 10.0742C12.7266 10.2891 12.4336 10.3281 12.2383 10.1523L0.675781 1.08984C0.460938 0.933594 0.421875 0.640625 0.597656 0.445312C0.753906 0.230469 1.04688 0.191406 1.24219 0.367188L3.42969 2.06641ZM4.19141 2.67188L5.08984 3.375C5.53906 2.98438 6.10547 2.75 6.75 2.75C8.11719 2.75 9.25 3.88281 9.25 5.25C9.25 5.67969 9.13281 6.07031 8.95703 6.40234L10.0117 7.22266C10.6758 6.55859 11.1445 5.79688 11.3984 5.25C11.125 4.66406 10.6172 3.84375 9.85547 3.14062C9.05469 2.39844 8.01953 1.8125 6.75 1.8125C5.75391 1.8125 4.89453 2.16406 4.19141 2.67188ZM8.19531 5.81641C8.27344 5.64062 8.3125 5.44531 8.3125 5.25C8.3125 4.39062 7.60938 3.6875 6.75 3.6875C6.73047 3.6875 6.71094 3.6875 6.69141 3.6875C6.73047 3.80469 6.75 3.90234 6.75 4C6.75 4.21484 6.69141 4.39062 6.61328 4.56641L8.19531 5.81641ZM8.39062 8.35547L9.21094 9C8.48828 9.39062 7.66797 9.625 6.75 9.625C5.16797 9.625 3.89844 8.92188 2.98047 8.0625C2.0625 7.20312 1.45703 6.1875 1.16406 5.50391C1.10547 5.34766 1.10547 5.17188 1.16406 5.01562C1.35938 4.56641 1.67188 4 2.12109 3.41406L2.84375 4C2.49219 4.44922 2.23828 4.89844 2.08203 5.25C2.35547 5.83594 2.86328 6.67578 3.625 7.37891C4.42578 8.12109 5.46094 8.6875 6.75 8.6875C7.33594 8.6875 7.88281 8.57031 8.39062 8.35547ZM4.25 5.25C4.25 5.21094 4.25 5.15234 4.25 5.09375L5.34375 5.95312C5.53906 6.36328 5.92969 6.67578 6.39844 6.77344L7.49219 7.65234C7.25781 7.71094 7.00391 7.75 6.73047 7.75C5.36328 7.75 4.23047 6.63672 4.23047 5.25H4.25Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        {!userHasReachedMaxGalleryLimit() && (
                          <button
                            type="button"
                            onClick={handleGalleryFilesButtonClick}
                            className={`relative w-full h-[133px] rounded-[16px] bg-black-alt ${userHasReachedGalleryLimit() ? 'opacity-60 cursor-not-allowed' : ''}`}
                          >
                            <div className="pt-[35px] pl-[46px] ">
                              <svg
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.6875 4.25C8.6875 4.52344 8.47266 4.71875 8.21875 4.71875H5.09375V7.84375C5.09375 8.11719 4.87891 8.33203 4.625 8.33203C4.35156 8.33203 4.15625 8.11719 4.15625 7.84375V4.71875H1.03125C0.757812 4.71875 0.5625 4.52344 0.5625 4.26953C0.5625 3.99609 0.757812 3.78125 1.03125 3.78125H4.15625V0.65625C4.15625 0.402344 4.35156 0.207031 4.625 0.207031C4.87891 0.207031 5.09375 0.402344 5.09375 0.65625V3.78125H8.21875C8.47266 3.78125 8.6875 3.99609 8.6875 4.25Z"
                                  fill="#ECECEC"
                                />
                              </svg>
                              <h6 className="text-gray-light font-avenir mt-[28px] text-left">
                                {userHasReachedGalleryLimit()
                                  ? 'Upgrade to add more images'
                                  : 'Add Image'}
                              </h6>
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start justify-start gap-2 pb-4 pt-6 w-full">
                    <div className="flex-grow flex-row w-full ">
                      <SaveButton
                        onClick={async () => {
                          if (formik.isValid) {
                            await formik.submitForm();
                            await markTaskComplete(OnboardingTaskNames.updateBusinessInfo);
                            if (logoFile || galleryFiles?.length > 0) {
                              await markTaskComplete(OnboardingTaskNames.addFirstBusinessImage);
                            }
                          }
                        }}
                        disabled={!formik.isValid}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
