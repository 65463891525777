import { Contact, ContactBooking, ContactBookingMethod } from './contact';
import { EventType } from './event-type';
import { User } from './user';

export interface Booking {
  id: string;
  external_id: number;
  user: User;
  title: string;
  description: string;
  event_type: Partial<EventType>;
  paid: boolean;
  start_time: string;
  end_time: string;
  attendees: BookingAttendee[];
  contact: Contact;
  contact_booking?: ContactBooking;
}

export enum BookingStatus {
  UPCOMING = 'upcoming',
  UNCONFIRMED = 'unconfirmed',
  RECURRING = 'recurring',
  PAST = 'past',
  CANCELED = 'cancelled',
}

export interface BookingWebhook {
  booking_id: string;
  user_id: string;
  rescheduleUid?: string | null;
  is_cancelled?: boolean;
  booking_method?: ContactBookingMethod;
}

export interface BookingAttendee {
  name: string;
  email: string;
}

export interface Schedule {
  working_hours: WorkingHours[];
  time_zone: string;
}

export interface WorkingHours {
  days: number[];
  startTime: number;
  endTime: number;
}
