import React, { useEffect, useReducer } from 'react';
import { useIsMobile } from './isMobile';

interface SwipeContainerProps {
  children: React.ReactNode;
  defaultHeight: number;
  maxHeight?: number;
  animationDuration?: number;
  isOpened?: boolean;
  transparentBg?: boolean;
}

const swipeReducer = (state: { isOpen: boolean; y: number }, action: any) => {
  switch (action.type) {
    case 'START':
      return { ...state, y: action.payload.y };
    case 'MOVE':
      return { ...state, y: action.payload.y };
    case 'OPEN':
      return { isOpen: true, y: 0 };
    case 'CLOSE':
      return { isOpen: false, y: 0 };
    default:
      return state;
  }
};

const SwipeContainer: React.FC<SwipeContainerProps> = ({
  children,
  defaultHeight,
  transparentBg,
  animationDuration = 300,
  isOpened = false,
}) => {
  const [state, dispatch] = useReducer(swipeReducer, { isOpen: false, y: 0 });
  const isMobile = useIsMobile(); // Use the hook if imported

  useEffect(() => {
    if (!isMobile) {
      dispatch({ type: 'CLOSE' });
    }

    if (isOpened) {
      dispatch({ type: 'OPEN' });
    } else {
      dispatch({ type: 'CLOSE' });
    }
  }, [isMobile, isOpened]);

  const containerStyle: React.CSSProperties = {
    // Use React.CSSProperties for style object
    transition: `transform ${animationDuration}ms ease-in-out`, // Add animation transition
    transform: `translateY(${state.isOpen ? -state.y : 0}px)`, // Move container based on swipe and openness
    position: state.isOpen ? 'absolute' : 'relative',
    top: state.isOpen ? '80px' : 'unset',
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E8EB', // Adjust background color as needed
    boxShadow: state.isOpen ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none', // Add optional shadow on open
    zIndex: state.isOpen ? 10 : 0, // Set higher z-index for overlay behavior on open
    flexGrow: 1,
  };

  return (
    <div className={`swipe-container ${state.isOpen ? 'open' : ''}`} style={containerStyle}>
      {children}
    </div>
  );
};

export default SwipeContainer;
