import { Booking, Contact, ContactBooking } from '@book-nestor-monorepo/shared-types';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/authContext';
import { createCheckoutSession } from '../../../libs/services/connect';
import { patchContactBooking } from '../../../libs/services/contacts';
import { formatIsoDateString, formatYYYYMMDDDate } from '../../../libs/utils/date.util';
import { cleanCalcomEventTitleToJustEventType } from '../../../libs/utils/string.util';
import PrimaryTextField from '../../formLibrary/primaryTextInput';

export const ContactBookingPaymentAction = ({
  contact,
  onClose,
  onSuccess,
  serviceSlug,
  booking,
}: {
  contact: Contact;
  onClose?: (res?: ContactBooking) => void;
  onSuccess?: (contactId?: string) => void;
  serviceSlug?: string;
  booking: Booking;
}) => {
  const authContext = useContext(AuthContext);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [service, setService] = useState('');

  useEffect(() => {
    if (booking) {
      setService(cleanCalcomEventTitleToJustEventType(booking.title) || '');
    }
  }, [booking]);

  const markAsPaid = async () => {
    if (!amount) return;
    try {
      if (booking.id && contact?.id && !isNaN(amount)) {
        const res = await patchContactBooking(
          authContext.user?.id as string,
          contact?.id as string,
          booking.id,
          {
            client_has_paid: true,
            booking_paid_date: formatYYYYMMDDDate(new Date()),
            booking_paid_amount: Number(amount * 100),
          },
        );
        onClose?.(res);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const submitCheckout = async () => {
    if (!amount || !service) return;
    const url = await createCheckoutSession({
      connected_account_id: authContext.user?.payment_connect_data?.connected_account_id as string,
      amount: amount * 100,
      product_name: service,
      customer_email: contact?.email as string,
      customer_id: contact?.payment_customer_id,
      booking_id: booking.id,
    });
    window.location.href = url.url;
  };

  if (booking.contact_booking?.client_has_paid) {
    return (
      <div className="flex flex-col pt-1.5 px-2 pb-4  mb-2">
        <p className="text-[41.89px] leading-[48px] text-black font-extralight font-inter pb-4">
          Booking Paid
        </p>
        {booking && (
          <div className="flex flex-col">
            <p className="text-xs text-black font-avenir">
              Payment for Appointment on: {formatIsoDateString(booking.start_time)}
            </p>
            {booking?.contact_booking?.booking_paid_date && (
              <p className="text-xs text-black font-avenir">
                Booking Paid on: {booking?.contact_booking?.booking_paid_date}
              </p>
            )}
            {booking?.contact_booking?.booking_paid_amount && (
              <p className="text-xs text-black font-avenir">
                Payment Amount: $
                {!isNaN(booking?.contact_booking?.booking_paid_amount) &&
                  Number(booking?.contact_booking?.booking_paid_amount / 100)}
              </p>
            )}
            {booking?.contact_booking?.payment_session_id ? (
              <p className="text-xs text-black font-avenir line-clamp-1">Payment Method: Stripe</p>
            ) : (
              <p className="text-xs text-black font-avenir  ">Payment Method: Manual</p>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-between pt-1.5 px-2 pb-4  mb-2">
        <p className="text-[41.89px] leading-[48px] text-black font-extralight font-avenir">
          Begin Payment Process
        </p>
        {booking && (
          <div className="flex flex-col pt-2">
            <p className="text-xs text-black font-avenir">
              Payment for Appointment on {formatIsoDateString(booking.start_time)}
            </p>
          </div>
        )}
      </div>
      <form className="flex flex-col justify-start gap-4">
        <PrimaryTextField
          label="Amount"
          color="primary"
          onChange={(e) => {
            const value = Number(e.target.value);
            setAmount(value === 0 ? undefined : value);
          }}
          type="number"
          name="amount"
          value={amount}
          className="w-full"
          variant="filled"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />

        <PrimaryTextField
          label="Service"
          color="primary"
          onChange={(e) => setService(e.target.value)}
          type="text"
          name="service"
          value={service}
          className="w-full"
          variant="filled"
        />

        <div className="flex flex-row justify-start gap-2">
          <button
            disabled={
              !amount ||
              !service ||
              !authContext.user?.payment_connect_data?.payment_requirements_complete
            }
            onClick={(e) => {
              if (!amount || !service) return;
              e.preventDefault();
              submitCheckout();
            }}
            className={`bg-black text-[#5f4cfe] text-[14px] py-2 px-4 rounded-full ${
              !amount ||
              !service ||
              !authContext.user?.payment_connect_data?.payment_requirements_complete
                ? 'opacity-50'
                : ''
            }`}
          >
            <span className="text-[#5f4cfe] pt-8">
              <FontAwesomeIcon color="#5f4cfe" size="xl" className="px-1" icon={faStripe} />
            </span>
            Checkout
          </button>
          {booking && (
            <button
              onClick={(e) => {
                if (!amount || !service) return;
                e.preventDefault();
                markAsPaid();
              }}
              disabled={!amount || !service}
              className={`bg-black  text-white text-[14px]  py-2 px-4 font-avenir rounded-full ${
                !amount || !service ? 'opacity-50' : ''
              }`}
            >
              Mark as Paid
            </button>
          )}
        </div>
      </form>
    </>
  );
};
