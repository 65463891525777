import { AppLayout } from './layouts/app-layout';
import { useLocation, useNavigate } from 'react-router-dom';

export default function UpgradePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const isDark =
    location.pathname === '/voice-messages' || location.pathname.includes('/text-conversation');

  return (
    <AppLayout>
      <div className="flex flex-col h-full pt-8 md:pt-2">
        <div className="flex items-center justify-start pb-4 px-2">
          <span className={`text-2xl ${isDark ? 'text-white' : 'text-black'}`}>Upgrade</span>
        </div>

        <div className="flex flex-col text-left bg-black-modal rounded-tl-3xl rounded-tr-3xl h-full p-16">
          <div className="flex flex-row items-baseline pb-2">
            <div className="flex justify-start flex-grow-0">
              <span></span>
            </div>
          </div>
          <div className="flex flex-col justify-between items-start w-full flex-wrap gap-2 md:text-[48.9px] text-[38px] font-avenir text-white mb-6 md:mb-0">
            Nestor Studio
          </div>
          <div className="flex flex-col justify-between items-start w-full flex-wrap gap-2 text-white text-base leading-6">
            A missed call could mean missed opportunities. Your own designated number means you get
            your personal life back.
          </div>
          <div className="flex flex-row justify-start items-start w-full gap-2 pt-8">
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.466749"
                y="0.466749"
                width="43.8744"
                height="43.8744"
                stroke="white"
                strokeWidth="0.933498"
              />
              <g filter="url(#filter0_b_386_1127)">
                <rect
                  x="0.466749"
                  y="0.466749"
                  width="43.8744"
                  height="43.8744"
                  rx="21.9372"
                  stroke="white"
                  strokeWidth="0.933498"
                />
                <path
                  d="M30.6701 21.6265C30.6701 23.5154 29.2151 25.0724 27.4028 25.2766V25.3022H17.6011V25.2766C15.7633 25.0724 14.3338 23.5154 14.3338 21.6265C14.3338 19.61 15.9675 17.9509 18.0095 17.9509C20.026 17.9509 21.6851 19.61 21.6851 21.6265C21.6851 22.8007 21.1236 23.8217 20.2812 24.4854H24.6971C23.8548 23.8217 23.3188 22.8007 23.3188 21.6265C23.3188 19.61 24.9524 17.9509 26.9944 17.9509C29.0109 17.9509 30.6701 19.61 30.6701 21.6265ZM15.1507 21.6265C15.1507 23.2091 16.4269 24.4854 18.0095 24.4854C19.5665 24.4854 20.8683 23.2091 20.8683 21.6265C20.8683 20.0695 19.5665 18.7677 18.0095 18.7677C16.4269 18.7677 15.1507 20.0695 15.1507 21.6265ZM26.9944 24.4854C28.5515 24.4854 29.8277 23.2091 29.8533 21.6265C29.8533 20.0695 28.5515 18.7677 26.9944 18.7677C25.4118 18.7677 24.1356 20.0695 24.1356 21.6265C24.1356 23.2091 25.4118 24.4854 26.9944 24.4854Z"
                  fill="white"
                />
              </g>
              <rect
                x="30.3378"
                y="0.466505"
                width="14.0025"
                height="14.0025"
                rx="7.00124"
                fill="#F5F5F5"
              />
              <rect
                x="30.3378"
                y="0.466505"
                width="14.0025"
                height="14.0025"
                rx="7.00124"
                stroke="black"
                strokeWidth="0.933498"
              />
              <path
                d="M37.6996 9.23275H34.9523V8.3422L37.4812 4.51952H38.691V8.3674H39.5228V9.23275H38.691V10.4678H37.6996V9.23275ZM37.6996 5.70413H37.6828L35.9941 8.3674H37.6996V5.70413Z"
                fill="black"
              />
              <defs>
                <filter
                  id="filter0_b_386_1127"
                  x="-3.73399"
                  y="-3.73399"
                  width="52.2766"
                  height="52.2758"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.867" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_386_1127"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_386_1127"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <svg
              width="46"
              height="45"
              viewBox="0 0 46 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.27534"
                y="0.466749"
                width="43.8744"
                height="43.8744"
                stroke="white"
                strokeWidth="0.933498"
              />
              <g filter="url(#filter0_b_386_1132)">
                <rect
                  x="1.27534"
                  y="0.466749"
                  width="43.8744"
                  height="43.8744"
                  rx="21.9372"
                  stroke="white"
                  strokeWidth="0.933498"
                />
                <path
                  d="M23.6777 17.1341C20.5126 17.1341 17.9601 19.1506 17.9601 21.6265C17.9601 22.5965 18.3429 23.5154 19.0832 24.3067C19.2874 24.5619 19.364 24.8682 19.2363 25.149C18.9555 25.8893 18.4961 26.5529 18.1898 26.9358C19.3384 26.8082 20.2573 26.3232 20.8189 25.9148C21.0231 25.7616 21.3039 25.7361 21.5336 25.8127C22.2228 26.0169 22.9375 26.119 23.6777 26.119C26.8174 26.119 29.3699 24.1025 29.3699 21.6265C29.3699 19.1506 26.8174 17.1341 23.6777 17.1341ZM23.6777 16.3172C27.2768 16.3172 30.1867 18.7166 30.1867 21.6265C30.1867 24.5619 27.2768 26.9358 23.6777 26.9358C22.8354 26.9358 22.0441 26.8082 21.3039 26.5784C20.5381 27.1145 19.3129 27.7526 17.7559 27.7526C17.5006 27.7526 17.2709 27.625 17.1688 27.3952C17.0922 27.1655 17.1177 26.9103 17.2964 26.7316C17.3219 26.7316 18.1132 25.8637 18.4706 24.8682C17.6282 23.9749 17.1432 22.8517 17.1432 21.6265C17.1432 18.7166 20.0531 16.3172 23.6777 16.3172Z"
                  fill="white"
                />
              </g>
              <rect
                x="31.1464"
                y="0.466505"
                width="14.0025"
                height="14.0025"
                rx="7.00124"
                fill="#F5F5F5"
              />
              <rect
                x="31.1464"
                y="0.466505"
                width="14.0025"
                height="14.0025"
                rx="7.00124"
                stroke="black"
                strokeWidth="0.933498"
              />
              <path
                d="M37.3656 6.96435H37.6345C37.7857 6.96435 37.9341 6.95315 38.0797 6.93075C38.231 6.90274 38.3682 6.85513 38.4914 6.78792C38.6146 6.72071 38.7127 6.63109 38.7855 6.51907C38.8639 6.40145 38.9031 6.25023 38.9031 6.06539C38.9031 5.93097 38.8779 5.81055 38.8275 5.70413C38.7771 5.59771 38.7071 5.5081 38.6174 5.43528C38.5334 5.36247 38.4326 5.30646 38.315 5.26725C38.203 5.22805 38.0853 5.20844 37.9621 5.20844C37.7157 5.20844 37.5112 5.27846 37.3488 5.41848C37.1864 5.5585 37.0744 5.74334 37.0128 5.97298L36.0634 5.72934C36.1138 5.5277 36.1978 5.34567 36.3154 5.18324C36.4387 5.01521 36.5843 4.87238 36.7523 4.75476C36.9259 4.63154 37.1192 4.53632 37.332 4.46911C37.5449 4.4019 37.7689 4.36829 38.0041 4.36829C38.2618 4.36829 38.5054 4.4047 38.7351 4.47751C38.9703 4.54473 39.1747 4.64834 39.3484 4.78837C39.5276 4.92279 39.6676 5.09082 39.7684 5.29246C39.8693 5.49409 39.9197 5.72933 39.9197 5.99818C39.9197 6.31744 39.8217 6.60309 39.6256 6.85513C39.4352 7.10158 39.1775 7.26401 38.8527 7.34242V7.35922C39.2168 7.43204 39.5108 7.59727 39.7348 7.85491C39.9645 8.10696 40.0793 8.41781 40.0793 8.78748C40.0793 9.09553 40.0177 9.36438 39.8945 9.59402C39.7768 9.82366 39.6172 10.0141 39.4156 10.1653C39.2196 10.3165 38.9983 10.4286 38.7519 10.5014C38.5054 10.5798 38.2562 10.619 38.0041 10.619C37.7521 10.619 37.514 10.591 37.29 10.535C37.066 10.4846 36.8587 10.4034 36.6683 10.2913C36.4835 10.1793 36.3182 10.0337 36.1726 9.85446C36.0326 9.67523 35.9234 9.4624 35.845 9.21595L36.7859 8.9219C36.8587 9.15714 36.9932 9.35598 37.1892 9.51841C37.3908 9.68083 37.6513 9.76205 37.9705 9.76205C38.0994 9.76205 38.2282 9.74525 38.357 9.71164C38.4914 9.67243 38.609 9.61362 38.7099 9.53521C38.8163 9.45679 38.9003 9.35598 38.9619 9.23275C39.0291 9.10953 39.0627 8.95831 39.0627 8.77907C39.0627 8.58864 39.0151 8.42901 38.9199 8.30019C38.8303 8.16577 38.7155 8.06215 38.5754 7.98933C38.4354 7.91092 38.2786 7.85491 38.105 7.8213C37.9369 7.7877 37.7745 7.7709 37.6177 7.7709H37.3656V6.96435Z"
                fill="black"
              />
              <defs>
                <filter
                  id="filter0_b_386_1132"
                  x="-2.9254"
                  y="-3.73399"
                  width="52.2766"
                  height="52.2758"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.867" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_386_1132"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_386_1132"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <svg
              width="87"
              height="45"
              viewBox="0 0 87 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_386_1137)">
                <rect
                  x="44.0234"
                  y="42.4742"
                  width="40.1404"
                  height="40.1404"
                  rx="20.0702"
                  transform="rotate(-90 44.0234 42.4742)"
                  stroke="white"
                  strokeWidth="0.933498"
                />
                <path
                  d="M64.3077 15.8509C62.036 15.8509 60.2237 17.6888 60.2237 19.935V20.3434C60.2237 20.5731 60.0195 20.7518 59.8153 20.7518C59.5855 20.7518 59.4068 20.5731 59.4068 20.3434V19.935C59.4068 17.2293 61.602 15.0341 64.3077 15.0341C67.0134 15.0341 69.2086 17.2293 69.2086 19.935V20.3434C69.2086 21.926 67.9068 23.2022 66.3497 23.2022H64.7161H64.3077H63.8993C63.4399 23.2022 63.0825 22.8449 63.0825 22.3854C63.0825 21.9515 63.4399 21.5686 63.8993 21.5686H64.7161C65.15 21.5686 65.5329 21.9515 65.5329 22.3854H66.3497C67.4729 22.3854 68.3918 21.492 68.3918 20.3434V19.935C68.3918 17.6888 66.5539 15.8509 64.3077 15.8509ZM61.0405 19.935C61.0405 18.1482 62.4954 16.6677 64.3077 16.6677C66.0945 16.6677 67.575 18.1482 67.575 19.935C67.575 20.2923 67.4984 20.6497 67.3963 20.956C67.1921 21.3389 66.7837 21.5686 66.3497 21.5686H66.12C65.8392 21.0836 65.3032 20.7518 64.7161 20.7518H63.8993C62.9804 20.7518 62.2657 21.492 62.2657 22.3854C62.2657 22.4365 62.2657 22.462 62.2657 22.4875C61.4999 21.9004 61.0405 20.9815 61.0405 19.935ZM64.3077 24.019H64.7161H66.3497H66.5795C68.4683 24.019 70.0254 25.5761 70.0254 27.465C70.0254 27.8223 69.7191 28.1031 69.3617 28.1031H59.2282C58.8708 28.1031 58.59 27.8223 58.59 27.465C58.59 25.5761 60.1216 24.019 62.0104 24.019H63.8993H64.3077Z"
                  fill="white"
                />
              </g>
              <rect
                x="1.08198"
                y="44.3411"
                width="43.8744"
                height="84.9484"
                rx="21.9372"
                transform="rotate(-90 1.08198 44.3411)"
                stroke="white"
                strokeWidth="0.933498"
              />
              <defs>
                <clipPath id="clip0_386_1137">
                  <rect
                    x="0.615234"
                    y="44.8079"
                    width="44.8079"
                    height="85.8819"
                    rx="22.404"
                    transform="rotate(-90 0.615234 44.8079)"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="flex flex-row justify-start items-start  gap-2 pt-8">
            <button
              onClick={() => navigate('/subscription')}
              className="flex flex-row justify-center items-center w-auto gap-2 p-4 border border-white rounded-full"
            >
              <span className="text-base leading-6 text-white">Upgrade Now</span>
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
