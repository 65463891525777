import { Organization } from '@book-nestor-monorepo/shared-types';
import { faCcVisa, faCcMastercard, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faMoneyBill, faCreditCard, faFileLines } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const items = [
  {
    label: 'Business Type',
    value: 'Massage, Holistic Therapy',
  },
  {
    label: 'Spoken Languages',
    value: 'English, Spanish',
  },
];

const DetailsTab = ({ organization }: { organization: Organization }) => {
  return (
    <div className="py-[40px] px-[24px]">
      <div className="flex items-center gap-2">
        {organization.website_url && (
          <Link
            to={`//${organization.website_url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full px-[24px] py-[12px] bg-black-alt flex items-center justify-center text-white text-sm"
          >
            Website
          </Link>
        )}
        {organization.phone_number && (
          <a
            href={`tel:${organization.phone_number}`}
            className="rounded-full px-[18px] py-[8px] bg-black-alt text-white text-sm"
          >
            Text Us
          </a>
        )}
      </div>
      <div>
        <div className="mt-[30px]">
          <h6 className="font-semibold">Business Type</h6>
          <p>{organization.business_type}</p>
        </div>
        <div className="mt-[30px]">
          <h6 className="font-semibold">Spoken Languages</h6>
          <p>{organization.spoken_languages}</p>
        </div>
      </div>
      <div className="flex mt-[30px]">
        <FontAwesomeIcon className="w-[18px] h-[13px]" icon={faCcVisa} color="#000" />
        <FontAwesomeIcon className="w-[18px] h-[13px]" icon={faCcMastercard} color="#000" />
        <FontAwesomeIcon className="w-[18px] h-[13px]" icon={faCcDiscover} color="#000" />
        <FontAwesomeIcon className="w-[22px] h-[13px]" icon={faMoneyBill} color="#000" />
        <FontAwesomeIcon className="w-[18px] h-[13px]" icon={faCreditCard} color="#000" />
      </div>
      {/* <button className="w-[193px] h-[40px] flex items-center justify-center border border-black rounded-full mt-[30px] text-[14px] text-black-alt">
        Cancellation Policy
        <FontAwesomeIcon className="w-[12px] ml-6" icon={faFileLines} color="#000" />
      </button> */}
    </div>
  );
};

export default DetailsTab;
