export const hasAccess = (decodedToken: any, perm: string) => {
  if (!decodedToken?.['custom:scopes']) {
    return false;
  }
  try {
    // TODO: store decoded token in context
    const scopes = JSON.parse(decodedToken['custom:scopes']);
    return scopes?.includes(perm);
  } catch (error) {
    return false;
  }
};
