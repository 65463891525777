import { AvailabilitySettings, GcalConnect } from '@calcom/atoms';
import { useQuickStart } from '../contexts/quickStartContext';
import { AppLayout } from './layouts/app-layout';
import { OnboardingTaskNames } from '@book-nestor-monorepo/shared-types';

export default function Availability() {
  const { markTaskComplete } = useQuickStart();
  return (
    <AppLayout settings>
      <div className="max-h-full px-2 pt-12">
        <h1 className="font-avenir text-[68px] leading-[72px]">Calendar & Availability</h1>
        <div className="w-full h-auto bg-white rounded-t-[32px] pl-4 pr-2 py-2 mt-8 overflow-y-auto">
          <div className="flex items-center justify-between">
            <h6 className="text-black font-medium font-inter">Calendar Settings</h6>
            <div className="w-[42px] h-[42px] rounded-full border border-black-alt flex items-center justify-center">
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4375 6.5625C11.7422 6.5625 12 6.82031 12 7.125C12 7.45312 11.7422 7.6875 11.4375 7.6875H3.65625C3.42188 8.46094 2.69531 9 1.875 9C0.820312 9 0 8.17969 0 7.125C0 6.09375 0.820312 5.25 1.875 5.25C2.69531 5.25 3.42188 5.8125 3.65625 6.5625H11.4375ZM1.125 7.125C1.125 7.54688 1.45312 7.875 1.875 7.875C2.27344 7.875 2.625 7.54688 2.625 7.125C2.625 6.72656 2.27344 6.375 1.875 6.375C1.45312 6.375 1.125 6.72656 1.125 7.125ZM8.32031 1.3125C8.55469 0.5625 9.28125 0 10.125 0C11.1562 0 12 0.84375 12 1.875C12 2.92969 11.1562 3.75 10.125 3.75C9.28125 3.75 8.55469 3.21094 8.32031 2.4375H0.5625C0.234375 2.4375 0 2.20312 0 1.875C0 1.57031 0.234375 1.3125 0.5625 1.3125H8.32031ZM10.125 2.625C10.5234 2.625 10.875 2.29688 10.875 1.875C10.875 1.47656 10.5234 1.125 10.125 1.125C9.70312 1.125 9.375 1.47656 9.375 1.875C9.375 2.29688 9.70312 2.625 10.125 2.625Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div className="mt-[20px]">
            <h6 className="font-avenir mb-3">Linked Calendars</h6>
            <GcalConnect className="w-full font-avenir max-w-[315px] rounded-full h-10 flex items-center !bg-transparent border border-black-alt !text-black-alt gap-[10px] hover:!bg-transparent" />
          </div>
          <div className="mt-[26px] overflow-y-auto">
            <div className="flex items-center justify-between mb-4">
              <h6 className="text-black font-medium font-inter">Main Availability Settings </h6>
              <div className="w-[42px] h-[42px] rounded-full border border-black-alt flex items-center justify-center">
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4375 6.5625C11.7422 6.5625 12 6.82031 12 7.125C12 7.45312 11.7422 7.6875 11.4375 7.6875H3.65625C3.42188 8.46094 2.69531 9 1.875 9C0.820312 9 0 8.17969 0 7.125C0 6.09375 0.820312 5.25 1.875 5.25C2.69531 5.25 3.42188 5.8125 3.65625 6.5625H11.4375ZM1.125 7.125C1.125 7.54688 1.45312 7.875 1.875 7.875C2.27344 7.875 2.625 7.54688 2.625 7.125C2.625 6.72656 2.27344 6.375 1.875 6.375C1.45312 6.375 1.125 6.72656 1.125 7.125ZM8.32031 1.3125C8.55469 0.5625 9.28125 0 10.125 0C11.1562 0 12 0.84375 12 1.875C12 2.92969 11.1562 3.75 10.125 3.75C9.28125 3.75 8.55469 3.21094 8.32031 2.4375H0.5625C0.234375 2.4375 0 2.20312 0 1.875C0 1.57031 0.234375 1.3125 0.5625 1.3125H8.32031ZM10.125 2.625C10.5234 2.625 10.875 2.29688 10.875 1.875C10.875 1.47656 10.5234 1.125 10.125 1.125C9.70312 1.125 9.375 1.47656 9.375 1.875C9.375 2.29688 9.70312 2.625 10.125 2.625Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <AvailabilitySettings
              customClassNames={{
                containerClassName: 'editableAvailabilityContainerClassName !px-0 !pt-0 ',
                ctaClassName: '',
                editableHeadingClassName:
                  'editableHeadingClassName font-avenir text-black-text text-[25.888px] leading-8 font-semibold px-0',
                formClassName: 'formClassName px-0',
                timezoneSelectClassName: 'C px-0',
                subtitlesClassName: 'subtitlesClassName hidden px-0',
                scheduleClassNames: {
                  scheduleContainer: 'scheduleContainer px-0',
                  scheduleDay: 'scheduleDay   mb-4 last:mb-0',
                  dayRanges: 'ml-8 px-0',
                  timeRanges: 'timeRanges px-0',
                  labelAndSwitchContainer: 'labelAndSwitchContainer px-0',
                },
              }}
              onUpdateSuccess={() => {
                markTaskComplete(OnboardingTaskNames.verifyAvailability);
              }}
            />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
