import { EventType } from '@book-nestor-monorepo/shared-types';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  services: EventType[];
}

const ServiceBox: FC<IProps> = ({ title, services }) => {
  const history = useNavigate();
  return (
    <div className="relative">
      <button
        onClick={() => history('/services/new')}
        className="absolute right-2 top-0 translate-x-[10px] -translate-y-[17px] w-10 h-10 rounded-full flex items-center justify-center bg-black-alt"
      >
        <svg
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0.875V4.25H9.875C10.2734 4.25 10.625 4.60156 10.625 5C10.625 5.42188 10.2734 5.75 9.875 5.75H6.5V9.125C6.5 9.54688 6.14844 9.875 5.75 9.875C5.32812 9.875 5 9.54688 5 9.125V5.75H1.625C1.20312 5.75 0.875 5.42188 0.875 5C0.875 4.60156 1.20312 4.25 1.625 4.25H5V0.875C5 0.476562 5.32812 0.125 5.75 0.125C6.14844 0.125 6.5 0.476562 6.5 0.875Z"
            fill="white"
          />
        </svg>
      </button>
      <h4 className="font-medium">{title}</h4>
      <div className="mt-3 grid grid-cols-2 gap-[6px]">
        {services.map((eventType) => (
          <div
            key={eventType.id}
            onClick={() => history(`/services/${eventType.id}`)}
            className={`w-full h-[135px] py-[11px] px-2 flex flex-col justify-between bg-[#ECECEC] rounded-[16px] cursor-pointer`}
          >
            <div className="flex w-full justify-between">
              <p className="max-w-[111px] text-[10px] leading-[16px] text-[#959595]">
                {eventType.categories && eventType.categories.length > 0
                  ? eventType.categories[0].name
                  : ''}
              </p>
              <div>
                {services && 'title' in eventType && !eventType.image_key && (
                  <div className="w-8 h-8 border border-[#FF0000] bg-[#FFFFFF1A] flex items-center justify-center rounded-full">
                    <svg
                      width="11"
                      height="10"
                      viewBox="0 0 11 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.96875 2.59375C4.47656 2.59375 4.90625 3.02344 4.90625 3.53125C4.90625 4.05859 4.47656 4.46875 3.96875 4.46875C3.44141 4.46875 3.03125 4.05859 3.03125 3.53125C3.03125 3.02344 3.44141 2.59375 3.96875 2.59375ZM9.73047 0.875C10.4336 0.875 10.9805 1.44141 10.9805 2.125V8.375C10.9805 9.07812 10.4141 9.625 9.73047 9.625H2.23047C1.54688 9.625 0.980469 9.07812 0.980469 8.375V2.125C0.980469 1.44141 1.54688 0.875 2.23047 0.875H9.73047ZM10.043 8.25781V2.125C10.043 1.96875 9.90625 1.8125 9.73047 1.8125H2.23047C2.07422 1.8125 1.91797 1.96875 1.91797 2.125L1.9375 8.375L3.40234 6.53906C3.48047 6.46094 3.57812 6.40234 3.69531 6.40234C3.8125 6.40234 3.91016 6.46094 3.98828 6.53906L4.71094 7.4375L6.78125 4.625C6.85938 4.52734 6.95703 4.46875 7.09375 4.46875C7.21094 4.46875 7.30859 4.52734 7.36719 4.625L10.043 8.25781Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="max-w-[111px] line-clamp-2">
              <p className="text-black-alt font-avenir">{eventType.title}</p>
            </div>
          </div>
        ))}
        <button
          onClick={() => history('/services/new')}
          className="w-full h-[135px] pt-[35px] pl-[46px] pb-[20px] flex flex-col justify-between bg-black-alt rounded-[16px]"
        >
          <div>
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.6875 4.25C8.6875 4.52344 8.47266 4.71875 8.21875 4.71875H5.09375V7.84375C5.09375 8.11719 4.87891 8.33203 4.625 8.33203C4.35156 8.33203 4.15625 8.11719 4.15625 7.84375V4.71875H1.03125C0.757812 4.71875 0.5625 4.52344 0.5625 4.26953C0.5625 3.99609 0.757812 3.78125 1.03125 3.78125H4.15625V0.65625C4.15625 0.402344 4.35156 0.207031 4.625 0.207031C4.87891 0.207031 5.09375 0.402344 5.09375 0.65625V3.78125H8.21875C8.47266 3.78125 8.6875 3.99609 8.6875 4.25Z"
                fill="#ECECEC"
              />
            </svg>
          </div>
          <p className="text-white font-avenir">{services ? 'Service' : 'Category'}</p>
        </button>
      </div>
    </div>
  );
};

export default ServiceBox;
