import { SubscriptionPlan, USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { AppProvider } from './contexts/appContext';
import { ModalProvider } from './contexts/modalContext';
import { OnboardingProvider } from './contexts/onboardingContext';
import { StripePaymentProvider } from './contexts/paymentContext';

// SignInRoute routes
import EnterPassword from './pages/auth/enterPassword';
import ForgotPassword from './pages/auth/forgotPassword';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import VerifyCode from './pages/auth/verifyCode';
import Welcome from './pages/auth/welcome';

import { MUIFlyUpModalProvider } from './contexts/muiFlyupModal';
import { AgentProvider } from './contexts/v2/agentContext';
import { ContactsProvider } from './contexts/v2/contactsContext';
import { ServicesProvider } from './contexts/v2/eventTypesContext';
import { OrganizationProvider } from './contexts/v2/organizationContext';
import { DrawerProvider } from './contexts/v2/rightDrawerContext';
import { ToastProvider } from './contexts/v2/toastContext';
import AuthGuard from './guards/authGuard';
import BookingDetail from './pages/booking-detail';
import BookingNew from './pages/booking-new';
import CheckoutDetail from './pages/checkout-detail';
import AuthenticatedAppLayout from './pages/layouts/v2/authenticated-app-layout';
import OauthExchange from './pages/oauth/exchange';
import ReviewDetail from './pages/review-detail';
import ScheduleEventType from './pages/scheduleEventType';
import { AgentPage } from './pages/v2/agent';
import { AvailabilityV2 } from './pages/v2/availability';
import { BookerMobilePage } from './pages/v2/booker-mobile';
import { BookingControlV2 } from './pages/v2/booking-control';
import { BookingRescheduleMobilePage } from './pages/v2/booking-reschedule';
import { BookingsListV2 } from './pages/v2/bookings-list';
import { BusinessProfileV2 } from './pages/v2/business-profile';
import { CampaignsListV2 } from './pages/v2/campaigns-list';
import { CampaignBuilderFillSlotV2 } from './pages/v2/campain-types.tsx/campaign-builder-fill-slot';
import { CampaignBuilderGeneralV2 } from './pages/v2/campain-types.tsx/campaign-builder-general';
import { CommunicationSettings } from './pages/v2/communication-settings';
import { ContactDetailV2 } from './pages/v2/contact-detail';
import { ContactsV3 } from './pages/v2/contactsV3';
import { ConversationsListV2 } from './pages/v2/conversations-list';
import { HomeV3 } from './pages/v2/homev3';
import { LinkedCalendarsV2 } from './pages/v2/linked-calendars';
import { PaymentsV2 } from './pages/v2/payments';
import { PersonalSettingsPageV2 } from './pages/v2/personal-settings';
import { QuickStartPageV2 } from './pages/v2/quick-start';
import { SchedulePageV2 } from './pages/v2/schedule';
import { ServiceDetailV2 } from './pages/v2/service-detail';
import { ServicesListV2 } from './pages/v2/services-list';
import { SubscriptionContainer } from './pages/v2/subscription-container';

export const SignInRoute: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/enterPassword" element={<EnterPassword />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/" element={<Login />} />
      <Route path="/book/:userSlug" element={<BookingNew />} />
      <Route path="/book/:userSlug/:eventTypeSlug" element={<ScheduleEventType />} />
      <Route path="/book/:userSlug/booking/:bookingId" element={<BookingDetail />} />
      <Route path="/book/:userSlug/reviews/:reviewId" element={<ReviewDetail />} />
      <Route path="/oauth">
        <Route path="exchange" element={<OauthExchange />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

interface MainRouteProps {
  token: string;
  subscription?: SubscriptionPlan;
}

export const MainRoute: React.FunctionComponent<MainRouteProps> = (props: MainRouteProps) => {
  const isInactiveSubscription = props.subscription === SubscriptionPlan.INACTIVE;
  const navigate = useNavigate();

  useEffect(() => {
    if (isInactiveSubscription) {
      navigate('/subscription');
    }
  }, [isInactiveSubscription, navigate]);

  return (
    <OnboardingProvider>
      <ContactsProvider>
        <ServicesProvider>
          <AgentProvider>
            <StripePaymentProvider>
              <MUIFlyUpModalProvider>
                <DrawerProvider>
                  <AppProvider>
                    <ModalProvider>
                      <ToastProvider>
                        <OrganizationProvider>
                          <Routes>
                            <Route
                              path="/"
                              element={
                                <AuthenticatedAppLayout>
                                  <Outlet />
                                </AuthenticatedAppLayout>
                              }
                            >
                              <Route index element={<HomeV3 />} />
                              <Route path="agent" element={<AgentPage />} />
                              <Route path="contacts" element={<ContactsV3 />} />
                              <Route path="contacts/:contactId" element={<ContactDetailV2 />} />
                              {/* <Route path="bookings" element={<BookingsPageV2 />} /> */}
                              <Route path="bookings" element={<BookingsListV2 />} />
                              <Route path="schedule" element={<SchedulePageV2 />} />
                              <Route
                                path="booking-control/:contactId"
                                element={<BookingControlV2 />}
                              />
                              {/* <Route path="services" element={<ServicesPageV2 />} /> */}
                              <Route path="services" element={<ServicesListV2 />} />
                              <Route path="services/:serviceId" element={<ServiceDetailV2 />} />
                              <Route path="campaigns" element={<CampaignsListV2 />} />
                              <Route path="conversations" element={<ConversationsListV2 />} />
                              <Route
                                path="campaigns/fill-slot/:bookingId"
                                element={<CampaignBuilderFillSlotV2 />}
                              />
                              <Route
                                path="campaigns/general"
                                element={<CampaignBuilderGeneralV2 />}
                              />
                              <Route path="communication" element={<CommunicationSettings />} />
                              <Route path="business-profile" element={<BusinessProfileV2 />} />
                              <Route path="availability" element={<AvailabilityV2 />} />
                              <Route path="linked-calendars" element={<LinkedCalendarsV2 />} />
                              <Route path="payments" element={<PaymentsV2 />} />
                              <Route
                                path="payments/checkout/:checkoutState"
                                element={<CheckoutDetail />}
                              />
                              <Route path="subscription" element={<SubscriptionContainer />} />
                              <Route path="quick-start" element={<QuickStartPageV2 />} />
                              <Route
                                path="personal-settings"
                                element={<PersonalSettingsPageV2 />}
                              />
                              <Route path="booking-mobile" element={<BookerMobilePage />} />
                              <Route
                                path="booking-reschedule/:bookingId"
                                element={<BookingRescheduleMobilePage />}
                              />
                            </Route>
                            <Route path="/book/:userSlug" element={<BookingNew />} />
                            <Route
                              path="/book/:userSlug/:eventTypeSlug"
                              element={<ScheduleEventType />}
                            />
                            <Route
                              path="/book/:userSlug/booking/:bookingId"
                              element={<BookingDetail />}
                            />
                            <Route
                              path="/book/:userSlug/reviews/:reviewId"
                              element={<ReviewDetail />}
                            />
                            <Route path="/oauth">
                              <Route path="exchange" element={<OauthExchange />} />
                            </Route>
                            <Route path="*" element={<Navigate to="/" replace />} />
                          </Routes>
                        </OrganizationProvider>
                      </ToastProvider>
                    </ModalProvider>
                  </AppProvider>
                </DrawerProvider>
              </MUIFlyUpModalProvider>
            </StripePaymentProvider>
          </AgentProvider>
        </ServicesProvider>
      </ContactsProvider>
    </OnboardingProvider>
  );
};
