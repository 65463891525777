import { faArrowRightToBracket, faEye, faEyeSlash, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmResetPassword, signIn } from 'aws-amplify/auth';
import { Cache } from 'aws-amplify/utils';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { useValidPassword } from '../../hooks/useAuthHooks';
import { AuthLayout } from '../layouts/auth-layout';

export default function ForgotPassword() {
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { password, setPassword, passwordIsValid } = useValidPassword('');

  const history = useNavigate();

  const authContext = useContext(AuthContext);
  const username = authContext.username;

  useEffect(() => {
    const fetchData = async () => {
      const username = authContext?.username || (await Cache.getItem('login-username-set'));

      setEmail(username);
    };

    fetchData();
  }, [authContext?.username]);

  const resetPasswordClicked = async (event: any) => {
    event.preventDefault();
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword: password,
      });
      await signIn({ username: email, password });
      history('/');
    } catch (err) {
      if (err instanceof Error) {
        switch (err.name) {
          default:
            setError(err.message);
            break;
        }
      }
    }
  };

  const showPasswordHint = password?.length > 0 && !passwordIsValid;

  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  const isValid =
    !confirmationCode ||
    confirmationCode?.length === 0 ||
    !passwordIsValid ||
    password?.length === 0;

  const signUpClicked = async (event: any) => {
    history('/signup');
    setEmail('');
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center bg-mid-gray  overflow-hidden ">
        <div className=" md:max-w-sm mx-auto min-h-screen  md:h-[640px] md:min-h-[720px] h-full  overflow-hidden relative shadow-md w-full ">
          <div className="absolute top-0 right-0 w-80 h-18 bg-black rounded-full transform translate-x-1/2 -translate-y-1/2 -rotate-45">
            <div className="pt-7 pl-6 ">
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="rotate-45"
              >
                <circle cx="23.3513" cy="8.64866" r="8.14866" stroke="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.2947 31.8271C20.1631 31.8271 17.5977 29.4029 17.3728 26.3287L17.3568 26.346C17.3568 25.2041 16.5194 20.7126 14.6162 18.5811C11.5542 15.1516 8.45091 14.7425 6.74778 14.518L6.74753 14.518C6.6221 14.5015 6.50427 14.4859 6.39461 14.4703L6.4118 14.4517C6.25544 14.464 6.09738 14.4703 5.93785 14.4703C2.65846 14.4703 -1.40382e-07 11.8118 2.96496e-09 8.53245C1.46312e-07 5.25307 2.65846 2.5946 5.93785 2.5946C9.21723 2.5946 11.8757 5.25307 11.8757 8.53245V8.53267C11.8757 8.64597 11.8725 8.75852 11.8662 8.87024C11.8682 10.3798 12.3914 14.0366 15.7581 16.9824C18.9073 19.5017 20.8984 19.7051 22.7961 19.899C22.9628 19.916 23.1287 19.9329 23.2946 19.9514L23.2946 19.9514H23.2947C26.574 19.9514 29.2325 22.6099 29.2325 25.8893C29.2325 29.1687 26.574 31.8271 23.2947 31.8271ZM23.2947 14.4703C20.0153 14.4703 17.3568 11.8118 17.3568 8.53245C17.3568 5.25307 20.0153 2.59461 23.2947 2.59461C26.574 2.59461 29.2325 5.25307 29.2325 8.53245C29.2325 11.8118 26.574 14.4703 23.2947 14.4703ZM5.93785 31.8271C2.65846 31.8271 -1.43347e-07 29.1687 0 25.8893C1.43347e-07 22.6099 2.65846 19.9514 5.93785 19.9514C9.21723 19.9514 11.8757 22.6099 11.8757 25.8893C11.8757 29.1687 9.21723 31.8271 5.93785 31.8271Z"
                  fill="#E1E0E1"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-16  pt-16 pb-8 bg-light-gray rounded-bl-[3rem] rounded-br-[3rem]">
            <div className="mb-2 w-full text-left">
              <p className="text-3xl leading-12 font-bold text-black tracking-tighter">
                Reset <br />
                Password
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mb-0 pt-8">
              <div className="w-full h-auto rounded-3xl p-0.5 bg-white">
                <div className="flex items-center justify-between">
                  <button className="p-3.5 bg-black rounded-3xl flex items-center">
                    <div className="text-white text-base font-medium leading-none">Sign in</div>
                    <div className="text-white text-xs font-black leading-none px-4">
                      <FontAwesomeIcon icon={faArrowRightToBracket} />
                    </div>
                  </button>
                  <button
                    onClick={signUpClicked}
                    className="p-3.5 bg-white rounded-3xl flex items-center"
                  >
                    <div className="text-black text-base font-medium leading-none">Register</div>
                    <div className="text-stone-900 text-xs font-black leading-none pl-4">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 pt-12 pb-8  md:mb-12 space-y-4">
            <form>
              <div className="w-full pb-4">
                <label htmlFor="password" className="block text-base text-black">
                  <label>Enter the verification code sent to {username}</label>
                </label>
                <input
                  name="code"
                  type="confirmationCode"
                  value={confirmationCode}
                  maxLength={6}
                  onChange={(e) => {
                    setError('');
                    setConfirmationCode(e.target.value);
                  }}
                  required
                  className="block w-full bg-transparent border-0 border-b focus:outline-none  border-black input-no-outline"
                ></input>
              </div>
              <div className="w-full pb-4">
                <label htmlFor="password" className="block text-base text-black">
                  Enter your <span className="font-bold">password</span>
                </label>
                <div className="flex">
                  <input
                    type={`${showPassword ? 'text' : 'password'}`}
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                    className="block w-full bg-transparent border-0 border-b focus:outline-none  border-black input-no-outline"
                  ></input>
                  <span
                    className="inline-block cursor-pointer align-middle items-center justify-center"
                    style={{ marginLeft: '-40px' }}
                    onClick={toggleShowHidePassword}
                  >
                    <FontAwesomeIcon
                      className={`${showPassword ? 'block' : 'hidden'} text-black`}
                      icon={faEye}
                    />
                    <FontAwesomeIcon
                      className={`${showPassword ? 'hidden' : 'block'} text-black`}
                      icon={faEyeSlash}
                    />
                  </span>
                </div>
                {showPasswordHint && (
                  <p className="text-black text-left text-xs mt-1 ">
                    8+ characters, with special character
                  </p>
                )}
                {error && <p className="text-red-500 text-left text-xs  mt-1 ">{error}</p>}
              </div>

              <div className="flex justify-between items-center pt-6">
                <button
                  disabled={isValid}
                  onClick={resetPasswordClicked}
                  className={`bg-white text-black py-2 w-full px-4 rounded-full ${
                    isValid ? 'opacity-50' : ''
                  }`}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
