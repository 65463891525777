import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContacts } from '../../../contexts/v2/contactsContext';
import ContactComboBox from '../../../pages/v2/contact-combobox';
import { BookingData } from './booking-container';
import { useEffect } from 'react';
import { BookingSummaryCard } from './booking-summary-card';
export const SelectClient = ({
  bookingData,
  selectedClient,
  onSelect,
  onNext,
  preLoadContactId,
}: {
  bookingData: BookingData;
  selectedClient: any;
  onSelect: (client: any) => void;
  onNext: () => void;
  preLoadContactId?: string;
}) => {
  const { contacts } = useContacts();

  useEffect(() => {
    if (preLoadContactId) {
      const contact = contacts.find((c) => c.id === preLoadContactId);
      if (contact) {
        onSelect(contact);
      }
    }
  }, [preLoadContactId, contacts, onSelect]);

  return (
    <div className="mt-8 space-y-6 font-avenir">
      {/* Header Section */}
      <div className="border-b pb-4">
        {/* Booking Summary Card */}
        <BookingSummaryCard bookingData={bookingData} />
      </div>
      {/* Add client selection UI here */}
      <ContactComboBox
        contacts={contacts}
        selectedContact={selectedClient}
        onSelect={onSelect}
        preLoadContactId={preLoadContactId}
      />
      <div className="flex justify-end w-full pt-6">
        <button
          onClick={onNext}
          disabled={!selectedClient}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-700"
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4 w-4" />
        </button>
      </div>
    </div>
  );
};
