import { ChatCompletionMessageParam } from 'openai/resources/chat/completions';
import { User } from '../user';
import { Organization } from '../organization';
import { EventType } from '../event-type';
import { Contact } from '../contact';
export interface BookingConversationProcessorEvent {
  conversationId: string;
  userId: string;
}

export interface BookingConversationCachedData {
  user?: Partial<User>;
  business?: Partial<Organization>;
  services?: Partial<EventType>[];
  defaultSchedule?: WeekSchedule;
  contact?: Partial<Contact>;
}

export enum BookingConversationGoal {
  BookAppointment = 'BookAppointment',
  ProvideInformation = 'ProvideInformation',
  TakeMessage = 'TakeMessage',
}

export enum BookingConversationStep {
  GATHER_FIRST_AND_LAST_NAME = 'GATHER_FIRST_AND_LAST_NAME',
  SELECT_SERVICE = 'SELECT_SERVICE',
}

export interface BookingConversationItem {
  id: string;
  user_id: string;
  organization_id: string;
  contact_id?: string;
  contact?: Partial<Contact>;
  messages: ChatCompletionMessageParam[];
  function_call_messages: ChatCompletionMessageParam[];
  channel_type: ChannelType;
  cached_data: BookingConversationCachedData;
  current_goal: BookingConversationGoal;
  current_step: BookingConversationStep;
  is_idle: boolean;
  summary: BookingConversationSummaryItem | null;
  created_at: number;
  updated_at: number;
  conversation_phone_number?: string;
  booking_id?: string;
  is_recovery?: boolean;
  has_recovery?: boolean;
  recovery_conversation_id?: string;
  recovery_conversation_parent_id?: string;
  recovery_conversation_messages?: ChatCompletionMessageParam[];
}

interface Slot {
  time: string;
}

export interface SlotsForAgent {
  [date: string]: Slot[];
}

export type TimeSlot = {
  start: string;
  end: string;
};

export type WeekSchedule = TimeSlot[][];

export enum Sentiment {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

export enum ConversationIntent {
  BOOKING = 'book appointment',
  CANCEL_APPOINTMENT = 'cancel appointment',
  RESCHEDULE_APPOINTMENT = 'reschedule appointment',
  INFORMATION_REQUEST = 'information request',
  OTHER = 'other',
}

export interface BookingConversationSummaryItem {
  id: string;
  user_id: string;
  sentiment: Sentiment;
  channel_type: ChannelType;
  conversation_intent: ConversationIntent;
  conversation_intent_timeline: ConversationIntent[];
  complexity_score: number;
  outcome: ConversationIntent;
  summary: string;
  duration: number;
  conversation_date: number;
  created_at: number;
  updated_at: number;
}

export enum AgentType {
  BOOKING_AGENT = 'booking-agent',
}

export enum ChannelType {
  VOICE = 'voice',
  SMS = 'sms',
  TEXT = 'text',
}

export enum AgentType {
  BOOKING = 'booking',
}

export interface BookingConversationMessageItem {
  conversation_date: number;
  conversation_id: string;
  user_id: string;
  organization_id: string;
  title: string;
  content: string;
  is_urgent: boolean;
  status: BookingConversationMessageStatus;
  created_at: number;
  updated_at: number;
  agent_type: AgentType;
  response?: AgentConversationMessageResponseDto;
}

export interface AgentConversationMessageResponseDto {
  content: string;
  created_at: number;
  updated_at: number;
  status: BookingConversationMessageResponseStatus;
}

export enum BookingConversationMessageResponseStatus {
  PENDING = 'pending',
  ACKNOWLEDGED = 'acknowledged',
  SENT = 'sent',
}

export enum BookingConversationMessageStatus {
  PENDING = 'pending',
  ACKNOWLEDGED = 'acknowledged',
  COMPLETED = 'completed',
}

export interface NiBookingMessageResponseTemplateInputs {
  user_id: string;
  message_id: string;
  message_content: string;
}
