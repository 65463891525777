import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEllipsisVertical,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import * as dayjs from 'dayjs';
import { cleanCalcomEventTitleToJustEventType, truncateText } from '../../libs/utils/string.util';
import { CalendarDay, CalendarError, CalendarView } from './calendar-container';
import CalendarErrorView from './calendar-error-view';

interface CalendarMonthViewProps {
  setCurrentView: (view: CalendarView) => void;
  onEventClick: (bookingId: string) => void;
  currentDateRange: { start: string; end: string };
  calendarDays: CalendarDay[];
  handleBackwardMonth: () => void;
  handleForwardMonth: () => void;
  handleDayClick: (day: CalendarDay) => void;
  selectedDay: CalendarDay | null;
  currentView: CalendarView;
  handleTodayClick: () => void;
  error: CalendarError | null;
  handleAddEventClick: () => void;
}

export default function CalendarMonthView({
  setCurrentView,
  onEventClick,
  currentDateRange,
  calendarDays,
  handleBackwardMonth,
  handleForwardMonth,
  handleDayClick,
  selectedDay,
  currentView,
  handleTodayClick,
  error,
  handleAddEventClick,
}: CalendarMonthViewProps) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  if (error) {
    return <CalendarErrorView error={error} />;
  }

  return (
    <div className="bg-gray-50 lg:h-auto font-inter rounded-2xl pb-6">
      <div className="lg:flex lg:h-full lg:flex-col rounded-2xl">
        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none font-avenir">
          <h1 className="text-base font-semibold font-avenir text-gray-900">
            <time dateTime={currentDateRange.start}>
              {dayjs(currentDateRange.start).format('MMMM YYYY')}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-full bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-full border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50 "
                onClick={() => handleBackwardMonth()}
              >
                <span className="sr-only">Previous month</span>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                type="button"
                className="hidden border-y px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block border border-gray-300"
                onClick={handleTodayClick}
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-full border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={() => handleForwardMonth()}
              >
                <span className="sr-only">Next month</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <Menu as="div" className="relative">
                <MenuButton
                  type="button"
                  className="flex items-center gap-x-2 rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Month view
                  <FontAwesomeIcon icon={faChevronDown} className="-mr-1 size-5 text-gray-400" />
                </MenuButton>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    {/* <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                      >
                        Day view
                      </a>
                    </MenuItem> */}
                    <MenuItem>
                      <span
                        onClick={() => setCurrentView('week')}
                        className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'week' ? 'font-bold' : ''}`}
                      >
                        Week view
                      </span>
                    </MenuItem>
                    <MenuItem>
                      <span
                        className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'month' ? 'font-bold' : ''}`}
                      >
                        Month view
                      </span>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                type="button"
                className="ml-6 rounded-full bg-green-600 px-3 py-2 text-[12px] text-white shadow-sm  items-center flex justify-center cursor-pointer"
                onClick={handleAddEventClick}
              >
                <FontAwesomeIcon icon={faPlus} className="font-[10px]" />
                <span className="ml-2">Add event</span>
              </button>
            </div>
            <button
              className="ml-6 rounded-full bg-green-600 px-3 py-2 h-9 w-9 text-[12px] text-white shadow-sm  items-center flex justify-center cursor-pointer md:hidden"
              onClick={handleAddEventClick}
            >
              <FontAwesomeIcon icon={faPlus} className="font-[10px]" />
            </button>
            <Menu as="div" className="relative ml-6 md:hidden">
              <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <FontAwesomeIcon icon={faEllipsisVertical} className="size-5" />
              </MenuButton>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {/* <div className="py-1">
                  <MenuItem>
                    <span
                      onClick={handleAddEventClick}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
                    >
                      Create event
                    </span>
                  </MenuItem>
                </div> */}
                <div className="py-1">
                  <MenuItem>
                    <span
                      onClick={handleTodayClick}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Today
                    </span>
                  </MenuItem>
                </div>
                <div className="py-1">
                  {/* <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Day view
                    </a>
                  </MenuItem> */}
                  <MenuItem>
                    <span
                      onClick={() => setCurrentView('week')}
                      className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'week' ? 'font-bold' : ''}`}
                    >
                      Week view
                    </span>
                  </MenuItem>
                  <MenuItem>
                    <span
                      onClick={() => setCurrentView('month')}
                      className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'month' ? 'font-bold' : ''}`}
                    >
                      Month view
                    </span>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </header>
        <div className="shadow ring-1 ring-black/5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs/6 font-semibold text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs/6 text-gray-700 lg:flex-auto lg:min-h-[700px]">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
              {calendarDays.map((day) => (
                <div
                  key={day.date}
                  className={classNames(
                    day.isCurrentMonth ? 'bg-white ' : 'bg-gray-50 text-gray-500',
                    'relative px-3 py-2',
                  )}
                  onClick={() => handleDayClick(day)}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      'flex size-6 items-center justify-center rounded-full text-[12px] font-inter',
                      day.isToday ? 'bg-green-600 font-semibold text-white ' : 'text-gray-900',
                    )}
                  >
                    {day.date?.split('-').pop()?.replace(/^0/, '')}
                  </time>
                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.slice(0, 2).map((event) => (
                        <li key={event.id}>
                          <a
                            href={event.href}
                            className="group flex justify-start items-center font-[12px]"
                            onClick={(e) => {
                              e.preventDefault();
                              onEventClick(event.bookingId);
                            }}
                          >
                            <time
                              dateTime={event.datetime}
                              className="ml-1 hidden flex-none text-black group-hover:text-gray-500 xl:block mt-[1px] font-inter"
                            >
                              {event.time}
                            </time>
                            <p className="flex-auto text-left truncate font-medium text-black group-hover:text-gray-500 ml-1 font-inter">
                              {cleanCalcomEventTitleToJustEventType(event.name || '')}
                            </p>
                          </a>
                        </li>
                      ))}
                      {day.events.length > 2 && (
                        <li className="text-gray-600">+ {day.events.length - 2} more</li>
                      )}
                    </ol>
                  )}
                </div>
              ))}
            </div>
            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
              {calendarDays.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (day.date === selectedDay?.date || day.isToday) && 'font-semibold',
                    day.date === selectedDay?.date && 'text-white',
                    day.date !== selectedDay?.date && day.isToday && 'text-black',
                    day.date !== selectedDay?.date &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      'text-gray-900',
                    day.date !== selectedDay?.date &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      'text-gray-500',
                    'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                  )}
                  onClick={() => handleDayClick(day)}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.date === selectedDay?.date &&
                        'flex size-6 items-center justify-center rounded-full',
                      day.date === selectedDay?.date && day.isToday && 'bg-green-600 font-bold',
                      day.date === selectedDay?.date && !day.isToday && 'bg-green-600',
                      'ml-auto',
                    )}
                  >
                    {day.date?.split('-').pop()?.replace(/^0/, '')}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span
                          key={event.id}
                          className="mx-0.5 mb-1 size-1.5 rounded-full bg-gray-400"
                        />
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        {selectedDay?.events && selectedDay.events.length > 0 && (
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black/5">
              {selectedDay?.events?.map((event) => (
                <li
                  key={event.id}
                  className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
                  onClick={() => onEventClick(event.bookingId)}
                >
                  <div
                    className={`w-full flex flex-col ${event.is_cancelled ? 'opacity-50 line-through' : ''}`}
                  >
                    <div className="w-full flex items-center justify-start">
                      <p className="font-semibold text-gray-900 mr-1">
                        {truncateText(cleanCalcomEventTitleToJustEventType(event.name || ''), 30)}
                      </p>
                      {' - '}
                      {truncateText(event.contact_name, 20)}
                      {event.is_paid && (
                        <span
                          className={`inline-block w-[6px] h-[6px] rounded-full bg-[#43CA51] ml-1`}
                        />
                      )}
                      {event.is_cancelled && (
                        <span className="inline-block w-[6px] h-[6px] rounded-full bg-red-500 ml-1" />
                      )}
                    </div>
                    <div className="w-full flex items-center justify-start">
                      <time
                        dateTime={event.datetime}
                        className="mt-2 flex items-center text-gray-700"
                      >
                        {event.time} - {event.formatted_end_time}
                      </time>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
}
