import { ReminderWindow } from './contact';

export interface EventType {
  id?: string;
  user_id?: string;
  title: string;
  categories: { id: string; name: string }[];
  slug?: string;
  description?: string;
  image_key?: string;
  duration_mins: number;
  location?: EventTypeLocation;
  price: number;
  minimum_booking_notice_mins: number;
  created_at?: string;
  updated_at?: string;
  settings?: EventTypeNotificationSettings;
}

export interface EventTypeNotificationSettings {
  booking_reminders: {
    enabled: boolean;
    window: ReminderWindow[];
    delivery_methods: {
      email: boolean;
      sms: boolean;
    };
  };
}

export interface EventTypeLocation {
  type: EventTypeLocationType;
  address: string;
  displayLocationPublicly?: boolean;
  hostPhoneNumber?: string;
}

export enum EventTypeLocationType {
  IN_PERSON = 'inPerson',
}
