import { Organization, ReviewSummary } from '@book-nestor-monorepo/shared-types';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { environment } from '../../environments/environment';
import BookingSlider from './BookingSlider';

const BookingDetails = ({
  organization,
  reviewsSummary,
  userCanUseBusinessReviews,
  userCanUseBusinessDescription,
  userUploadPhotoLimit,
}: {
  organization: Organization;
  reviewsSummary: ReviewSummary;
  userCanUseBusinessReviews: boolean;
  userCanUseBusinessDescription: boolean;
  userUploadPhotoLimit: number;
}) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const mainBookingImage = organization.logo_image_key
    ? `${environment.s3.bucketUrl}/${organization.logo_image_key}`
    : '/assets/bookings/booking-main-coming-soon-desktop.jpg';

  const galleryImages = organization.gallery_image_keys || [];

  const getGalleryImageUrl = (imageKey: string) => {
    return `${environment.s3.bucketUrl}/${imageKey}`;
  };

  const items = [];

  if (galleryImages.length === 0) {
    // If no images, use a placeholder or default image
    items.push(...Array(4).fill('/assets/bookings/booking-main-coming-soon-desktop.jpg'));
  } else if (galleryImages.length < 4) {
    // If less than 4 images, use available images and repeat to fill 4 slots
    const availableImages = galleryImages.map(getGalleryImageUrl);
    items.push(...availableImages);
    while (items.length < 4) {
      items.push(...availableImages);
    }
    items.splice(4); // Ensure we only have 4 items
  } else {
    // Use up to userUploadPhotoLimit images
    const imageUrls = galleryImages.slice(0, userUploadPhotoLimit).map(getGalleryImageUrl);
    items.push(...imageUrls);

    // If we have less than 4 items, repeat to fill at least 4 slots
    while (items.length < 4) {
      items.push(...imageUrls);
    }
  }

  const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(
          <FontAwesomeIcon className="w-4 " icon={faStar} color="#FFC107" key={`star-${i}`} />,
        );
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(
          <FontAwesomeIcon
            className="w-4"
            icon={faStarHalfStroke}
            color="#FFC107"
            key={`star-${i}`}
          />,
        );
      } else {
        stars.push(
          <FontAwesomeIcon
            className="w-4"
            icon={faStarRegular}
            color="#FFC107"
            key={`star-${i}`}
          />,
        );
      }
    }

    return stars;
  };

  const cleanHandle = (handle: string) => {
    return handle.replace(/^@/, '');
  };

  const onScrollToDetails = () => {
    descriptionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const cityState = organization.mailing_address
    ? `${organization.mailing_address.city}, ${organization.mailing_address.state}`
    : 'City, State';

  return (
    <div className="hidden md:flex">
      <div className="md:w-[444px] lg:w-[592px]">
        <div className="w-full md:h-[462px] lg:h-[617px] relative">
          <img
            className="w-full h-full object-cover object-center rounded-[24px]"
            src={mainBookingImage}
            alt=""
          />
        </div>
        {items.length > 0 && <BookingSlider images={items} />}
      </div>
      <div className="relative md:w-[306px] lg:w-[408px] border-t border-r border-[#D7D8D3] px-3 lg:px-5 pt-[50px] lg:pr-[58px]">
        <h1 className="text-5xl font-semibold text-black">
          {organization.name || 'Your business'}
        </h1>
        <div className="flex justify-between mt-[22px] font-avenir">
          <div className="flex flex-col justify-between">
            <h5 className="font-medium pb-4">{cityState}</h5>
            {reviewsSummary && (
              <div className="flex items-center justify-center gap-2">
                <div className="flex items-center justify-center pt-[2px]">
                  <p className="font-medium text-[15px] font-avenir">
                    {reviewsSummary.average_rating}
                  </p>
                </div>
                <div className="flex items-center gap-[2px]">
                  {renderStars(reviewsSummary.average_rating)}
                </div>
                <p className="font-medium text-[15px] font-avenir pt-[2px]">
                  ({reviewsSummary.total_reviews})
                </p>
              </div>
            )}
          </div>
          <div></div>
        </div>
        <div className="flex w-full items-start justify-start">
          <button
            onClick={onScrollToDetails}
            className="mt-6 mb-4 px-[54px] py-[12px] bg-blue-status rounded-full font-avenir text-white"
          >
            Book Now
          </button>
        </div>
        <div className="flex items-center flex-wrap gap-2 md:mt-[12px] lg:mt-[12px] font-avenir">
          {organization.website_url && (
            <Link
              to={`//${organization.website_url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full px-[24px] py-[12px] bg-black-alt flex items-center justify-center text-white text-sm"
            >
              Website
            </Link>
          )}

          {userCanUseBusinessReviews && (
            <button
              onClick={onScrollToDetails}
              className="rounded-full px-[24px] py-[12px] bg-black-alt flex items-center justify-center text-white text-sm"
            >
              Reviews
            </button>
          )}
          {organization.phone_number && (
            <a
              href={`tel:${organization.phone_number}`}
              className="rounded-full px-[24px] py-[12px] bg-black-alt flex items-center justify-center text-white text-sm"
            >
              Text Us
            </a>
          )}
        </div>
        {userCanUseBusinessDescription && (
          <div className="md:mt-[72px] lg:mt-[97px] font-avenir">
            <h4 className="text-2xl text-black-text">Description</h4>
            <p className="text-black-text mt-4">{organization.description}</p>
          </div>
        )}
        {/* <button
          onClick={onScrollToDetails}
          className="md:mt-[90px] lg:mt-[121px] px-[54px] py-[12px] bg-blue-status rounded-full font-avenir text-white"
        >
          Book Now
        </button> */}
        <div
          className="absolute top-[37%] right-0 translate-x-1/2 flex flex-col gap-6"
          ref={descriptionRef}
        >
          {organization.instagram_handle && (
            <Link
              to={`//instagram.com/${cleanHandle(organization.instagram_handle)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="w-5 h-5" icon={faInstagram} color="#000" />
            </Link>
          )}
          {organization.facebook_handle && (
            <Link
              to={`//facebook.com/${cleanHandle(organization.facebook_handle)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="w-5 h-5" icon={faFacebookF} color="#000" />
            </Link>
          )}
          {organization.tiktok_handle && (
            <Link
              to={`//tiktok.com/@${cleanHandle(organization.tiktok_handle)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="w-5 h-5" icon={faTiktok} color="#000" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
