import { createContext, useState } from 'react';

interface AppContextType {
  isMenuOpen: boolean;
  isSubMenuOpen?: boolean;
  toggleMenu: () => void;
  toggleSubMenu: () => void;
}

const defaultContextValue: AppContextType = {
  isMenuOpen: false,
  isSubMenuOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleMenu: () => {}, // Empty function as a placeholder
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSubMenu: () => {}, // Empty function as a placeholder
};

// Create context with a default value
export const AppContext = createContext(defaultContextValue);

// Context provider component
export const AppProvider = ({ children }: any) => {
  const [isMenuOpen, setMenuOpen] = useState(false); // Default state
  const [isSubMenuOpen, setSubMenuOpen] = useState(false); // Default state

  // Toggle menu function
  const toggleMenu = () => {
    if (isMenuOpen) {
      setSubMenuOpen(false);
    }
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    if (isMenuOpen) {
      setSubMenuOpen(!isSubMenuOpen);
    }
  };

  // Value to be passed to consuming components
  const value = { isMenuOpen, isSubMenuOpen, toggleMenu, toggleSubMenu };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
