import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { environment } from '../../environments/environment';

export const getContactsCount = async (): Promise<number> => {
  try {
    const session = await fetchAuthSession();
    const userId = session?.tokens?.accessToken?.payload?.sub;
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/contacts/count`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.count;
  } catch (error) {
    return 0;
  }
};

export const getAppointmentsCount = async (): Promise<number> => {
  try {
    const session = await fetchAuthSession();
    const userId = session?.tokens?.accessToken?.payload?.sub;
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/insights/user/${userId}/booking/booking-count?date_range=MTD`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.value;
  } catch (error) {
    return 0;
  }
};

export const getTextMessagesCount = async (servicePhoneNumber: string): Promise<number> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/text/service-phone/${servicePhoneNumber}/text-messages/count`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.count;
  } catch (error) {
    return 0;
  }
};

export const getVoiceMessagesCount = async (servicePhoneNumber: string): Promise<number> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/voice/service-phone/${servicePhoneNumber}/voice-messages/count`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.count;
  } catch (error) {
    return 0;
  }
};
