import { useMemo } from 'react';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { MobileAppMenu } from './MobileAppMenu';
import { MobileHomeMenu } from './MobileHomeMenu';

export const MobileMenuV2 = ({
  toggleMenu,
  isOpen,
}: {
  toggleMenu: () => void;
  isOpen: boolean;
}) => {
  const { menuType, bgColor } = useAppExperience();
  const shouldShowHomeMenu = useMemo(() => {
    return menuType === 'home';
  }, [menuType]);

  return (
    <header className={`bg-[${bgColor}] z-50 px-1 py-3 flex items-center justify-between`}>
      {shouldShowHomeMenu ? (
        <MobileHomeMenu toggleMenu={toggleMenu} isOpen={isOpen} />
      ) : (
        <MobileAppMenu toggleMenu={toggleMenu} isOpen={isOpen} />
      )}
    </header>
  );
};
