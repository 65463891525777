import { PaginationMeta } from '@book-nestor-monorepo/nestor-dtos';
import { Booking, BookingStatus, ContactBookingMethod } from '@book-nestor-monorepo/shared-types';
import {
  faArrowRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { durationString, formatDateString } from '../../libs/utils/date.util';
import { truncateText } from '../../libs/utils/string.util';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

export const BookingsTable: React.FC<{
  bookings: Booking[];
  bookingsMeta: PaginationMeta;
  handleNextBookings: (status: TSelectedStatus) => void;
  handleStatusChange: (status: TSelectedStatus) => void;
  handleBookingClick: (bookingId: string) => void;
  selectedStatus: TSelectedStatus;
  isFiltersOpen: boolean;
  toggleFilters: () => void;
}> = ({
  bookings,
  bookingsMeta,
  handleNextBookings,
  handleStatusChange,
  handleBookingClick,
  selectedStatus,
  isFiltersOpen,
  toggleFilters,
}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
  const user = useContext(AuthContext);

  const getContactDisplayName = (booking: Booking) => {
    const bookingContact = booking?.contact?.name;
    if (bookingContact) {
      return (
        <div className="flex-1 p-4 font-bold font-avenir text-[12px]">
          {truncateText(`${booking.contact?.name} ${booking.contact?.last_name}`, 50)}
        </div>
      );
    }
    return (
      <div className="flex-1 p-4 font-avenir text-[12px]">
        {truncateText(booking.attendees.map((a) => a.name).join(', '), 50)}
      </div>
    );
  };

  const getMobileContactDisplayName = (booking: Booking) => {
    const bookingContact = booking?.contact?.name;
    if (bookingContact) {
      return truncateText(`${booking.contact?.name} ${booking.contact?.last_name}`, 50);
    }
    return truncateText(booking.attendees.map((a) => a.name).join(', '), 50);
  };

  const getEventTypeName = (booking: Booking) => {
    return booking.contact_booking?.internal_event_type_name
      ? booking.contact_booking?.internal_event_type_name
      : booking.event_type.title;
  };

  const hasPaid = (booking: Booking) => {
    return booking.contact_booking?.client_has_paid;
  };

  const reminderSent = (booking: Booking) => {
    return booking.contact_booking?.reminder_window_sent?.length || 0 > 0;
  };

  const isDifficult = (booking: Booking) => {
    return booking.contact?.is_difficult;
  };

  const createdByString = (booking: Booking) => {
    if (!booking.contact_booking) return 'N/A';
    if (booking.contact_booking.booking_method === ContactBookingMethod.PUBLIC_PAGE) {
      return booking.attendees[0]?.name || 'N/A';
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.AI_AGENT) {
      return `${user?.servicePhone?.agent?.name} (agent)`;
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.MANUAL_USER) {
      return `${user?.firstName} (you)`;
    }
    return `${user?.firstName} (you)` || 'N/A';
  };

  return (
    <div className="flex-col lg:bg-white rounded-lg shadow shadow-gray-200 p-2 lg:p-4">
      <div className="flex flex-col w-full items-start justify-between mb-4">
        <h2 className="text-[22px] font-medium font-avenir ">Bookings</h2>
        <div className="flex overflow-x-auto mb-2 hide-scrollbar">
          <button
            onClick={toggleFilters}
            className="flex items-center justify-between text-black font-avenir text-[16px] cursor-pointer font-medium"
          >
            Filters
            {isFiltersOpen ? (
              <FontAwesomeIcon icon={faChevronUp} className="ml-1" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
            )}
          </button>
        </div>
        {isFiltersOpen && (
          <div className="flex">
            <button
              className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.UPCOMING
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.UPCOMING)}
            >
              Upcoming
            </button>
            <button
              className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.PAST
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.PAST)}
            >
              Past
            </button>
            <button
              className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.CANCELED
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.CANCELED)}
            >
              Canceled
            </button>
          </div>
        )}
      </div>

      <div className="w-full h-full lg:bg-[#F5F5F7] lg:rounded-lg">
        {/* Mobile view (up to lg breakpoint) */}
        <div className="lg:hidden space-y-2">
          {bookings.map((booking, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`p-4 bg-white ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <div className="w-full" onClick={() => handleBookingClick(booking.id)}>
                  <div className="flex flex-col flex-1">
                    <div className="flex justify-between">
                      <div className="flex flex-col items-start">
                        {' '}
                        <div className="font-bold font-avenir text-[12px]">
                          {getMobileContactDisplayName(booking)}
                        </div>
                        <div className="flex font-avenir text-[12px]">
                          <div className="flex flex-col items-start">
                            {formatDateString(new Date(booking.start_time).toISOString())}
                            <div className="flex flex-row items-center">
                              {new Date(booking.start_time).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                              })}{' '}
                              -{' '}
                              {new Date(booking.end_time).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="flex font-avenir font-semibold text-[12px]">
                          {getEventTypeName(booking)}
                        </div>
                        <div className="flex flex-row items-center font-avenir text-[12px]">
                          {durationString(booking)}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start mt-4">
                      <div className="flex font-avenir text-[12px]">
                        <span className="font-bold mr-1">Created At: </span>
                        {new Date(booking.contact_booking?.created_at as string).toLocaleTimeString(
                          'en-US',
                          {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          },
                        )}
                      </div>
                      <div className="flex font-avenir text-[12px] mb-4">
                        <span className="font-bold mr-1">Created By: </span>
                        {createdByString(booking)}
                      </div>
                      <div className="flex font-avenir text-[12px] justify-between w-full">
                        <div className="flex flex-row items-start">
                          {hasPaid(booking) && (
                            <div className="flex h-[19px] w-auto items-center p-2 bg-green-100 rounded-full mr-1">
                              <span className="text-[10px] font-avenir text-green-600">Paid</span>
                            </div>
                          )}
                          {reminderSent(booking) && (
                            <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                              <span className="text-[10px] font-avenir">Reminder Sent</span>
                            </div>
                          )}
                          {isDifficult(booking) && (
                            <div className="flex h-[19px] w-auto items-center p-2 bg-red-100 rounded-full mr-1">
                              <span className="text-[10px] font-avenir text-red-600">
                                Difficult
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-center">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={`text-[12px] text-gray-600 transform transition-transform duration-200 ${
                              expandedRows[index] ? 'rotate-90' : ''
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop view (lg and above) */}
        <div className="hidden lg:block">
          <div className="min-w-full w-full p-2">
            <div className="flex text-[14px] font-inter text-gray-600 text-left">
              <div className="flex-1 p-4">Contact</div>
              <div className="flex-1 p-4">Date</div>
              <div className="flex-1 p-4">Service</div>
              <div className="flex-1 p-4">Tags</div>
              <div className="flex-1 p-4">Created</div>
              {/* <div className="flex-1 p-0"></div> */}
            </div>
            <div className="space-y-2 mt-2 text-left">
              {bookings.map((booking, index) => (
                <div
                  key={index}
                  className="flex flex-col"
                  onClick={() => handleBookingClick(booking.id)}
                >
                  <div
                    className={`flex ${
                      index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'
                    } ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}  cursor-pointer`}
                  >
                    {getContactDisplayName(booking)}

                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex flex-col items-start">
                        {formatDateString(new Date(booking.start_time).toISOString())}
                        <div className="flex flex-row items-center">
                          {new Date(booking.start_time).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                          })}{' '}
                          -{' '}
                          {new Date(booking.end_time).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex flex-col items-start">
                        <span className="font-bold">{getEventTypeName(booking)}</span>
                        <div className="flex flex-row items-center">{durationString(booking)}</div>
                      </div>
                    </div>

                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex items-center justify-start w-full">
                        {hasPaid(booking) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-green-100 rounded-full mr-1">
                            <span className="text-[10px] font-avenir text-green-600">Paid</span>
                          </div>
                        )}
                        {reminderSent(booking) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Reminder Sent</span>
                          </div>
                        )}
                        {isDifficult(booking) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-red-100 rounded-full mr-1">
                            <span className="text-[10px] font-avenir text-red-600">Difficult</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex flex-col items-start">
                        {new Date(booking.contact_booking?.created_at as string).toLocaleTimeString(
                          'en-US',
                          {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          },
                        )}
                        <div className="flex flex-row items-center">
                          {' '}
                          Created By: {createdByString(booking)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-2 items-center w-full">
        {bookingsMeta.hasPrevious && (
          <div className="flex justify-between items-center">
            <div className="flex-1 pt-2 items-center justify-center">
              <button
                className="text-[12px] font-avenir font-medium"
                onClick={() => handleNextBookings(selectedStatus)}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                Previous
              </button>
            </div>
          </div>
        )}
        {bookingsMeta.hasMore && (
          <div className="flex justify-between items-center">
            <div className="flex-1 pt-2 items-center justify-center">
              <button
                className="text-[12px] font-avenir font-medium"
                onClick={() => handleNextBookings(selectedStatus)}
              >
                Next <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
