import { EventType } from '@book-nestor-monorepo/shared-types';
import { faArrowRight, faInfoCircle, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { truncateText } from '../../libs/utils/string.util';

export const ServicesTable: React.FC<{
  services: EventType[];
  handleServiceClick: (serviceId: string) => void;
  handleCreateNewContactClick: () => void;
  handleServiceCategoriesClick: () => void;
}> = ({
  services,
  handleServiceClick,
  handleCreateNewContactClick,
  handleServiceCategoriesClick,
}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  return (
    <div className="flex-col lg:bg-white rounded-lg shadow shadow-gray-200 p-2 lg:p-4">
      <div className="flex w-full items-center justify-between mb-4">
        <div className="flex flex-col items-start gap-1">
          <h2 className="text-[22px] font-medium font-avenir ">Services</h2>
          <span
            className="text-[12px] lg:text-[14px] font-avenir text-gray-600 underline cursor-pointer"
            onClick={handleServiceCategoriesClick}
          >
            Manage Service Categories
          </span>
        </div>

        <button
          className=" w-auto bg-black text-white rounded-full text-[14px] font-avenir font-normal px-4 py-2 hidden lg:block"
          onClick={handleCreateNewContactClick}
        >
          Create a new Service
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
        <button
          className=" w-auto bg-black text-white rounded-full text-[12px] font-avenir font-normal px-4 py-2 block lg:hidden"
          onClick={handleCreateNewContactClick}
        >
          Create
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
      </div>

      <div className="w-full h-full lg:bg-[#F5F5F7] lg:rounded-lg">
        {/* Mobile view (up to lg breakpoint) */}
        <div className="lg:hidden space-y-2">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`p-4 bg-white ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <div className="w-full" onClick={() => handleServiceClick(service.id as string)}>
                  <div className="flex flex-row flex-2">
                    <div className="flex flex-col flex-[2]">
                      <div className="flex font-bold text-gray-600 font-avenir text-[12px]">
                        {truncateText(service.title as string, 35)}
                      </div>
                      {service.description ? (
                        <div className="text-[12px] text-gray-600 font-inter text-left bg-gray-100 py-2 px-4 rounded-md mt-1">
                          {truncateText(service.description || 'No Description Yet', 150)}
                        </div>
                      ) : (
                        <div className="text-[12px] text-gray-400  font-avenir text-left bg-gray-100 py-2 px-4 rounded-md mt-1">
                          A good description generates more bookings{' '}
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-[2px] text-[12px]" />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col flex-[1] ml-1 justify-start items-end">
                      <div className="flex font-avenir text-[12px]">
                        {service.duration_mins} mins
                      </div>
                      <div className="flex font-avenir text-[12px] mt-[4px]">${service.price}</div>
                      <div className="flex font-avenir text-[12px] mt-[4px]"></div>
                    </div>
                  </div>
                  <div className="flex w-full items-end justify-between mt-6">
                    <div className="flex items-center justify-start h-full">
                      {service.categories.map((category) => (
                        <div
                          className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1"
                          key={category.id}
                        >
                          <span className="text-[10px] font-avenir">{category.name}</span>
                        </div>
                      ))}
                    </div>
                    <div className="flex h-full items-end justify-end">
                      <div className=" bg-transparent flex items-center justify-center cursor-pointer">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className={`text-[12px] text-gray-600 transform transition-transform duration-200 ${
                            expandedRows[index] ? 'rotate-90' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop view (lg and above) */}
        <div className="hidden lg:block">
          <div className="min-w-full w-full p-2">
            <div className="flex text-[14px] font-inter text-gray-600 text-left">
              <div className="flex-1 p-4">Name</div>
              <div className="flex-1 p-4">Duration</div>
              <div className="flex-1 p-4">Price</div>
              <div className="flex-1 p-4">Booking Notice</div>
              <div className="flex-1 p-4">Categories</div>
            </div>
            <div className="space-y-2 mt-2 text-left">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="flex flex-col"
                  onClick={() => handleServiceClick(service.id as string)}
                >
                  <div
                    className={`flex ${
                      index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'
                    } ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}  cursor-pointer`}
                  >
                    <div className="flex-1 p-4 font-bold font-avenir text-[12px]">
                      {truncateText(service.title as string, 35)}
                    </div>

                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {service.duration_mins} mins
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px] justify-start">
                      ${service.price}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px] justify-start">
                      {service.minimum_booking_notice_mins} mins
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex items-center justify-start w-full">
                        {service.categories.map((category) => (
                          <div
                            className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1"
                            key={category.id}
                          >
                            <span className="text-[10px] font-avenir">{category.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
