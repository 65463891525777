import { AgentMenuDrawerTabs } from '../../components/v2/AgentMenuDrawer/AgentMenuDrawerTabs';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';

export const AgentPage = () => {
  const { bgColor } = useAppExperience();
  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex h-full">
        <div className="flex-1 flex flex-col h-full pt-2 ml-0">
          <div className="flex-col px-1">
            <div>
              <AgentMenuDrawerTabs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
