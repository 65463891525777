import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  SelectChangeEvent,
  Card,
} from '@mui/material';
import { TaskNames } from '@book-nestor-monorepo/shared-types';
import { Cache } from 'aws-amplify/utils';
import { AuthContext } from '../../contexts/authContext';
import { useOnboarding } from '../../contexts/onboardingContext';
import { OnboardingLayout } from '../layouts/onboarding-layout';
import NextButtons from '../../components/onboarding/NextButtons';
import { patchUser } from '../../libs/services/user';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: '#F5F5F5',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiFilledInput-root': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiFilledInput-root': {
    //         backgroundColor: '#FFFFFF',
    //       },
    //     },
    //   },
    // },
  },
});

const inputStyles = {
  mb: 3,
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
  },
};

export default function IntroSplash() {
  const [firstName, setFirstName] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [otherIndustry, setOtherIndustry] = useState('');
  const [website, setWebsite] = useState('');

  const authContext = useContext(AuthContext);
  const { completeTask, setCurrentRoute, onboardingState } = useOnboarding();
  const [nameError, setNameError] = useState(false);
  const [industryError, setIndustryError] = useState(false);
  const [otherIndustryError, setOtherIndustryError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const firstName =
        authContext?.firstName ||
        authContext?.user?.first_name ||
        (await Cache.getItem('login-first-name-set'));

      setFirstName(firstName || '');
      setSelectedIndustry(authContext?.user?.industry || '');
      setOtherIndustry(authContext?.user?.other_industry || '');
      setWebsite(authContext?.user?.website || '');
    };

    fetchData();
  }, [authContext?.firstName]);

  useEffect(() => {
    setCurrentRoute();
  }, [onboardingState]);

  const getStartedClicked = async () => {
    let hasError = false;

    if (firstName.trim() === '') {
      setNameError(true);
      hasError = true;
    }

    // if (selectedIndustry === '') {
    //   setIndustryError(true);
    //   hasError = true;
    // }

    // if (selectedIndustry === 'other' && otherIndustry.trim() === '') {
    //   setOtherIndustryError(true);
    //   hasError = true;
    // }

    if (!hasError) {
      await patchUser(authContext?.user?.id as string, {
        first_name: firstName,
        industry: selectedIndustry,
        other_industry: otherIndustry,
        website: website,
      });
      completeTask(TaskNames.welcome);
    }
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFirstName(value);
    if (value.trim() !== '') {
      setNameError(false);
    }
  };

  const handleIndustryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedIndustry(value);
    setIndustryError(false);
    if (value !== 'other') {
      setOtherIndustry('');
      setOtherIndustryError(false);
    }
  };

  const handleOtherIndustryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setOtherIndustry(value);
    if (value.trim() !== '') {
      setOtherIndustryError(false);
    }
  };

  const industries = [
    { value: 'health', label: 'Health & Wellness' },
    { value: 'mental-health', label: 'Mental Health & Counseling' },
    { value: 'beauty', label: 'Beauty & Personal Care' },
    { value: 'education', label: 'Education' },
    { value: 'creative', label: 'Creative Services' },
    { value: 'fitness', label: 'Fitness' },
    { value: 'other', label: 'Other' },
  ];

  const welcomeText = () => {
    if (authContext?.user?.first_name) {
      return (
        <>
          Is your name <strong>{authContext.user.first_name}</strong>? If not, change it below
        </>
      );
    }
    return "Great to meet you! What's your name?";
  };

  return (
    <ThemeProvider theme={theme}>
      <OnboardingLayout>
        <Container
          maxWidth="sm"
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: 4,
          }}
        >
          <Box>
            {/* <h6 className="text-black-text pt-[30px]">INTRODUCTION</h6> */}
            <h4 className="font-inter text-black-text text-3xl 2xl:text-4xl mt-[96px] mb-[64px] ">
              {welcomeText()}
            </h4>
            <TextField
              variant="filled"
              required
              label="First Name:"
              fullWidth
              value={firstName}
              onChange={handleFirstNameChange}
              error={nameError}
              helperText={nameError ? 'Name is required' : ''}
              sx={{ ...inputStyles, paddingRight: '40px', backgroundColor: 'transparent' }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <div className="flex flex-col w-full mb-8">
              <Card
                sx={{
                  borderRadius: '10px',
                  paddingX: '20px',
                  paddingBottom: '20px',
                  boxShadow: 'none',
                }}
              >
                <FormControl fullWidth variant="filled" sx={{ ...inputStyles, mt: 4 }}>
                  <InputLabel error={industryError}>What industry best fits you?*</InputLabel>
                  <Select
                    required
                    value={selectedIndustry}
                    onChange={handleIndustryChange}
                    error={industryError}
                  >
                    {industries.map((industry) => (
                      <MenuItem key={industry.value} value={industry.value}>
                        {industry.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {industryError && (
                    <Typography color="error" variant="caption">
                      Industry is required
                    </Typography>
                  )}
                </FormControl>
                {selectedIndustry === 'other' && (
                  <TextField
                    label="Please specify your industry"
                    variant="filled"
                    required
                    fullWidth
                    value={otherIndustry}
                    onChange={handleOtherIndustryChange}
                    error={otherIndustryError}
                    helperText={otherIndustryError ? 'Please specify your other industry' : ''}
                    sx={inputStyles}
                  />
                )}
                <TextField
                  label="Website (optional)"
                  variant="filled"
                  fullWidth
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  sx={inputStyles}
                />
              </Card>
            </div> */}
            <NextButtons onSkipStep={getStartedClicked} nextStep={getStartedClicked} skipLight />
          </Box>
        </Container>
      </OnboardingLayout>
    </ThemeProvider>
  );
}
