import React, { useState, useEffect } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ExpandableCardActiveAdornmentProps } from './ExpandableCardListWithActive';

export interface ExpandableCardProps {
  title: string;
  id: string;
  content: React.ReactNode;
  isExpanded: boolean;
  onToggle?: () => void;
  isActive?: boolean;
  activeAdornment?: ExpandableCardActiveAdornmentProps;
  bodyText?: string;
  onSelect?: () => void;
  sxProps?: SxProps<Theme> | undefined;
}

export const ExpandableCard = ({
  title,
  content,
  isExpanded,
  onToggle,
  isActive = false,
  activeAdornment,
  bodyText,
  onSelect,
  sxProps,
}: ExpandableCardProps) => {
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const handleToggle = () => {
    setExpanded(!expanded);
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 'md',
        padding: '8px',
        bgcolor: isActive ? '#FFFFFF' : '#E8E8E8',
        borderRadius: '24px',
        border: isActive ? '2px solid #3FAB4A' : '2px solid #E2E2E2',
        boxShadow: 'none',
        ...sxProps,
      }}
    >
      <CardActions className="flex justify-between" onClick={handleToggle}>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between w-full">
            <h6 className="font-avenir text-[32px] text-black pl-2">{title}</h6>
            <IconButton aria-expanded={expanded} aria-label="show more">
              {expanded ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="sm"
                  color="#000000"
                  style={{ fontWeight: 100 }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size="sm"
                  color="#000000"
                  style={{ fontWeight: 1000 }}
                />
              )}
            </IconButton>
          </div>

          <div className="flex flex-row m-2">
            {isActive ? (
              <>
                <div className="flex justify-between items-center w-full">
                  <strong>{bodyText}</strong>
                </div>
                {activeAdornment?.shouldShow && (
                  <div
                    className={`w-[78px] h-[24px] flex justify-center items-center bg-[#D2EAD5]`}
                  >
                    <div className="text-center text-black font-inter text-[12px] ">
                      {activeAdornment?.activeText}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-row justify-between w-full">
                <div className="flex justify-center items-center mr-2">
                  <strong>{bodyText}</strong>
                </div>
                {activeAdornment?.shouldShow && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelect?.();
                    }}
                    className={`h-8 flex justify-center items-center bg-[#CCCCCC] rounded-full w-auto px-6 py-2 cursor-pointer`}
                  >
                    <div className="text-center text-black font-inter text-[12px] ">
                      {activeAdornment?.inactiveText}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout={0} unmountOnExit>
        <CardContent>
          <Typography paragraph>{content}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};
