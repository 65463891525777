import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TodayCalendar from '../components/app/todayCalendar';
import SwipeContainer from '../components/common/SwipeContainer/SwipeContainer';
import { useOnboarding } from '../contexts/onboardingContext';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

export default function Home() {
  const { setCurrentRoute, onboardingState } = useOnboarding();

  const [noAppointments, setNoAppointments] = useState(false);
  const [swipeOpen, setSwipeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(8, 10));
  const selectedDateRef = useRef(new Date().toISOString().slice(8, 10));
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const onWindowResize = () => {
    if (location.pathname === '/' && window.innerWidth <= 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
      document.body.style.overflow = 'unset';
    };
  }, [location]);

  useEffect(() => {
    setIsLoading(onboardingState.isLoading);
    setCurrentRoute();
  }, [onboardingState]);

  const handleSwipeUp = () => {
    setSwipeOpen(!swipeOpen);
  };

  const handleDateSelect = (date: string) => {
    if (date.length === 1) {
      date = '0' + date;
    }
    setSelectedDate(date);
    selectedDateRef.current = date;
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <ExperimentalAppLayout handleDateSelect={handleDateSelect}>
      <SwipeContainer transparentBg defaultHeight={500} maxHeight={800} isOpened={swipeOpen}>
        <div
          className={`rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full bg-gray-secondary`}
        >
          <TodayCalendar
            swipeUp={handleSwipeUp}
            gray-secondary
            selectedDate={selectedDate}
            handleDateSelect={handleDateSelect}
            selectedDateRef={selectedDateRef}
          />
        </div>
      </SwipeContainer>
    </ExperimentalAppLayout>
  );
}
