export interface Review {
  id?: string;
  contact_id?: string;
  contact_name?: string;
  deliver_email?: boolean;
  review_url?: string;
  reviewee_name?: string;
  contact_email: string;
  target_user_id?: string;
  rating?: number;
  review_text?: string;
  created_at?: string;
  updated_at?: string;
  status?: ReviewStatus;
  is_public?: boolean;
}

export enum ReviewStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
}

export interface ReviewSummary {
  average_rating: number;
  total_reviews: number;
  top_reviews: Review[];
}
