'use client';

import { Contact } from '@book-nestor-monorepo/shared-types';
import { faCheck, faChevronDown, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { ContactDetailCard } from '../../components/v2/Contact/ContactDetailCard';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useContacts } from '../../contexts/v2/contactsContext';

export default function ContactComboBox({
  contacts,
  selectedContact,
  onSelect,
  preLoadContactId,
}: {
  contacts: Contact[];
  selectedContact: Contact | null;
  onSelect: (contact: Contact) => void;
  preLoadContactId?: string;
}) {
  const [query, setQuery] = useState('');
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const latestContactRef = useRef<string | null>(null);
  const [latestCreatedContactId, setLatestCreatedContactId] = useState<string | null>(null);
  const { fetchContacts } = useContacts();
  const filteredContacts =
    query === ''
      ? contacts
      : contacts.filter((contact) => {
          return contact.name.toLowerCase().includes(query.toLowerCase());
        });

  const contactDisplayName = (contact: Contact) => {
    if (!contact) return '';
    return `${contact?.name} ${contact?.last_name}`;
  };

  const handleSelect = (contact: Contact) => {
    setQuery('');
    onSelect(contact);
  };

  const openContactModal = () => {
    openMuiModal(
      <ContactDetailCard
        onSuccess={async (contactId?: string) => {
          if (contactId) {
            latestContactRef.current = contactId; // Set the ref before fetching
            setLatestCreatedContactId(contactId);
          }
          await fetchContacts(true);
          closeMuiModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const handleNewContact = async () => {
      if (contacts.length > 0 && latestCreatedContactId) {
        const newContact = contacts.find((contact) => contact.id === latestCreatedContactId);
        if (newContact && latestContactRef.current === latestCreatedContactId) {
          onSelect(newContact);
          // Clear refs after successful selection
          latestContactRef.current = null;
          setLatestCreatedContactId(null);
        }
      }
    };

    handleNewContact();
  }, [contacts, latestCreatedContactId, onSelect]);

  return (
    <div className="">
      <div
        className={`flex items-center justify-end cursor-pointer `}
        onClick={preLoadContactId ? undefined : openContactModal}
      >
        <div
          className={`w-6 h-6 flex items-center justify-center bg-green-600 rounded-full ${
            preLoadContactId ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <FontAwesomeIcon icon={faPlus} className="text-white text-[16px]" />
        </div>
      </div>
      <Combobox
        as="div"
        value={selectedContact}
        disabled={!!preLoadContactId}
        onChange={(contact) => {
          setQuery('');
          handleSelect(contact as Contact);
        }}
      >
        <Label className="block text-sm/6 font-medium text-gray-900 font-inter">
          Select Contact
        </Label>

        <div className={`relative mt-2 ${preLoadContactId ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <ComboboxInput
            className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-300 sm:text-sm/6"
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery('')}
            displayValue={(contact) => contactDisplayName(contact as Contact)}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <FontAwesomeIcon
              icon={faChevronDown}
              className="size-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>

          {filteredContacts.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredContacts.map((contact) => (
                <ComboboxOption
                  key={contact.id}
                  value={contact}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                >
                  <div className="flex">
                    <span className="truncate group-data-[selected]:font-semibold">
                      {contactDisplayName(contact)}
                    </span>
                    <span className="ml-2 truncate text-gray-500 group-data-[focus]:text-gray-400">
                      {contact.phone_number}
                    </span>
                  </div>

                  <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-600 group-data-[selected]:flex group-data-[focus]:text-gray-900">
                    <FontAwesomeIcon icon={faCheck} className="size-5" aria-hidden="true" />
                  </span>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    </div>
  );
}
