import { AuthContext } from '../../contexts/authContext';
import { useContext, useEffect, useState } from 'react';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import {
  SubscriptionProduct,
  SubscriptionProductPrice,
  PaymentMethod,
} from '@book-nestor-monorepo/shared-types';
import { ExpandableCardProps } from '../../components/common/ExpandableCard/ExpandableCard';
import { ProductContent } from '../../components/payments/stripeProductDescriptions/StripeProductContent';
import { useStripePayment } from '../../contexts/paymentContext';
import {
  getSubscriptionProducts,
  getPaymentMethods,
  changeSubscription,
  createSubscription,
} from '../../libs/services/subscription.service';
import { faTag, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import ExpandableCardListWithActive from '../../components/common/ExpandableCard/ExpandableCardListWithActive';
import PromoCodeInput from '../../components/app/v2/promoCodeInput';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { environment } from '../../environments/environment';
import { getUserById } from '../../libs/services/user';

const lookup_keys = 'subscription_v2';

export const SubscriptionV2 = () => {
  const { bgColor } = useAppExperience();
  const authContext = useContext(AuthContext);
  const { stripe } = useStripePayment();

  const [products, setProducts] = useState<SubscriptionProduct[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<SubscriptionProduct | undefined>();
  const [selectedPrice, setSelectedPrice] = useState<SubscriptionProductPrice | undefined>();

  const [currentProduct, setCurrentProduct] = useState<SubscriptionProduct | undefined>();
  const [currentPrice, setCurrentPrice] = useState<SubscriptionProductPrice | undefined>();

  const [productCardsForDisplay, setProductCardsForDisplay] = useState<ExpandableCardProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [isSubscriptionCreated, setIsSubscriptionCreated] = useState(false);

  const [isMonthly, setIsMonthly] = useState(false); // YEARLY by default
  const [showYearly, setShowYearly] = useState(false);

  const [isDowngrade, setIsDowngrade] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);

  useEffect(() => {
    if (!selectedPrice || !currentPrice || !currentProduct) return;

    const _currentPrice =
      currentPrice.recurringInterval === 'month' ? currentPrice.price * 12 : currentPrice.price;
    const _selectedPrice =
      selectedPrice.recurringInterval === 'month' ? selectedPrice.price * 12 : selectedPrice.price;

    const _isDowngrade = _currentPrice > _selectedPrice;
    const _isUpgrade = !_isDowngrade;

    setIsDowngrade(_isDowngrade);
    setIsUpgrade(_isUpgrade);
  }, [selectedPrice]);

  const fetchData = async () => {
    if (!authContext.user) return;

    setIsLoading(true);
    setIsSubscriptionCreated(false);

    const products = await getSubscriptionProducts();
    setProducts(products);

    const defaultProduct = products.find((product) => product.isDefault);
    const defaultPrice = defaultProduct?.monthlyPrice;

    const currentPriceId = authContext.user?.external_subscription?.items[0]?.price.id;
    let currentProduct: SubscriptionProduct | undefined;
    let currentPrice: SubscriptionProductPrice | undefined;
    for (const product of products) {
      if (product.monthlyPrice?.id === currentPriceId) {
        currentProduct = product;
        currentPrice = product.monthlyPrice;
        break;
      } else if (product.yearlyPrice?.id === currentPriceId) {
        currentProduct = product;
        currentPrice = product.yearlyPrice;
        break;
      }
    }
    setCurrentProduct(currentProduct || defaultProduct);
    setCurrentPrice(currentPrice || defaultPrice);

    const paymentMethods = await getPaymentMethods();
    setDefaultPaymentMethod(paymentMethods.find((method) => method.isDefault));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const changeSubscriptionAction = async (newProduct: SubscriptionProductPrice) => {
  const changeSubscriptionAction = async (
    newProduct: SubscriptionProductPrice,
    paymentMethod?: PaymentMethod,
    promoCode?: string,
  ) => {
    setIsLoading(true);

    if (authContext.user?.external_payment_customer) {
      await changeSubscription(newProduct.id, paymentMethod?.id, promoCode);
    } else {
      await createSubscription(paymentMethod?.id as string, newProduct.id, promoCode);
    }

    setIsSubscriptionCreated(true);
    const user = await getUserById(authContext.user?.id as string);
    if (user && authContext.updateLoggedInUser) {
      await authContext.updateLoggedInUser(user);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isSubscriptionCreated) return;

    // refresh the user's session to get the permissions
    authContext.updateSession && authContext.updateSession();

    setCurrentProduct(selectedProduct);
    setCurrentPrice(selectedPrice);
    setSelectedProduct(undefined);
    setSelectedPrice(undefined);
  }, [isSubscriptionCreated]);

  useEffect(() => {
    if (products.length > 0) {
      const mappedProductsForDisplay = mapProductsToCards(products);
      setProductCardsForDisplay(mappedProductsForDisplay);
    }
  }, [products, isMonthly]);

  const formatPriceToDisplay = (priceObject?: SubscriptionProductPrice): string => {
    if (!priceObject) return '';

    const _isMonthly = priceObject.recurringInterval === 'month';
    const price = _isMonthly ? priceObject.price / 100 : priceObject.price / 100 / 12;
    if (price === 0) return '';
    return price.toFixed(2);
  };

  const mapProductsToCards = (products: SubscriptionProduct[]): ExpandableCardProps[] => {
    const lookupKeys = environment.stripe.lookup_keys;
    return products
      .filter((product) => {
        const priceObject = isMonthly ? product.monthlyPrice : product.yearlyPrice;
        return lookupKeys.includes(priceObject?.lookupKey || '');
      })
      .map((product) => {
        const priceObject = isMonthly ? product.monthlyPrice : product.yearlyPrice;
        const priceToDisplay = formatPriceToDisplay(priceObject);
        const bodyText = priceToDisplay === '' ? 'Always Free' : `$${priceToDisplay}/mo`;

        return {
          id: product.id,
          title: product.name,
          content: <ProductContent productType={product.type} />,
          isExpanded: currentPrice?.id === priceObject?.id,
          bodyText: bodyText,
          onSelect: () => onSelect(product, priceObject),
        };
      });
  };

  const onSelect = (product: SubscriptionProduct, price?: SubscriptionProductPrice) => {
    setSelectedProduct(product);
    setSelectedPrice(price);
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#DADCDC" />;
  }

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full h-auto items-start pl-4">
          <span className="text-[16px] font-avenir text-white">Your subscription</span>
        </div>
        <div className="flex w-full h-full items-start px-1 pt-4">
          <div className="h-full px-2 flex flex-col justify-between overflow-x-scroll hide-scrollbar w-full ">
            {!selectedPrice ? (
              // Select Price
              <div className="w-full h-full bg-transparent rounded-t-[32px] pr-2 mt-8">
                <div className="flex flex-row gap-4 justify-between items-center pr-2">
                  {showYearly && (
                    <div className="flex justify-end items-center border-4 border-gray-200 rounded-full bg-gray-200 ">
                      <button
                        className={`px-6 py-3 text-[12px] font-avenir leading-4  rounded-full ${!isMonthly ? 'bg-black text-white shadow-sm ' : 'bg-gray-200 text-black'}`}
                        onClick={() => setIsMonthly(false)}
                      >
                        Annually
                      </button>
                      <button
                        className={`px-6 text-[12px] font-avenir py-3 leading-4 rounded-full ${isMonthly ? 'bg-black text-white shadow-sm ' : 'bg-gray-200 text-black'}`}
                        onClick={() => setIsMonthly(true)}
                      >
                        Monthly
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-4 mt-[30px]">
                  <ExpandableCardListWithActive
                    cards={productCardsForDisplay}
                    activeCardId={currentProduct?.id as string}
                    activeAdornment={{
                      shouldShow: true,
                      activeText: 'Active',
                      inactiveText: 'Select',
                    }}
                  />
                </div>
              </div>
            ) : (
              // Upgrade/Downgrade
              <div className="w-full h-full bg-white rounded-t-[32px] px-4 pr-2 py-6 my-8">
                <div className="flex flex-col">
                  <h5 className="font-inter text-[24px] leading-[24px] ">
                    {isDowngrade ? 'Downgrade' : 'Upgrade'} to {selectedProduct?.name}
                  </h5>

                  <span
                    className="font-inter text-[14px]  text-blue-500 underline cursor-pointer mt-1"
                    onClick={() => {
                      setSelectedPrice(undefined);
                      setSelectedProduct(undefined);
                    }}
                  >
                    Change Product
                  </span>
                </div>

                {selectedProduct?.monthlyPrice.price === 0 &&
                selectedProduct?.yearlyPrice.price === 0 ? (
                  <div className="flex flex-row w-full justify-center">
                    <button
                      type="button"
                      disabled={isLoading}
                      className="
                mt-6
                bg-[#222222]
                disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed
                text-white font-bold py-2 px-4 rounded-full w-full
              "
                      onClick={() => {
                        changeSubscriptionAction(selectedProduct?.monthlyPrice);
                      }}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} color="white" spin />
                      ) : (
                        'Get it for free'
                      )}
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col mt-6">
                      <h6 className="font-avenir text-[18px] mb-4">1. Choose Billing Cycle</h6>
                    </div>

                    <div className="flex flex-col">
                      {/* Monthly */}
                      <div className="flex flex-row items-center mb-2 border-[1px] border-gray-200 rounded-[12px] p-4">
                        <input
                          type="radio"
                          name="billingCycle"
                          className="mr-2"
                          checked={selectedPrice?.id === selectedProduct?.monthlyPrice?.id}
                          value={selectedProduct?.monthlyPrice?.id}
                          onChange={(e) => {
                            // console.log('onChange monthly =>', e.target.value);
                            setSelectedPrice(selectedProduct?.monthlyPrice);
                          }}
                        />
                        <div className="flex flex-col">
                          <span className="font-inter font-bold">Monthly</span>
                          <span className="text-sm font-normal font-inter">{`$${formatPriceToDisplay(selectedProduct?.monthlyPrice)}/month`}</span>
                        </div>
                      </div>

                      {/* Yearly */}
                      <div className="flex flex-row items-center border-[1px] border-gray-200 rounded-[12px] p-4 relative">
                        <input
                          type="radio"
                          name="billingCycle"
                          className="mr-2"
                          checked={selectedPrice?.id === selectedProduct?.yearlyPrice.id}
                          value={selectedProduct?.yearlyPrice.id}
                          onChange={(e) => {
                            // console.log('onChange yearly =>', e.target.value);
                            setSelectedPrice(selectedProduct?.yearlyPrice);
                          }}
                        />
                        <div className="flex flex-col">
                          <span className="font-inter font-bold">Yearly</span>
                          <span className="text-sm font-normal font-inter">{`$${formatPriceToDisplay(selectedProduct?.yearlyPrice)}/month`}</span>
                        </div>

                        <div className="absolute top-1 right-1 rounded-lg bg-green-500 text-white text-xs font-bold px-2 py-1 ">
                          SAVE $
                          {(
                            ((selectedProduct?.monthlyPrice.price || 0) * 12 -
                              (selectedProduct?.yearlyPrice.price || 0)) /
                            100
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mt-6">
                      <h6 className="font-avenir text-[18px] mb-4">2. Payment Method</h6>
                    </div>

                    {selectedProduct && stripe && (
                      <Elements stripe={stripe}>
                        <CardInfoForm
                          stripePromise={stripe}
                          selectedPrice={selectedPrice}
                          changeSubscriptionAction={changeSubscriptionAction}
                        />
                      </Elements>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface CardInfoFormProps {
  stripePromise: any;
  // selectedProduct?: SubscriptionProduct;
  selectedPrice?: SubscriptionProductPrice;
  // setIsSubscriptionCreated: any;
  changeSubscriptionAction: any;
}

function CardInfoForm(props: CardInfoFormProps) {
  const { stripe } = useStripePayment();

  const { stripePromise, selectedPrice, changeSubscriptionAction } = props;

  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements || !selectedPrice) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      try {
        changeSubscriptionAction(selectedPrice, paymentMethod, promoCode);
      } catch (err: any) {
        console.log('Subscription created error =>', err);
        const message = err?.response?.data?.message || 'Error';
        setMessage(message);
      }

      setIsLoading(false);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Inter", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disableLink: true,
  };

  return stripePromise ? (
    <form onSubmit={handleSubmit} className="w-full p-4 bg-white md:bg-transparent">
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="flex flex-row items-center mt-4">
        <PromoCodeInput onPromoCodeChange={setPromoCode} />
      </div>

      <div className="flex flex-row gap-2">
        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || !selectedPrice}
          className="
                mt-4
                bg-black
                disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed
                text-white font-avenir text-[18px] py-2 px-4 rounded-full w-full
              "
        >
          {isLoading ? <FontAwesomeIcon icon={faSpinner} color="white" spin /> : 'Purchase'}
        </button>
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  ) : null;
}
