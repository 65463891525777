import { EventType } from '@book-nestor-monorepo/shared-types';
import { useEffect, useState } from 'react';
import LoadingDots from '../../components/loading';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { getEventTypes } from '../../libs/services/event-type';
import { ServiceCategoryDrawer } from './right-drawer-components/service-category-drawer';
import { ServiceDetailDrawer } from './right-drawer-components/service-detail-drawer';
import { ServicesTable } from './services-table';

export const ServicesListV2 = () => {
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const { bgColor } = useAppExperience();
  const [services, setServices] = useState<EventType[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleCreateNewContactClick = () => {
    if (isOpen) {
      setDrawerContent(
        <ServiceDetailDrawer serviceId={'new'} onRefresh={onRefresh} toggleDrawer={toggleDrawer} />,
        {
          darkMode: true,
        },
      );
    } else {
      setDrawerContent(
        <ServiceDetailDrawer serviceId={'new'} onRefresh={onRefresh} toggleDrawer={toggleDrawer} />,
        {
          darkMode: true,
        },
      );
      toggleDrawer();
    }
  };

  const handleServiceCategoriesClick = () => {
    if (isOpen) {
      setDrawerContent(<ServiceCategoryDrawer />, {
        darkMode: false,
      });
    } else {
      setDrawerContent(<ServiceCategoryDrawer />, {
        darkMode: false,
      });
      toggleDrawer();
    }
  };

  const onRefresh = async () => {
    await loadData();
  };

  const handleServiceClick = (serviceId: string) => {
    if (isOpen) {
      setDrawerContent(
        <ServiceDetailDrawer
          serviceId={serviceId}
          onRefresh={onRefresh}
          toggleDrawer={toggleDrawer}
        />,
        {
          darkMode: true,
        },
      );
    } else {
      setDrawerContent(
        <ServiceDetailDrawer
          serviceId={serviceId}
          onRefresh={onRefresh}
          toggleDrawer={toggleDrawer}
        />,
        {
          darkMode: true,
        },
      );
      toggleDrawer();
    }
  };

  async function loadData() {
    setIsLoading(true);
    const services = await getEventTypes();
    setServices(services);
    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        {isLoading ? (
          <LoadingDots skeletonProps={{ count: 4 }} />
        ) : (
          <ServicesTable
            services={services}
            handleServiceClick={handleServiceClick}
            handleCreateNewContactClick={handleCreateNewContactClick}
            handleServiceCategoriesClick={handleServiceCategoriesClick}
          />
        )}
      </div>
    </div>
  );
};
