import { User } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/appContext';
import { AuthContext } from '../../contexts/authContext';
import { useQuickStart } from '../../contexts/quickStartContext';
import { useWebSocket } from '../../contexts/websocket.context';
import { formatHeaderDate } from '../../libs/utils/date.util';
import { StackedCircularProgress } from '../common/CircularProgress/CircularProgress';
import HorizontalToggle from '../common/HorizontalToggle/HorizontalToggle';
import NotificationIcon from './notificationIcon';

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { toggleMenu } = useContext(AppContext);
  const { messages } = useWebSocket();
  const { totalTasksCount, completedTaskCountForPlan, allTasksCompleted, totalTaskCountForPlan } =
    useQuickStart();

  const [userState, setUserState] = useState<User | null>(null);

  const locationAvailable = [
    '/voice-messages',
    '/text-conversation',
    '/contacts/',
    '/personal/',
    '/search',
  ].some((path) => location.pathname.startsWith(path) || location.pathname === path);

  const locationTitle =
    {
      '/voice-messages': 'Voicemail',
      '/personal/branding': 'Branding',
      '/text-conversation': 'Messages',
      '/search': 'Search',
    }[location.pathname] || 'Client Profile';

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (user) setUserState(user);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchUser();
  }, [user]);

  const renderMenuButton = () => (
    <button
      onClick={toggleMenu}
      className=" bg-white rounded-full h-12 w-12 flex items-center justify-center px-2"
    >
      {/* <span className="text-xs hidden md:inline">Menu</span> */}
      <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 0.5625C0.5 0.257812 0.734375 0 1.0625 0H10.4375C10.7422 0 11 0.257812 11 0.5625C11 0.890625 10.7422 1.125 10.4375 1.125H1.0625C0.734375 1.125 0.5 0.890625 0.5 0.5625ZM0.5 4.3125C0.5 4.00781 0.734375 3.75 1.0625 3.75H10.4375C10.7422 3.75 11 4.00781 11 4.3125C11 4.64062 10.7422 4.875 10.4375 4.875H1.0625C0.734375 4.875 0.5 4.64062 0.5 4.3125ZM10.4375 8.625H1.0625C0.734375 8.625 0.5 8.39062 0.5 8.0625C0.5 7.75781 0.734375 7.5 1.0625 7.5H10.4375C10.7422 7.5 11 7.75781 11 8.0625C11 8.39062 10.7422 8.625 10.4375 8.625Z"
          fill="#333333"
        />
      </svg>
    </button>
  );

  const renderUserInfo = () =>
    !locationAvailable && (
      <div className="flex items-center flex-row">
        {/* <NavLink to="/personal/settings">
          <div className="relative hidden md:block">
            {userState?.picture ? (
              <img
                src={userState.picture}
                alt="profile"
                className="h-12 w-12 rounded-full object-cover"
              />
            ) : (
              <div className="h-12 w-12 bg-blue-status rounded-full flex items-center justify-center">
                <span className="text-white font-bold">{userState?.first_name?.[0]}</span>
              </div>
            )}
            <button className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-[#FFFFFFB0] border-[0.5px] border-[#D9DAD6] flex items-center justify-center">
              <svg
                width="9"
                height="3"
                viewBox="0 0 9 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.8125 1.25C6.8125 0.742188 7.22266 0.3125 7.75 0.3125C8.25781 0.3125 8.6875 0.742188 8.6875 1.25C8.6875 1.77734 8.25781 2.1875 7.75 2.1875C7.22266 2.1875 6.8125 1.77734 6.8125 1.25ZM3.6875 1.25C3.6875 0.742188 4.09766 0.3125 4.625 0.3125C5.13281 0.3125 5.5625 0.742188 5.5625 1.25C5.5625 1.77734 5.13281 2.1875 4.625 2.1875C4.09766 2.1875 3.6875 1.77734 3.6875 1.25ZM2.4375 1.25C2.4375 1.77734 2.00781 2.1875 1.5 2.1875C0.972656 2.1875 0.5625 1.77734 0.5625 1.25C0.5625 0.742188 0.972656 0.3125 1.5 0.3125C2.00781 0.3125 2.4375 0.742188 2.4375 1.25Z"
                  fill="#989898"
                />
              </svg>
            </button>
          </div>
        </NavLink> */}
        <div className="ml-3">
          <p className="text-sm text-dark-gray">{userState?.first_name}</p>
          <p className="text-sm font-bold text-black">{formatHeaderDate()}</p>
        </div>
      </div>
    );

  const renderQuickStartProgress = () =>
    location.pathname === '/quick-start' &&
    !allTasksCompleted && (
      <div className="mr-3 cursor-pointer" onClick={() => navigate('/quick-start')}>
        <StackedCircularProgress
          size="48px"
          thickness={3}
          completedValue={completedTaskCountForPlan}
          completedTotal={totalTaskCountForPlan}
          completedColor="#4caf50"
          totalValue={totalTasksCount}
          totalTotal={totalTasksCount}
          totalColor="#DDDDDD"
        />
      </div>
    );
  const renderQuickStartStandard = () =>
    location.pathname === '/' &&
    !allTasksCompleted && (
      <div
        className="relative w-[147px] h-[48px] rounded-full bg-[#4caf50] cursor-pointer"
        onClick={() => navigate('/quick-start')}
      >
        <div className="flex items-center justify-center text-center w-full h-full">
          <span className="text-white font-avenir text-[14px] text-center animate-pulse">
            Build Booking Page
          </span>
        </div>
        <div className="absolute top-1 right-1 w-4 h-4  bg-black rounded-full transform translate-x-1/3 -translate-y-1/3 flex items-center justify-center">
          <span className="text-white fpnt-avenir text-[9px] font-bold">
            {totalTaskCountForPlan - completedTaskCountForPlan}
          </span>
        </div>
      </div>
    );

  const renderSearchButton = () =>
    location.pathname !== '/search' &&
    location.pathname !== '/quick-start' && (
      <button
        onClick={() => navigate('/search')}
        className="bg-white rounded-full h-12 w-12 flex items-center justify-center relative ml-2"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8124 11.5391C12.0468 11.7734 12.0468 12.125 11.8124 12.3359C11.7186 12.4531 11.578 12.5 11.4374 12.5C11.2733 12.5 11.1327 12.4531 11.0155 12.3359L7.87488 9.19531C7.03113 9.875 5.97644 10.25 4.85144 10.25C2.17957 10.25 -0.00012207 8.07031 -0.00012207 5.375C-0.00012207 2.70312 2.15613 0.5 4.85144 0.5C7.52332 0.5 9.72644 2.70312 9.72644 5.375C9.72644 6.52344 9.35144 7.57812 8.67175 8.39844L11.8124 11.5391ZM1.12488 5.375C1.12488 7.46094 2.78894 9.125 4.87488 9.125C6.93738 9.125 8.62488 7.46094 8.62488 5.375C8.62488 3.3125 6.93738 1.625 4.87488 1.625C2.78894 1.625 1.12488 3.3125 1.12488 5.375Z"
            fill="#333333"
          />
        </svg>
      </button>
    );

  const renderAlertIcon = () => allTasksCompleted && <NotificationIcon messages={messages} />;

  return (
    <div className="bg-transparent">
      <div className="flex items-center justify-start py-4">
        <div className="flex items-center">
          {renderMenuButton()}
          {renderUserInfo()}
        </div>

        {locationAvailable && (
          <div className="flex self-end ml-auto mr-2">
            <HorizontalToggle title={locationTitle} />
          </div>
        )}

        <div className={`flex items-center self-end ${locationAvailable ? '' : 'ml-auto'}`}>
          {renderQuickStartProgress()}
          {renderQuickStartStandard()}
          {renderAlertIcon()}
          {renderSearchButton()}
        </div>
      </div>
    </div>
  );
};

export default Header;
