import { useRef, useState } from 'react';
import {
  AvailableTimeSlot,
  BookingStatus,
  PaginatedBookingItem,
} from '@book-nestor-monorepo/shared-types';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarTimeSlotViewToggle from './calendar-time-slot-view-toggle';

export default function CalendarTimeSlot({
  timeSlots,
  bookings,
  selectedDate,
  slotDurationMins,
  onNext,
  onBack,
  onSelect,
}: {
  timeSlots: AvailableTimeSlot[];
  bookings: PaginatedBookingItem | null;
  selectedDate: string;
  slotDurationMins: number;
  onNext: () => void;
  onBack: () => void;
  onSelect: (time: string) => void;
}) {
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'calendar' | 'list'>('calendar');
  const containerRef = useRef<HTMLDivElement | null>(null);

  // 1) Determine earliest + latest hour, then add an hour top/bottom
  const calculateTimeRange = () => {
    const times: Date[] = [];

    bookings?.items?.forEach((b) => {
      if (b.status !== BookingStatus.CANCELED) {
        times.push(new Date(b.start_time));
      }
    });
    timeSlots?.forEach((slot) => times.push(new Date(slot.time)));

    if (!times.length) return { startHour: 8, endHour: 18 }; // fallback range + padding

    const sorted = times.sort((a, b) => a.getTime() - b.getTime());
    let earliest = sorted[0].getHours();
    let latest = sorted[sorted.length - 1].getHours();

    // pad top & bottom but clamp to [0..23]
    earliest = Math.max(0, earliest - 1);
    latest = Math.min(23, latest + 1);

    return { startHour: earliest, endHour: latest };
  };

  const { startHour, endHour } = calculateTimeRange();

  // 2) Each hour has 12 blocks of 5 minutes
  const blocksPerHour = 12;
  const totalBlocks = (endHour - startHour) * blocksPerHour;

  // 3) Adjust these values for your desired vertical sizing
  const blockHeight = 12; // px per 5-minute block
  const hourHeight = blockHeight * blocksPerHour; // 144 px per hour

  // 4) Convert startTime+duration to row/column values
  const calcPosition = (startTime: string, duration: number) => {
    const d = new Date(startTime);
    const hour = d.getHours();
    const mins = d.getMinutes();
    const rowStart = (hour - startHour) * blocksPerHour + Math.floor(mins / 5) + 1;
    const rowSpan = Math.ceil(duration / 5);
    return { rowStart, rowSpan };
  };

  // Hour labels for the visible time range
  const renderHourLabels = () => {
    const labels = [];
    for (let h = startHour; h <= endHour; h++) {
      const disp = h % 12 || 12;
      const ampm = h >= 12 ? 'PM' : 'AM';
      labels.push(
        <div key={`hour-${h}`} className="flex items-center justify-end px-1 text-xs text-gray-400">
          <span className="w-14 text-right">
            {disp}
            {ampm}
          </span>
        </div>,
      );
    }
    return labels;
  };

  const handleSlotClick = (time: string) => {
    setSelectedSlot(time);
    onSelect(time);
  };

  // The "list" view: simple uniform list of available slots (no bookings, no hour columns)
  function renderListView() {
    return (
      <div className="flex-1 overflow-auto p-4">
        {timeSlots?.length ? (
          <div className="grid grid-cols-1 gap-4">
            {timeSlots.map((slot) => {
              const isSelected = selectedSlot === slot.time;
              return (
                <button
                  key={slot.time}
                  onClick={() => handleSlotClick(slot.time)}
                  className={`flex w-full items-center justify-between rounded p-2 text-left transition-colors ${
                    isSelected
                      ? 'bg-green-600 text-white hover:bg-green-500'
                      : 'bg-green-50 text-green-700 hover:bg-green-100'
                  }`}
                >
                  <span className="text-sm font-semibold">
                    {new Date(slot.time).toLocaleTimeString([], {
                      hour: 'numeric',
                      minute: '2-digit',
                    })}
                  </span>
                </button>
              );
            })}
          </div>
        ) : (
          <p className="text-sm text-gray-500">No available slots.</p>
        )}
      </div>
    );
  }

  // The "calendar" view: existing design
  function renderCalendarView() {
    return (
      <div className="relative isolate flex flex-auto bg-white">
        {/* Left column: hours */}
        <div className="w-16 flex-none border-r border-gray-100 bg-white">
          <div
            className="grid divide-y divide-gray-100"
            style={{
              gridTemplateRows: `repeat(${endHour - startHour + 1}, ${hourHeight}px)`,
            }}
          >
            {renderHourLabels()}
          </div>
        </div>

        {/* Scrollable column for slots/bookings */}
        <div ref={containerRef} className="flex flex-auto flex-col overflow-auto">
          <div className="relative" style={{ height: totalBlocks * blockHeight }}>
            {/* Horizontal lines (one per 5 min block) */}
            <div
              className="absolute inset-0 grid"
              style={{
                gridTemplateRows: `repeat(${totalBlocks}, 1fr)`,
              }}
            >
              {[...Array(totalBlocks)].map((_, i) => (
                <div key={i} className="border-b border-gray-100" />
              ))}
            </div>

            {/* Slots & Bookings overlay */}
            <ol
              className="absolute inset-0 grid grid-cols-1"
              style={{
                gridTemplateRows: `repeat(${totalBlocks}, 1fr)`,
              }}
            >
              {timeSlots?.map((slot) => {
                const { rowStart, rowSpan } = calcPosition(slot.time, slotDurationMins);
                const isSelected = selectedSlot === slot.time;
                return (
                  <li
                    key={slot.time}
                    style={{ gridRow: `${rowStart} / span ${rowSpan}` }}
                    className="relative"
                  >
                    <button
                      onClick={() => handleSlotClick(slot.time)}
                      className={`group absolute inset-1 flex flex-col rounded p-1 transition-colors ${
                        isSelected
                          ? 'bg-green-600 text-white hover:bg-green-500'
                          : 'bg-green-50 text-green-700 hover:bg-green-100'
                      }`}
                    >
                      <span className="text-xs">
                        {new Date(slot.time).toLocaleTimeString([], {
                          hour: 'numeric',
                          minute: '2-digit',
                        })}
                      </span>
                    </button>
                  </li>
                );
              })}
              {bookings?.items?.map((b) => {
                if (b.status === BookingStatus.CANCELED) return null;
                const { rowStart, rowSpan } = calcPosition(
                  b.start_time,
                  b.contact_booking?.internal_event_type_duration ?? 30,
                );
                return (
                  <li
                    key={b.id}
                    style={{ gridRow: `${rowStart} / span ${rowSpan}` }}
                    className="relative"
                  >
                    <div className="absolute inset-1 flex items-start rounded bg-gray-100 p-1">
                      <p className="text-xs">
                        {b.contact_booking?.internal_event_type_name} - {b.contact?.name}{' '}
                        {b.contact?.last_name}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col font-avenir">
      {/* Sticky container to keep these buttons/header visible */}
      <div className="sticky top-0 z-10 border-b border-gray-200 bg-white">
        <div className="flex items-start justify-between p-2">
          <button
            onClick={onBack}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2 h-4 w-4" />
            Back
          </button>
          <button
            onClick={onNext}
            disabled={!selectedSlot}
            className={`inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 ${
              selectedSlot ? 'bg-black hover:bg-gray-700' : 'bg-black opacity-50 cursor-not-allowed'
            }`}
          >
            Next
            <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4 w-4" />
          </button>
        </div>
        <header className="flex items-center justify-between px-4 py-3 font-inter">
          <div className="flex flex-col">
            <h1 className="text-base font-semibold text-gray-900">
              <time>{new Date(selectedDate).toLocaleDateString()}</time>
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              {new Date(selectedDate).toLocaleDateString('en-US', { weekday: 'long' })}
            </p>
          </div>
          <CalendarTimeSlotViewToggle viewMode={viewMode} setViewMode={setViewMode} />
        </header>
      </div>

      {/* Main content below the sticky header */}
      {viewMode === 'calendar' ? renderCalendarView() : renderListView()}
    </div>
  );
}
