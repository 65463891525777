import {
  EventCategory,
  EventType,
  EventTypeLocationType,
  OnboardingTaskNames,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import mime from 'mime';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { ConfirmDeleteButton } from '../components/formLibrary/confirmDeleteButton';
import { SaveButton } from '../components/formLibrary/formSaveButton';
import PrimaryTextField from '../components/formLibrary/primaryTextInput';
import LoadingDots from '../components/loading';
import { environment } from '../environments/environment';
import { createEventCategory, getEventCategories } from '../libs/services/event-category.service';
import { createEventType, deleteEventType, getEventType } from '../libs/services/event-type';
import { hasAccess } from '../libs/utils/hasAccess';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';
import { useQuickStart } from '../contexts/quickStartContext';

interface EventTypeForm {
  title: string;
  categories: { id: string; name: string }[];
  slug?: string;
  description?: string;
  duration_mins: string;
  price: string;
  file?: any;
  service_address: string;
  display_location_publicly: boolean;
  host_phone_number?: string;
  booking_reminders_enabled: boolean;
  booking_reminders_window: ('3hours' | '24hours' | '48hours')[];
}

export default function ServiceDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const history = useNavigate();
  const fileInputRef = useRef<any>(null);
  const [previewSrc, setPreviewSrc] = useState('');
  const { markTaskComplete } = useQuickStart();

  const [eventType, setEventType] = useState<EventType | undefined>(undefined);
  const [eventCategories, setEventCategories] = useState<EventCategory[] | undefined>(undefined);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const eventType = await getEventType(id);

        if (eventType) {
          setEventType(eventType);

          const eventTypeValues: EventTypeForm = {
            title: eventType.title,
            categories:
              eventType.categories?.map((cat: any) => ({ id: cat.id, name: cat.name })) || [],
            slug: eventType.slug || '',
            description: eventType.description || '',
            duration_mins: eventType.duration_mins?.toString() || '',
            price: eventType.price?.toString() || '',
            file: undefined,
            service_address: eventType.location?.address || 'My Business Location',
            display_location_publicly: eventType.location?.displayLocationPublicly || false,
            host_phone_number: eventType.location?.hostPhoneNumber || '',
            booking_reminders_enabled: eventType.settings?.booking_reminders.enabled ?? true,
            booking_reminders_window: eventType.settings?.booking_reminders.window ?? ['24hours'],
          };
          formik.setValues(eventTypeValues);

          if (eventType.image_key) {
            setPreviewSrc(`${environment.s3.bucketUrl}/${eventType.image_key}`);
          }

          const session = await fetchAuthSession();
          if (!session) return;

          const idToken = session?.tokens?.idToken?.payload;
          setIsVoiceEnabled(hasAccess(idToken, USER_PERMISSIONS.CAN_USE_VOICE));
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchCategories = async () => {
    const eventCategory = await getEventCategories();
    setEventCategories(eventCategory);
  };

  useEffect(() => {
    fetchCategories();

    if (id !== 'new') {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const initialValues: EventTypeForm = {
    title: '',
    categories: [],
    slug: '',
    description: '',
    duration_mins: '',
    price: '',
    file: undefined,
    service_address: 'My Business Location',
    display_location_publicly: false,
    host_phone_number: '',
    booking_reminders_enabled: true,
    booking_reminders_window: ['24hours'],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string(),
      duration_mins: Yup.number().required('Duration is required').positive().integer(),
      price: Yup.number().required('Price is required'),
      slug: Yup.string().nullable(),
      service_address: Yup.string().required(),
      display_location_publicly: Yup.boolean(),
      host_phone_number: Yup.string().nullable(),
      booking_reminders_enabled: Yup.boolean(),
      booking_reminders_window: Yup.array().of(
        Yup.string().oneOf(['3hours', '24hours', '48hours']),
      ),
    }),
    onSubmit: async (values) => {
      const uploadName = values.file && `${uuidv4()}.${mime.getExtension(values.file.type)}`;
      await createEventType(
        { ...mapToCreateEvent(values) },
        { file: values.file as any, uploadName },
        eventType,
      );
      history('/services');
    },
  });

  const mapToCreateEvent = (values: any): EventType => {
    return {
      title: values.title,
      categories: values.categories,
      description: values.description,
      duration_mins: Number(values.duration_mins),
      price: Number(values.price),
      location: {
        type: EventTypeLocationType.IN_PERSON,
        address: values.service_address,
        displayLocationPublicly: values.display_location_publicly,
        hostPhoneNumber: values.host_phone_number,
      },
      minimum_booking_notice_mins: Number(values.minimum_booking_notice_mins),
      settings: {
        booking_reminders: {
          enabled: values.booking_reminders_enabled,
          window: values.booking_reminders_window,
          delivery_methods: {
            email: true, // assuming email delivery method is always enabled for now
            sms: true, // assuming sms delivery method is always enabled for now
          },
        },
      },
    };
  };

  const handleReminderWindowChange = (window: '3hours' | '24hours' | '48hours') => {
    const currentWindows = formik.values.booking_reminders_window;
    let updatedWindow;

    if (currentWindows.includes(window)) {
      updatedWindow = currentWindows.filter((w) => w !== window);
    } else {
      updatedWindow = [...currentWindows, window];
    }

    formik.setFieldValue('booking_reminders_window', updatedWindow);
  };

  const handleFileButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    formik.setFieldValue('file', file);

    const reader: any = new FileReader();
    reader.onloadend = () => {
      setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCategoryChange = (category: { id: string; name: string }) => {
    const { categories } = formik.values;
    const categoryExists = categories.some((cat) => cat.id === category.id);

    if (categoryExists) {
      formik.setFieldValue(
        'categories',
        categories.filter((cat) => cat.id !== category.id),
      );
    } else {
      formik.setFieldValue('categories', [...categories, category]);
    }
  };

  const handleDelete = async () => {
    // Implement the delete functionality here
    await deleteEventType(id as string);
    history(-1);
  };

  const handleAddCategory = async () => {
    if (!newCategoryName) return;

    await createEventCategory({ name: newCategoryName });

    fetchCategories();
    setNewCategoryName('');
  };

  return (
    <ExperimentalAppLayout
      bookings={true}
      pageTitle="Booked Appt."
      insightCardsStyles="hidden md:flex"
      componentName="Your bookings"
    >
      {isLoading ? (
        <LoadingDots
          skeletonProps={{
            count: 4,
            height: 70,
            borderRadius: 10,
            style: { marginBottom: '10px' },
          }}
        />
      ) : (
        <div className="flex flex-col h-full pt-8 md:pt-2 max-h-screen overflow-y-auto w-full">
          <div className="flex flex-col items-center text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 px-4">
            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex justify-center flex-col md:max-w-[350px] "
            >
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/jpeg,image/png,image/heic"
              />
              <div
                onClick={handleFileButtonClick}
                className={`relative h-[288px] w-full rounded-[22px] flex justify-center items-center cursor-pointer mb-6 ${
                  previewSrc ? 'bg-transparent' : 'bg-[#303030]'
                }`}
              >
                {!previewSrc ? (
                  <svg
                    width="34"
                    height="30"
                    viewBox="0 0 42 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M38.0728 0.964375C40.2 0.964375 42 2.76434 42 4.89156V33.6909C42 35.9 40.2 37.6181 38.0728 37.6181H4.03719C1.82815 37.6181 0.110001 35.9 0.110001 33.6909V4.89156C0.110001 2.76434 1.82815 0.964375 4.03719 0.964375H38.0728ZM4.03719 36.3091H8.45527L17.4551 27.3093L10.9916 20.8458C10.7461 20.6003 10.3371 20.6003 10.0916 20.8458L1.41906 29.5183V33.6909C1.41906 35.1636 2.56449 36.3091 4.03719 36.3091ZM40.6909 33.6909V24.2821L31.9366 15.6095C31.6911 15.3641 31.2821 15.3641 31.0366 15.6095L10.2552 36.3091H38.0728C39.4637 36.3091 40.6909 35.1636 40.6909 33.6909ZM40.6909 22.4821V4.89156C40.6909 3.50068 39.4637 2.27344 38.0728 2.27344H4.03719C2.56449 2.27344 1.41906 3.50068 1.41906 4.89156V27.7183L9.10981 19.864C9.84615 19.1276 11.0734 19.1276 11.8916 19.864L18.4369 26.4093L30.1366 14.7095C30.873 13.8914 32.1002 13.8914 32.8366 14.7095L40.6909 22.4821ZM10.5825 6.85516C13.037 6.85516 15.1642 8.98238 15.1642 11.4369C15.1642 13.9732 13.037 16.0186 10.5825 16.0186C8.04619 16.0186 6.00078 13.9732 6.00078 11.4369C6.00078 8.98238 8.04619 6.85516 10.5825 6.85516ZM10.5825 14.7095C12.3825 14.7095 13.8552 13.3187 13.8552 11.4369C13.8552 9.63691 12.3825 8.16422 10.5825 8.16422C8.70072 8.16422 7.30984 9.63691 7.30984 11.4369C7.30984 13.3187 8.70072 14.7095 10.5825 14.7095Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <img
                    src={previewSrc}
                    alt="Preview"
                    className="h-full w-full object-cover rounded-3xl"
                  />
                )}
                <div className="w-8 h-8 absolute bottom-2 right-2 rounded-full flex items-center justify-center bg-[#FFFFFF33]">
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-full font-avenir flex flex-col gap-4">
                <div className="w-full inline-flex flex-row h-[48px] bg-[#f7f7f7] rounded-full">
                  <PrimaryTextField
                    label="Service Name"
                    color="primary"
                    type="text"
                    required={true}
                    fullWidth={true}
                    {...formik.getFieldProps('title')}
                  />
                </div>
                <div className="w-full inline-flex flex-row h-[48px] bg-[#f7f7f7] rounded-full gap-4 mt-2">
                  <PrimaryTextField
                    label="Price"
                    color="primary"
                    type="number"
                    required={true}
                    {...formik.getFieldProps('price')}
                  />
                  <PrimaryTextField
                    label="Duration Mins."
                    color="primary"
                    type="number"
                    required={true}
                    {...formik.getFieldProps('duration_mins')}
                  />
                </div>
                <div className="relative w-full flex flex-col items-start rounded-[24px] bg-[#f7f7f7] mt-2">
                  <PrimaryTextField
                    label="Description"
                    color="primary"
                    type="text"
                    multiline={true}
                    fullWidth={true}
                    {...formik.getFieldProps('description')}
                  />
                </div>
                <div>
                  <h4 className="text-[16px] font-avenir font-bold text-black-text text-left mt-4">
                    Locations
                  </h4>
                  <div className="flex flex-wrap mt-4 gap-2">
                    <button
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          service_address: 'My Business Location',
                        })
                      }
                      value={formik.values.service_address}
                      type="button"
                      className={`h-[48px] rounded-[34px] px-6 text-[14px] flex items-center ${formik.values.service_address === 'My Business Location' ? '!bg-black-alt !text-white' : '!bg-[#F7F7F7]'}`}
                    >
                      My Business Location
                    </button>
                    <button
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          service_address: 'Client Location',
                        })
                      }
                      type="button"
                      value={formik.values.service_address}
                      className={`h-[48px] rounded-[34px] px-6 text-[14px] flex items-center ${formik.values.service_address === 'Client Location' ? '!bg-black-alt !text-white' : '!bg-[#F7F7F7]'}`}
                    >
                      Client Location
                    </button>
                  </div>
                </div>
                <div>
                  <h4 className="text-[16px] font-avenir font-bold text-black-text text-left mt-4">
                    Categories
                  </h4>
                  <div className="flex gap-2 mt-[22px]">
                    <input
                      placeholder="Category Name:"
                      value={newCategoryName}
                      onChange={(e) => setNewCategoryName(e.target.value)}
                      className="w-full px-[18px] focus:outline-none text-[14px] max-w-[292px] h-12 rounded-full bg-gray-alt border-0 placeholder:text-[#C7C7C2] text-black-alt"
                    />

                    <button
                      onClick={handleAddCategory}
                      type="button"
                      className="w-12 min-w-12 h-12 rounded-full flex items-center justify-center border border-black-alt"
                    >
                      <svg
                        width="11"
                        height="10"
                        viewBox="0 0 11 10"
                        fill="#000000"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.875V4.25H9.875C10.2734 4.25 10.625 4.60156 10.625 5C10.625 5.42188 10.2734 5.75 9.875 5.75H6.5V9.125C6.5 9.54688 6.14844 9.875 5.75 9.875C5.32812 9.875 5 9.54688 5 9.125V5.75H1.625C1.20312 5.75 0.875 5.42188 0.875 5C0.875 4.60156 1.20312 4.25 1.625 4.25H5V0.875C5 0.476562 5.32812 0.125 5.75 0.125C6.14844 0.125 6.5 0.476562 6.5 0.875Z"
                          fill="#000000"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-[9px] mt-4">
                    {(eventCategories || []).map(({ name, id }) => (
                      <button
                        key={id}
                        onClick={() => handleCategoryChange({ name, id: id! })}
                        type="button"
                        className={`h-12 border border-transparent rounded-full px-[18px] flex items-center gap-[10px] transition duration-300 ${formik.values.categories.some((cat) => cat.id === id) ? '!bg-black-alt !text-white' : '!text-black-alt !bg-[#F7F7F7]'}`}
                      >
                        <p className="font-[14px]">{name}</p>
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="text-[16px] font-avenir font-bold text-black-text text-left mt-4">
                    Reminder Notifications
                  </h4>
                  <p className="text-left text-[10px] text-black font-inter  mb-4">
                    {isVoiceEnabled
                      ? 'Email & SMS reminders will be sent to the client per the specified Reminder Windows'
                      : 'Email reminders will be sent to the client per the specified Reminder Windows.  Upgrade to enable SMS reminders.'}
                  </p>
                  <div className="flex flex-col gap-2 mt-[8px] ">
                    <div className="flex w-[105px] h-10 rounded-full bg-[#EBEBEB] p-[2px] mb-4">
                      <div className="h-full w-[67px] min-w-[67px] rounded-full bg-black flex items-center justify-center">
                        <p
                          className={`text-[12px] font-avenir font-medium ${formik.values.booking_reminders_enabled ? 'text-book-green' : 'text-red-notification'}`}
                        >
                          {formik.values.booking_reminders_enabled ? 'Active' : 'Inactive'}
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          formik.setFieldValue(
                            'booking_reminders_enabled',
                            !formik.values.booking_reminders_enabled,
                          );
                        }}
                        className="w-full flex items-center justify-center"
                      >
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                            fill="#303030"
                          />
                        </svg>
                      </button>
                    </div>
                    <h4 className="text-[14px] font-avenir text-black-text text-left mb-4">
                      Email & SMS Reminder Window
                    </h4>
                    <div
                      className={`flex flex-row gap-2 ${
                        formik.values.booking_reminders_enabled ? '' : '!opacity-50'
                      }`}
                    >
                      {(['3hours', '24hours', '48hours'] as const).map((window) => (
                        <button
                          key={window}
                          onClick={() => handleReminderWindowChange(window)}
                          type="button"
                          className={`h-10 border ${
                            formik.values.booking_reminders_window.includes(window)
                              ? 'border-black bg-black text-white'
                              : 'border-black text-black'
                          } rounded-full px-[14px] flex items-center gap-[10px] transition duration-300`}
                          disabled={!formik.values.booking_reminders_enabled}
                        >
                          <p className="text-[14px]">
                            {window === '3hours'
                              ? '3 hours'
                              : window === '24hours'
                                ? '24 hours'
                                : '48 hours'}
                          </p>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex gap-[10px] mt-[60px] mb-10">
                  {id !== 'new' && <ConfirmDeleteButton onClick={handleDelete} />}
                  <SaveButton
                    onClick={async () => {
                      if (formik.isValid) {
                        await formik.submitForm();
                        await markTaskComplete(OnboardingTaskNames.createAService);
                      }
                    }}
                    defaultText={`${id === 'new' ? 'Create' : 'Update'}`}
                    loadingText={`${id === 'new' ? 'Creating' : 'Updating'}`}
                    disabled={!formik.isValid}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
