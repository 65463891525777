import React, { useState } from 'react';
import { Notification } from '@book-nestor-monorepo/shared-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faX } from '@fortawesome/pro-light-svg-icons';
import { faStripe } from '@fortawesome/free-brands-svg-icons';

const NotificationModalContent = ({
  messages,
  onClose,
}: {
  messages: Notification[];
  onClose: () => void;
}) => {
  const [selectedMessage, setSelectedMessage] = useState<Notification | null>(null);

  const handleMessageClick = (message: Notification) => {
    setSelectedMessage(message);
  };

  const handleBack = () => {
    setSelectedMessage(null);
  };

  if (selectedMessage) {
    return (
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={handleBack}
            className="flex items-center text-sm text-gray-600 hover:text-gray-900"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4 mr-1 font-avenir" />
            Back to messages
          </button>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faX} className="w-5 h-5" />
          </button>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">{selectedMessage.content}</p>
          {selectedMessage.link && (
            <a
              href={selectedMessage.link}
              className="mt-2 text-blue-600 hover:text-blue-800 block font-avenir"
            >
              View details
            </a>
          )}
        </div>
      </div>
    );
  }

  //   return (
  //     <div className="">
  //       {messages.length === 0 ? (
  //         <p className="text-black text-center font-avenir">No notifications</p>
  //       ) : (
  //         <div className="space-y-2">
  //           {messages.map((message, index) => (
  //             <div
  //               key={index}
  //               onClick={() => handleMessageClick(message)}
  //               className="p-1 rounded-lg  hover:bg-gray-100 cursor-pointer"
  //             >
  //               <p className="text-sm text-gray-800 font-avenir">
  //                 {message.title} {new Date(message.createdAt).toLocaleDateString()}
  //               </p>
  //             </div>
  //           ))}
  //         </div>
  //       )}
  //     </div>
  //   );

  // TODO: right now we only have 1 notification but we will have more so we will use above when we have more
  return (
    <div className="">
      {messages.length === 0 ? (
        <div className="flex items-center justify-between">
          <button
            onClick={handleBack}
            className="flex items-center justify-center text-[16px] font-avenir font-medium"
          >
            No new notifications
          </button>
          <button onClick={onClose} className="bg-black h-[40px] w-[40px] rounded-full p-2">
            <FontAwesomeIcon icon={faX} className="text-[14px] text-white" />
          </button>
        </div>
      ) : (
        <div className="">
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={handleBack}
              className="flex items-center justify-center text-[16px] font-avenir font-medium"
            >
              Finish Setting Up{' '}
              <FontAwesomeIcon
                icon={faStripe}
                className="text-[44px] text-[#5f4cfe] ml-1 font-avenir"
              />
            </button>
            <button onClick={onClose} className="bg-black h-[40px] w-[40px] rounded-full p-2">
              <FontAwesomeIcon icon={faX} className="text-[14px] text-white" />
            </button>
          </div>
          <div className="mt-4">
            <p className="text-black text-left text-[14px] font-avenir">
              You're almost there! To start accepting payments, you need to finish setting up your
              Stripe account. This quick and easy step ensures a seamless and secure experience for
              both you and your clients.
            </p>
            <br />
            <p className="text-black text-left text-[14px] font-avenir">
              Click below to complete your setup today and get ready to take your business to the
              next level!
            </p>
            <button
              className="bg-black text-white text-[14px] font-avenir font-medium rounded-full px-4 py-2 mt-4"
              onClick={() => {
                window.open('https://dashboard.stripe.com/account/status', '_blank');
              }}
            >
              Finish Set Up
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationModalContent;
