import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import { formatHeaderDate } from '../../../libs/utils/date.util';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { useQuickStart } from '../../../contexts/quickStartContext';
import { USER_PERMISSIONS, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { hasAccess } from '../../../libs/utils/hasAccess';
import NotificationIcon from '../../app/notificationIcon';
import { useOrganization } from '../../../contexts/v2/organizationContext';
import { AgentAiBorder } from '../Agent/AgentAiBorder';

type ToggleMenuFunction = () => void;

export const MobileHomeMenu = ({
  toggleMenu,
  isOpen,
}: {
  toggleMenu: ToggleMenuFunction;
  isOpen: boolean;
}) => {
  const navigate = useNavigate();
  const { user, token, servicePhone } = useContext(AuthContext);
  const { completedTaskCountForPlan, allTasksCompleted, totalTaskCountForPlan } = useQuickStart();
  const { organization } = useOrganization();
  const [activeServicePhone, setActiveServicePhone] = useState<UserServicePhone | undefined>(
    servicePhone,
  );

  const onClickNavigateAgent = () => {
    if (hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT)) {
      navigate('/agent');
    } else {
      navigate('/subscription');
    }
  };

  useEffect(() => {
    setActiveServicePhone(servicePhone);
  }, [servicePhone]);

  const renderQuickStartStandard = () =>
    !allTasksCompleted && (
      <div
        className="relative w-auto px-4 h-[48px] rounded-full bg-[#4caf50] cursor-pointer"
        onClick={() => navigate('/quick-start')}
      >
        <div className="flex items-center justify-center text-center w-full h-full">
          <span className="text-white font-avenir text-[14px] text-center animate-pulse">
            Quick Start
          </span>
        </div>
        <div className="absolute top-1 right-1 w-4 h-4  bg-black rounded-full transform translate-x-1/3 -translate-y-1/3 flex items-center justify-center">
          <span className="text-white fpnt-avenir text-[9px] font-bold">
            {totalTaskCountForPlan - completedTaskCountForPlan}
          </span>
        </div>
      </div>
    );

  const renderNotificationIcon = () => {
    return <NotificationIcon messages={[]} />;
  };

  return (
    <div className="flex justify-between flex-grow">
      <div className="flex items-center">
        <div onClick={toggleMenu} className="cursor-pointer flex items-center">
          <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <FontAwesomeIcon icon={faBars} className="text-[14px] text-black" />
          </div>
          <div className="flex flex-col justify-center ml-3 text-left h-12">
            <p className="text-[14px] text-black font-avenir leading-tight">
              {organization?.name || user?.first_name}
            </p>
            <p className="text-[14px] text-black font-avenir font-bold leading-tight">
              {formatHeaderDate()}
            </p>
          </div>
        </div>
        <MobileMenuDrawer isOpen={isOpen} toggleMenu={toggleMenu} />
      </div>
      <div className="flex items-center space-x-2">
        {renderNotificationIcon()}
        {renderQuickStartStandard()}
        <div
          className="w-12 h-12 bg-[#434242] rounded-full flex items-center justify-center "
          onClick={onClickNavigateAgent}
        >
          {activeServicePhone?.agent?.pfp_url ? (
            <AgentAiBorder>
              <img
                src={activeServicePhone.agent.pfp_url}
                height={100}
                width={100}
                alt="Connector black"
              />
            </AgentAiBorder>
          ) : (
            <img src="/assets/nestor-ai.svg" height={100} width={100} alt="Connector black" />
          )}
        </div>
      </div>
    </div>
  );
};
