import { Organization, ReviewSummary } from '@book-nestor-monorepo/shared-types';
import { faBookmark, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { environment } from '../../environments/environment';

const BookingDetailsMobile = ({
  organization,
  reviewsSummary,
}: {
  organization: Organization;
  reviewsSummary: ReviewSummary;
}) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const mainBookingImage = organization.logo_image_key
    ? `${environment.s3.bucketUrl}/${organization.logo_image_key}`
    : undefined;

  const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStar} color="#FFC107" />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStarHalfStroke} color="#FFC107" />);
      } else {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStarRegular} color="#FFC107" />);
      }
    }

    return stars;
  };

  const onScrollToDetails = () => {
    descriptionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const cityState = organization.mailing_address
    ? `${organization.mailing_address.city}, ${organization.mailing_address.state}`
    : 'City, State';

  return (
    <div className="md:hidden">
      <div className={`font-avenir`}>
        {mainBookingImage ? (
          <div
            className="bg-center bg-no-repeat bg-cover h-screen flex"
            style={{ backgroundImage: `url('${mainBookingImage}')` }}
          ></div>
        ) : (
          <div className="bg-[#474747] h-screen"></div>
        )}
        <div className="absolute bottom-7 w-full px-[14px]">
          <div className="relative w-full bg-[#ABABAB6] backdrop-blur-md rounded-[24px] px-[24px]">
            <h1 className="text-4xl font-semibold text-white pt-[25px] whitespace-pre-line">
              {organization.name?.split(' ').join('\n') || 'Your business'}
            </h1>
            <h5 className="font-medium mt-[14px] text-white pb-4">{cityState}</h5>
            <div className="flex items-center justify-between mt-[-10px]">
              {reviewsSummary && (
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center pt-[2px]">
                    <p className="font-medium text-[15px] font-avenir text-white pt-[1px]">
                      {reviewsSummary.average_rating}
                    </p>
                  </div>
                  <div className="flex items-center gap-[2px]">
                    {renderStars(reviewsSummary.average_rating)}
                  </div>
                  <p className="font-medium text-[15px] font-avenir  text-white pt-[1px]">
                    ({reviewsSummary.total_reviews})
                  </p>
                </div>
              )}
            </div>
            <button
              onClick={onScrollToDetails}
              className="w-full mt-[34px] py-[11.5px] bg-blue-status rounded-full font-avenir text-white mb-[16px]"
            >
              Book Now
            </button>
            <button className="absolute top-2 right-2 w-[48px] h-[48px] rounded-full border border-[#ffffff80] bg-[#f7f7f780] flex items-center justify-center">
              <FontAwesomeIcon className="w-3" icon={faBookmark} color="#fff" />
            </button>
          </div>
          <button onClick={onScrollToDetails} className="w-full mt-8 font-semibold text-white">
            More details
            <FontAwesomeIcon className="w-4 h-4 ml-2" icon={faChevronDown} color="#fff" />
          </button>
        </div>
      </div>

      {organization.description && (
        <div
          ref={descriptionRef}
          className="md:mt-[72px] lg:mt-[97px] font-avenir py-[65px] px-[44px]"
        >
          <h4 className="text-2xl text-black-text">Description</h4>
          <p className="text-black-text mt-4">{organization.description}</p>
        </div>
      )}
    </div>
  );
};

export default BookingDetailsMobile;
