export interface Insight {
  metric_type: InsightsMetricType;
  value: number;
  date_range: string;
  start_date?: string;
  end_date?: string;
  month: number;
  year: number;
  value_type: ValueType;
}

export enum ValueType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export enum InsightsMetricType {
  BOOKING_COUNT = 'booking_count',
  SITE_VISIT = 'site_visit',
  TOTAL_REVENUE = 'total_revenue',
  CONVERSION_RATE = 'conversion_rate',
  NI_CONVERSATION_COUNT = 'ni_conversation_count',
  NI_MESSAGE_COUNT = 'ni_message_count',
  NI_SMS_CONVERSATION_COUNT = 'ni_sms_conversation_count',
  NI_VOICE_CONVERSATION_COUNT = 'ni_voice_conversation_count',
  NI_BOOKED_CONVERSATION_COUNT = 'ni_booked_conversation_count',
  NI_BOOKING_COUNT_LAST_24_HOURS = 'ni_booking_count_last_24_hours',
  NI_RECOVERY_CONVERSATION_COUNT = 'ni_recovery_conversation_count',
}

export enum DateRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
  MTD = 'MTD',
  LastMonth = 'LastMonth',
  ThisMonth = 'ThisMonth',
}

export class InsightsFilters {
  date_range: DateRange;
  page_url?: string;
  month?: string;
  year?: string;
}
