import LoadingDots from '../../components/loading';
import NestorFlag from '../../components/nestorFlag';

export function BookingLayout({ isLoading, children, hideBranding = false }: any) {
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen bg-[#E5E6E1]">
          <LoadingDots />
        </div>
      ) : (
        <div className="bg-[#E5E6E1] md:pt-[88px]">
          {!hideBranding && (
            <NestorFlag
              className="translate-x-[50%] -translate-y-[60%] lg:translate-x-[45%] lg:translate-y-[10%]"
              powered
              backgroundColor="bg-[#FFFFFF33] backdrop-blur-sm md:bg-[#0000001a] md:backdrop-blur-none"
            />
          )}
          {children}
          {!hideBranding ? (
            <footer className="bg-[#191919] pt-[86px] md:pt-[115px] pb-[80px] md:pb-[128px]">
              <div className="md:w-[750px] lg:w-[1000px] mx-auto flex items-center flex-col md:flex-row">
                <div className="text-[#3F3F3F] w-[342px]">
                  <h2 className="font-semibold text-[42px] leading-[48px]">
                    The new era of booking - MyNestor
                  </h2>
                  <p className="font-avenir mt-[47px]">Automated Booking. Payments. Growth.</p>
                </div>
                <div className="flex items-start w-[342px] mt-[21px] md:mt-0 md:w-auto">
                  <div className="font-avenir text-white w-[184px] md:ml-[66px]">
                    <p>
                      Do you run a booking business as a solo professional? Sign up and get started
                      for free!
                    </p>
                    <button className="mt-[81px] w-full py-3 border border-[#D7D8D3] rounded-full">
                      Try <span className="font-semibold">MyNestor</span>
                    </button>
                  </div>
                  <div className="font-avenir text-white w-[184px] ml-5">
                    <p>
                      Get ahead with the first ever AI Booking Agent. Are you a student or just
                      beginning your journey? Ask about our special pricing!
                    </p>
                  </div>
                </div>
              </div>
            </footer>
          ) : (
            <div className="h-[100px] bg-[#191919]" />
          )}
        </div>
      )}
    </div>
  );
}
