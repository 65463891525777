import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPhoneVoiceMessage } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import AudioPlayer from '../components/common/AudioPlayer/AudioPlayer';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { useWebSocket } from '../contexts/websocket.context';
import { getServicePhoneDetailsByUser, getVoiceMessages } from '../libs/services/voice';
import { minsToHHMMSS } from '../libs/utils/date.util';
import { AppLayout } from './layouts/app-layout';
import { StoreContext } from '../contexts/storeContext';
import { assignContactNamesToVoiceMessages } from '../libs/utils/contacts.util';

export default function VoiceMessagesPage() {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(StoreContext);
  const history = useNavigate();
  const authContext = useContext(AuthContext);
  const { messages } = useWebSocket();

  const [voiceMessages, setVoiceMessages] = useState<UserPhoneVoiceMessage[]>([]);
  const [servicePhoneNumber, setServicePhoneNumber] = useState('');

  const fetchVoiceConversaions = async (servicePhoneNumber: string) => {
    const voiceMessages = await getVoiceMessages(servicePhoneNumber);
    // assign contact names from store
    const voiceMessagesWithNames = assignContactNamesToVoiceMessages(voiceMessages, state.contacts);
    setVoiceMessages(voiceMessagesWithNames);
  };

  useEffect(() => {
    // assign contact names from store
    const voiceMessagesWithNames = assignContactNamesToVoiceMessages(voiceMessages, state.contacts);
    setVoiceMessages(voiceMessagesWithNames);
  }, [state.contacts]);

  useEffect(() => {
    if (messages.length > 0) {
      fetchVoiceConversaions(servicePhoneNumber);
    }
  }, [messages]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;

        // TODO: move to GlobalContext
        const userServicePhoneResponse = await getServicePhoneDetailsByUser(user.id || '');
        if (!userServicePhoneResponse?.phone_number) return;

        setServicePhoneNumber(userServicePhoneResponse.phone_number);

        fetchVoiceConversaions(userServicePhoneResponse.phone_number);
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [authContext.user]);

  const handleButtonClick = async (event: any) => {
    // FIXME: wtf is this?
    event.preventDefault();
    try {
      history('/services/new');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <AppLayout>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col h-full pt-20 md:pt-2 ">
          <div className="flex items-center justify-start pb-4 px-2">
            <span className="text-[64px] text-white">Voicemails</span>
          </div>

          <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4 mx-2">
            <div className="flex flex-row items-baseline pb-4">
              <span className="bg-white border border-black-back rounded-full px-4 py-2 text-sm mr-2 h-10 flex items-center">
                All Messages <span className="font-bold ml-2">{voiceMessages.length}</span>
              </span>
              <span
                onClick={handleButtonClick}
                className="rounded-full px-4 py-2 text-sm mr-2 text-white bg-blue-status h-10 flex items-center"
              >
                Unread
                <span className="font-bold ml-2">
                  {voiceMessages.filter((i) => !i.IsListened).length}
                </span>
              </span>
            </div>
            <div className="flex flex-col">
              {voiceMessages.map((item, index) => (
                <div key={index}>
                  <div
                    key={index}
                    className="flex flex-flow justify-between p-[11px] border-y border-gray-btn cursor-pointer"
                  >
                    <div className="flex flex-row">
                      <span className={`w-3 h-3 rounded-full bg-blue-status mr-2`} />
                      <div className="flex flex-col items-start">
                        <p className="font-medium text-base text-black-text">
                          {item.CallerName || item.Caller}
                        </p>
                        <p className="text-[10px] text-gray-text">{item.Caller}</p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="flex flex-col items-end">
                        <p className="font-medium text-base text-black-text">
                          {item.created_at || 'yesterday'}
                        </p>
                        <p className="text-[10px] text-gray-text">
                          {minsToHHMMSS(+(item.RecordingDuration || 0))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <AudioPlayer
                    voiceMessageId={item.RecordingSid ?? ''}
                    phoneNumber={servicePhoneNumber}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}
