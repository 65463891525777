import { TextMessage } from '@book-nestor-monorepo/shared-types';
import { faUserAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MessageSendButton } from '../components/formLibrary/messageSendButton';
import PrimaryTextFieldLight from '../components/formLibrary/primaryTextInputLight';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { useWebSocket } from '../contexts/websocket.context';
import { createTextMessage, getTextConversation } from '../libs/services/text.service';
import { getServicePhoneDetailsByUser } from '../libs/services/voice';
import { formatTextMessageDate } from '../libs/utils/date.util';
import { formatPhoneForTextMessage, formatPhoneNumber } from '../libs/utils/phone.util';
import { AppLayout } from './layouts/app-layout';
import { StoreContext } from '../contexts/storeContext';

const MessageItem = ({ message, isSender }: { message: TextMessage; isSender: boolean }) => {
  return (
    <div className={`flex flex-col my-1 ${isSender ? 'items-end' : 'items-start'}`}>
      <div
        className={`p-4 font-inter text-sm text-left rounded-2xl break-words max-w-[90%] leading-4 ${
          isSender
            ? 'bg-black-back text-white rounded-br-none'
            : 'bg-gray-200 text-black rounded-bl-none'
        }`}
      >
        {message.text}
      </div>
      <span className="text-[9.89px] text-[#666666] mt-1 leading-4">
        {formatTextMessageDate(new Date(message.createdAt))}
      </span>
    </div>
  );
};

export default function TextConversationPage() {
  const history = useNavigate();
  const { state } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { recipientPhoneNumber } = useParams();
  const [searchParams] = useSearchParams();
  const messageParam = searchParams.get('message') || '';
  const [newRecipientPhoneNumber, setNewRecipientPhoneNumber] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [textMessages, setTextMessages] = useState<TextMessage[]>([]);
  const [servicePhoneNumber, setServicePhoneNumber] = useState('');
  const [message, setMessage] = useState(messageParam || '');
  const [recipientName, setRecipientName] = useState('');

  const disabledSubmit =
    (recipientPhoneNumber === 'new' && !newRecipientPhoneNumber) ||
    !message.trim() ||
    !servicePhoneNumber;

  const { messages } = useWebSocket();

  useEffect(() => {
    const recipientPhoneNumberFormatted = formatPhoneForTextMessage(recipientPhoneNumber || '');
    const contact = state.contacts.find(
      (contact) =>
        formatPhoneForTextMessage(contact.phone_number || '') === recipientPhoneNumberFormatted,
    );

    if (contact) {
      setRecipientName(contact ? `${contact?.name} ${contact?.last_name}` : '');
    }
  }, [state.contacts]);

  useEffect(() => {
    if (messages.length > 0) {
      fetchTextConversaion(servicePhoneNumber);
    }
  }, [messages]);

  const fetchTextConversaion = async (servicePhoneNumber: string) => {
    const textMessages = await getTextConversation(servicePhoneNumber, recipientPhoneNumber || '');
    textMessages.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1));

    setTextMessages(textMessages);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!recipientPhoneNumber) return;

      const user = authContext.user;
      if (!user) return;

      // TODO: move to GlobalContext
      const userServicePhoneResponse = await getServicePhoneDetailsByUser(user.id || '');
      if (!userServicePhoneResponse?.phone_number) return;

      setServicePhoneNumber(userServicePhoneResponse.phone_number);

      fetchTextConversaion(userServicePhoneResponse.phone_number);
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user, recipientPhoneNumber]);

  const handleSubmit = async () => {
    if (message.trim()) {
      const phoneNumberToUse =
        recipientPhoneNumber === 'new' ? `+1${newRecipientPhoneNumber}` : recipientPhoneNumber;
      if (!phoneNumberToUse) return;

      const newTextMessage = await createTextMessage(
        formatPhoneForTextMessage(servicePhoneNumber),
        formatPhoneForTextMessage(phoneNumberToUse),
        message,
      );
      if (!newTextMessage) return;
      if (recipientPhoneNumber === 'new') {
        history(`/text-conversations/${formatPhoneForTextMessage(phoneNumberToUse)}`);
      }

      setTextMessages([newTextMessage, ...textMessages]);
      setMessage('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  };

  if (isLoading)
    return (
      <AppLayout>
        <div className="flex flex-col h-full pt-8 md:pt-2 bg-black-back">
          <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4 border-none">
            <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4 border-none">
              <LoadingDots
                skeletonProps={{
                  count: 4,
                  height: 20,
                  borderRadius: 10,
                  style: { marginBottom: '20px' },
                }}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    );

  return (
    <AppLayout>
      <div className="flex flex-col h-full pt-8 md:pt-2 overflow-hidden">
        <div className="flex items-center justify-center pb-4 px-2">
          {recipientPhoneNumber === 'new' ? (
            <div className="flex items-center justify-center pb-4 px-2 w-full">
              <ReactInputMask
                mask="(999) 999-9999"
                maskChar="_"
                minLength={10}
                value={newRecipientPhoneNumber}
                onChange={(e) => setNewRecipientPhoneNumber(e.target.value)}
                name="phoneNumber"
              >
                <PrimaryTextFieldLight
                  label="Enter recipient's phone number"
                  value={newRecipientPhoneNumber}
                  fullWidth={true}
                />
              </ReactInputMask>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <span className="flex rounded-full bg-gray-300 h-10 w-10 mr-2 items-center justify-center">
                <FontAwesomeIcon color="white" icon={faUserAlt} className="text-2xl" />
              </span>
              <span className="text-base text-white font-inter leading-4 ">
                {recipientName || formatPhoneNumber(recipientPhoneNumber || '')}
              </span>
            </div>
          )}
        </div>

        <div className="flex text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4 border-none pb-[110px]">
          <div className="flex flex-col-reverse space-y-2 p-3 overflow-y-auto hide-scrollbar w-full">
            {textMessages.map((item, index) => (
              <div key={index} className="pb-1 pr-2 md:pr-2 cursor-pointer">
                <MessageItem
                  key={index}
                  message={item}
                  isSender={
                    formatPhoneForTextMessage(item.from) ===
                    formatPhoneForTextMessage(servicePhoneNumber)
                  }
                />
              </div>
            ))}
          </div>
        </div>

        <form className="flex items-center p-2 bg-white sticky bottom-0" onSubmit={handleSubmit}>
          <textarea
            className={`flex-1 p-2 mr-4 rounded-xl text-xs border border-[#C5C5C7] focus:outline-none placeholder:text-gray-500 h-auto`}
            placeholder="Write Message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            style={{ overflow: 'hidden' }}
            ref={textareaRef as React.RefObject<HTMLTextAreaElement>}
          />
          <MessageSendButton
            onClick={async () => {
              await handleSubmit();
            }}
            disabled={disabledSubmit}
          />
        </form>
      </div>
    </AppLayout>
  );
}
