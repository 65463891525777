import {
  TextConversation,
  Contact,
  UserPhoneVoiceMessage,
} from '@book-nestor-monorepo/shared-types';
import { formatPhoneForTextMessage } from './phone.util';

export const assignContactNamesToTextConversations = (
  conversations: TextConversation[],
  contacts: Contact[],
): TextConversation[] => {
  return conversations.map((conversation) => {
    const conversationPhoneNumber = formatPhoneForTextMessage(conversation.to);

    const contact = contacts.find((contact) => {
      const contactPhoneNumber = formatPhoneForTextMessage(contact.phone_number || '');
      return contactPhoneNumber === conversationPhoneNumber;
    });

    return {
      ...conversation,
      name: contact ? `${contact?.name} ${contact?.last_name}` : undefined,
    };
  });
};

export const assignContactNamesToVoiceMessages = (
  voiceMessages: UserPhoneVoiceMessage[],
  contacts: Contact[],
): UserPhoneVoiceMessage[] => {
  return voiceMessages.map((voiceMessage) => {
    const voiceMessagePhoneNumber = formatPhoneForTextMessage(voiceMessage.Caller);

    const contact = contacts.find((contact) => {
      const contactPhoneNumber = formatPhoneForTextMessage(contact.phone_number || '');
      return contactPhoneNumber === voiceMessagePhoneNumber;
    });

    return {
      ...voiceMessage,
      CallerName: contact ? `${contact?.name} ${contact?.last_name}` : undefined,
    };
  });
};
