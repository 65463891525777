import { BookingContainer } from '../../../components/v2/InAppBooking/booking-container';
import { Booking } from '@book-nestor-monorepo/shared-types';

export const CreateBookingDrawer = ({
  onSuccessfulBooking,
  preLoadContactId,
  preLoadServiceId,
  rescheduleFromBooking,
}: {
  onSuccessfulBooking: () => Promise<void>;
  preLoadContactId?: string;
  preLoadServiceId?: string;
  rescheduleFromBooking?: Booking;
}) => {
  return (
    <div className="flex flex-col w-full items-start justify-center bg-white p-2">
      <BookingContainer
        onSuccessfulBooking={onSuccessfulBooking}
        preLoadContactId={preLoadContactId}
        preLoadServiceId={preLoadServiceId}
        rescheduleFromBooking={rescheduleFromBooking}
      />
    </div>
  );
};
