import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { ContactNote } from '@book-nestor-monorepo/shared-types';
import { environment } from '../../environments/environment';

interface NoteModalType {
  onClose: () => void;
  note: ContactNote;
}

const NoteModal = ({ onClose, note }: NoteModalType) => {
  const authContext = useContext(AuthContext);

  return (
    <div className="flex flex-col w-full">
      <div className={`flex flex-row items-center justify-between h-12 mb-9`}>
        <p className="font-inter font-medium text-base leading-4 text-black-text">Notes</p>
        <div className="flex flex-row gap-1 items-center">
          <span
            className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-black-solid"
            onClick={onClose}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="flex flex-col pt-1.5 px-2 gap-2">
        <div className="flex items-center relative">
          <h2
            className={`bg-gray-modals font-avenir italic text-black-text ${
              note.title.length <= 8
                ? 'text-[67.778px] leading-[72px]'
                : note.title.length <= 20
                  ? 'text-[50px] leading-[60px]'
                  : 'text-[40px] leading-[48px]'
            } mb-4`}
          >
            {note.title}
          </h2>
        </div>

        <div
          className={`pt-[45px] bg-[#DCDFE1] rounded-[20px] 2xl:pt-[69px] pb-[57px] 2xl:pb-[80px] pl-[36px] 2xl:pl-[52px] pr-[21px] 2xl:pr-[30px] h-[calc(100vh-584px)] border transition-all duration-200 overflow-y-scroll`}
        >
          <p className="font-avenir text-black-text">{note.body}</p>
        </div>

        <div className="mb-10">
          <h5 className="font-inter font-medium">Gallery</h5>

          <div className="grid grid-cols-2 gap-2 mt-5">
            {note.gallery_image_keys?.map((image, index) => (
              <div
                key={index}
                className="relative w-full h-[133px] rounded-[16px] bg-black-alt overflow-hidden"
              >
                <img
                  src={`${environment.s3.bucketUrl}/${image}`}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
