import { OnboardingTaskNames, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import FormikWrapper from '../../components/form-wrapper';
import { SaveButton } from '../../components/formLibrary/formSaveButton';
import PrimaryTextFieldLight from '../../components/formLibrary/primaryTextInputLight';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import {
  assignUserServicePhone,
  getServicePhoneDetailsByUser,
  updateUserServicePhone,
} from '../../libs/services/voice';
import { phoneNumberAddressSchema } from '../../schemas/phone-number-location.schema';

export const CommunicationPageV2 = () => {
  const [expandedCard, setExpandedCard] = useState('phone');
  const { bgColor } = useAppExperience();
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone>();
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { markTaskComplete } = useQuickStart();
  const toggleCard = (cardName: string) => {
    setExpandedCard(expandedCard === cardName ? '' : cardName);
  };
  const [copied, setCopied] = useState(false);

  const isPhoneExpanded = expandedCard === 'phone';

  const fetchCommunication = async () => {
    if (!authContext.user) return;
    const userServicePhoneResponse = await getServicePhoneDetailsByUser(authContext.user.id || '');
    setUserServicePhone(userServicePhoneResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCommunication();
  }, [authContext.user]);

  const initialValuesLocation = {
    city: '',
    state: '',
  };

  const mapToUpdateUserServicePhone = (values: Partial<UserServicePhone>): UserServicePhone => {
    if (!userServicePhone) {
      throw new Error('User Service Phone not found');
    }
    return {
      ...userServicePhone,
      ...values,
    };
  };

  const handleSubmitAi = async (values: Partial<UserServicePhone>) => {
    try {
      await updateUserServicePhone({ ...mapToUpdateUserServicePhone(values) });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSubmitAddress = async (values: { city: string; state: string }) => {
    try {
      await assignUserServicePhone(values);
      await markTaskComplete(OnboardingTaskNames.verifyAIAssistant);

      fetchCommunication();
      closeMuiModal();
    } catch (error) {
      alert(
        'No available phone numbers found in your area. Please try again with a different area.',
      );
      Sentry.captureException(error);
    }
  };

  const FormFieldsLocation = ({ dirty }: any) => {
    const { values, handleChange, submitForm, isSubmitting, isValid } = useFormikContext<{
      city: string;
      state: string;
    }>();

    return (
      <div className="p-4 mt-10 ml-5 bg-black">
        <p className="text-base  mb-2 text-white text-[12px] font-avenir pb-2">
          Let’s get your phone number set up.
        </p>
        <div className="flex w-full items-center mb-4">
          <div className="flex items-center relative w-full">
            <PrimaryTextFieldLight
              label="City"
              color="primary"
              fullWidth={true}
              name="city"
              type="text"
              variant="filled"
              onChange={handleChange}
              value={values.city}
              helperText="e.g. New York"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex items-center relative w-full">
            <PrimaryTextFieldLight
              label="State Abbrev."
              color="primary"
              fullWidth={true}
              name="state"
              variant="filled"
              type="text"
              onChange={handleChange}
              value={values.state}
              helperText="e.g. NY"
            />
          </div>
        </div>
        <div className="w-full pt-6">
          <SaveButton
            onClick={async () => {
              if (isValid) {
                await submitForm();
              }
            }}
            defaultText="Get my number"
            loadingText="Searching"
            successText="Number found"
            disabled={!isValid || !dirty}
            color="black"
            bgColor="white"
          />
        </div>
      </div>
    );
  };

  const NoServicePhone = ({ onClose }: { onClose: () => void }) => {
    return (
      <div className="w-full bg-black">
        <div className={`flex flex-row items-center justify-between h-12 mb-9`}>
          <p className="font-inter font-medium text-base leading-4 text-white "></p>
          <div className="flex flex-row gap-1 items-center pt-4 pr-2">
            <span
              className="flex items-center justify-center right-1 top-2 w-12 h-12  cursor-pointer z-20 rounded-full border border-white-solid"
              onClick={onClose}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#F7F7F7" />
                <path
                  d="M28.0312 27.0039C28.2773 27.2773 28.2773 27.6875 28.0312 27.9336C27.7578 28.207 27.3477 28.207 27.1016 27.9336L23.875 24.6797L20.6211 27.9336C20.3477 28.207 19.9375 28.207 19.6914 27.9336C19.418 27.6875 19.418 27.2773 19.6914 27.0039L22.9453 23.75L19.6914 20.4961C19.418 20.2227 19.418 19.8125 19.6914 19.5664C19.9375 19.293 20.3477 19.293 20.5938 19.5664L23.875 22.8477L27.1289 19.5938C27.375 19.3203 27.7852 19.3203 28.0312 19.5938C28.3047 19.8398 28.3047 20.25 28.0312 20.5234L24.7773 23.75L28.0312 27.0039Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <FormikWrapper<{ city: string; state: string }>
          initialValues={initialValuesLocation}
          validationSchema={phoneNumberAddressSchema}
          onSubmit={handleSubmitAddress}
        >
          {({ dirty }) => <FormFieldsLocation dirty={dirty} />}
        </FormikWrapper>
      </div>
    );
  };

  const copyToClipboard = (phoneNumber: string) => {
    navigator.clipboard.writeText(phoneNumber).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const openServicePhone = () => {
    openMuiModal(<NoServicePhone onClose={closeMuiModal} />, 0);
  };

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full h-auto items-start pl-4">
          <span className="text-[16px] font-avenir text-white">
            Customize your communication settings
          </span>
        </div>
        <div className="flex w-full h-full items-start px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden"
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('phone')}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center">{<></>}</div>
                <div className="flex pl-4 justify-between items-start w-full">
                  <div className="flex w-full">
                    <span className="font-avenir font-bold text-[16px] text-[#727272]">
                      Your custom AI Phone #
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={isPhoneExpanded} unmountOnExit>
              <CardContent>
                {userServicePhone?.phone_number ? (
                  <>
                    <div className="flex items-start gap-x-1">
                      <div className="w-max h-12 px-[18px] rounded-full bg-black-alt flex items-center gap-[10px] text-[14px] text-gray-modal mb-10">
                        <p>{userServicePhone?.phone_number}</p>
                        <p>|</p>
                        <svg
                          width="9"
                          height="13"
                          viewBox="0 0 9 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.125 0.5C7.94531 0.5 8.625 1.17969 8.625 2V11C8.625 11.8438 7.94531 12.5 7.125 12.5H1.875C1.03125 12.5 0.375 11.8438 0.375 11V2C0.375 1.17969 1.03125 0.5 1.875 0.5H7.125ZM7.5 11V2C7.5 1.8125 7.3125 1.625 7.125 1.625H1.875C1.66406 1.625 1.5 1.8125 1.5 2V11C1.5 11.2109 1.66406 11.375 1.875 11.375H7.125C7.3125 11.375 7.5 11.2109 7.5 11ZM5.25 9.875C5.4375 9.875 5.625 10.0625 5.625 10.25C5.625 10.4609 5.4375 10.625 5.25 10.625H3.75C3.53906 10.625 3.375 10.4609 3.375 10.25C3.375 10.0625 3.53906 9.875 3.75 9.875H5.25Z"
                            fill="#DCDDD7"
                          />
                        </svg>
                      </div>
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600 ml-4 flex items-center justify-center h-12 w-12 rounded-full bg-[#E5E8E8]"
                        onClick={() => copyToClipboard(userServicePhone?.phone_number)}
                        title="Copy full URL"
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          size="xl"
                          color="black"
                          className="text-[12px]"
                        />
                      </button>
                      {copied && (
                        <span className="text-xs text-[#038164] font-inter ml-2">Copied!</span>
                      )}
                    </div>
                    {userServicePhone?.external_status !== 'registered' && (
                      <div>
                        <div className="h-12 rounded-full px-[10px] inline-flex items-center">
                          <p className="text-sm text-gray-400 text-left break-words">
                            Your Phone Number is Pending
                            <svg
                              className="ml-2 inline-block align-top cursor-pointer"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              //   onClick={() => handleModal('unregisteredPhoneNumber')}
                            >
                              <circle cx="12" cy="12" r="12" fill="#DCDDD7" />
                              <text
                                x="12"
                                y="16"
                                textAnchor="middle"
                                fontSize="16"
                                fontWeight="bold"
                                fill="#000"
                              >
                                i
                              </text>
                            </svg>
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <button
                    onClick={openServicePhone}
                    className="w-max h-12 px-[18px] rounded-full bg-black-alt flex items-center gap-[10px] text-[14px] text-gray-modal mb-10"
                  >
                    <p>Get my number</p>
                    <p>|</p>
                    <svg
                      width="9"
                      height="13"
                      viewBox="0 0 9 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.125 0.5C7.94531 0.5 8.625 1.17969 8.625 2V11C8.625 11.8438 7.94531 12.5 7.125 12.5H1.875C1.03125 12.5 0.375 11.8438 0.375 11V2C0.375 1.17969 1.03125 0.5 1.875 0.5H7.125ZM7.5 11V2C7.5 1.8125 7.3125 1.625 7.125 1.625H1.875C1.66406 1.625 1.5 1.8125 1.5 2V11C1.5 11.2109 1.66406 11.375 1.875 11.375H7.125C7.3125 11.375 7.5 11.2109 7.5 11ZM5.25 9.875C5.4375 9.875 5.625 10.0625 5.625 10.25C5.625 10.4609 5.4375 10.625 5.25 10.625H3.75C3.53906 10.625 3.375 10.4609 3.375 10.25C3.375 10.0625 3.53906 9.875 3.75 9.875H5.25Z"
                        fill="#DCDDD7"
                      />
                    </svg>
                  </button>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
};
