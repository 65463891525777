import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const PrimaryTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: 'black', // Bottom border when not focused
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: 'black', // Bottom border when focused
  },
  '& .MuiFilledInput-root': {
    fontSize: '16px',
    fontFamily: 'Avenir',
    letterSpacing: '0.05px',
    backgroundColor: 'rgba(0, 0, 0, 0.008)', // Background color
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.008)', // Background color on hover
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(0, 0, 0, 0.008)', // Background color when focused
    },
    '& input': {
      fontSize: '16px',
      fontFamily: 'Avenir',
      letterSpacing: '0.05px',
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
        {
          WebkitBoxShadow: '0 0 0 1000px transparent inset',
          WebkitTextFillColor: 'inherit',
          transition: 'background-color 5000s ease-in-out 0s',
        },
    },
  },
  '& MuiOutlinedInput-input': {
    fontSize: '16px',
    fontFamily: 'Avenir',
    letterSpacing: '0.05px',
  },
  '& .MuiOutlinedInput-root': {
    '& input': {
      fontSize: '16px',
      fontFamily: 'Avenir',
      letterSpacing: '0.05px',
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
        {
          WebkitBoxShadow: '0 0 0 1000px transparent inset',
          WebkitTextFillColor: 'inherit',
          transition: 'background-color 5000s ease-in-out 0s',
        },
    },
    '& fieldset': {
      borderRadius: '34px',
      borderColor: 'black',
      fontColor: '#BDBDBD',
    },
    '&:hover fieldset': {
      fontColor: 'black',
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      fontColor: 'black',
      borderRadius: '34px',
      borderColor: 'black',
    },
    '&.Mui-error fieldset': {
      borderColor: '#ef4444',
      fontColor: '#ef4444',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    // padding: '0px 28px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#ef4444',
  },
  '& .MuiInputLabel-root': {
    color: '#2B2B2B',
    // marginLeft: '18px',
    marginRight: '8px',
    fontSize: '16px',
    letterSpacing: '0.04px',
    fontFamily: 'Avenir',
    '&.Mui-focused': {
      color: '#2B2B2B',

      // marginLeft: '18px',
    },
    '&.Mui-error': {
      color: '#ef4444',
      '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
        color: '#ef4444',
      },
    },
    '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
      color: '#BDBDBD', // Tailwind `text-gray-500` equivalent when empty
      fontSize: '16px',
      letterSpacing: '0.04px',
      fontFamily: 'Avenir',
    },
    '& .MuiInputLabel-shrink': {
      color: 'black', // Tailwind `text-gray-700` equivalent when label is shrunk (not empty)
    },
  },
}));

export default PrimaryTextField;
