export function generateSMSConversationId(
  userSvcPhoneNumber: string,
  clientPhoneNumber: string,
  date?: Date,
): string {
  const currentDate = date
    ? date.toISOString().split('T')[0]
    : new Date().toISOString().split('T')[0];
  return `${clientPhoneNumber.replace(/[^a-zA-Z0-9]/g, '')}-${userSvcPhoneNumber.replace(/[^a-zA-Z0-9]/g, '')}-${currentDate}`;
}
