import {
  BookingConversationItem,
  BookingConversationMessageItem,
} from '@book-nestor-monorepo/shared-types';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { agentMenuDrawerTabProps } from '../AgentMenuDrawerTabs';
import { AgentMessageConversationDetailView } from './views/AgentMessageConversationDetailView';
import { AgentMessageDetailView } from './views/AgentMessageDetailView';
import { AgentMessageListView } from './views/AgentMessageListView';

export enum AgentMessageView {
  LIST = 'list',
  DETAIL = 'detail',
  CONVERSATION = 'conversation',
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function AgentMessages(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...agentMenuDrawerTabProps(index)} {...other}>
      {value === index && (
        <Box sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: '30px', paddingBottom: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function AgentMenuDrawerMessagesTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const [currentView, setCurrentView] = useState(AgentMessageView.LIST);
  const [agentMessage, setAgentMessage] = useState<BookingConversationItem | null>(null);

  const handleNavigate = (
    view: AgentMessageView,
    agentMessage?: BookingConversationItem,
    agentConversationMessage?: BookingConversationMessageItem,
  ) => {
    setCurrentView(view);
    if (agentMessage) {
      setAgentMessage(agentMessage);
    }
  };

  const renderView = () => {
    switch (currentView) {
      case AgentMessageView.LIST:
        return <AgentMessageListView onNavigate={handleNavigate} />;
      case AgentMessageView.DETAIL:
        return (
          <AgentMessageDetailView
            onNavigate={handleNavigate}
            agentMessage={agentMessage}
            onBackNavigate={() => setCurrentView(AgentMessageView.LIST)}
          />
        );
      case AgentMessageView.CONVERSATION:
        return (
          <AgentMessageConversationDetailView
            onNavigate={handleNavigate}
            agentMessage={agentMessage}
            onBackNavigate={() => setCurrentView(AgentMessageView.DETAIL)}
          />
        );
      default:
        return <AgentMessageListView onNavigate={handleNavigate} />;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <AgentMessages value={value} index={1}>
        <Box sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: '20px', paddingBottom: 0 }}>
          {renderView()}
        </Box>
      </AgentMessages>
    </div>
  );
}
