import {
  OnboardingTaskNames,
  USER_PERMISSIONS,
  UserServicePhone,
} from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import LoadingDots from '../../components/loading';
import { AuthContext } from '../../contexts/authContext';
import { getServicePhoneDetailsByUser } from '../../libs/services/voice';
import { formatPhoneNumber } from '../../libs/utils/phone.util';
import { faArrowRight, faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import FormikWrapper from '../../components/form-wrapper';
import { SaveButton } from '../../components/formLibrary/formSaveButton';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { AgentCardList } from '../../components/v2/Agent/AgentCardList';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useQuickStart } from '../../contexts/quickStartContext';
import { assignUserServicePhone } from '../../libs/services/voice';
import { areaCodeSchema } from '../../schemas/phone-number-location.schema';
import { hasAccess } from '../../libs/utils/hasAccess';
import { CommunicationBrochure } from './communication-brochure';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
export const CommunicationSettings = () => {
  const { bgColor } = useAppExperience();
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone>();
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { markTaskComplete } = useQuickStart();
  const user = useContext(AuthContext);
  const userCanUseCommunication = hasAccess(user?.token, USER_PERMISSIONS.CAN_USE_VOICE);

  useEffect(() => {
    fetchCommunication();
  }, [user.user]);

  const fetchCommunication = async () => {
    if (!user) return;
    const userServicePhoneResponse = await getServicePhoneDetailsByUser(user.user?.id || '');
    setUserServicePhone(userServicePhoneResponse);
    setIsLoading(false);
  };

  const copyToClipboard = (phoneNumber: string) => {
    navigator.clipboard.writeText(phoneNumber).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const openServicePhone = () => {
    openMuiModal(<NoServicePhone onClose={closeMuiModal} />, 3);
  };

  const initialValuesLocation = {
    areaCode: null,
  };

  const handleSubmitAddress = async (values: { areaCode: number | null }) => {
    if (!values.areaCode) {
      return;
    }
    try {
      await assignUserServicePhone({ areaCode: values.areaCode! });
      await markTaskComplete(OnboardingTaskNames.verifyAIAssistant);

      fetchCommunication();
      closeMuiModal();
    } catch (error) {
      alert(
        'No available phone numbers found in your area. Please try again with a different area.',
      );
      Sentry.captureException(error);
    }
  };

  const NoServicePhone = ({ onClose }: { onClose: () => void }) => {
    return (
      <div className="w-full">
        <div className="flex items-center justify-between gap-12">
          <span className="flex items-center justify-center text-[16px] font-avenir font-medium">
            Claim your new number
          </span>
          <button onClick={onClose} className="bg-black h-[40px] w-[40px] rounded-full p-2">
            <FontAwesomeIcon icon={faX} className="text-[14px] text-white" />
          </button>
        </div>
        <FormikWrapper<{ areaCode: number | null }>
          initialValues={initialValuesLocation}
          validationSchema={areaCodeSchema}
          onSubmit={handleSubmitAddress}
        >
          {({ dirty }) => <FormFieldsLocation dirty={dirty} />}
        </FormikWrapper>
      </div>
    );
  };

  const FormFieldsLocation = ({ dirty }: any) => {
    const { values, handleChange, submitForm, isSubmitting, isValid } = useFormikContext<{
      areaCode: number;
    }>();

    return (
      <div className="pt-2">
        <div className="flex w-full items-center mb-4">
          <div className="flex items-center relative w-full">
            <PrimaryTextField
              label="Area Code"
              color="primary"
              name="areaCode"
              fullWidth={true}
              type="number"
              required
              variant="filled"
              onChange={handleChange}
              value={values.areaCode}
              helperText="e.g. 305"
            />
          </div>
        </div>
        <div className="w-full pt-6">
          <SaveButton
            onClick={async () => {
              if (isValid) {
                await submitForm();
              }
            }}
            defaultText="Get my new number"
            loadingText={['Searching...', 'Enlisting AI Agent...', 'Almost there...']}
            loadingTextDuration={3500}
            successText="Success!"
            disabled={!isValid || !dirty}
            color="white"
            bgColor="black"
          />
        </div>
      </div>
    );
  };

  if (!userCanUseCommunication) {
    return (
      <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
        <div className="flex flex-col h-auto w-full overflow-hidden">
          <CommunicationBrochure />
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-3xl md:max-w-4xl lg:max-w-5xl  py-2 sm:px-6 lg:px-8 font-avenir">
      <div className="flex flex-col mx-auto  items-start justify-start">
        <div className="space-y-12 flex w-full">
          <div className="flex flex-col pb-12 w-full items-start justify-start p-4">
            <h2 className="text-[32px] font-medium font-avenir ">Communication Settings</h2>
            {isLoading ? (
              <div className="flex items-start justify-start w-full h-full max-w-sm mt-4">
                <LoadingDots
                  skeletonProps={{
                    count: 4,
                    height: '24px',
                    borderRadius: '40px',
                    style: {
                      marginBottom: '12px',
                    },
                  }}
                />
              </div>
            ) : (
              <div className="mt-10 flex flex-col items-start justify-start w-full max-w-lg">
                {userServicePhone?.phone_number ? (
                  <div className="flex flex-col items-start justify-start w-full">
                    <div className="flex w-full">
                      <span className="text-[16px] font-medium text-gray-900 font-avenir">
                        Phone Number
                      </span>
                    </div>
                    <div className="flex w-full justify-between">
                      <span className="text-[14px] text-gray-600 font-inter">
                        {formatPhoneNumber(userServicePhone?.phone_number || '')}
                      </span>
                      <div
                        className="flex items-center justify-center cursor-pointer"
                        onClick={() => copyToClipboard(userServicePhone?.phone_number || '')}
                      >
                        <span className="text-[14px] text-gray-600 font-inter underline">Copy</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-start justify-start w-full">
                    <div className="flex w-full cursor-pointer" onClick={openServicePhone}>
                      <span className="text-[16px] font-medium text-gray-900 font-avenir underline">
                        Get started with communication
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="text-[12px] text-gray-900 font-avenir ml-2"
                        />
                      </span>
                    </div>
                  </div>
                )}

                {userServicePhone?.agent_id && (
                  <div className="flex flex-col items-start justify-start w-full mt-12 border-t border-gray-300 pt-12">
                    <div className="flex flex-col w-full">
                      <span className="text-[22px] font-medium text-gray-900 font-avenir mb-1 text-left">
                        Your Agents
                      </span>
                      <span className="text-[14px]  text-gray-900 font-avenir mb-4 text-left">
                        Select the agent that you want to represent your business. Try all the
                        voices to find the best fit.
                      </span>
                      <AgentCardList current_agent_id={userServicePhone?.agent_id || ''} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
