import { useContext, useState } from 'react';
import { BookingProgressDots } from './booking-progress-dots';
import { SelectClient } from './select-client';
import { SelectService } from './select-service';
import { SelectDate } from './select-date';
import { SelectTime } from './select-time';
import { ConfirmBooking } from './confirm-booking';
import {
  AvailableTimeSlots,
  Booking,
  CreateBookingRequest,
  EventType,
  PaginatedBookingItem,
} from '@book-nestor-monorepo/shared-types';
import { Contact } from '@book-nestor-monorepo/shared-types';
import { AuthContext } from '../../../contexts/authContext';
import {
  bookingWebhook,
  createBooking,
  getAvailableSlots,
  getBookings,
} from '../../../libs/services/bookings';
import { formatYYYYMMDDDate } from '../../../libs/utils/date.util';
import dayjs from 'dayjs';
import React from 'react';

export type BookingData = {
  client: Contact | null;
  service: EventType | null;
  date: any;
  time: any;
  rescheduleFromBooking: Booking | null;
};

export const BookingContainer = ({
  onSuccessfulBooking,
  preLoadContactId,
  preLoadServiceId,
  rescheduleFromBooking,
}: {
  onSuccessfulBooking: () => Promise<void>;
  preLoadContactId?: string;
  preLoadServiceId?: string;
  rescheduleFromBooking?: Booking;
}) => {
  const { user } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<AvailableTimeSlots>({ slots: {} });
  const [bookingData, setBookingData] = useState<BookingData>({
    client: null,
    service: null,
    date: null,
    time: null,
    rescheduleFromBooking: rescheduleFromBooking || null,
  });

  const updateBookingData = (field: string, value: any) => {
    setBookingData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const fetchAvailableSlots = async (startDate: string, endDate: string) => {
    const slots = await getAvailableSlots(
      user?.id as string,
      Number(bookingData.service?.id),
      startDate,
      endDate,
    );
    setAvailableSlots(slots);
    return slots;
  };

  const handleStepChange = (stepIndex: number) => {
    // Validate if we can move to that step
    if (stepIndex > currentStep) {
      // Check if previous steps are completed
      const canProceed = validatePreviousSteps(stepIndex);
      if (!canProceed) return;
    }
    setCurrentStep(stepIndex);
  };

  const validatePreviousSteps = (targetStep: number) => {
    // Add validation logic based on the step
    switch (targetStep) {
      case 1: // Moving to Select Service
        return true; // Client selection is optional
      case 2: // Moving to Select Date
        return bookingData.service !== null;
      case 3: // Moving to Select Time
        return bookingData.date !== null;
      case 4: // Moving to Confirm
        return bookingData.time !== null;
      default:
        return true;
    }
  };

  const availableTimeSlotsForSelectedDate = () => {
    if (!bookingData.date || !availableSlots.slots) {
      return [];
    }

    // Format the date to match the key format in availableSlots
    const dateKey = formatYYYYMMDDDate(bookingData.date);

    // Return the slots for the selected date or an empty array if none exist
    return availableSlots.slots[dateKey] || [];
  };

  const fetchBookingsForSelectedDate = async (): Promise<PaginatedBookingItem> => {
    if (!bookingData.date || !user?.id) {
      return { items: [], meta: { hasMore: false, take: 100 } }; // Return an empty PaginatedBookingItem
    }

    const startDateDayJs = dayjs(bookingData.date);
    const startDate = startDateDayJs.toISOString();
    const endDate = startDateDayJs.add(1, 'day').toISOString();

    const bookings = await getBookings(user.id, undefined, {
      afterStart: startDate,
      beforeEnd: endDate,
      take: 100,
      sortStart: 'asc',
    });

    return bookings;
  };

  const handleCreateBooking = async () => {
    setIsLoading(true);
    try {
      const createBookingRequest: CreateBookingRequest = {
        contactId: bookingData.client?.id as string,
        serviceId: bookingData.service?.id as string,
        time: bookingData.time,
        rescheduleFromBookingId: rescheduleFromBooking?.id,
      };
      const booking = await createBooking(user?.id as string, createBookingRequest);
      await bookingWebhook({
        booking_id: booking?.id as string,
        user_id: user?.id as string,
        rescheduleUid: null,
        is_cancelled: false,
        contact_id: bookingData.client?.id as string,
      });
      await onSuccessfulBooking?.();
    } catch (error) {
      console.error('Error creating booking', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <SelectClient
            bookingData={bookingData}
            selectedClient={bookingData.client}
            onSelect={(client: Contact) => updateBookingData('client', client)}
            onNext={() => handleStepChange(1)}
            preLoadContactId={preLoadContactId}
          />
        );
      case 1:
        return (
          <SelectService
            bookingData={bookingData}
            onSelect={(service: any) => updateBookingData('service', service)}
            onNext={() => handleStepChange(2)}
            onBack={() => handleStepChange(0)}
            preLoadServiceId={preLoadServiceId}
          />
        );
      case 2:
        return (
          <SelectDate
            bookingData={bookingData}
            selectedDate={bookingData.date}
            onSelect={(date: any) => updateBookingData('date', date)}
            onNext={() => handleStepChange(3)}
            onBack={() => handleStepChange(1)}
            fetchAvailableSlots={fetchAvailableSlots}
          />
        );
      case 3:
        return (
          <SelectTime
            bookingData={bookingData}
            availableTimeSlotsForSelectedDate={availableTimeSlotsForSelectedDate}
            fetchBookingsForSelectedDate={fetchBookingsForSelectedDate}
            onSelect={(time: any) => updateBookingData('time', time)}
            onNext={() => handleStepChange(4)}
            onBack={() => handleStepChange(2)}
          />
        );
      case 4:
        return (
          <ConfirmBooking
            bookingData={bookingData}
            onBack={() => handleStepChange(3)}
            isLoading={isLoading}
            onConfirm={async () => {
              // Handle booking confirmation
              await handleCreateBooking();
            }}
            rescheduleFromBooking={rescheduleFromBooking}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full items-start justify-center bg-white p-2">
      <div className="flex w-full items-center justify-center mb-6">
        <BookingProgressDots currentStep={currentStep} onStepClick={handleStepChange} />
      </div>
      <div className="w-full">{renderCurrentStep()}</div>
    </div>
  );
};
