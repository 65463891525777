import { Booking, BookingStatus } from '@book-nestor-monorepo/shared-types';
import { useCancelBooking } from '@calcom/atoms';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { getBookings } from '../libs/services/bookings';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';
import dayjs from 'dayjs';
import { durationString } from '../libs/utils/date.util';
import { truncateText } from '../libs/utils/string.util';
import * as Sentry from '@sentry/react';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

export default function Calendar() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<TSelectedStatus>(BookingStatus.UPCOMING);
  const [bookings, setBookings] = useState<Record<TSelectedStatus, Booking[]> | null>(null);
  const authContext = useContext(AuthContext);
  const history = useNavigate();
  const { mutate: cancelBooking } = useCancelBooking({
    onSuccess: async () => {
      const user = authContext.user;
      if (!user) return;
      if (user?.id) {
        const [tokenUpcomingResponse, tokenPastResponse, tokenCancelledResponse] =
          await Promise.all([
            getBookings(user?.id, BookingStatus.UPCOMING),
            getBookings(user?.id, BookingStatus.PAST),
            getBookings(user?.id, BookingStatus.CANCELED),
          ]);
        setBookings({
          [BookingStatus.UPCOMING]: tokenUpcomingResponse,
          [BookingStatus.PAST]: tokenPastResponse,
          [BookingStatus.CANCELED]: tokenCancelledResponse,
        });
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id) {
          const [tokenUpcomingResponse, tokenPastResponse, tokenCancelledResponse] =
            await Promise.all([
              getBookings(user?.id, BookingStatus.UPCOMING),
              getBookings(user?.id, BookingStatus.PAST),
              getBookings(user?.id, BookingStatus.CANCELED),
            ]);

          setBookings({
            [BookingStatus.UPCOMING]: tokenUpcomingResponse,
            [BookingStatus.PAST]: tokenPastResponse,
            [BookingStatus.CANCELED]: tokenCancelledResponse,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  const handleStatusChange = (status: TSelectedStatus) => {
    setSelectedStatus(status);
  };

  const cancelBookingClick = async (booking: Booking) => {
    cancelBooking({
      id: booking.external_id,
      uid: booking.id,
      cancellationReason: 'User request',
      allRemainingBookings: true,
    });
  };

  function formatBookingTitle(str: string) {
    const regex = /^(.*) between .*? and (.*)$/;
    const match = str.match(regex);
    return match ? `${match[1]} with ${match[2]}` : str;
  }

  return (
    <ExperimentalAppLayout
      bookings
      pageTitle="Booked Appt."
      insightCardsStyles="hidden md:flex"
      componentName="Your bookings"
    >
      {isLoading ? (
        <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-4 pt-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 70,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="bg-[#E5E6E1] rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full pt-4">
          <h1 className="text-black-alt font-semibold pb-2 md:pb-20 pl-4 font-avenir">
            Your bookings
          </h1>
          <div className="flex overflow-x-auto mb-8 hide-scrollbar px-2">
            <button
              className={`px-[18px] text-[14px] flex items-center py-2 mr-2 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.UPCOMING
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.UPCOMING)}
            >
              Upcoming
              <span className="ml-[10px]">{bookings?.upcoming.length}</span>
            </button>
            <button
              className={`px-[18px] text-[14px] flex items-center py-2 mr-2 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.PAST
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.PAST)}
            >
              Past
              <span className="ml-[10px]">{bookings?.past.length}</span>
            </button>
            <button
              className={`px-[18px] text-[14px] flex items-center py-2 mr-2 font-avenir font-light rounded-full border border-transparent ${
                selectedStatus === BookingStatus.CANCELED
                  ? 'bg-black-alt text-white'
                  : '!border-black-alt text-black-alt'
              }`}
              onClick={() => handleStatusChange(BookingStatus.CANCELED)}
            >
              Canceled
              <span className="ml-[10px]">{bookings?.cancelled.length}</span>
            </button>
          </div>
          <div className="overflow-y-auto w-full h-full bg-white rounded-t-[40px] hide-scrollbar pt-[55px] px-6 pb-[115px]">
            {bookings?.[selectedStatus]?.length === 0 ? (
              <div className="text-center text-black">
                No {selectedStatus.toLowerCase()} bookings. As soon as someone books a{' '}
                {selectedStatus.toLowerCase()} meeting with you it will show up here.
              </div>
            ) : (
              bookings?.[selectedStatus]?.map((booking) => (
                <div
                  key={booking.id}
                  className="flex border-b pb-6 border-b-[#E5E7EA] justify-between items-end mt-4 first:mt-0 min-h-[130px]"
                >
                  <div className="w-[calc(100%-130px)]">
                    <h3 className="text-black-alt font-bold font-inter text-base flex items-center gap-1">
                      <span className="text-ellipsis overflow-hidden truncate text-nowrap">
                        {formatBookingTitle(booking.title)}
                      </span>
                    </h3>
                    <h3 className="text-black-alt  font-inter flex text-[9.89px] items-center gap-1">
                      <span className="text-nowrap">{durationString(booking)}</span>
                    </h3>
                    <div
                      onClick={() => history(`/contacts/${booking.contact.id}`)}
                      className="h-10 rounded-full w-max bg-black-alt px-[18px] text-[14px] flex items-center text-white font-avenir mt-[26px] cursor-pointer"
                    >
                      {truncateText(
                        booking.attendees.map((attendee) => attendee.name).join(', '),
                        30,
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="text-[10px] flex font-bold font-inter leading-4">
                      {new Date(booking.start_time).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}{' '}
                    </p>
                    <p className="text-[#00000080] text-[10px] flex font-light">
                      {new Date(booking.start_time).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                      })}{' '}
                      -{' '}
                      {new Date(booking.end_time).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                      })}
                    </p>
                    <div className="flex items-center gap-2 mt-[26px] justify-end">
                      {selectedStatus === BookingStatus.UPCOMING && (
                        <button
                          onClick={() => cancelBookingClick(booking)}
                          className="w-10 h-10 rounded-full bg-black-alt flex items-center justify-center"
                        >
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.8906 12.1641C11.0273 12.3555 11.0273 12.6289 10.8359 12.793C10.7539 12.8477 10.6445 12.875 10.5625 12.875C10.4258 12.875 10.2891 12.8477 10.207 12.7383L5.75 7.43359L1.26562 12.7383C1.18359 12.8477 1.04688 12.875 0.910156 12.875C0.828125 12.875 0.71875 12.8477 0.636719 12.793C0.445312 12.6289 0.445312 12.3555 0.582031 12.1641L5.14844 6.75L0.582031 1.36328C0.445312 1.17188 0.445312 0.898438 0.636719 0.734375C0.828125 0.597656 1.10156 0.597656 1.26562 0.789062L5.75 6.09375L10.207 0.789062C10.3711 0.597656 10.6445 0.597656 10.8359 0.734375C11.0273 0.898438 11.0273 1.17188 10.8906 1.36328L6.32422 6.75L10.8906 12.1641Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      <Link
                        to={`/contacts/${booking?.contact?.id}?target=newPayment&bookingId=${booking.id}`}
                        className={`w-10 h-10 rounded-full border border-black-alt flex items-center justify-center ${booking.contact_booking?.client_has_paid ? 'border-book-green' : dayjs().isAfter(dayjs(booking.end_time)) ? 'border-red-notification' : 'border-black-alt'}`}
                      >
                        <svg
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.875 9.8125C2.875 9.59375 3.06641 9.375 3.3125 9.375H5.0625C5.28125 9.375 5.5 9.59375 5.5 9.8125C5.5 10.0586 5.28125 10.25 5.0625 10.25H3.3125C3.06641 10.25 2.875 10.0586 2.875 9.8125ZM6.375 9.8125C6.375 9.59375 6.56641 9.375 6.8125 9.375H10.3125C10.5312 9.375 10.75 9.59375 10.75 9.8125C10.75 10.0586 10.5312 10.25 10.3125 10.25H6.8125C6.56641 10.25 6.375 10.0586 6.375 9.8125ZM0.25 2.375C0.25 1.41797 1.01562 0.625 2 0.625H14.25C15.207 0.625 16 1.41797 16 2.375V11.125C16 12.1094 15.207 12.875 14.25 12.875H2C1.01562 12.875 0.25 12.1094 0.25 11.125V2.375ZM1.125 2.375V3.25H15.125V2.375C15.125 1.91016 14.7148 1.5 14.25 1.5H2C1.50781 1.5 1.125 1.91016 1.125 2.375ZM1.125 5.875H15.125V4.125H1.125V5.875ZM1.125 6.75V11.125C1.125 11.6172 1.50781 12 2 12H14.25C14.7148 12 15.125 11.6172 15.125 11.125V6.75H1.125Z"
                            fill={
                              booking.contact_booking?.client_has_paid
                                ? '#43CA51'
                                : dayjs().isAfter(dayjs(booking.end_time))
                                  ? '#DB1B24'
                                  : '#000'
                            }
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
