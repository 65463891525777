import {
  PaymentMethod,
  SubscriptionProduct,
  SubscriptionProductPrice,
} from '@book-nestor-monorepo/shared-types';
import { Elements } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useStripePayment } from '../../contexts/paymentContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import {
  changeSubscription,
  createSubscription,
  getPaymentMethods,
  getSubscriptionProducts,
} from '../../libs/services/subscription.service';
import { getUserById } from '../../libs/services/user';
import { SubscriptionPaymentDrawer } from './right-drawer-components/subscription-payment-drawer';
import { SubscriptionV3 } from './subscription-v3';
export const SubscriptionContainer = () => {
  const { bgColor } = useAppExperience();
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [isSubscriptionCreated, setIsSubscriptionCreated] = useState(false);
  const [products, setProducts] = useState<SubscriptionProduct[]>([]);
  const [currentProduct, setCurrentProduct] = useState<SubscriptionProduct | undefined>();
  const [currentPrice, setCurrentPrice] = useState<SubscriptionProductPrice | undefined>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const { toggleDrawer, setDrawerContent, isOpen, closeDrawer } = useDrawer();
  const { stripe } = useStripePayment();
  const fetchData = async () => {
    if (!authContext.user) return;

    setIsLoading(true);
    setIsSubscriptionCreated(false);

    const products = await getSubscriptionProducts();
    setProducts(products);

    const defaultProduct = products.find((product) => product.isDefault);
    const defaultPrice = defaultProduct?.monthlyPrice;

    const currentPriceId = authContext.user?.external_subscription?.items[0]?.price.id;
    let currentProduct: SubscriptionProduct | undefined;
    let currentPrice: SubscriptionProductPrice | undefined;
    for (const product of products) {
      if (product.allActivePriceIds.includes(currentPriceId || '')) {
        currentProduct = product;
      }
      if (product.allMonthlyPriceIds.includes(currentPriceId || '')) {
        currentPrice = product.monthlyPrice;
        break;
      } else if (product.allYearlyPriceIds.includes(currentPriceId || '')) {
        currentPrice = product.yearlyPrice;
        break;
      }
    }
    setCurrentProduct(currentProduct || defaultProduct);
    setCurrentPrice(currentPrice || defaultPrice);

    const paymentMethods = await getPaymentMethods();
    setDefaultPaymentMethod(paymentMethods.find((method) => method.isDefault));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user]);

  const changeSubscriptionAction = async (
    newProduct: SubscriptionProductPrice,
    paymentMethod?: PaymentMethod,
    promoCode?: string,
  ) => {
    // setIsLoading(true);

    try {
      if (authContext.user?.external_payment_customer) {
        await changeSubscription(newProduct.id, paymentMethod?.id, promoCode);
      } else {
        await createSubscription(paymentMethod?.id as string, newProduct.id, promoCode);
      }
    } catch (error) {
      console.error('Error changing subscription', error);
      throw error;
    }

    setIsSubscriptionCreated(true);
    const user = await getUserById(authContext.user?.id as string);
    if (user && authContext.updateLoggedInUser) {
      await authContext.updateLoggedInUser(user);
    }
    closeDrawer();
  };

  useEffect(() => {
    if (!isSubscriptionCreated) return;

    // refresh the user's session to get the permissions
    authContext.updateSession && authContext.updateSession();
  }, [isSubscriptionCreated]);

  const handleChangeSubscriptionClick = (upgradeProduct?: SubscriptionProduct) => {
    if (isOpen) {
      setDrawerContent(
        <Elements stripe={stripe}>
          <SubscriptionPaymentDrawer
            selectedProduct={upgradeProduct as SubscriptionProduct}
            selectedPrice={upgradeProduct?.yearlyPrice as SubscriptionProductPrice}
            changeSubscriptionAction={changeSubscriptionAction}
          />
        </Elements>,
        {
          darkMode: false,
        },
      );
    } else {
      setDrawerContent(
        <Elements stripe={stripe}>
          <SubscriptionPaymentDrawer
            selectedProduct={upgradeProduct as SubscriptionProduct}
            selectedPrice={upgradeProduct?.yearlyPrice as SubscriptionProductPrice}
            changeSubscriptionAction={changeSubscriptionAction}
          />
        </Elements>,
        {
          darkMode: false,
        },
      );
      toggleDrawer();
    }
  };

  return (
    <div className={`font-avenir bg-[${bgColor}] font-avenir`}>
      {/* <div className="flex w-full justify-start">
        <h2 className="text-[32px]  font-medium font-avenir px-2">Subscription</h2>
      </div> */}
      <SubscriptionV3
        products={products}
        selectedProduct={currentProduct}
        changeSubscriptionAction={changeSubscriptionAction}
        selectedPrice={currentPrice}
        isLoading={isLoading}
        handleChangeSubscriptionClick={handleChangeSubscriptionClick}
      />
    </div>
  );
};
