import {
  BookingConversationItem,
  BookingConversationMessageItem,
  Insight,
} from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { environment } from '../../environments/environment';

export const getAgentConversations = async (userId: string): Promise<BookingConversationItem[]> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/users/${userId}/conversations`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.items;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch agent conversations');
  }
};

export const getAgentConversation = async (
  userId: string,
  conversationId: string,
): Promise<BookingConversationItem> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/users/${userId}/conversations/${conversationId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch agent conversations');
  }
};

export const getAgentMetrics = async (userId: string): Promise<Insight[]> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/insights/user/${userId}/ni/summary`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch agent metrics');
  }
};

export const getAgentConversationMessages = async (
  userId: string,
): Promise<BookingConversationMessageItem[]> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/users/${userId}/conversation-messages`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.items;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch agent conversation messages');
  }
};

export const updateAgentConversationMessageResponse = async (
  userId: string,
  messageId: string,
  content: string,
) => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/users/${userId}/conversation-messages/${messageId}/response`;

    const response = await axios.put(
      url,
      { content },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to update agent conversation message response');
  }
};

export const acknowledgeAgentConversationMessageResponse = async (
  userId: string,
  messageId: string,
) => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/users/${userId}/conversation-messages/${messageId}/acknowledge`;

    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to acknowledge agent conversation message response');
  }
};
