import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BookingConversationItem,
  BookingConversationMessageItem,
  Insight,
} from '@book-nestor-monorepo/shared-types';
import {
  getAgentConversation,
  getAgentConversationMessages,
  getAgentConversations,
  getAgentMetrics,
} from '../../libs/services/agent-conversations';
import { AuthContext } from '../authContext';

interface AgentContextType {
  isLoading: boolean;
  conversations: BookingConversationItem[];
  currentConversation: BookingConversationItem | null;
  conversationMessages: BookingConversationMessageItem[];
  agentMetrics: Insight[];
  fetchConversations: () => Promise<BookingConversationItem[]>;
  fetchConversation: (id: string) => Promise<BookingConversationItem | null>;
  fetchConversationMessages: () => Promise<BookingConversationMessageItem[]>;
  fetchConversationMetrics: (forceRefresh?: boolean) => Promise<Insight[]>;
}

interface AgentProviderProps {
  children: ReactNode;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export const useAgent = (): AgentContextType => {
  const context = useContext(AgentContext);

  if (!context) {
    throw new Error('useAgent must be used within an AgentProvider');
  }

  return context;
};

export const AgentProvider: FunctionComponent<AgentProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [conversations, setConversations] = useState<BookingConversationItem[]>([]);
  const [recoveryConversations, setRecoveryConversations] = useState<BookingConversationItem[]>([]);
  const [agentMetrics, setAgentMetrics] = useState<any>([]);
  const [conversationMessages, setConversationMessages] = useState<
    BookingConversationMessageItem[]
  >([]);
  const [currentConversation, setCurrentConversation] = useState<BookingConversationItem | null>(
    null,
  );

  const conversationsFetched = useRef<boolean>(false);
  const conversationMessagesFetched = useRef<boolean>(false);
  const agentMetricsFetched = useRef<boolean>(false);

  const handleFetchConversations = useCallback(
    async (forceRefresh = false): Promise<BookingConversationItem[]> => {
      if (conversationsFetched.current && !forceRefresh) {
        return [];
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getAgentConversations(user.id as string);
        setRecoveryConversations(result.filter((conversation) => conversation.is_recovery));
        setConversations(result.filter((conversation) => !conversation.is_recovery));
        return result;
      } catch (error) {
        console.error('Error fetching conversations:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [],
  );

  const handleFetchConversationMessages = useCallback(
    async (forceRefresh = false): Promise<BookingConversationMessageItem[]> => {
      if (conversationMessagesFetched.current && !forceRefresh) {
        return [];
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getAgentConversationMessages(user.id as string);
        setConversationMessages(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversations:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [],
  );

  const handleFetchConversation = useCallback(
    async (id: string): Promise<BookingConversationItem | null> => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return null;
        const result = await getAgentConversation(user.id as string, id);
        setCurrentConversation(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversation:', error);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [],
  );

  const handleFetchConversationMetrics = useCallback(
    async (forceRefresh = false): Promise<Insight[]> => {
      if (agentMetricsFetched.current && !forceRefresh) {
        return [];
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getAgentMetrics(user.id as string);
        setAgentMetrics(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversation metrics:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [],
  );

  useEffect(() => {
    handleFetchConversations();
    handleFetchConversationMessages();
    handleFetchConversationMetrics();
  }, [handleFetchConversations, handleFetchConversationMessages, handleFetchConversationMetrics]);

  return (
    <AgentContext.Provider
      value={{
        isLoading,
        conversations,
        currentConversation,
        conversationMessages,
        agentMetrics,
        fetchConversations: handleFetchConversations,
        fetchConversation: handleFetchConversation,
        fetchConversationMessages: handleFetchConversationMessages,
        fetchConversationMetrics: handleFetchConversationMetrics,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};
