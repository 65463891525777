import { AgentVoiceMetadata } from '@book-nestor-monorepo/shared-types';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { MultiPartSkeleton } from '../Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../../contexts/authContext';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { useToast } from '../../../contexts/v2/toastContext';
import { getAgents } from '../../../libs/services/agent';
import { updateUserServicePhone } from '../../../libs/services/voice';

export const AgentCardList = ({ current_agent_id }: { current_agent_id: string }) => {
  const [playing, setPlaying] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<string>();
  const [agents, setAgents] = useState<AgentVoiceMetadata[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { showToast } = useToast();
  const { servicePhone, updateUserServicePhone: updateContextServicePhone } =
    useContext(AuthContext);

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);
      const agents = await getAgents();
      setAgents(agents);
      setIsLoading(false);
    };
    fetchAgents();
    setSelectedRow(current_agent_id);
  }, []);

  useEffect(() => {
    // Cleanup function to stop audio when component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const handlePlay = async (id: string) => {
    try {
      // If we're already playing this audio
      if (playing === id) {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
        setPlaying(null);
        return;
      }

      // If we're playing something else, stop it
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }

      // Find the agent and play their sample
      const agent = agents.find((a) => a.id === id);
      if (!agent) return;

      // Create and play new audio
      const audio = new Audio(`${agent.mp3_sample}`);
      audioRef.current = audio;

      // Set up event listeners
      audio.addEventListener('ended', () => {
        setPlaying(null);
        audioRef.current = null;
      });

      audio.addEventListener('error', (e) => {
        console.error('Error playing audio:', e);
        setPlaying(null);
        audioRef.current = null;
      });

      // Play the audio
      await audio.play();
      setPlaying(id);
    } catch (error) {
      console.error('Error in handlePlay:', error);
      setPlaying(null);
      audioRef.current = null;
    }
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#FFFFFF" />;
  }

  async function updateAgent(newAgentId: string) {
    if (!servicePhone || !updateContextServicePhone) return;

    const updatedServicePhone = await updateUserServicePhone({
      ...servicePhone,
      agent_id: newAgentId,
    });
    setSelectedRow(newAgentId);
    showToast('Agent updated successfully');
    updateContextServicePhone(updatedServicePhone);
  }

  const handleConfirmAgentChange = ({ newAgentId }: { newAgentId: string }) => {
    openMuiModal(
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Box sx={{ mb: 3 }}>
          Are you sure you want to change your AI Agent from{' '}
          {agents.find((a) => a.agent_id === current_agent_id)?.name} to{' '}
          {agents.find((a) => a.agent_id === newAgentId)?.name}?
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            variant="contained"
            onClick={async (e) => {
              closeMuiModal();
              await updateAgent(newAgentId);
            }}
            sx={{
              bgcolor: '#000000',
              '&:hover': { bgcolor: '#333333' },
              borderRadius: '24px',
              textTransform: 'none',
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => closeMuiModal()}
            sx={{
              color: '#000000',
              borderColor: '#000000',
              '&:hover': { borderColor: '#333333', color: '#333333' },
              borderRadius: '24px',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>,
    );
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#FFFFFF" />;
  }

  const agentPills = {
    Brian: ['American', 'Young', 'Male'],
    Zuri: ['American', 'Older', 'Female'],
    Marissa: ['American', 'Young', 'Female'],
    Andrew: ['American', 'Young', 'Male'],
  };

  return (
    // <div className="mx-auto bg-white rounded-3xl shadow w-full">
    //   <div className="divide-y">
    //     {agents.map((item) => (
    //       <div
    //         key={item.id}
    //         className={`flex items-center justify-between p-4  transition-all cursor-pointer first:rounded-t-3xl last:rounded-b-3xl
    //           ${selectedRow === item.agent_id ? 'bg-[#E5E8E8]' : ''}`}
    //       >
    //         <div className="flex-none">
    //           <button
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               handlePlay(item.id);
    //             }}
    //             className="w-8 h-8 flex items-center justify-center rounded-full  transition-colors"
    //           >
    //             {playing === item.id ? (
    //               <FontAwesomeIcon icon={faPause} className="text-[12px] text-black-alt" />
    //             ) : (
    //               <FontAwesomeIcon icon={faPlay} className="text-[12px] text-black-alt" />
    //             )}
    //           </button>
    //         </div>

    //         <div className="flex items-start justify-start flex-grow ml-4">
    //           <img
    //             src={item.pfp_url}
    //             alt={item.name}
    //             className="w-8 h-8 rounded-full mx-4 object-cover"
    //           />
    //           <span className="text-left text-sm font-medium text-gray-900 font-avenir pt-[5px]">
    //             {item.name}
    //           </span>
    //         </div>

    //         <div className="flex-none">
    //           <button
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               // setSelectedRow(item.agent_id);
    //               handleConfirmAgentChange({ newAgentId: item.agent_id });
    //             }}
    //           >
    //             {selectedRow === item.agent_id ? (
    //               // <FontAwesomeIcon icon={faCheck} className="text-[18px] text-[#43CA51]" />
    //               <div className="flex items-center justify-center w-[72px] px-2 py-1 border border-[#43CA51] bg-[#43CA51] rounded-full">
    //                 <span className="text-[12px] text-white leading-4 font-avenir">Current</span>
    //               </div>
    //             ) : (
    //               <div className="flex items-center justify-center w-[72px] px-2 py-1 border border-black rounded-full">
    //                 <span className="text-[12px] text-black leading-4 font-avenir">Use Voice</span>
    //               </div>
    //             )}
    //           </button>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <ul className="divide-y divide-gray-100 w-full flex flex-col">
      {agents.map((agent) => (
        <li key={agent.id} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <div className="flex-none items-center justify-center h-full mt-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlay(agent.id);
                }}
                className="w-8 h-8 flex items-center justify-center rounded-full  transition-colors"
              >
                {playing === agent.id ? (
                  <FontAwesomeIcon icon={faPause} className="text-[12px] text-black-alt" />
                ) : (
                  <FontAwesomeIcon icon={faPlay} className="text-[12px] text-black-alt" />
                )}
              </button>
            </div>
            <img alt="" src={agent.pfp_url} className="size-12 flex-none rounded-full bg-gray-50" />

            <div className="min-w-0 flex-auto">
              <p className="text-sm/6 font-semibold text-gray-900 text-left">{agent.name}</p>
              {/* <p className="mt-1 truncate text-xs/5 text-gray-500">{agent.email}</p> */}
              <div className="flex flex-row">
                {agentPills[agent?.name as keyof typeof agentPills]?.map((pill) => (
                  <div key={pill} className="flex ">
                    <div className="flex h-[19px] w-auto items-center p-2 bg-white rounded-full mr-1">
                      <span className="text-[10px] font-avenir">{pill}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex-none">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  // setSelectedRow(item.agent_id);
                  handleConfirmAgentChange({ newAgentId: agent.agent_id });
                }}
              >
                {selectedRow === agent.agent_id ? (
                  // <FontAwesomeIcon icon={faCheck} className="text-[18px] text-[#43CA51]" />
                  <div className="flex items-center justify-center w-[72px] px-2 py-1 border border-[#43CA51] bg-[#43CA51] rounded-full">
                    <span className="text-[12px] text-white leading-4 font-avenir">Current</span>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-[72px] px-2 py-1 border border-black rounded-full">
                    <span className="text-[12px] text-black leading-4 font-avenir">Use Voice</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
