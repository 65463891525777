interface EditReviewType {
  title: string;
  onClose: () => void;
}

const EditReviewModal = ({ title, onClose }: EditReviewType) => {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-row items-center ${title ? 'justify-between' : 'justify-end'} h-12 mb-24`}
      >
        {title && <p className="font-inter font-medium text-base leading-4 text-white">{title}</p>}
        <span
          className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-white"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9  mb-[72px]">
        <p className="text-sm leading-6 text-white font-normal font-avenir">
          Hello Susan, we hope you found your recent massage session relaxing and rejuvenating. We
          would be grateful if you could share your experience by leaving a quick review here:
          [Link]. Thank you for your support!
        </p>
      </div>
      <div className="flex flex-row justify-start pb-2">
        <button className="rounded-full flex items-center justify-center border border-white text-white h-12 font-avenir px-[18px] py-[3]">
          Save as New default message
        </button>
      </div>
      <div className="flex gap-2">
        <button className="rounded-full flex items-center justify-center bg-white text-black-text h-12 font-avenir px-[18px] py-[3]">
          just this one time
        </button>
        <button className="rounded-full flex items-center justify-center border border-white text-white h-12 font-avenir px-[18px] py-[3]">
          Send to susan now?
        </button>
      </div>
    </div>
  );
};

export default EditReviewModal;
