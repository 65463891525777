import * as moment from 'moment-timezone';

export type DatePart = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
export function convertUtcToTimeZone(date: Date, timeZone: string): Date {
  // Validate input
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  if (typeof timeZone !== 'string' || timeZone.trim() === '') {
    throw new Error('Invalid time zone input');
  }

  try {
    // Test if the time zone is valid
    new Intl.DateTimeFormat('en-US', { timeZone }).format(date);
  } catch (error) {
    throw new Error(`Invalid time zone: ${timeZone}`);
  }

  const utcDate = new Date(date.toUTCString());
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const parts = dateTimeFormat.formatToParts(utcDate);

  const getDatePart = (type: DatePart): string => {
    const part = parts.find((part) => part.type === type);
    if (!part) {
      throw new Error(`Failed to parse ${type}`);
    }
    return part.value.padStart(2, '0');
  };

  const year = getDatePart('year');
  const month = getDatePart('month');
  const day = getDatePart('day');
  const hour = getDatePart('hour');
  const minute = getDatePart('minute');
  const second = getDatePart('second');

  // Constructing a date string in the specified time zone
  const tzDateStr = `${year}-${month}-${day}T${hour}:${minute}:${second}`;

  // Create a date object in UTC, accounting for the time zone offset
  const tzDate = new Date(tzDateStr);
  const tzOffset = tzDate.getTimezoneOffset() * 60000;

  return new Date(tzDate.getTime() - tzOffset);
}

export function convertToMomentTimeZone(date: Date, timeZone: string) {
  return moment.tz(date, timeZone).format();
}

export function convertToMomentTimeZoneForBooker(date: string, timeZone: string) {
  return moment
    .tz(date, timeZone)
    .utc() // convert to UTC
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}
