import {
  AssignUserServicePhone,
  USER_PERMISSIONS,
  UserPhoneVoiceMessage,
  UserServicePhone,
} from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { hasAccess } from '../utils/hasAccess';

export const getServicePhoneDetailsByUser = async (
  id: string,
): Promise<UserServicePhone | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const idToken = session?.tokens?.idToken?.payload;
    if (!hasAccess(idToken, USER_PERMISSIONS.CAN_USE_VOICE)) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/voice/${id}/phone-owner-details`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};

export const assignUserServicePhone = async (values: AssignUserServicePhone) => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(`${apiBaseUrl}/voice/service-phone/assign`, values, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error assigning user phone');
  }
};

export const updateUserServicePhone = async (
  userServicePhone: UserServicePhone,
): Promise<UserServicePhone> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.put(
      `${apiBaseUrl}/voice/${userServicePhone.user_id}/service-phone/${userServicePhone.phone_number}`,
      userServicePhone,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return { ...userServicePhone };
  }
};

export const getVoiceMessages = async (phoneNumber: string): Promise<UserPhoneVoiceMessage[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(
      `${apiBaseUrl}/voice/service-phone/${phoneNumber}/voice-messages`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data.items || [];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const getVoiceMessageContent = async (
  phoneNumber: string,
  voiceMessageId: string,
): Promise<any> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(
      `${apiBaseUrl}/voice/service-phone/${phoneNumber}/voice-messages/${voiceMessageId}`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
        responseType: 'blob',
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};
