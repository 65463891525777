import { faCheck, faPlus, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export function MessageSendButton({
  onClick,
  disabled,
}: {
  onClick: () => Promise<void>;
  disabled?: boolean;
}) {
  const [buttonState, setButtonState] = useState<'default' | 'loading' | 'success'>('default');

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (buttonState === 'default' && !disabled) {
      setButtonState('loading');
      try {
        await onClick();
        setButtonState('success');
        setTimeout(() => setButtonState('default'), 1000);
      } catch (error) {
        setButtonState('default');
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex font-avenir items-center h-11 border text-[12px] text-white rounded-full w-auto leading-4 ${
        disabled ? 'opacity-60 cursor-not-allowed' : 'bg-black'
      }`}
      disabled={buttonState !== 'default' || disabled}
    >
      {/* {buttonState === 'default' && 'Send'} */}

      <div className={`h-10 w-10 bg-black flex items-center justify-center rounded-full  `}>
        {buttonState === 'default' && <FontAwesomeIcon icon={faPlus} color="white" />}
        {buttonState === 'loading' && <FontAwesomeIcon icon={faSpinner} spin color="white" />}
        {buttonState === 'success' && <FontAwesomeIcon icon={faCheck} color="white" />}
      </div>
    </button>
  );
}
