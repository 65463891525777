import { EventCategory } from '@book-nestor-monorepo/shared-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import PrimaryTextField from '../../../components/formLibrary/primaryTextInput';
import LoadingDots from '../../../components/loading';
import { AuthContext } from '../../../contexts/authContext';
import {
  createEventCategory,
  getEventCategories,
} from '../../../libs/services/event-category.service';

export const ServiceCategoryDrawer = () => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [eventCategories, setEventCategories] = useState<EventCategory[]>([]);

  useEffect(() => {
    const fetchEventCategories = async () => {
      if (!authContext.user || !authContext.user.id) return;
      setIsLoading(true);
      const eventCategories = await getEventCategories();
      setEventCategories(eventCategories);
      setIsLoading(false);
    };
    fetchEventCategories();
  }, []);

  const handleAddCategory = async () => {
    if (!newCategoryName) return;

    const newCategory = await createEventCategory({ name: newCategoryName });
    setEventCategories([...eventCategories, newCategory]);
    setNewCategoryName('');
  };

  return (
    <div className="flex flex-col w-full items-start justify-center">
      <div className="flex flex-col w-full items-start justify-center mb-4 border-b-2 border-gray-200 pb-4">
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <span className="text-[16px] font-medium text-left font-avenir">Service Categories</span>
          <span className="text-[12px] text-left font-avenir text-gray-600">
            Manage the categories of services that you offer
          </span>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 30,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <span className="text-[16px] font-medium text-left font-avenir">Your Categories</span>
          <div className="relative w-full mt-8">
            {/* Timeline items */}
            <div className="space-y-6">
              <div className="flex w-full items-start gap-2">
                <PrimaryTextField
                  label="Enter category name"
                  color="primary"
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  type="text"
                  name="categoryName"
                  variant="filled"
                  fullWidth={true}
                  required={true}
                  value={newCategoryName}
                />
                <button
                  className="w-12 justify-center items-center flex"
                  onClick={handleAddCategory}
                >
                  <div className="w-8 h-8 py-4 bg-[#B8E7F4] rounded-[59px] border justify-center items-center inline-flex">
                    <div className="w-4 h-4 pl-px flex-col justify-center items-center inline-flex">
                      <FontAwesomeIcon icon={faPlus} color="black" className="text-[12px]" />
                    </div>
                  </div>
                </button>
              </div>
              {/* Categories list section */}
              <div className="mt-4 flex w-full items-start gap-2">
                {eventCategories
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((eventCategory) => (
                    <div key={eventCategory.id} className="justify-start items-start flex">
                      <div className="flex h-[29px] w-auto items-center py-4 px-5 bg-[#B8E7F4] rounded-full mr-1">
                        <span className="text-[14px] font-avenir">{eventCategory.name}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
