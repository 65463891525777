import {
  EventCategory,
  EventType,
  EventTypeLocationType,
  OnboardingTaskNames,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import mime from 'mime';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { ConfirmDeleteButton } from '../../../components/formLibrary/confirmDeleteButton';
import { SaveButton } from '../../../components/formLibrary/formSaveButton';
import PrimaryTextField from '../../../components/formLibrary/primaryTextInput';
import LoadingDots from '../../../components/loading';
import { useQuickStart } from '../../../contexts/quickStartContext';
import { environment } from '../../../environments/environment';
import {
  createEventCategory,
  getEventCategories,
} from '../../../libs/services/event-category.service';
import { createEventType, deleteEventType, getEventType } from '../../../libs/services/event-type';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { useServices } from '../../../contexts/v2/eventTypesContext';

interface EventTypeForm {
  title: string;
  categories: { id: string; name: string }[];
  slug?: string;
  description?: string;
  duration_mins: string;
  price: string;
  file?: any;
  service_address: string;
  display_location_publicly: boolean;
  host_phone_number?: string;
  booking_reminders_enabled: boolean;
  booking_reminders_window: ('3hours' | '24hours' | '48hours')[];
  minimum_booking_notice_mins: number;
  slot_interval: number;
}

export const ServiceDetailDrawer = ({
  serviceId,
  onRefresh,
  toggleDrawer,
}: {
  serviceId: string;
  onRefresh?: () => void;
  toggleDrawer?: () => void;
}) => {
  const MAX_DESCRIPTION_LENGTH = 100;
  const { fetchServices } = useServices();
  const { markTaskComplete } = useQuickStart();
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);

  const fileInputRef = useRef<any>(null);
  const [previewSrc, setPreviewSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [eventType, setEventType] = useState<EventType | undefined>(undefined);
  const [eventCategories, setEventCategories] = useState<EventCategory[] | undefined>(undefined);
  const [newCategoryName, setNewCategoryName] = useState('');

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (serviceId) {
        const eventType = await getEventType(serviceId);

        if (eventType) {
          setEventType(eventType);

          const eventTypeValues: EventTypeForm = {
            title: eventType.title,
            categories:
              eventType.categories?.map((cat: any) => ({ id: cat.id, name: cat.name })) || [],
            slug: eventType.slug || '',
            description: eventType.description || '',
            duration_mins: eventType.duration_mins?.toString() || '',
            price: eventType.price?.toString() || '',
            file: undefined,
            service_address: eventType.location?.address || 'My Business Location',
            display_location_publicly: eventType.location?.displayLocationPublicly || false,
            host_phone_number: eventType.location?.hostPhoneNumber || '',
            booking_reminders_enabled: eventType.settings?.booking_reminders.enabled ?? true,
            booking_reminders_window: eventType.settings?.booking_reminders.window ?? ['24hours'],
            minimum_booking_notice_mins: eventType.minimum_booking_notice_mins || 10,
            slot_interval: eventType.slot_interval || eventType.duration_mins,
          };
          formik.setValues(eventTypeValues);

          if (eventType.image_key) {
            setPreviewSrc(`${environment.s3.bucketUrl}/${eventType.image_key}`);
          }

          const session = await fetchAuthSession();
          if (!session) return;

          const idToken = session?.tokens?.idToken?.payload;
          setIsVoiceEnabled(hasAccess(idToken, USER_PERMISSIONS.CAN_USE_VOICE));
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    const eventCategory = await getEventCategories();
    setEventCategories(eventCategory);
  };

  useEffect(() => {
    fetchCategories();

    if (serviceId !== 'new') {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [serviceId]);

  const initialValues: EventTypeForm = {
    title: '',
    categories: [],
    slug: '',
    description: '',
    duration_mins: '',
    price: '',
    file: undefined,
    service_address: 'My Business Location',
    display_location_publicly: false,
    host_phone_number: '',
    booking_reminders_enabled: true,
    booking_reminders_window: ['24hours'],
    minimum_booking_notice_mins: 10,
    slot_interval: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string().max(MAX_DESCRIPTION_LENGTH),
      duration_mins: Yup.number().required('Duration is required').positive().integer(),
      price: Yup.number().required('Price is required'),
      slug: Yup.string().nullable(),
      service_address: Yup.string().required(),
      display_location_publicly: Yup.boolean(),
      host_phone_number: Yup.string().nullable(),
      booking_reminders_enabled: Yup.boolean(),
      booking_reminders_window: Yup.array().of(
        Yup.string().oneOf(['3hours', '24hours', '48hours']),
      ),
      minimum_booking_notice_mins: Yup.number().required('Minimum notice is required').min(0),
      slot_interval: Yup.number().required('Slot interval is required').min(0),
    }),
    onSubmit: async (values) => {
      const uploadName = values.file && `${uuidv4()}.${mime.getExtension(values.file.type)}`;
      await createEventType(
        { ...mapToCreateEvent(values) },
        { file: values.file as any, uploadName },
        eventType,
      );
      await fetchServices(true);
      onRefresh?.();
    },
  });

  const mapToCreateEvent = (values: any): EventType => {
    return {
      title: values.title,
      categories: values.categories,
      description: values.description,
      duration_mins: Number(values.duration_mins),
      price: Number(values.price),
      minimum_booking_notice_mins: Number(values.minimum_booking_notice_mins),
      slot_interval: Number(values.slot_interval),
      location: {
        type: EventTypeLocationType.IN_PERSON,
        address: values.service_address,
        displayLocationPublicly: values.display_location_publicly,
        hostPhoneNumber: values.host_phone_number,
      },
      settings: {
        booking_reminders: {
          enabled: values.booking_reminders_enabled,
          window: values.booking_reminders_window,
          delivery_methods: {
            email: true, // assuming email delivery method is always enabled for now
            sms: true, // assuming sms delivery method is always enabled for now
          },
        },
      },
    };
  };

  const handleFileButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    formik.setFieldValue('file', file);

    const reader: any = new FileReader();
    reader.onloadend = () => {
      setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCategoryChange = (category: { id: string; name: string }) => {
    const { categories } = formik.values;
    const categoryExists = categories.some((cat) => cat.id === category.id);

    if (categoryExists) {
      formik.setFieldValue(
        'categories',
        categories.filter((cat) => cat.id !== category.id),
      );
    } else {
      formik.setFieldValue('categories', [...categories, category]);
    }
  };

  const handleAddCategory = async () => {
    if (!newCategoryName) return;

    await createEventCategory({ name: newCategoryName });

    fetchCategories();
    setNewCategoryName('');
    onRefresh?.();
  };

  const handleReminderWindowChange = (window: '3hours' | '24hours' | '48hours') => {
    const currentWindows = formik.values.booking_reminders_window;
    let updatedWindow;

    if (currentWindows.includes(window)) {
      updatedWindow = currentWindows.filter((w) => w !== window);
    } else {
      updatedWindow = [...currentWindows, window];
    }

    formik.setFieldValue('booking_reminders_window', updatedWindow);
  };

  const handleDelete = async () => {
    // Implement the delete functionality here
    await deleteEventType(serviceId as string);
    onRefresh?.();
  };

  return (
    <div className="flex flex-col w-full items-start justify-center bg-black">
      <div className="flex flex-col w-full items-start justify-center mb-2 border-b-2 border-gray-200 pb-4">
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          {isLoading ? (
            <LoadingDots
              skeletonProps={{
                count: 2,
                height: 15,
                width: 100,
                borderRadius: 10,
                style: { marginBottom: '4px' },
              }}
            />
          ) : (
            <div className="flex w-full items-start justify-between">
              <div className="flex flex-col w-full items-start justify-start">
                <span className="text-[16px] font-medium text-left font-avenir text-white">
                  {eventType?.title || 'Create Service'}
                </span>
                <span className="text-[12px] text-left font-avenir text-white">Service</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col w-full items-start justify-center p-2">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 30,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full items-start justify-center p-2">
          <div className="flex justify-between items-center w-full pb-2">
            <div className="flex flex-row justify-end gap-2">
              {serviceId !== 'new' && <ConfirmDeleteButton onClick={handleDelete} />}
              <SaveButton
                onClick={async () => {
                  if (formik.isValid) {
                    await formik.submitForm();
                    await markTaskComplete(OnboardingTaskNames.createAService);
                  }
                }}
                disabled={!formik.isValid}
                bgColor="[#DEDEDE]"
                color="black"
                defaultText={serviceId === 'new' ? 'Create' : 'Update'}
                loadingText={serviceId === 'new' ? 'Creating...' : 'Updating...'}
                successText={serviceId === 'new' ? 'Created!' : 'Updated!'}
              />
            </div>
          </div>

          <div className="flex-1 overflow-y-auto hide-scrollbar">
            <Card
              className="mb-4 shadow-md rounded-3xl overflow-hidden "
              sx={{
                borderRadius: '30px',
                backgroundColor: 'white',
                marginBottom: '8px',
                minHeight: '60px',
                width: '100%',
              }}
            >
              <CardContent
                sx={{
                  padding: '8px',
                  backgroundColor: 'white',
                  '&:last-child': {
                    paddingBottom: '8px',
                  },
                }}
                className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              >
                <div className="flex items-center w-full">
                  <div className="flex items-center">{<></>}</div>
                  <div className="flex pl-4 justify-between items-start w-full">
                    <div className="flex w-full">
                      <span className="font-avenir text-[16px] text-[#727272]">
                        {serviceId === 'new' ? 'Create Service' : 'Edit Service'}
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>
              <Collapse in={true} unmountOnExit>
                <CardContent
                  sx={{
                    padding: '0px',
                    backgroundColor: 'white',
                    '&:last-child': {
                      paddingBottom: '0px',
                    },
                    height: '100%',
                  }}
                >
                  <div className="bg-white rounded-t-3xl  w-full p-2">
                    <div className="flex flex-col h-full ">
                      <div className="flex flex-col items-center text-center bg-white rounded-3xl h-full overflow-y-scroll hide-scrollbar pb-6">
                        <form
                          onSubmit={formik.handleSubmit}
                          className="w-full flex justify-center items-center flex-col mb-2"
                        >
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/jpeg,image/png,image/heic"
                          />
                          <div className="flex h-[200px] w-full justify-between gap-1">
                            <div
                              className={`flex w-full ${
                                previewSrc ? 'bg-transparent' : 'bg-black'
                              } rounded-2xl`}
                            >
                              {previewSrc ? (
                                <img
                                  src={previewSrc}
                                  alt="Preview"
                                  className="h-full w-full object-cover rounded-2xl"
                                />
                              ) : null}
                            </div>
                            <div
                              className="flex w-[75px] bg-black rounded-2xl items-center justify-center cursor-pointer"
                              onClick={handleFileButtonClick}
                            >
                              <FontAwesomeIcon icon={faPlus} className="text-white text-[12px]" />
                            </div>
                          </div>

                          <div className="w-full  font-avenir flex flex-col gap-x-4 gap-y-4 pt-8">
                            <div className="w-full inline-flex flex-row h-[48px]">
                              <PrimaryTextField
                                label="Service Name"
                                color="primary"
                                type="text"
                                variant="filled"
                                required={true}
                                fullWidth={true}
                                {...formik.getFieldProps('title')}
                              />
                            </div>
                            <div className="w-full inline-flex flex-row h-[48px] b gap-4 mt-2">
                              <PrimaryTextField
                                label="Price"
                                color="primary"
                                variant="filled"
                                type="number"
                                required={true}
                                {...formik.getFieldProps('price')}
                              />
                              <PrimaryTextField
                                label="Duration Mins."
                                color="primary"
                                variant="filled"
                                type="number"
                                required={true}
                                {...formik.getFieldProps('duration_mins')}
                              />
                            </div>
                            <div className="w-full inline-flex flex-row h-[48px] b gap-4 mt-2">
                              <div className=" flex-col">
                                <PrimaryTextField
                                  label="Minimum Notice Mins."
                                  color="primary"
                                  variant="filled"
                                  type="number"
                                  required={true}
                                  {...formik.getFieldProps('minimum_booking_notice_mins')}
                                />
                              </div>
                              {serviceId !== 'new' && (
                                <div className=" flex-col">
                                  <PrimaryTextField
                                    label="Slot Interval"
                                    color="primary"
                                    variant="filled"
                                    type="number"
                                    required={true}
                                    {...formik.getFieldProps('slot_interval')}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="relative w-full flex flex-col items-start   mt-4">
                              <PrimaryTextField
                                label="Description"
                                color="primary"
                                variant="filled"
                                type="text"
                                multiline={true}
                                fullWidth={true}
                                {...formik.getFieldProps('description')}
                                rows={4}
                                inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                                helperText={`${formik.values.description?.length || 0}/${MAX_DESCRIPTION_LENGTH}`}
                              />
                            </div>
                            <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                              Booking Limits
                            </label>
                            <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                              Locations
                            </label>
                            <div className="flex flex-wrap mt-4 gap-2">
                              <button
                                onClick={() =>
                                  formik.setValues({
                                    ...formik.values,
                                    service_address: 'My Business Location',
                                  })
                                }
                                value={formik.values.service_address}
                                type="button"
                                className={`h-[48px] rounded-[34px] px-6 text-[14px] flex items-center ${formik.values.service_address === 'My Business Location' ? '!bg-black-alt !text-white' : '!bg-[#F7F7F7]'}`}
                              >
                                My Business Location
                              </button>
                              <button
                                onClick={() =>
                                  formik.setValues({
                                    ...formik.values,
                                    service_address: 'Client Location',
                                  })
                                }
                                type="button"
                                value={formik.values.service_address}
                                className={`h-[48px] rounded-[34px] px-6 text-[14px] flex items-center ${formik.values.service_address === 'Client Location' ? '!bg-black-alt !text-white' : '!bg-[#F7F7F7]'}`}
                              >
                                Client Location
                              </button>
                            </div>
                            <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                              Categories
                            </label>
                            <div className="flex gap-2 mt-[22px]">
                              <input
                                placeholder="Category Name:"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                                className="w-full px-[18px] focus:outline-none text-[14px] max-w-[292px] h-12 rounded-full bg-gray-alt border-0 placeholder:text-[#C7C7C2] text-black-alt"
                              />

                              <button
                                onClick={handleAddCategory}
                                type="button"
                                className="w-12 min-w-12 h-12 rounded-full flex items-center justify-center border border-black-alt"
                              >
                                <svg
                                  width="11"
                                  height="10"
                                  viewBox="0 0 11 10"
                                  fill="#000000"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.5 0.875V4.25H9.875C10.2734 4.25 10.625 4.60156 10.625 5C10.625 5.42188 10.2734 5.75 9.875 5.75H6.5V9.125C6.5 9.54688 6.14844 9.875 5.75 9.875C5.32812 9.875 5 9.54688 5 9.125V5.75H1.625C1.20312 5.75 0.875 5.42188 0.875 5C0.875 4.60156 1.20312 4.25 1.625 4.25H5V0.875C5 0.476562 5.32812 0.125 5.75 0.125C6.14844 0.125 6.5 0.476562 6.5 0.875Z"
                                    fill="#000000"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="flex flex-wrap gap-[9px] mt-4">
                              {(eventCategories || []).map(({ name, id }) => (
                                <button
                                  key={id}
                                  onClick={() => handleCategoryChange({ name, id: id! })}
                                  type="button"
                                  className={`h-12 border border-transparent rounded-full px-[18px] flex items-center gap-[10px] transition duration-300 ${formik.values.categories.some((cat) => cat.id === id) ? '!bg-black-alt !text-white' : '!text-black-alt !bg-[#F7F7F7]'}`}
                                >
                                  <p className="text-[14px]">{name}</p>
                                </button>
                              ))}
                            </div>
                            <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                              Reminder Notifications
                            </label>
                            <p className="text-left text-[10px] text-black font-inter  mb-4">
                              {isVoiceEnabled
                                ? 'Email & SMS reminders will be sent to the client per the specified Reminder Windows'
                                : 'Email reminders will be sent to the client per the specified Reminder Windows.  Upgrade to enable SMS reminders.'}
                            </p>
                            <div className="flex flex-col gap-2 mt-[8px] ">
                              <div className="flex w-[105px] h-10 rounded-full bg-[#EBEBEB] p-[2px] mb-4">
                                <div className="h-full w-[67px] min-w-[67px] rounded-full bg-black flex items-center justify-center">
                                  <p
                                    className={`text-[12px] font-avenir font-medium ${formik.values.booking_reminders_enabled ? 'text-book-green' : 'text-red-notification'}`}
                                  >
                                    {formik.values.booking_reminders_enabled
                                      ? 'Active'
                                      : 'Inactive'}
                                  </p>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    formik.setFieldValue(
                                      'booking_reminders_enabled',
                                      !formik.values.booking_reminders_enabled,
                                    );
                                  }}
                                  className="w-full flex items-center justify-center"
                                >
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                                      fill="#303030"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <h4 className="text-[14px] font-avenir text-black-text text-left mb-4">
                                Email & SMS Reminder Window
                              </h4>
                              <div
                                className={`flex flex-row gap-2 ${
                                  formik.values.booking_reminders_enabled ? '' : '!opacity-50'
                                }`}
                              >
                                {(['3hours', '24hours', '48hours'] as const).map((window) => (
                                  <button
                                    key={window}
                                    onClick={() => handleReminderWindowChange(window)}
                                    type="button"
                                    className={`h-10 border ${
                                      formik.values.booking_reminders_window.includes(window)
                                        ? 'border-black bg-black text-white'
                                        : 'border-black text-black'
                                    } rounded-full px-[14px] flex items-center gap-[10px] transition duration-300`}
                                    disabled={!formik.values.booking_reminders_enabled}
                                  >
                                    <p className="text-[14px]">
                                      {window === '3hours'
                                        ? '3 hours'
                                        : window === '24hours'
                                          ? '24 hours'
                                          : '48 hours'}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};
