import {
  BookingConversationMessageItem,
  BookingConversationMessageResponseStatus,
} from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import {
  getAgentConversationMessages,
  updateAgentConversationMessageResponse,
} from '../libs/services/agent-conversations';
import { useAgent } from '../contexts/v2/agentContext';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

export default function AgentConversationMessages() {
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [messages, setMessages] = useState<BookingConversationMessageItem[]>([]);
  const [responses, setResponses] = useState<{ [key: string]: string }>({});
  const { fetchConversationMessages } = useAgent();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id) {
          const messages = await getAgentConversationMessages(user.id);
          setMessages(messages);

          const initialResponses = messages.reduce(
            (acc, message) => {
              acc[message.conversation_id] = message.response?.content || '';
              return acc;
            },
            {} as { [key: string]: string },
          );
          setResponses(initialResponses);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  const handleResponseChange = (messageId: string, content: string) => {
    setResponses((prev) => ({ ...prev, [messageId]: content }));
  };

  const handleSendResponse = async (userId: string, messageId: string) => {
    try {
      await updateAgentConversationMessageResponse(userId, messageId, responses[messageId]);
      fetchConversationMessages();
    } catch (error) {
      Sentry.captureException(error);
      alert('Failed to send response. Please try again.');
    }
  };

  return (
    <ExperimentalAppLayout
      bookings
      pageTitle="Messages"
      insightCardsStyles="hidden md:flex"
      componentName="Agent Conversation Messages"
    >
      {isLoading ? (
        <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-4 pt-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 70,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="bg-[#E5E6E1] rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full pt-4 overflow-y-scroll">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-black-alt font-semibold pl-4 font-avenir">Agent Messages</h1>
            <p>
              <Link className="text-blue-500 pr-2" to="/agent-conversations">
                Agent Conversations
              </Link>
            </p>
          </div>
          <div className="flex flex-col overflow-y-scroll">
            {messages.map((message) => (
              <div key={message.conversation_id}>
                <div className="bg-white rounded-lg shadow-md p-4 mb-4 mx-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm text-gray-500">ID: {message.conversation_id}</span>
                    <span className="text-sm text-gray-500">
                      {new Date(message.created_at).toLocaleString()}
                    </span>
                  </div>
                  {/* {message.contact && (
                    <div className="mb-2">
                      <Link to={`/contacts/${message.contact.id}`}>
                        <span className="font-semibold">
                          Contact: {message.contact.name} {message.contact.last_name}
                        </span>
                      </Link>
                    </div>
                  )} */}
                  {/* <div className="mb-2">
                    <span className="font-semibold">User ID:</span> {message.user_id}
                  </div> */}
                  {/* <div className="mb-2">
                    <span className="font-semibold">Organization ID:</span>{' '}
                    {message.organization_id}
                  </div> */}

                  <div className="mb-2">
                    <span className="font-semibold">Status:</span>{' '}
                    {message.is_urgent ? 'Urgent' : 'Not Urgent'}
                  </div>

                  <div className="mt-2 p-2 bg-gray-100 rounded">
                    <span className="font-semibold">Summary:</span>
                    <div>Title: {message.title}</div>
                    <div>Content: {message.content}</div>
                    <div>Status: {message.status}</div>
                  </div>
                  <div className="mt-4">
                    <div className="mt-2">
                      <span className="font-semibold">Response Status:</span>
                      <span
                        className={`font-bold ${
                          message.response?.status === BookingConversationMessageResponseStatus.SENT
                            ? 'text-green-500'
                            : 'text-yellow-500'
                        }`}
                      >
                        {message.status}
                      </span>
                    </div>
                    <textarea
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="Leave a response..."
                      value={responses[message.conversation_id] || ''}
                      onChange={(e) =>
                        handleResponseChange(message.conversation_id, e.target.value)
                      }
                    />
                    <button
                      className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                      onClick={() => handleSendResponse(message.user_id, message.conversation_id)}
                    >
                      Send Response
                    </button>
                  </div>
                  <button className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                    Mark as Acknowledged
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
