export const environment = {
  userPoolId: 'us-east-2_sI3iPcUtl',
  identityPoolId: 'us-east-2:e49f6906-1989-4f68-9b4f-0d0eb8a9f1de',
  userPoolClientId: '5leg46oaal3m99kcks8chd3ugp',
  apiDomainUrl: 'https://api.dev.mynestor.com',
  apiBaseUrl: 'https://api.dev.mynestor.com/api',
  baseBookingUrl: 'https://dev.mynestor.com/book',
  reactAppBaseUrl: 'https://dev.mynestor.com',
  awsRegion: 'us-east-2',
  oauthDomainName: 'schedule-me-api-userpool-dev.auth.us-east-2.amazoncognito.com',
  appVersion: '1.4.0',
  cognito: {
    oauth: {
      redirectSignIn: 'https://dev.mynestor.com/',
      redirectSignOut: 'https://dev.mynestor.com/',
    },
  },
  oauth: {
    redirectUri: 'https://dev.mynestor.com/oauth/exchange',
  },
  s3: {
    bucketUrl: 'https://d3h9nz0hllooda.cloudfront.net',
  },
  calcom: {
    apiUrl: 'https://api.cal.com/api/v2',
    clientId: 'cluraauln0005oy1nk27h4cs3',
    refreshUrl: 'https://api.dev.mynestor.com/api/auth/refresh',
  },
  stripe: {
    apiVersion: '2024-06-20',
    publicKey:
      'pk_test_51OJ7w4ErwztSwim5ro5Z7TB0xtAgHv72JFB5qkhe4cbIO5RW0erBorcH7aDDPQajeMu6CIUmL1GMMC5QDMk1e8Gj00TWWXItmO',
    paymentsLinkRedirect: 'https://dev.mynestor.com/payments',
    lookup_keys: [
      'standard_ni_monthly',
      'standard_ni_yearly',
      'pro_ni_monthly',
      'pro_ni_yearly',
      'free_ni_monthly',
      'free_ni_yearly',
    ],
  },
  recaptcha: {
    siteKey: '6LfQNQ0qAAAAAAPckLNEEFTah6iXI5PYicXBZ2AU',
  },
};
