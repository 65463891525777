// DesktopMenuDrawer.tsx
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';

import {
  faArrowUpLeftFromCircle,
  faBuildingUser,
  faCalendar,
  faChevronsLeft,
  faChevronsRight,
  faClock,
  faDollarSign,
  faEnvelope,
  faGears,
  faList,
  faQuestionCircle,
  faRefresh,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../../contexts/v2/organizationContext';
import { truncateText } from '../../libs/utils/string.util';
export interface MenuItem {
  icon?: () => React.ReactNode;
  label: string;
  action?: () => void | string; // Can be a function or a URL string
}

interface DesktopMenuDrawerProps {
  open: boolean;
  onToggle: () => void;
  anchor: 'left' | 'right';
  menuItems: MenuItem[];
  behindOverlay?: boolean;
}

const DesktopMenuDrawer: React.FC<DesktopMenuDrawerProps> = ({
  open,
  onToggle,
  anchor,
  menuItems,
  behindOverlay = false,
}) => {
  const zIndex = behindOverlay ? 'z-0' : 'z-50';
  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();
  const { organization } = useOrganization();

  const DesktopMenuItem: React.FC<{
    item: MenuItem;
    onToggle: () => void;
    iconsOnly?: boolean;
  }> = ({ item, onToggle, iconsOnly = false }) => {
    return (
      <div className="mt-4 w-full flex items-center justify-center">
        <div
          onClick={() => {
            if (typeof item.action === 'function') {
              item.action();
              if (open) {
                onToggle();
                setShowSettings(false);
              }
            } else if (typeof item.action === 'string') {
              window.location.href = item.action;
            } else if (item.label === 'Settings') {
              setShowSettings(!showSettings);
              if (!open) {
                onToggle();
                setShowSettings(true);
              }
            } else {
              onToggle();
              setShowSettings(false);
            }
          }}
          className="cursor-pointer flex items-center justify-center"
        >
          <Tooltip title={item.label} placement="right" arrow>
            <div className=" w-full flex items-center justify-center">
              {item.icon && <span className="text-[20px] text-black">{item.icon()}</span>}
            </div>
          </Tooltip>
          {!iconsOnly && (
            <span className="ml-5 font-avenir text-[20px] leading-6">{item.label}</span>
          )}
        </div>
      </div>
    );
  };

  const firstLetterOfBusinessOrDefault = () => {
    return organization?.name?.charAt(0).toUpperCase() ?? 'B';
  };

  const businessNameOrDefault = () => {
    return organization?.name ?? 'Set your business';
  };

  const DesktopMenuItemWithLabel: React.FC<{
    item: MenuItem;
    onToggle: () => void;
    iconsOnly?: boolean;
  }> = ({ item, onToggle, iconsOnly = false }) => {
    return (
      <div className="mt-4 w-full flex items-start justify-start">
        <div
          onClick={() => {
            if (typeof item.action === 'function') {
              item.action();
              if (open) {
                onToggle();
                setShowSettings(false);
              }
            } else if (typeof item.action === 'string') {
              window.location.href = item.action;
            } else if (item.label === 'Settings') {
              setShowSettings(!showSettings);
              if (!open) {
                onToggle();
                setShowSettings(true);
              }
            } else {
              onToggle();
              setShowSettings(false);
            }
          }}
          className="cursor-pointer flex items-center w-full justify-start"
        >
          <div className="flex w-full items-center justify-start">
            <div className="flex-col w-6 justify-center">
              <Tooltip title={item.label} placement="right" arrow>
                <div className=" flex items-start justify-start">
                  {item.icon && <span className="text-[20px] text-black">{item.icon()}</span>}
                </div>
              </Tooltip>
            </div>
            <div className="flex-col justify-center items-center">
              <div className="flex items-center justify-start">
                <span className="text-[14px] font-avenir font-medium ml-4">{item.label}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DrawerList = (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full ml-6">
        {menuItems.map((item, index) => (
          <div key={item.label + index} className="flex w-full">
            {item.label === 'Divider' ? (
              <div className="flex pt-12  w-full">
                <span className="text-[12px] font-avenir font-medium text-gray-400">
                  Your Nestor
                </span>
              </div>
            ) : (
              <DesktopMenuItemWithLabel item={item} onToggle={onToggle} />
            )}
          </div>
        ))}
        <div className="flex pt-12">
          <span className="text-[12px] font-avenir font-medium text-gray-400">Your Business</span>
        </div>
        <div className="flex pt-4">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              onToggle();
              navigate('/business-profile');
            }}
          >
            <div className="w-6 h-6 rounded-md border border-black flex items-center justify-center">
              {/* <FontAwesomeIcon icon={faComments} className="text-[16px]" /> */}
              <span className="text-[12px] font-avenir font-medium text-black">
                {firstLetterOfBusinessOrDefault()}
              </span>
            </div>
            <span className="text-[14px] ml-3 font-avenir font-medium">
              {truncateText(businessNameOrDefault(), 30)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const DrawerListIcons = (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full">
        {menuItems.map((item, index) => (
          <div key={item.label + index} className="flex w-full">
            {item.label === 'Divider' ? (
              <div className="flex pt-6 w-full pb-2">
                <div className="w-full">
                  <Divider />
                </div>
              </div>
            ) : (
              <DesktopMenuItem item={item} onToggle={onToggle} iconsOnly={true} />
            )}
          </div>
        ))}
        <div className="mt-6">
          <Divider />
        </div>
        <div className="flex w-full items-center justify-center mt-8">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              navigate('/business-profile');
            }}
          >
            <Tooltip title={businessNameOrDefault()} placement="right" arrow>
              <div className="w-6 h-6 rounded-md border border-black flex items-center justify-center">
                {/* <FontAwesomeIcon icon={faComments} className="text-[16px]" /> */}
                <span className="text-[12px] font-avenir font-medium text-black">
                  {firstLetterOfBusinessOrDefault()}
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );

  const handleSettingsMenuClick = (path: string) => {
    setShowSettings(!showSettings);
    onToggle();
    if (path) {
      navigate(path);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const renderOpenSettingsMenu = () => {
    return showSettings ? (
      <div className="flex flex-col justify-start items-start w-full h-full ml-8">
        <div className="flex pt-8">
          <span className="text-[12px] font-avenir font-medium text-gray-400">
            Client Engagement
          </span>
        </div>
        <div className="flex flex-col  pt-1">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/communication')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faEnvelope} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Communication</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/business-profile')}
          >
            <div className="w-10 h-10 rounded-full b flex items-center justify-start">
              <FontAwesomeIcon icon={faBuildingUser} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Business Profile</span>
          </div>
        </div>
        <div className="flex pt-12">
          <span className="text-[12px] font-avenir font-medium text-gray-400">
            Booking Management
          </span>
        </div>
        <div className="flex flex-col gap-1 pt-1">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/services')}
          >
            <div className="w-10 h-10 rounded-full   flex items-center justify-start">
              <FontAwesomeIcon icon={faList} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Services & Pricing</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/linked-calendars')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faCalendar} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Linked Calendars</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/availability')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faClock} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Availability</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/payments')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faDollarSign} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Payments</span>
          </div>
        </div>
        <div className="flex pt-12">
          <span className="text-[12px] font-avenir font-medium text-gray-400">Nestor</span>
        </div>
        <div className="flex flex-col gap-1 pt-1">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/personal-settings')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faGears} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Personal Settings</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => handleSettingsMenuClick('/subscription')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faRefresh} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Subscription</span>
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => window.Intercom?.('show')}
          >
            <div className="w-10 h-10 rounded-full  flex items-center justify-start">
              <FontAwesomeIcon icon={faQuestionCircle} className="text-[18px]" />
            </div>
            <span className="text-[14px] font-avenir font-medium">Help & Support</span>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start items-start w-full h-full">{DrawerList}</div>
    );
  };

  return (
    <>
      {/* Overlay */}
      {open && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 z-40 transition-opacity duration-300 ease-in-out"
          onClick={onToggle}
        ></div>
      )}

      {/* Drawer */}
      <div id={`drawer-container-desktop-${anchor}`}>
        <div
          className={`absolute top-0 ${anchor}-0 h-full ${zIndex} transition-[width] duration-300 ease-in-out ${
            open ? 'w-[350px]' : 'w-[65px]'
          } ${open ? 'bg-gray-50' : 'bg-gray-50'}`}
        >
          {/* Toggle button */}
          <div
            className={`w-full flex  items-center pt-1 ${open ? 'justify-start ' : 'justify-center'}`}
          >
            <button
              onClick={onToggle}
              className="w-12 h-12 rounded-full  flex items-center justify-center"
            >
              <FontAwesomeIcon
                className="text-[18px]"
                icon={open ? faChevronsLeft : faChevronsRight}
              />
            </button>
          </div>
          {open && (
            <div>
              <div className="flex items-start justify-between px-4 py-4">
                <div className="flex">
                  <span className="text-[32px] font-avenir">{showSettings ? '' : ''}</span>
                </div>
              </div>
              {showSettings && (
                <div
                  className="flex items-center  justify-start cursor-pointer pl-4"
                  onClick={() => setShowSettings(false)}
                >
                  <FontAwesomeIcon
                    icon={faChevronsLeft}
                    className="text-[12px] pb-[4px]"
                    color="#13ACD4"
                  />
                  <span className="text-[16px] font-avenir pl-2 text-[#13ACD4]">
                    Back to main menu
                  </span>
                </div>
              )}
            </div>
          )}

          {/* Menu items */}
          {open ? (
            renderOpenSettingsMenu()
          ) : (
            <div className="flex justify-start items-start w-full h-full pt-4">
              {DrawerListIcons}
            </div>
          )}

          {/* Bottom section */}
          <div
            className={`absolute bottom-0 w-full flex  ${
              open ? 'justify-start pl-1' : 'justify-center'
            }`}
          >
            <Tooltip title="Logout" placement="right" arrow>
              <div
                className="w-12 h-12  rounded-full flex items-center justify-center mb-[5px] cursor-pointer"
                onClick={handleLogout}
              >
                <FontAwesomeIcon
                  icon={faArrowUpLeftFromCircle}
                  className="text-black text-[20px]"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopMenuDrawer;
