import { AuthContext } from '../../../contexts/authContext';
import { useContext, useEffect, useState } from 'react';
import {
  convertToMomentTimeZone,
  formatClientTimeFromDate,
  formatDateString,
  formatHeaderDate,
} from '../../../libs/utils/date.util';
import LoadingDots from '../../../components/loading';
import {
  BookingConversationItem,
  BookingConversationMessageItem,
  BookingConversationMessageResponseStatus,
  BookingConversationMessageStatus,
  Contact,
  Sentiment,
} from '@book-nestor-monorepo/shared-types';
import {
  acknowledgeAgentConversationMessageResponse,
  getAgentConversation,
  updateAgentConversationMessageResponse,
} from '../../../libs/services/agent-conversations';
import { ConversationButtonGroup } from '../conversation-button-group';
import { useAgent } from '../../../contexts/v2/agentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faSparkles, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { SentimentBadge } from '../conversation-sentiment-badge';
import { useNavigate } from 'react-router-dom';

export const AgentConversationDrawer = ({
  conversationId,
  toggleContactDrawer,
}: {
  conversationId: string;
  toggleContactDrawer: () => void;
}) => {
  const authContext = useContext(AuthContext);
  const [conversation, setConversation] = useState<BookingConversationItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState<'summary' | 'messages'>('summary');
  const { conversationMessages, fetchConversationMessages } = useAgent();
  const [response, setResponse] = useState('');
  const [responseSent, setResponseSent] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [conversationMessage, setConversationMessage] =
    useState<BookingConversationMessageItem | null>(null);
  const navigate = useNavigate();

  const fetchBookingConversation = async () => {
    if (!authContext.user || !authContext.user?.id || !conversationId) return;
    setIsLoading(true);
    const conversation = await getAgentConversation(authContext.user?.id as string, conversationId);
    setConversation(conversation);
    const conversationMessage = conversationMessages.find(
      (message) => message.conversation_id === conversationId,
    ) as BookingConversationMessageItem;
    setConversationMessage(conversationMessage);
    setIsAcknowledged(
      conversationMessage?.status === BookingConversationMessageStatus.ACKNOWLEDGED,
    );
    setResponseSent(
      conversationMessage?.response?.status === BookingConversationMessageResponseStatus.SENT,
    );
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBookingConversation();
  }, [conversationId]);

  const handleSendResponse = () => {
    if (conversationMessage) {
      updateAgentConversationMessageResponse(
        conversation?.user_id as string,
        conversationMessage.conversation_id as string,
        response,
      );
      setResponseSent(true);
      fetchConversationMessages();
    }
  };

  const contactDisplayName = (contact: Contact) => {
    if (!contact || !contact.name || !contact.last_name) return 'Unknown Contact';
    return `${contact.name} ${contact.last_name}`;
  };

  const getNeedsResponse = (conversation: BookingConversationItem) => {
    if (!conversationMessage) return false;
    return (
      conversationMessage.response?.status !== BookingConversationMessageResponseStatus.SENT &&
      conversationMessage.status !== BookingConversationMessageStatus.ACKNOWLEDGED
    );
  };

  const getIsJustAcknowledged = (conversation: BookingConversationItem) => {
    return (
      conversationMessage?.status === BookingConversationMessageStatus.ACKNOWLEDGED &&
      conversationMessage?.response?.status !== BookingConversationMessageResponseStatus.SENT
    );
  };

  const getHasResponded = (conversation: BookingConversationItem) => {
    return conversationMessage?.response?.status === BookingConversationMessageResponseStatus.SENT;
  };

  const hasBookedAppointment = (conversation: BookingConversationItem) => {
    return conversation?.booking_id;
  };

  const handleAcknowledgeResponse = () => {
    if (conversationMessage) {
      acknowledgeAgentConversationMessageResponse(
        conversation?.user_id as string,
        conversationMessage.conversation_id as string,
      );
      setIsAcknowledged(true);
    }
  };

  const handleContactClick = () => {
    toggleContactDrawer();
    navigate(`/contacts/${conversation?.contact?.id}`);
  };

  console.log(conversationMessage);

  if (conversation && !conversation.summary) {
    return (
      <div className="flex flex-col w-full items-start justify-center">
        <div className="flex flex-col w-full items-start justify-center mb-4 border-b-2 border-gray-200 pb-4">
          <div className="flex flex-col w-full items-start justify-center px-6 py-4">
            <span className="text-[16px] font-medium text-left font-avenir">
              {contactDisplayName(conversation?.contact as Contact)}
            </span>
            <span className="text-[12px] text-left font-avenir text-black">
              Agent Conversation -{' '}
              {formatDateString(
                new Date(
                  conversation?.created_at ? conversation?.created_at : new Date(),
                ).toISOString(),
              )}{' '}
              {formatClientTimeFromDate(new Date(conversation?.created_at as number))}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <span className="text-[14px] font-avenir font-bold">Conversation Still In Progress</span>
          <span className="text-[12px] font-avenir text-gray-600">
            This conversation is still in progress. Please check back later.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full items-start justify-center">
      <div className="flex flex-col w-full items-start justify-center mb-4 border-b-2 border-gray-200 pb-4">
        <div className="flex flex-col w-full items-start justify-center px-6 pb-4">
          <span className="text-[16px] font-medium text-left font-avenir">
            {contactDisplayName(conversation?.contact as Contact)}
          </span>

          <span className="text-[12px] text-left font-avenir text-black">
            Agent Conversation -{' '}
            {formatDateString(
              new Date(
                conversation?.created_at ? conversation?.created_at : new Date(),
              ).toISOString(),
            )}{' '}
            {formatClientTimeFromDate(new Date(conversation?.created_at as number))}
          </span>
          {conversation?.contact && (
            <button
              className="text-[12px] mt-2 text-left font-avenir text-black underline"
              onClick={handleContactClick}
            >
              View Contact
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 30,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          <ConversationButtonGroup view={view} setView={setView} />
          {view === 'messages' && (
            <div className="relative w-full mt-8">
              {/* Vertical line */}
              <div className="absolute left-[7px] top-2 bottom-2 w-[2px] bg-gray-200" />
              {conversation?.messages.length === 0 && (
                <div className="space-y-6">
                  <div className="flex items-start mb-8">
                    {/* Dot */}
                    <div className="relative">
                      <div className="w-4 h-4 rounded-full bg-black z-10 relative border-4 border-gray-200 "></div>
                    </div>
                    {/* Content */}
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900 font-avenir">
                        No conversation messages
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Timeline items */}
              {conversation?.messages?.length && conversation?.messages?.length > 0 && (
                <div className="space-y-6">
                  {conversation?.messages.map((message, index) => (
                    <div key={index} className="flex items-start mb-8">
                      {/* Dot */}
                      <div className="relative">
                        <div className="w-4 h-4 rounded-full bg-black z-10 relative border-4 border-gray-200 "></div>
                      </div>
                      {/* Content */}
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900 font-avenir">
                          {message.role === 'assistant'
                            ? 'Agent'
                            : contactDisplayName(conversation?.contact as Contact)}
                        </div>
                        <div className="text-[12px] text-gray-600 font-avenir">
                          {formatHeaderDate(
                            new Date(
                              convertToMomentTimeZone(
                                new Date(),
                                authContext.user?.time_zone || 'America/Los_Angeles',
                              ),
                            ),
                          )}
                        </div>
                        <div className="text-[12px]  text-gray-600 font-inter mt-2 bg-gray-100 py-2 px-4 rounded-md">
                          {message.content as string}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {view === 'summary' && (
            <div className="flex flex-col w-full items-start justify-center mt-8">
              <div className="text-[12px]  text-gray-600 font-inter mt-2 bg-gray-100 py-2 px-4 rounded-md">
                <div className="flex flex-row items-center justify-between">
                  <span className="text-[14px] font-avenir font-bold">Conversation Summary</span>
                  <FontAwesomeIcon icon={faSparkles} className="text-gray-600 text-[12px]" />
                </div>
                <br />
                {conversation?.summary?.summary as string}
                <div className="flex flex-row items-center justify-start pt-4">
                  <div className="flex h-[19px] items-center justify-start p-2 bg-[#DADCDC] rounded-full">
                    <span className="text-[10px] font-avenir">
                      {conversation?.messages.length} Messages
                    </span>
                  </div>
                  {hasBookedAppointment(conversation as BookingConversationItem) && (
                    <div className="flex h-[19px] w-auto items-center px-2 bg-[#43CA51] rounded-full ml-2 mr-2">
                      <span className="text-[10px] font-avenir text-white">Booked</span>
                    </div>
                  )}
                  {getNeedsResponse(conversation as BookingConversationItem) && (
                    <div className="flex h-[19px] items-center p-2 bg-[#B8E7F4] rounded-full ml-2 mr-2">
                      <span className="text-[10px] text-black font-avenir">Action Required</span>
                    </div>
                  )}
                  {getHasResponded(conversation as BookingConversationItem) && (
                    <div className="flex h-[19px] items-center p-2 bg-[#B8E7F4] rounded-full ml-2 mr-2">
                      <span className="text-[10px] text-black font-avenir">Responded</span>
                    </div>
                  )}
                  {getIsJustAcknowledged(conversation as BookingConversationItem) && (
                    <div className="flex h-[19px] items-center p-2 bg-[#B8E7F4] rounded-full ml-2 mr-2">
                      <span className="text-[10px] text-black font-avenir">Acknowledged</span>
                    </div>
                  )}
                  <SentimentBadge value={conversation?.summary?.sentiment as Sentiment} />
                </div>
              </div>
              {conversationMessage && (
                <div className="text-[12px]  text-gray-600 font-inter bg-[#B8E7F4] py-2 px-4 rounded-md w-full mt-4">
                  {!responseSent && !isAcknowledged ? (
                    <div className="flex flex-row items-center justify-between pb-">
                      <span className="text-[14px] font-avenir font-bold">Action Required</span>
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-gray-600 text-[12px]"
                      />
                    </div>
                  ) : !responseSent && isAcknowledged ? (
                    <div className="flex flex-row items-center justify-between pb-">
                      <span className="text-[14px] font-avenir font-bold">Acknowledged</span>
                      <FontAwesomeIcon icon={faCheck} className="text-gray-600 text-[12px]" />
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between pb-">
                      <span className="text-[14px] font-avenir font-bold">Responded</span>
                      <FontAwesomeIcon icon={faCheck} className="text-gray-600 text-[12px]" />
                    </div>
                  )}
                  <div className="w-full mt-4">
                    <span className="text-[12px]  text-gray-600 font-inter">
                      {conversationMessage.content}
                    </span>
                    <br />
                  </div>
                  <div className="w-full pt-5">
                    <textarea
                      value={conversationMessage.response?.content}
                      disabled={responseSent}
                      onChange={(e) => setResponse(e.target.value)}
                      className={`w-full h-24 p-2 border border-white rounded-lg  bg-white text-black font-inter text-[12px] active:border-none focus:outline-none focus:ring-0 focus:border-transparent ${
                        responseSent ? 'opacity-50' : ''
                      }`}
                      placeholder={`Respond to ${conversation?.contact?.name || 'Unknown Contact'} here...`}
                    />
                  </div>
                  <div className="mt-4 flex text-gray-500 items-start">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-[12px] text-black mt-[2px]"
                    />
                    <span className="text-[10px] ml-1 text-black font-avenir text-left">
                      Your AI agent will pass any responses you leave here to the customer and
                      follow-up if anything else is needed. If you don't wish to respond to the
                      customer, you can simply acknowledge the message.
                    </span>
                  </div>
                  <div className="flex flex-row  items-start justify start w-auto py-2">
                    {isAcknowledged && !responseSent && (
                      <button
                        className="text-[12px] font-avenir font-normal text-white rounded-full bg-black px-4 py-2 mr-2"
                        onClick={handleSendResponse}
                      >
                        Reply
                      </button>
                    )}
                    {!responseSent && !isAcknowledged && (
                      <>
                        <button
                          className="text-[12px] font-avenir font-normal text-white rounded-full bg-black px-4 py-2 mr-2"
                          onClick={handleAcknowledgeResponse}
                        >
                          Acknowledge
                        </button>
                        <button
                          className="text-[12px] font-avenir font-normal text-white rounded-full bg-black px-4 py-2 mr-2"
                          onClick={handleSendResponse}
                        >
                          &nbsp;& Send&nbsp;
                        </button>
                      </>
                    )}
                    {responseSent && (
                      <div className="flex flex-row items-center justify-center">
                        <FontAwesomeIcon icon={faCheck} className="text-black text-[10px] pr-1" />
                        <span className="text-[10px] font-avenir font-normal text-black">
                          Response Sent on{' '}
                          {formatDateString(
                            new Date(
                              conversationMessage.response?.updated_at || new Date(),
                            ).toISOString(),
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
