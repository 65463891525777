import { FC } from 'react';

interface IProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  variant?: 'standard' | 'outlined';
  error?: boolean;
}

const Input: FC<IProps> = ({ className, variant, error, ...props }) => {
  return (
    <input
      {...props}
      className={`w-full rounded-full px-[26px] 2xl:px-[36px] border focus:outline-0 font-avenir transition-all duration-200
      ${className}
      ${variant === 'outlined' ? 'bg-transparent h-[55px] 2xl:h-[60px]' : 'text-2xl h-[80px]'}
      ${error ? 'border-red-notification text-red-notification placeholder:text-red-notification' : variant === 'outlined' ? 'text-white md:text-black-alt placeholder:text-white md:placeholder:text-black-alt border-[#353535]' : 'border-transparent text-black-text placeholder:text-[#6B6B6B]'}
      `}
    />
  );
};

export default Input;
