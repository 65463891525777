import { useRef, useState, useEffect } from 'react';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';

export default function CalendarDayScroll({
  onDateSelect,
}: {
  onDateSelect: (date: string) => void;
}) {
  const { isMobile } = useAppExperience();
  const containerRef = useRef(null);
  const [calendarDays, setCalendarDays] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState(null); // State to store selected date

  // Function to get an array of calendar days for the current month
  const getCalendarDays = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const todayDate = currentDate.getDate();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const calendarDays = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(currentYear, currentMonth, i);
      const day = date.toLocaleString('en-us', { weekday: 'short' });
      calendarDays.push({
        day: day,
        date: i.toString(),
        isSelected: i === todayDate,
        isToday: i === todayDate,
      });
    }

    return calendarDays;
  };

  useEffect(() => {
    setCalendarDays(getCalendarDays());
  }, []);

  useEffect(() => {
    const findToday = calendarDays.find((day) => day.isToday);
    if (findToday) {
      scrollToDate(findToday.date);
    }
  }, [calendarDays]);

  const handleScroll = () => {
    const container: any = containerRef.current;
    if (container?.scrollWidth - container.scrollLeft > container.clientWidth) {
      // container.classList.add('shadow-right');
    } else {
      container.classList.remove('shadow-right');
    }
  };

  const scrollToDate = (date: string) => {
    const container: any = containerRef.current;
    if (!container) return;

    const selectedDayIndex = calendarDays.findIndex((day) => day.date === date);
    if (selectedDayIndex === -1) return;

    const selectedDayElement = container.children[selectedDayIndex];
    if (!selectedDayElement) return;

    container.scrollTo({ left: selectedDayElement.offsetLeft - 55, behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedDate) {
      scrollToDate(selectedDate);
    }
  }, [selectedDate]);

  const handleDayClick = (day: any) => {
    setSelectedDate(day.date);
    setCalendarDays(calendarDays.map((d) => ({ ...d, isSelected: d.date === day.date })));
    onDateSelect(day.date); // Call the onDateSelect prop with the selected date
  };

  const shadowClasses = isMobile ? 'scrollable-shadow-mobile' : '';

  return (
    <div className={`flex flex-row overflow-x-scroll hide-scrollbar ${shadowClasses}`}>
      <div className="relative w-full">
        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="flex hide-scrollbar overflow-y-hidden w-full mx-0 space-x-1 relative pr-[350px] md:pr-12"
        >
          {calendarDays.map((day, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center h-[75px] rounded-3xl cursor-pointer max-w-full
                    ${day.isSelected ? 'bg-black-alt text-white' : day.isToday ? 'bg-gray-day text-white' : 'light-gray'}`}
              onClick={() => handleDayClick(day)}
            >
              <div className="flex flex-col items-center justify-center w-10 min-w-10 p-4">
                <span className="text-[12px] font-avenir leading-5">{day.day}</span>
                <span className="text-[18px]font-avenir leading-5 pt-2 font-medium">
                  {day.date}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
