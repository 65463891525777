import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';

interface IProps {
  open: boolean;
  className?: string;
  containerClassName?: string;
  children?: ReactNode;
  onClose: () => void;
  onSave?: () => void;
  darkCloseIcon?: boolean;
}

const MobileModal: FC<IProps> = ({
  open,
  children,
  containerClassName,
  darkCloseIcon,
  onClose,
  className,
}) => {
  const handleClose: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <>
      <div
        onClick={handleClose}
        className={`absolute top-0 w-full h-full bg-[rgba(0,0,0,.5)] transition ease-in-out duration-300 ${open ? 'opacity-100' : 'opacity-0 -z-10'}`}
      />
      <div
        className={`absolute bottom-0 w-full rounded-t-[24px] overflow-y-auto overflow-x-hidden bg-[#1C1C1C] transition ease-in-out duration-300 ${open ? 'translate-y-0' : 'translate-y-full'} ${containerClassName}`}
      >
        <button
          onClick={handleClose}
          className={`absolute w-[48px] h-[48px] top-2 right-2 rounded-full ${darkCloseIcon ? 'bg-[#0909091A]' : 'bg-[#C4C4C41A]'} flex items-center justify-center`}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill={darkCloseIcon ? 'black' : 'white'}
            />
          </svg>
        </button>
        <div className={`${className}`}>{children}</div>
      </div>
    </>
  );
};

export default MobileModal;
