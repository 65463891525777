import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatClientTimeFromDate, formatYYYYMMDDDate } from '../../../libs/utils/date.util';
import { BookingData } from './booking-container';

export const BookingSummaryCard = ({ bookingData }: { bookingData: BookingData }) => {
  const showBookingSummary = bookingData.client;

  if (!showBookingSummary) return null;

  const rescheduledFromBooking = bookingData.rescheduleFromBooking;
  const SummaryHeader = rescheduledFromBooking ? 'Reschedule Booking Summary' : 'Booking Summary';
  const originalBookingDate = rescheduledFromBooking?.start_time;
  return (
    <div className="mt-4 bg-gray-100 rounded-lg p-4 font-avenir">
      <h2 className="text-sm font-medium text-gray-600 font-inter">{SummaryHeader}</h2>
      <div className="mt-2 space-y-2">
        {originalBookingDate && (
          <div className="flex items-center border-b pb-2">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 text-[16px]" />
            <p className="text-sm text-gray-700">
              Original Booking Date: {formatYYYYMMDDDate(new Date(originalBookingDate))}{' '}
              {formatClientTimeFromDate(new Date(originalBookingDate))}
            </p>
          </div>
        )}
        {bookingData.client && (
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 text-[16px]" />
            <p className="text-sm text-gray-700">
              Client: {bookingData.client?.name} {bookingData.client?.last_name}
            </p>
          </div>
        )}
        {bookingData.service && (
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 text-[16px]" />
            <p className="text-sm text-gray-700">Service: {bookingData.service?.title}</p>
          </div>
        )}
        {bookingData.date && (
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 text-[16px]" />
            <p className="text-sm text-gray-700">Date: {formatYYYYMMDDDate(bookingData.date)}</p>
          </div>
        )}
        {bookingData.time && (
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 text-[16px]" />
            <p className="text-sm text-gray-700">
              Time: {formatClientTimeFromDate(new Date(bookingData.time))}
              {bookingData.service?.duration_mins &&
                ` - (${bookingData.service?.duration_mins} mins)`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
