import React, { useEffect, useState, useRef } from 'react';
import { Autocomplete, TextField, Paper } from '@mui/material';
import { Contact } from '@book-nestor-monorepo/shared-types';

interface SearchAutocompleteProps {
  contacts: Contact[];
  label: string;
  onSelectContact?: (contact: Contact | null) => void;
  selectedContact?: Contact | null;
}

export const SearchAutocompleteContacts: React.FC<SearchAutocompleteProps> = ({
  contacts,
  label,
  onSelectContact,
  selectedContact,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<Contact | null>(null);
  const isExternalUpdate = useRef(false);

  const getFullName = (contact: Contact) =>
    `${contact.name}${contact.last_name ? ` ${contact.last_name}` : ''}`.trim();

  const filterOptions = (options: Contact[], state: { inputValue: string }) => {
    return options.filter((option) =>
      getFullName(option).toLowerCase().startsWith(state.inputValue.toLowerCase()),
    );
  };

  // Handle external selectedContact changes
  useEffect(() => {
    if (selectedContact) {
      isExternalUpdate.current = true;
      setValue(selectedContact);
      setInputValue(getFullName(selectedContact));
    } else if (selectedContact === null) {
      isExternalUpdate.current = true;
      setValue(null);
      setInputValue('');
    }
  }, [selectedContact]);

  const handleChange = (event: React.SyntheticEvent, newValue: Contact | null) => {
    if (!isExternalUpdate.current) {
      setValue(newValue);
      if (onSelectContact) {
        onSelectContact(newValue);
      }
    }
    isExternalUpdate.current = false;
  };

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    if (!isExternalUpdate.current) {
      setInputValue(newInputValue);
      if (!newInputValue) {
        setValue(null);
        if (onSelectContact) {
          onSelectContact(null);
        }
      }
    }
    isExternalUpdate.current = false;
  };

  return (
    <Autocomplete
      value={value}
      options={contacts}
      fullWidth
      autoComplete
      includeInputInList
      filterOptions={filterOptions}
      getOptionLabel={getFullName}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          sx={{
            '& .MuiInputBase-root': {
              fontFamily: 'Avenir, sans-serif',
              fontSize: '24px',
              color: 'black',
            },
            '& .MuiInputLabel-root': {
              fontFamily: 'Avenir, sans-serif',
              fontSize: '18px',
              color: 'black',
              '&.Mui-focused': {
                color: 'black',
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: 'black',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: 'black',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'black',
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          className="flex items-center w-full font-avenir text-[18px] text-black px-4 py-2 hover:bg-gray-100 overflow-hidden cursor-pointer"
        >
          <div className="flex-shrink-0 mr-2 truncate">{getFullName(option)}</div>
          <div className="flex-shrink truncate text-[12px] text-[#909090] font-inter">
            {option.email}
            {option.phone_number && ` • ${option.phone_number}`}
          </div>
        </li>
      )}
      PaperComponent={({ children }) => (
        <Paper style={{ backgroundColor: '#F5F5F7' }}>{children}</Paper>
      )}
    />
  );
};
