import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { getVoiceMessageContent } from '../../../libs/services/voice';

interface AudioPlayerProps {
  phoneNumber: string;
  voiceMessageId: string;
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const { phoneNumber, voiceMessageId } = props;

  const [audioSrc, setAudioSrc] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePlay = async () => {
    if (!audioSrc) {
      setLoading(true);
      try {
        const content = await getVoiceMessageContent(phoneNumber, voiceMessageId);
        const blobUrl = URL.createObjectURL(content);
        setAudioSrc(blobUrl);
        playAudio(blobUrl);
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
      }
    } else {
      playAudio(audioSrc);
    }
  };

  const playAudio = (src: string) => {
    const audioElement = new Audio(src);
    audioElement.play();
    setLoading(false);

    audioElement.onended = () => {
      // cleanup ??
    };
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-4">
      <div>
        {loading ? <p>Loading...</p> : <button onClick={handlePlay}>Play</button>}
        {audioSrc && <audio src={audioSrc} controls style={{ display: 'none' }} />}
      </div>
    </div>
  );
};

export default AudioPlayer;
