interface AppointmentType {
  onClose: () => void;
  phoneNumber?: string;
}

const UnregisteredPhoneNumberModal = ({ phoneNumber, onClose }: AppointmentType) => {
  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.startsWith('+1')) {
      phoneNumber = phoneNumber.slice(2);
    }
    // format +19102945999 to 910.294.5999
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  };

  return (
    <div className="flex flex-col w-full ">
      <div className={`flex flex-row items-center justify-end mb-5`}>
        <div className="flex gap-2">
          <button
            className="flex items-center justify-center right-1 top-2 w-10 h-10 cursor-pointer z-20 rounded-full border border-white bg-white"
            onClick={onClose}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9">
        <p className="text-base leading-6 text-white font-avenir">Phone Number Pending</p>
      </div>

      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9">
        <p className="text-[41.89px] leading-[48px] text-white font-avenir">
          {phoneNumber ? formatPhoneNumber(phoneNumber) : ''}
        </p>
      </div>

      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9">
        <p className="text-base leading-6 text-white font-avenir">
          Please note that it may take up to 24 hours for your phone number to activate. We will
          notify you as soon as the activation is complete.
        </p>
      </div>
    </div>
  );
};

export default UnregisteredPhoneNumberModal;
