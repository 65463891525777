import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function LoadingDots({
  skeletonProps,
}: {
  skeletonProps?: {
    count?: number;
    width?: string | number;
    height?: string | number;
    borderRadius?: string | number;
    baseColor?: string;
    style?: React.CSSProperties;
  };
}) {
  return (
    <div className=" w-full">
      <Skeleton {...skeletonProps} />
    </div>
  );
}
