// CalendarContainer.tsx
import { Booking, BookingStatus } from '@book-nestor-monorepo/shared-types';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { getBookings } from '../../libs/services/bookings';
import { BookingDetailDrawer } from '../../pages/v2/right-drawer-components/booking-detail-drawer';
import CalendarView from './calendar-view-component';
import { CreateBookingDrawer } from '../../pages/v2/right-drawer-components/create-booking-drawer';
export type CalendarView = 'day' | 'week' | 'month';

interface CalendarEvent {
  id: string;
  name: string;
  contact_name: string;
  time: string;
  formatted_end_time: string;
  is_cancelled: boolean;
  is_paid: boolean;
  is_active: boolean;
  datetime: string;
  end_time: string;
  href: string;
  bookingId: string;
}

export interface CalendarDay {
  date: string;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  events: CalendarEvent[];
  dayOfWeekName: string;
}

export interface CalendarError {
  message: string;
  code?: string;
  retry?: () => void;
}

export default function CalendarContainer() {
  const [currentView, setCurrentView] = useState<CalendarView>('month');
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const [currentDateRange, setCurrentDateRange] = useState<{ start: string; end: string }>({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0],
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toISOString()
      .split('T')[0],
  });
  const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [selectedDay, setSelectedDay] = useState<CalendarDay | null>(null);
  const [error, setError] = useState<CalendarError | null>(null);

  useEffect(() => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      .toISOString()
      .split('T')[0];
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      .toISOString()
      .split('T')[0];
    setCurrentDateRange({ start: startDate, end: endDate });
  }, []);

  const handleContactCommsClick = (bookingId: string) => {
    if (isOpen) {
      setDrawerContent(<BookingDetailDrawer bookingId={bookingId} />, {
        darkMode: true,
      });
    } else {
      setDrawerContent(<BookingDetailDrawer bookingId={bookingId} />, {
        darkMode: true,
      });
      toggleDrawer();
    }
  };

  const onSuccessfulBooking = async () => {
    console.log('onSuccessfulBooking');
    await loadCalendarData();
  };

  const handleAddEventClick = () => {
    setDrawerContent(<CreateBookingDrawer onSuccessfulBooking={onSuccessfulBooking} />, {});
    toggleDrawer();
  };

  const handleTodayClick = () => {
    const today = dayjs();

    if (currentView === 'week') {
      // Set date range to the week containing today
      setCurrentDateRange({
        start: today.startOf('week').format('YYYY-MM-DD'),
        end: today.endOf('week').format('YYYY-MM-DD'),
      });
    } else {
      // month view
      setCurrentDateRange({
        start: today.startOf('month').format('YYYY-MM-DD'),
        end: today.endOf('month').format('YYYY-MM-DD'),
      });
    }

    // Update selected day to highlight today
    const todayObj: CalendarDay = {
      date: today.format('YYYY-MM-DD'),
      isCurrentMonth: true,
      isToday: true,
      events: [], // This will be populated when calendar refreshes
      dayOfWeekName: today.format('dddd'),
    };

    setSelectedDay(todayObj);
  };

  const loadCalendarData = async () => {
    setIsLoading(true);
    const bookings = await fetchBookings(currentDateRange.start, currentDateRange.end);
    const calendarDays = createCalendarDays(currentDateRange.start, currentDateRange.end, bookings);
    setCalendarDays(calendarDays);
    setIsLoading(false);
  };

  useEffect(() => {
    loadCalendarData();
  }, [currentDateRange]);

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD');
    const todayInCalendar = calendarDays.find((day) => day.date === today);
    if (currentView === 'week' && calendarDays?.length > 0) {
      if (todayInCalendar) {
        setSelectedDay(todayInCalendar);
      } else {
        setSelectedDay(calendarDays[0]);
      }
    } else if (currentView === 'month' && calendarDays?.length > 0) {
      if (todayInCalendar) {
        setSelectedDay(todayInCalendar);
      }
    }
  }, [calendarDays]);

  const fetchBookings = async (startDate: string, endDate: string) => {
    try {
      setError(null);
      const response = await getBookings(authContext.user?.id as string, undefined, {
        afterStart: startDate,
        beforeEnd: dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
        take: 200,
        sortStart: 'asc',
      });
      return response.items;
    } catch (error) {
      console.error('Failed to fetch bookings:', error);
      setError({
        message: 'Failed to load calendar bookings',
        code: 'CALENDAR_FETCH_ERROR',
        retry: () => loadCalendarData(),
      });
      return [];
    }
  };

  const contactDisplayName = (booking: Booking) => {
    const fullName = booking?.attendees[0]?.name;
    if (booking.contact) {
      const firstName = booking.contact.name;
      const lastName = booking.contact.last_name;
      return `${firstName} ${lastName}`;
    }
    return fullName;
  };

  const createCalendarDays = (
    startDate: string,
    endDate: string,
    bookings: Booking[],
  ): CalendarDay[] => {
    const calendarDays: CalendarDay[] = [];
    const start = dayjs(startDate);

    // Determine how many days to generate based on view
    let daysToGenerate: number;
    let includeAdjacentMonths = false;

    switch (currentView) {
      case 'day':
        daysToGenerate = 1;
        break;
      case 'week':
        daysToGenerate = 7;
        break;
      case 'month':
        daysToGenerate = 42; // 6 rows * 7 days
        includeAdjacentMonths = true;
        break;
      default:
        daysToGenerate = 7;
    }

    if (currentView === 'month' && includeAdjacentMonths) {
      // For month view, start from the first day of the week containing the 1st of the month
      const firstDayOfMonth = start.startOf('month');
      const firstDayWeekday = firstDayOfMonth.day();
      const daysToSubtract = firstDayWeekday === 0 ? 6 : firstDayWeekday - 1; // Adjust for Monday start

      let currentDate = firstDayOfMonth.subtract(daysToSubtract, 'days');

      for (let i = 0; i < daysToGenerate; i++) {
        const date = currentDate.format('YYYY-MM-DD');
        const isCurrentMonth = currentDate.month() === start.month();

        const dayEvents = bookings
          .filter((booking) => booking.start_time.startsWith(date))
          .map((booking) => ({
            id: booking.id,
            name: booking.title || '',
            time: new Date(booking.start_time).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
            }),
            formatted_end_time: new Date(booking.end_time).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
            }),
            datetime: booking.start_time,
            end_time: booking.end_time,
            href: '#',
            bookingId: booking.id,
            contact_name: contactDisplayName(booking),
            is_cancelled: booking.status === BookingStatus.CANCELED,
            is_paid: booking.contact_booking?.client_has_paid || false,
            is_active:
              new Date(booking.start_time).getTime() <= Date.now() &&
              new Date(booking.end_time).getTime() >= Date.now(),
          }));

        calendarDays.push({
          date,
          isCurrentMonth,
          isToday: currentDate.isSame(dayjs(), 'day'),
          events: dayEvents,
          dayOfWeekName: currentDate.format('dddd'),
        });

        currentDate = currentDate.add(1, 'day');
      }
    } else {
      // For day and week views, just generate the exact days needed
      let currentDate = start;

      for (let i = 0; i < daysToGenerate; i++) {
        const date = currentDate.format('YYYY-MM-DD');

        const dayEvents = bookings
          .filter((booking) => booking.start_time.startsWith(date))
          .map((booking) => ({
            id: booking.id,
            name: booking.title || '',
            time: new Date(booking.start_time).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
            }),
            formatted_end_time: new Date(booking.end_time).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
            }),
            datetime: booking.start_time,
            end_time: booking.end_time,
            href: '#',
            bookingId: booking.id,
            contact_name: contactDisplayName(booking),
            is_cancelled: booking.status === BookingStatus.CANCELED,
            is_paid: booking.contact_booking?.client_has_paid || false,
            is_active:
              new Date(booking.start_time).getTime() <= Date.now() &&
              new Date(booking.end_time).getTime() >= Date.now(),
          }));

        calendarDays.push({
          date,
          isCurrentMonth: true, // Always true for day/week views
          isToday: currentDate.isSame(dayjs(), 'day'),
          events: dayEvents,
          dayOfWeekName: currentDate.format('dddd'),
        });

        currentDate = currentDate.add(1, 'day');
      }
    }

    return calendarDays;
  };

  const handleSetCurrentView = async (view: CalendarView) => {
    if (view === currentView) {
      return;
    }

    setCalendarDays([]);

    if (view === 'week') {
      let startDate;

      if (selectedDay) {
        // If a day is selected, show its week
        startDate = dayjs(selectedDay.date).startOf('week');
      } else {
        const today = dayjs();
        const currentMonth = dayjs(currentDateRange.start);

        if (today.isSame(currentMonth, 'month')) {
          // If we're viewing current month, show this week
          startDate = today.startOf('week');
        } else {
          // Otherwise show the first week of the visible month
          startDate = currentMonth.startOf('month').startOf('week');
        }
      }

      setCurrentDateRange({
        start: startDate.format('YYYY-MM-DD'),
        end: startDate.add(6, 'days').format('YYYY-MM-DD'),
      });
    }

    if (view === 'month') {
      setCurrentDateRange({
        start: dayjs(currentDateRange.start).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(currentDateRange.end).endOf('month').format('YYYY-MM-DD'),
      });
    }

    setCurrentView(view);
  };

  const handleForwardMonth = () => {
    const startDate = dayjs(currentDateRange.start);
    const nextMonth = startDate.add(1, 'month');

    setCurrentDateRange({
      start: nextMonth.startOf('month').format('YYYY-MM-DD'),
      end: nextMonth.endOf('month').format('YYYY-MM-DD'),
    });
    setSelectedDay(null);
  };

  const handleBackwardMonth = () => {
    const startDate = dayjs(currentDateRange.start);
    const previousMonth = startDate.subtract(1, 'month');

    setCurrentDateRange({
      start: previousMonth.startOf('month').format('YYYY-MM-DD'),
      end: previousMonth.endOf('month').format('YYYY-MM-DD'),
    });
    setSelectedDay(null);
  };

  const handleForwardWeek = () => {
    const startDate = dayjs(currentDateRange.start);
    const nextWeek = startDate.add(1, 'week');

    setCurrentDateRange({
      start: nextWeek.startOf('week').format('YYYY-MM-DD'),
      end: nextWeek.endOf('week').format('YYYY-MM-DD'),
    });
  };

  const handleBackwardWeek = () => {
    const startDate = dayjs(currentDateRange.start);
    const previousWeek = startDate.subtract(1, 'week');

    setCurrentDateRange({
      start: previousWeek.startOf('week').format('YYYY-MM-DD'),
      end: previousWeek.endOf('week').format('YYYY-MM-DD'),
    });
  };

  const handleDayClick = (day: CalendarDay) => {
    setSelectedDay(day);
  };

  return (
    <CalendarView
      currentView={currentView}
      isLoading={isLoading}
      currentDateRange={currentDateRange}
      calendarDays={calendarDays}
      handleSetCurrentView={handleSetCurrentView}
      handleContactCommsClick={handleContactCommsClick}
      handleBackwardWeek={handleBackwardWeek}
      handleForwardWeek={handleForwardWeek}
      handleBackwardMonth={handleBackwardMonth}
      handleForwardMonth={handleForwardMonth}
      handleDayClick={handleDayClick}
      selectedDay={selectedDay}
      handleTodayClick={handleTodayClick}
      handleAddEventClick={handleAddEventClick}
      error={error}
    />
  );
}
