import {
  BookingConversationItem,
  BookingConversationMessageItem,
} from '@book-nestor-monorepo/shared-types';
import { AgentMessageCard } from './AgentMessageCard';
import { AgentMessageView } from '../AgentMenuDrawer/AgentMenuDrawerTabPanels/AgentMenuDrawerMessagesTabPanel';
import { formatDate } from '../../../libs/utils/date.util';

interface AgentMessageCardListProps {
  agentMessages: BookingConversationItem[];
  onNavigate: (view: AgentMessageView) => void;
  agentConversationMessages: BookingConversationMessageItem[];
}

interface AgentMessageCardMapByDate {
  [date: string]: BookingConversationItem[];
}

export const AgentMessageCardList = ({
  agentMessages,
  onNavigate,
  agentConversationMessages,
}: AgentMessageCardListProps) => {
  const agentMessageCardMapByDate = agentMessages.reduce<AgentMessageCardMapByDate>(
    (acc, agentMessage) => {
      const date = formatDate(new Date(agentMessage.created_at), '-');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(agentMessage);
      return acc;
    },
    {},
  );

  return (
    <div className="flex flex-col  w-full">
      {Object.entries(agentMessageCardMapByDate).length === 0 && (
        <div className="block font-avenir text-[18px] text-[#FFFFFF] leading-5">No messages</div>
      )}

      {Object.entries(agentMessageCardMapByDate).map(([date, agentMessages]) => (
        <div className="flex flex-col  items-start w-full" key={date}>
          <h3 className="text-[14px] font-avenir text-white mb-2">{date}</h3>
          {agentMessages.map((agentMessage) => (
            <div className="flex flex-col items-start w-full mb-2" key={agentMessage.id}>
              <AgentMessageCard
                agentMessage={agentMessage}
                onNavigate={onNavigate}
                agentConversationMessage={agentConversationMessages.find(
                  (message) => message.conversation_id === agentMessage.id,
                )}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
