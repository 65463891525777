import { CalendarSettings } from '@calcom/atoms';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';

export const LinkedCalendarsV2 = () => {
  const { bgColor } = useAppExperience();

  return (
    <div
      className={`ml-0 lg:ml-[72px] max-w-4xl  py-2 sm:px-6 lg:px-8 font-avenir bg-[${bgColor}] font-avenir`}
    >
      <div className="flex w-full justify-start">
        <h2 className="text-[32px]  font-medium font-avenir px-2">Linked Calendars</h2>
      </div>
      <CalendarSettings />
    </div>
  );
};
