import { BookingConversationItem, ChannelType } from '@book-nestor-monorepo/shared-types';
import { faComment, faPhone, faSms } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { getAgentConversation } from '../libs/services/agent-conversations';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

export default function AgentConversationDetail() {
  const { conversationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [conversation, setConversation] = useState<BookingConversationItem>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id && conversationId) {
          const conversation = await getAgentConversation(user.id, conversationId);
          setConversation(conversation);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  return (
    <ExperimentalAppLayout
      bookings
      pageTitle="Conversation"
      insightCardsStyles="hidden md:flex"
      componentName="Agent Conversation"
    >
      {isLoading ? (
        <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-4 pt-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 70,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="bg-[#E5E6E1] rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full pt-4 overflow-y-scroll">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-black-alt font-semibold pl-4 font-avenir">Agent Conversation</h1>
            <p>
              <Link className="text-blue-500 pr-2 text-sm" to="/agent-conversations">
                Back to Agent Conversations
              </Link>
            </p>
          </div>
          <div className="flex flex-col overflow-y-scroll">
            {conversation && (
              <div key={conversation.id}>
                <div className="bg-white rounded-lg shadow-md p-4 mb-4 mx-4">
                  <span className="text-sm text-gray-500">
                    {conversation.channel_type === ChannelType.VOICE && (
                      <FontAwesomeIcon icon={faPhone} color="green" />
                    )}
                    {conversation.channel_type === ChannelType.SMS && (
                      <FontAwesomeIcon icon={faSms} color="blue" />
                    )}
                    {conversation.channel_type === ChannelType.TEXT && (
                      <FontAwesomeIcon icon={faComment} color="purple" />
                    )}
                  </span>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm text-gray-500">ID: {conversation.id}</span>
                    <span className="text-sm text-gray-500">
                      {new Date(conversation.created_at).toLocaleString()}
                    </span>
                  </div>
                  {conversation.contact && (
                    <div className="mb-2">
                      <Link to={`/contacts/${conversation.contact.id}`}>
                        <span className="font-semibold">
                          Contact: {conversation.contact.name} {conversation.contact.last_name}
                        </span>
                      </Link>
                    </div>
                  )}
                  {/* <div className="mb-2">
                    <span className="font-semibold">User ID:</span> {conversation.user_id}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Organization ID:</span>{' '}
                    {conversation.organization_id}
                  </div> */}
                  <div className="mb-2">
                    <span className="font-semibold">Status:</span>{' '}
                    {conversation.is_idle ? 'Idle' : 'Active'}
                  </div>
                  <div className="mb-2">
                    <span className="font-semibold">Messages:</span>
                    <div className="mt-2 space-y-2">
                      {conversation.function_call_messages.map((message, index) => {
                        let messageClass = 'p-2 rounded-lg ';
                        if (message.role === 'assistant') {
                          if (message.function_call) {
                            messageClass += 'bg-yellow-100'; // For assistant messages with function calls
                          } else {
                            messageClass += 'bg-blue-100'; // Regular assistant messages
                          }
                        } else if (message.role === 'user') {
                          messageClass += 'bg-green-100'; // User messages
                        } else if (message.role === 'function') {
                          messageClass += 'bg-gray-100'; // Function response messages
                        }

                        return (
                          <div key={index} className={messageClass}>
                            <p className="text-sm font-semibold mb-1">
                              {message.role === 'assistant'
                                ? 'Assistant'
                                : message.role === 'user'
                                  ? 'User'
                                  : 'Function'}
                            </p>
                            {message.role === 'assistant' && message.function_call && (
                              <p className="text-sm">
                                <span className="font-semibold">Assistant Action:</span>{' '}
                                {message.function_call.name}
                              </p>
                            )}
                            {message.role === 'assistant' && message.function_call && (
                              <p className="text-sm">
                                <span className="font-semibold">Action Definition:</span>{' '}
                                {JSON.stringify(message.function_call.arguments)}
                              </p>
                            )}
                            {message.role === 'assistant' && !message.function_call && (
                              <p className="text-sm">{message.content as any}</p>
                            )}
                            {message.role === 'user' && (
                              <p className="text-sm">{message.content as any}</p>
                            )}
                            {message.role === 'function' && (
                              <p className="text-sm">
                                <span className="font-semibold">Action Outcome:</span>{' '}
                                {message.content as any}
                              </p>
                            )}
                          </div>
                        );
                      })}
                      {/* {conversation.function_call_messages.map((message, index): any => (
                        <div
                          key={index}
                          className={`p-2 rounded-lg ${
                            message.role === 'assistant' ? 'bg-blue-100' : 'bg-green-100'
                          }`}
                        >
                          <p className="text-sm font-semibold mb-1">
                            {message.role === 'assistant' ? 'Assistant' : 'User'}
                          </p>
                          <p className="text-sm">{message.content as any}</p>
                        </div>
                      ))} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
