import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface ToggleTypes {
  title: string;
  variant?: string;
}

const userSvg = (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 6.5C4.08594 6.5 2.75 5.16406 2.75 3.5C2.75 1.85938 4.08594 0.5 5.75 0.5C7.39062 0.5 8.75 1.85938 8.75 3.5C8.75 5.16406 7.39062 6.5 5.75 6.5ZM4.67188 7.625H6.80469C9.125 7.625 11 9.5 11 11.8203C11 12.1953 10.6719 12.5 10.2969 12.5H1.17969C0.804688 12.5 0.5 12.1953 0.5 11.8203C0.5 9.5 2.35156 7.625 4.67188 7.625Z"
      fill="white"
    />
  </svg>
);

const voiceSvg = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 5.375C9.75 6.45312 9.39844 7.46094 8.8125 8.25781L11.7656 11.2344C12.0703 11.5156 12.0703 12.0078 11.7656 12.2891C11.4844 12.5938 10.9922 12.5938 10.7109 12.2891L7.73438 9.3125C6.9375 9.92188 5.92969 10.25 4.875 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.17969 0.5 4.875 0.5C7.54688 0.5 9.75 2.70312 9.75 5.375ZM4.875 8.75C6.72656 8.75 8.25 7.25 8.25 5.375C8.25 3.52344 6.72656 2 4.875 2C3 2 1.5 3.52344 1.5 5.375C1.5 7.25 3 8.75 4.875 8.75Z"
      fill="white"
    />
  </svg>
);

const messageSvg = (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5.125C13 7.82031 10.3047 10 7 10C6.10938 10 5.28906 9.85938 4.53906 9.60156C4.28125 9.78906 3.8125 10.0703 3.27344 10.3047C2.71094 10.5625 2.03125 10.75 1.375 10.75C1.21094 10.75 1.07031 10.6797 1.02344 10.5391C0.953125 10.3984 1 10.2344 1.09375 10.1172C1.11719 10.1172 1.11719 10.0938 1.14062 10.0703C1.16406 10.0469 1.21094 10 1.25781 9.95312C1.35156 9.83594 1.46875 9.64844 1.60938 9.4375C1.84375 9.0625 2.05469 8.54688 2.10156 7.96094C1.39844 7.16406 1 6.20312 1 5.125C1 2.45312 3.67188 0.25 7 0.25C10.3047 0.25 13 2.45312 13 5.125Z"
      fill="white"
    />
  </svg>
);

const searchSvg = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 5.375C9.75 6.45312 9.39844 7.46094 8.8125 8.25781L11.7656 11.2344C12.0703 11.5156 12.0703 12.0078 11.7656 12.2891C11.4844 12.5938 10.9922 12.5938 10.7109 12.2891L7.73438 9.3125C6.9375 9.92188 5.92969 10.25 4.875 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.17969 0.5 4.875 0.5C7.54688 0.5 9.75 2.70312 9.75 5.375ZM4.875 8.75C6.72656 8.75 8.25 7.25 8.25 5.375C8.25 3.52344 6.72656 2 4.875 2C3 2 1.5 3.52344 1.5 5.375C1.5 7.25 3 8.75 4.875 8.75Z"
      fill="white"
    />
  </svg>
);

const HorizontalToggle = ({ title, variant = 'header' }: ToggleTypes) => {
  const [isActive, setIsActive] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  const locationSvg =
    location.pathname === '/voice-messages'
      ? voiceSvg
      : location.pathname === '/text-conversations'
        ? messageSvg
        : location.pathname === '/search'
          ? searchSvg
          : userSvg;

  const handleToggle = () => {
    setIsActive(!isActive);
    setTimeout(() => {
      history('/');
    }, 100);
  };

  return (
    <div
      className={`relative flex flex-row items-center h-12 bg-gray-alt rounded-full p-0.5 ${isActive ? 'justify-end' : 'justify-start'}`}
      onClick={handleToggle}
    >
      {isActive ? (
        <>
          <div className="w-8 h-12 flex items-center justify-center">{locationSvg}</div>
          <button className="flex items-center justify-center z-10 p-3.5 bg-black rounded-full cursor-pointer focus:outline-none">
            <span className="text-white font-avenir text-xs mr-2">{title}</span>
            {variant === 'header' ? (
              <svg
                width="15"
                height="13"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2266 6.5C14.2266 6.92188 13.875 7.25 13.4766 7.25H12.7266L12.75 11C12.75 11.0703 12.75 11.1406 12.75 11.1875V11.5625C12.75 12.1016 12.3281 12.5 11.8125 12.5H11.4375C11.3906 12.5 11.3672 12.5 11.3438 12.5C11.3203 12.5 11.2734 12.5 11.25 12.5H10.5H9.9375C9.39844 12.5 9 12.1016 9 11.5625V11V9.5C9 9.10156 8.64844 8.75 8.25 8.75H6.75C6.32812 8.75 6 9.10156 6 9.5V11V11.5625C6 12.1016 5.57812 12.5 5.0625 12.5H4.5H3.75C3.70312 12.5 3.67969 12.5 3.63281 12.5C3.60938 12.5 3.58594 12.5 3.5625 12.5H3.1875C2.64844 12.5 2.25 12.1016 2.25 11.5625V8.9375C2.25 8.9375 2.25 8.91406 2.25 8.89062V7.25H1.5C1.07812 7.25 0.75 6.92188 0.75 6.5C0.75 6.28906 0.820312 6.10156 0.984375 5.9375L6.98438 0.6875C7.14844 0.523438 7.33594 0.5 7.5 0.5C7.66406 0.5 7.85156 0.546875 7.99219 0.664062L13.9688 5.9375C14.1562 6.10156 14.25 6.28906 14.2266 6.5Z"
                  fill="white"
                />
              </svg>
            ) : (
              ''
            )}
          </button>
        </>
      ) : (
        <>
          <button className="flex items-center justify-center z-10 p-3.5 bg-black rounded-full cursor-pointer focus:outline-none">
            <span className="text-white font-avenir text-xs mr-2">{title}</span>
            {locationSvg}
          </button>
          <div className="w-8 h-12 flex items-center justify-center">
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2266 6.5C14.2266 6.92188 13.875 7.25 13.4766 7.25H12.7266L12.75 11C12.75 11.0703 12.75 11.1406 12.75 11.1875V11.5625C12.75 12.1016 12.3281 12.5 11.8125 12.5H11.4375C11.3906 12.5 11.3672 12.5 11.3438 12.5C11.3203 12.5 11.2734 12.5 11.25 12.5H10.5H9.9375C9.39844 12.5 9 12.1016 9 11.5625V11V9.5C9 9.10156 8.64844 8.75 8.25 8.75H6.75C6.32812 8.75 6 9.10156 6 9.5V11V11.5625C6 12.1016 5.57812 12.5 5.0625 12.5H4.5H3.75C3.70312 12.5 3.67969 12.5 3.63281 12.5C3.60938 12.5 3.58594 12.5 3.5625 12.5H3.1875C2.64844 12.5 2.25 12.1016 2.25 11.5625V8.9375C2.25 8.9375 2.25 8.91406 2.25 8.89062V7.25H1.5C1.07812 7.25 0.75 6.92188 0.75 6.5C0.75 6.28906 0.820312 6.10156 0.984375 5.9375L6.98438 0.6875C7.14844 0.523438 7.33594 0.5 7.5 0.5C7.66406 0.5 7.85156 0.546875 7.99219 0.664062L13.9688 5.9375C14.1562 6.10156 14.25 6.28906 14.2266 6.5Z"
                fill="#303030"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default HorizontalToggle;
