import { Booking, BookingStatus, ContactNote } from '@book-nestor-monorepo/shared-types';
import {
  faCalendar,
  faChevronDown,
  faChevronRight,
  faMoneyBill,
  faNote,
  faSparkles,
  faSync,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import LoadingDots from '../../../components/loading';
import { BookingDetailCard } from '../../../components/v2/BookingControl/BookingDetailCard';
import { BookingPaymentCard } from '../../../components/v2/BookingControl/BookingPaymentCard';
import { ContactBookingPaymentAction } from '../../../components/v2/Contact/ContactBookingPaymentAction';
import { ContactNotesCard } from '../../../components/v2/Contact/ContactNotesCard';
import { AuthContext } from '../../../contexts/authContext';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { getBookingById } from '../../../libs/services/bookings';
import { getContactNotes } from '../../../libs/services/contacts';
import { cleanCalcomEventTitleToJustEventType } from '../../../libs/utils/string.util';
import { convertToMomentTimeZone } from '../../../libs/utils/date.util';
import { useNavigate } from 'react-router-dom';
import { AgentAiBorder } from '../../../components/v2/Agent/AgentAiBorder';

export const BookingDetailDrawer = ({
  bookingId,
  onRefresh,
  toggleDrawer,
}: {
  bookingId: string;
  onRefresh?: () => void;
  toggleDrawer?: () => void;
}) => {
  const [reshceduledFromDetails, setRescheduledFromDetails] = useState<Booking>();
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    details: true,
    'booking payment': true,
    notes: true,
  });
  const authContext = useContext(AuthContext);
  const [booking, setBooking] = useState<Booking | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState<string | undefined>(undefined);
  const [bookingNote, setBookingNote] = useState<ContactNote | undefined>(undefined);
  const navigate = useNavigate();
  const fetchBooking = async () => {
    try {
      if (!authContext.user || !authContext.user?.id || !bookingId) return;
      setIsLoading(true);
      const booking = await getBookingById(authContext.user?.id as string, bookingId);
      setBooking(booking);
      if (booking.rescheduled_from) {
        const rescheduledFromBooking = await getBookingById(
          authContext.user.id as string,
          booking.rescheduled_from,
        );
        setRescheduledFromDetails(rescheduledFromBooking);
      }
      setContactId(booking.contact.id as string);
      let contactNotesRes: ContactNote[] = [];
      try {
        contactNotesRes = await getContactNotes(
          authContext.user?.id as string,
          booking.contact?.id as string,
        );

        const note = contactNotesRes?.find((note) => note.appointment_id === booking.id);
        if (note) {
          setBookingNote(note);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBooking();
  }, [bookingId]);

  const refreshCard = () => {
    fetchBooking();
    if (onRefresh) {
      onRefresh();
    }
  };

  const toggleCard = (cardName: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [cardName]: !prev[cardName],
    }));
  };

  const dateIsInFuture = (date: string) => {
    let dateInUserTimezone = date;
    if (authContext?.user?.time_zone) {
      dateInUserTimezone = convertToMomentTimeZone(new Date(date), authContext?.user?.time_zone);
    }
    return new Date(dateInUserTimezone) > new Date();
  };

  interface CardProps {
    title: string;
    content: React.ReactNode;
    icon?: React.ReactNode;
    bgColor?: string;
    contentAction?: string;
    contentActionCallback?: () => void;
  }

  const renderCard = ({
    title,
    content,
    icon,
    bgColor = '#F5F5F7',
    contentAction,
    contentActionCallback,
  }: CardProps) => {
    const cardKey = title?.toLowerCase();
    const handleContentAction = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (contentActionCallback) {
        contentActionCallback();
      }
    };

    return (
      <Card
        className="mb-4 shadow-md rounded-3xl overflow-hidden"
        sx={{
          borderRadius: '30px',
          backgroundColor: bgColor,
          marginBottom: '8px',
          minHeight: '60px',
        }}
      >
        <CardContent
          sx={{
            padding: '8px',
            backgroundColor: bgColor,
            '&:last-child': {
              paddingBottom: '8px',
            },
          }}
          className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
          onClick={() => toggleCard(cardKey)}
        >
          <div className="flex items-center w-full">
            <div className="flex items-center">{icon ? icon : <></>}</div>
            <div className="flex pl-4 justify-between items-center w-full">
              <div className="flex">
                <span className="font-avenir font-bold text-[16px]">{title}</span>
              </div>
              {contentAction && (
                <div
                  className="flex pr-6 h-11 w-auto px-6 py-[2px] items-center rounded-full bg-[#000000]/10"
                  onClick={handleContentAction}
                >
                  <span className="font-inter text-[12px]">{contentAction}</span>
                </div>
              )}
              {!contentAction && (
                <div className="flex items-center pr-4">
                  {expandedCards[cardKey] ? (
                    <FontAwesomeIcon icon={faChevronDown} className="text-[14px]" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} className="text-[14px]" />
                  )}
                </div>
              )}
            </div>
          </div>
        </CardContent>
        <Collapse in={expandedCards[cardKey]} unmountOnExit>
          <CardContent>{content}</CardContent>
        </Collapse>
      </Card>
    );
  };

  if (!booking && !isLoading) {
    return (
      <div className="flex flex-col w-full items-start justify-center bg-black">
        <span className="text-white">Booking not found</span>
      </div>
    );
  }

  const handleFillCanceledSlot = () => {
    navigate(`/campaigns/fill-slot/${booking?.id}`);
    toggleDrawer?.();
  };

  return (
    <div className="flex flex-col w-full items-start justify-center bg-black">
      <div className="flex flex-col w-full items-start justify-center mb-2 border-b-2 border-gray-200 pb-4">
        <div className="flex flex-col w-full items-start justify-center px-6 py-4">
          {isLoading ? (
            <LoadingDots
              skeletonProps={{
                count: 2,
                height: 15,
                width: 100,
                borderRadius: 10,
                style: { marginBottom: '4px' },
              }}
            />
          ) : (
            <div className="flex w-full items-start justify-between">
              <div className="flex flex-col w-full items-start justify-start">
                <span className="text-[16px] font-medium text-left font-avenir text-white">
                  {booking?.contact?.name} {booking?.contact?.last_name}
                </span>
                <span className="text-[12px] text-left font-avenir text-white">Booking</span>
              </div>
              <div className="flex items-center justify-end w-full">
                {booking?.status === BookingStatus.CANCELED &&
                  dateIsInFuture(booking.start_time) && (
                    <div className="flex items-center">
                      <AgentAiBorder styles={{ bgColor: 'white' }}>
                        <button
                          className="flex items-center text-[12px] font-avenir text-black-alt px-4 py-1"
                          onClick={handleFillCanceledSlot}
                        >
                          Fill Canceled Slot
                          <FontAwesomeIcon
                            icon={faSparkles}
                            className="text-[12px] text-black-alt pl-1 pb-[2px]"
                          />
                        </button>
                      </AgentAiBorder>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col w-full items-start justify-center p-2">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 30,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full items-start justify-center p-2">
          <div className="flex flex-col w-full pt-2">
            {booking &&
              renderCard({
                title: 'Details',
                content: <BookingDetailCard booking={booking} refreshCard={refreshCard} />,
                icon: (
                  <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faCalendar} color="#000" className="text-[12px]" />
                  </div>
                ),
                bgColor: '#E5E5E5',
              })}

            {booking &&
              reshceduledFromDetails &&
              renderCard({
                title: 'Rescheduled From',
                content: (
                  <BookingDetailCard booking={reshceduledFromDetails} refreshCard={refreshCard} />
                ),
                icon: (
                  <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faSync} color="#000" className="text-[12px]" />
                  </div>
                ),
                bgColor: '#E5E5E5',
              })}

            {booking?.contact_booking &&
              renderCard({
                title: 'Booking Payment',
                content: <BookingPaymentCard booking={booking} />,
                icon: (
                  <div className="h-11 w-11 bg-[#E5E5E5] rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faMoneyBill} color="#000" className="text-[12px]" />
                  </div>
                ),
                ...(!booking.contact_booking?.client_has_paid && {
                  contentAction: booking.contact_booking?.client_has_paid
                    ? 'View Payment'
                    : 'Take Payment',
                  contentActionCallback: () => {
                    openMuiModal(
                      <ContactBookingPaymentAction
                        onClose={(res) => {
                          closeMuiModal();
                          if (res) {
                            fetchBooking();
                          }
                        }}
                        booking={booking}
                        contact={booking.contact}
                      />,
                    );
                  },
                }),
                bgColor: '#F5F5F7',
              })}

            {booking?.contact_booking &&
              renderCard({
                title: 'Notes',
                content: (
                  <ContactNotesCard
                    contactId={contactId as string}
                    note={bookingNote}
                    appointmentId={booking?.id}
                    noteTitle={cleanCalcomEventTitleToJustEventType(booking?.title || '')}
                  />
                ),
                icon: (
                  <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faNote} color="#000" className="text-[12px]" />
                  </div>
                ),
                bgColor: '#BCC8C7',
              })}
          </div>
        </div>
      )}
    </div>
  );
};
