import { Booking } from '@book-nestor-monorepo/shared-types';
import dayjs from 'dayjs';

export const formatHeaderDate = (date?: Date): string => {
  // Output format example: "Mon.8.29.24"

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short', // "Mon" for Monday
    month: 'numeric', // Numeric month (e.g., 8)
    day: 'numeric', // Numeric day of the month (e.g., 29)
    year: '2-digit', // Two digit year (e.g., 24)
  };

  // Get user's locale from the browser
  const userLocale = navigator.language;

  // Format current date
  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(date || new Date());

  // Replace slashes or other common delimiters with periods if necessary
  return formattedDate.replace(/\/|-|,/g, '.').replace(/,/g, '');
};

export function formatDate(date: Date, separator: '/' | '-' = '/') {
  // Output format example: "01/09/2024" or "01-09-2024"
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}${separator}${day}${separator}${year}`;
}

export function formatMonthDay(date: Date): string {
  // Output format example: "4.12"
  const month = date.getMonth() + 1; // getMonth() returns 0-11
  const day = date.getDate();
  return `${month}.${day}`;
}

export function formatClientTime() {
  // Output format example: "12:05p"
  const date = new Date();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'p' : 'a';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;

  return strTime;
}

export function formatClientTimeFromDate(date: Date) {
  // Output format example: "12:05p"
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'p' : 'a';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;

  return strTime;
}

export function minsToHHMMSS(minute: number) {
  // Output format example: "02:46:40"
  const mins_num = minute;
  let hours: number | string = Math.floor(mins_num / 60);
  let minutes: number | string = Math.floor(mins_num - (hours * 3600) / 60);
  let seconds: number | string = Math.floor(mins_num * 60 - hours * 3600 - minutes * 60);

  // Appends 0 when unit is less than 10
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

export const formatIsoDateString = (isoDateString: string): string => {
  // Output format example: "9.24 | 3p"
  const date = new Date(isoDateString);
  const month = date.getMonth() + 1; // getMonth() returns 0-11
  const day = date.getDate();
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'p' : 'a';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'

  return `${month}.${day} | ${hours}${ampm}`;
};

export const formatDateString = (isoString: string) => {
  // 2024-06-10T13:00:00.000Z -> June 10, 2024
  return dayjs(isoString).format('MMMM D, YYYY');
};

export const formatTextMessageDate = (inputDate: Date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let date;
  if (isSameDate(inputDate, today)) {
    date = 'Today';
  } else if (isSameDate(inputDate, yesterday)) {
    date = 'Yesterday';
  } else {
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const month = months[inputDate.getMonth()];
    date = `${dayOfWeek}, ${month}`;
  }

  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const time = `${formattedHours}:${formattedMinutes}`;

  return `${date} ${time} ${ampm}`;
};

function isSameDate(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function formatStripeDate(stripeTimestamp: number) {
  // Create a new Date object from the Stripe timestamp
  const date = new Date(stripeTimestamp * 1000);

  // Extract the month, day, and year from the Date object
  const month = date.getMonth() + 1; // Month is zero-indexed, so we add 1
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date string as "MM.DD.YYYY"
  const formattedDate = `${padZero(month)}.${padZero(day)}.${year}`;

  return formattedDate;
}

// Helper function to pad single-digit numbers with a leading zero
function padZero(number: number) {
  return number < 10 ? `0${number}` : number;
}

export function calculateDurationInMinutes(
  startDate: Date | null,
  endDate: Date | null,
): number | undefined {
  // Check if either startDate or endDate is null or undefined
  if (!startDate || !endDate) {
    return undefined;
  }

  // Check if startDate is greater than endDate
  if (startDate > endDate) {
    return undefined;
  }

  // Check if startDate and endDate are the same
  if (startDate.getTime() === endDate.getTime()) {
    return 0;
  }

  // Calculate the duration in milliseconds
  const durationInMilliseconds = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to minutes
  const durationInMinutes = Math.floor(durationInMilliseconds / 60000);

  return durationInMinutes;
}

export function formatYYYYMMDDDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const durationString = (appointment: Booking) => {
  if (!appointment?.start_time || !appointment?.end_time) {
    return '0 min';
  }
  const duration = calculateDurationInMinutes(
    new Date(appointment?.start_time),
    new Date(appointment?.end_time),
  );
  return duration ? `${duration} mins` : '0 min';
};

export const durationStringM = (appointment: Booking) => {
  if (!appointment?.start_time || !appointment?.end_time) {
    return '0m';
  }
  const duration = calculateDurationInMinutes(
    new Date(appointment?.start_time),
    new Date(appointment?.end_time),
  );
  return duration ? `${duration}m` : '0m';
};

export function getAbbreviatedMonthAndDay(date: Date): { month: string; day: string } {
  // example output: { month: 'Sep', day: '18' }
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[date.getMonth()];
  const day = String(date.getDate());

  return { month, day };
}
