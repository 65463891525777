import { Contact } from '@book-nestor-monorepo/shared-types';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { AuthContext } from '../../contexts/authContext';
import { createContact, updateContact } from '../../libs/services/contacts';
import PrimaryTextField from '../formLibrary/primaryTextInput';
import { SaveButton } from '../formLibrary/formSaveButton';
import { ActionTypes, StoreContext } from '../../contexts/storeContext';

interface EditContactType {
  title: string;
  onClose: (contactId?: string) => void;
  contact?: Contact;
}

const EditContactModal = ({ title, onClose, contact }: EditContactType) => {
  const { state, dispatch } = useContext(StoreContext);
  const [isRegular, setIsRegular] = useState(contact?.is_regular || false);
  const [isFavorite, setIsFavorite] = useState(contact?.is_favorite || false);
  const [isDifficult, setIsDifficult] = useState(contact?.is_difficult || false);

  const authContext = useContext(AuthContext);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('First name is required'),
    last_name: Yup.string(),
    email: Yup.string().email('Invalid email').required('Email is required'),
    street_address: Yup.string(),
    birth_date: Yup.string().nullable(),
    phone_number: Yup.string().nullable(),
    payment_customer_id: Yup.string().nullable(),
    is_favorite: Yup.boolean(),
    is_regular: Yup.boolean(),
    is_difficult: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      name: contact?.name ?? '',
      last_name: contact?.last_name ?? '',
      email: contact?.email ?? '',
      street_address: contact?.street_address ?? '',
      birth_date: contact?.birth_date ?? '',
      phone_number: contact?.phone_number ?? '',
      payment_customer_id: contact?.payment_customer_id ?? '',
      is_favorite: contact?.is_favorite ?? false,
      is_regular: contact?.is_regular ?? false,
      is_difficult: contact?.is_difficult ?? false,
    },
    validationSchema,
    onSubmit: async (values) => {
      let createdContact;
      if (contact) {
        const updatedContact = {
          ...contact,
          ...values,
        };
        await updateContact(authContext.user?.id as string, updatedContact);
        dispatch({ type: ActionTypes.UPDATE_CONTACT, payload: updatedContact });
      } else {
        createdContact = await createContact(authContext.user?.id as string, { ...values });
        dispatch({ type: ActionTypes.ADD_CONTACT, payload: createdContact });
      }
      onClose(createdContact?.id);
    },
  });

  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-row items-center ${
          title ? 'justify-between' : 'justify-end'
        } h-12 mb-9`}
      >
        {title && (
          <p className="font-inter font-medium text-base leading-4 text-black-text">{title}</p>
        )}
        <div className="flex flex-row gap-1 items-center">
          <SaveButton
            onClick={async () => {
              if (formik.isValid) {
                await formik.submitForm();
              }
            }}
            disabled={!formik.isValid}
          />
          <span
            className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-black-solid"
            onClick={() => onClose(contact?.id ?? 'new')}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-row justify-start pt-1.5 pr-2 gap-2 mb-4 w-full">
          <div>
            <div className="flex items-center relative">
              <PrimaryTextField
                label="First Name"
                color="primary"
                type="text"
                error={formik.touched.name && formik.errors.name ? true : false}
                required={true}
                {...formik.getFieldProps('name')}
              />
            </div>
          </div>
          <div className="flex items-center relative">
            <PrimaryTextField
              label="Last Name"
              color="primary"
              type="text"
              {...formik.getFieldProps('last_name')}
            />
          </div>
        </div>
        <div className="flex flex-row mb-4">
          <div className="flex items-center relative w-full">
            <PrimaryTextField
              label="Address"
              color="primary"
              fullWidth={true}
              multiline={true}
              type="text"
              {...formik.getFieldProps('street_address')}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center mb-4">
          <div className="flex items-center relative ">
            <InputMask
              mask="999 999-9999"
              maskChar="_"
              minLength={10}
              {...formik.getFieldProps('phone_number')}
            >
              <PrimaryTextField
                label="Mobile"
                type="tel"
                error={formik.touched.phone_number && formik.errors.phone_number ? true : false}
                {...formik.getFieldProps('phone_number')}
              />
            </InputMask>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center mb-4">
          <div className="flex items-center relative ">
            <PrimaryTextField
              label="Email"
              color="primary"
              required={true}
              type="text"
              error={formik.touched.email && formik.errors.email ? true : false}
              {...formik.getFieldProps('email')}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex items-center relative ">
            <InputMask mask="99/99/9999" maskChar="_" {...formik.getFieldProps('birth_date')}>
              <PrimaryTextField
                label="Birth Date"
                color="primary"
                type="text"
                {...formik.getFieldProps('birth_date')}
              />
            </InputMask>
          </div>
        </div>
        <p className="pl-[18px] font-avenir text-sm max-w-[303px] mt-9">
          Please ensure all client details are accurately entered to provide the best service. This
          information will help streamline appointment scheduling and enhance client interactions.{' '}
        </p>
        <div className="mt-9">
          <label className="font-avenir text-sm capitalize text-black-alt">Client Tags:</label>
          <div className="flex flex-wrap gap-2 mt-2">
            <button
              onClick={(event) => {
                event.preventDefault();
                formik.setFieldValue('is_regular', !isRegular);
                setIsRegular(!isRegular);
              }}
              className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                isRegular ? 'bg-black-alt text-white' : 'bg-red'
              }`}
            >
              Regular
            </button>
            <button
              onClick={(event) => {
                event.preventDefault();
                formik.setFieldValue('is_favorite', !isFavorite);
                setIsFavorite(!isFavorite);
              }}
              className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                isFavorite ? 'bg-black-alt text-white' : 'bg-red'
              }`}
            >
              Favorites
            </button>
            <button
              onClick={(event) => {
                event.preventDefault();
                formik.setFieldValue('is_difficult', !isDifficult);
                setIsDifficult(!isDifficult);
              }}
              className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                isDifficult ? 'bg-black-alt text-white' : 'bg-red'
              }`}
            >
              Difficult
            </button>
          </div>
        </div>
        {/* {authContext.user?.payment_connect_data?.connected_account_id && (
          <div className="flex flex-row pt-4">
            <div className="flex items-center relative">
              <label
                className="font-avenir text-sm capitalize text-gray-input absolute left-[18px] top-[18px]"
                htmlFor="payment_customer_id"
              >
                Stripe Customer ID:
              </label>
              <input
                placeholder="cus_..."
                className="text-black-solid text-sm font-avenir font-medium border border-black-solid py-4 pl-[148px] pr-[18px] rounded-[34px] w-full"
                {...formik.getFieldProps('payment_customer_id')}
              />
            </div>
          </div>
        )} */}
        {/* <div className="flex flex-col pt-4">
          <button
            type="submit"
            className="text-white rounded-full bg-black-solid w-10 h-10 flex items-center justify-center"
          >
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 1.375V4.75H9.875C10.2734 4.75 10.625 5.10156 10.625 5.5C10.625 5.92188 10.2734 6.25 9.875 6.25H6.5V9.625C6.5 10.0469 6.14844 10.375 5.75 10.375C5.32812 10.375 5 10.0469 5 9.625V6.25H1.625C1.20312 6.25 0.875 5.92188 0.875 5.5C0.875 5.10156 1.20312 4.75 1.625 4.75H5V1.375C5 0.976562 5.32812 0.625 5.75 0.625C6.14844 0.625 6.5 0.976562 6.5 1.375Z"
                fill="white"
              />
            </svg>
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default EditContactModal;
