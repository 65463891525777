import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Modal, Backdrop, Fade, Box } from '@mui/material';

interface MUIFlyUpModalContextType {
  openMuiModal: (content: ReactNode, padding?: number) => void;
  closeMuiModal: (padding?: number) => void;
}

const MUIFlyUpModalContext = createContext<MUIFlyUpModalContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openMuiModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeMuiModal: () => {},
});

export const useMuiModal = (): MUIFlyUpModalContextType => {
  const context = useContext(MUIFlyUpModalContext);
  if (context === undefined) {
    throw new Error('useMuiModal must be used within a MUIFlyUpModalProvider');
  }
  return context;
};

export const MUIFlyUpModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [padding, setPadding] = useState(4);

  const openMuiModal = (content: ReactNode, padding = 4) => {
    setModalContent(content);
    setModalOpen(true);
    setPadding(padding);
  };

  const closeMuiModal = () => {
    setModalOpen(false);
    setModalContent(null);
    setPadding(4);
  };

  return (
    <MUIFlyUpModalContext.Provider value={{ openMuiModal, closeMuiModal }}>
      {children}
      <Modal
        open={modalOpen}
        onClose={closeMuiModal}
        closeAfterTransition
        components={{
          Backdrop: Backdrop,
        }}
        componentsProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute',
              padding: padding,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: 600,
              maxHeight: '90vh',
              bgcolor: '#F1F1F1',
              boxShadow: 24,
              borderRadius: 4,
              overflowY: 'auto',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            {modalContent}
          </Box>
        </Fade>
      </Modal>
    </MUIFlyUpModalContext.Provider>
  );
};
