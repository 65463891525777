/* eslint-disable @typescript-eslint/no-empty-function */
import { useMediaQuery } from '@mui/material';
import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

enum V2AppBgColor {
  MAIN = '#E5E8E8',
  SETTINGS = '#222222',
  CLIENT_CONTROL = '#BCC8C7',
  SUBSCRIPTION = '#FFFFFF',
}

enum AppExperienceMobileMenuType {
  HOME = 'home',
  APP = 'app',
}

export enum AppExperienceDesktopMenuType {
  FULL_SCREEN = 'full-screen',
  SIDE_BAR = 'side-bar',
}

const DESKTOP_BREAKPOINT = 1366;
const LARGE_SCREEN_BREAKPOINT = 1500;
const TABLET_BREAKPOINT = 768;
const MOBILE_BREAKPOINT = 480;

const routeExperienceMap = {
  '/': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.HOME,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/agent': {
    bgColor: V2AppBgColor.SETTINGS,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/contacts': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/bookings': {
    bgColor: V2AppBgColor.CLIENT_CONTROL,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/contacts/:contactId': {
    bgColor: V2AppBgColor.SETTINGS,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/communication': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/business-profile': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/availability': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/linked-calendars': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/payments': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/subscription': {
    bgColor: V2AppBgColor.SUBSCRIPTION,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/booking-control/:contactId': {
    bgColor: V2AppBgColor.SETTINGS,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/services': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/services/:serviceId': {
    bgColor: V2AppBgColor.SETTINGS,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/quick-start': {
    bgColor: V2AppBgColor.CLIENT_CONTROL,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/personal-settings': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/payments/checkout/:checkoutState': {
    bgColor: V2AppBgColor.CLIENT_CONTROL,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/booking-mobile': {
    bgColor: V2AppBgColor.SETTINGS,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  },
  '/campaigns/fill-slot/:bookingId': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/campaigns': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/campaigns/general': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/schedule': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
  '/conversations': {
    bgColor: V2AppBgColor.MAIN,
    menuType: AppExperienceMobileMenuType.APP,
    desktopType: AppExperienceDesktopMenuType.FULL_SCREEN,
  },
};

interface AppExperienceContextType {
  bgColor: V2AppBgColor;
  menuType: AppExperienceMobileMenuType;
  desktopType: AppExperienceDesktopMenuType;
  isDesktop: boolean;
  isLargeScreen: boolean;
  isTablet: boolean;
  isMobile: boolean;
  mobileAppMenuBackArrowFunction: () => void;
  setMobileAppMenuBackArrowFunction: (func: () => void) => void;
}

interface AppExperienceProviderProps {
  children: ReactNode;
}

const AppExperienceContext = createContext<AppExperienceContextType>({
  bgColor: V2AppBgColor.MAIN,
  menuType: AppExperienceMobileMenuType.HOME,
  desktopType: AppExperienceDesktopMenuType.SIDE_BAR,
  isDesktop: false,
  isLargeScreen: false,
  isTablet: false,
  isMobile: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  mobileAppMenuBackArrowFunction: () => {},
  setMobileAppMenuBackArrowFunction: () => {},
});

export const useAppExperience = (): AppExperienceContextType => {
  const context = useContext(AppExperienceContext);

  if (!context) {
    throw new Error('useAppExperience must be used within a AppExperienceProvider');
  }

  return context;
};

export const AppExperienceProvider: FunctionComponent<AppExperienceProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(`(min-width: ${LARGE_SCREEN_BREAKPOINT}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT}px)`);
  const isTablet = useMediaQuery(`(min-width: ${TABLET_BREAKPOINT}px)`);
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  const [bgColor, setBgColor] = useState<V2AppBgColor>(V2AppBgColor.MAIN);
  const [desktopType, setDesktopType] = useState<AppExperienceDesktopMenuType>(
    AppExperienceDesktopMenuType.SIDE_BAR,
  );

  const defaultBackArrowFunction = useCallback(() => navigate(-1), [navigate]);
  const [mobileAppMenuBackArrowFunction, setMobileAppMenuBackArrowFunction] = useState<() => void>(
    () => defaultBackArrowFunction,
  );

  const [menuType, setMenuType] = useState<AppExperienceMobileMenuType>(
    AppExperienceMobileMenuType.HOME,
  );

  useEffect(() => {
    const path = location.pathname;
    const matchingRoute = Object.keys(routeExperienceMap).find((route) => {
      const routeParts = route.split('/');
      const pathParts = path.split('/');
      if (routeParts.length !== pathParts.length) return false;
      return routeParts.every((part, index) => part === pathParts[index] || part.startsWith(':'));
    });

    const map = matchingRoute
      ? routeExperienceMap[matchingRoute as keyof typeof routeExperienceMap]
      : undefined;
    setBgColor(map?.bgColor ?? V2AppBgColor.MAIN);
    setMenuType(map?.menuType ?? AppExperienceMobileMenuType.HOME);
    setDesktopType(map?.desktopType ?? AppExperienceDesktopMenuType.SIDE_BAR);
    setMobileAppMenuBackArrowFunction(() => defaultBackArrowFunction);
  }, [location.pathname, defaultBackArrowFunction]);

  return (
    <AppExperienceContext.Provider
      value={{
        bgColor,
        desktopType,
        menuType,
        isDesktop,
        isLargeScreen,
        isTablet,
        isMobile,
        mobileAppMenuBackArrowFunction,
        setMobileAppMenuBackArrowFunction,
      }}
    >
      {children}
    </AppExperienceContext.Provider>
  );
};
