import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OnboardingModal = ({
  onClose,
  onViewOnboarding,
}: {
  onClose: () => void;
  onViewOnboarding: () => void;
}) => {
  const handleGetStarted = () => {
    onViewOnboarding();
  };

  return (
    <div className="p-6 mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-avenir font-semibold">Welcome to Nestor!</h2>
        <button onClick={onClose} className="h-[40px] w-[40px] rounded-full p-2">
          <FontAwesomeIcon icon={faX} className="text-[16px] text-black" />
        </button>
      </div>

      <div className="flex flex-col items-center mb-6">
        <div className="w-20 h-20 bg-black rounded-full flex items-center justify-center mb-4">
          <span className="text-white text-2xl pt-[1px]" role="img" aria-label="Quick Start Guide">
            ✅
          </span>
        </div>
        <p className="text-center text-lg mb-4">Let's get your business set up for success!</p>
        <p className="text-center text-sm text-gray-600 mb-6">
          Complete a few quick steps to create your booking page and start accepting appointments.
        </p>
      </div>

      <div className="flex flex-col space-y-4">
        <button
          onClick={handleGetStarted}
          className="w-full bg-black text-white py-3 px-4 rounded-full font-avenir font-medium"
        >
          Get Started
        </button>
      </div>

      <div className="mt-6 text-center">
        <p className="text-sm text-gray-500">
          You can always access your setup tasks by clicking the checkmark button in the bottom
          right corner.
        </p>
      </div>
    </div>
  );
};
