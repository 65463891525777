import { TextConversation, TextMessage } from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export const getTextConversations = async (phoneNumber: string): Promise<TextConversation[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(
      `${apiBaseUrl}/text/service-phone/${phoneNumber}/text-conversations`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const getTextConversation = async (
  phoneNumber: string,
  recipientPhoneNumber: string,
): Promise<TextMessage[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(
      `${apiBaseUrl}/text/service-phone/${phoneNumber}/text-conversations/${recipientPhoneNumber}`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const createTextMessage = async (
  phoneNumber: string,
  recipientPhoneNumber: string,
  text: string,
): Promise<TextMessage | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/text/service-phone/${phoneNumber}/text-messages`,
      { to: recipientPhoneNumber, text },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
};
