import * as Sentry from '@sentry/react';
import { useContext } from 'react';
import StripePayments from '../components/payments/StripePayments';
import { AuthContext } from '../contexts/authContext';
import { environment } from '../environments/environment';
import { linkStripeAccount } from '../libs/services/connect';
import { cleanCalcomEmail } from '../libs/utils/email.util';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

export default function Payments() {
  const authContext = useContext(AuthContext);
  const userHasLinkedPaymentsProvider =
    authContext.user?.payment_connect_data?.connected_account_id;

  const handleStripeAccountCreation = async () => {
    try {
      const { url } = await linkStripeAccount(
        {
          email_address: cleanCalcomEmail(authContext.user?.email as string),
        },
        `${environment.reactAppBaseUrl}/payments`,
        `${environment.reactAppBaseUrl}/payments`,
      );
      window.location.href = url;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <ExperimentalAppLayout
      bookings={true}
      pageTitle="Payments"
      insightCardsStyles="hidden md:flex"
      componentName="Payments"
    >
      <div className="flex flex-col items-left md:items-center text-center px-4">
        {!userHasLinkedPaymentsProvider ? (
          <button
            className="bg-black  text-white font-bold py-2 px-4 mt-8 rounded-3xl md:mt-40"
            onClick={handleStripeAccountCreation}
          >
            Create or Link your Stripe Account to Process Payments
          </button>
        ) : (
          <StripePayments />
        )}
      </div>
    </ExperimentalAppLayout>
  );
}
