import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useServices } from '../../../contexts/v2/eventTypesContext';
import ServiceComboBox from '../../../pages/v2/service-combobox';
import { BookingData } from './booking-container';
import { BookingSummaryCard } from './booking-summary-card';
import { useEffect } from 'react';
export const SelectService = ({
  bookingData,
  onSelect,
  onNext,
  onBack,
  preLoadServiceId,
}: {
  bookingData: BookingData;
  onSelect: (service: any) => void;
  onNext: () => void;
  onBack: () => void;
  preLoadServiceId?: string;
}) => {
  const { services } = useServices();

  useEffect(() => {
    if (preLoadServiceId) {
      const service = services.find((s) => s.id === preLoadServiceId);
      if (service) {
        onSelect(service);
      }
    }
  }, [preLoadServiceId, services, onSelect]);

  return (
    <div className="mt-8 space-y-6 font-avenir">
      <div className="border-b pb-4">
        {/* Booking Summary Card */}
        <BookingSummaryCard bookingData={bookingData} />
      </div>
      <div className="bg-white rounded-lg">
        <ServiceComboBox
          services={services}
          selectedService={bookingData.service}
          onSelect={onSelect}
        />
      </div>
      <div className="flex items-center justify-between pt-6">
        <button
          onClick={onBack}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2 h-4 w-4" />
          Back
        </button>
        <button
          onClick={onNext}
          disabled={!bookingData.service}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-700"
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4 w-4" />
        </button>
      </div>
    </div>
  );
};
