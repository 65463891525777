export enum NotificationType {
  TEXT_MESSAGE = 'textMessage',
  VOICE_MESSAGE = 'voiceMessage',
  SYSTEM_ALERT = 'systemAlert',
}
export interface Notification {
  createdAt: number;
  content?: string;
  type: NotificationType;
  link?: string;
  title: string;
}

export interface ServerToClientEvents {
  notification: (e: Notification) => void;
}

export interface ClientToServerEvents {
  notification: (e: Notification) => void;
}
