import { PaginationMeta } from '@book-nestor-monorepo/nestor-dtos';
import {
  BookingConversationItem,
  BookingConversationMessageItem,
  BookingConversationMessageResponseStatus,
  BookingConversationMessageStatus,
  ChannelType,
  Sentiment,
} from '@book-nestor-monorepo/shared-types';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRight,
  faBullhorn,
  faReply,
  faSpinner,
  faMessage,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { formatDateString } from '../../libs/utils/date.util';
import { truncateText } from '../../libs/utils/string.util';
import { SentimentBadge } from './conversation-sentiment-badge';

export const ConversationsTable: React.FC<{
  conversations: BookingConversationItem[];
  conversationsMeta: PaginationMeta;
  handleNextConversations: (meta: PaginationMeta) => void;
  conversationMessages: BookingConversationMessageItem[];
  handleConversationClick: (conversationId: string) => void;
}> = ({
  conversations,
  conversationsMeta,
  handleNextConversations,
  conversationMessages,
  handleConversationClick,
}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const translateChannelType = (type: ChannelType) => {
    switch (type) {
      case ChannelType.SMS:
        return 'SMS';
      case ChannelType.VOICE:
        return 'Voice';
    }
  };

  const getNeedsResponse = (conversation: BookingConversationItem) => {
    const hasNeedsResponse = conversationMessages.filter(
      (message: BookingConversationMessageItem) =>
        message.conversation_id === conversation.id &&
        message.response?.status !== BookingConversationMessageResponseStatus.SENT &&
        message.status !== BookingConversationMessageStatus.ACKNOWLEDGED,
    );
    return hasNeedsResponse.length > 0;
  };

  const getHasResponded = (conversation: BookingConversationItem) => {
    const hasResponded = conversationMessages.filter(
      (message: BookingConversationMessageItem) =>
        message.conversation_id === conversation.id &&
        message.response?.status === BookingConversationMessageResponseStatus.SENT,
    );
    return hasResponded.length > 0;
  };

  const getIsJustAcknowledged = (conversation: BookingConversationItem) => {
    const isAcknowledged = conversationMessages.filter(
      (message: BookingConversationMessageItem) =>
        message.conversation_id === conversation.id &&
        message.status === BookingConversationMessageStatus.ACKNOWLEDGED &&
        message.response?.status !== BookingConversationMessageResponseStatus.SENT,
    );
    return isAcknowledged.length > 0;
  };

  const isRecoveryConversation = (conversation: BookingConversationItem) => {
    return conversation.has_recovery;
  };

  const isCampaignConversation = (conversation: BookingConversationItem) => {
    return conversation.has_campaign;
  };

  const hasBookedAppointment = (conversation: BookingConversationItem) => {
    return conversation.booking_id;
  };

  return (
    <div className="flex-col lg:bg-white rounded-lg shadow shadow-gray-200 p-2 lg:p-4">
      <div className="flex w-full items-center justify-between mb-4">
        <h2 className="text-[22px] font-medium font-avenir ">Agent Conversations</h2>

        {/* <button
          className=" w-auto bg-black text-white rounded-md text-[14px] font-avenir font-normal px-4 py-3 hidden lg:block"
          onClick={() => {
            navigate('/campaigns/general');
          }}
        >
          Create a new Campaign
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button> */}
        {/* <button
          className=" w-auto bg-black text-white rounded-md text-[12px] font-avenir font-normal px-4 py-2 block lg:hidden"
          onClick={() => {
            navigate('/campaigns/general');
          }}
        >
          Create
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button> */}
      </div>

      <div className="w-full h-full lg:bg-[#F5F5F7] lg:rounded-lg">
        {/* Mobile view (up to lg breakpoint) */}
        <div className="lg:hidden space-y-2">
          {conversations.map((conversation, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`p-4 bg-white ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <div className="w-full" onClick={() => handleConversationClick(conversation.id)}>
                  <div className="flex flex-row flex-1">
                    <div className="flex flex-col flex-[1]">
                      {conversation.contact?.name ? (
                        <div className="flex font-bold text-gray-600 font-avenir text-[12px]">
                          {conversation.contact?.name} {conversation.contact?.last_name}
                        </div>
                      ) : (
                        <div className="flex font-avenir text-gray-600 text-[12px]">
                          Unknown Contact
                        </div>
                      )}
                      <div className="flex font-avenir text-[12px]">
                        {formatDateString(new Date(conversation.created_at).toISOString())}
                      </div>
                      <div className="flex font-avenir text-[12px] mt-[4px]">
                        {translateChannelType(conversation.channel_type) === 'Voice' ? (
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="text-gray-600 text-[12px] mt-[2px]"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faMessage}
                            className="text-gray-600 text-[12px] mt-[2px]"
                          />
                        )}
                        {isRecoveryConversation(conversation) && (
                          <FontAwesomeIcon
                            icon={faReply}
                            className="text-gray-600 text-[12px] mt-[1px] ml-2"
                          />
                        )}
                        {isCampaignConversation(conversation) && (
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            className="text-gray-600 text-[12px] mt-[1px] ml-2"
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col flex-[2] ml-1">
                      <div className="text-[12px] text-gray-600 font-inter text-left bg-gray-100 py-2 px-4 rounded-md">
                        {truncateText(conversation.summary?.summary as string, 150)}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full items-end justify-between mt-6">
                    <div className="flex items-center justify-start h-full">
                      {/* {isRecoveryConversation(conversation) && (
                        <FontAwesomeIcon
                          icon={faReply}
                          className="text-[#43CA51] text-[20px] pr-1"
                        />
                      )} */}
                      {/* {isCampaignConversation(conversation) && (
                        <FontAwesomeIcon
                          icon={faBullhorn}
                          className="text-black text-[18px] pr-1"
                        />
                      )} */}
                      {hasBookedAppointment(conversation) && (
                        <div className="flex h-[19px] w-auto items-center px-2 bg-[#43CA51] rounded-full mr-1">
                          <span className="text-[10px] font-avenir text-white">Booked</span>
                        </div>
                      )}
                      {getNeedsResponse(conversation) && (
                        <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                          <span className="text-[10px] font-avenir">Action Required</span>
                        </div>
                      )}
                      {getIsJustAcknowledged(conversation) && (
                        <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                          <span className="text-[10px] font-avenir">Acknowledged</span>
                        </div>
                      )}
                      {getHasResponded(conversation) && (
                        <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                          <span className="text-[10px] font-avenir">Responded</span>
                        </div>
                      )}
                      <SentimentBadge value={conversation.summary?.sentiment as Sentiment} />
                    </div>
                    <div className="flex h-full items-end justify-end">
                      <div className=" bg-transparent flex items-center justify-center cursor-pointer">
                        {conversation.summary ? (
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={`text-[12px] text-gray-600 transform transition-transform duration-200 ${
                              expandedRows[index] ? 'rotate-90' : ''
                            }`}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className={`text-[12px] text-gray-600 transform transition-transform duration-200 ${
                              expandedRows[index] ? 'rotate-90' : ''
                            }`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop view (lg and above) */}
        <div className="hidden lg:block">
          <div className="min-w-full w-full p-2">
            <div className="flex text-[14px] font-inter text-gray-600 text-left">
              <div className="flex-1 p-4">Contact</div>
              <div className="flex-1 p-4">Date</div>
              <div className="flex-1 p-4">Channels</div>
              <div className="flex-1 p-4">Tags</div>
              <div className="flex-1 p-4">Summary</div>
              {/* <div className="flex-1 p-0"></div> */}
            </div>
            <div className="space-y-2 mt-2 text-left">
              {conversations.map((conversation, index) => (
                <div
                  key={index}
                  className="flex flex-col"
                  onClick={() => handleConversationClick(conversation.id)}
                >
                  <div
                    className={`flex ${
                      index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'
                    } ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}  cursor-pointer`}
                  >
                    {conversation.contact?.name ? (
                      <div className="flex-1 p-4 font-bold font-avenir text-[12px]">
                        {conversation.contact?.name} {conversation.contact?.last_name}
                      </div>
                    ) : (
                      <div className="flex-1 p-4 font-avenir text-[12px] text-gray-600">
                        Unknown Contact
                      </div>
                    )}

                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {formatDateString(new Date(conversation.created_at).toISOString())}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px] justify-start">
                      {translateChannelType(conversation.channel_type) === 'Voice' ? (
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="text-gray-600 text-[12px] pt-[4px]"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faMessage}
                          className="text-gray-600 text-[12px] pt-[4px]"
                        />
                      )}
                      {isRecoveryConversation(conversation) && (
                        <FontAwesomeIcon
                          icon={faReply}
                          className="text-gray-600 text-[12px] ml-2"
                        />
                      )}
                      {isCampaignConversation(conversation) && (
                        <FontAwesomeIcon
                          icon={faBullhorn}
                          className="text-gray-600 text-[12px]  ml-2"
                        />
                      )}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex items-center justify-start w-full">
                        {getNeedsResponse(conversation) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Action Required</span>
                          </div>
                        )}
                        {getHasResponded(conversation) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Responded</span>
                          </div>
                        )}
                        {getIsJustAcknowledged(conversation) && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Acknowledged</span>
                          </div>
                        )}
                        {hasBookedAppointment(conversation) && (
                          <div className="flex h-[19px] w-auto items-center px-2 bg-[#43CA51] rounded-full mr-1">
                            <span className="text-[10px] font-avenir text-white">Booked</span>
                          </div>
                        )}
                        <SentimentBadge value={conversation.summary?.sentiment as Sentiment} />
                      </div>
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex items-center justify-center w-full">
                        {/* {isRecoveryConversation(conversation) && (
                          <FontAwesomeIcon
                            icon={faReply}
                            className="text-[#43CA51] text-[16px] pr-1"
                          />
                        )}
                        {isCampaignConversation(conversation) && (
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            className="text-black text-[16px] pr-1"
                          />
                        )} */}
                        {truncateText(conversation.summary?.summary as string, 100)}
                      </div>
                    </div>
                    {/* <div
                      className="flex-1 py-2 pt-3 cursor-pointer"
                      onClick={() => toggleRow(index)}
                    >
                      {conversation.summary ? (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className={`text-[12px] ml-2 transform transition-transform duration-200 text-gray-600`}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className={`text-[12px] ml-2 transform transition-transform duration-200 text-gray-600`}
                        />
                      )}
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-2 items-center w-full">
        {conversationsMeta.hasPrevious && (
          <div className="flex justify-between items-center">
            <div className="flex-1 pt-2 items-center justify-center">
              <button
                className="text-[12px] font-avenir font-medium"
                onClick={() => handleNextConversations(conversationsMeta)}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                Previous
              </button>
            </div>
          </div>
        )}
        {conversationsMeta.hasMore && (
          <div className="flex justify-between items-center">
            <div className="flex-1 pt-2 items-center justify-center">
              <button
                className="text-[12px] font-avenir font-medium"
                onClick={() => handleNextConversations(conversationsMeta)}
              >
                Next <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
