import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const DesktopAppMenu = () => {
  const navigate = useNavigate();

  const exclusionRoutes = ['/'];

  if (exclusionRoutes.includes(window.location.pathname)) {
    return null;
  }

  return (
    <div className="flex items-start justify-start pl-4 py-8 w-full">
      <Button
        onClick={() => navigate('/')}
        sx={{ padding: '0px', alignItems: 'flex-start', justifyContent: 'flex-start' }}
      >
        <div className="flex items-center justify-center">
          <FontAwesomeIcon icon={faArrowLeftLong} size="xl" color="white" />
        </div>
      </Button>
    </div>
  );
};
