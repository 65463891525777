import { faEye, faEyeSlash, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { signInWithRedirect, signUp } from 'aws-amplify/auth';
import { Cache, Hub } from 'aws-amplify/utils';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { useValidEmail, useValidFirstName, useValidPassword } from '../../hooks/useAuthHooks';
import { validateCaptcha } from '../../libs/services/connect';
import { addPixel } from '../../libs/utils/pixel';
import { AuthLayout } from '../layouts/auth-layout';

function Signup() {
  const { email, setEmail, emailIsValid } = useValidEmail('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const { firstName, setFirstName, firstNameIsValid } = useValidFirstName('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [created, setCreated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginWithGoogleIsLoading, setLoginWithGoogleIsLoading] = useState(false);

  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowHideConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const confirmPasswordIsValid = password === confirmPassword;

  const isValid =
    !emailIsValid ||
    email?.length === 0 ||
    !passwordIsValid ||
    password?.length === 0 ||
    !confirmPasswordIsValid ||
    confirmPassword?.length === 0 ||
    !firstNameIsValid ||
    firstName?.length === 0;

  const history = useNavigate();

  const showPasswordHint = password?.length > 0 && !passwordIsValid;

  const signUpClicked = async (event: any) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email,
            name: firstName,
          },
        },
      });
      addPixel('https://tag.simpli.fi/sifitag/c2c03913-e5cb-440e-bd1e-7dcdfc396748');
      await Cache.setItem('login-first-name-set', firstName);
      await Cache.setItem('login-username-set', email);
      await Cache.setItem('nestor-first', password);
      Hub.dispatch('auth', {
        event: 'login-username-set',
        data: { loginUserName: email },
      });
      Hub.dispatch('auth', {
        event: 'login-first-name-set',
        data: { firstName },
      });

      setCreated(true);
      history('/verify');
    } catch (err) {
      if (err instanceof Error) {
        switch (err.name) {
          case 'UsernameExistsException':
            setError('A user with this email address already exists');
            break;
          default:
            setError(err.message);
            break;
        }
      }
    }
  };

  const handleGoogleLogin = async (event: any) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    try {
      setLoginWithGoogleIsLoading(true);
      const recaptchaResponse = await executeRecaptcha('google_login');

      // The token is typically the first element in the response array
      const token = Array.isArray(recaptchaResponse) ? recaptchaResponse[0] : recaptchaResponse;

      if (typeof token !== 'string') {
        throw new Error('Invalid reCAPTCHA token');
      }

      const captchaResult = await validateCaptcha(token);

      if (captchaResult.success) {
        addPixel('https://tag.simpli.fi/sifitag/c2c03913-e5cb-440e-bd1e-7dcdfc396748');
        await signInWithRedirect({ provider: 'Google' });
      } else {
        alert('reCAPTCHA validation failed. Please try again.');
        setLoginWithGoogleIsLoading(false);
      }
    } catch (error: any) {
      setLoginWithGoogleIsLoading(false);
      // ... handle other errors ...
    }
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center bg-mid-gray  overflow-hidden ">
        <div className=" md:max-w-sm mx-auto min-h-screen  md:h-[640px] md:min-h-[760px] h-full  overflow-hidden relative shadow-md w-full ">
          <div className="flex flex-col px-10 pt-10 md:pt-6 pb-4  bg-light-gray rounded-bl-[3rem] rounded-br-[3rem]">
            <div className="w-10 h-10 flex items-center justify-start mb-6 md:mb-2">
              <img src="/assets/onboarding/nestor-logo.png" alt="logo" />
            </div>

            <div className="flex flex-col items-center justify-center pt-8 md:pt-6">
              <div className="mb-2 w-full text-left">
                <p className="text-[41.89px] md:text-[31.89px] leading-[48px] md:leading-[38px] font-semibold text-black  font-inter">
                  Ready to book? <br />
                  Let’s get you <br />
                  setup quickly!
                </p>
              </div>

              <div className="w-full pt-8 md:pt-6">
                <div className="w-16 h-2 justify-start gap-1 inline-flex">
                  <div className="w-8 h-2 bg-black rounded-3xl" />
                  <div className="w-2 h-2 bg-[#CECECE]  rounded-full" />
                  <div className="w-2 h-2 bg-[#CECECE]  rounded-full" />
                </div>
              </div>
            </div>
          </div>
          <div className="px-8 pt-12 pb-8  md:mb-4 space-y-4">
            <form>
              <div className="w-full pb-4">
                <PrimaryTextField
                  label="Email"
                  type="email"
                  fullWidth={true}
                  value={email}
                  required={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  variant="filled"
                  size="small"
                />
              </div>
              <div className="w-full pb-4">
                <PrimaryTextField
                  label="First Name"
                  type="text"
                  fullWidth={true}
                  value={firstName}
                  required={true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="firstName"
                  variant="filled"
                  size="small"
                />
              </div>
              <div className="w-full pb-4">
                <div className="flex">
                  <PrimaryTextField
                    label="Create Password"
                    type={`${showPassword ? 'text' : 'password'}`}
                    value={password}
                    fullWidth={true}
                    required={true}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    name="password"
                    variant="filled"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="cursor-pointer " onClick={toggleShowHidePassword}>
                            <FontAwesomeIcon
                              className={`${showPassword ? 'block' : 'hidden'} text-black`}
                              icon={faEye}
                            />
                            <FontAwesomeIcon
                              className={`${showPassword ? 'hidden' : 'block'} text-black`}
                              icon={faEyeSlash}
                            />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {showPasswordHint && (
                  <p className="text-black text-left text-xs mt-1">
                    8+ characters, with special character
                  </p>
                )}
              </div>
              <div className="w-full pb-4">
                <div className="flex">
                  <PrimaryTextField
                    label="Confirm Password"
                    type={`${showConfirmPassword ? 'text' : 'password'}`}
                    value={confirmPassword}
                    fullWidth={true}
                    required={true}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    name="confirmPassword"
                    variant="filled"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="cursor-pointer " onClick={toggleShowHideConfirmPassword}>
                            <FontAwesomeIcon
                              className={`${showConfirmPassword ? 'block' : 'hidden'} text-black`}
                              icon={faEye}
                            />
                            <FontAwesomeIcon
                              className={`${showConfirmPassword ? 'hidden' : 'block'} text-black`}
                              icon={faEyeSlash}
                            />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {error && <p className="text-red-500 text-left text-xs mt-1 pt-1">{error}</p>}
              </div>

              <div className="flex justify-between items-center pt-2">
                <button
                  disabled={isValid}
                  onClick={signUpClicked}
                  className={`bg-white text-black py-2 w-full px-4 rounded-full ${
                    isValid ? 'opacity-50' : ''
                  }`}
                >
                  Get Started
                </button>
              </div>
            </form>
            <div className="items-center justify-center flex">
              <button
                onClick={handleGoogleLogin}
                className="bg-black w-full text-base relative inline-flex align-middle justify-center min-h-11 rounded-full m-0 text-center px-4 py-2"
              >
                <div className="gap-2 flex justify-center align-center text-white">
                  {loginWithGoogleIsLoading ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} color="white" spin />
                    </div>
                  ) : (
                    <>
                      <div className="pt-1">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 8.1875C16 12.625 12.9688 15.75 8.5 15.75C4.1875 15.75 0.75 12.3125 0.75 8C0.75 3.71875 4.1875 0.25 8.5 0.25C10.5625 0.25 12.3438 1.03125 13.6875 2.28125L11.5625 4.3125C8.8125 1.65625 3.6875 3.65625 3.6875 8C3.6875 10.7188 5.84375 12.9062 8.5 12.9062C11.5625 12.9062 12.7188 10.7188 12.875 9.5625H8.5V6.90625H15.875C15.9375 7.3125 16 7.6875 16 8.1875Z"
                            fill="#DCDCDC"
                          />
                        </svg>
                      </div>
                      Sign up with Google
                    </>
                  )}
                </div>
              </button>
            </div>
            <div className="text-xs text-center text-black font-inter">
              This site is protected by reCAPTCHA and the Google
              <a className="underline" href="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a className="underline" href="https://policies.google.com/terms">
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Signup;
