export const ConversationButtonGroup = ({
  view,
  setView,
}: {
  view: 'summary' | 'messages';
  setView: (view: 'summary' | 'messages') => void;
}) => {
  const handleViewChange = (view: 'summary' | 'messages') => {
    setView(view);
  };

  return (
    <span className="isolate inline-flex rounded-md shadow-sm font-avenir">
      <button
        type="button"
        className={`relative inline-flex items-center rounded-l-full  px-3 py-2 text-[12px] ring-1 ring-inset ring-gray-300 focus:z-10 ${
          view === 'summary'
            ? 'bg-gray-100 text-black font-semibold'
            : 'bg-white text-black font-normal'
        }`}
        onClick={() => handleViewChange('summary')}
      >
        Summary
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center rounded-r-full  px-3 py-2 text-[12px] ring-1 ring-inset ring-gray-300 focus:z-10 ${
          view === 'messages'
            ? 'bg-gray-100 text-black font-semibold'
            : 'bg-white text-black font-normal'
        }`}
        onClick={() => handleViewChange('messages')}
      >
        Conversation
      </button>
    </span>
  );
};
