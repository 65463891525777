export const BookingProgressDots = ({
  currentStep,
  onStepClick,
}: {
  currentStep: number;
  onStepClick: (stepIndex: number) => void;
}) => {
  const steps = [
    'Select Client',
    'Select Service',
    'Select Date',
    'Select Time',
    'Confirm Booking',
  ];

  return (
    <nav aria-label="Progress" className="flex items-center justify-center">
      <ol className="flex items-center ">
        {steps.map((step, index) => (
          <li key={step} className="flex items-center">
            <button
              onClick={() => onStepClick(index)}
              disabled={index > currentStep} // Prevent clicking future steps
              className={`flex items-center space-x-2 text-sm font-medium ${
                index === currentStep ? 'text-book-light-green' : 'text-gray-500'
              }`}
            >
              <span
                className={`size-5 flex items-center justify-center rounded-full ${
                  index < currentStep
                    ? 'bg-book-light-green text-white'
                    : index === currentStep
                      ? 'bg-green-600 text-white'
                      : 'bg-gray-300'
                }`}
              >
                {index + 1}
              </span>
            </button>
            {index < steps.length - 1 && <span className="mx-1 h-0.5 w-6 bg-gray-300"></span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};
