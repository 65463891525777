import { useNavigate } from 'react-router-dom';
import NumberedServiceCard from '../../../components/quickStartTasks/numbered-service-card';
import { FormattedTask, useQuickStart } from '../../../contexts/quickStartContext';
import { useDrawer } from '../../../contexts/v2/rightDrawerContext';
export const OnboardingDrawer = () => {
  const navigate = useNavigate();
  const { remainingFormattedTasks, subscriptionTierFormattedTasks, completedFormattedTasks } =
    useQuickStart();
  const { closeDrawer } = useDrawer();

  const handleOnButtonClick = (task: FormattedTask) => {
    task.buttonOnClick();
    closeDrawer();
  };
  return (
    <div className="flex flex-col w-full items-start justify-center">
      <div className="flex flex-col w-full items-start justify-center px-2 py-4">
        <div className="flex flex-col text-center py-4 h-full pl-2 mx-2">
          <div className="flex flex-col items-start justify-center rounded-3xl">
            <span className="text-[16px] font-avenir font-semibold leading-4 text-black pb-4">
              Let's get you setup!
            </span>
          </div>
          {remainingFormattedTasks.map((task) => (
            <NumberedServiceCard
              key={task.number}
              title={task.title}
              description={task.description}
              buttonText={task.buttonText}
              onButtonClick={() => handleOnButtonClick(task)}
              number={task.number}
              canDismiss={task.canBeSkipped}
              onDismiss={task.onDismiss}
              confirmDeleteText={task.confirmDeleteText}
            />
          ))}
          {subscriptionTierFormattedTasks.map((task) => (
            <div className="flex flex-row" key={task.title}>
              <span className="text-2 mr-2 text-black font-avenir font-semibold leading-4 w-5"></span>
              <div className="flex flex-row justify-between w-full bg-black rounded-3xl items-center text-center mb-2 pl-4 pr-2 py-2">
                <div className="flex flex-row justify-start items-start text-left">
                  <span className="text-[16px] font-avenir font-semibold leading-4 text-white">
                    {task.title}
                  </span>
                </div>
                <button
                  className="text-[12px] text-black bg-[#ffffff] h-10 px-4 font-avenir font-normal leading-4 rounded-full"
                  onClick={() => {
                    closeDrawer();
                    navigate('/subscription');
                  }}
                >
                  {'Upgrade'}
                </button>
              </div>
            </div>
          ))}
          {completedFormattedTasks.map((task) => (
            <NumberedServiceCard
              key={task.number}
              title={task.title}
              description={task.description}
              buttonText={task.buttonText}
              onButtonClick={() => handleOnButtonClick(task)}
              number={task.number}
              disabled={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
