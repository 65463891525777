import { faX, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useContext, useState } from 'react';

// Update the context type to include the new setDrawerContent signature
const DrawerContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerContent: (content: React.ReactNode, options?: { darkMode?: boolean }) => {},
  darkMode: false,
  isSubDrawerOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSubDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSubDrawerContent: (content: React.ReactNode) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeDrawer: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openDrawer: () => {},
});

// Custom hook for using drawer context
export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<React.ReactNode>(null);
  const [darkMode, setDarkMode] = useState(false);
  const [isSubDrawerOpen, setIsSubDrawerOpen] = useState(false);
  const [subDrawerContent, setSubDrawerContent] = useState<React.ReactNode>(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    if (!isOpen === false) {
      // Clear content when closing
      setDrawerContent(null);
      setDarkMode(false); // Reset dark mode when closing
    }
  };

  // Updated setDrawerContent function with options
  const handleSetDrawerContent = (content: React.ReactNode, options?: { darkMode?: boolean }) => {
    setDrawerContent(content);
    setDarkMode(options?.darkMode ?? false);
  };

  const toggleSubDrawer = () => {
    setIsSubDrawerOpen(!isSubDrawerOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setDrawerContent(null);
    setDarkMode(false);
  };

  const openDrawer = () => {
    setIsOpen(true);
  };

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        toggleDrawer,
        setDrawerContent: handleSetDrawerContent,
        darkMode,
        isSubDrawerOpen,
        toggleSubDrawer,
        setSubDrawerContent,
        closeDrawer,
        openDrawer,
      }}
    >
      {/* Main Content */}
      <div className="flex-1">{children}</div>
      <div className="flex">
        {/* Drawer */}
        <div
          className={`fixed right-0 top-0 h-full w-full md:w-[440px] shadow-xl transform transition-transform z-[999] ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } ${darkMode ? 'bg-black' : 'bg-white'}`}
        >
          <div className="flex flex-col w-full h-full">
            <div
              className="flex w-full items-end justify-end cursor-pointer pt-6 pr-6"
              onClick={toggleDrawer}
            >
              <FontAwesomeIcon
                icon={faX}
                className={`text-[16px] ${darkMode ? 'text-white' : 'text-black'}`}
              />
            </div>
            <div className="mt-4 flex-1 overflow-y-auto">{drawerContent}</div>
          </div>
        </div>
        {isSubDrawerOpen && (
          <div
            className={`fixed right-0 top-0 h-full w-full md:w-[440px] shadow-xl transform transition-transform z-[1000] bg-white`}
          >
            <div className="flex flex-col w-full h-full">
              <div className="flex w-full items-start justify-start cursor-pointer pt-6 pl-6">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-[16px] text-black"
                  onClick={toggleSubDrawer}
                />
              </div>
              <div className="mt-4 flex-1 overflow-y-auto">{subDrawerContent}</div>
            </div>
          </div>
        )}
      </div>
    </DrawerContext.Provider>
  );
};
