import { AccountSession } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectPayments } from '@stripe/react-connect-js';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { environment } from '../../environments/environment';
import { createAccountSession } from '../../libs/services/connect';

export default function StripePayments() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const authContext = useContext(AuthContext);
  const [accountSession, setAccountSession] = useState<AccountSession>();
  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>(null);

  useEffect(() => {
    const initializeStripe = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) {
          throw new Error('User not found');
        }

        const connectedAccountId = user.payment_connect_data?.connected_account_id;
        if (!connectedAccountId) {
          throw new Error('Linked payments provider not found');
        }

        const instance = await loadConnectAndInitialize({
          publishableKey: environment.stripe.publicKey,
          fetchClientSecret: async () => {
            try {
              const tokenResponse = await createAccountSession(connectedAccountId);
              setAccountSession(tokenResponse);
              const clientSecret = tokenResponse.client_secret;
              if (!clientSecret) {
                throw new Error('Client secret not found in token response');
              }
              return clientSecret;
            } catch (error) {
              Sentry.captureException(error);
              setHasError(true);
              throw error;
            }
          },
        });

        setStripeConnectInstance(instance);
      } catch (error) {
        Sentry.captureException(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    initializeStripe();
  }, [authContext.user]);

  if (isLoading) {
    return null; // Or return a loading spinner if preferred
  }

  if (hasError || !stripeConnectInstance || !accountSession) {
    return undefined;
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectPayments />
    </ConnectComponentsProvider>
  );
}
