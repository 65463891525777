import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { hasAccess } from '../../libs/utils/hasAccess';
import { UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { getServicePhoneDetailsByUser, updateUserServicePhone } from '../../libs/services/voice';
import LoadingDots from '../loading';
import * as Sentry from '@sentry/react';
import { USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';

export default function SideButtons() {
  const context = useContext(AuthContext);
  const history = useNavigate();
  const [slideBtn, setSlideBtn] = useState(false);
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone>();
  const [isLoading, setIsLoading] = useState(true);

  const userHasAccess = (): boolean => {
    const token = context.token;
    if (!token) return true;

    return hasAccess(token, USER_PERMISSIONS.CAN_USE_VOICE);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = context.user;
        if (!user) return;
        if (user?.id) {
          setIsLoading(true);
          const userServicePhone = await getServicePhoneDetailsByUser(user?.id);
          setUserServicePhone(userServicePhone);
          setIsLoading(false);
        }
      } catch (error) {
        Sentry.captureException(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [context.user]);

  const updateUserServicePhoneClick = async (aiAssistantEnabled: boolean) => {
    if (!userServicePhone) return;
    const updatedUserServicePhone = await updateUserServicePhone({
      ...userServicePhone,
      voice_assistant_enabled: aiAssistantEnabled,
    });
    setUserServicePhone(updatedUserServicePhone);
  };

  const toggleSlideBtn = () => {
    if (slideBtn === undefined) return;
    setSlideBtn(!slideBtn);
    updateUserServicePhoneClick(!slideBtn);
  };

  useEffect(() => {
    setSlideBtn(userServicePhone?.voice_assistant_enabled as boolean);
  }, [userServicePhone]);

  if (isLoading)
    return (
      <div className={`flex flex-col md:hidden gap-2 ${!userHasAccess() ? 'opacity-50' : ''}`}>
        <div className="flex items-center justify-center bg-black-alt/10 w-12 h-12 rounded-full relative after:w-4 after:h-4 after:top-0 after:right-0 after:z-10 after:rounded-full after:absolute ">
          <LoadingDots
            skeletonProps={{
              count: 3,
              height: '48px',
              width: '48px',
              borderRadius: '100%',
              baseColor: 'lightgray',
            }}
          />
        </div>
      </div>
    );

  return (
    <>
      <div className={`flex flex-col md:hidden gap-2 ${!userHasAccess() ? 'opacity-30' : ''}`}>
        <div
          className="flex items-center justify-center bg-black-alt/10 w-12 h-12 rounded-full relative after:w-4 after:h-4 after:top-0 after:right-0 after:z-10 after:rounded-full after:absolute "
          onClick={() => history('/voice-messages')}
        >
          <svg
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.75 4.3125C17.75 6.33594 16.1914 8.00391 14.25 8.22266V8.25H3.75V8.22266C1.78125 8.00391 0.25 6.33594 0.25 4.3125C0.25 2.15234 2 0.375 4.1875 0.375C6.34766 0.375 8.125 2.15234 8.125 4.3125C8.125 5.57031 7.52344 6.66406 6.62109 7.375H11.3516C10.4492 6.66406 9.875 5.57031 9.875 4.3125C9.875 2.15234 11.625 0.375 13.8125 0.375C15.9727 0.375 17.75 2.15234 17.75 4.3125ZM1.125 4.3125C1.125 6.00781 2.49219 7.375 4.1875 7.375C5.85547 7.375 7.25 6.00781 7.25 4.3125C7.25 2.64453 5.85547 1.25 4.1875 1.25C2.49219 1.25 1.125 2.64453 1.125 4.3125ZM13.8125 7.375C15.4805 7.375 16.8477 6.00781 16.875 4.3125C16.875 2.64453 15.4805 1.25 13.8125 1.25C12.1172 1.25 10.75 2.64453 10.75 4.3125C10.75 6.00781 12.1172 7.375 13.8125 7.375Z"
              fill="#303030"
            />
          </svg>
        </div>
        <div
          className="flex items-center justify-center bg-black-alt/10 w-12 h-12 rounded-full relative after:w-4 after:h-4 after:top-0 after:right-0 after:z-10 after:rounded-full after:absolute "
          onClick={() => history('/text-conversations')}
        >
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.5C4.60938 1.5 1.875 3.66016 1.875 6.3125C1.875 7.35156 2.28516 8.33594 3.07812 9.18359C3.29688 9.45703 3.37891 9.78516 3.24219 10.0859C2.94141 10.8789 2.44922 11.5898 2.12109 12C3.35156 11.8633 4.33594 11.3438 4.9375 10.9062C5.15625 10.7422 5.45703 10.7148 5.70312 10.7969C6.44141 11.0156 7.20703 11.125 8 11.125C11.3633 11.125 14.0977 8.96484 14.0977 6.3125C14.0977 3.66016 11.3633 1.5 8 1.5ZM8 0.625C11.8555 0.625 14.9727 3.19531 14.9727 6.3125C14.9727 9.45703 11.8555 12 8 12C7.09766 12 6.25 11.8633 5.45703 11.6172C4.63672 12.1914 3.32422 12.875 1.65625 12.875C1.38281 12.875 1.13672 12.7383 1.02734 12.4922C0.945312 12.2461 0.972656 11.9727 1.16406 11.7812C1.19141 11.7812 2.03906 10.8516 2.42188 9.78516C1.51953 8.82812 1 7.625 1 6.3125C1 3.19531 4.11719 0.625 8 0.625Z"
              fill="#303030"
            />
          </svg>
        </div>
        <div
          className={`${!slideBtn ? 'items-end' : 'items-start'} flex  justify-center bg-black w-12 h-24 rounded-full py-[2px]`}
          onClick={toggleSlideBtn}
        >
          <div
            className={`${!slideBtn ? 'bg-[#111111] border-[#DB1B24]' : 'bg-[#D9DAD6] border-[#F7F7F7]'} flex items-center justify-center border w-11 h-11 rounded-full cursor-pointer`}
          >
            {slideBtn ? (
              <svg
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.875 1.625C4.44141 1.625 2.5 3.59375 2.5 6V6.4375C2.5 6.68359 2.28125 6.875 2.0625 6.875C1.81641 6.875 1.625 6.68359 1.625 6.4375V6C1.625 3.10156 3.97656 0.75 6.875 0.75C9.77344 0.75 12.125 3.10156 12.125 6V6.4375C12.125 8.13281 10.7305 9.5 9.0625 9.5H7.3125H6.875H6.4375C5.94531 9.5 5.5625 9.11719 5.5625 8.625C5.5625 8.16016 5.94531 7.75 6.4375 7.75H7.3125C7.77734 7.75 8.1875 8.16016 8.1875 8.625H9.0625C10.2656 8.625 11.25 7.66797 11.25 6.4375V6C11.25 3.59375 9.28125 1.625 6.875 1.625ZM3.375 6C3.375 4.08594 4.93359 2.5 6.875 2.5C8.78906 2.5 10.375 4.08594 10.375 6C10.375 6.38281 10.293 6.76562 10.1836 7.09375C9.96484 7.50391 9.52734 7.75 9.0625 7.75H8.81641C8.51562 7.23047 7.94141 6.875 7.3125 6.875H6.4375C5.45312 6.875 4.6875 7.66797 4.6875 8.625C4.6875 8.67969 4.6875 8.70703 4.6875 8.73438C3.86719 8.10547 3.375 7.12109 3.375 6ZM6.875 10.375H7.3125H9.0625H9.30859C11.332 10.375 13 12.043 13 14.0664C13 14.4492 12.6719 14.75 12.2891 14.75H1.43359C1.05078 14.75 0.75 14.4492 0.75 14.0664C0.75 12.043 2.39062 10.375 4.41406 10.375H6.4375H6.875Z"
                  fill="#3FAB4A"
                />
              </svg>
            ) : (
              <svg
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.875 1.625C4.44141 1.625 2.5 3.59375 2.5 6V6.4375C2.5 6.68359 2.28125 6.875 2.0625 6.875C1.81641 6.875 1.625 6.68359 1.625 6.4375V6C1.625 3.10156 3.97656 0.75 6.875 0.75C9.77344 0.75 12.125 3.10156 12.125 6V6.4375C12.125 8.13281 10.7305 9.5 9.0625 9.5H7.3125H6.875H6.4375C5.94531 9.5 5.5625 9.11719 5.5625 8.625C5.5625 8.16016 5.94531 7.75 6.4375 7.75H7.3125C7.77734 7.75 8.1875 8.16016 8.1875 8.625H9.0625C10.2656 8.625 11.25 7.66797 11.25 6.4375V6C11.25 3.59375 9.28125 1.625 6.875 1.625ZM3.375 6C3.375 4.08594 4.93359 2.5 6.875 2.5C8.78906 2.5 10.375 4.08594 10.375 6C10.375 6.38281 10.293 6.76562 10.1836 7.09375C9.96484 7.50391 9.52734 7.75 9.0625 7.75H8.81641C8.51562 7.23047 7.94141 6.875 7.3125 6.875H6.4375C5.45312 6.875 4.6875 7.66797 4.6875 8.625C4.6875 8.67969 4.6875 8.70703 4.6875 8.73438C3.86719 8.10547 3.375 7.12109 3.375 6ZM6.875 10.375H7.3125H9.0625H9.30859C11.332 10.375 13 12.043 13 14.0664C13 14.4492 12.6719 14.75 12.2891 14.75H1.43359C1.05078 14.75 0.75 14.4492 0.75 14.0664C0.75 12.043 2.39062 10.375 4.41406 10.375H6.4375H6.875Z"
                  fill="#DB1B24"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex-row justify-end hidden md:flex gap-2 mt-2">
        <div
          className="flex items-center justify-center bg-white w-12 h-12 rounded-full relative cursor-pointer"
          onClick={onUpdate}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div
          className="flex items-center justify-center bg-white w-12 h-12 rounded-full relative cursor-pointer"
          onClick={onUpdate}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div> */}
    </>
  );
}
