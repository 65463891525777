import { useBooking, useCancelBooking } from '@calcom/atoms';
import { faArrowLeft, faCheck, faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthIsSignedIn } from '../contexts/authContext';
import { cleanCalcomEmail } from '../libs/utils/email.util';
import { SchedulerLayout } from './layouts/scheduler-layout';

export default function BookingDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { userSlug } = useParams<{ userSlug: string }>();
  const { bookingId } = useParams<{ bookingId: string }>();
  const history = useNavigate();

  const { isLoading, data: booking, refetch } = useBooking((bookingId as string) ?? '');
  const bookingData: any = booking; // Making the booking data type 'any'
  const startTime = dayjs(bookingData?.start).format('h:mma');
  const endTime = dayjs(bookingData?.end).format('h:mma');
  const date = dayjs(bookingData?.start).toDate();
  const dateToday = dayjs(bookingData?.start).date();
  const year = dayjs(bookingData?.start).year();
  const day = dayjs(date).format('dddd');
  const month = dayjs(date).format('MMMM');

  const { mutate: cancelBooking } = useCancelBooking({
    onSuccess: async () => {
      refetch();
    },
  });

  return (
    <SchedulerLayout>
      <AuthIsSignedIn>
        <div className="flex flex-row items-start justify-start pl-4 cursor-pointer">
          <Link to="/">
            <FontAwesomeIcon className="pr-4" icon={faArrowLeft} />
            Back to scheduler
          </Link>
        </div>
      </AuthIsSignedIn>
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center text-center max-h-full px-4">
          {/* booking details */}

          {!isLoading && bookingData && (
            <div
              key={bookingData.id}
              className="my-10 w-[440px] overflow-hidden rounded-md border-[0.7px] border-black px-10 py-5"
            >
              {bookingData.status === 'accepted' ? (
                <div className="mx-2 my-4 flex flex-col items-center justify-center text-center">
                  {/* <CheckCircle2Icon className="my-5 flex h-[40px] w-[40px] rounded-full bg-green-500" /> */}
                  <div className="my-5 flex h-[40px] w-[40px] rounded-full bg-green-500 items-center justify-center flex">
                    <FontAwesomeIcon icon={faCheck} color="white" />
                  </div>
                  <h1 className="text-xl font-bold">This meeting is scheduled</h1>
                  <p>We sent an email with a calendar invitation with the details to everyone.</p>
                </div>
              ) : (
                <div className="mx-2 my-4 flex flex-col items-center justify-center text-center">
                  {/* <X className="my-5 flex h-[40px] w-[40px] rounded-full bg-red-400" /> */}
                  <div className="my-5 flex h-[40px] w-[40px] rounded-full bg-red-400 items-center justify-center flex">
                    <FontAwesomeIcon icon={faX} color="white" />
                  </div>
                  <h4 className="text-2xl font-bold">This event is canceled</h4>
                </div>
              )}
              <hr className="mx-2 bg-black text-black" />
              <div className="mx-2 my-7 flex flex-col gap-y-3 text-left">
                <div className="flex gap-[70px]">
                  <div>
                    <h4>What</h4>
                  </div>
                  <div>
                    <p>{bookingData.title}</p>
                  </div>
                </div>
                <div className="flex gap-[70px]">
                  <div>
                    <h4>When</h4>
                  </div>
                  <div>
                    <div>
                      <p
                        style={{
                          textDecoration:
                            bookingData.status === 'accepted' ? 'normal' : 'line-through',
                        }}
                      >
                        {`${day}, ${month} ${dateToday}, ${year}`}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{
                          textDecoration:
                            bookingData.status === 'accepted' ? 'normal' : 'line-through',
                        }}
                      >
                        {`${startTime}`} - {`${endTime}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-[70px]">
                  <div>Who</div>
                  <div>
                    <div>
                      <div>
                        <h4>
                          {bookingData.hosts[0]?.name}{' '}
                          <span className="rounded-md bg-blue-800 px-2 text-sm text-white">
                            Host
                          </span>
                        </h4>
                        <p>{cleanCalcomEmail(bookingData.hosts[0]?.email || '')}</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <h4>{`${bookingData.attendees[0]?.name}`}</h4>
                        <p>{cleanCalcomEmail(`${bookingData.attendees[0]?.email}`)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {!!bookingData.location && (
                  <div className="flex gap-[70px]">
                    <div>
                      <h4>Where</h4>
                    </div>
                    <div>
                      <p>{bookingData.location}</p>
                    </div>
                  </div>
                )}
                {bookingData.bookingFieldsResponses?.notes && (
                  <div className="flex gap-[70px]">
                    <div className="w-[40px]">
                      <h4>Additional notes</h4>
                    </div>
                    <div>
                      <p>{`${bookingData.bookingFieldsResponses.notes}`}</p>
                    </div>
                  </div>
                )}
              </div>

              {bookingData.status === 'accepted' && (
                <>
                  <hr className="mx-3" />
                  <div className="mx-2 my-3 text-center">
                    <p>
                      Need to make a change?{' '}
                      <button
                        className="underline"
                        onClick={() => {
                          history(
                            `/book/${userSlug}/${bookingData?.eventType?.slug}?rescheduleUid=${bookingData?.uid}&eventTypeSlug=${bookingData?.eventType?.slug}`,
                          );
                        }}
                      >
                        Reschedule
                      </button>{' '}
                      or{' '}
                      <button
                        className="underline"
                        onClick={() => {
                          cancelBooking({
                            id: bookingData.id,
                            uid: bookingData.uid,
                            cancellationReason: 'User request',
                            allRemainingBookings: true,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </SchedulerLayout>
  );
}
