import { Card, CardContent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NumberedServiceCard from '../../components/quickStartTasks/numbered-service-card';
import { AuthContext } from '../../contexts/authContext';
import { useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { environment } from '../../environments/environment';

export const QuickStartPageV2 = () => {
  const navigate = useNavigate();
  const { bgColor } = useAppExperience();
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [bookingLink, setBookingLink] = useState('');
  const { remainingFormattedTasks, subscriptionTierFormattedTasks, completedFormattedTasks } =
    useQuickStart();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!authContext.user?.id) return;
      const user = authContext.user;
      const bookingLink = `${environment.baseBookingUrl}/${user.user_slug?.slug}`;
      setBookingLink(bookingLink);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex h-full">
        <div className="flex flex-col  text-center py-4 h-full pl-2  mx-2">
          <Card
            sx={{
              flexGrow: 1,
              transition: 'opacity 0.3s',
              ml: '25px',
              position: 'relative',
              borderRadius: '24px',
              boxShadow: 'none',
              bgcolor: '#F7F7F7',
              marginBottom: '8px',
            }}
          >
            <CardContent sx={{ paddingY: 3, paddingX: 2 }}>
              <div className="flex flex-row justify-between w-full">
                <div className="w-1/2 pr-2">
                  <div className="overflow-hidden">
                    <img
                      className="w-full h-full scale-125"
                      src="/assets/quick_start_booking.png"
                      alt="booking page trio"
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col justify-start text-left pl-2">
                  <div className="flex flex-row pb-4">
                    <span className="font-avenir text-sm ">
                      Your booking page is empty and needs your help.
                    </span>
                  </div>
                  <div className="flex flex-row pb-4">
                    <span className="font-avenir text-sm leading-4">
                      Complete each step to make it come alive.
                    </span>
                  </div>
                  <div className="flex flex-row">
                    <span
                      onClick={() => {
                        window.open(bookingLink, '_blank');
                      }}
                      className="font-avenir text-sm underline cursor-pointer"
                    >
                      Go ahead, see your booking page.
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          {remainingFormattedTasks.map((task) => (
            <NumberedServiceCard
              key={task.number}
              title={task.title}
              description={task.description}
              buttonText={task.buttonText}
              onButtonClick={task.buttonOnClick}
              number={task.number}
              canDismiss={task.canBeSkipped}
              onDismiss={task.onDismiss}
            />
          ))}
          {subscriptionTierFormattedTasks.map((task) => (
            <div className="flex flex-row">
              <span className="text-2 mr-2 text-black font-avenir font-semibold leading-4 w-5"></span>
              <div className="flex flex-row justify-between w-full bg-black rounded-3xl items-center text-center mb-2 pl-4 pr-2 py-2">
                <div className="flex flex-row justify-start items-start text-left">
                  <span className="text-[16px] font-avenir font-semibold leading-4  text-white">
                    {task.title}
                  </span>
                </div>
                <button
                  className="text-[12px] text-black bg-[#ffffff] h-10 px-4 font-avenir font-normal leading-4 rounded-full"
                  onClick={() => {
                    navigate('/subscription');
                  }}
                >
                  {'Upgrade'}
                </button>
              </div>
            </div>
          ))}
          {completedFormattedTasks.map((task) => (
            <NumberedServiceCard
              key={task.number}
              title={task.title}
              description={task.description}
              buttonText={task.buttonText}
              onButtonClick={task.buttonOnClick}
              number={task.number}
              disabled={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
