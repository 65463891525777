import { Contact } from '@book-nestor-monorepo/shared-types';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import { createCheckoutSession } from '../../../libs/services/connect';
import PrimaryTextField from '../../formLibrary/primaryTextInput';

export const ContactPaymentAction = ({
  contact,
  onClose,
  onSuccess,
  serviceSlug,
}: {
  contact: Contact;
  onClose?: () => void;
  onSuccess?: (contactId?: string) => void;
  serviceSlug?: string;
}) => {
  const authContext = useContext(AuthContext);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [service, setService] = useState('');

  const submitCheckout = async () => {
    if (!amount || !service) return;
    const url = await createCheckoutSession({
      connected_account_id: authContext.user?.payment_connect_data?.connected_account_id as string,
      amount: amount * 100,
      product_name: service,
      customer_email: contact?.email as string,
      customer_id: contact?.payment_customer_id,
    });
    window.location.href = url.url;
  };

  if (!authContext.user?.payment_connect_data?.payment_requirements_complete) {
    return (
      <Link
        to={authContext.user?.payment_connect_data?.complete_requirements_link as string}
        target="_blank"
        rel="noopener noreferrer"
        className=" text-black text-xs"
      >
        Complete Stripe setup to accept payments
        <FontAwesomeIcon className="pl-2" icon={faArrowRight} size="sm" />
      </Link>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-between pt-1.5 px-2 pb-4  mb-2">
        <p className="text-[41.89px] leading-[48px] text-black font-extralight font-avenir">
          Begin Payment Process
        </p>
      </div>
      <form className="flex flex-col justify-start gap-4">
        <PrimaryTextField
          label="Amount"
          color="primary"
          onChange={(e) => {
            const value = Number(e.target.value);
            setAmount(value === 0 ? undefined : value);
          }}
          type="number"
          name="amount"
          value={amount}
          className="w-full"
          variant="filled"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />

        <PrimaryTextField
          label="Service"
          color="primary"
          onChange={(e) => setService(e.target.value)}
          type="text"
          name="service"
          value={service}
          className="w-full"
          variant="filled"
        />

        <div className="flex flex-row justify-start gap-2">
          <button
            disabled={
              !amount ||
              !service ||
              !authContext.user?.payment_connect_data?.payment_requirements_complete
            }
            onClick={(e) => {
              if (!amount || !service) return;
              e.preventDefault();
              submitCheckout();
            }}
            className={`bg-black text-white font-bold py-2 px-3 rounded-full ${
              !amount ||
              !service ||
              !authContext.user?.payment_connect_data?.payment_requirements_complete
                ? 'opacity-50'
                : ''
            }`}
          >
            <span className="text-[#5f4cfe] pt-4">
              <FontAwesomeIcon color="white" size="2xl" className="px-1" icon={faStripe} />
            </span>
            Checkout
          </button>
        </div>
      </form>
    </>
  );
};
