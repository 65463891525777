import { Box, Card, CardContent } from '@mui/material';
import React from 'react';
import AgentDashboardPopover from '../../Agent/AgentDashboardPopover';
import { agentMenuDrawerTabProps } from '../AgentMenuDrawerTabs';
import { MultiPartSkeleton } from '../../Skeleton/MultiPartSkeleton';
import { InsightsMetricType } from '@book-nestor-monorepo/shared-types';
import { useAgent } from '../../../../contexts/v2/agentContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function AgentDashboard(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...agentMenuDrawerTabProps(index)} {...other}>
      {value === index && (
        <Box sx={{ p: '8px', minHeight: '360px', marginTop: '24px' }}>{children}</Box>
      )}
    </div>
  );
}

export function AgentMenuDrawerDashboardTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const { agentMetrics, isLoading: isLoadingAgentMetrics } = useAgent();

  if (isLoadingAgentMetrics) {
    return <MultiPartSkeleton color="#DADCDC" />;
  }

  return (
    <AgentDashboard value={value} index={0}>
      <div className="flex flex-col items-start w-full h-full">
        <Card
          sx={{
            borderRadius: '20px',
            width: '100%',
            minHeight: '100%', // Changed from height to minHeight
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#D2D2D2',
          }}
        >
          <CardContent
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: '8px',
              '&:last-child': {
                paddingBottom: '8px', // Or any other value you prefer
              },
            }}
          >
            <div className="flex flex-col items-start justify-between w-full h-full">
              <div className="flex-row items-start justify-between">
                <AgentDashboardPopover />
              </div>
              <div className="flex justify-between items-end pt-12 flex-grow">
                <div className="w-1/2">
                  <p className="text-[24px] leading-[30px] font-avenir text-left">
                    Your Ni performance metrics
                  </p>
                </div>
                <div className="w-1/2 text-right">
                  {/* <p className="text-[42px] leading-[30px] font-avenir">
                    9.3<span className="text-[12px] font-inter block">High Quality</span>
                  </p> */}
                </div>
              </div>
              <div className="flex flex-wrap w-full mt-4 gap-1">
                <div className="flex-1 basis-[calc(50%-0.5rem)] bg-[#FFFFFF] text-black rounded-xl p-4 flex flex-col justify-between  text-left h-[154px]">
                  <p className="text-[12px] font-inter ">Conversations</p>
                  <p className="text-[42px] font-avenir">
                    {(agentMetrics?.find(
                      (metric) =>
                        metric.metric_type === InsightsMetricType.NI_VOICE_CONVERSATION_COUNT,
                    )?.value || 0) +
                      (agentMetrics?.find(
                        (metric) =>
                          metric.metric_type === InsightsMetricType.NI_SMS_CONVERSATION_COUNT,
                      )?.value || 0)}
                  </p>
                </div>
                <div className="flex-1 basis-[calc(50%-0.5rem)] bg-[#FFFFFF] text-black rounded-xl p-4 flex flex-col justify-between text-left h-[154px]">
                  <p className="text-[12px] font-inter">Recovery Conversations</p>
                  <p className="text-[42px] font-avenir">
                    {
                      agentMetrics.find(
                        (metric) =>
                          metric.metric_type === InsightsMetricType.NI_RECOVERY_CONVERSATION_COUNT,
                      )?.value
                    }
                  </p>
                </div>
                <div className="flex-1 basis-[calc(50%-0.5rem)] bg-[#FFFFFF] text-black rounded-xl p-4 flex flex-col justify-between  text-left h-[154px]">
                  <p className="text-[12px] font-inter text-gray-600">Booked Appointments</p>
                  <p className="text-[42px] font-avenir">
                    {
                      agentMetrics.find(
                        (metric) =>
                          metric.metric_type === InsightsMetricType.NI_BOOKED_CONVERSATION_COUNT,
                      )?.value
                    }
                  </p>
                </div>
                <div className="flex-1 basis-[calc(50%-0.5rem)] bg-[#FFFFFF] text-black rounded-xl p-4 flex flex-col justify-between text-left h-[154px]">
                  <p className="text-[12px] font-inter text-gray-600">Messages Taken</p>
                  <p className="text-[42px] font-avenir">
                    {
                      agentMetrics.find(
                        (metric) => metric.metric_type === InsightsMetricType.NI_MESSAGE_COUNT,
                      )?.value
                    }
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </AgentDashboard>
  );
}
