export function formatPhoneNumber(phoneNumberString: string) {
  // Check if the phone number starts with a "+"
  if (phoneNumberString.startsWith('+')) {
    // Remove the "+" and the digit immediately following it
    phoneNumberString = phoneNumberString.slice(2);
  }

  // Remove all non-digit characters from the phone number string
  const cleanedPhoneNumber = phoneNumberString.replace(/\D/g, '');

  // Check if the cleaned phone number has exactly 10 digits
  if (cleanedPhoneNumber.length !== 10) {
    return phoneNumberString;
  }

  // Format the cleaned phone number as (123)-456-7890
  const formattedPhoneNumber = `(${cleanedPhoneNumber.slice(0, 3)})-${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6)}`;

  return formattedPhoneNumber;
}

export function formatPhoneForTextMessage(phoneNumber: string) {
  if (!phoneNumber) return phoneNumber;

  let formattedPhoneNumber = phoneNumber;

  if (formattedPhoneNumber.startsWith('+1')) {
    formattedPhoneNumber = formattedPhoneNumber.substring(2);
  } else if (formattedPhoneNumber.startsWith('+')) {
    formattedPhoneNumber = formattedPhoneNumber.substring(1);
  }
  formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, '');

  return `+1${formattedPhoneNumber}`;
}
