import { Contact } from '@book-nestor-monorepo/shared-types';
import { faArrowRight, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumber } from '../../libs/utils/phone.util';

export const ContactsTable: React.FC<{
  contacts: Contact[];
  handleSearchChange: (value: string) => void;
  handleButtonClick: (buttonType: 'regular' | 'favorite' | 'difficult') => void;
  handleContactClick: (contactId: string) => void;
  searchString: string;
  addNewContact: () => void;
  activeTab: {
    regular: boolean;
    favorite: boolean;
    difficult: boolean;
  };
}> = ({
  contacts,
  handleSearchChange,
  handleButtonClick,
  handleContactClick,
  searchString,
  addNewContact,
  activeTab,
}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  const ContactSearchInput = ({
    handleSearchChange,
    value,
  }: {
    handleSearchChange: (value: string) => void;
    value: string;
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [value]);

    return (
      <div className="font-avenir mb-2">
        <div className="mt-2 grid grid-cols-1">
          <input
            ref={inputRef}
            placeholder="Search by name, email, or phone number"
            value={value}
            onChange={(event) => handleSearchChange(event.target.value)}
            className="col-start-1 row-start-1 block w-full max-w-[500px] min-w-[400px] rounded-full bg-white py-3 lg:py-2 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400  sm:pl-9 sm:text-sm/6 font-avenir placeholder:font-avenir placeholder:text-[14px]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
          />
        </div>
      </div>
    );
  };

  const ContactToggles = () => {
    return (
      <div className="flex gap-2  font-avenir mb-5 lg:mb-0">
        <div
          className={`h-9 min-h-9 px-[18px] flex items-center border ${
            activeTab.regular ? 'bg-black-alt text-white' : 'border-black text-black'
          } rounded-full text-[14px] cursor-pointer font-avenir`}
          onClick={() => handleButtonClick('regular')}
        >
          Regulars
        </div>
        <div
          className={`h-9 min-h-9 px-[18px] flex items-center border ${
            activeTab.favorite ? 'bg-black-alt text-white' : 'border-black text-black'
          } rounded-full text-[14px] cursor-pointer font-avenir`}
          onClick={() => handleButtonClick('favorite')}
        >
          Favorites
        </div>
        <div
          className={`h-9 min-h-9 px-[18px] flex items-center border ${
            activeTab.difficult ? 'bg-black-alt text-white' : 'border-black text-black'
          } rounded-full text-[14px] cursor-pointer font-avenir`}
          onClick={() => handleButtonClick('difficult')}
        >
          Difficult
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col lg:bg-white rounded-lg shadow shadow-gray-200 p-2 lg:p-4">
      <div className="flex w-full items-center justify-between mb-4">
        <h2 className="text-[22px] font-medium font-avenir ">Contacts</h2>

        <button
          className=" w-auto bg-black text-white rounded-full text-[14px] font-avenir font-normal px-4 py-2 hidden lg:block"
          onClick={addNewContact}
        >
          Create a new Contact
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
        <button
          className=" w-auto bg-black text-white rounded-full text-[12px] font-avenir font-normal px-4 py-2 block lg:hidden"
          onClick={addNewContact}
        >
          Create
          <FontAwesomeIcon icon={faPlus} className="ml-2" />
        </button>
      </div>
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 sm:items-start sm:justify-between w-full lg:items-center">
        {' '}
        <ContactSearchInput handleSearchChange={handleSearchChange} value={searchString} />
        <ContactToggles />
      </div>
      <div className="w-full h-full lg:bg-[#F5F5F7] lg:rounded-lg">
        {/* Mobile view (up to lg breakpoint) */}
        <div className="lg:hidden space-y-2">
          {contacts.map((contact, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`p-4 bg-white ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}`}
              >
                <div className="w-full" onClick={() => handleContactClick(contact.id as string)}>
                  <div className="flex w-full h-auto">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex">
                        <div className="h-8 w-8 bg-black rounded-full items-center justify-center flex">
                          <span className="text-[12px] font-normal font-inter text-white">
                            {contact.name?.charAt(0)}
                            {contact.last_name?.charAt(0)}
                          </span>
                        </div>
                        <div className="flex items-center justify-start pl-2">
                          <span className="text-[12px] font-normal font-inter text-gray-600">
                            {contact.name} {contact.last_name}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col justify-end items-end">
                        <span className="text-[12px] font-normal font-inter text-gray-600">
                          {contact.email}
                        </span>
                        {contact.phone_number && (
                          <span className="text-[12px] font-normal font-inter text-gray-600">
                            {formatPhoneNumber(contact.phone_number)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full h-auto pt-4">
                    {' '}
                    <div className="flex items-center justify-between w-full font-avenir text-[14px]">
                      <div className="flex">
                        {' '}
                        {contact.is_regular && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Regular</span>
                          </div>
                        )}
                        {contact.is_favorite && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-green-200 rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Favorite</span>
                          </div>
                        )}
                        {contact.is_difficult && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-red-200 rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Difficult</span>
                          </div>
                        )}
                      </div>
                      <div className="flex">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className={`text-[12px] text-gray-600 transform transition-transform duration-200 ${
                            expandedRows[index] ? 'rotate-90' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop view (lg and above) */}
        <div className="hidden lg:block">
          <div className="min-w-full w-full p-2">
            <div className="flex text-[14px] font-inter text-gray-600 text-left">
              <div className="flex-none p-2 w-20"></div>
              <div className="flex-1 p-4">First Name</div>
              <div className="flex-1 p-4">Last Name</div>
              <div className="flex-1 p-4">Email</div>
              <div className="flex-1 p-4">Phone</div>
              <div className="flex-1 p-4">Tags</div>
              {/* <div className="flex-1 p-0"></div> */}
            </div>
            <div className="space-y-2 mt-2 text-left">
              {contacts.map((contact, index) => (
                <div
                  key={index}
                  className="flex flex-col"
                  onClick={() => handleContactClick(contact.id as string)}
                >
                  <div
                    className={`flex ${
                      index % 2 === 0 ? 'bg-[#CED0D030]' : 'bg-[#F5F5F7]'
                    } ${expandedRows[index] ? 'rounded-t-xl' : 'rounded-xl'}  cursor-pointer`}
                  >
                    <div className="flex-none p-2 font-bold font-avenir text-[12px] w-20">
                      <div className="h-8 w-8 bg-black rounded-full items-center justify-center flex">
                        <span className="text-[12px] font-normal font-inter text-white">
                          {contact.name?.charAt(0)}
                          {contact.last_name?.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="flex-1 p-4 font-bold font-avenir text-[12px]">
                      {contact.name}
                    </div>

                    <div className="flex-1 p-4 font-avenir text-[12px]">{contact.last_name}</div>
                    <div className="flex-1 p-4 font-avenir text-[12px] justify-start">
                      {contact.email}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      {contact.phone_number && formatPhoneNumber(contact.phone_number)}
                    </div>
                    <div className="flex-1 p-4 font-avenir text-[12px]">
                      <div className="flex items-center justify-start w-full">
                        {contact.is_regular && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-[#B8E7F4] rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Regular</span>
                          </div>
                        )}
                        {contact.is_favorite && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-green-200 rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Favorite</span>
                          </div>
                        )}
                        {contact.is_difficult && (
                          <div className="flex h-[19px] w-auto items-center p-2 bg-red-200 rounded-full mr-1">
                            <span className="text-[10px] font-avenir">Difficult</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
