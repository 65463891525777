import { PaginationMeta } from '@book-nestor-monorepo/nestor-dtos';
import { Booking, BookingStatus, PaginatedBookingItem } from '@book-nestor-monorepo/shared-types';
import { useContext, useEffect, useState } from 'react';
import LoadingDots from '../../components/loading';
import { AuthContext } from '../../contexts/authContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { getAgentConversations } from '../../libs/services/agent-conversations';
import { getBookings } from '../../libs/services/bookings';
import { ConversationsTable } from './conversations-table';
import { AgentConversationDrawer } from './right-drawer-components/agent-conversation-drawer';
import { BookingsTable } from './bookings-table';
import { BookingDetailDrawer } from './right-drawer-components/booking-detail-drawer';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

interface BookingsByStatus {
  [BookingStatus.UPCOMING]: Booking[];
  [BookingStatus.PAST]: Booking[];
  [BookingStatus.CANCELED]: Booking[];
}

interface PaginationState {
  hasMore: boolean;
  take: number;
  skip: number;
  isLoading: boolean;
}

interface PaginationByStatus {
  [BookingStatus.UPCOMING]: PaginationState;
  [BookingStatus.PAST]: PaginationState;
  [BookingStatus.CANCELED]: PaginationState;
}

export const BookingsListV2 = () => {
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const [selectedStatus, setSelectedStatus] = useState<TSelectedStatus>(BookingStatus.UPCOMING);
  const [isFiltersOpen, setIsFiltersOpen] = useState(true);
  //   const [bookings, setBookings] = useState<PaginatedBookingItem>({
  //     items: [],
  //     meta: {
  //       take: 0,
  //       hasMore: false,
  //     },
  //   });

  const [bookingsByStatus, setBookingsByStatus] = useState<BookingsByStatus>({
    [BookingStatus.UPCOMING]: [],
    [BookingStatus.PAST]: [],
    [BookingStatus.CANCELED]: [],
  });
  const [paginationByStatus, setPaginationByStatus] = useState<PaginationByStatus>({
    [BookingStatus.UPCOMING]: { hasMore: true, take: 10, skip: 0, isLoading: false },
    [BookingStatus.PAST]: { hasMore: true, take: 10, skip: 0, isLoading: false },
    [BookingStatus.CANCELED]: { hasMore: true, take: 10, skip: 0, isLoading: false },
  });

  const toggleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const [isLoading, setIsLoading] = useState(false);

  const user = useContext(AuthContext);

  const handleBookingClick = (bookingId: string) => {
    if (isOpen) {
      setDrawerContent(
        <BookingDetailDrawer
          bookingId={bookingId}
          onRefresh={onRefresh}
          toggleDrawer={toggleDrawer}
        />,
        {
          darkMode: true,
        },
      );
    } else {
      setDrawerContent(
        <BookingDetailDrawer
          bookingId={bookingId}
          onRefresh={onRefresh}
          toggleDrawer={toggleDrawer}
        />,
        {
          darkMode: true,
        },
      );
      toggleDrawer();
    }
  };

  const handleStatusChange = async (status: TSelectedStatus) => {
    setSelectedStatus(status);
    // If no bookings have been loaded for this status yet, fetch them
    if (bookingsByStatus[status].length === 0 && paginationByStatus[status].hasMore) {
      await handleNextBookings(status);
    }
  };

  async function loadData() {
    setIsLoading(true);
    const bookings = await getBookings(user?.user?.id as string, BookingStatus.UPCOMING, {
      take: 10,
      sortStart: 'asc',
    });

    setPaginationByStatus((prev) => ({
      ...prev,
      [BookingStatus.UPCOMING]: {
        ...prev[BookingStatus.UPCOMING],
        hasMore: bookings.meta.hasMore,
        skip: bookings.meta.take,
      },
    }));

    const sortByStartTime = (bookings: Booking[], reverse = true) => {
      return bookings.sort((a, b) => {
        const diff = new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
        return reverse ? -diff : diff;
      });
    };

    setBookingsByStatus((prev) => ({
      ...prev,
      [BookingStatus.UPCOMING]: sortByStartTime(bookings.items, false),
    }));

    setIsLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const onRefresh = async () => {
    await loadData();
  };

  const handleNextBookings = async (status: TSelectedStatus) => {
    if (!user?.user?.id || paginationByStatus[status].isLoading) return;
    const newPaginationState = { ...paginationByStatus };
    newPaginationState[status].isLoading = true;
    setPaginationByStatus(newPaginationState);

    try {
      const bookingsPaginatedResponse = await getBookings(user?.user?.id as string, status, {
        take: paginationByStatus[status].take,
        skip: paginationByStatus[status].skip,
        sortStart: status === BookingStatus.UPCOMING ? 'asc' : 'desc',
      });

      const newBookings = bookingsPaginatedResponse.items;

      // Update pagination state
      setPaginationByStatus((prev) => ({
        ...prev,
        [status]: {
          ...prev[status],
          hasMore: bookingsPaginatedResponse.meta.hasMore,
          skip: prev[status].skip + bookingsPaginatedResponse.meta.take,
          isLoading: false,
        },
      }));

      // Sort function
      const sortByStartTime = (bookings: Booking[], reverse = true) => {
        return bookings.sort((a, b) => {
          const diff = new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
          return reverse ? -diff : diff;
        });
      };

      // Merge and sort bookings
      setBookingsByStatus((prev) => ({
        ...prev,
        [status]: sortByStartTime(
          [...prev[status], ...newBookings],
          status !== BookingStatus.UPCOMING,
        ),
      }));
    } catch (error) {
      setPaginationByStatus((prev) => ({
        ...prev,
        [status]: { ...prev[status], isLoading: false },
      }));
    }
  };

  return (
    <div className={`flex flex-col h-auto `}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        {isLoading ? (
          <LoadingDots skeletonProps={{ count: 4 }} />
        ) : (
          <BookingsTable
            bookings={bookingsByStatus[selectedStatus]}
            bookingsMeta={paginationByStatus[selectedStatus]}
            handleNextBookings={handleNextBookings}
            handleStatusChange={handleStatusChange}
            handleBookingClick={handleBookingClick}
            selectedStatus={selectedStatus}
            isFiltersOpen={isFiltersOpen}
            toggleFilters={toggleFilters}
          />
        )}
      </div>
    </div>
  );
};
