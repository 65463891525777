import React from 'react';
import { Formik, Form, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';

interface FormikWrapperProps<T> {
  initialValues: T;
  validationSchema: Yup.ObjectSchema<Partial<T>>;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  children: (props: { dirty: boolean }) => React.ReactNode;
  submitButton?: (props: {
    isSubmitting: boolean;
    isValid: boolean;
    dirty: boolean;
  }) => React.ReactNode;
}

const FormikWrapper = <T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  submitButton,
}: FormikWrapperProps<T>) => {
  return (
    <Formik<T>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          {children({ dirty })}
          {submitButton && submitButton({ isSubmitting, isValid, dirty })}
        </Form>
      )}
    </Formik>
  );
};

export default FormikWrapper;
