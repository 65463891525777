import {
  BookingConversationItem,
  BookingConversationMessageResponseStatus,
  BookingConversationMessageStatus,
} from '@book-nestor-monorepo/shared-types';
import { styled, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { AgentMessageCardList } from '../../../Agent/AgentMessageCardList';
import { AgentMessageView } from '../AgentMenuDrawerMessagesTabPanel';
import { useAgent } from '../../../../../contexts/v2/agentContext';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
  backgroundColor: '#222222',
  borderRadius: '1px',
  padding: '0px',
  '&.MuiBox-root': {
    padding: '0px',
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '34px',
  border: '1px solid white',
  color: 'white',
  backgroundColor: 'transparent',
  marginLeft: '8px',
  padding: '20px 15px',
  textTransform: 'none',
  fontFamily: 'Avenir',
  fontSize: '14px',
  height: '48px',
  '&.Mui-selected': {
    color: '#000',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  '&:hover': {
    backgroundColor: '#333',
  },
}));

interface CustomToggleButtonProps {
  value: string;
  label: string;
  count: number;
}

const AgentMessagesToggleButton: React.FC<CustomToggleButtonProps> = ({ value, label, count }) => (
  <StyledToggleButton value={value} aria-label={label.toLowerCase()}>
    <div className="flex items-center">
      {label}
      <span className="ml-2">{count}</span>
    </div>
  </StyledToggleButton>
);

export const AgentMessageListView: React.FC<{
  onNavigate: (view: AgentMessageView, agentMessage?: BookingConversationItem) => void;
}> = ({ onNavigate }) => {
  const { conversations, conversationMessages, isLoading } = useAgent();
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [filteredConversations, setFilteredConversations] = useState<BookingConversationItem[]>([]);

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== null) {
      setSelectedFilter(newFilter);
    }
  };

  useEffect(() => {
    // Apply filtering logic here
    const filtered = conversations.filter((conversation) => {
      if (conversation.is_recovery) {
        return false;
      }
      // Implement your filtering logic based on the selectedFilter
      // This is a placeholder, replace with actual logic
      switch (selectedFilter) {
        case 'all':
          return true; // Replace with actual condition
        case 'actionRequired':
          return conversationMessages.some(
            (message) =>
              message.conversation_id === conversation.id &&
              message.response?.status !== BookingConversationMessageResponseStatus.SENT &&
              message.status !== BookingConversationMessageStatus.ACKNOWLEDGED,
          );
        case 'booked':
          return conversation?.booking_id !== undefined; // Check if bookingId exists
        case 'negative':
          return conversation.summary?.sentiment === 'negative'; // Check if sentiment is negative
        case 'unknown':
          return !conversation.contact; // Check if sentiment is unknown
        case 'responded':
          return conversationMessages.some(
            (message) =>
              message.conversation_id === conversation.id &&
              message.response?.status === BookingConversationMessageResponseStatus.SENT,
          );
        case 'acknowledged':
          return conversationMessages.some(
            (message) =>
              message.conversation_id === conversation.id &&
              message.status === BookingConversationMessageStatus.ACKNOWLEDGED &&
              message.response?.status !== BookingConversationMessageResponseStatus.SENT,
          );
        default:
          return true;
      }
    });
    setFilteredConversations(filtered);
  }, [selectedFilter, conversations]);

  const getCounts = (conversations: BookingConversationItem[]) => {
    return {
      all: conversations.length,
      actionRequired: conversations.filter((c) =>
        conversationMessages.some(
          (message) =>
            message.conversation_id === c.id &&
            message.response?.status !== BookingConversationMessageResponseStatus.SENT &&
            message.status !== BookingConversationMessageStatus.ACKNOWLEDGED,
        ),
      ).length,
      booked: conversations.filter((c) => c.booking_id !== undefined).length,
      negative: conversations.filter((c) => c.summary?.sentiment === 'negative').length,
      unknown: conversations.filter((c) => !c.contact).length,
      responded: conversations.filter((c) =>
        conversationMessages.some(
          (message) =>
            message.conversation_id === c.id &&
            message.response?.status === BookingConversationMessageResponseStatus.SENT,
        ),
      ).length,
      acknowledged: conversations.filter((c) =>
        conversationMessages.some(
          (message) =>
            message.conversation_id === c.id &&
            message.status === BookingConversationMessageStatus.ACKNOWLEDGED &&
            message.response?.status !== BookingConversationMessageResponseStatus.SENT,
        ),
      ).length,
    };
  };

  const counts = getCounts(conversations);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <StyledToggleButtonGroup
        value={selectedFilter}
        exclusive
        onChange={handleFilterChange}
        aria-label="filter selection"
        size="small"
        sx={{ paddingLeft: '10px' }}
        classes={{
          firstButton: '!ml-0  !border-l-white !rounded-[34px]',
          lastButton: '!mr-0 !border-r-white  !border-l-white !rounded-[34px]',
          middleButton: '!ml-0 !border-l-white !rounded-[34px]',
        }}
      >
        <AgentMessagesToggleButton value="all" label="All" count={counts.all} />
        {counts.actionRequired > 0 && (
          <AgentMessagesToggleButton
            value="actionRequired"
            label="Action Required"
            count={counts.actionRequired}
          />
        )}
        {counts.booked > 0 && (
          <AgentMessagesToggleButton value="booked" label="Booked" count={counts.booked} />
        )}
        {counts.negative > 0 && (
          <AgentMessagesToggleButton value="negative" label="Negative" count={counts.negative} />
        )}
        {counts.unknown > 0 && (
          <AgentMessagesToggleButton value="unknown" label="Unknown" count={counts.unknown} />
        )}
        {counts.responded > 0 && (
          <AgentMessagesToggleButton value="responded" label="Responded" count={counts.responded} />
        )}
        {counts.acknowledged > 0 && (
          <AgentMessagesToggleButton
            value="acknowledged"
            label="Acknowledged"
            count={counts.acknowledged}
          />
        )}
      </StyledToggleButtonGroup>
      <div className="mt-[30px]">
        <AgentMessageCardList
          agentMessages={filteredConversations}
          onNavigate={onNavigate}
          agentConversationMessages={conversationMessages}
        />
      </div>
    </>
  );
};
