import { faCheck, faEnvelope, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { environment } from '../../environments/environment';
import { sendEmail } from '../../libs/services/email';
import { getUserSlug } from '../../libs/services/user-slug';
import PrimaryTextField from '../formLibrary/primaryTextInput';

type NewBookingFormType = {
  side?: boolean;
  onClose: () => void;
  email?: string;
  name?: string;
  phone?: string;
  contactId?: string;
};

interface FormData {
  phoneNumber: string;
  email: string;
}

export default function NewBookingForm({
  side,
  onClose,
  email,
  phone,
  contactId,
}: NewBookingFormType) {
  const authContext = useContext(AuthContext);
  const history = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    phoneNumber: phone || '',
    email: email || '',
  });
  const [emailSent, setEmailSent] = useState(false);
  const [userSlug, setUserSlug] = useState<string | null>(
    authContext.user?.user_slug?.slug || null,
  );

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authContext.user;
        if (!(user && user.id)) return;
        if (userSlug) return;
        const slugResponse = await getUserSlug(user.id);
        if (slugResponse) {
          setUserSlug(slugResponse.slug);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchData();
  }, []);

  const sendBookingLinkPhone = () => {
    const message = `Book a service with ${authContext.user?.first_name} using this link: ${environment.baseBookingUrl}/${userSlug}`;
    history(`/text-conversations/+1${formData.phoneNumber}?message=${message}`);
    onClose();
  };

  const sendBookingLinkEmail = async () => {
    await sendEmail(formData.email, `${environment.baseBookingUrl}/${userSlug}`);
    setEmailSent(true);
  };

  const bookingLink = () => {
    if (contactId) {
      return `${environment.baseBookingUrl}/${userSlug}?name=${contactId}`;
    }
    return `${environment.baseBookingUrl}/${userSlug}`;
  };

  return (
    <div className="p-4 mt-10 ml-5 h-full">
      <p className="text-base font-semibold font-avenir text-black-solid mb-2">
        Send booking link to:
      </p>
      <div className="flex w-full items-center mb-4">
        <div className="flex items-center relative">
          <InputMask
            mask="(999) 999-9999"
            maskChar="_"
            minLength={10}
            value={formData.phoneNumber}
            onChange={onChangeField}
            name="phoneNumber"
          >
            <PrimaryTextField
              label="Mobile"
              type="tel"
              value={formData.phoneNumber}
              onChange={onChangeField}
              name="phoneNumber"
            />
          </InputMask>
        </div>
        <button
          onClick={sendBookingLinkPhone}
          disabled={!formData.phoneNumber}
          className={`rounded-full border border-black-solid w-14 h-14 ml-4 ${!formData.phoneNumber ? 'opacity-50 cursor-default' : 'cursor-pointer'}`}
        >
          <FontAwesomeIcon icon={faPaperPlane} color="#000" />
        </button>
      </div>
      <div className="flex w-full">
        <div className="flex-grow">
          <PrimaryTextField
            label="Email"
            color="primary"
            onChange={onChangeField}
            type="text"
            name="email"
            value={formData.email}
            className="w-full"
          />
        </div>
        <button
          onClick={sendBookingLinkEmail}
          disabled={!formData.email}
          className={`rounded-full border border-black-solid w-14 h-14 ml-4 ${!formData.email ? 'opacity-50 cursor-default' : 'cursor-pointer'}`}
        >
          {emailSent ? (
            <FontAwesomeIcon icon={faCheck} color="#22C55E" />
          ) : (
            <FontAwesomeIcon icon={faEnvelope} color="#000" />
          )}
        </button>
      </div>
      <div className="w-full pt-12">
        <p className="text-base font-semibold font-avenir text-black-solid mb-2">
          Create a booking yourself:
        </p>
        <a
          href={bookingLink()}
          className="cursor-pointer w-[194px] h-12 rounded-full bg-black-alt gap-[10px] inline-flex items-center justify-center mt-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="text-sm font-avenir text-gray-modal">Book a new event</p>
          <p className="text-sm font-avenir text-gray-modal">|</p>
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.12891 2.5H8.09375V1.40625C8.09375 1.05078 8.36719 0.75 8.75 0.75C9.10547 0.75 9.40625 1.05078 9.40625 1.40625V2.5H10.5C11.457 2.5 12.25 3.29297 12.25 4.25V6H1.28516V13C1.28516 13.2461 1.50391 13.4375 1.72266 13.4375H7.76562C8.09375 13.957 8.53125 14.4219 9.02344 14.75H1.75C0.765625 14.75 0 13.9844 0 13V4.25C0 3.29297 0.765625 2.5 1.75 2.5H2.84375V1.40625C2.84375 1.05078 3.11719 0.75 3.5 0.75C3.85547 0.75 4.15625 1.05078 4.15625 1.40625L4.12891 2.5ZM15.75 10.8125C15.75 13 13.9727 14.75 11.8125 14.75C9.625 14.75 7.84766 13 7.84766 10.8125C7.84766 8.65234 9.625 6.875 11.8125 6.875C13.9727 6.875 15.75 8.65234 15.75 10.8125ZM11.375 9.0625V10.375H10.0625C9.81641 10.375 9.625 10.5938 9.625 10.8125C9.625 11.0586 9.81641 11.25 10.0625 11.25H11.375V12.5625C11.375 12.8086 11.5664 13 11.8125 13C12.0312 13 12.25 12.8086 12.25 12.5625V11.25H13.5625C13.7812 11.25 14 11.0586 14 10.8125C14 10.5938 13.7812 10.375 13.5625 10.375H12.25V9.0625C12.25 8.84375 12.0312 8.625 11.8125 8.625C11.5664 8.625 11.375 8.84375 11.375 9.0625Z"
              fill="#DCDDD7"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
