import NestorFlag from '../../components/nestorFlag';

export function OnboardingLayout({ children, overrideWidth, hideFlag }: any) {
  let widthClass = 'md:w-[360px] md:min-w-[360px] 2xl:w-[411px] 2xl:min-w-[411px]';
  if (overrideWidth) {
    widthClass = overrideWidth;
  }
  return (
    <div className="relative bg-light-gray md:py-[10px] 2xl:py-[40px] md:px-2 2xl:px-[14px] flex items-center w-autol h-screen gap-2">
      {!hideFlag && (
        <NestorFlag
          className="translate-x-[50%] -translate-y-[15%] hidden md:block z-20"
          backgroundColor="bg-[#4B4B4B]"
        />
      )}
      <div className="hidden md:flex w-full h-full items-end bg-[url('/assets/onboarding/onboarding-bg.jpg')] bg-cover bg-center pb-[25px] pl-[28px]">
        <h2 className="font-semibold onboarding-responsive-title text-white">
          Solo doesn’t mean second-rate — <span className="font-normal font-lora">Compete</span>{' '}
          <span className="font-light">and conquer as a thriving solo</span>{' '}
          <span className="italic">professional</span>
        </h2>
      </div>
      <div className={`w-full min-w-full flex items-center ${widthClass} h-full overflow-hidden`}>
        <div className="relative w-full h-full max-h-[932px] overflow-hidden">{children}</div>
      </div>
    </div>
  );
}
