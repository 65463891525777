import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';
import { getAgentConversations } from '../libs/services/agent-conversations';
import { BookingConversationItem, ChannelType } from '@book-nestor-monorepo/shared-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPhone, faSms } from '@fortawesome/pro-light-svg-icons';

export default function AgentConversations() {
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [conversations, setConversations] = useState<BookingConversationItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id) {
          const conversations = await getAgentConversations(user.id);
          setConversations(conversations);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  return (
    <ExperimentalAppLayout
      bookings
      pageTitle="Agent Conversations"
      insightCardsStyles="hidden md:flex"
      componentName="Agent Conversations"
    >
      {isLoading ? (
        <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-4 pt-4">
          <LoadingDots
            skeletonProps={{
              count: 4,
              height: 70,
              borderRadius: 10,
              style: { marginBottom: '10px' },
            }}
          />
        </div>
      ) : (
        <div className="bg-[#E5E6E1] rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full pt-4 overflow-y-scroll">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-black-alt font-semibold pl-4 font-avenir">Agent Conversations</h1>
            <p>
              <Link className="text-blue-500 pr-2" to="/agent-messages">
                Agent Messages
              </Link>
            </p>
          </div>
          <div className="flex flex-col overflow-y-scroll">
            {conversations.map((conversation) => (
              <div key={conversation.id}>
                <Link to={`/agent-conversations/${conversation.id}`}>
                  <div className="bg-white rounded-lg shadow-md p-4 mb-4 mx-4">
                    <span className="text-sm text-gray-500">
                      {conversation.channel_type === ChannelType.VOICE && (
                        <FontAwesomeIcon icon={faPhone} color="green" />
                      )}
                      {conversation.channel_type === ChannelType.SMS && (
                        <FontAwesomeIcon icon={faSms} color="blue" />
                      )}
                      {conversation.channel_type === ChannelType.TEXT && (
                        <FontAwesomeIcon icon={faComment} color="purple" />
                      )}
                    </span>
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm text-gray-500">ID: {conversation.id}</span>
                      <span className="text-sm text-gray-500">
                        {new Date(conversation.created_at).toLocaleString()}
                      </span>
                    </div>
                    {conversation.contact && (
                      <div className="mb-2">
                        <Link to={`/contacts/${conversation.contact.id}`}>
                          <span className="font-semibold">
                            Contact: {conversation.contact.name} {conversation.contact.last_name}
                          </span>
                        </Link>
                      </div>
                    )}
                    {/* <div className="mb-2">
                      <span className="font-semibold">User ID:</span> {conversation.user_id}
                    </div>
                    <div className="mb-2">
                      <span className="font-semibold">Organization ID:</span>{' '}
                      {conversation.organization_id}
                    </div> */}
                    <div className="mb-2">
                      <span className="font-semibold">Status:</span>{' '}
                      {conversation.is_idle ? 'Idle' : 'Active'}
                    </div>

                    {conversation.summary && (
                      <div className="mt-2 p-2 bg-gray-100 rounded">
                        <span className="font-semibold">Summary:</span>
                        {typeof conversation.summary.summary === 'string' && (
                          <div>Summary: {conversation.summary.summary}</div>
                        )}
                        {typeof conversation.summary.sentiment === 'string' && (
                          <div>Sentiment: {conversation.summary.sentiment}</div>
                        )}
                        {typeof conversation.summary.conversation_intent === 'string' && (
                          <div>Intent: {conversation.summary.conversation_intent}</div>
                        )}
                        {typeof conversation.summary.complexity_score === 'number' && (
                          <div>Complexity Score: {conversation.summary.complexity_score}</div>
                        )}
                        {typeof conversation.summary.outcome === 'string' && (
                          <div>Outcome: {conversation.summary.outcome}</div>
                        )}
                        {typeof conversation.summary.duration === 'number' && (
                          <div>Duration: {conversation.summary.duration} seconds</div>
                        )}
                        {conversation.booking_id && (
                          <div>Related Booking ID: {conversation.booking_id}</div>
                        )}
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
