import { SubscriptionProduct, SubscriptionProductPrice } from '@book-nestor-monorepo/shared-types';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import PromoCodeInput from '../../../components/app/v2/promoCodeInput';
import { useStripePayment } from '../../../contexts/paymentContext';

interface CardInfoFormProps {
  // selectedProduct?: SubscriptionProduct;
  selectedPrice: SubscriptionProductPrice;
  selectedProduct: SubscriptionProduct;
  // setIsSubscriptionCreated: any;
  changeSubscriptionAction: (
    newProduct: SubscriptionProductPrice,
    paymentMethod?: any,
    promoCode?: string,
  ) => Promise<void>;
}

// Initialize Stripe

export const SubscriptionPaymentDrawer = (props: CardInfoFormProps) => {
  const { stripe } = useStripePayment();

  const { selectedPrice, changeSubscriptionAction, selectedProduct } = props;

  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
  const [upgradePrice, setUpgradePrice] = useState<SubscriptionProductPrice>(selectedPrice);
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements || !selectedPrice) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      try {
        await changeSubscriptionAction(upgradePrice, paymentMethod, promoCode);
      } catch (err: any) {
        console.log('Subscription created error =>', err);
        const message = err?.response?.data?.message || 'Error';
        setMessage(message);
      }

      setIsLoading(false);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Inter", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disableLink: true,
  };

  const handleFreePlanClick = async () => {
    setIsLoading(true);
    await changeSubscriptionAction(selectedProduct?.monthlyPrice);
    setIsLoading(false);
  };

  const formatPriceToDisplay = (priceObject?: SubscriptionProductPrice): string => {
    if (!priceObject) return '';

    const _isMonthly = priceObject.recurringInterval === 'month';
    const price = _isMonthly ? priceObject.price / 100 : priceObject.price / 100 / 12;
    if (price === 0) return '0';
    return `${price.toFixed(2)}`;
  };

  return stripe ? (
    <div className="flex flex-col p-4">
      <h3 id={selectedProduct?.id} className=" text-gray-900 text-lg/8 font-semibold">
        {selectedProduct?.name}
      </h3>

      {selectedProduct?.monthlyPrice.price === 0 && selectedProduct?.yearlyPrice.price === 0 ? (
        <div>
          <div className="flex flex-col mt-6">
            <button
              type="button"
              disabled={isLoading}
              className="
                mt-6
                bg-[#222222]
                disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed
                text-white font-bold py-2 px-4 rounded-full w-full
              "
              onClick={handleFreePlanClick}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} color="white" spin />
              ) : (
                'Get it for free'
              )}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-col mt-6">
            <h6 className="font-avenir text-[18px] mb-4">1. Choose Billing Cycle</h6>
          </div>

          <div className="flex flex-col">
            {/* Monthly */}
            <div className="flex flex-row items-center mb-2 border-[1px] border-gray-200 rounded-[12px] p-4">
              <input
                type="radio"
                name="billingCycle"
                className="mr-2"
                checked={upgradePrice?.id === selectedProduct?.monthlyPrice?.id}
                value={selectedProduct?.monthlyPrice?.id}
                onChange={(e) => {
                  // console.log('onChange monthly =>', e.target.value);
                  setUpgradePrice(selectedProduct?.monthlyPrice);
                }}
              />
              <div className="flex flex-col">
                <span className="font-inter font-bold">Monthly</span>
                <span className="text-sm font-normal font-inter">{`$${formatPriceToDisplay(selectedProduct?.monthlyPrice)}/month`}</span>
              </div>
            </div>

            {/* Yearly */}
            <div className="flex flex-row items-center border-[1px] border-gray-200 rounded-[12px] p-4 relative">
              <input
                type="radio"
                name="billingCycle"
                className="mr-2"
                checked={upgradePrice?.id === selectedProduct?.yearlyPrice.id}
                value={selectedProduct?.yearlyPrice.id}
                onChange={(e) => {
                  // console.log('onChange yearly =>', e.target.value);
                  setUpgradePrice(selectedProduct?.yearlyPrice);
                }}
              />
              <div className="flex flex-col">
                <span className="font-inter font-bold">Yearly</span>
                <span className="text-sm font-normal font-inter">{`$${formatPriceToDisplay(selectedProduct?.yearlyPrice)}/month`}</span>
              </div>

              <div className="absolute top-1 right-1 rounded-lg bg-green-500 text-white text-xs font-bold px-2 py-1 ">
                SAVE $
                {(
                  ((selectedProduct?.monthlyPrice.price || 0) * 12 -
                    (selectedProduct?.yearlyPrice.price || 0)) /
                  100
                ).toFixed(2)}
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <h6 className="font-avenir text-[18px] mb-4">2. Payment Method</h6>
          </div>
          <form onSubmit={handleSubmit} className="w-full p-4 bg-white md:bg-transparent">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div className="flex flex-row items-center mt-4">
              <PromoCodeInput onPromoCodeChange={setPromoCode} selectedProduct={selectedProduct} />
            </div>

            <div className="flex flex-row gap-2">
              <button
                type="submit"
                disabled={isLoading || !stripe || !elements || !selectedPrice}
                className="
                  mt-4
                  bg-black
                  disabled:bg-gray-400 disabled:text-gray-700 disabled:cursor-not-allowed
                  text-white font-avenir text-[18px] py-2 px-4 rounded-full w-full
                "
              >
                {isLoading ? <FontAwesomeIcon icon={faSpinner} color="white" spin /> : 'Purchase'}
              </button>
            </div>
            {message && (
              <div id="payment-message" className="text-red-500 text-sm mt-2">
                {message}
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  ) : null;
};
