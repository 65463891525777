import { TextConversation } from '@book-nestor-monorepo/shared-types';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { useWebSocket } from '../contexts/websocket.context';
import { getTextConversations } from '../libs/services/text.service';
import { getServicePhoneDetailsByUser } from '../libs/services/voice';
import { formatTextMessageDate } from '../libs/utils/date.util';
import { formatPhoneNumber } from '../libs/utils/phone.util';
import { truncateText } from '../libs/utils/string.util';
import { AppLayout } from './layouts/app-layout';
import { StoreContext } from '../contexts/storeContext';
import { assignContactNamesToTextConversations } from '../libs/utils/contacts.util';

const ConversationItem = ({ conversation }: { conversation: TextConversation }) => {
  return (
    <div className="flex flex-col p-2 py-4 border-b border-gray-200">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-base font-inter text-left">
          {conversation.name || formatPhoneNumber(conversation.to)}
        </h3>
        <span className="text-xs text-black text-[9.89px]">
          {formatTextMessageDate(new Date(conversation.updatedAt))}
        </span>
      </div>
      <p className="text-sm text-gray-400 text-left break-words">
        {truncateText(conversation.lastMessage, 100)}
      </p>
    </div>
  );
};

export default function TextConversationsPage() {
  const { state } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const authContext = useContext(AuthContext);
  const { messages } = useWebSocket();

  const [servicePhoneNumber, setServicePhoneNumber] = useState('');
  const [textConversations, setTextConversations] = useState<TextConversation[]>([]);

  const fetchTextConversaions = async (servicePhoneNumber: string) => {
    const textConversations = await getTextConversations(servicePhoneNumber);
    // assign contact names from store
    const textConversationsWithNames = assignContactNamesToTextConversations(
      textConversations,
      state.contacts,
    );
    setTextConversations(textConversationsWithNames);
  };

  useEffect(() => {
    // assign contact names from store
    const textConversationsWithNames = assignContactNamesToTextConversations(
      textConversations,
      state.contacts,
    );
    setTextConversations(textConversationsWithNames);
  }, [state.contacts]);

  useEffect(() => {
    if (messages.length > 0) {
      fetchTextConversaions(servicePhoneNumber);
    }
  }, [messages]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;

        // TODO: move to GlobalContext
        const userServicePhoneResponse = await getServicePhoneDetailsByUser(user.id || '');
        if (!userServicePhoneResponse?.phone_number) return;

        setServicePhoneNumber(userServicePhoneResponse.phone_number);

        fetchTextConversaions(userServicePhoneResponse.phone_number);
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [authContext.user]);

  const handleButtonClick = async (event: any) => {
    event.preventDefault();
    try {
      history('/text-conversations/new');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <AppLayout>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col h-full pt-8 md:pt-2">
          <div className="flex items-center justify-start pb-4 px-2">
            <span className="text-[64px] text-white">Messages</span>
          </div>

          <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4 overflow-none">
            <div className="flex flex-row items-baseline pb-4">
              <div className="flex justify-start flex-grow-0">
                <span className="text-lg font-avenir">Messages</span>
              </div>
              <div className="flex justify-end flex-grow">
                <button
                  onClick={handleButtonClick}
                  className="p-3 bg-white border border-black rounded-3xl flex items-center text-black"
                >
                  <FontAwesomeIcon icon={faPencilAlt} color="black" />
                </button>
              </div>
            </div>
            <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-4 h-full px-4">
              <div className="divide-y divide-gray-200">
                {textConversations.map((item, index) => (
                  <Link key={index} to={`/text-conversations/${item.to}`}>
                    <div key={index} className="pb-1 pr-2 md:pr-2 cursor-pointer">
                      <ConversationItem key={index} conversation={item} />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}
