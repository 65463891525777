import { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsersLine,
  faComments,
  faVoicemail,
  faAddressBook,
  faPhone,
  faBusinessTime,
  faList,
  faCalendar,
  faMoneyBills,
  faUserAlt,
  faCircleQuestion,
  faSignOutAlt,
} from '@fortawesome/pro-light-svg-icons';

export interface MenuStatistic {
  id: string;
  icon: React.ReactNode;
  count: number;
  title: string;
  url: string;
  subTitle: string;
}
export interface MenuBoxProps {
  menuStatistics: MenuStatistic[];
}

export type TItem = {
  url?: string;
  title: string;
  icon: ReactNode;
  type?: string;
  todo?: boolean;
};

export const menuStatistics = [
  {
    id: 'appointments',
    icon: <FontAwesomeIcon icon={faUsersLine} color="black" size="lg" width={20} />,
    count: 0,
    title: 'Booked',
    url: '/bookings',
    subTitle: 'Appointments',
  },
  {
    id: 'text-conversations',
    icon: <FontAwesomeIcon icon={faComments} color="black" size="lg" width={20} />,
    count: 0,
    title: 'Messages',
    url: '/text-conversations',
    subTitle: 'Threads',
  },
  {
    id: 'voice-messages',
    icon: <FontAwesomeIcon icon={faVoicemail} color="black" size="lg" width={20} />,
    count: 0,
    title: 'Voicemail',
    url: '/voice-messages',
    subTitle: 'Messages',
  },
  {
    id: 'contacts',
    icon: <FontAwesomeIcon icon={faAddressBook} color="black" size="lg" width={20} />,
    count: 0,
    title: 'Client',
    url: '/contacts',
    subTitle: 'Contacts',
  },
];

export const clientEngagementItems: TItem[] = [
  {
    url: '/communication',
    title: 'Communication',
    icon: <FontAwesomeIcon icon={faPhone} color="black" size="lg" width={20} />,
  },
  {
    url: '/business-profile',
    title: 'Business Profile',
    icon: <FontAwesomeIcon icon={faBusinessTime} color="black" size="lg" width={20} />,
  },
];

export const bookingManagementItems: TItem[] = [
  {
    url: '/services',
    title: 'Services & Pricing',
    icon: <FontAwesomeIcon icon={faList} color="black" size="lg" width={20} />,
  },
  {
    url: '/availability',
    title: 'Calendar & Availability',
    icon: <FontAwesomeIcon icon={faCalendar} color="black" size="lg" width={20} />,
  },
  {
    url: '/payments',
    title: 'Payments',
    icon: <FontAwesomeIcon icon={faMoneyBills} color="black" size="lg" width={20} />,
  },
];

export const nestorItems: TItem[] = [
  {
    url: '/subscription',
    title: 'Subscription',
    icon: <FontAwesomeIcon icon={faUserAlt} color="black" size="lg" width={20} />,
  },
  {
    title: 'Help & Support',
    type: 'support',
    icon: <FontAwesomeIcon icon={faCircleQuestion} color="black" size="lg" width={20} />,
  },
  {
    title: 'Log Out',
    type: 'logout',
    icon: <FontAwesomeIcon icon={faSignOutAlt} color="black" size="lg" width={20} />,
  },
];
