import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';
import { getContactBooking, patchContactBooking } from '../libs/services/contacts';
import { formatYYYYMMDDDate } from '../libs/utils/date.util';
import { ContactBooking } from '@book-nestor-monorepo/shared-types';

export default function CheckoutDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { checkoutState } = useParams();
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get('session_id') || '';
  const bookingId = searchParams.get('booking_id') || '';
  const amount = searchParams.get('amount') || '';
  const contactId = searchParams.get('contact') || '';
  const [contactBooking, setContactBooking] = useState<ContactBooking | null>(null);

  useEffect(() => {
    const processPayment = async () => {
      if (bookingId && checkoutState === 'success') {
        setIsLoading(true);
        if (!authContext.user?.id) return;
        try {
          const contactBooking = await getContactBooking(
            authContext.user?.id,
            contactId,
            bookingId,
          );
          if (contactBooking && !isNaN(Number(amount))) {
            const updatedContactBooking = await patchContactBooking(
              authContext.user?.id,
              contactId,
              bookingId,
              {
                client_has_paid: true,
                booking_paid_date: formatYYYYMMDDDate(new Date()),
                booking_paid_amount: Number(amount),
                payment_session_id: checkoutSessionId,
              },
            );
            setContactBooking(updatedContactBooking);
          }
        } catch (e) {
          Sentry.captureException(e);
        }

        setIsLoading(false);
      }
    };

    processPayment();
  }, [bookingId, checkoutState]);

  return (
    <div className={`flex flex-col h-full w-full bg-[#D1DEDD] overflow-hidden`}>
      <div className="flex flex-col h-full w-full pb-2">
        {checkoutState === 'success' ? (
          <div className="flex flex-col items-left   px-4">
            <h1 className="text-black-alt text-left pb-4 font-avenir text-[20px]">
              Payment Successful!
            </h1>
            {contactId && (
              <a className="underline text-[14px] text-left" href={`/contacts/${contactId}`}>
                View contact
              </a>
            )}
            {contactBooking && (
              <a
                className="underline text-[14px] text-left"
                href={`/booking-control/${contactId}?bookingId=${bookingId}`}
              >
                View booking
              </a>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-left   px-4">
            <h1 className="text-black-alt text-left pb-4 font-avenir text-[20px]">
              Payment Failed
            </h1>
            {contactId && (
              <a className="underline text-[14px] text-left" href={`/contacts/${contactId}`}>
                View contact
              </a>
            )}
            {contactBooking && (
              <a
                className="underline text-[14px] text-left"
                href={`/booking-control/${contactId}?bookingId=${bookingId}`}
              >
                View booking
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
