import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { AppLayout } from './layouts/app-layout';
import { getContactBooking, patchContactBooking } from '../libs/services/contacts';
import { formatYYYYMMDDDate } from '../libs/utils/date.util';
import * as Sentry from '@sentry/react';

export default function CheckoutDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { checkoutState } = useParams();
  const [searchParams] = useSearchParams();
  const checkoutSessionId = searchParams.get('session_id') || '';
  const bookingId = searchParams.get('booking_id') || '';
  const amount = searchParams.get('amount') || '';
  const contactId = searchParams.get('contact') || '';

  useEffect(() => {
    const processPayment = async () => {
      if (bookingId && checkoutState === 'success') {
        setIsLoading(true);
        if (!authContext.user?.id) return;
        try {
          const contactBooking = await getContactBooking(
            authContext.user?.id,
            contactId,
            bookingId,
          );
          if (contactBooking && !isNaN(Number(amount))) {
            await patchContactBooking(authContext.user?.id, contactId, bookingId, {
              client_has_paid: true,
              booking_paid_date: formatYYYYMMDDDate(new Date()),
              booking_paid_amount: Number(amount),
              payment_session_id: checkoutSessionId,
            });
          }
        } catch (e) {
          Sentry.captureException(e);
        }

        setIsLoading(false);
      }
    };

    processPayment();
  }, [bookingId, checkoutState]);

  return (
    <AppLayout>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col items-left md:items-center text-center px-4">
          <div className="flex overflow-x-auto mb-4 hide-scrollbar">
            {checkoutState === 'success' ? (
              <h1>Payment Successful!</h1>
            ) : (
              <h1>Your order failed!</h1>
            )}
          </div>
        </div>
      )}
    </AppLayout>
  );
}
