export const cleanCalcomEmail = (email: string): string => {
  const plusIndex = email.indexOf('+');
  const atIndex = email.indexOf('@');

  if (plusIndex !== -1 && atIndex !== -1 && plusIndex < atIndex) {
    const cleanedEmail = email.slice(0, plusIndex) + email.slice(atIndex);
    return cleanedEmail;
  }

  return email;
};
