import { Contact, Review, ReviewStatus } from '@book-nestor-monorepo/shared-types';
import { faCheck, faEnvelope, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { AuthContext } from '../../../contexts/authContext';
import { environment } from '../../../environments/environment';
import { createReview } from '../../../libs/services/reviews';
import { createTextMessage } from '../../../libs/services/text.service';
import { getServicePhoneDetailsByUser } from '../../../libs/services/voice';
import { formatPhoneForTextMessage } from '../../../libs/utils/phone.util';
import PrimaryTextField from '../../formLibrary/primaryTextInput';
import { formatDate } from '../../../libs/utils/date.util';

interface FormData {
  email: string;
  phoneNumber?: string;
}

export const ContactReviewCard = ({
  contact,
  review,
  onSuccess,
}: {
  contact: Contact;
  review?: Review;
  onSuccess?: () => void;
}) => {
  const [formData, setFormData] = useState<FormData>({
    email: contact.email || '',
    phoneNumber: contact.phone_number,
  });
  const authContext = useContext(AuthContext);
  const [emailSent, setEmailSent] = useState(false);
  const [contactReview, setContactReview] = useState<Review | undefined>(review);
  const [messageSent, setMessageSent] = useState(false);

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const sendReviewRequestEmail = async (e: any) => {
    try {
      e.preventDefault();
      const review = await createReview(authContext.user?.id as string, {
        contact_email: contact?.email,
        contact_name: contact?.name,
        reviewee_name: authContext.user?.first_name,
        deliver_email: true,
        contact_id: contact.id,
      });
      setEmailSent(true);
      setContactReview(review);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const sendReviewRequestPhone = async (e: any) => {
    try {
      e.preventDefault();
      const review = await createReview(authContext.user?.id as string, {
        contact_email: contact?.email,
        contact_name: contact?.name,
        reviewee_name: authContext.user?.first_name,
        deliver_email: false,
        contact_id: contact.id,
      });
      let userServicePhoneResponse;
      try {
        userServicePhoneResponse = await getServicePhoneDetailsByUser(authContext.user?.id || '');
      } catch (error) {
        Sentry.captureException(error);
      }

      if (userServicePhoneResponse?.phone_number && contact.phone_number) {
        try {
          const message = `Leave a review for ${authContext.user?.first_name} using this link: ${environment.reactAppBaseUrl}/book/${authContext.user?.user_slug?.slug}/reviews/${review.id}`;
          await createTextMessage(
            formatPhoneForTextMessage(userServicePhoneResponse.phone_number),
            formatPhoneForTextMessage(contact.phone_number),
            message,
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      setMessageSent(true);
      setContactReview(review);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (contactReview?.status === ReviewStatus.PENDING) {
    return (
      <div className="flex flex-col w-full items-center justify-center">
        <p className="text-[14px] text-black font-inter leading-4 font-normal">
          Review requested on{' '}
          {contactReview?.created_at && formatDate(new Date(contactReview?.created_at))}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <span className="text-[10px] text-black font-inter leading-4 font-normal text-left pb-1">
        Send a request to this contact for a review of your services
      </span>
      <div className="flex gap-2 w-full">
        {contact.phone_number || contact.email ? (
          <div className="flex flex-col gap-2 w-full">
            {contact.phone_number && (
              <div className="flex gap-2 items-center w-full justify-between">
                <ReactInputMask
                  mask="(999) 999-9999"
                  maskChar="_"
                  minLength={10}
                  value={formData.phoneNumber}
                  onChange={onChangeField}
                  name="phoneNumber"
                >
                  <PrimaryTextField
                    label="Mobile"
                    type="tel"
                    variant="filled"
                    value={formData.phoneNumber}
                    onChange={onChangeField}
                    fullWidth
                    name="phoneNumber"
                  />
                </ReactInputMask>
                <div className="flex gap-2">
                  <button
                    onClick={sendReviewRequestPhone}
                    className="rounded-full border border-[#000] flex items-center justify-center h-12 w-12 min-w-[48px] cursor-pointer"
                  >
                    {messageSent ? (
                      <FontAwesomeIcon icon={faCheck} color="#000" className="text-[16px]" />
                    ) : (
                      <FontAwesomeIcon icon={faPaperPlane} color="#000" className="text-[16px]" />
                    )}
                  </button>
                </div>
              </div>
            )}
            {contact.email && (
              <div className="flex gap-2 items-center w-full justify-between">
                <PrimaryTextField
                  label="Email"
                  color="primary"
                  onChange={onChangeField}
                  type="text"
                  variant="filled"
                  fullWidth
                  name="email"
                  value={formData.email}
                />
                <button
                  onClick={sendReviewRequestEmail}
                  className="rounded-full border border-[#000] flex items-center justify-center h-12 w-12 min-w-[48px] cursor-pointer"
                >
                  {emailSent ? (
                    <FontAwesomeIcon icon={faCheck} color="#000" className="text-[16px]" />
                  ) : (
                    <FontAwesomeIcon icon={faEnvelope} color="#000" className="text-[16px]" />
                  )}
                </button>
              </div>
            )}
          </div>
        ) : (
          <p className="text-black pb-2">No phone number or email available</p>
        )}
      </div>
    </div>
  );
};
