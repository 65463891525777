import { Booking } from '@book-nestor-monorepo/shared-types';
import { formatIsoDateString } from '../../../libs/utils/date.util';

export const BookingPaymentCard = ({ booking }: { booking: Booking }) => {
  if (!booking) {
    return null;
  }

  if (booking.contact_booking?.client_has_paid) {
    return (
      <div className="flex flex-col justify-between pt-1.5 px-2 pb-4 items-start mb-2">
        <p className="text-[22px] text-black font-avenir leading-4 font-normal">Booking Paid</p>
        {booking && (
          <div className="flex flex-col  w-full items-start pt-4">
            <p className="text-xs text-black font-avenir">
              Payment for Appointment on: {formatIsoDateString(booking.start_time)}
            </p>
            {booking?.contact_booking?.booking_paid_date && (
              <p className="text-xs text-black font-avenir">
                Booking Paid on: {booking?.contact_booking?.booking_paid_date}
              </p>
            )}
            {booking?.contact_booking?.booking_paid_amount && (
              <p className="text-xs text-black font-avenir">
                Payment Amount: $
                {!isNaN(booking?.contact_booking?.booking_paid_amount) &&
                  Number(booking?.contact_booking?.booking_paid_amount / 100)}
              </p>
            )}
            {booking?.contact_booking?.payment_session_id ? (
              <p className="text-xs text-black font-avenir line-clamp-1">Payment Method: Stripe</p>
            ) : (
              <p className="text-xs text-black font-avenir  ">Payment Method: Manual</p>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <p className="text-[14px] text-black font-inter leading-4 font-normal">
        Booking has not yet been paid
      </p>
    </div>
  );
};
