import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarDaySlot from '../../../pages/v2/calendar-day-slot';
import { BookingData } from './booking-container';
import { faCheck, faChevronLeft, faChevronRight, faUser } from '@fortawesome/pro-solid-svg-icons';
import { BookingSummaryCard } from './booking-summary-card';

export const SelectDate = ({
  selectedDate,
  bookingData,
  onSelect,
  onNext,
  onBack,
  fetchAvailableSlots,
}: {
  selectedDate: any;
  bookingData: BookingData;
  onSelect: (date: any) => void;
  onNext: () => void;
  onBack: () => void;
  fetchAvailableSlots: (startDate: string, endDate: string) => Promise<any>;
}) => {
  return (
    <div className="mt-8 space-y-6 font-avenir">
      {/* Header Section */}
      <div className="border-b pb-4">
        {/* Booking Summary Card */}
        <BookingSummaryCard bookingData={bookingData} />
      </div>

      {/* Calendar Section */}
      <div className="bg-white rounded-lg">
        <CalendarDaySlot
          selectedDate={selectedDate}
          onDateSelect={onSelect}
          fetchAvailableSlots={fetchAvailableSlots}
        />
      </div>

      {/* Navigation Buttons */}
      <div className="flex items-center justify-between pt-6">
        <button
          onClick={onBack}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2 h-4 w-4" />
          Back
        </button>
        <button
          disabled={!selectedDate}
          onClick={onNext}
          className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-700 ${
            !selectedDate ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4 w-4" />
        </button>
      </div>
    </div>
  );
};
