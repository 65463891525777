import { Contact } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { ContactDetailCard } from '../../components/v2/Contact/ContactDetailCard';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { getContacts } from '../../libs/services/contacts';
import { ContactsTable } from './contacts-table';
import { ContactDetailDrawer } from './right-drawer-components/contact-detail-drawer';

export const ContactsV3 = () => {
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const [isLoading, setIsLoading] = useState(false);
  const [unsortedContacts, setUnsortedContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [searchString, setSearchString] = useState('');
  const [activeTab, setActiveTab] = useState<{
    regular: boolean;
    favorite: boolean;
    difficult: boolean;
  }>({
    regular: false,
    favorite: false,
    difficult: false,
  });
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const { user } = useContext(AuthContext);

  const handleSearchChange = (value: string) => {
    setSearchString(value);
  };

  const openContactModal = () => {
    openMuiModal(<ContactDetailCard onSuccess={onCloseContactModal} />);
  };

  const onCloseContactModal = async (contactId?: string) => {
    closeMuiModal();
    onRefresh();
  };

  const handleButtonClick = (buttonType: 'regular' | 'favorite' | 'difficult') => {
    setActiveTab((prevState) => ({
      ...prevState,
      [buttonType]: !prevState[buttonType],
    }));
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchString.length >= 2) {
        setIsLoading(true);
        try {
          if (user?.id) {
            const contacts = await getContacts(user.id, {
              searchTerm: searchString,
              // email: searchString,
              // phoneNumber: searchString,
            });
            setUnsortedContacts(contacts);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
        setIsLoading(false);
      } else if (searchString.length === 0) {
        setUnsortedContacts(contacts); // from the store
      }
    };

    fetchSearchResults();
  }, [user, contacts, searchString]);

  const filterContacts = async () => {
    let filtered = unsortedContacts;

    if (activeTab.regular) {
      filtered = filtered.filter((contact) => contact.is_regular);
    }
    if (activeTab.favorite) {
      filtered = filtered.filter((contact) => contact.is_favorite);
    }
    if (activeTab.difficult) {
      filtered = filtered.filter((contact) => contact.is_difficult);
    }

    setFilteredContacts(filtered);
  };

  useEffect(() => {
    filterContacts();
  }, [unsortedContacts, activeTab]);

  const onRefresh = async () => {
    await fetchContacts(true);
  };

  const handleToggleContactDrawerClick = () => {
    if (isOpen) {
      toggleDrawer();
    } else {
      toggleDrawer();
      toggleDrawer();
    }
  };
  const handleContactClick = (conversationId: string) => {
    if (isOpen) {
      setDrawerContent(<ContactDetailDrawer contactId={conversationId} onRefresh={onRefresh} />, {
        darkMode: true,
      });
    } else {
      setDrawerContent(<ContactDetailDrawer contactId={conversationId} onRefresh={onRefresh} />, {
        darkMode: true,
      });
      toggleDrawer();
    }
  };

  return (
    <div className={`flex flex-col h-auto `}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        <ContactsTable
          contacts={filteredContacts}
          handleSearchChange={handleSearchChange}
          handleButtonClick={handleButtonClick}
          handleContactClick={handleContactClick}
          searchString={searchString}
          addNewContact={openContactModal}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};
