import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../authContext';
import { getBookings } from '../../libs/services/bookings';
import { Booking, BookingStatus, Organization } from '@book-nestor-monorepo/shared-types';
import { getOrganization } from '../../libs/services/organization.service';

interface OrganizationContextType {
  isLoading: boolean;
  organization: Organization | undefined;
  fetchOrganization: (forceRefresh?: boolean) => Promise<Organization | undefined>;
}

interface OrganizationProviderProps {
  children: ReactNode;
}

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);

export const useOrganization = (): OrganizationContextType => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error('useOrganization must be used within a OrganizationProvider');
  }

  return context;
};

export const OrganizationProvider: FunctionComponent<OrganizationProviderProps> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization | undefined>(undefined);

  const organizationFetched = useRef<boolean>(false);

  const handleFetchOrganization = useCallback(
    async (forceRefresh = false): Promise<Organization | undefined> => {
      if (organizationFetched.current && !forceRefresh) {
        return organization;
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return undefined;
        const result = await getOrganization(user.id as string);
        setOrganization(result);
        organizationFetched.current = true;
        return result;
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setIsLoading(false);
      }
      return undefined;
    },
    [organization, authContext.user],
  );

  useEffect(() => {
    handleFetchOrganization();
  }, [handleFetchOrganization]);

  return (
    <OrganizationContext.Provider
      value={{
        isLoading,
        organization,
        fetchOrganization: handleFetchOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
