import { Contact, EventType } from '@book-nestor-monorepo/shared-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScaledBooker } from '../../components/v2/Booker/ScaledBooker';
import { AuthContext } from '../../contexts/authContext';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useServices } from '../../contexts/v2/eventTypesContext';
import { useToast } from '../../contexts/v2/toastContext';

export const BookingRescheduleMobilePage = () => {
  const [bookerKey, setBookerKey] = useState(0);
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { bookingId } = useParams();

  const { contacts, isLoading: isLoadingContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [selectedService, setSelectedService] = useState<EventType | null>();

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
  }, [services, contacts]);

  useEffect(() => {
    setBookerKey((prevKey) => prevKey + 1);
  }, [selectedContact, selectedService]);

  const handleBookingSuccess = async (data: any) => {
    showToast('Booking created successfully!', 'success');

    navigate('/');
  };

  return (
    <div className="flex flex-col">
      <ScaledBooker
        key={bookerKey}
        scale={1}
        rescheduleUid={bookingId}
        eventTypeSlug={selectedService?.slug as string}
        username={user?.connection_data?.cal_provider_user_name as string}
        contact={selectedContact as Contact}
        onSuccess={handleBookingSuccess}
      />
    </div>
  );
};
