import React, {
  FunctionComponent,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { AuthContext } from './authContext';
import {
  ClientToServerEvents,
  ServerToClientEvents,
  Notification,
} from '@book-nestor-monorepo/shared-types';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/react';

interface WebSocketContextType {
  isConnected: boolean;
  messages: any[];
}

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<WebSocketContextType>({
  isConnected: false,
  messages: [],
});

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }

  return context;
};

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(environment.apiDomainUrl, {
  autoConnect: false,
  transports: ['websocket'],
});

export const WebSocketProvider: FunctionComponent<WebSocketProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [messages, setMessages] = useState<Notification[]>([]);

  useEffect(() => {
    const user = authContext.user;
    if (!user) return;

    if (socket.connected) return;

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('notification', (event: any) => {
      setMessages((prevMessages) => {
        const updatedMessages = [event, ...prevMessages];
        return updatedMessages;
      });
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket  connect_error', error);
      Sentry.captureException(error, {
        tags: {
          type: 'websocket',
          transport: 'websocket',
        },
      });

      // try to reconnect ?
      socket.io.opts.transports = ['polling', 'websocket'];
      socket.connect();
    });

    socket.io.opts.query = { userId: user.id };
    socket.connect();

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('notification');
      socket.close();
    };
  }, [authContext.user]);

  return (
    <WebSocketContext.Provider value={{ isConnected, messages }}>
      {children}
    </WebSocketContext.Provider>
  );
};
