import { ThemeProvider } from '@emotion/react';
import { Box, createTheme, Tabs, Tab } from '@mui/material';
import React from 'react';
import { AgentMenuDrawerMessagesTabPanel } from './AgentMenuDrawerTabPanels/AgentMenuDrawerMessagesTabPanel';
import { AgentMenuDrawerDashboardTabPanel } from './AgentMenuDrawerTabPanels/AgentMenuDrawerDashboardTabPanel';

export function agentMenuDrawerTabProps(index: number) {
  return {
    id: `agent-menu-drawer-messages-tab-${index}`,
    'aria-controls': `agent-menu-drawer-messages-tab-panel-${index}`,
  };
}

const agentDrawerTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff', // white
    },
    secondary: {
      main: '#000000', // black
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          textTransform: 'none',
          fontSize: 16,
          fontFamily: 'Avenir',
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
  },
});

export const AgentMenuDrawerTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={agentDrawerTheme}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
          >
            <Tab label="Dashboard" {...agentMenuDrawerTabProps(0)} />
            <Tab label="Ni Conversations" {...agentMenuDrawerTabProps(1)} />
          </Tabs>
        </Box>
        <AgentMenuDrawerDashboardTabPanel value={value} index={0} />
        <AgentMenuDrawerMessagesTabPanel value={value} index={1} />
      </Box>
    </ThemeProvider>
  );
};
