import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { BookingConversationItem } from '@book-nestor-monorepo/shared-types';
import { useAppExperience } from '../../../../../contexts/v2/appExperienceContext';
import { AgentMessageView } from '../AgentMenuDrawerMessagesTabPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useAgent } from '../../../../../contexts/v2/agentContext';
import { MultiPartSkeleton } from '../../../Skeleton/MultiPartSkeleton';
import {
  formatClientTimeFromDate,
  getAbbreviatedMonthAndDay,
} from '../../../../../libs/utils/date.util';

export const AgentMessageConversationDetailView: React.FC<{
  agentMessage?: BookingConversationItem | null;
  onNavigate: (view: AgentMessageView) => void;
  onBackNavigate: (view: AgentMessageView) => void;
}> = ({ agentMessage, onNavigate, onBackNavigate }) => {
  const { fetchConversation, isLoading } = useAgent();
  const [conversation, setConversation] = useState<BookingConversationItem | null>(null);
  const { setMobileAppMenuBackArrowFunction } = useAppExperience();

  const getContactReference = useCallback(
    (agentMessage: BookingConversationItem | null): string => {
      if (!agentMessage?.contact) return 'Unknown Contact';
      if (agentMessage?.contact?.name && agentMessage?.contact?.last_name)
        return `${agentMessage.contact.name} ${agentMessage.contact.last_name}`;
      if (agentMessage?.contact?.name) return agentMessage.contact.name;
      return 'Unknown Contact';
    },
    [],
  );

  const contactReference = useMemo(
    () => getContactReference(conversation),
    [conversation, getContactReference],
  );

  useEffect(() => {
    setMobileAppMenuBackArrowFunction(() => onBackNavigate);
  }, [onBackNavigate, setMobileAppMenuBackArrowFunction]);

  useEffect(() => {
    const fetchData = async () => {
      if (agentMessage?.id) {
        const fetchedConversation = await fetchConversation(agentMessage.id);
        setConversation(fetchedConversation);
      }
    };
    fetchData();
  }, [fetchConversation, agentMessage?.id]);

  if (isLoading) {
    return <MultiPartSkeleton />;
  }

  const { month, day } = getAbbreviatedMonthAndDay(new Date(conversation?.created_at || ''));

  return (
    <>
      <div className="flex flex-col h-full w-full ml-4 pb-[60px]">
        <div className="flex-row w-full items-start justify-start">
          <div className="flex">
            <span
              onClick={() => onNavigate(AgentMessageView.LIST)}
              className="text-white text-[16px] font-avenir font-normal cursor-pointer"
            >
              {'Ni Conversations >'}
            </span>
            <span
              onClick={() => onNavigate(AgentMessageView.DETAIL)}
              className="text-white text-[16px] font-avenir ml-2 cursor-pointer"
            >
              {contactReference}
            </span>
            <span className="text-white text-[16px] font-avenir font-bold ml-2">{`> ${month} ${day}`}</span>
          </div>
        </div>
      </div>
      <div className="bg-[#2c2c2c] px-[17px] py-[10px] text-sm rounded-2xl mb-4 overflow-y-scroll hide-scrollbar">
        <div className="flex flex-col space-y-4 ">
          {conversation?.messages.map((message: any, index) => (
            <div key={index} className="flex items-start space-x-4 relative">
              <div className="w-[48px] h-[48px] rounded-full bg-[#E5E8E8] flex items-center justify-center flex-shrink-0 relative z-10">
                {message.role === 'assistant' && message.name === 'recovery-message' ? (
                  <FontAwesomeIcon icon={faReply} color="#43CA51" />
                ) : message.role === 'assistant' ? (
                  <img src="/assets/nestor-ai.svg" height={30} width={30} alt="Connector black" />
                ) : (
                  <FontAwesomeIcon icon={faUser} color="#B7BABA" />
                )}
              </div>
              {index < conversation.messages.length - 1 && (
                <div className="absolute left-2 top-[60px] bottom-0 w-[1px] bg-white  z-0" />
              )}
              <div className="flex flex-col items-start justify-start mt-4">
                <div className="text-[#B7BABA] font-avenir text-[14px] font-bold">
                  {message.role === 'user' && contactReference}
                </div>
                <div className="text-[#B7BABA] font-avenir text-[14px] ">
                  {formatClientTimeFromDate(
                    new Date(new Date(conversation.created_at).getTime() + index * 40000),
                  )}
                </div>

                <div
                  className={`${message.role === 'assistant' ? 'bg-[#202020]' : 'bg-[#E5E8E8]'} ${message.name === 'recovery-message' ? 'border border-[#43CA51]' : ''} rounded-2xl p-3 mt-3 max-w-xs`}
                >
                  <p
                    className={`${message.role === 'assistant' ? 'text-white' : 'text-black'} text-left text-[14px] font-avenir leading-6`}
                  >
                    {message.content as string}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {conversation?.recovery_conversation_messages && (
        <div className="bg-[#2c2c2c] px-[17px] py-[10px] text-sm rounded-2xl mb-4 overflow-y-scroll hide-scrollbar">
          <span className="text-white font-avenir text-[14px] font-bold">
            Recovery Message Thread
          </span>
          <div className="flex flex-col space-y-4 ">
            {conversation?.recovery_conversation_messages?.map((message: any, index) => (
              <div key={index} className="flex items-start space-x-4 relative">
                <div className="w-[48px] h-[48px] rounded-full bg-[#E5E8E8] flex items-center justify-center flex-shrink-0 relative z-10">
                  {message.role === 'assistant' && message.name === 'recovery-message' ? (
                    <FontAwesomeIcon icon={faReply} color="#43CA51" />
                  ) : message.role === 'assistant' ? (
                    <img src="/assets/nestor-ai.svg" height={30} width={30} alt="Connector black" />
                  ) : (
                    <FontAwesomeIcon icon={faUser} color="#B7BABA" />
                  )}
                </div>
                {index < conversation.messages.length - 1 && (
                  <div className="absolute left-2 top-[60px] bottom-0 w-[1px] bg-white  z-0" />
                )}
                <div className="flex flex-col items-start justify-start mt-4">
                  <div className="text-[#B7BABA] font-avenir text-[14px] font-bold">
                    {message.role === 'user' && contactReference}
                  </div>
                  <div className="text-[#B7BABA] font-avenir text-[14px] ">
                    {formatClientTimeFromDate(
                      new Date(new Date(conversation.created_at).getTime() + index + 10 * 40000),
                    )}
                  </div>

                  <div
                    className={`${message.role === 'assistant' ? 'bg-[#202020]' : 'bg-[#E5E8E8]'} ${message.name === 'recovery-message' ? 'border border-[#43CA51]' : ''} rounded-2xl p-3 mt-3 max-w-xs`}
                  >
                    <p
                      className={`${message.role === 'assistant' ? 'text-white' : 'text-black'} text-left text-[14px] font-avenir leading-6`}
                    >
                      {message.content as string}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
