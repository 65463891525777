import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button, tooltipClasses, styled, TooltipProps } from '@mui/material';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

export default function Card({
  name,
  title,
  rawValue,
  openInfoModal,
  size,
  color,
  textColor,
  helpColor,
  styles,
}: {
  name: string;
  title: string;
  rawValue: string;
  size: string;
  color: string;
  textColor: string;
  helpColor?: string;
  styles?: string;
  openInfoModal?: () => void;
}) {
  const textBg = 'text-white';
  const sizeClass =
    size === 'lg'
      ? 'min-[220px]:w-[56.1%] min-[390px]:w-[56.7%] md:w-full h-[148px] '
      : size === 'lg-sm'
        ? 'min-[220px]:w-[56.1%] min-[390px]:w-[56.7%] md:max-w-full md:w-full h-[148px] ]'
        : 'w-[40.8%] md:max-w-full md:w-full h-[148px] ';

  const bgColor = color;

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#222222',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#222222',
      fontSize: '10px',
      fontFamily: 'Inter',
    },
  }));

  return (
    <div className={`${styles} ${bgColor} ${sizeClass} rounded-[20px] p-4 relative`}>
      <div className="right-3 top-2 absolute">
        <LightTooltip
          title="Month to date"
          enterTouchDelay={0}
          leaveTouchDelay={1500}
          disableFocusListener
          disableTouchListener
          disableHoverListener={false}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            sx={{
              minWidth: 'auto',
              padding: 0,
              marginLeft: '4px',
              verticalAlign: 'text-top',
            }}
          >
            <FontAwesomeIcon icon={faCircleInfo} color="#ffffff" size="sm" />
          </Button>
        </LightTooltip>
      </div>
      <div className="absolute bottom-0 left-0 m-4">
        <div className="flex flex-col flex-start">
          <p className={`flex self-start text-4 font-avenir  leading-4 pb-1 ${textBg}`}>{title}</p>
          <p
            className={`flex self-start leading-4 text-[12px] font-avenir font-semibold pt-1 ${textBg}`}
          >
            {name}
          </p>
        </div>
      </div>
    </div>
  );
}
