import { ContactNote } from '@book-nestor-monorepo/shared-types';
import { formatDate } from '../../libs/utils/date.util';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { deleteContactNote } from '../../libs/services/contacts';
import { ConfirmDeleteButton } from '../formLibrary/confirmDeleteButton';
import { truncateText } from '../../libs/utils/string.util';
import { useModal } from '../../contexts/modalContext';
import SwipeUpModal from '../common/SwipeModal/SwipeModal';
import NoteModal from './note-detail';

interface NoteTypes {
  title: string;
  onClose: () => void;
  contactNotes: ContactNote[];
  contactId: string;
}

const NotesModal = ({ title, onClose, contactNotes, contactId }: NoteTypes) => {
  const { openModal, closeModal, modalContent } = useModal();
  const authContext = useContext(AuthContext);
  const deleteNote = async (noteId: string) => {
    await deleteContactNote(authContext?.user?.id as string, contactId, noteId);
    onClose();
  };
  const onOpenNote = (note: ContactNote) => {
    openModal(
      <SwipeUpModal
        variant={true}
        useContainer={true}
        bgColor="bg-gray-modals"
        onClose={() => closeModal()}
      >
        <NoteModal
          onClose={async () => {
            closeModal();
          }}
          note={note}
        />
      </SwipeUpModal>,
    );
  };
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between items-center mb-12">
        {title && (
          <p className="font-inter font-medium text-base leading-4 text-black-text ">{title}</p>
        )}
        <span
          className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-[#090909]/10 rounded-full"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      {contactNotes.map((note) => (
        <div
          key={note.id}
          className="flex flex-row justify-between pt-1.5 px-2 pb-9 border-b border-black-solid mb-2"
        >
          <div
            className="flex flex-col max-w-[255px] cursor-pointer"
            onClick={() => onOpenNote(note)}
          >
            <p className="font-avenir leading-4 text-base font-bold mb-2.5">
              {note.created_at ? formatDate(new Date(note.created_at)) : ''}
            </p>
            <p className="font-avenir leading-4 font-normal text-[9.89px] mb-2">{note.title}</p>
            <p className="font-avenir leading-4 font-normal text-[9.89px]">
              {truncateText(note.body, 100)}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <ConfirmDeleteButton
              onClick={() => {
                deleteNote(note.id as string);
              }}
            />
          </div>
        </div>
      ))}
      {!contactNotes.length && (
        <div className="flex flex-col items-center justify-center h-[100px]">
          <p className="font-avenir text-base font-medium text-black-text">No notes yet</p>
        </div>
      )}
    </div>
  );
};

export default NotesModal;
