import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { UserServicePhone, User, UserSlug } from '@book-nestor-monorepo/shared-types';
import { getServicePhoneDetailsByUser } from '../../libs/services/voice';
import { environment } from '../../environments/environment';
import { getUserSlug } from '../../libs/services/user-slug';
import LoadingDots from '../loading';
import { formatPhoneNumber } from '../../libs/utils/phone.util';

export default function QuickReport() {
  const [user, setUser] = useState<User | null>(null);
  const [userSlug, setUserSlug] = useState<UserSlug | undefined>(undefined);
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone | undefined>(undefined);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      if (!authContext.user?.id) return;
      setUser(authContext.user);
      const userServicePhone = await getServicePhoneDetailsByUser(authContext.user.id || '');
      const userSlug = await getUserSlug(authContext.user.id);
      setUserSlug(userSlug);

      setUserServicePhone(userServicePhone);
      setIsLoading(false);
    };

    fetchUserDetails();
  }, [authContext.user]);

  if (isLoading) {
    return (
      <div className="px-4 py-4 flex flex-row">
        <LoadingDots
          skeletonProps={{
            count: 2,
            height: 140,
            borderRadius: 10,
            baseColor: '#E1E0E1',
            style: { marginBottom: '10px' },
          }}
        />
      </div>
    );
  }

  return (
    <div className="px-4 py-8 flex flex-row">
      <div className="flex-col px-4 flex-1 justify-between">
        <div className="flex-1">
          <div>
            <span className="text-4xl font-bold">Welcome back. Here's your quick report.</span>
          </div>
        </div>
      </div>
      <div className="flex-col  px-4 flex-1 justify-between">
        <div className="flex-1">
          <div>
            <span className="text-lg font-bold">Quick Links</span>

            <div className="pt-4">
              <a
                href={`${environment.baseBookingUrl}/${userSlug?.slug}`}
                className="cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-sm leading-4 pr-1">Your booking page</span>
                <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} />
              </a>
              <br />
              <span className="text-sm">
                Your Service Phone:{' '}
                {userServicePhone ? (
                  <Link to={'/text-conversations'}>
                    <span className="font-bold pr-1">
                      {formatPhoneNumber(userServicePhone?.phone_number)}
                      <FontAwesomeIcon size="sm" icon={faArrowUpRightFromSquare} />
                    </span>
                  </Link>
                ) : (
                  <Link to={'/communication'}>
                    <span className="font-bold text-red-500">Setup your service phone</span>
                  </Link>
                )}
              </span>{' '}
              <br />
              <Link to={'/payments'}>
                <span className="text-sm">Payment gateway</span>
              </Link>
              <br />
              <Link to={'/contacts'}>
                <span className="text-sm">Your clients</span>
              </Link>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
