import { faArrowRightLong, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';

interface IProps {
  nextStep: (e?: any) => void;
  onSkipStep: (e?: any) => void;
  skip?: boolean;
  skipLight?: boolean;
}

const NextButtons: FC<IProps> = ({ nextStep, skip, onSkipStep, skipLight }) => {
  const [skipConfirmation, setSkipConfirmation] = useState(false);

  const skipStep = () => {
    if (!skipConfirmation) {
      setSkipConfirmation(true);
    } else {
      setSkipConfirmation(false);
      onSkipStep();
    }
  };

  const handleNext = () => {
    setSkipConfirmation(false);
    nextStep();
  };

  return (
    <div className="absolute right-2 bottom-3 flex items-center gap-[6px]">
      {skip && (
        <button
          onClick={skipStep}
          className={`w-[60px] h-[60px] transition-all duration-200 flex items-center justify-center rounded-full border border-[#353535] ${skipLight ? 'bg-[#353535]' : ''} ${skipConfirmation ? 'px-[30px] !w-auto gap-5' : ''}`}
        >
          {skipConfirmation && <p className="text-red-notification">Sure you want to skip</p>}
          <FontAwesomeIcon
            className="w-4 h-8 md:!text-[#1A1A1A]"
            icon={faArrowRightLong}
            color={skipConfirmation ? '#DB1B24' : skipLight ? '#000000' : '#fff'}
          />
        </button>
      )}
      <button
        onClick={handleNext}
        className={`w-[60px] h-[60px] flex items-center justify-center rounded-full ${skipLight ? 'bg-black' : 'bg-[#E1E0E1]'}`}
      >
        <FontAwesomeIcon
          className="w-[14px] h-8"
          icon={faCheck}
          color={skipLight ? '#FFFFFF' : '#1A1A1A'}
        />
      </button>
    </div>
  );
};

export default NextButtons;
