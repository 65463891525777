import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { environment } from '../../environments/environment';

import { AgentVoiceMetadata } from '@book-nestor-monorepo/shared-types';

export const getAgents = async (): Promise<AgentVoiceMetadata[]> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/agent/voice-agents`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.items;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch agent voice collection');
  }
};
