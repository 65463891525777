import { AuthContext } from '../contexts/authContext';

import { useContext } from 'react';
import { AuthStatus } from '../contexts/authContext';
import { hasAccess } from '../libs/utils/hasAccess';
import { SubscriptionV2 } from '../pages/v2/subscription';

interface AuthGuardProps {
  children: React.ReactNode;
  permission: string;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children, permission }) => {
  const { token, authStatus } = useContext(AuthContext);

  if (authStatus === AuthStatus.Loading) {
    return <></>;
  }

  if (!token || !hasAccess(token, permission)) {
    return <SubscriptionV2 />;
  }

  return <>{children}</>;
};

export default AuthGuard;
