import { motion, useCycle } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { SearchResourceType, SearchResponse } from '@book-nestor-monorepo/shared-types';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Switch from '../components/common/Switch/Switch';
import { searchResources } from '../libs/services/search';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

const sidebar = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      stiffness: 100,
      damping: 10,
    },
  },
  closed: {
    x: '120%',
    opacity: 0,
    transition: {
      stiffness: 100,
      damping: 10,
    },
  },
};

export default function SearchPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResponse>();
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (search.length >= 3) {
        setIsLoading(true);
        const res = await searchResources(search);
        setSearchResults(res);
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [search]);

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;

    setSearch(value);
  };

  return (
    <ExperimentalAppLayout
      className="!bg-[#E5E6E1]"
      insightCardsStyles="hidden md:flex"
      componentName="Search"
    >
      <div className="flex flex-col h-full pt-4 px-1.5 md:pt-2 w-full overflow-scroll no-scrollbar">
        <div className="flex items-center justify-start mb-12 md:mt-[120px]">
          <div className="relative flex items-center flex-grow mr-3">
            <svg
              className="absolute left-6"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8125 11.5391C12.0469 11.7734 12.0469 12.125 11.8125 12.3359C11.7188 12.4531 11.5781 12.5 11.4375 12.5C11.2734 12.5 11.1328 12.4531 11.0156 12.3359L7.875 9.19531C7.03125 9.875 5.97656 10.25 4.85156 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.15625 0.5 4.85156 0.5C7.52344 0.5 9.72656 2.70312 9.72656 5.375C9.72656 6.52344 9.35156 7.57812 8.67188 8.39844L11.8125 11.5391ZM1.125 5.375C1.125 7.46094 2.78906 9.125 4.875 9.125C6.9375 9.125 8.625 7.46094 8.625 5.375C8.625 3.3125 6.9375 1.625 4.875 1.625C2.78906 1.625 1.125 3.3125 1.125 5.375Z"
                fill="black"
              />
            </svg>
            <input
              className="text-black-solid text-base font-avenir font-normal h-12 py-4 pl-12 pr-[18px] rounded-[34px] w-full"
              type="text"
              placeholder="Search for a client, message, or note..."
              onChange={onChangeField}
              name="title"
              value={search}
            />
          </div>
          <button
            className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-white rounded-full"
            onClick={() => toggleOpen()}
          >
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.08203 1.25391C1.27344 0.871094 1.65625 0.625 2.09375 0.625H13.9062C14.3164 0.625 14.6992 0.871094 14.8906 1.25391C15.0547 1.63672 15 2.10156 14.7266 2.42969L9.75 8.52734V12C9.75 12.3555 9.55859 12.6562 9.25781 12.793C8.95703 12.9297 8.60156 12.9023 8.32812 12.7109L6.57812 11.3984C6.35938 11.2344 6.25 10.9883 6.25 10.6875V8.52734L1.24609 2.42969C0.972656 2.10156 0.917969 1.63672 1.08203 1.25391Z"
                fill="black"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              className="bg-white opacity-75 h-full w-full fixed left-0 top-0 z-30"
              onClick={() => toggleOpen()}
            />
          )}

          <motion.div
            className="absolute bg-white rounded-2xl py-[24px] px-5 right-2 z-40 w-[203px] top-[160px] md:top-[190px] shadow-md"
            variants={sidebar}
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            ref={containerRef}
          >
            <ul className="flex flex-col gap-3.5">
              <li className="flex flex-row justify-between items-center">
                All Results <Switch />
              </li>
              <li className="flex flex-row justify-between items-center">
                Clients <Switch />
              </li>
              {/* <li className="flex flex-row justify-between items-center">
                Recents <Switch />
              </li> */}
              {/* <li className="flex flex-row justify-between items-center">
                Campaigns <Switch />
              </li> */}
              {/* <li className="flex flex-row justify-between items-center">
                Reports <Switch />
              </li> */}
              <li className="flex flex-row justify-between items-center">
                Messages <Switch />
              </li>
              {/* <li className="flex flex-row justify-between items-center">
                Note <Switch />
              </li> */}
              {/* <li className="flex flex-row justify-between items-center">
                Files / Notes <Switch />
              </li> */}
            </ul>
          </motion.div>
        </div>
        <div className="flex flex-col mb-10">
          <p className="font-avenir text-base text-black-solid leading-6 font-semibold mb-2 px-4">
            Searching For
          </p>
          <ul className="flex flex-row w-full flex-wrap gap-1.5">
            <li className="cursor-pointer flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              All Results
              <span className="font-bold ml-2.5">{searchResults?.totalResults || 0}</span>
            </li>
            {searchResults?.resourceCounts.map((resourceCount) => (
              <li
                key={resourceCount.resourceType}
                className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid"
              >
                {resourceCount.resourceType === SearchResourceType.CONTACT ? 'Clients' : 'Messages'}
                <span className="font-bold ml-2.5">{resourceCount.count}</span>
              </li>
            ))}
            {/* <li className="flex rounded-[34px] items-center bg-black-solid text-white h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize">
              Recent
              <span className="font-bold ml-2.5">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.8125 1.49219L6.46094 5.5L9.8125 9.53125C10.0703 9.85938 10.0469 10.3281 9.71875 10.5859C9.39062 10.8438 8.92188 10.8203 8.66406 10.4922L5.5 6.67188L2.3125 10.4922C2.05469 10.8203 1.58594 10.8438 1.25781 10.5859C0.929688 10.3281 0.90625 9.85938 1.16406 9.53125L4.51562 5.5L1.16406 1.49219C0.90625 1.16406 0.929688 0.695312 1.25781 0.4375C1.58594 0.179688 2.05469 0.203125 2.3125 0.53125L5.5 4.32812L8.66406 0.53125C8.92188 0.203125 9.39062 0.179688 9.71875 0.4375C10.0469 0.695312 10.0703 1.16406 9.8125 1.49219Z"
                    fill="white"
                  />
                </svg>
              </span>
            </li>
            <li className="flex rounded-[34px] items-center bg-black-solid text-white h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize">
              Recent
              <span className="font-bold ml-2.5">1</span>
            </li>
            <li className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              Campaigns
              <span className="font-bold ml-2.5">0</span>
            </li>
            <li className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              Reports
              <span className="font-bold ml-2.5">18</span>
            </li> */}
            {/* <li className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              Messages
              <span className="font-bold ml-2.5">2</span>
            </li>
            <li className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              Clients
              <span className="font-bold ml-2.5">2</span>
            </li> */}
            {/* <li className="flex rounded-[34px] items-center border border-black-solid h-22 px-[18px] py-2 font-avenir text-sm leading-6 capitalize text-black-solid">
              Notes
              <span className="font-bold ml-2.5">2</span>
            </li> */}
          </ul>
        </div>
        <div className="bg-white rounded-t-3xl flex flex-col h-full">
          <div className="p-8">
            <p className="font-avenir text-base font-semibold leading-6 text-black-solid mb-2">
              Messages
            </p>
            {searchResults?.groupedResults?.text.map((text) => (
              <div className="flex flex-row items-center mb-4">
                <Link to={`/text-conversations/${text.from}`}>
                  <button className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-black rounded-full mr-[22px]">
                    {/* <svg
                    width="12"
                    height="11"
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 1V8.875C1.5 9.08594 1.66406 9.25 1.875 9.25H11.25C11.6484 9.25 12 9.60156 12 10C12 10.4219 11.6484 10.75 11.25 10.75H1.875C0.820312 10.75 0 9.92969 0 8.875V1C0 0.601562 0.328125 0.25 0.75 0.25C1.14844 0.25 1.5 0.601562 1.5 1ZM3.75 7.75V7.77344C3.32812 7.77344 3 7.42188 3 7.02344V5.05469C3 4.86719 3.04688 4.67969 3.16406 4.53906L5.03906 2.42969C5.34375 2.07812 5.88281 2.07812 6.1875 2.42969L7.10156 3.53125C7.3125 3.78906 7.6875 3.8125 7.92188 3.57812L8.41406 3.08594C8.71875 2.78125 9.23438 2.78125 9.51562 3.13281L11.0625 4.91406C11.1797 5.05469 11.25 5.24219 11.25 5.40625V7C11.25 7.42188 10.8984 7.75 10.5 7.75H3.75Z"
                      fill="#EEEEEE"
                    />
                  </svg> */}
                    <FontAwesomeIcon className="w-3 h-3" icon={faPhone} color="#fff" />
                  </button>
                </Link>
                <div className="flex flex-col">
                  <p className="font-avenir text-base text-black-solid leading-6">{text.text}</p>
                  <p className="font-avenir  text-sm text-gray-400 leading-6">{text.from}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-light-gray p-6 flex flex-col rounded-t-3xl h-full">
            <p className="px-2 font-avenir text-base text-black-solid leading-6 font-semibold mb-2.5">
              Clients
            </p>
            <ul className="grid grid-cols-2 gap-2">
              {searchResults?.groupedResults?.contact.map((contact) => (
                <li className="cursor-pointer bg-black rounded-2xl pt-2 px-2 pb-4">
                  <div className="flex flex-row-reverse">
                    <div className="flex flex-col gap-1.5">
                      <Link to={`/contacts/${contact.id}`}>
                        <button className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-[#212121]/50 rounded-full">
                          <svg
                            width="5"
                            height="9"
                            viewBox="0 0 5 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.957031 0.1875L4.53125 3.91797C4.60938 4.01562 4.66797 4.13281 4.66797 4.25C4.66797 4.36719 4.60938 4.48438 4.53125 4.5625L0.957031 8.29297C0.78125 8.48828 0.46875 8.48828 0.292969 8.3125C0.0976562 8.13672 0.0976562 7.84375 0.273438 7.64844L3.55469 4.23047L0.273438 0.832031C0.0976562 0.65625 0.0976562 0.34375 0.292969 0.167969C0.46875 -0.0078125 0.78125 -0.0078125 0.957031 0.1875Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </Link>
                      {/* <button className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-white/10 rounded-full">
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 10 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                            fill="white"
                          />
                        </svg>
                      </button> */}
                    </div>
                  </div>
                  <p className="font-avenir text-base font-normal leading-4 max-w-20 text-light-gray">
                    {contact?.name}
                  </p>
                </li>
              ))}
              {/* <li className="cursor-pointer bg-black rounded-2xl pt-2 px-2 pb-4">
                <div className="flex flex-row-reverse">
                  <div className="flex flex-col gap-1.5">
                    <button className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-[#151515]/50 rounded-full">
                      <svg
                        width="5"
                        height="9"
                        viewBox="0 0 5 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.957031 0.1875L4.53125 3.91797C4.60938 4.01562 4.66797 4.13281 4.66797 4.25C4.66797 4.36719 4.60938 4.48438 4.53125 4.5625L0.957031 8.29297C0.78125 8.48828 0.46875 8.48828 0.292969 8.3125C0.0976562 8.13672 0.0976562 7.84375 0.273438 7.64844L3.55469 4.23047L0.273438 0.832031C0.0976562 0.65625 0.0976562 0.34375 0.292969 0.167969C0.46875 -0.0078125 0.78125 -0.0078125 0.957031 0.1875Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <button className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-white/10 rounded-full">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <p className="font-avenir text-base font-bold leading-4 mid-gray max-w-20 text-mid-gray"></p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </ExperimentalAppLayout>
  );
}
