import {
  faCalendarPlus,
  faCheck,
  faCopy,
  faEnvelope,
  faPaperPlane,
  faX,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import { environment } from '../../../environments/environment';
import { sendEmail } from '../../../libs/services/email';
import { getUserSlug } from '../../../libs/services/user-slug';
import PrimaryTextField from '../../formLibrary/primaryTextInput';
import { fetchAuthSession } from 'aws-amplify/auth';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';

type NewBookingFormType = {
  side?: boolean;
  onClose: () => void;
  email?: string;
  name?: string;
  phone?: string;
};

interface FormData {
  phoneNumber: string;
  email: string;
}

export default function V2BookingForm({ side, onClose, email, phone }: NewBookingFormType) {
  const authContext = useContext(AuthContext);
  const history = useNavigate();
  const [copied, setCopied] = useState(false);
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    phoneNumber: phone || '',
    email: email || '',
  });
  const [emailSent, setEmailSent] = useState(false);
  const [userSlug, setUserSlug] = useState<string | null>(
    authContext.user?.user_slug?.slug || null,
  );

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authContext.user;
        if (!(user && user.id)) return;
        if (userSlug) return;
        const slugResponse = await getUserSlug(user.id);
        if (slugResponse) {
          setUserSlug(slugResponse.slug);
        }
        const session = await fetchAuthSession();
        if (!session) return;

        const idToken = session?.tokens?.idToken?.payload;
        setIsVoiceEnabled(hasAccess(idToken, USER_PERMISSIONS.CAN_USE_VOICE));
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchData();
  }, []);

  const copyToClipboard = () => {
    const fullUrl = `${environment.baseBookingUrl}/${userSlug}`;
    navigator.clipboard.writeText(fullUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const sendBookingLinkPhone = () => {
    const message = `Book a service with ${authContext.user?.first_name} using this link: ${environment.baseBookingUrl}/${userSlug}`;
    history(`/text-conversations/+1${formData.phoneNumber}?message=${message}`);
    onClose();
  };

  const sendBookingLinkEmail = async () => {
    await sendEmail(formData.email, `${environment.baseBookingUrl}/${userSlug}`);
    setEmailSent(true);
  };

  const bookingLink = () => {
    if (email) {
      return `${environment.baseBookingUrl}/${userSlug}?name=${email}`;
    }
    return `${environment.baseBookingUrl}/${userSlug}`;
  };

  const openBookingLink = () => {
    window.open(bookingLink(), '_blank', 'noopener noreferrer');
  };

  return (
    <div className="h-full bg-[#F1F1F1] rounded-tl-3xl rounded-tr-3xl">
      <div className="flex p-2 flex-col">
        <div className="flex flex-row justify-end items-center ">
          <div className="h-12 w-12 bg-[#E5E8E8] rounded-full flex items-center justify-center cursor-pointer">
            <div onClick={onClose}>
              <FontAwesomeIcon icon={faX} color="#000" size="xs" />
            </div>
          </div>
        </div>
        <p className="text-[16px] font-semibold font-avenir leading-6 text-black-solid mb-6 mt-2">
          Send booking link to:
        </p>
        <div className="flex w-full items-center mb-4">
          {isVoiceEnabled && (
            <>
              <div className="flex w-full items-center relative">
                <InputMask
                  mask="(999) 999-9999"
                  maskChar="_"
                  minLength={10}
                  value={formData.phoneNumber}
                  onChange={onChangeField}
                  name="phoneNumber"
                >
                  <PrimaryTextField
                    label="Mobile"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={onChangeField}
                    name="phoneNumber"
                    fullWidth={true}
                    variant="filled"
                  />
                </InputMask>
              </div>
              <button
                onClick={sendBookingLinkPhone}
                disabled={!formData.phoneNumber}
                className={`rounded-full w-14 h-14 ml-2 cursor-pointer `}
              >
                <FontAwesomeIcon icon={faPaperPlane} color="#000" />
              </button>
            </>
          )}
        </div>

        <div className="flex w-full">
          <div className="flex-grow">
            <PrimaryTextField
              label="Email"
              color="primary"
              onChange={onChangeField}
              type="text"
              name="email"
              value={formData.email}
              className="w-full"
              fullWidth={true}
              variant="filled"
            />
          </div>
          <button
            onClick={sendBookingLinkEmail}
            disabled={!formData.email}
            className={`rounded-full w-14 h-14 cursor-pointer `}
          >
            {emailSent ? (
              <FontAwesomeIcon icon={faCheck} color="#22C55E" className="ml-1" />
            ) : (
              <FontAwesomeIcon icon={faEnvelope} color="#000" className="ml-2" />
            )}
          </button>
        </div>
      </div>
      <div className="w-full h-auto pt-1 flex flex-row -px-2 flex-grow">
        <div className="w-full h-[168px] bg-[#E5E8EB] p-2 flex flex-col justify-between">
          <div className="flex flex-col w-full h-auto">
            <div className="flex flex-row items-center justify-end">
              <div
                className="flex cursor-pointer h-12 w-12 border border-black-solid rounded-full items-center justify-center "
                onClick={() => copyToClipboard()}
              >
                {copied ? (
                  <FontAwesomeIcon icon={faCheck} color="#22C55E" />
                ) : (
                  <FontAwesomeIcon icon={faCopy} color="#000" />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-start justify-start">
              <p className="text-[14px] font-avenir text-black-solid text-start pb-4 max-w-[80px]">
                Copy & Share
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={openBookingLink}
          className="w-full h-[168px] bg-[#D9D9D9] p-2 flex flex-col justify-between cursor-pointer hover:bg-[#CECECE] transition-colors cursor-pointer"
        >
          <div className="flex flex-col w-full h-auto">
            <div className="flex flex-row items-center justify-end">
              <div className="flex h-12 w-12 border border-black-solid rounded-full items-center justify-center">
                <FontAwesomeIcon icon={faCalendarPlus} color="#000" />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-start justify-start">
              <p className="text-[14px] font-avenir text-black-solid text-start pb-4 max-w-[100px]">
                Create a booking yourself
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
