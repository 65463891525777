import { faUserHeadset } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, Switch, ThemeProvider } from '@mui/material';

const agentControlSwitchTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 99,
          width: 48,
          padding: 0,
          borderRadius: 59,
          position: 'relative',
          '& .MuiSwitch-switchBase': {
            transitionDuration: '300ms',
            padding: 0,
            '&:hover': {
              backgroundColor: '#D2D2D2',
            },
            '&.Mui-checked': {
              transform: 'translateY(50px)',
              backgroundColor: '#ffffff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#D2D2D2',
              },
            },
            '& Mui-checked:hover': {
              backgroundColor: '#ffffff',
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: '#D2D2D2',
          },
          '& .MuiSwitch-thumb': {
            display: 'flex',
            alignItems: 'center',
          },
        },
        switchBase: {
          marginTop: 2,
          marginBottom: 2,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          width: 44,
          height: 44,
          marginLeft: 2,
          backgroundColor: '#111111',
        },
      },
    },
  },
});

interface AgentControlSwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AgentControlSwitch = ({ checked, onChange }: AgentControlSwitchProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onChange({ target: { checked: !checked } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div onClick={handleClick} className="relative cursor-pointer">
      <ThemeProvider theme={agentControlSwitchTheme}>
        <div className="relative">
          <Switch
            icon={<FontAwesomeIcon icon={faUserHeadset} className="text-[#E5E8E8] text-[16px]" />}
            checkedIcon={
              <FontAwesomeIcon icon={faUserHeadset} className="text-[#000000] text-[16px]" />
            }
            checked={checked}
            onChange={onChange}
          />
          <div className="absolute w-full h-full top-0 left-0 pointer-events-none">
            <div
              className={`absolute left-1/2 -translate-x-1/2 text-xs font-avenir font-medium   ${
                checked ? 'opacity-100 top-4' : 'opacity-0'
              }`}
              style={{ color: '#3FAB4A' }} // Green color for ON
            >
              ON
            </div>
            <div
              className={`absolute left-1/2 -translate-x-1/2 text-xs font-avenir font-medium  ${
                checked ? 'opacity-0' : 'opacity-100 bottom-4'
              }`}
            >
              OFF
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};
