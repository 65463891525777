import { faFrown, faMeh, faSmile, faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AgentMessageScoreContainer = ({ score, label }: { score: number; label: string }) => {
  const calculateComplexityFromScore = (score: number) => {
    if (score >= 66) {
      return 'High';
    } else if (score >= 33) {
      return 'Medium';
    } else {
      return 'Low';
    }
  };
  return (
    <div className="w-full h-[61px] bg-[#B7BABA] rounded-full flex items-center justify-between p-0">
      <div className="flex w-1/2 pl-10">
        <span className="text-black text-base font-inter">{label}</span>
      </div>

      <div className="flex items-center justify-between h-full w-1/2">
        <div className="flex w-full justify-end items-center h-full">
          <div
            className="flex justify-between items-center h-full bg-[#E0E3E3] rounded-full"
            style={{ width: `${score + 40}%` }}
          >
            <span className="text-black text-sm font-avenir ml-4 first-letter:uppercase">
              {calculateComplexityFromScore(score)}
            </span>
            <div className="w-11 h-11 bg-[#000000] opacity-20 rounded-full flex items-center justify-center mr-1">
              <span className="text-[#B7BABA] text-[16px] font-inter ">{score}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AgentMessageSentimentScoreContainer = ({
  sentiment,
  label,
}: {
  sentiment: 'positive' | 'neutral' | 'negative';
  label: string;
}) => {
  const getIconAndColor = (sentiment: string) => {
    switch (sentiment) {
      case 'positive':
        return { icon: faSmile, color: 'text-[#43CA51]' };
      case 'neutral':
        return { icon: faMeh, color: 'text-yellow-500' };
      case 'negative':
        return { icon: faFrown, color: 'text-[#DB1B24]' };
      default:
        return { icon: faMeh, color: 'text-gray-500' };
    }
  };

  const getWidth = (sentiment: string) => {
    switch (sentiment) {
      case 'positive':
        return '100%';
      case 'neutral':
        return '85%';
      case 'negative':
        return '70%';
      default:
        return '50%';
    }
  };

  const { icon, color } = getIconAndColor(sentiment);

  return (
    <div className="w-full h-[61px] bg-[#B7BABA] rounded-full flex items-center justify-between p-0">
      <div className="flex w-1/2 pl-10">
        <span className="text-black text-base font-inter">{label}</span>
      </div>

      <div className="flex items-center justify-between h-full w-1/2">
        <div className="flex w-full justify-end items-center h-full">
          <div
            className="flex justify-between items-center h-full bg-[#E0E3E3] rounded-full"
            style={{ width: `${getWidth(sentiment)}` }}
          >
            <span className="text-black text-sm font-avenir ml-4 first-letter:uppercase">
              {sentiment}
            </span>
            <div
              className={`w-11 h-11 bg-white rounded-full flex items-center justify-center mr-1 ${color}`}
            >
              <FontAwesomeIcon icon={icon} className="text-[18px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
