import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { AgentControlSwitch } from '../Agent/AgentControlSwitch';
import { AgentMenuDrawerTabs } from './AgentMenuDrawerTabs';
import { UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { getServicePhoneDetailsByUser, updateUserServicePhone } from '../../../libs/services/voice';
import { AuthContext } from '../../../contexts/authContext';

interface DesktopMenuDrawerProps {
  open: boolean;
  onToggle: () => void;
  anchor: 'left' | 'right';
  behindOverlay?: boolean;
}

export const AgentMenuDrawer: React.FC<DesktopMenuDrawerProps> = ({
  open,
  onToggle,
  anchor,
  behindOverlay = false,
}) => {
  const zIndex = behindOverlay ? 'z-0' : 'z-50';
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone | undefined>(undefined);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserServicePhone = async () => {
      if (!user) return;
      const userId = user.id;
      const userServicePhone = await getServicePhoneDetailsByUser(userId as string);
      setUserServicePhone(userServicePhone);
    };
    fetchUserServicePhone();
  }, [user]);

  const toggleVoiceAssistant = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!userServicePhone) return;

    try {
      // Create a new object with the updated value
      const updatedServicePhone = {
        ...userServicePhone,
        voice_assistant_enabled: !userServicePhone.voice_assistant_enabled,
      };

      // Update the backend
      await updateUserServicePhone(updatedServicePhone);

      // Update local state after successful API call
      setUserServicePhone(updatedServicePhone);
    } catch (error) {
      // Optionally handle error (e.g., show toast message)
      console.error('Failed to toggle voice assistant:', error);

      // You might want to revert the switch state here if the API call fails
      // by not updating the state
    }
  };

  if (!userServicePhone) return null;

  return (
    <div className="w-[58px] relative ml-2">
      {/* Overlay */}
      {open && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 z-40 transition-opacity duration-300 ease-in-out"
          onClick={onToggle}
        ></div>
      )}

      {/* Drawer */}
      <div
        id={`agent-drawer-container-desktop-${anchor}`}
        className="cursor-pointer"
        onClick={!open ? onToggle : undefined}
      >
        <div
          className={`absolute top-0 ${anchor}-0 h-full ${zIndex} transition-[width] ${
            open ? 'w-[483px]' : 'w-[58px]'
          } ${open ? 'bg-[#222222] rounded-[20px]' : 'bg-[#E5E8E8] rounded-[50px]'}`}
        >
          <div className="flex flex-col h-full">
            <div className="flex h-full">
              {open && (
                <div className="flex-1 flex flex-col h-full mt-5 ml-0 overflow-hidden">
                  <div className="flex-col px-2 items-start justify-start h-full overflow-y-auto hide-scrollbar">
                    <div className="flex ml-4 mb-4 items-start justify-start">
                      <button onClick={onToggle}>
                        <FontAwesomeIcon icon={faArrowRightLong} color="white" size="xs" />
                      </button>
                    </div>
                    <div>
                      <AgentMenuDrawerTabs />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col w-[58px] justify-between h-full my-1">
                <div className="flex justify-center" onClick={(e) => e.stopPropagation()}>
                  {userServicePhone && (
                    <AgentControlSwitch
                      checked={userServicePhone.voice_assistant_enabled}
                      onChange={toggleVoiceAssistant}
                    />
                  )}
                </div>
                <div className="flex justify-center mb-1">
                  <div className="w-12 h-12 bg-[#434242] rounded-full flex items-center justify-center mb-[5px]">
                    <img
                      src="/assets/nestor-ai.svg"
                      height={100}
                      width={100}
                      alt="Connector black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
