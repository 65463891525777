import { Schedule, WorkingHours } from '@book-nestor-monorepo/shared-types';

function formatWorkingHours(workingHours: WorkingHours[], timeZone: string) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const formattedHours: Record<string, string[]> = {};

  // Initialize formatted hours for each day
  daysOfWeek.forEach((day) => {
    formattedHours[day] = ['Closed'];
  });

  // Process each working hour entry
  workingHours.forEach((entry) => {
    const { days, startTime, endTime } = entry;

    // Convert start and end times from minutes to hours and minutes
    const startHour = Math.floor(startTime / 60);
    const startMinute = startTime % 60;
    const endHour = Math.floor(endTime / 60);
    const endMinute = endTime % 60;

    // Create date objects with the correct timezone offset
    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);
    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // Format start and end times in the user's timezone
    const formattedStartTime = startDate.toLocaleString('en-US', {
      timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const formattedEndTime = endDate.toLocaleString('en-US', {
      timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    // Update formatted hours for each day in the entry
    days.forEach((day) => {
      const formattedHour = `${formattedStartTime} - ${formattedEndTime}`;
      if (formattedHours[daysOfWeek[day]][0] === 'Closed') {
        formattedHours[daysOfWeek[day]] = [formattedHour];
      } else {
        formattedHours[daysOfWeek[day]].push(formattedHour);
      }
    });
  });

  // Generate the formatted output
  let output = '';
  daysOfWeek.forEach((day) => {
    output += `${day.padEnd(12)} ${formattedHours[day].join('\n             ')}\n`;
  });

  return output.trim();
}

function WorkingHoursComponent({
  workingHours,
  timeZone,
}: {
  workingHours: WorkingHours[];
  timeZone: string;
}) {
  const formattedWorkingHours = formatWorkingHours(workingHours, timeZone);

  return (
    <div className="flex w-full justify-between items-center overflow-x-auto">
      {/* <h6 className="font-semibold">{label}</h6>
      <p className="text-right">{value}</p> */}
      <pre className=" text-black-alt text-[18px] font-open-sans">{formattedWorkingHours}</pre>
    </div>
  );
}

const HoursTab = ({ defaultSchedule }: { defaultSchedule: Schedule }) => {
  return (
    <div className="py-[40px] px-[24px]">
      <h4 className="text-2xl text-[#211D1D]">Business Hours</h4>
      <div className="mt-[31px]">
        <WorkingHoursComponent
          workingHours={defaultSchedule.working_hours}
          timeZone={defaultSchedule.time_zone}
        />
        {/* {items.map(({ label, value }) => (
          <div className="flex w-full justify-between items-center">
            <h6 className="font-semibold">{label}</h6>
            <p className="text-right">{value}</p>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default HoursTab;
