import { User } from '@book-nestor-monorepo/shared-types';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { fetchAuthSession } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { resizeImage } from '../utils/resizeImage.util';
import { uploadFile } from './organization.service';
import mime from 'mime';

export const createUser = async (
  id: string,
  email: string,
  first_name: string,
  phone_number: string,
  picture?: string,
  auth_user_name?: string,
): Promise<User> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const postBody = {
      email,
      id,
      first_name,
      phone_number,
      picture,
      auth_user_name,
    };
    const response = await axios.post(`${apiBaseUrl}/users`, postBody, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error creating user');
  }
};

export const getUserBySlug = async (slug: string): Promise<User | undefined> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session?.tokens?.accessToken?.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/users/slug/${slug}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data?.items[0];
  } catch (error) {
    throw new Error('Failed to fetch user by slug');
  }
};

export const getUserById = async (userId: string): Promise<User | undefined> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session?.tokens?.accessToken?.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch user by id');
  }
};

export const patchUser = async (id: string, patchUser: User, logoFile?: File): Promise<User> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString() || '';

    let remoteLogoFileName: string | undefined;
    const uploadPromises: Promise<string | number | undefined>[] = [];
    if (logoFile) {
      const uploadLogoName = `${uuidv4()}.${mime.getExtension(logoFile.type)}`;
      remoteLogoFileName = `${id}/business/${uploadLogoName}`;
      const smallerImage = await resizeImage(logoFile);
      uploadPromises.push(uploadFile(remoteLogoFileName, smallerImage, cognitoToken));
      await Promise.all(uploadPromises);
      patchUser.picture = remoteLogoFileName;
    }

    const apiBaseUrl = environment.apiBaseUrl;
    const patchBody = {
      ...patchUser,
    };
    const response = await axios.patch(`${apiBaseUrl}/users/${id}`, patchBody, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to patch user');
  }
};
