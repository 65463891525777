import { UserServicePhone } from '@book-nestor-monorepo/shared-types';
import {
  faBrowser,
  faBuilding,
  faBullhorn,
  faCalendar,
  faClock,
  faCog,
  faComments,
  faCreditCard,
  faEnvelope,
  faHome,
  faMoneyBill,
  faQuestionCircle,
  faSignOut,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronsLeft, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, styled, Switch, Theme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { signOut } from 'aws-amplify/auth';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import { useOrganization } from '../../../contexts/v2/organizationContext';
import { getServicePhoneDetailsByUser, updateUserServicePhone } from '../../../libs/services/voice';
import { truncateText } from '../../../libs/utils/string.util';
import { ProfilePicture } from '../App/ProfilePicture';

export const MobileMenuDrawer = ({
  isOpen,
  toggleMenu,
}: {
  isOpen: boolean;
  toggleMenu: () => void;
}) => {
  const { user, bookingLink } = useContext(AuthContext);
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone | undefined>(undefined);

  useEffect(() => {
    const fetchUserServicePhone = async () => {
      if (!user) return;
      const userId = user.id;
      const userServicePhone = await getServicePhoneDetailsByUser(userId as string);
      setUserServicePhone(userServicePhone);
    };
    fetchUserServicePhone();
  }, [user]);

  const toggleVoiceAssistant = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!userServicePhone) return;

    try {
      // Create a new object with the updated value
      const updatedServicePhone = {
        ...userServicePhone,
        voice_assistant_enabled: !userServicePhone.voice_assistant_enabled,
      };

      // Update the backend
      await updateUserServicePhone(updatedServicePhone);

      // Update local state after successful API call
      setUserServicePhone(updatedServicePhone);
    } catch (error) {
      // Optionally handle error (e.g., show toast message)
      console.error('Failed to toggle voice assistant:', error);

      // You might want to revert the switch state here if the API call fails
      // by not updating the state
    }
  };

  const onDrawerClose = () => {
    setShowSettingsMenu(false);
    toggleMenu();
  };

  const handleClick = (path: string) => {
    if (path) navigate(path);
    toggleMenu();
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const StyledSwitch = styled(Switch)(({ theme }: { theme: Theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#ffffff',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3FAB4A',
    },
  }));

  const firstLetterOfBusinessOrDefault = () => {
    return organization?.name?.charAt(0).toUpperCase() ?? 'B';
  };

  const businessNameOrDefault = () => {
    return organization?.name ?? 'Set your business';
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'white',
        },
      }}
    >
      <div className="flex flex-col h-full w-[300px] ml-2">
        <div className={`flex justify-start items-center mt-2 gap-2 ml-2`}>
          <div className="flex items-center justify-between w-full">
            <div className="flex  gap-2 w-full">
              {/* <NavLink onClick={toggleMenu} to="/personal-settings">
                <div className="relative">
                  <ProfilePicture
                    picture={user?.picture}
                    firstName={user?.first_name}
                    height={40}
                    width={40}
                    fontSize={20}
                  />
                  <button className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-[#FFFFFFB0] border-[0.5px] border-[#D9DAD6] flex items-center justify-center">
                    <svg
                      width="9"
                      height="3"
                      viewBox="0 0 9 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.8125 1.25C6.8125 0.742188 7.22266 0.3125 7.75 0.3125C8.25781 0.3125 8.6875 0.742188 8.6875 1.25C8.6875 1.77734 8.25781 2.1875 7.75 2.1875C7.22266 2.1875 6.8125 1.77734 6.8125 1.25ZM3.6875 1.25C3.6875 0.742188 4.09766 0.3125 4.625 0.3125C5.13281 0.3125 5.5625 0.742188 5.5625 1.25C5.5625 1.77734 5.13281 2.1875 4.625 2.1875C4.09766 2.1875 3.6875 1.77734 3.6875 1.25ZM2.4375 1.25C2.4375 1.77734 2.00781 2.1875 1.5 2.1875C0.972656 2.1875 0.5625 1.77734 0.5625 1.25C0.5625 0.742188 0.972656 0.3125 1.5 0.3125C2.00781 0.3125 2.4375 0.742188 2.4375 1.25Z"
                        fill="#989898"
                      />
                    </svg>
                  </button>
                </div>
              </NavLink> */}
              <button onClick={toggleMenu} className=" flex items-center justify-center my-4 mx-3">
                <FontAwesomeIcon className="text-[16px]  font-bold" icon={faX} />
              </button>
              {/* <img src="/assets/nestor.png" alt="Nestor Logo" className="w-auto h-10" /> */}
            </div>
          </div>
        </div>
        <div className="flex pt-4 pl-4">
          <span className="text-[32px] font-avenir">{showSettingsMenu ? '' : ''}</span>
        </div>
        {showSettingsMenu ? (
          <div>
            <div className="flex pt-1 pl-4">
              <div
                className="flex items-center  justify-start"
                onClick={() => setShowSettingsMenu(false)}
              >
                <FontAwesomeIcon icon={faChevronsLeft} className="text-[12px] " color="#13ACD4" />
                <span className="text-[16px] font-avenir font-medium pl-2 text-[#13ACD4]">
                  Back to main menu
                </span>
              </div>
            </div>
            <div className="flex pt-8 pl-4">
              <span className="text-[12px] font-avenir font-medium text-gray-400">
                Client Engagement
              </span>
            </div>
            <div className="flex flex-col gap-1 pl-2">
              <div
                className="flex items-center gap-2"
                onClick={() => handleClick('/communication')}
              >
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Communication</span>
              </div>
              <div
                className="flex items-center gap-2"
                onClick={() => handleClick('/business-profile')}
              >
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faBuilding} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Business Profile</span>
              </div>
            </div>
            <div className="flex pt-12 pl-4">
              <span className="text-[12px] font-avenir font-medium text-gray-400">
                Booking Management
              </span>
            </div>
            <div className="flex flex-col gap-2 pl-2">
              <div className="flex items-center gap-2" onClick={() => handleClick('/services')}>
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Services & Pricing</span>
              </div>
              <div
                className="flex items-center gap-2"
                onClick={() => handleClick('/linked-calendars')}
              >
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faCalendar} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Linked Calendars</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => handleClick('/availability')}>
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faClock} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Availability</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => handleClick('/payments')}>
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faMoneyBill} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Payments</span>
              </div>
            </div>
            <div className="flex pt-12 pl-4">
              <span className="text-[12px] font-avenir font-medium text-gray-400">Nestor</span>
            </div>
            <div className="flex flex-col gap-2 pl-2">
              <div className="flex items-center gap-2" onClick={() => handleClick('/subscription')}>
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faCreditCard} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Subscription</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => window.Intercom?.('show')}>
                <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                  <FontAwesomeIcon icon={faQuestionCircle} className="text-[20px]" />
                </div>
                <span className="text-[14px] font-avenir font-medium">Help & Support</span>
              </div>
              {/* <div className="flex items-center gap-2" onClick={handleLogout}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faSignOut} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Log Out</span>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-col h-full">
              <div className="flex pt-2 pl-2">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2" onClick={() => handleClick('/')}>
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faHome} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Home</span>
                  </div>
                  <div className="flex items-center gap-2" onClick={() => handleClick('/bookings')}>
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faCalendar} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Bookings</span>
                  </div>
                  <div className="flex items-center gap-2" onClick={() => handleClick('/contacts')}>
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faUsers} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Contacts</span>
                  </div>
                  <div
                    className="flex items-center gap-2"
                    onClick={() => handleClick('/campaigns')}
                  >
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faBullhorn} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Campaigns</span>
                  </div>
                  <div
                    className="flex items-center gap-2"
                    onClick={() => handleClick('/conversations')}
                  >
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faComments} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Conversations</span>
                  </div>
                </div>
              </div>
              <div className="flex pt-12 pl-4">
                <span className="text-[12px] font-avenir font-medium text-gray-400">
                  Your Business
                </span>
              </div>
              <div className="flex pt-2 pl-4">
                <div
                  className="flex items-center gap-2"
                  onClick={() => handleClick('/business-profile')}
                >
                  <div className="w-6 h-6 rounded-md border border-black flex items-center justify-center">
                    {/* <FontAwesomeIcon icon={faComments} className="text-[16px]" /> */}
                    <span className="text-[12px] font-avenir font-medium text-black">
                      {firstLetterOfBusinessOrDefault()}
                    </span>
                  </div>
                  <span className="text-[14px] font-avenir font-medium">
                    {truncateText(businessNameOrDefault(), 30)}
                  </span>
                </div>
              </div>
              <div className="flex pt-12 pl-4">
                <span className="text-[12px] font-avenir font-medium text-gray-400">
                  Your Nestor
                </span>
              </div>
              <div className="flex  pl-2">
                <div className="flex flex-col gap-1">
                  <div
                    className="flex items-center gap-2"
                    onClick={() => setShowSettingsMenu(true)}
                  >
                    <div className="w-10 h-10 rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faCog} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Settings</span>
                  </div>
                  <div
                    className="flex items-center gap-2"
                    onClick={() => bookingLink && window.open(bookingLink(), '_blank')}
                  >
                    <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                      <FontAwesomeIcon icon={faBrowser} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Client Booking Site</span>
                  </div>
                  <div className="flex items-center gap-2" onClick={handleLogout}>
                    <div className="w-10 h-10 rounded-full  flex items-center justify-center">
                      <FontAwesomeIcon icon={faSignOut} className="text-[20px]" />
                    </div>
                    <span className="text-[14px] font-avenir font-medium">Log Out</span>
                  </div>
                  {/* <div
                  className="flex items-center gap-2"
                  onClick={() => handleClick('/booking-mobile')}
                >
                  <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon icon={faCalendarPlus} size="xs" />
                  </div>
                  <span className="text-[14px] font-avenir pl-6">Book a Client</span>
                </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full justify-end m-4">
              <NavLink onClick={toggleMenu} to="/personal-settings">
                <div className="flex items-center justify-start gap-2">
                  <ProfilePicture
                    picture={user?.picture}
                    firstName={user?.first_name}
                    height={32}
                    width={32}
                    fontSize={16}
                  />
                  <span className="text-[14px] font-avenir font-medium">{user?.first_name}</span>
                </div>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};
