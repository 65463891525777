import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import StripePayments from '../../components/payments/StripePayments';
import { AuthContext } from '../../contexts/authContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { environment } from '../../environments/environment';
import { linkStripeAccount } from '../../libs/services/connect';
import { cleanCalcomEmail } from '../../libs/utils/email.util';
import { useLocation } from 'react-router-dom';
import { useQuickStart, FormattedTask } from '../../contexts/quickStartContext';
import { OnboardingTaskNames, SubscriptionPlan } from '@book-nestor-monorepo/shared-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

export const PaymentsV2 = () => {
  const { bgColor } = useAppExperience();
  const authContext = useContext(AuthContext);
  const userHasLinkedPaymentsProvider =
    authContext.user?.payment_connect_data?.connected_account_id;
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    'link-stripe-account': true,
    payments: true,
  });

  const { markTaskComplete, selectedTask, remainingFormattedTasks } = useQuickStart();
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    undefined,
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onboardingContext = queryParams.get('onboardingContext');

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    }
  }, [selectedTask]);

  const toggleCard = (card: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [card]: !prev[card],
    }));
  };

  const stripeFeeVerbiage = () => {
    const base = 'Stripe fees + $0.20 per transaction apply';
    const userSubscription = authContext.user?.subscription;
    if (!userSubscription) {
      return base;
    } else if (userSubscription === SubscriptionPlan.STUDIO) {
      return `Stripe fees apply`;
    } else if (userSubscription === SubscriptionPlan.PRO) {
      return `Stripe fees + $0.10 per transaction apply`;
    }
    return base;
  };

  const handleStripeAccountCreation = async () => {
    try {
      const { url } = await linkStripeAccount(
        {
          email_address: cleanCalcomEmail(authContext.user?.email as string),
        },
        `${environment.reactAppBaseUrl}/payments`,
        `${environment.reactAppBaseUrl}/payments`,
      );
      window.location.href = url;
      markTaskComplete(OnboardingTaskNames.linkStripeAccount);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    // <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
    //   <div className="flex flex-col h-auto w-full">
    //     <div className="flex w-full h-auto items-start pl-4">
    //       {onboardingContext && selectedFormattedTask ? (
    //         <div className="flex flex-col items-start">
    //           <span className="text-[14px] font-avenir text-white">
    //             Step {selectedFormattedTask?.number}
    //           </span>
    //           <span className="text-[24px] font-inter text-white">
    //             {selectedFormattedTask?.title}
    //           </span>
    //         </div>
    //       ) : (
    //         <span className="text-[16px] font-avenir text-white">Your payment settings</span>
    //       )}
    //     </div>
    //     <div className="flex flex-col w-full h-full items-start gap-y-1 px-1 pt-4">
    //       <Card
    //         className="mb-4 shadow-md rounded-3xl overflow-hidden"
    //         sx={{
    //           borderRadius: '30px',
    //           backgroundColor: 'white',
    //           marginBottom: '8px',
    //           minHeight: '60px',
    //           width: '100%',
    //         }}
    //       >
    //         <CardContent
    //           sx={{
    //             padding: '8px',
    //             backgroundColor: 'white',
    //             '&:last-child': {
    //               paddingBottom: '8px',
    //             },
    //           }}
    //           className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
    //           onClick={() => toggleCard('link-stripe-account')}
    //         >
    //           <div className="flex items-center w-full">
    //             <div className="flex items-center">{<></>}</div>
    //             <div className="flex pl-4 justify-between items-start w-full">
    //               <div className="flex w-full">
    //                 <span className="font-avenir text-[16px] text-[#727272]">
    //                   Create or Link your Stripe account
    //                 </span>
    //               </div>
    //             </div>
    //           </div>
    //         </CardContent>
    //         <Collapse in={expandedCards['link-stripe-account']} unmountOnExit>
    //           <CardContent
    //             sx={{
    //               padding: '8px',
    //               paddingBottom: '4px',
    //               backgroundColor: 'white',
    //               '&:last-child': {
    //                 paddingBottom: '8px',
    //               },
    //             }}
    //           >
    //             {!userHasLinkedPaymentsProvider ? (
    //               <button
    //                 className="bg-black  text-white font-avenir text-[14px] py-2 px-4 mt-8 rounded-3xl "
    //                 onClick={handleStripeAccountCreation}
    //               >
    //                 Create or Link your Stripe Account to Process Payments
    //               </button>
    //             ) : (
    //               <button className="bg-black  text-white font-avenir text-[14px] py-2 px-4 mt-8 rounded-3xl ">
    //                 Stripe Account Linked
    //               </button>
    //             )}
    //             <p className="text-[14px] font-avenir text-[#727272] mt-2">{stripeFeeVerbiage()}</p>
    //           </CardContent>
    //         </Collapse>
    //       </Card>
    //       {userHasLinkedPaymentsProvider && (
    //         <Card
    //           className="mb-4 shadow-md rounded-3xl overflow-hidden"
    //           sx={{
    //             borderRadius: '30px',
    //             backgroundColor: 'white',
    //             marginBottom: '8px',
    //             minHeight: '60px',
    //             width: '100%',
    //           }}
    //         >
    //           <CardContent
    //             sx={{
    //               padding: '8px',
    //               backgroundColor: 'white',
    //               '&:last-child': {
    //                 paddingBottom: '8px',
    //               },
    //             }}
    //             className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
    //             onClick={() => toggleCard('payments')}
    //           >
    //             <div className="flex items-center w-full">
    //               <div className="flex items-center">{<></>}</div>
    //               <div className="flex pl-4 justify-between items-start w-full">
    //                 <div className="flex w-full">
    //                   <span className="font-avenir text-[16px] text-[#727272]">Payments</span>
    //                 </div>
    //               </div>
    //             </div>
    //           </CardContent>
    //           <Collapse in={expandedCards['payments']} unmountOnExit>
    //             <CardContent
    //               sx={{
    //                 padding: '8px',
    //                 overflowY: 'scroll',
    //                 backgroundColor: 'white',
    //                 '&:last-child': {
    //                   paddingBottom: '0px',
    //                 },
    //               }}
    //             >
    //               <div className="flex flex-col w-full h-full items-start gap-y-1 px-1 pt-4 overflow-y-scroll hide-scrollbar pb-8">
    //                 <StripePayments />
    //               </div>
    //             </CardContent>
    //           </Collapse>
    //         </Card>
    //       )}
    //     </div>
    //   </div>
    // </div>

    <div
      className={`ml-0 lg:ml-[72px] max-w-4xl  py-2 sm:px-6 lg:px-8 font-avenir bg-[${bgColor}] font-avenir`}
    >
      <div className="flex flex-col w-full justify-start items-start">
        <h2 className="text-[32px]  font-medium font-avenir px-2">Credit Card Payments</h2>
        {!userHasLinkedPaymentsProvider ? (
          <div className="flex w-full justify-start">
            <div
              className="  text-black font-avenir text-[14px] py-2 px-4 mt-4 underline cursor-pointer"
              onClick={handleStripeAccountCreation}
            >
              Create or Link your Stripe Account to Process Payments
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </div>
          </div>
        ) : (
          <StripePayments />
        )}
      </div>
    </div>
  );
};
