import { Contact, User } from '@book-nestor-monorepo/shared-types';
import { Booker } from '@calcom/atoms';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getUserBySlug } from '../libs/services/user';
import { SchedulerLayout } from './layouts/scheduler-layout';
import { bookingWebhook } from '../libs/services/bookings';
import { AuthIsSignedIn } from '../contexts/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import * as Sentry from '@sentry/react';
import { getContact } from '../libs/services/contacts';

export default function ScheduleEventType() {
  const [searchParams] = useSearchParams();
  const rescheduleUid = searchParams.get('rescheduleUid');
  const contactId = searchParams.get('contactId')?.replace(/ /g, '+') || undefined;

  const [user, setUser] = useState<User | undefined>(undefined);
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const { eventTypeSlug } = useParams<{ eventTypeSlug: string }>();
  const { userSlug } = useParams<{ userSlug: string }>();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUserBySlug(userSlug as string);
        if (!user) return;

        setUser(user);

        if (contactId) {
          const contact = await getContact(user.id || '', contactId as string);
          setContact(contact);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return isLoading ? (
    <div></div>
  ) : (
    <SchedulerLayout>
      <AuthIsSignedIn>
        <div className="flex flex-row items-start justify-start pl-4 cursor-pointer">
          <Link to="/">
            <FontAwesomeIcon className="pr-4" icon={faArrowLeft} />
            Back to scheduler
          </Link>
        </div>
      </AuthIsSignedIn>

      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center text-center max-h-full px-4">
          <Booker
            {...(rescheduleUid && { rescheduleUid })}
            eventSlug={eventTypeSlug as string}
            username={user?.connection_data?.cal_provider_user_name as string}
            defaultFormValues={{
              firstName: contact?.name || '',
              lastName: contact?.last_name || '',
              name: contact ? `${contact?.name} ${contact?.last_name}` : '',
              email: contact?.email || '',
            }}
            onCreateBookingSuccess={async (data) => {
              if (userSlug && data?.data?.uid) {
                await bookingWebhook({
                  user_slug: userSlug,
                  booking_id: data?.data?.uid,
                  rescheduleUid,
                });
                window.scrollTo(0, 0);
                history(`/book/${userSlug}/booking/${data?.data?.uid}`);
              }
            }}
          />
        </div>
      </div>
    </SchedulerLayout>
  );
}
