import { GettingStartedTask, TaskNames, User } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { patchUser } from '../libs/services/user';
import { AuthContext } from './authContext';

// Define a type for your context state to improve type safety
interface OnboardingState {
  tasks: GettingStartedTask[];
  currentTaskIndex: number;
  onboardingComplete: boolean;
  isLoading: boolean;
}

interface OnboardingContextType {
  onboardingState: OnboardingState;
  completeTask: (taskName: TaskNames) => void;
  setCurrentRoute: (currentPlan?: string) => void;
}

// the names make the order of the tasks seem backwards but they are correct
const defaultTaskOrderedList: GettingStartedTask[] = [
  { name: TaskNames.onboardingOutro },
  { name: TaskNames.welcome },
];

const defaultOnboardingState: OnboardingContextType = {
  onboardingState: {
    tasks: [],
    currentTaskIndex: 0,
    onboardingComplete: false,
    isLoading: true,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  completeTask: () => {},
  setCurrentRoute: () => '/',
};

const OnboardingContext = createContext<OnboardingContextType>(defaultOnboardingState);

export const OnboardingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, updateLoggedInUser, handleLoginFlow } = useContext(AuthContext);
  const history = useNavigate();

  const [onboardingState, setOnboardingState] = useState<OnboardingState>({
    tasks: [],
    currentTaskIndex: 0,
    onboardingComplete: false,
    isLoading: true,
  });

  const processOnboardingUseEffectRef = useRef(false);
  useEffect(() => {
    // PREVENT from double run
    // if the useEffect is called again, we don't want to run the code again.
    if (processOnboardingUseEffectRef.current) return;

    processOnboardingUseEffectRef.current = true;

    const processOnboarding = async () => {
      if (user !== undefined) {
        const tasks = user?.activation_data?.getting_started_tasks || defaultTaskOrderedList;
        const currentTaskIndex = tasks.findIndex((task) => !task.completed_at);
        const onboardingComplete = user?.activation_data?.has_seen_getting_started === true;

        setOnboardingState({
          tasks,
          currentTaskIndex,
          onboardingComplete,
          isLoading: false,
        });
      } else {
        const user = await handleLoginFlow?.();
        if (user) {
          await finishLoginFlow(user);
        }
      }
    };

    processOnboarding();

    // return () => {
    //   processOnboardingUseEffectRef.current = true;
    // };
  }, []);

  const finishLoginFlow = async (localUser: User) => {
    const tasks = localUser?.activation_data?.getting_started_tasks || defaultTaskOrderedList;
    const currentTaskIndex = tasks.findIndex((task) => !task.completed_at);
    const onboardingComplete = localUser?.activation_data?.has_seen_getting_started === true;

    setOnboardingState({
      tasks,
      currentTaskIndex,
      onboardingComplete,
      isLoading: false,
    });
  };

  const patchUserData = async (done: boolean) => {
    const session = await fetchAuthSession();
    const userId = session?.tokens?.accessToken?.payload?.sub || '';
    if (!user) return;
    if (!onboardingState.tasks.length) return;
    if (user.activation_data?.has_seen_getting_started) return;

    try {
      const user = await patchUser(userId, {
        activation_data: {
          has_seen_getting_started: done,
          getting_started_tasks: onboardingState.tasks,
        },
      });
      if (updateLoggedInUser) updateLoggedInUser(user);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    const onboardingOutroTask = onboardingState.tasks.find(
      (task) => task.name === TaskNames.welcome,
    );
    if (onboardingOutroTask && onboardingOutroTask.completed_at) {
      patchUserData(true);
    } else {
      patchUserData(false);
    }
  }, [onboardingState.tasks]);

  const completeTask = async (taskName: string) => {
    setOnboardingState((prevState) => {
      const tasksCopy = [...prevState.tasks];
      const taskIndex = tasksCopy.findIndex((task) => task.name === taskName);
      if (taskIndex !== -1) {
        tasksCopy[taskIndex].completed_at = new Date().toISOString();
        return {
          ...prevState,
          tasks: tasksCopy,
          currentTaskIndex:
            prevState.currentTaskIndex + 1 >= tasksCopy.length
              ? prevState.currentTaskIndex
              : prevState.currentTaskIndex + 1,
          onboardingComplete: tasksCopy.length === prevState.currentTaskIndex + 1,
        };
      }
      return prevState;
    });
  };

  const setCurrentRoute = (currentPlan?: string): void => {
    // Define your task-to-route mapping here
    const taskToRouteMap: Record<TaskNames, string> = {
      welcome: '/intro/splash',
      onboardingOutro: '/intro/onboardingoutro',
    };

    const shouldSetCurrentRoute = !onboardingState.isLoading;
    if (shouldSetCurrentRoute) {
      if (onboardingState.onboardingComplete) {
        history(`/`);
        return;
      }
      const currentTaskName = onboardingState.tasks[onboardingState.currentTaskIndex]?.name;

      const currentRoute = taskToRouteMap[currentTaskName] || '/';
      history(`${currentRoute}`);
    }
  };

  return (
    <OnboardingContext.Provider value={{ onboardingState, completeTask, setCurrentRoute }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);
