import { AvailableTimeSlot, PaginatedBookingItem } from '@book-nestor-monorepo/shared-types';
import { useEffect, useState } from 'react';
import CalendarTimeSlot from '../../../pages/v2/calendar-time-slot';
import { BookingData } from './booking-container';
import { BookingSummaryCard } from './booking-summary-card';

export const SelectTime = ({
  bookingData,
  availableTimeSlotsForSelectedDate,
  fetchBookingsForSelectedDate,
  onSelect,
  onNext,
  onBack,
}: {
  bookingData: BookingData;
  availableTimeSlotsForSelectedDate: () => AvailableTimeSlot[];
  fetchBookingsForSelectedDate: () => Promise<PaginatedBookingItem>;
  onSelect: (time: any) => void;
  onNext: () => void;
  onBack: () => void;
}) => {
  const [timeSlots, setTimeSlots] = useState<AvailableTimeSlot[]>([]);
  const [bookings, setBookings] = useState<PaginatedBookingItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const bookings = await fetchBookingsForSelectedDate();
      setBookings(bookings);
      setIsLoading(false);
    };
    fetchData();
  }, [bookingData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Call both methods in parallel using Promise.all
        const [slots, bookingsData] = await Promise.all([
          availableTimeSlotsForSelectedDate(),
          fetchBookingsForSelectedDate(),
        ]);

        setTimeSlots(slots);
        setBookings(bookingsData);
      } catch (error) {
        console.error('Error fetching time slots and bookings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [availableTimeSlotsForSelectedDate, fetchBookingsForSelectedDate]);

  return (
    <div className="mt-8 space-y-2">
      <div className="border-b pb-4">
        {/* Booking Summary Card */}
        <BookingSummaryCard bookingData={bookingData} />
      </div>

      <CalendarTimeSlot
        timeSlots={timeSlots}
        bookings={bookings}
        selectedDate={bookingData.date}
        slotDurationMins={bookingData.service?.duration_mins as number}
        onNext={onNext}
        onBack={onBack}
        onSelect={onSelect}
      />
    </div>
  );
};
