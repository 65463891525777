import { Contact, ContactPayment, User } from '@book-nestor-monorepo/shared-types';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { Link } from 'react-router-dom';
import { formatStripeAmount } from '../../libs/utils/currency.util';
import { formatStripeDate } from '../../libs/utils/date.util';
import { SaveButton } from '../formLibrary/formSaveButton';

interface PaymentType {
  title: string;
  onClose: () => void;
  onCreateStripeCustomer: () => Promise<Contact>;
  contact?: Contact;
  contactPayments?: ContactPayment[];
}

const renderPayments = (
  user: User,
  onCreateStripeCustomer: () => Promise<Contact>,
  onUpdatedContact: (updatedContact: Contact) => void,
  contact?: Contact,
  contactPayments?: ContactPayment[],
) => {
  if (!user.payment_connect_data?.connected_account_id) {
    return (
      <div>
        <Link to="/payments">Link your Stripe Account</Link> to process payments
      </div>
    );
  }

  if (!contact?.payment_customer_id) {
    return (
      <>
        <p className="text-[41.89px] leading-[48px] text-white font-extralight">
          Create a Stripe Customer
        </p>
        <p className="font-inter text-start w-3/4  text-sm leading-6 text-white pb-4 tracking-wide">
          To accurately track and manage payment history for this client, first create a Stripe
          customer profile.
        </p>
        {/* <button
          onClick={onCreateStripeCustomer}
          className="bg-white text-black w-1/2 rounded-full p-2"
        >
          Create Customer
        </button> */}
        <SaveButton
          defaultText="Create Customer"
          loadingText="Creating Customer..."
          successText="Customer Created"
          onClick={async () => {
            const updatedContact = await onCreateStripeCustomer();
            onUpdatedContact(updatedContact);
          }}
          width="w-1/2"
        />
      </>
    );
  }

  if (!contactPayments?.length) {
    return <div className="flex flex-col items-center text-white">No payments yet!</div>;
  }

  return (
    <div>
      {contactPayments?.map((payment) => (
        <div className="flex flex-row justify-between pt-1.5 px-2 pb-9 border-b border-white mb-2">
          <div className="flex flex-col max-w-[255px]">
            <p className="font-avenir leading-4 text-base text-white font-bold mb-2.5">
              {formatStripeAmount(payment.amount)}
            </p>
            <p className="font-avenir leading-4 font-bold text-white text-[9.89px] mb-2">
              {formatStripeDate(payment.created)}
            </p>
            <p className="font-avenir leading-4 font-normal text-white text-[9.89px]">
              {payment.product_name}
            </p>
          </div>
          <div className="flex flex-row">
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6875 6.25C14.6875 6.25 14.9062 7.40625 14.9688 7.65625H13.9375C14.0312 7.375 14.4375 6.28125 14.4375 6.28125C14.4062 6.3125 14.5312 6 14.5938 5.8125L14.6875 6.25ZM18 1.5V12.5C18 13.3438 17.3125 14 16.5 14H1.5C0.65625 14 0 13.3438 0 12.5V1.5C0 0.6875 0.65625 0 1.5 0H16.5C17.3125 0 18 0.6875 18 1.5ZM4.75 9.375L6.71875 4.5H5.40625L4.15625 7.8125L4.03125 7.15625L3.59375 4.9375C3.53125 4.625 3.3125 4.53125 3.03125 4.5H1V4.625C1.46875 4.75 1.90625 4.90625 2.3125 5.15625L3.4375 9.375H4.75ZM7.6875 9.375L8.5 4.5H7.21875L6.4375 9.375H7.6875ZM12.0625 7.78125C12.0938 7.21875 11.75 6.8125 11.0312 6.46875C10.5938 6.25 10.3125 6.09375 10.3125 5.875C10.3125 5.65625 10.5312 5.4375 11.0312 5.4375C11.4375 5.4375 11.75 5.53125 11.9688 5.625L12.0938 5.6875L12.25 4.625C12 4.53125 11.625 4.4375 11.125 4.4375C9.875 4.4375 9 5.09375 9 6.03125C9 6.71875 9.625 7.125 10.0938 7.34375C10.5938 7.59375 10.75 7.75 10.75 7.9375C10.75 8.28125 10.375 8.4375 10 8.4375C9.5 8.4375 9.25 8.34375 8.8125 8.15625L8.65625 8.09375L8.5 9.1875C8.78125 9.3125 9.3125 9.4375 9.875 9.4375C11.2188 9.4375 12.0625 8.78125 12.0625 7.78125ZM16.5 9.375L15.4688 4.5H14.5C14.1875 4.5 13.9688 4.59375 13.8438 4.90625L11.9688 9.375H13.3125C13.3125 9.375 13.5 8.78125 13.5625 8.65625H15.1875C15.2188 8.8125 15.3125 9.375 15.3125 9.375H16.5Z"
                fill="white"
              />
            </svg>
            <p className="uppercase font-avenir text-base text-white leading-4 ml-2">Visa</p>
          </div>
          {payment.receipt_url && (
            <div className="flex flex-col gap-2">
              <Link
                to={payment.receipt_url}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full flex items-center justify-center bg-gray-secondary/50 w-8 h-8"
              >
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.957031 0.1875L4.53125 3.91797C4.60938 4.01562 4.66797 4.13281 4.66797 4.25C4.66797 4.36719 4.60938 4.48438 4.53125 4.5625L0.957031 8.29297C0.78125 8.48828 0.46875 8.48828 0.292969 8.3125C0.0976562 8.13672 0.0976562 7.84375 0.273438 7.64844L3.55469 4.23047L0.273438 0.832031C0.0976562 0.65625 0.0976562 0.34375 0.292969 0.167969C0.46875 -0.0078125 0.78125 -0.0078125 0.957031 0.1875Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const PaymentHistoryModal = ({
  title,
  onClose,
  contact: initialContact,
  onCreateStripeCustomer,
  contactPayments,
}: PaymentType) => {
  const authContext = useContext(AuthContext);
  const [contact, setContact] = useState<Contact | undefined>(initialContact);

  useEffect(() => {
    setContact(initialContact);
  }, [initialContact]);

  const onUpdatedContact = (updatedContact: Contact) => {
    setContact(updatedContact);
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-row items-center ${title ? 'justify-between' : 'justify-end'} h-12 mb-10`}
      >
        {title && (
          <div className="flex flex-col">
            <p className="font-inter font-medium text-base leading-4 text-white">
              {contact?.payment_customer_id ? title : 'Create Customer'} :
            </p>
            {contact?.payment_customer_id && (
              <p className="font-inter font-medium text-base leading-4 text-white ">
                <FontAwesomeIcon color="#5f4cfe" size="2xl" className="pt-2 pr-2" icon={faStripe} />
                <span className="font-inter font-medium text-base leading-4 text-white mb-2">
                  {contact?.payment_customer_id}
                </span>
              </p>
            )}
          </div>
        )}
        <span
          className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 border border-white rounded-full"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
      {renderPayments(
        authContext.user as User,
        onCreateStripeCustomer,
        onUpdatedContact,
        contact as Contact,
        contactPayments as ContactPayment[],
      )}
    </div>
  );
};

export default PaymentHistoryModal;
