import { ContactBooking, Review, ReviewSummary } from '@book-nestor-monorepo/shared-types';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

export const getReviewById = async (userSlug: string, reviewId: string): Promise<Review> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userSlug}/reviews/${reviewId}`;

    const response = await axios.get(url, {});

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch review by id');
  }
};

export const getReviewByContactId = async (
  userSlug: string,
  contactId: string,
): Promise<Review | undefined> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userSlug}/reviews/contact/${contactId}`;

    const response = await axios.get(url, {});

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return undefined; // means the review does not exist
    }
    throw new Error('Failed to fetch review by contact id');
  }
};

export const getReviewSummary = async (userSlug: string): Promise<ReviewSummary> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userSlug}/reviews?reviewSummary=true`;

    const response = await axios.get(url, {});

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch review summary');
  }
};

export const getContactBookings = async (
  userId: string,
  contactId: string,
): Promise<ContactBooking[]> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();

    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/contacts/${contactId}/bookings`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.items || [];
  } catch (error) {
    throw new Error('Failed to fetch contact bookings');
  }
};

export const createReview = async (
  userId: string,
  createReviewRequest: Partial<Review>,
): Promise<Review> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/reviews`;

    const response = await axios.post(url, createReviewRequest, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to create review');
  }
};

export const submitReview = async (
  userSlug: string,
  reviewId: string,
  review: Review,
): Promise<Review> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userSlug}/reviews/${reviewId}/submit`;

    const response = await axios.post(url, review, {});

    return response.data;
  } catch (error) {
    throw new Error('Failed to submit review');
  }
};
