import React from 'react';
import { LayoutProps } from '../../../models';
import { DesktopLayout } from './desktop-layout';
import { TabletLayout } from './tablet-layout';
import { MobileLayout } from './mobile-layout';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';

const AuthenticatedAppLayout: React.FC<LayoutProps> = ({ children }) => {
  const { isDesktop, isTablet } = useAppExperience();
  return (
    <div className="w-full h-full text-2xl items-center justify-center text-center font-avenir">
      {isDesktop ? (
        <DesktopLayout>{children}</DesktopLayout>
      ) : isTablet ? (
        <TabletLayout>{children}</TabletLayout>
      ) : (
        <MobileLayout>{children}</MobileLayout>
      )}
    </div>
  );
};

export default AuthenticatedAppLayout;
