import { Review, ReviewStatus } from '@book-nestor-monorepo/shared-types';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReviewById, submitReview } from '../libs/services/reviews';
import { getUserBySlug } from '../libs/services/user';
import { SchedulerLayout } from './layouts/scheduler-layout';
import * as Sentry from '@sentry/react';

export default function ReviewDetail() {
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useState<Review | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { reviewId } = useParams<{ reviewId: string }>();
  const { userSlug } = useParams<{ userSlug: string }>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (reviewId && userSlug) {
          const user = await getUserBySlug(userSlug);
          if (user?.id) {
            const reviewResponse = await getReviewById(user.id, reviewId);
            setReview(reviewResponse);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [reviewId, userSlug]);

  const formik = useFormik({
    initialValues: {
      rating: review?.rating || 0,
      review_text: review?.review_text || '',
    },
    onSubmit: async (values) => {
      if (reviewId && userSlug) {
        const user = await getUserBySlug(userSlug);
        if (user?.id) {
          await submitReview(user.id, reviewId, { ...review, ...values } as Review);
          setIsSubmitted(true);
        }
      }
    },
  });

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <button
          type="button"
          key={i}
          className={`text-2xl focus:outline-none ${
            i <= formik.values.rating ? 'text-yellow-400' : 'text-gray-400'
          } hover:text-yellow-400`}
          onClick={() => formik.setFieldValue('rating', i)}
        >
          &#9733;
        </button>,
      );
    }
    return stars;
  };

  return (
    <SchedulerLayout>
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center text-center max-h-full px-4">
          {!isLoading && review && (
            <div>
              {isSubmitted ? (
                <div>
                  <p className="text-2xl font-bold mb-4">Thank you for leaving a review!</p>
                  <p>Your feedback is greatly appreciated.</p>
                </div>
              ) : review.status !== ReviewStatus.PENDING ? (
                <p>This review has already been submitted.</p>
              ) : (
                <div>
                  {review.contact_name && (
                    <p>
                      Hi, {review.contact_name}! Leave your review for {review.reviewee_name}
                    </p>
                  )}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                      <label className="block mb-2">Rating:</label>
                      <div className="flex justify-center space-x-2">{renderStars()}</div>
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2">Review:</label>
                      <textarea
                        id="review_text"
                        name="review_text"
                        rows={4}
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        maxLength={512}
                        value={formik.values.review_text}
                        onChange={formik.handleChange}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    >
                      Submit Review
                    </button>
                  </form>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </SchedulerLayout>
  );
}
