export interface Upload {
  file_name?: string;
  file_type?: SupportedFileType;
  upload_url?: string;
}

export enum SupportedFileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  HEIC = 'image/heic',
  WAV = 'audio/wav',
  MP3 = 'audio/mpeg',
}
