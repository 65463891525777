import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/appContext';
import { StoreContext, ActionTypes } from '../../contexts/storeContext';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import MenuBox from '../menu/MenuBox';
import SubMenu from '../menu/SubMenu';
import { bookingManagementItems, clientEngagementItems, nestorItems } from '../menu/data';
import { environment } from '../../environments/environment';
import {
  getAppointmentsCount,
  getTextMessagesCount,
  getVoiceMessagesCount,
} from '../../libs/services/counts';
import { getContacts } from '../../libs/services/contacts';
import { MenuStatistic, menuStatistics } from './data';
import { getServicePhoneDetailsByUser } from '../../libs/services/voice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useQuickStart } from '../../contexts/quickStartContext';
import LoadingDots from '../loading';

export default function MobileMenu() {
  const { allTasksCompleted, remainingFormattedTasks } = useQuickStart();
  const { isMenuOpen, toggleMenu } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { state, dispatch } = useContext(StoreContext);

  const [contactsCount, setContactsCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  const [voiceCount, setVoiceCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [menuStats, setMenuStats] = useState<MenuStatistic[]>([]);

  const getCounts = async () => {
    if (!user || !user.id) {
      return;
    }
    setIsLoading(true);
    // TODO: move to GlobalContext
    const userServicePhoneResponse = await getServicePhoneDetailsByUser(user.id);
    const servicePhoneNumber = userServicePhoneResponse?.phone_number || '';

    if (servicePhoneNumber) {
      const [textMessagesCountResponse, voiceMessagesCountResponse] = await Promise.all([
        getTextMessagesCount(servicePhoneNumber),
        getVoiceMessagesCount(servicePhoneNumber),
      ]);
      if (textMessagesCountResponse) {
        setMessagesCount(textMessagesCountResponse);
      }
      if (voiceMessagesCountResponse) {
        setVoiceCount(voiceMessagesCountResponse);
      }
    }

    if (state.contacts.length === 0) {
      const contactsResponse = await getContacts(user.id, {});
      if (contactsResponse) {
        setContactsCount(contactsResponse.length);
        dispatch({ type: ActionTypes.SET_CONTACTS, payload: contactsResponse });
      }
    }
    // TODO: Store Appointments ???
    const appointmentsCountResponse = await getAppointmentsCount();
    if (appointmentsCountResponse) {
      setAppointmentsCount(appointmentsCountResponse);
    }
    setIsLoading(false);
  };

  // set contacts count
  useEffect(() => {
    if (state.contacts) {
      setContactsCount(state.contacts.length);
    }
  }, [state.contacts]);

  // set menu stats
  useEffect(() => {
    const newStat = [...menuStatistics];
    for (const stat of newStat) {
      if (stat.id === 'contacts') {
        stat.count = contactsCount;
      } else if (stat.id === 'appointments') {
        stat.count = appointmentsCount;
      } else if (stat.id === 'text-conversations') {
        stat.count = messagesCount;
      } else if (stat.id === 'voice-messages') {
        stat.count = voiceCount;
      }
    }

    setMenuStats(newStat);
  }, [contactsCount, messagesCount, appointmentsCount, voiceCount]);

  // get counts
  useEffect(() => {
    if (user) {
      getCounts();
    }
  }, [user]);

  return (
    <div className="">
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-20" onClick={toggleMenu}></div>
      )}
      <div className="bg-[#303030] z-50">
        <div className="font-avenir">
          <div
            className={`transform top-0 left-0 w-screen  md:w-[400px] md:mb-[100px] md:overflow-y-scroll bg-[#9e9e9e] text-white absolute h-full pt-6 px-2 overflow-auto hide-scrollbar ease-in-out transition-all duration-300 z-30 ${
              isMenuOpen ? 'shadow-right translate-x-0' : '-translate-x-full'
            }`}
          >
            <div className="flex gap-2 px-2">
              <button
                onClick={toggleMenu}
                className="w-10 h-10 rounded-full bg-[#F7F7F780] border-[0.5px] border-[#FFFFFF80] flex items-center justify-center"
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.32812 10.3984L1.03906 5.92188C0.921875 5.78125 0.875 5.64062 0.875 5.5C0.875 5.38281 0.921875 5.24219 1.01562 5.125L5.30469 0.648438C5.51562 0.414062 5.89062 0.414062 6.10156 0.625C6.33594 0.835938 6.33594 1.1875 6.125 1.42188L2.21094 5.5L6.14844 9.625C6.35938 9.83594 6.35938 10.2109 6.125 10.4219C5.91406 10.6328 5.53906 10.6328 5.32812 10.3984Z"
                    fill="white"
                  />
                </svg>
              </button>
              <NavLink
                onClick={toggleMenu}
                to="/"
                className="w-10 h-10 rounded-full bg-[#F7F7F780] border-[0.5px] border-[#FFFFFF80] flex items-center justify-center"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9766 6.5C13.9766 6.92188 13.625 7.25 13.2266 7.25H12.4766L12.5 11C12.5 11.0703 12.5 11.1406 12.5 11.1875V11.5625C12.5 12.1016 12.0781 12.5 11.5625 12.5H11.1875C11.1406 12.5 11.1172 12.5 11.0938 12.5C11.0703 12.5 11.0234 12.5 11 12.5H10.25H9.6875C9.14844 12.5 8.75 12.1016 8.75 11.5625V11V9.5C8.75 9.10156 8.39844 8.75 8 8.75H6.5C6.07812 8.75 5.75 9.10156 5.75 9.5V11V11.5625C5.75 12.1016 5.32812 12.5 4.8125 12.5H4.25H3.5C3.45312 12.5 3.42969 12.5 3.38281 12.5C3.35938 12.5 3.33594 12.5 3.3125 12.5H2.9375C2.39844 12.5 2 12.1016 2 11.5625V8.9375C2 8.9375 2 8.91406 2 8.89062V7.25H1.25C0.828125 7.25 0.5 6.92188 0.5 6.5C0.5 6.28906 0.570312 6.10156 0.734375 5.9375L6.73438 0.6875C6.89844 0.523438 7.08594 0.5 7.25 0.5C7.41406 0.5 7.60156 0.546875 7.74219 0.664062L13.7188 5.9375C13.9062 6.10156 14 6.28906 13.9766 6.5Z"
                    fill="white"
                  />
                </svg>
              </NavLink>
              <NavLink onClick={toggleMenu} to="/personal/settings">
                <div className="relative">
                  {user?.picture ? (
                    <img
                      src={user.picture}
                      alt="profile"
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 bg-blue-status rounded-full flex items-center justify-center">
                      <span className="text-white font-bold">{user?.first_name?.[0]}</span>
                    </div>
                  )}
                  <button className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-[#FFFFFFB0] border-[0.5px] border-[#D9DAD6] flex items-center justify-center">
                    <svg
                      width="9"
                      height="3"
                      viewBox="0 0 9 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.8125 1.25C6.8125 0.742188 7.22266 0.3125 7.75 0.3125C8.25781 0.3125 8.6875 0.742188 8.6875 1.25C8.6875 1.77734 8.25781 2.1875 7.75 2.1875C7.22266 2.1875 6.8125 1.77734 6.8125 1.25ZM3.6875 1.25C3.6875 0.742188 4.09766 0.3125 4.625 0.3125C5.13281 0.3125 5.5625 0.742188 5.5625 1.25C5.5625 1.77734 5.13281 2.1875 4.625 2.1875C4.09766 2.1875 3.6875 1.77734 3.6875 1.25ZM2.4375 1.25C2.4375 1.77734 2.00781 2.1875 1.5 2.1875C0.972656 2.1875 0.5625 1.77734 0.5625 1.25C0.5625 0.742188 0.972656 0.3125 1.5 0.3125C2.00781 0.3125 2.4375 0.742188 2.4375 1.25Z"
                        fill="#989898"
                      />
                    </svg>
                  </button>
                </div>
              </NavLink>
            </div>
            <h2 className="font-bold text-[42px] leading-[42px] mt-[25px] pl-2">
              {user?.first_name}
            </h2>
            {isLoading ? (
              <LoadingDots
                skeletonProps={{
                  count: 2,
                  height: 128,
                  borderRadius: 10,
                  baseColor: '#E1E0E1',
                  style: { marginBottom: '4px', width: '100%', height: 'auto' },
                }}
              />
            ) : (
              <MenuBox menuStatistics={menuStats} />
            )}
            {!allTasksCompleted && (
              <NavLink
                onClick={toggleMenu}
                to="/quick-start"
                className="items-center justify-center"
              >
                <div className="mt-2 w-full bg-black text-white rounded-full py-[19px] px-[14px] flex flex-row justify-between cursor-pointer items-center">
                  <h6 className="font-semibold text-white">Quick Start</h6>

                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </NavLink>
            )}

            <SubMenu
              items={clientEngagementItems}
              title="Client Engagement"
              formattedTasks={remainingFormattedTasks}
            />
            <SubMenu
              items={bookingManagementItems}
              title="Booking Management"
              formattedTasks={remainingFormattedTasks}
            />
            <SubMenu items={nestorItems} title="Nestor" formattedTasks={remainingFormattedTasks} />
            <p className="text-[#CDCDCD] mt-6">v{environment.appVersion}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
