import * as Yup from 'yup';

export const userServicePhoneSchema = Yup.object({
  friendly_business_name: Yup.string().required('Friendly business name is required'),
  friendly_user_name: Yup.string().required('Friendly user name is required'),
  ai_assistant_name: Yup.string().required('AI assistant name is required'),
  user_id: Yup.string(),
  phone_number: Yup.string(),
  primary_phone_number: Yup.string(),
  booking_link: Yup.string(),
  voice_assistant_enabled: Yup.boolean(),
  external_status: Yup.string().optional(),
  created_at: Yup.string(),
  updated_at: Yup.string(),
});
