import { FC } from 'react';
import DetailsTab from './Tabs/DetailsTab';
import HoursTab from './Tabs/HoursTab';
import ReviewsTab from './Tabs/ReviewsTab';
import ServicesTab from './Tabs/ServicesTab';
import { EventType, Organization, Review, Schedule } from '@book-nestor-monorepo/shared-types';

type TabType = {
  label: string;
  active: boolean;
};

interface IProps {
  tabs: TabType[];
  selectTab: (idx: number) => void;
  eventTypes: EventType[];
  organization: Organization;
  defaultSchedule: Schedule;
  reviews: Review[];
  contactId?: string;
}

const BookingTabsMobile: FC<IProps> = ({
  tabs,
  selectTab,
  eventTypes,
  organization,
  defaultSchedule,
  reviews,
  contactId,
}) => {
  const activeTab = tabs.find((tab) => tab.active);

  return (
    <div className="w-full bg-[#D7D8D3] md:hidden font-avenir">
      <div className="bg-[#E5E6E1] rounded-bl-[32px] p-2 rounded-br-[32px] w-full">
        <div className="bg-[#D7D8D3] rounded-[32px] w-full">
          <div className="py-[39px] px-[18.5px] flex items-end justify-around">
            {tabs.map(({ label, active }, idx) => (
              <button
                key={`${label}-${idx}`}
                onClick={() => selectTab(idx)}
                className={`text-[#00000080] h-[41px] flex flex-col gap-[9px] items-center ${active && 'text-black-alt font-bold'}`}
              >
                {label}
                {active && <div className="w-[32px] h-2 bg-black-alt" />}
              </button>
            ))}
          </div>
          <div className="px-2 pb-2">
            <div className="bg-white rounded-[32px]">
              {activeTab?.label === 'Services' && (
                <ServicesTab eventTypes={eventTypes} contactId={contactId} />
              )}
              {activeTab?.label === 'Details' && <DetailsTab organization={organization} />}
              {activeTab?.label === 'Hours' && <HoursTab defaultSchedule={defaultSchedule} />}
              {activeTab?.label === 'Reviews' && <ReviewsTab reviews={reviews} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingTabsMobile;
