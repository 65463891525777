import { EventType } from '@book-nestor-monorepo/shared-types';
import { FC } from 'react';
import { environment } from '../../environments/environment';
import { Link, useParams } from 'react-router-dom';

interface IProps {
  eventType: EventType;
  contactId?: string;
}

const BookingCardItem: FC<IProps> = ({ eventType, contactId }) => {
  const { userSlug } = useParams<{ userSlug: string }>();
  const image = eventType.image_key ? `${environment.s3.bucketUrl}/${eventType.image_key}` : null;
  const bookingLink = contactId
    ? `${environment.baseBookingUrl}/${userSlug}/${eventType.slug}?contactId=${contactId}`
    : `${environment.baseBookingUrl}/${userSlug}/${eventType.slug}`;
  return (
    <div className="w-full bg-[#ffffff80] rounded-[16px] p-2">
      {image ? (
        <img
          className="w-full h-[144px] rounded-[10px] object-cover object-center"
          alt=""
          src={image}
        />
      ) : (
        <div className="w-full h-[144px] rounded-[10px] bg-[#CECFCA]" />
      )}
      <h4 className="mt-[12px]">{eventType.title}</h4>
      <h3 className="font-bold">${eventType.price}</h3>
      <h4 className="mt-[24px]">{eventType.description}</h4>
      <div className="flex items-center gap-2 mt-[12px] flex-col lg:flex-row">
        <Link
          to={bookingLink}
          target="_blank"
          rel="noopener noreferrer"
          className="border border-[#CECFCA] rounded-full px-[12px] py-[8px]"
        >
          Book Now
        </Link>
        <Link
          to={bookingLink}
          target="_blank"
          rel="noopener noreferrer"
          className="border border-[#CECFCA] rounded-full px-[12px] py-[8px]"
        >
          {eventType.duration_mins} Mins
        </Link>
      </div>
    </div>
  );
};

export default BookingCardItem;
