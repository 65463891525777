import { OnboardingTaskNames, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { faCopy, faX } from '@fortawesome/pro-light-svg-icons';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import FormikWrapper from '../../components/form-wrapper';
import { SaveButton } from '../../components/formLibrary/formSaveButton';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { assignUserServicePhone, getServicePhoneDetailsByUser } from '../../libs/services/voice';
import { formatPhoneNumber } from '../../libs/utils/phone.util';
import { areaCodeSchema } from '../../schemas/phone-number-location.schema';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { AgentCardList } from '../../components/v2/Agent/AgentCardList';

export const CommunicationPageV2 = () => {
  const [expandedCard, setExpandedCard] = useState('phone');
  const { bgColor } = useAppExperience();
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone>();
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { markTaskComplete } = useQuickStart();
  const toggleCard = (cardName: string) => {
    setExpandedCard(expandedCard === cardName ? '' : cardName);
  };
  const [copied, setCopied] = useState(false);

  const isPhoneExpanded = expandedCard === 'phone';

  const fetchCommunication = async () => {
    if (!authContext.user) return;
    const userServicePhoneResponse = await getServicePhoneDetailsByUser(authContext.user.id || '');
    setUserServicePhone(userServicePhoneResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCommunication();
  }, [authContext.user]);

  const initialValuesLocation = {
    areaCode: null,
  };

  const mapToUpdateUserServicePhone = (values: Partial<UserServicePhone>): UserServicePhone => {
    if (!userServicePhone) {
      throw new Error('User Service Phone not found');
    }
    return {
      ...userServicePhone,
      ...values,
    };
  };

  const handleSubmitAddress = async (values: { areaCode: number | null }) => {
    if (!values.areaCode) {
      return;
    }
    try {
      await assignUserServicePhone({ areaCode: values.areaCode! });
      await markTaskComplete(OnboardingTaskNames.verifyAIAssistant);

      fetchCommunication();
      closeMuiModal();
    } catch (error) {
      alert(
        'No available phone numbers found in your area. Please try again with a different area.',
      );
      Sentry.captureException(error);
    }
  };

  const FormFieldsLocation = ({ dirty }: any) => {
    const { values, handleChange, submitForm, isSubmitting, isValid } = useFormikContext<{
      areaCode: number;
    }>();

    return (
      <div className="pt-2">
        <div className="flex w-full items-center mb-4">
          <div className="flex items-center relative w-full">
            <PrimaryTextField
              label="Area Code"
              color="primary"
              name="areaCode"
              fullWidth={true}
              type="number"
              required
              variant="filled"
              onChange={handleChange}
              value={values.areaCode}
              helperText="e.g. 305"
            />
          </div>
        </div>
        <div className="w-full pt-6">
          <SaveButton
            onClick={async () => {
              if (isValid) {
                await submitForm();
              }
            }}
            defaultText="Get my new number"
            loadingText={['Searching...', 'Enlisting AI Agent...', 'Almost there...']}
            loadingTextDuration={3500}
            successText="Success!"
            disabled={!isValid || !dirty}
            color="white"
            bgColor="black"
          />
        </div>
      </div>
    );
  };

  const NoServicePhone = ({ onClose }: { onClose: () => void }) => {
    return (
      <div className="w-full">
        <div className="flex items-center justify-between gap-12">
          <span className="flex items-center justify-center text-[16px] font-avenir font-medium">
            Claim your new number
          </span>
          <button onClick={onClose} className="bg-black h-[40px] w-[40px] rounded-full p-2">
            <FontAwesomeIcon icon={faX} className="text-[14px] text-white" />
          </button>
        </div>
        <FormikWrapper<{ areaCode: number | null }>
          initialValues={initialValuesLocation}
          validationSchema={areaCodeSchema}
          onSubmit={handleSubmitAddress}
        >
          {({ dirty }) => <FormFieldsLocation dirty={dirty} />}
        </FormikWrapper>
      </div>
    );
  };

  const copyToClipboard = (phoneNumber: string) => {
    navigator.clipboard.writeText(phoneNumber).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const openServicePhone = () => {
    openMuiModal(<NoServicePhone onClose={closeMuiModal} />, 3);
  };

  return (
    <div className={`flex flex-col h-full `}>
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full h-auto items-start pl-4">
          <span className="text-[16px] font-avenir text-white">
            Customize your communication settings
          </span>
        </div>
        <div className="flex w-full h-auto items-start px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden"
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('phone')}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center">{<></>}</div>
                <div className="flex pl-4 justify-between items-end w-full">
                  <div className="flex w-full">
                    <span className="font-avenir leading-6 text-[16px] text-[#727272]">
                      Your custom AI Phone #
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={isPhoneExpanded} unmountOnExit>
              <CardContent>
                {userServicePhone?.phone_number ? (
                  <>
                    <div className="flex items-center gap-x-1">
                      <div className="h-12 px-[18px] rounded-full bg-black-alt flex items-center justify-center gap-[10px] text-[14px] text-gray-modal">
                        <FontAwesomeIcon icon={faPhone} />

                        <p>{formatPhoneNumber(userServicePhone?.phone_number)}</p>
                      </div>
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600 ml-4 flex items-center justify-center h-12 w-12 rounded-full bg-[#E5E8E8]"
                        onClick={() => copyToClipboard(userServicePhone?.phone_number)}
                        title="Copy full URL"
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          size="xl"
                          color="black"
                          className="text-[12px]"
                        />
                      </button>
                      {copied && (
                        <span className="text-xs text-[#038164] font-inter ml-2">Copied!</span>
                      )}
                    </div>
                    {userServicePhone?.external_status !== 'registered' && (
                      <div>
                        <div className="h-12 rounded-full px-[10px] inline-flex items-center">
                          <p className="text-sm text-gray-400 text-left break-words">
                            Your Phone Number is Pending
                            <svg
                              className="ml-2 inline-block align-top cursor-pointer"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              //   onClick={() => handleModal('unregisteredPhoneNumber')}
                            >
                              <circle cx="12" cy="12" r="12" fill="#DCDDD7" />
                              <text
                                x="12"
                                y="16"
                                textAnchor="middle"
                                fontSize="16"
                                fontWeight="bold"
                                fill="#000"
                              >
                                i
                              </text>
                            </svg>
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <button
                    onClick={openServicePhone}
                    className="w-max h-12 px-[18px] rounded-full bg-black-alt flex items-center gap-[10px] text-[14px] text-gray-modal mb-10"
                  >
                    <p>Get my new number</p>
                    <p>|</p>
                    <svg
                      width="9"
                      height="13"
                      viewBox="0 0 9 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.125 0.5C7.94531 0.5 8.625 1.17969 8.625 2V11C8.625 11.8438 7.94531 12.5 7.125 12.5H1.875C1.03125 12.5 0.375 11.8438 0.375 11V2C0.375 1.17969 1.03125 0.5 1.875 0.5H7.125ZM7.5 11V2C7.5 1.8125 7.3125 1.625 7.125 1.625H1.875C1.66406 1.625 1.5 1.8125 1.5 2V11C1.5 11.2109 1.66406 11.375 1.875 11.375H7.125C7.3125 11.375 7.5 11.2109 7.5 11ZM5.25 9.875C5.4375 9.875 5.625 10.0625 5.625 10.25C5.625 10.4609 5.4375 10.625 5.25 10.625H3.75C3.53906 10.625 3.375 10.4609 3.375 10.25C3.375 10.0625 3.53906 9.875 3.75 9.875H5.25Z"
                        fill="#DCDDD7"
                      />
                    </svg>
                  </button>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </div>

        {userServicePhone?.agent_id && (
          <>
            <div className="flex w-full h-auto items-start pl-4 pt-6">
              <span className="text-[16px] font-avenir text-white">Select your AI Agent</span>
            </div>
            <div className="flex w-full h-full items-start px-1 pt-4">
              <AgentCardList current_agent_id={userServicePhone?.agent_id || ''} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
