import { FC, useContext } from 'react';
import { AppContext } from '../../contexts/appContext';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../contexts/modalContext';

interface IProps {
  modal?: boolean;
  pageTitle?: string;
}

const SettingsHeader: FC<IProps> = ({ modal, pageTitle }) => {
  const { toggleMenu } = useContext(AppContext);
  const { closeModal } = useModal();
  const history = useNavigate();

  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center gap-3">
        <button
          onClick={toggleMenu}
          className="md:hidden bg-white rounded-full h-12 w-12 flex items-center justify-center"
        >
          <svg
            width="11"
            height="9"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.499878 0.5625C0.499878 0.257812 0.734253 0 1.06238 0H10.4374C10.7421 0 10.9999 0.257812 10.9999 0.5625C10.9999 0.890625 10.7421 1.125 10.4374 1.125H1.06238C0.734253 1.125 0.499878 0.890625 0.499878 0.5625ZM0.499878 4.3125C0.499878 4.00781 0.734253 3.75 1.06238 3.75H10.4374C10.7421 3.75 10.9999 4.00781 10.9999 4.3125C10.9999 4.64062 10.7421 4.875 10.4374 4.875H1.06238C0.734253 4.875 0.499878 4.64062 0.499878 4.3125ZM10.4374 8.625H1.06238C0.734253 8.625 0.499878 8.39062 0.499878 8.0625C0.499878 7.75781 0.734253 7.5 1.06238 7.5H10.4374C10.7421 7.5 10.9999 7.75781 10.9999 8.0625C10.9999 8.39062 10.7421 8.625 10.4374 8.625Z"
              fill="#333333"
            />
          </svg>
        </button>
        <button
          onClick={toggleMenu}
          className="hidden bg-white rounded-full h-12 w-20 md:flex items-center justify-center px-2"
        >
          <span className="text-xs mr-2">Menu</span>
          <svg
            width="11"
            height="9"
            viewBox="0 0 11 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0.75C0 0.351562 0.328125 0 0.75 0H9.75C10.1484 0 10.5 0.351562 10.5 0.75C10.5 1.17188 10.1484 1.5 9.75 1.5H0.75C0.328125 1.5 0 1.17188 0 0.75ZM0 4.5C0 4.10156 0.328125 3.75 0.75 3.75H6.75C7.14844 3.75 7.5 4.10156 7.5 4.5C7.5 4.92188 7.14844 5.25 6.75 5.25H0.75C0.328125 5.25 0 4.92188 0 4.5ZM4.5 8.25C4.5 8.67188 4.14844 9 3.75 9H0.75C0.328125 9 0 8.67188 0 8.25C0 7.85156 0.328125 7.5 0.75 7.5H3.75C4.14844 7.5 4.5 7.85156 4.5 8.25Z"
              fill="#303030"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            if (modal) {
              closeModal();
            } else {
              history(-1);
            }
          }}
          className="bg-white rounded-full h-12 w-12 flex items-center justify-center"
        >
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.32812 10.3984L1.03906 5.92188C0.921875 5.78125 0.875 5.64062 0.875 5.5C0.875 5.38281 0.921875 5.24219 1.01562 5.125L5.30469 0.648438C5.51562 0.414062 5.89062 0.414062 6.10156 0.625C6.33594 0.835938 6.33594 1.1875 6.125 1.42188L2.21094 5.5L6.14844 9.625C6.35938 9.83594 6.35938 10.2109 6.125 10.4219C5.91406 10.6328 5.53906 10.6328 5.32812 10.3984Z"
              fill="#333333"
            />
          </svg>
        </button>
      </div>
      <div className="flex items-center gap-3">
        <div className="flex w-[175px] h-12 rounded-full bg-white p-[2px]">
          <div className="h-full w-[130px] min-w-[130px] rounded-full bg-black px-4 flex items-center justify-between">
            <p className="text-white text-[12px] font-avenir font-medium">
              {pageTitle || 'Settings'}
            </p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 6C1.82812 6 1.5 6.35156 1.5 6.75C1.5 7.17188 1.82812 7.5 2.25 7.5C2.64844 7.5 3 7.17188 3 6.75C3 6.35156 2.64844 6 2.25 6ZM4.35938 6H11.25C11.6484 6 12 6.35156 12 6.75C12 7.17188 11.6484 7.5 11.25 7.5H4.35938C4.05469 8.39062 3.21094 9 2.25 9C1.00781 9 0 7.99219 0 6.75C0 5.50781 1.00781 4.5 2.25 4.5C3.21094 4.5 4.05469 5.13281 4.35938 6ZM9 2.25C9 2.67188 9.32812 3 9.75 3C10.1484 3 10.5 2.67188 10.5 2.25C10.5 1.85156 10.1484 1.5 9.75 1.5C9.32812 1.5 9 1.85156 9 2.25ZM7.61719 1.5C7.92188 0.632812 8.76562 0 9.75 0C10.9922 0 12 1.00781 12 2.25C12 3.49219 10.9922 4.5 9.75 4.5C8.76562 4.5 7.92188 3.89062 7.61719 3H0.75C0.328125 3 0 2.67188 0 2.25C0 1.85156 0.328125 1.5 0.75 1.5H7.61719Z"
                fill="white"
              />
            </svg>
          </div>
          <button onClick={() => history('/')} className="w-full flex items-center justify-center">
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2266 6.5C14.2266 6.92188 13.875 7.25 13.4766 7.25H12.7266L12.75 11C12.75 11.0703 12.75 11.1406 12.75 11.1875V11.5625C12.75 12.1016 12.3281 12.5 11.8125 12.5H11.4375C11.3906 12.5 11.3672 12.5 11.3438 12.5C11.3203 12.5 11.2734 12.5 11.25 12.5H10.5H9.9375C9.39844 12.5 9 12.1016 9 11.5625V11V9.5C9 9.10156 8.64844 8.75 8.25 8.75H6.75C6.32812 8.75 6 9.10156 6 9.5V11V11.5625C6 12.1016 5.57812 12.5 5.0625 12.5H4.5H3.75C3.70312 12.5 3.67969 12.5 3.63281 12.5C3.60938 12.5 3.58594 12.5 3.5625 12.5H3.1875C2.64844 12.5 2.25 12.1016 2.25 11.5625V8.9375C2.25 8.9375 2.25 8.91406 2.25 8.89062V7.25H1.5C1.07812 7.25 0.75 6.92188 0.75 6.5C0.75 6.28906 0.820312 6.10156 0.984375 5.9375L6.98438 0.6875C7.14844 0.523438 7.33594 0.5 7.5 0.5C7.66406 0.5 7.85156 0.546875 7.99219 0.664062L13.9688 5.9375C14.1562 6.10156 14.25 6.28906 14.2266 6.5Z"
                fill="#303030"
              />
            </svg>
          </button>
        </div>
        <button
          onClick={() => history('/search')}
          className="bg-white rounded-full h-12 w-12 flex items-center justify-center"
        >
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8125 11.5391C12.0469 11.7734 12.0469 12.125 11.8125 12.3359C11.7188 12.4531 11.5781 12.5 11.4375 12.5C11.2734 12.5 11.1328 12.4531 11.0156 12.3359L7.875 9.19531C7.03125 9.875 5.97656 10.25 4.85156 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.15625 0.5 4.85156 0.5C7.52344 0.5 9.72656 2.70312 9.72656 5.375C9.72656 6.52344 9.35156 7.57812 8.67188 8.39844L11.8125 11.5391ZM1.125 5.375C1.125 7.46094 2.78906 9.125 4.875 9.125C6.9375 9.125 8.625 7.46094 8.625 5.375C8.625 3.3125 6.9375 1.625 4.875 1.625C2.78906 1.625 1.125 3.3125 1.125 5.375Z"
              fill="#333333"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SettingsHeader;
