import { useState, useEffect } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import * as yup from 'yup';

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue);
  const [emailIsValid, setEmailIsValid] = useState(true);

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    });

    if (email?.length === 0) {
      setEmailIsValid(true);
      return;
    }

    const isValid = emailSchema.isValidSync({ email });

    setEmailIsValid(isValid);
  }, [email]);

  return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  useEffect(() => {
    const passwordSchema = yup.object().shape({
      password: yup
        .string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /[^A-Za-z0-9]/,
          'Password must contain at least one special character'
        )
        .required('Password is required'),
    });

    if (password?.length === 0) {
      setPasswordIsValid(true);
      return;
    }

    const isValid = passwordSchema.isValidSync({ password });

    setPasswordIsValid(isValid);
  }, [password]);

  return { password, setPassword, passwordIsValid };
};

export const useValidPhone = (initialValue: string) => {
  const [phone, setPhone] = useState(initialValue);
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(phone, 'US');

    if (phoneNumber && phoneNumber.isValid()) {
      setPhoneIsValid(true);
    } else {
      setPhoneIsValid(false);
    }
  }, [phone]); // Depend on phone state to trigger validation

  return { phone, setPhone, phoneIsValid };
};

export const useValidFirstName = (initialValue: string) => {
  const [firstName, setFirstName] = useState(initialValue);
  const [firstNameIsValid, setfirstNameIsValid] = useState(true);

  useEffect(() => {
    if (firstName?.length !== 0) {
      setfirstNameIsValid(true);
      return;
    }

    setfirstNameIsValid(false);
  }, [firstName]);

  return { firstName, setFirstName, firstNameIsValid };
};

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    });

    if (code?.length === 0) {
      setCodeIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ code });

    setCodeIsValid(isValid);
  }, [code]);

  return { code, setCode, codeIsValid };
};
