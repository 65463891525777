import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Paper } from '@mui/material';
import { EventType } from '@book-nestor-monorepo/shared-types';

interface SearchAutocompleteServicesProps {
  services: EventType[];
  label: string;
  onSelectService?: (service: EventType | null) => void;
}

export const SearchAutocompleteServices: React.FC<SearchAutocompleteServicesProps> = ({
  services,
  label,
  onSelectService,
}) => {
  const [value, setValue] = useState<EventType | null>(null);

  useEffect(() => {
    if (services.length > 0 && !value) {
      setValue(services[0]);
      if (onSelectService) {
        onSelectService(services[0]);
      }
    }
  }, [services, value, onSelectService]);

  return (
    <Autocomplete
      options={services}
      fullWidth
      autoComplete
      includeInputInList
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          sx={{
            '& .MuiInputBase-root': {
              fontFamily: 'Avenir, sans-serif',
              fontSize: '24px',
              color: 'black',
            },
            '& .MuiInputLabel-root': {
              fontFamily: 'Avenir, sans-serif',
              fontSize: '18px',
              color: 'black',
              '&.Mui-focused': {
                color: 'black',
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: 'black',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: 'black',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'black',
            },
          }}
        />
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => (
        <li
          {...props}
          className="flex items-center w-full font-avenir text-[18px] text-black px-4 py-2 hover:bg-gray-100 overflow-hidden cursor-pointer"
        >
          <div className="flex-shrink-0 mr-2 truncate">{option.title}</div>
          <div className="flex-shrink truncate text-[12px] text-[#909090] font-inter">
            ${option.price} • {option.duration_mins} mins
          </div>
        </li>
      )}
      PaperComponent={({ children }) => (
        <Paper style={{ backgroundColor: '#F5F5F7' }}>{children}</Paper>
      )}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (onSelectService) {
          onSelectService(newValue);
        }
      }}
    />
  );
};
