import { EventCategory } from '@book-nestor-monorepo/shared-types';
import { Dispatch, FC, SetStateAction } from 'react';

interface IProps {
  eventCategories: EventCategory[];
  newCategoryName: string;
  setNewCategoryName: Dispatch<SetStateAction<string>>;
  handleAddCategory: () => void;
  handleDeleteCategory: (id: string) => void;
}

const CategoriesBox: FC<IProps> = ({
  eventCategories,
  handleDeleteCategory,
  handleAddCategory,
  newCategoryName,
  setNewCategoryName,
}) => {
  return (
    <div className="relative mt-7 first:mt-0">
      <h4 className="font-medium">Categories</h4>
      <div className="flex gap-2 mt-[22px]">
        <input
          placeholder="Category Name:"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          className="w-full px-[18px] focus:outline-none text-[14px] max-w-[292px] h-12 rounded-full bg-gray-alt border-0 placeholder:text-[#C7C7C2] text-black-alt"
        />

        <button
          onClick={handleAddCategory}
          className="w-12 min-w-12 h-12 rounded-full flex items-center justify-center bg-black-alt"
        >
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 0.875V4.25H9.875C10.2734 4.25 10.625 4.60156 10.625 5C10.625 5.42188 10.2734 5.75 9.875 5.75H6.5V9.125C6.5 9.54688 6.14844 9.875 5.75 9.875C5.32812 9.875 5 9.54688 5 9.125V5.75H1.625C1.20312 5.75 0.875 5.42188 0.875 5C0.875 4.60156 1.20312 4.25 1.625 4.25H5V0.875C5 0.476562 5.32812 0.125 5.75 0.125C6.14844 0.125 6.5 0.476562 6.5 0.875Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-wrap gap-[9px] mt-4">
        {eventCategories.map(({ name, id }) => (
          <div
            key={id}
            className="h-12 bg-black-alt rounded-full px-[18px] flex items-center gap-[10px]"
          >
            <p className="text-white font-[14px]">{name}</p>
            <button onClick={() => handleDeleteCategory(id!)}>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.8125 1.49219L6.46094 5.5L9.8125 9.53125C10.0703 9.85938 10.0469 10.3281 9.71875 10.5859C9.39062 10.8438 8.92188 10.8203 8.66406 10.4922L5.5 6.67188L2.3125 10.4922C2.05469 10.8203 1.58594 10.8438 1.25781 10.5859C0.929688 10.3281 0.90625 9.85938 1.16406 9.53125L4.51562 5.5L1.16406 1.49219C0.90625 1.16406 0.929688 0.695312 1.25781 0.4375C1.58594 0.179688 2.05469 0.203125 2.3125 0.53125L5.5 4.32812L8.66406 0.53125C8.92188 0.203125 9.39062 0.179688 9.71875 0.4375C10.0469 0.695312 10.0703 1.16406 9.8125 1.49219Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesBox;
