import { Contact } from '@book-nestor-monorepo/shared-types';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import authContext, { AuthContext } from '../../../contexts/authContext';
import { ActionTypes, StoreContext } from '../../../contexts/storeContext';
import { createContact, updateContact } from '../../../libs/services/contacts';
import { SaveButton } from '../../formLibrary/formSaveButton';
import PrimaryTextField from '../../formLibrary/primaryTextInput';
import { ConfirmDeleteButton } from '../../formLibrary/confirmDeleteButton';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { ContactConflictModal } from '../../app/v2/409ContactConflictModal';

export const ContactDetailCard = ({
  contact,
  onSuccess,
  onDelete,
}: {
  contact?: Contact;
  onSuccess?: (contactId?: string) => void;
  onDelete?: () => void;
}) => {
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { dispatch } = useContext(StoreContext);
  const authContext = useContext(AuthContext);
  const [isRegular, setIsRegular] = useState(contact?.is_regular || false);
  const [isFavorite, setIsFavorite] = useState(contact?.is_favorite || false);
  const [isDifficult, setIsDifficult] = useState(contact?.is_difficult || false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('First name is required'),
    last_name: Yup.string(),
    email: Yup.string()
      .matches(/.+@.+\..+/, 'Invalid email address')
      .nullable(),
    street_address: Yup.string(),
    birth_date: Yup.string().nullable(),
    phone_number: Yup.string().nullable(),
    payment_customer_id: Yup.string().nullable(),
    is_favorite: Yup.boolean(),
    is_regular: Yup.boolean(),
    is_difficult: Yup.boolean(),
  });
  const formik = useFormik({
    initialValues: {
      name: contact?.name ?? '',
      last_name: contact?.last_name ?? '',
      email: contact?.email ?? '',
      street_address: contact?.street_address ?? '',
      birth_date: contact?.birth_date ?? '',
      phone_number: contact?.phone_number ?? '',
      payment_customer_id: contact?.payment_customer_id ?? '',
      is_favorite: contact?.is_favorite ?? false,
      is_regular: contact?.is_regular ?? false,
      is_difficult: contact?.is_difficult ?? false,
    },
    validationSchema,
    onSubmit: async (values) => {
      let createdContact;
      if (contact) {
        const updatedContact = {
          ...contact,
          ...values,
        };
        try {
          await updateContact(authContext.user?.id as string, updatedContact);
          dispatch({ type: ActionTypes.UPDATE_CONTACT, payload: updatedContact });
          if (onSuccess) {
            onSuccess(contact?.id);
          }
        } catch (error: any) {
          console.error('Error updating contact', error);
          openMuiModal(
            <ContactConflictModal
              onClose={closeMuiModal}
              title="Error updating contact"
              message={error?.message}
              existingContactId={error?.existingContactId}
            />,
          );
        }
      } else {
        try {
          createdContact = await createContact(authContext.user?.id as string, { ...values });
          dispatch({ type: ActionTypes.ADD_CONTACT, payload: createdContact });
          if (onSuccess) {
            onSuccess(createdContact.id);
          }
        } catch (error: any) {
          console.error('Error creating contact', error);
          openMuiModal(
            <ContactConflictModal
              onClose={closeMuiModal}
              title="Error creating contact"
              message={error?.message}
              existingContactId={error?.existingContactId}
            />,
          );
        }
      }
    },
  });

  const handleDelete = async () => {
    if (onDelete) {
      await onDelete();
    }
  };

  return (
    <div className="flex flex-col w-full">
      {!contact && (
        <div className="flex w-full">
          <span className="text-[14px] font-avenir">New Contact</span>
        </div>
      )}
      <div className="flex flex-row gap-1 items-center justify-end">
        <div className="flex flex-row gap-1 items-center justify-center">
          {contact && <ConfirmDeleteButton onClick={handleDelete} />}
          <SaveButton
            onClick={async () => {
              if (formik.isValid) {
                await formik.submitForm();
              }
            }}
            disabled={!formik.isValid}
            bgColor="[#000000]"
            color="[#FFFFFF]"
          />
        </div>
      </div>
      <div className="flex flex-col w-full pt-2">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col w-full h-full gap-2">
            <div className="flex gap-2">
              <PrimaryTextField
                label="First Name"
                color="primary"
                type="text"
                fullWidth={true}
                variant="filled"
                error={formik.touched.name && formik.errors.name ? true : false}
                required={true}
                {...formik.getFieldProps('name')}
              />
              <PrimaryTextField
                label="Last Name"
                color="primary"
                type="text"
                fullWidth={true}
                variant="filled"
                {...formik.getFieldProps('last_name')}
              />
            </div>
            <div className="flex gap-2">
              <InputMask
                mask="999 999-9999"
                maskChar="_"
                minLength={10}
                {...formik.getFieldProps('phone_number')}
              >
                <PrimaryTextField
                  label="Mobile"
                  type="tel"
                  variant="filled"
                  fullWidth={true}
                  error={formik.touched.phone_number && formik.errors.phone_number ? true : false}
                  {...formik.getFieldProps('phone_number')}
                />
              </InputMask>
              <InputMask mask="99/99/9999" maskChar="_" {...formik.getFieldProps('birth_date')}>
                <PrimaryTextField
                  label="Birth Date"
                  variant="filled"
                  color="primary"
                  type="text"
                  fullWidth={true}
                  {...formik.getFieldProps('birth_date')}
                />
              </InputMask>
            </div>
            <div className="flex gap-2">
              <PrimaryTextField
                label="Email"
                color="primary"
                variant="filled"
                fullWidth={true}
                type="text"
                error={formik.touched.email && formik.errors.email ? true : false}
                {...formik.getFieldProps('email')}
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-xs mt-1">{formik.errors.email}</div>
            )}
            <div className="flex gap-2 w-full">
              <PrimaryTextField
                label="Address"
                color="primary"
                variant="filled"
                fullWidth={true}
                multiline={true}
                type="text"
                {...formik.getFieldProps('street_address')}
              />
            </div>

            <div className="mt-6 w-full">
              <label className="font-avenir text-sm capitalize text-black-alt text-left">
                Client Tags:
              </label>
              <div className="flex flex-wrap gap-1 mt-2">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    formik.setFieldValue('is_regular', !isRegular);
                    setIsRegular(!isRegular);
                  }}
                  className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                    isRegular ? 'bg-black-alt text-white' : 'bg-red'
                  }`}
                >
                  Regular
                </button>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    formik.setFieldValue('is_favorite', !isFavorite);
                    setIsFavorite(!isFavorite);
                  }}
                  className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                    isFavorite ? 'bg-black-alt text-white' : 'bg-red'
                  }`}
                >
                  Favorites
                </button>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    formik.setFieldValue('is_difficult', !isDifficult);
                    setIsDifficult(!isDifficult);
                  }}
                  className={`h-12 flex items-center px-[18px] border border-black-alt rounded-full text-sm font-medium ${
                    isDifficult ? 'bg-black-alt text-white' : 'bg-red'
                  }`}
                >
                  Difficult
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
