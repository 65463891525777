import React, { useState } from 'react';
import { MobileMenuV2 } from '../../../components/v2/Menu/MobileMenu';
import { LayoutProps } from '../../../models';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';

export const MobileLayout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { bgColor } = useAppExperience();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`bg-[${bgColor}] flex justify-center items-center h-screen w-screen overflow-y-scroll hide-scrollbar`}
    >
      <div className="w-full h-full flex flex-col overflow-hidden max-w-screen-2xl max-h-[1036px] portrait:max-h-screen">
        {/* Header */}
        <div className="w-full flex-shrink-0">
          <MobileMenuV2 toggleMenu={toggleDrawer} isOpen={isOpen} />
        </div>
        {/* Main content area */}
        <div className="flex-1 overflow-hidden">
          {/* Content */}
          <main className="h-full overflow-y-scroll hide-scrollbar">
            <div className="w-full h-full max-w-screen-2xl mx-auto">
              {/* Content container */}
              <div
                className={`w-full h-full bg-[${bgColor}] px-1 pt-1 rounded-2xl overflow-y-scroll hide-scrollbar`}
              >
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
