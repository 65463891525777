import { EventType } from '@book-nestor-monorepo/shared-types';
import { environment } from '../../../environments/environment';
import { Link, useParams } from 'react-router-dom';

interface ServicesTabProps {
  eventTypes: EventType[];
  contactId?: string;
}

const ServicesTab = ({ eventTypes, contactId }: ServicesTabProps) => {
  const { userSlug } = useParams<{ userSlug: string }>();
  const imageUrl = (eventType: EventType) => {
    return eventType.image_key ? `${environment.s3.bucketUrl}/${eventType.image_key}` : null;
  };
  const eventTypesToDisplay =
    eventTypes.length > 0
      ? eventTypes
      : [
          {
            title: 'Service Name',
            price: 0,
            duration_mins: 0,
            description: 'Service Description',
            categories: [],
            minimum_booking_notice_mins: 10,
          },
        ];
  return (
    <div className="py-[40px]">
      <h4 className="text-2xl ml-[24px] text-[#211D1D]">Services</h4>
      <div className="mt-[31px] pl-[24px] flex w-full items-center overflow-y-hidden overflow-x-auto hide-scrollbar">
        {eventTypesToDisplay.map((eventType, index) => (
          <Link
            to={`${environment.baseBookingUrl}/${userSlug}/${eventType.slug}${
              contactId ? `?contactId=${contactId}` : ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {imageUrl(eventType) ? (
              <div
                key={index}
                className={`relative w-[170px] min-w-[170px] h-[240px]  bg-center bg-no-repeat bg-cover rounded-[16px] justify-between items-center px-2 text-white first:ml-0 last:mr-4 ml-4`}
                style={{ backgroundImage: `url(${imageUrl(eventType)})` }}
              >
                <h4 className="font-semibold text-2xl mt-[20px] pt-2">${eventType.price} </h4>
                <p>{eventType.duration_mins} Mins</p>
                <div className="absolute bottom-4 w-[calc(100%-16px)] px-2 pt-2 pb-3 rounded-[8px] bg-[#ABABAB66] backdrop-blur-sm text-whtie">
                  <h6 className="font-semibold">{eventType.title}</h6>
                  <p className="text-[10px]">{eventType.description}</p>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={`relative w-[170px] min-w-[170px] h-[240px]  bg-cover rounded-[16px] justify-between items-center px-2 text-white first:ml-0 last:mr-4 ml-4 bg-[#CECFCA]`}
              >
                <h4 className="font-semibold text-2xl mt-[20px]">${eventType.price}</h4>
                <p>{eventType.duration_mins} Mins</p>
                <div className="absolute bottom-4 w-[calc(100%-16px)] px-2 pt-2 pb-3 rounded-[8px] bg-[#ABABAB66] backdrop-blur-sm text-whtie">
                  <h6 className="font-semibold">{eventType.title}</h6>
                  <p className="text-[10px]">{eventType.description}</p>
                </div>
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ServicesTab;
