import { SubscriptionPlan } from '@book-nestor-monorepo/shared-types';
import { useEffect, useState } from 'react';

interface ProductContent {
  description: string;
  features: string[];
  footer?: string;
}

const productContents: Record<SubscriptionPlan, ProductContent> = {
  [SubscriptionPlan.BASIC]: {
    description: "All the same goodies for free. Don't let us go broke, pay for PRO.",
    features: [
      'Beautiful booking site',
      'Scheduler app',
      'Contact management',
      'Insights',
      'Payments with Stripe',
    ],
    footer: 'Free Forever',
  },
  [SubscriptionPlan.INACTIVE]: {
    description: "All the same goodies for free. Don't let us go broke, pay for PRO.",
    features: ['Beautiful booking site', 'Scheduler App', 'Insights', 'Payments with Stripe'],
    footer: 'Free Forever',
  },
  [SubscriptionPlan.PRO]: {
    description: 'Ideal for growing businesses.  Includes everything from Basic and more.',
    features: ['Customize booking link', 'Remove Nestor branding', 'Customer reviews'],
  },
  [SubscriptionPlan.STUDIO]: {
    description: 'For individuals and businesses who are looking to scale.',
    features: [
      'Nestor Intelligence booking assistant',
      'Lost booking recovery',
      'Nestor Intelligence contact management',
    ],
  },
};

interface ProductContentProps {
  productType: SubscriptionPlan;
}

export const ProductContent: React.FC<ProductContentProps> = ({ productType }) => {
  const [content, setContent] = useState<ProductContent | null>(null);

  useEffect(() => {
    setContent(productContents[productType]);
  }, [productType]);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <section className="product-content p-6 rounded-2xl shadow-lg bg-white text-gray-800 max-w-sm mx-auto">
      <h2 className="font-bold font-inter text-xl mb-2 text-center capitalize">{productType}</h2>
      <p className="text-[16px] font-avenir  pb-4 text-center">{content.description}</p>
      <ul className="space-y-2">
        {content.features.map((feature, index) => (
          <li className="list-disc list-inside text-[14px] font-avenir" key={index}>
            {feature}
          </li>
        ))}
      </ul>
      {content.footer && (
        <p className="text-[16px] pt-4 text-center font-semibold font-inter">{content.footer}</p>
      )}
    </section>
  );
};
