import { MenuItem } from '../../../components/v2/DesktopMenuDrawer';

import {
  faBrowser,
  faCalendarPlus,
  faHouse,
  faSliders,
  faUsersLine,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getLeftMenuItems = (
  navigate: (path: string) => void,
  bookingLink: (() => string) | undefined,
): MenuItem[] => [
  {
    icon: () => <FontAwesomeIcon icon={faHouse} className="text-[16px]" />,
    label: 'Home',
    action: () => navigate('/'),
  },
  {
    icon: () => <FontAwesomeIcon icon={faCalendarPlus} className="text-[16px]" />,
    label: 'Bookings',
    action: () => navigate('/bookings'),
  },
  {
    icon: () => <FontAwesomeIcon icon={faUsersLine} className="text-[16px]" />,
    label: 'Clients',
    action: () => navigate('/contacts'),
  },
  {
    label: 'Divider',
  },
  {
    icon: () => <FontAwesomeIcon icon={faSliders} className="text-[16px]" />,
    label: 'Settings', // TODO: change this to 'Settings'
  },
  {
    icon: () => <FontAwesomeIcon icon={faBrowser} className="text-[16px]" />,
    label: 'Client Booking Site', // TODO: change this to 'Settings'
    action: () => bookingLink && window.open(bookingLink(), '_blank'),
  },
];
