import {
  Event,
  Insight,
  InsightsFilters,
  InsightsMetricType,
  ValueType,
} from '@book-nestor-monorepo/shared-types';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { fetchAuthSession } from 'aws-amplify/auth';

export const incrementSiteVisit = async (page_url: string, userId: string): Promise<Event[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/insights/user/${userId}/booking-page-visits`,
      { page_url },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data.items || [];
  } catch (error) {
    throw new Error('Failed to fetch user events');
  }
};

export const getTotalRevenue = async (
  userId: string,
  filters: InsightsFilters,
): Promise<Insight | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/insights/user/${userId}/revenue/total`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch total revenue');
  }
};

export const getAllInsights = async (userId: string, page_url: string): Promise<Insight[]> => {
  try {
    const insightsResponse = [];
    const session = await fetchAuthSession();
    let totalBookingsValue = 0;
    let siteVisitsValue = 0;
    const month = new Date().getMonth() + 1; // JavaScript months are 0-indexed, so +1 to get the correct month
    const year = new Date().getFullYear();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;

    const siteVisits = await axios.get(
      `${apiBaseUrl}/insights/user/${userId}/booking-page-visits?month=${month}&year=${year}&page_url=${page_url}`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    if (siteVisits) {
      siteVisitsValue = siteVisits.data.value;
      insightsResponse.push(siteVisits.data);
    }

    const totalBookings = await axios.get(
      `${apiBaseUrl}/insights/user/${userId}/booking/booking-count?date_range=MTD`,
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    if (totalBookings) {
      totalBookingsValue = totalBookings.data.value;
      insightsResponse.push(totalBookings.data);
    }

    const conversionRateValue = siteVisitsValue
      ? Number(((totalBookingsValue || 0) / siteVisitsValue).toFixed(2))
      : 0;
    const conversion_rate: Insight = {
      value: conversionRateValue,
      metric_type: InsightsMetricType.CONVERSION_RATE,
      date_range: 'MTD',
      month,
      year,
      value_type: ValueType.PERCENTAGE,
    };
    insightsResponse.push(conversion_rate);

    const totalRevenue = await axios.get(`${apiBaseUrl}/insights/user/${userId}/revenue/total`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    if (totalRevenue) {
      insightsResponse.push(totalRevenue.data);
    }

    return insightsResponse;
  } catch (error) {
    throw new Error('Failed to fetch all insights');
  }
};
