import { Booking, BookingStatus, ContactBookingMethod } from '@book-nestor-monorepo/shared-types';
import {
  faArrowRight,
  faChevronRight,
  faChevronDown,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { getBookings } from '../../libs/services/bookings';
import { durationString } from '../../libs/utils/date.util';
import { truncateText } from '../../libs/utils/string.util';
import { Card, CardContent, Collapse, IconButton } from '@mui/material';
import { faX, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useBookings } from '@calcom/atoms';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

export const BookingsPageV2 = () => {
  const [selectedStatus, setSelectedStatus] = useState<TSelectedStatus>(BookingStatus.UPCOMING);
  const [bookings, setBookings] = useState<Record<TSelectedStatus, Booking[]> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState<string | null>(null);

  const handleStatusChange = (status: TSelectedStatus) => {
    setSelectedStatus(status);
  };

  console.log(bookings);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return;
        if (user?.id) {
          const [tokenUpcomingResponse, tokenPastResponse, tokenCancelledResponse] =
            await Promise.all([
              getBookings(user?.id, BookingStatus.UPCOMING),
              getBookings(user?.id, BookingStatus.PAST),
              getBookings(user?.id, BookingStatus.CANCELED),
            ]);

          const sortByStartTime = (bookings: Booking[], reverse = false) => {
            return bookings.sort((a, b) => {
              const diff = new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
              return reverse ? -diff : diff;
            });
          };

          const sortedUpcoming = sortByStartTime(tokenUpcomingResponse);
          const sortedPast = sortByStartTime(tokenPastResponse, true);
          const sortedCancelled = sortByStartTime(tokenCancelledResponse, true);

          setBookings({
            [BookingStatus.UPCOMING]: sortedUpcoming,
            [BookingStatus.PAST]: sortedPast,
            [BookingStatus.CANCELED]: sortedCancelled,
          });

          setBookings({
            [BookingStatus.UPCOMING]: tokenUpcomingResponse,
            [BookingStatus.PAST]: tokenPastResponse,
            [BookingStatus.CANCELED]: tokenCancelledResponse,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [authContext.user]);

  const handleToggleExpand = (bookingId: string) => {
    setExpandedId(expandedId === bookingId ? null : bookingId);
  };

  const createdByString = (booking: Booking) => {
    if (!booking.contact_booking) return 'N/A';
    if (booking.contact_booking.booking_method === ContactBookingMethod.PUBLIC_PAGE) {
      return booking.attendees[0]?.name || 'N/A';
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.AI_AGENT) {
      return `${authContext.servicePhone?.agent?.name} (agent)`;
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.MANUAL_USER) {
      return `${authContext.user?.first_name} (you)`;
    }
    return `${authContext.user?.first_name} (you)` || 'N/A';
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#FFFFFF" />;
  }

  return (
    <div className={`flex flex-col h-auto bg-[#D1DEDD] w-full`}>
      <div className="flex h-full w-full">
        <div className="flex-1 flex flex-col h-full pt-2 ml-0">
          <div className="flex-col ">
            <div className="bg-transparent w-full h-full ">
              <h1 className="text-black-alt text-left pb-4 pl-4 font-avenir text-[16px]">
                Your booking history
              </h1>
              <div className="flex overflow-x-auto mb-8 hide-scrollbar px-2">
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.UPCOMING
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.UPCOMING)}
                >
                  Upcoming
                  <span className="ml-[10px]">{bookings?.upcoming.length}</span>
                </button>
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.PAST
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.PAST)}
                >
                  Past
                  <span className="ml-[10px]">{bookings?.past.length}</span>
                </button>
                <button
                  className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                    selectedStatus === BookingStatus.CANCELED
                      ? 'bg-black-alt text-white'
                      : '!border-black-alt text-black-alt'
                  }`}
                  onClick={() => handleStatusChange(BookingStatus.CANCELED)}
                >
                  Canceled
                  <span className="ml-[10px]">{bookings?.cancelled.length}</span>
                </button>
              </div>
              <div className="overflow-y-auto w-full h-full bg-transparent hide-scrollbar pt-0 px-1 pb-1">
                {bookings?.[selectedStatus]?.length === 0 ? (
                  <div className="text-center text-black">
                    No {selectedStatus.toLowerCase()} bookings...
                  </div>
                ) : (
                  bookings?.[selectedStatus]?.map((booking) => (
                    <Card
                      key={booking.id}
                      sx={{
                        bgcolor: 'white',
                        boxShadow: 'none',
                        borderRadius: '24px',
                        marginBottom: '4px',
                      }}
                    >
                      <div className="flex flex-col p-4">
                        <div className="flex items-start justify-between">
                          <h3 className="font-avenir text-[16px] font-bold text-black-alt text-left leading-6">
                            {truncateText(booking.attendees.map((a) => a.name).join(', '), 50)}
                          </h3>
                          {booking.attendees.length > 1 && (
                            <FontAwesomeIcon
                              icon={faUsers}
                              className="text-[16px] text-black-alt"
                            />
                          )}
                        </div>
                        <div className="flex justify-between items-start pt-1">
                          <h3 className="font-avenir text-[14px] text-black-alt text-left leading-6">
                            {new Date(booking.start_time).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })}
                          </h3>
                          <h3 className="text-[14px] font-avenir text-black-alt">
                            {new Date(booking.start_time).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}{' '}
                            -{' '}
                            {new Date(booking.end_time).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}
                          </h3>
                        </div>

                        <div className="flex gap-2 mt-6 justify-between">
                          <div className="flex gap-2">
                            <div className="h-10 rounded-full bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir capitalize">
                              {booking.event_type.slug}
                            </div>
                            <div className="h-10 rounded-full bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir">
                              {durationString(booking)}
                            </div>
                          </div>

                          <div
                            className="h-12 w-12 rounded-full bg-[#D1DEDD] flex items-center justify-center cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/booking-control/${booking.contact?.id}?bookingId=${booking.id}`,
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="text-[12px] -rotate-45"
                            />
                          </div>
                        </div>

                        <div
                          className="flex justify-end items-center mt-2 cursor-pointer"
                          onClick={() => handleToggleExpand(booking.id)}
                        >
                          <span className="text-[14px] font-avenir text-black pt-[2px]">
                            Details
                          </span>
                          <IconButton className="ml-[-8px]">
                            <FontAwesomeIcon
                              icon={expandedId === booking.id ? faChevronDown : faChevronRight}
                              className="text-[12px] text-black"
                            />
                          </IconButton>
                        </div>

                        <Collapse in={expandedId === booking.id} timeout="auto" unmountOnExit>
                          <CardContent className="border-t mt-4 pt-4" sx={{ paddingX: '0px' }}>
                            {/* Add your expanded content here */}
                            <div className="flex-col w-full h-full">
                              <div className="w-full flex justify-between">
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Has Paid:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={booking.contact_booking?.client_has_paid ? faCheck : faX}
                                    className={
                                      booking.contact_booking?.client_has_paid
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Reminder Sent:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={
                                      booking.contact_booking?.reminder_window_sent?.length || 0 > 0
                                        ? faCheck
                                        : faX
                                    }
                                    className={
                                      booking.contact_booking?.reminder_window_sent?.length || 0 > 0
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Is Difficult:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={booking.contact?.is_difficult ? faCheck : faX}
                                    className={
                                      booking.contact?.is_difficult
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex w-full justify-start items-start pt-8">
                                <label className="text-[16px] font-avenir text-black-alt font-bold mr-2 leading-6">
                                  Created:{' '}
                                </label>
                                <span className="text-[14px] font-avenir text-black-alt leading-6">
                                  {new Date(
                                    booking.contact_booking?.created_at as string,
                                  ).toLocaleTimeString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })}
                                </span>
                              </div>
                              <div className="flex w-full justify-start items-start pt-1">
                                <label className="text-[16px] font-avenir text-black-alt font-bold mr-2 leading-6">
                                  Created By:{' '}
                                </label>
                                <span className="text-[14px] font-avenir text-black-alt leading-6">
                                  {createdByString(booking)}
                                </span>
                              </div>
                            </div>
                          </CardContent>
                        </Collapse>
                      </div>
                    </Card>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
