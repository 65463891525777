import { Booking, User } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { useQuickStart } from '../../contexts/quickStartContext';
import { useBookings } from '../../contexts/v2/bookingsContext';
import { patchContactBooking } from '../../libs/services/contacts';
import { formatClientTime } from '../../libs/utils/date.util';
import LoadingDots from '../loading';
import Appointment from './appointment';
import CalendarDayScroll from './calendarDayScroll';
import SelectedAppointment from './selectedAppointment';
import V2BookingForm from './v2/v2BookingForm';

interface Appointment {
  id: number;
  time: string;
  name: string;
  description: string;
  title: string;
  event_type: {
    id: string;
    slug: string;
    user_id: string;
  };
  paid: boolean;
  start_time: string;
  end_time: string;
  status?: string;
  checked?: boolean;
}

type Dimensions = {
  width: number;
  height: number;
  halfDaySpace: number;
  barHeight: number;
};

interface TodayCalendarType {
  swipeUp: () => void;
  selectedDate: string;
  handleDateSelect: (date: string) => void;
  selectedDateRef: React.MutableRefObject<string>;
}

export default function TodayCalendar({
  swipeUp,
  selectedDate,
  handleDateSelect,
  selectedDateRef,
}: TodayCalendarType) {
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { allTasksCompleted } = useQuickStart();
  const navigate = useNavigate();
  const mainRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);
  const [user, setUser] = useState<User>();
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
    halfDaySpace: 0,
    barHeight: 0,
  });
  const [time, setTime] = useState(formatClientTime());
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null);

  const [appointments, setAppointments] = useState<Booking[]>([]);

  const [nextAppointment, setNextAppointment] = useState<Booking | null>(null);
  const [selectedAppointment, setSelectedAppointment] = useState<Booking | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const { bookings } = useBookings();

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      try {
        setUser(authContext.user);
        const currentMonth = new Date().getMonth(); // 0 - January, 1 - February, etc.
        const filteredBookings = bookings.filter((booking) => {
          const bookingMonth = new Date(booking.start_time).getMonth(); // 0 - January, 1 - February, etc.
          return bookingMonth === currentMonth;
        });
        setAppointments(filteredBookings);
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchBookings();
  }, [bookings]);

  useEffect(() => {
    if (appointments.length > 0) {
      const today = new Date();
      const closestFutureAppointment = appointments.reduce<Booking | null>((closest, current) => {
        const currentDate = new Date(current.start_time);
        if (currentDate >= today && (!closest || currentDate < new Date(closest.start_time))) {
          return current;
        }
        return closest;
      }, null);

      if (closestFutureAppointment) {
        setNextAppointment(closestFutureAppointment);
      }
    }
  }, [appointments]);

  useEffect(() => {
    // Function to find the closest appointment to the current time
    const findCurrentAppointment = (): Booking | null => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      const currentTimeInMinutes = currentHour * 60 + currentMinute;

      // Find the closest appointment on the selected date
      let closestAppointment = null;
      let minDifference = Infinity;

      for (const appointment of appointments) {
        const appointmentDate = new Date(appointment.start_time);
        if (appointmentDate.toISOString().slice(8, 10) === selectedDateRef.current) {
          const appointmentHour = appointmentDate.getHours();
          const appointmentMinute = appointmentDate.getMinutes();
          const appointmentTimeInMinutes = appointmentHour * 60 + appointmentMinute;
          const difference = Math.abs(currentTimeInMinutes - appointmentTimeInMinutes);

          if (appointmentDate > currentDate && difference < minDifference) {
            minDifference = difference;
            closestAppointment = appointment;
            break;
          }
        }
      }

      return closestAppointment;
    };

    // Function to scroll to the selected appointment
    const scrollToSelectedAppointment = (app: Booking) => {
      const selectedAppointmentElement = document.getElementById(`appointment-${app.id}`);
      if (selectedAppointmentElement && listRef.current) {
        const offsetTop = selectedAppointmentElement.offsetTop;
        listRef.current.scrollTo({
          top: offsetTop - (isMobile ? 40 : 120),
          behavior: 'smooth',
        });
      }
    };

    // Only run the selection logic if we have appointments
    if (appointments.length > 0) {
      // Find the current appointment matching the current time
      const currentAppointment = findCurrentAppointment();

      if (currentAppointment) {
        setSelectedAppointment(currentAppointment);
        setSelectedAppointmentId(currentAppointment.id);
        scrollToSelectedAppointment(currentAppointment);
      } else {
        // Find first appointment on selected date if no current appointment
        const firstAppointmentOnSelectedDate = appointments.find(
          (appointment) => appointment.start_time.slice(8, 10) === selectedDateRef.current,
        );

        if (firstAppointmentOnSelectedDate) {
          setSelectedAppointment(firstAppointmentOnSelectedDate);
          setSelectedAppointmentId(firstAppointmentOnSelectedDate.id);
          scrollToSelectedAppointment(firstAppointmentOnSelectedDate);
        } else {
          setSelectedAppointment(null);
          setSelectedAppointmentId(null);
        }
      }
    }
  }, [appointments, selectedDateRef.current, isMobile]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(formatClientTime()); // Update time every second
    }, 1000);

    return () => {
      clearInterval(timerId); // Clear the interval on unmount
    };
  }, []);

  // Observe elements passing through the reference block
  useEffect(() => {
    // Initialize IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const appointmentId = entry.target.getAttribute('data-appointment-id');
          if (!appointmentId || !frameRef.current) return;
          const intersectingAppointment = appointments.find((a) => a.id === appointmentId);

          if (intersectingAppointment?.id) {
            const frameRect = frameRef.current.getBoundingClientRect();
            const appointmentRect = entry.target.getBoundingClientRect();

            // Check if the appointment div intersects with the selected appointment rectangle
            const isIntersecting =
              appointmentRect.top < frameRect.bottom + 30 &&
              appointmentRect.bottom > frameRect.top &&
              appointmentRect.left < frameRect.right &&
              appointmentRect.right > frameRect.left;

            if (isIntersecting) {
              // Handle intersecting appointment logic here
              // For example, update the style or perform some action
              setSelectedAppointment(intersectingAppointment);
            }
          }
        }
      });
    });

    // Observe all appointment elements
    const appointmentElements = document.querySelectorAll<HTMLElement>('.appointment');

    appointmentElements.forEach((element) => {
      observer.observe(element);
    });

    // Observe appointments within the viewport
    const observeAppointments = () => {
      appointmentElements.forEach((element) => {
        observer.observe(element);
      });
    };

    observeAppointments(); // Observe initial appointments

    // Function to handle scroll events
    const handleScroll = () => {
      // Update observer when scrolling
      observer.disconnect();
      observeAppointments();
    };

    // Add scroll event listener to the container
    const container = document.querySelector('.appointments-container');
    container?.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect(); // Clean up observer
    };
  }, [appointments, selectedDate]);

  useLayoutEffect(() => {
    if (mainRef.current) {
      const { clientWidth, scrollHeight } = mainRef.current;
      setDimensions({
        width: clientWidth,
        height: scrollHeight,
        halfDaySpace: scrollHeight / 2,
        barHeight: scrollHeight + 70,
      });
    }
  }, []);

  const selectAppointment = async (appointment: Booking) => {
    setSelectedAppointmentId((prevSelectedAppointmentId) =>
      prevSelectedAppointmentId === appointment.id ? null : appointment.id,
    );

    if (listRef.current && selectedAppointmentId !== appointment.id) {
      const selectedAppointmentElement = document.getElementById(`appointment-${appointment.id}`);
      if (selectedAppointmentElement) {
        const offsetTop = selectedAppointmentElement.offsetTop;
        listRef.current.scrollTo({ top: offsetTop - 30, behavior: 'smooth' });
      }
    }
  };

  const toggleCheckedIn = async (appointment: Booking) => {
    const contact_booking = appointment.contact_booking;
    if (contact_booking) {
      contact_booking.client_checked_in = !contact_booking.client_checked_in;
      try {
        await patchContactBooking(
          authContext.user?.id as string,
          contact_booking.contact_email,
          contact_booking.booking_id,
          { client_checked_in: contact_booking.client_checked_in },
        );
        const updatedAppointment = appointments.find((a) => a.id === appointment.id);
        if (updatedAppointment) {
          const updatedAppointments = appointments.map((a) =>
            a.id === updatedAppointment.id ? updatedAppointment : a,
          );
          setAppointments(updatedAppointments);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  const openBooking = () => {
    if (!allTasksCompleted) {
      navigate('/quick-start');
      return;
    }
    openMuiModal(<V2BookingForm onClose={closeMuiModal} />);
  };

  const todayCalDateSelect = (date: string) => {
    listRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    handleDateSelect(date);
  };

  // ??? How does it work? there is no appointments on the load
  const selectedAppointmentIdx = appointments
    .filter((appointment) => appointment.start_time.slice(8, 10) === selectedDateRef.current)
    .findIndex((app) => app.id === selectedAppointment?.id);

  if (isLoading) {
    return (
      <div className="flex flex-col h-full px-4 py-4 relative h">
        <LoadingDots
          skeletonProps={{
            count: 4,
            height: 70,
            borderRadius: 10,
            baseColor: '#E1E0E1',
            style: { marginBottom: '10px' },
          }}
        />
      </div>
    );
  }

  const handleBookingLinkClick = () => {
    navigate('/booking-mobile', {});
  };

  return (
    <div className="flex flex-col h-full pt-2  relative rounded-bl-3xl rounded-br-3xl">
      <div className="flex flex-row grow gap-2 relative max-h-[800px] " ref={mainRef}>
        <div className="flex flex-col flex-1 w-full relative rounded-bl-3xl rounded-br-3xl">
          <div className="flex flex-row items-center absolute right-0 top-0 md:hidden pt-1">
            <span
              className={`flex items-center justify-center w-12 h-12 cursor-pointer z-20 bg-[#F7F7F7] rounded-full`}
              onClick={swipeUp}
            >
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.88281 2.24219C5.02344 2.38281 5.02344 2.64062 4.88281 2.78125C4.74219 2.92188 4.48438 2.92188 4.34375 2.78125L3.10156 1.53906V9.48438L4.34375 8.24219C4.48438 8.10156 4.74219 8.10156 4.88281 8.24219C5.02344 8.38281 5.02344 8.64062 4.88281 8.78125L3.00781 10.6562C2.86719 10.7969 2.60938 10.7969 2.46875 10.6562L0.59375 8.78125C0.453125 8.64062 0.453125 8.38281 0.59375 8.24219C0.734375 8.10156 0.992188 8.10156 1.13281 8.24219L2.375 9.48438V1.53906L1.13281 2.78125C0.992188 2.92188 0.734375 2.92188 0.59375 2.78125C0.453125 2.64062 0.453125 2.38281 0.59375 2.24219L2.46875 0.367188C2.60938 0.226562 2.86719 0.226562 3.00781 0.367188L4.88281 2.24219ZM13.625 1.375C13.8125 1.375 14 1.5625 14 1.75C14 1.96094 13.8125 2.125 13.625 2.125H6.875C6.66406 2.125 6.5 1.96094 6.5 1.75C6.5 1.5625 6.66406 1.375 6.875 1.375H13.625ZM13.625 5.125C13.8125 5.125 14 5.3125 14 5.5C14 5.71094 13.8125 5.875 13.625 5.875H6.875C6.66406 5.875 6.5 5.71094 6.5 5.5C6.5 5.3125 6.66406 5.125 6.875 5.125H13.625ZM6.5 9.25C6.5 9.0625 6.66406 8.875 6.875 8.875H13.625C13.8125 8.875 14 9.0625 14 9.25C14 9.46094 13.8125 9.625 13.625 9.625H6.875C6.66406 9.625 6.5 9.46094 6.5 9.25Z"
                  fill="#333333"
                />
              </svg>
            </span>
            <span
              className="flex items-center justify-center w-12 h-12 cursor-pointer z-20 bg-[#F7F7F7] rounded-full ml-2"
              onClick={openBooking}
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.625 5.5C10.625 5.82812 10.3672 6.0625 10.0625 6.0625H6.3125V9.8125C6.3125 10.1406 6.05469 10.3984 5.75 10.3984C5.42188 10.3984 5.1875 10.1406 5.1875 9.8125V6.0625H1.4375C1.10938 6.0625 0.875 5.82812 0.875 5.52344C0.875 5.19531 1.10938 4.9375 1.4375 4.9375H5.1875V1.1875C5.1875 0.882812 5.42188 0.648438 5.75 0.648438C6.05469 0.648438 6.3125 0.882812 6.3125 1.1875V4.9375H10.0625C10.3672 4.9375 10.625 5.19531 10.625 5.5Z"
                  fill="#333333"
                />
              </svg>
            </span>
          </div>
          <div className="flex h-auto py-2 bg-white rounded-2xl pb-2 mb-2">
            <CalendarDayScroll onDateSelect={todayCalDateSelect} />
          </div>
          <div className="flex h-full pt-2  bg-white rounded-tl-2xl rounded-tr-2xl ">
            {selectedAppointment && (
              <SelectedAppointment
                appointment={selectedAppointment}
                frameRef={frameRef}
                listRef={listRef}
                handleCheck={(app) => toggleCheckedIn(app)}
                contact={selectedAppointment.contact}
              />
            )}
            {
              <div
                ref={listRef}
                className="appointments-container flex flex-col flex-1 w-full relative overflow-auto no-scrollbar h-full rounded-bl-3xl rounded-br-3xl"
              >
                {appointments.filter(
                  (appointment) => appointment.start_time.slice(8, 10) === selectedDateRef.current,
                ).length > 0 ? (
                  <div className="flex flex-col pb-[168%] pt-20 ">
                    {appointments
                      .filter(
                        (appointment) =>
                          appointment.start_time.slice(8, 10) === selectedDateRef.current,
                      )
                      .map((appointment, idx) => (
                        <Appointment
                          key={appointment.id}
                          appointment={appointment}
                          idx={idx}
                          selectedIdx={selectedAppointmentIdx}
                          onHandleAppointment={(app) => selectAppointment(app)}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-start h-full overflow-none md:mt-20 pt-4">
                    <span className="text-black font-avenir text-2xl">No appointments today</span>
                    <div className="flex md:hidden" onClick={handleBookingLinkClick}>
                      <div className="flex pt-4">
                        <div className="h-[48px] w-[171px] rounded-full bg-[#000000] items-center justify-center text-white flex font-avenir text-[14px]">
                          Create Appointment
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
