import { OnboardingTaskNames } from '@book-nestor-monorepo/shared-types';
import { AvailabilitySettings } from '@calcom/atoms';
import { Card, CardContent, Collapse } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { FormattedTask, useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { patchUser } from '../../libs/services/user';
import { useToast } from '../../contexts/v2/toastContext';

export const AvailabilityV2 = () => {
  const { bgColor } = useAppExperience();
  const { markTaskComplete } = useQuickStart();
  const { user } = useContext(AuthContext);
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    'linked-calendars': true,
    main: true,
  });
  const { showToast } = useToast();

  const toggleCard = (card: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [card]: !prev[card],
    }));
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onboardingContext = queryParams.get('onboardingContext');
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    undefined,
  );
  const { selectedTask, remainingFormattedTasks } = useQuickStart();

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    }
  }, [selectedTask]);

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full">
        <div className="flex w-full h-auto items-start pl-4">
          {onboardingContext && selectedFormattedTask ? (
            <div className="flex flex-col items-start">
              <span className="text-[14px] font-avenir text-white">
                Step {selectedFormattedTask?.number}
              </span>
              <span className="text-[24px] font-inter text-white">
                {selectedFormattedTask?.title}
              </span>
            </div>
          ) : (
            <span className="text-[16px] font-avenir text-white">Your availability settings</span>
          )}
        </div>
        <div className="flex flex-col w-full h-auto items-start gap-y-1 px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden"
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('main')}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center">{<></>}</div>
                <div className="flex pl-4 justify-between items-start w-full">
                  <div className="flex w-full">
                    <span className="font-avenir text-[16px] text-[#727272]">
                      Availability Settings
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={expandedCards['main']} unmountOnExit>
              <CardContent
                sx={{
                  padding: '8px',
                  overflowY: 'scroll',
                  backgroundColor: 'white',
                  '&:last-child': {
                    paddingBottom: '0px',
                  },
                }}
              >
                <AvailabilitySettings
                  customClassNames={{
                    containerClassName: 'editableAvailabilityContainerClassName !px-0 !pt-0 ',
                    ctaClassName: '',
                    editableHeadingClassName:
                      'editableHeadingClassName font-avenir text-black-text text-[25.888px] leading-8 font-semibold px-0',
                    formClassName: 'formClassName px-0',
                    timezoneSelectClassName: 'C px-0',
                    subtitlesClassName: 'subtitlesClassName hidden px-0',
                    scheduleClassNames: {
                      scheduleContainer: 'scheduleContainer px-0',
                      scheduleDay: 'scheduleDay   mb-4 last:mb-0',
                      dayRanges: 'ml-8 px-0',
                      timeRanges: 'timeRanges px-0',
                      labelAndSwitchContainer: 'labelAndSwitchContainer px-0',
                    },
                  }}
                  enableOverrides={true}
                  disableToasts={true}
                  disableEditableHeading={true}
                  onUpdateSuccess={(res: any) => {
                    showToast('Availability updated successfully!', 'success');
                    markTaskComplete(OnboardingTaskNames.verifyAvailability);
                    if (res?.data?.timeZone !== user?.time_zone) {
                      patchUser(user?.id as string, { time_zone: res?.data?.timeZone });
                    }
                  }}
                />
              </CardContent>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
};
