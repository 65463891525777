import { useState } from 'react';
import CalendarDayScroll from '../../components/app/calendarDayScroll';
import Header from '../../components/app/header';
import InsightCards from '../../components/app/insightCards';
import QuickReport from '../../components/app/quickReport';
import SideButtons from '../../components/app/sideButtons';
import Menu from '../../components/menu';
import NestorFlag from '../../components/nestorFlag';
import { useModal } from '../../contexts/modalContext';
import InsightInfoModal from '../../components/modals/insightInfo';
import { InsightsMetricType, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import Modal from '../../components/common/Modal/Modal';
import SettingsHeader from '../../components/app/settingsHeader';

interface ExperimentalAppLayoutTypes {
  children: React.ReactNode;
  insightCardsStyles?: string;
  componentName?: string;
  handleDateSelect?: (date: string) => void;
  className?: string;
  bookings?: boolean;
  pageTitle?: string;
}

export function ExperimentalAppLayout({
  children,
  insightCardsStyles,
  className,
  componentName,
  pageTitle,
  bookings,
  handleDateSelect,
}: ExperimentalAppLayoutTypes) {
  const { isInsightModalOpen, isOpen, toggleInsightModal } = useModal();
  const [infoType, setInfoType] = useState<InsightsMetricType | ''>('');

  const openInfoModal = (type: InsightsMetricType) => {
    toggleInsightModal(true);
    setInfoType(type);
  };

  const closeInfoModal = () => {
    toggleInsightModal(false);
    setInfoType('');
  };

  return (
    <div className={`flex bg-[#e5e6e1] flex-col h-screen md:h-auto md:py-8 ${className}`}>
      <div className="w-full flex flex-1 md:flex-none flex-col md:flex-row md:max-w-7xl mx-auto my-auto md:h-[800px] overflow-hidden relative app-shadow py-0 md:py-4 px-0 md:px-4">
        <div className="flex h-auto">
          <Menu />
        </div>
        <div id="flag" className="hidden lg:block z-10">
          <NestorFlag backgroundColor="bg-blue-status" />
        </div>

        <div className="flex w-full md:w-[50%] items-stretch justify-center px-2">
          <div className="w-full flex flex-col h-full">
            {bookings ? <SettingsHeader pageTitle={pageTitle} /> : <Header />}
            <div className="hidden md:flex flex-col w-full h-auto pt-8 pb-4">
              <div className="flex flex-col w-full h-auto ">
                {handleDateSelect && <CalendarDayScroll onDateSelect={handleDateSelect} />}
              </div>
            </div>
            <div className="hidden md:flex flex-col rounded-3xl bg-light-gray flex-grow">
              <QuickReport />
            </div>
          </div>
        </div>

        <div
          className={`flex flex-row min-h-[316px] md:max-h-full w-full md:w-[208px] overflow-hidden px-2 pb-3 ${insightCardsStyles}`}
        >
          <div className="flex flex-row md:flex-col w-full h-[304px] md:h-full gap-2">
            <InsightCards openInfoModal={openInfoModal} />
            <SideButtons />
          </div>
        </div>

        <div className="flex min-h-[500px] h-full md:h-auto w-full md:w-[35%] items-stretch justify-center md:px-2 overflow-hidden md:relative">
          {children}
        </div>
        <div className="">
          <InsightInfoModal type={infoType} open={isInsightModalOpen} onClose={closeInfoModal} />
          {isOpen && <Modal />}
        </div>
      </div>
    </div>
  );
}
