import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../authContext';
import { getBookings } from '../../libs/services/bookings';
import { Booking, Contact } from '@book-nestor-monorepo/shared-types';

interface BookingsContextType {
  isLoading: boolean;
  bookings: Booking[];
  fetchUpcomingBookings: (forceRefresh?: boolean) => Promise<Booking[]>;
}

interface BookingsProviderProps {
  children: ReactNode;
}

const BookingsContext = createContext<BookingsContextType | undefined>(undefined);

export const useBookings = (): BookingsContextType => {
  const context = useContext(BookingsContext);

  if (!context) {
    throw new Error('useBookings must be used within a BookingsProvider');
  }

  return context;
};

export const BookingsProvider: FunctionComponent<BookingsProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const bookingsFetched = useRef<boolean>(false);

  const handleFetchUpcomingBookings = useCallback(
    async (forceRefresh = false): Promise<Booking[]> => {
      if (bookingsFetched.current && !forceRefresh) {
        return bookings;
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getBookings(user.id as string);
        setBookings(result);
        bookingsFetched.current = true;
        return result;
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [bookings, authContext.user],
  );

  useEffect(() => {
    handleFetchUpcomingBookings();
  }, [handleFetchUpcomingBookings]);

  return (
    <BookingsContext.Provider
      value={{
        isLoading,
        bookings,
        fetchUpcomingBookings: handleFetchUpcomingBookings,
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
};
