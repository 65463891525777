import React, { useEffect } from 'react';
import { OnboardingModal } from '../../../components/app/OnboardingModal';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { useQuickStart } from '../../../contexts/quickStartContext';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { useDrawer } from '../../../contexts/v2/rightDrawerContext';
import { LayoutProps } from '../../../models';
import { OnboardingDrawer } from '../../v2/right-drawer-components/onboarding-drawer';
import { DesktopLayout } from './desktop-layout';
import { MobileLayout } from './mobile-layout';
import { TabletMobileLayout } from './tablet-mobile-layout';

const AuthenticatedAppLayout: React.FC<LayoutProps> = ({ children }) => {
  const { isDesktop, isTablet } = useAppExperience();
  const { setDrawerContent, toggleDrawer } = useDrawer();
  const { allTasksCompleted, completedFormattedTasks, isLoading } = useQuickStart();
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleOnboardingClick = () => {
    setDrawerContent(<OnboardingDrawer />);
    toggleDrawer();
    closeMuiModal();
  };

  useEffect(() => {
    if (completedFormattedTasks.length <= 1 && !isLoading && !allTasksCompleted) {
      handleModalOpen();
    }
  }, [completedFormattedTasks, isLoading]);

  const handleModalOpen = () => {
    openMuiModal(
      <OnboardingModal onClose={closeMuiModal} onViewOnboarding={handleOnboardingClick} />,
      3,
    );
  };

  return (
    <div className="w-full h-full items-center justify-center text-center font-avenir">
      {isDesktop ? (
        <DesktopLayout>{children}</DesktopLayout>
      ) : isTablet ? (
        <TabletMobileLayout>{children}</TabletMobileLayout>
      ) : (
        <MobileLayout>{children}</MobileLayout>
      )}
      {!allTasksCompleted && (
        <div
          onClick={handleOnboardingClick}
          className="fixed bottom-4 right-4 w-12 h-12 sm:w-14 sm:h-14 bg-black rounded-full z-50 flex items-center justify-center cursor-pointer"
        >
          <span className="text-white pt-[1px]" role="img" aria-label="Quick Start Guide">
            ✅
          </span>
        </div>
      )}
    </div>
  );
};

export default AuthenticatedAppLayout;
