import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#222222',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#222222',
    fontSize: '10px',
    fontFamily: 'Inter',
  },
}));

const AgentDashboardPopover: React.FC = () => {
  const tooltipText = 'Data from the last 24 hours';
  const { isDesktop } = useAppExperience();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {isDesktop ? (
        <LightTooltip
          title={tooltipText}
          enterTouchDelay={0} // Immediately opens on touch devices
          leaveTouchDelay={1500} // Delay before closing on mobile to mimic click behavior
          disableFocusListener
          disableTouchListener
          disableHoverListener={false} // Keep hover on for desktop
          onClick={(e) => e.stopPropagation()} // Stops auto-close when clicking on mobile
        >
          <Button sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
            <FontAwesomeIcon icon={faInfoCircle} color="#222222" size="lg" />
          </Button>
        </LightTooltip>
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipText}
            >
              <Button
                onClick={handleTooltipOpen}
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}
              >
                <FontAwesomeIcon icon={faInfoCircle} color="#222222" size="lg" />
              </Button>
            </LightTooltip>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AgentDashboardPopover;
