import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const imageWidth = 142;
const imageGap = 7;
const slideToView = 4;

const BookingSlider = ({ images }: { images: string[] }) => {
  const [activeSlider, setActiveSlider] = useState(0);

  const totalSlides = images.length;
  const sliderTranslate =
    ((imageWidth + imageGap) * activeSlider) % ((imageWidth + imageGap) * totalSlides);

  const handleSlide = (type: string) => {
    if (type === 'next') {
      setActiveSlider((prev) => (prev + 1) % totalSlides);
    } else {
      setActiveSlider((prev) => (prev - 1 + totalSlides) % totalSlides);
    }
  };

  // Duplicate the images array to create a seamless loop
  const loopedImages = [...images, ...images];

  return (
    <div className="relative w-full overflow-hidden">
      <div
        style={{
          transform: `translateX(${-sliderTranslate}px)`,
          width: `${(imageWidth + imageGap) * loopedImages.length}px`,
        }}
        className="h-[152px] flex items-center gap-[7px] mt-[23px] transition-all duration-200 ease-in-out"
      >
        {loopedImages.map((image, index) => (
          <img
            key={index}
            className="w-[143px] h-full object-cover rounded-[16px]"
            src={image}
            alt=""
          />
        ))}
      </div>
      <button
        onClick={() => handleSlide('prev')}
        className="absolute top-1/2 -translate-y-1/2 left-4"
      >
        <FontAwesomeIcon className="w-[20px] h-[20px]" icon={faChevronLeft} color="#fff" />
      </button>
      <button
        onClick={() => handleSlide('next')}
        className="absolute top-1/2 -translate-y-1/2 right-4"
      >
        <FontAwesomeIcon className="w-[20px] h-[20px]" icon={faChevronRight} color="#fff" />
      </button>
    </div>
  );
};

export default BookingSlider;
