interface ProfilePictureProps {
  picture?: string;
  firstName?: string;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ picture, firstName }) => {
  if (picture) {
    return <img src={picture} alt="profile" className="h-20 w-20 rounded-full object-cover" />;
  }
  return (
    <div className="h-20 w-20 bg-blue-status rounded-full flex items-center justify-center">
      <span className="text-white font-bold text-[38px] pt-1">{firstName?.[0]}</span>
    </div>
  );
};
