import { Sentiment } from '@book-nestor-monorepo/shared-types';

export const SentimentBadge = ({ value }: { value: Sentiment }) => {
  if (!value) return null;
  const colors = {
    positive: 'bg-green-100 text-green-600',
    negative: 'bg-red-100 text-red-600',
    neutral: 'bg-yellow-100 text-yellow-600',
  };

  return (
    <span
      className={`flex h-[19px] w-auto items-center px-2 rounded-full text-[10px] font-avenir ${colors[value]}`}
    >
      {value.charAt(0).toUpperCase() + value.slice(1)}
    </span>
  );
};
