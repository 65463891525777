import {
  BookingConversationItem,
  BookingConversationMessageItem,
  Insight,
} from '@book-nestor-monorepo/shared-types';
import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getAgentConversation,
  getAgentConversationMessages,
  getAgentMetrics,
} from '../../libs/services/agent-conversations';
import { AuthContext } from '../authContext';

interface AgentContextType {
  isLoading: boolean;
  currentConversation: BookingConversationItem | null;
  conversationMessages: BookingConversationMessageItem[];
  agentMetrics: Insight[];
  fetchConversation: (id: string) => Promise<BookingConversationItem | null>;
  fetchConversationMessages: () => Promise<BookingConversationMessageItem[]>;
  fetchConversationMetrics: (forceRefresh?: boolean) => Promise<Insight[]>;
}

interface AgentProviderProps {
  children: ReactNode;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export const useAgent = (): AgentContextType => {
  const context = useContext(AgentContext);

  if (!context) {
    throw new Error('useAgent must be used within an AgentProvider');
  }

  return context;
};

export const AgentProvider: FunctionComponent<AgentProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [agentMetrics, setAgentMetrics] = useState<any>([]);
  const [conversationMessages, setConversationMessages] = useState<
    BookingConversationMessageItem[]
  >([]);
  const [currentConversation, setCurrentConversation] = useState<BookingConversationItem | null>(
    null,
  );

  const conversationMessagesFetched = useRef<boolean>(false);
  const agentMetricsFetched = useRef<boolean>(false);

  // const handleFetchConversations = useCallback(
  //   async (forceRefresh = false, take = 10, cursor?: string) => {
  //     if (conversationsFetched.current && !forceRefresh) {
  //       return conversations;
  //     }

  //     setIsLoading(true);
  //     try {
  //       const user = authContext.user;
  //       if (!user) return [];
  //       const result = await getAgentConversations(user.id as string, take, cursor);
  //       setRecoveryConversations(result.items.filter((conversation) => conversation.is_recovery));

  //       // Use function form of setState to access latest state
  //       setConversations((prevConversations) => ({
  //         items: cursor
  //           ? [...prevConversations.items, ...result.items] // If paginating, append
  //           : result.items, // If first load, replace
  //         meta: {
  //           take: result.meta.take,
  //           hasMore: result.meta.hasMore,
  //           nextCursor: result.meta.nextCursor,
  //         },
  //       }));

  //       if (!cursor) {
  //         conversationsFetched.current = true;
  //       }
  //       return result;
  //     } catch (error) {
  //       console.error('Error fetching conversations:', error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //     return [];
  //   },
  //   [],
  // );

  const handleFetchConversationMessages = useCallback(
    async (forceRefresh = false): Promise<BookingConversationMessageItem[]> => {
      if (conversationMessagesFetched.current && !forceRefresh) {
        return [];
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getAgentConversationMessages(user.id as string);
        setConversationMessages(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversations:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [],
  );

  const handleFetchConversation = useCallback(
    async (id: string): Promise<BookingConversationItem | null> => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return null;
        const result = await getAgentConversation(user.id as string, id);
        setCurrentConversation(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversation:', error);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [],
  );

  const handleFetchConversationMetrics = useCallback(
    async (forceRefresh = false): Promise<Insight[]> => {
      if (agentMetricsFetched.current && !forceRefresh) {
        return [];
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getAgentMetrics(user.id as string);
        setAgentMetrics(result);
        return result;
      } catch (error) {
        console.error('Error fetching conversation metrics:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [],
  );

  useEffect(() => {
    handleFetchConversationMessages();
    handleFetchConversationMetrics();
  }, [handleFetchConversationMessages, handleFetchConversationMetrics]);

  return (
    <AgentContext.Provider
      value={{
        isLoading,
        currentConversation,
        conversationMessages,
        agentMetrics,
        fetchConversation: handleFetchConversation,
        fetchConversationMessages: handleFetchConversationMessages,
        fetchConversationMetrics: handleFetchConversationMetrics,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};
