import {
  OnboardingTaskNames,
  Organization,
  SubscriptionPlan,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { SaveButton } from '../../components/formLibrary/formSaveButton';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { AuthContext } from '../../contexts/authContext';
import { FormattedTask, useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { useOrganization } from '../../contexts/v2/organizationContext';
import { environment } from '../../environments/environment';
import { upsertOrganization } from '../../libs/services/organization.service';
import { hasAccess } from '../../libs/utils/hasAccess';

export const BusinessProfileV2 = () => {
  const MAX_DESCRIPTION_LENGTH = 350;

  const { bgColor } = useAppExperience();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onboardingContext = queryParams.get('onboardingContext');

  const { organization, fetchOrganization } = useOrganization();

  const [isLoading, setIsLoading] = useState(true);
  const { markTaskComplete, selectedTask, remainingFormattedTasks } = useQuickStart();
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    undefined,
  );
  const logoFileInputRef = useRef<any>(null);
  const galleryInputRef = useRef<any>(null);
  const [previewSrc, setPreviewSrc] = useState<any>('');
  const [logoFile, setLogoFile] = useState<any>(undefined);
  const [galleryFiles, setGalleryFiles] = useState<any[]>([]);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    }
  }, [selectedTask]);

  const userUploadPhotoLimit = () => {
    switch (authContext.user?.subscription) {
      case SubscriptionPlan.PRO:
        return 8;
      case SubscriptionPlan.STUDIO:
        return 12;
      default:
        return 4;
    }
  };

  const userCanUseBusinessBranding = (): boolean => {
    const token = authContext.token;
    if (!token) return true;

    return hasAccess(token, USER_PERMISSIONS.CAN_USE_BUSINESS_BRANDING);
  };

  const brandingDescription = () => {
    if (!userCanUseBusinessBranding()) {
      return (
        <p className="text-left font-normal text-[10px] text-black font-inter ">
          Upgrade your plan to hide Nestor Branding on your booking page.
        </p>
      );
    }
    return (
      <p className="text-left font-normal text-[10px] text-black font-inter ">
        All Nestor Branding on your booking page will be hidden.
      </p>
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = authContext.user;
      if (!user) return;

      if (organization) {
        formik.setValues(organization);

        if (organization.logo_image_key) {
          setPreviewSrc(`${environment.s3.bucketUrl}/${organization.logo_image_key}`);
        }

        if (organization.gallery_image_keys && organization.gallery_image_keys.length > 0) {
          const galleryPreviews = organization.gallery_image_keys.map(
            (key: string) => `${environment.s3.bucketUrl}/${key}`,
          );
          setGalleryFiles(galleryPreviews);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user]);

  const deleteUploadedImage = async (idx: number) => {
    const newGalleries = [...galleryFiles];
    newGalleries.splice(idx, 1);
    setGalleryFiles(newGalleries);
    if (organization?.id) {
      await upsertOrganization(organization, logoFile, newGalleries);
      await fetchOrganization(true);
    }
  };

  const handleLogoFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setLogoFile(file);

    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleGalleryFilesChange = async (event: any) => {
    const files = Array.from(event.target.files);

    setGalleryFiles([...galleryFiles, ...files]);

    if (organization?.id) {
      await upsertOrganization(organization, logoFile, [...galleryFiles, ...files]);
      await fetchOrganization(true);
    }
  };

  const userHasReachedGalleryLimit = (): boolean => {
    return galleryFiles.length >= userUploadPhotoLimit();
  };

  const userHasReachedMaxGalleryLimit = (): boolean => {
    return galleryFiles.length >= 12;
  };

  const handleLogoFileButtonClick = () => {
    logoFileInputRef?.current?.click();
  };

  const handleGalleryFilesButtonClick = () => {
    if (userHasReachedGalleryLimit()) {
      return;
    }
    galleryInputRef?.current?.click();
  };

  const initialValues: Organization = {
    name: '',
    mailing_address: {
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
    },
    business_type: '',
    spoken_languages: '',
    description: '',
    phone_number: '',
    website_url: '',
    facebook_handle: '',
    instagram_handle: '',
    tiktok_handle: '',
    settings: {
      booking_page: {
        hide_branding: false,
      },
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(),
      mailing_address: Yup.object({
        addressLine1: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
      business_type: Yup.string(),
      spoken_languages: Yup.string(),
      description: Yup.string().max(MAX_DESCRIPTION_LENGTH),
      phone_number: Yup.string().nullable(),
      website_url: Yup.string().nullable(),
      facebook_handle: Yup.string(),
      instagram_handle: Yup.string(),
      tiktok_handle: Yup.string(),
      settings: Yup.object({
        booking_page: Yup.object({
          hide_branding: Yup.boolean(),
        }),
      }),
    }),
    onSubmit: async (values) => {
      await upsertOrganization(values, logoFile, galleryFiles);
      setLogoFile(null);
      await fetchOrganization(true);
    },
  });

  return (
    <div
      className={`ml-0 lg:ml-[72px] max-w-4xl  py-2 sm:px-6 lg:px-8 font-avenir bg-[${bgColor}]`}
    >
      <div className="w-full items-start justify-start flex flex-col">
        <div className="flex flex-col mx-auto w-full items-start justify-start">
          <div className="space-y-12 flex w-full">
            <div className="flex flex-col pb-12 w-full items-start justify-start p-2">
              <div className="flex justify-between items-center w-full">
                <div className="fixed bottom-0 left-0 right-0  flex flex-row justify-end gap-2 p-4 shadow-lg z-50 lg:hidden">
                  <SaveButton
                    onClick={async () => {
                      if (formik.isValid) {
                        await formik.submitForm();
                        await markTaskComplete(OnboardingTaskNames.updateBusinessInfo);
                        if (logoFile || galleryFiles?.length > 0) {
                          await markTaskComplete(OnboardingTaskNames.addFirstBusinessImage);
                        }
                      }
                    }}
                    disabled={!formik.isValid}
                    bgColor="[#000000]"
                    color="[#FFFFFF]"
                  />
                </div>
              </div>
              <div className="flex w-full h-full items-start px-1 mt-1 overflow-y-auto hide-scrollbar max-w-4xl">
                <Card
                  className="mb-4  overflow-hidden"
                  sx={{
                    marginBottom: '8px',
                    minHeight: '60px',
                    width: '100%',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <CardContent
                    sx={{
                      padding: '8px',
                      '&:last-child': {
                        paddingBottom: '8px',
                      },
                    }}
                    className="flex justify-start items-start cursor-pointer  h-auto"
                  >
                    <div className="flex items-center w-full">
                      <div className="flex items-center">{<></>}</div>
                      <div className="flex justify-between items-start w-full">
                        <div className="flex w-full justify-between ">
                          <h2 className="text-[32px]  font-medium font-avenir ">
                            Business Profile
                          </h2>
                          <div className="hidden lg:flex flex-row justify-end gap-2 mr-2">
                            <SaveButton
                              onClick={async () => {
                                if (formik.isValid) {
                                  await formik.submitForm();
                                  await markTaskComplete(OnboardingTaskNames.updateBusinessInfo);
                                  if (logoFile || galleryFiles?.length > 0) {
                                    await markTaskComplete(
                                      OnboardingTaskNames.addFirstBusinessImage,
                                    );
                                  }
                                }
                              }}
                              disabled={!formik.isValid}
                              bgColor="[#000000]"
                              color="[#FFFFFF]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{
                      padding: '0px',
                      '&:last-child': {
                        paddingBottom: '0px',
                      },
                    }}
                  >
                    <div className=" md:rounded-br-2xl md:rounded-bl-2xl w-full py-2">
                      <div className="flex flex-col h-full ">
                        <div className="flex flex-col items-center text-center  h-full">
                          <form
                            onSubmit={formik.handleSubmit}
                            className="w-full flex justify-center items-center flex-col mb-2"
                          >
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              ref={logoFileInputRef}
                              onChange={handleLogoFileChange}
                              accept="image/jpeg,image/png,image/heic"
                            />
                            <div className="flex h-[200px] w-full justify-between gap-1">
                              <div
                                className={`flex w-full ${
                                  previewSrc ? 'bg-transparent' : 'bg-black'
                                } rounded-2xl`}
                              >
                                {previewSrc ? (
                                  <img
                                    src={previewSrc}
                                    alt="Preview"
                                    className="h-full w-full object-cover rounded-2xl"
                                  />
                                ) : null}
                              </div>
                              <div
                                className="flex w-[75px] bg-gray-300 rounded-2xl items-center justify-center cursor-pointer ml-1"
                                onClick={handleLogoFileButtonClick}
                              >
                                <FontAwesomeIcon icon={faPlus} className="text-black text-[12px]" />
                              </div>
                            </div>

                            <p className="text-xs text-gray-500">
                              Landscape image for best results
                            </p>
                            <div className="w-full  font-avenir flex flex-col gap-x-4 gap-y-4 pt-4">
                              <div className="w-full inline-flex flex-row h-[48px] rounded-full items-center grow">
                                <PrimaryTextField
                                  label="Company Name"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="name"
                                  variant="filled"
                                  fullWidth={true}
                                  required={true}
                                  value={formik.values.name}
                                />
                              </div>
                              <div className="flex flex-col gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="Address"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="mailing_address.addressLine1"
                                  variant="filled"
                                  fullWidth={true}
                                  required={true}
                                  value={formik.values.mailing_address?.addressLine1}
                                />
                              </div>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="City"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="mailing_address.city"
                                  variant="filled"
                                  required={true}
                                  value={formik.values.mailing_address?.city}
                                />
                                <InputMask
                                  mask="aa"
                                  maskChar=" "
                                  {...formik.getFieldProps('mailing_address.state')}
                                >
                                  <PrimaryTextField
                                    label="State"
                                    color="primary"
                                    onChange={formik.handleChange}
                                    type="text"
                                    variant="filled"
                                    name="mailing_address.state"
                                    required={true}
                                    value={formik.values.mailing_address?.state}
                                  />
                                </InputMask>

                                <InputMask
                                  mask="99999"
                                  maskChar=" "
                                  minLength={5}
                                  {...formik.getFieldProps('mailing_address.zipCode')}
                                >
                                  <PrimaryTextField
                                    label="Zip"
                                    color="primary"
                                    onChange={formik.handleChange}
                                    type="text"
                                    name="mailing_address.zipCode"
                                    required={true}
                                    variant="filled"
                                    value={formik.values.mailing_address?.zipCode}
                                  />
                                </InputMask>
                              </div>
                              <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                                Business Details
                              </label>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="Business Type"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  variant="filled"
                                  name="business_type"
                                  value={formik.values.business_type}
                                />
                                <PrimaryTextField
                                  label="Languages"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="spoken_languages"
                                  variant="filled"
                                  value={formik.values.spoken_languages}
                                />
                              </div>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="Website"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="website_url"
                                  value={formik.values.website_url}
                                  variant="filled"
                                />
                                <PrimaryTextField
                                  label="Phone Number"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="phone_number"
                                  value={formik.values.phone_number}
                                  variant="filled"
                                />
                              </div>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="Description"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  fullWidth={true}
                                  multiline={true}
                                  rows={4}
                                  name="description"
                                  value={formik.values.description}
                                  variant="filled"
                                  inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                                  helperText={`${formik.values.description?.length || 0}/${MAX_DESCRIPTION_LENGTH}`}
                                />
                              </div>

                              <label className="text-sm font-avenir font-bold text-black-text text-left mt-4">
                                Social Handles
                              </label>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="@Facebook"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  name="facebook_handle"
                                  fullWidth={true}
                                  value={formik.values.facebook_handle}
                                  variant="filled"
                                />
                              </div>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="@Instagram"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  fullWidth={true}
                                  name="instagram_handle"
                                  value={formik.values.instagram_handle}
                                  variant="filled"
                                />
                              </div>
                              <div className="flex flex-row gap-x-4 gap-y-4">
                                <PrimaryTextField
                                  label="@TikTok"
                                  color="primary"
                                  onChange={formik.handleChange}
                                  type="text"
                                  fullWidth={true}
                                  name="tiktok_handle"
                                  value={formik.values.tiktok_handle}
                                  variant="filled"
                                />
                              </div>

                              <h4 className="text-[16px] font-avenir font-bold text-black-text text-left mt-4">
                                Hide Nestor Branding
                                <div>{brandingDescription()}</div>
                              </h4>

                              <div
                                className={`flex w-[105px] h-10 rounded-full bg-[#EBEBEB] p-[2px] mb-4 ${userCanUseBusinessBranding() ? '' : 'opacity-60 cursor-not-allowed'}`}
                              >
                                <div className="h-full w-[67px] min-w-[67px] rounded-full bg-black flex items-center justify-center">
                                  <p
                                    className={`text-[12px] font-avenir font-medium ${formik.values.settings?.booking_page?.hide_branding ? 'text-book-green' : 'text-red-notification'}`}
                                  >
                                    {formik.values.settings?.booking_page?.hide_branding
                                      ? 'Hidden'
                                      : 'Visible'}
                                  </p>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!userCanUseBusinessBranding()) return;
                                    formik.setFieldValue(
                                      'settings.booking_page.hide_branding',
                                      !formik.values.settings?.booking_page?.hide_branding,
                                    );
                                  }}
                                  className="w-full flex items-center justify-center"
                                >
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                                      fill="#303030"
                                    />
                                  </svg>
                                </button>
                              </div>

                              <div className="flex flex-col gap-y-2">
                                <h5 className="font-inter font-medium text-left ">Gallery</h5>

                                <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  ref={galleryInputRef}
                                  onChange={handleGalleryFilesChange}
                                  accept="image/jpeg,image/png,image/heic"
                                  multiple
                                />
                                <div className="grid grid-cols-2 gap-2 mt-5">
                                  {galleryFiles.map((file, index) => (
                                    <div
                                      key={index}
                                      className={`relative w-full h-[133px] rounded-[16px] bg-black-alt overflow-hidden ${index >= userUploadPhotoLimit() ? 'opacity-40 cursor-not-allowed' : ''}`}
                                    >
                                      <img
                                        src={
                                          typeof file === 'string'
                                            ? file
                                            : URL.createObjectURL(file)
                                        }
                                        alt={`Gallery Preview ${index + 1}`}
                                        className="w-full h-full object-cover"
                                      />
                                      {index < userUploadPhotoLimit() && (
                                        <div className="absolute flex flex-col gap-[6px] right-2 top-2">
                                          <button
                                            type="button"
                                            onClick={() => deleteUploadedImage(index)}
                                            className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-sm flex items-center justify-center"
                                          >
                                            <svg
                                              width="9"
                                              height="11"
                                              viewBox="0 0 9 11"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8.53125 1.8125C8.78516 1.8125 9 2.02734 9 2.28125C9 2.55469 8.78516 2.75 8.53125 2.75H8.29688L7.82812 9.09766C7.76953 9.76172 7.24219 10.25 6.57812 10.25H2.65234C1.98828 10.25 1.46094 9.76172 1.40234 9.09766L0.933594 2.75H0.71875C0.445312 2.75 0.25 2.55469 0.25 2.28125C0.25 2.02734 0.445312 1.8125 0.71875 1.8125H2.06641L2.78906 0.738281C2.98438 0.445312 3.33594 0.25 3.70703 0.25H5.52344C5.89453 0.25 6.24609 0.445312 6.44141 0.738281L7.16406 1.8125H8.53125ZM3.70703 1.1875C3.64844 1.1875 3.58984 1.22656 3.57031 1.26562L3.19922 1.8125H6.03125L5.66016 1.26562C5.64062 1.22656 5.58203 1.1875 5.52344 1.1875H3.70703ZM7.35938 2.75H1.87109L2.33984 9.03906C2.35938 9.19531 2.49609 9.3125 2.65234 9.3125H6.57812C6.73438 9.3125 6.87109 9.19531 6.89062 9.03906L7.35938 2.75Z"
                                                fill="white"
                                              />
                                            </svg>
                                          </button>
                                          <button
                                            type="button"
                                            className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-md flex items-center justify-center"
                                          >
                                            <svg
                                              width="14"
                                              height="11"
                                              viewBox="0 0 14 11"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3.42969 2.06641C4.30859 1.40234 5.40234 0.875 6.75 0.875C8.3125 0.875 9.58203 1.59766 10.5 2.45703C11.418 3.29688 12.0234 4.3125 12.3164 5.01562C12.375 5.17188 12.375 5.34766 12.3164 5.50391C12.0625 6.12891 11.5352 7.02734 10.7539 7.80859L12.8047 9.42969C13.0195 9.58594 13.0586 9.87891 12.8828 10.0742C12.7266 10.2891 12.4336 10.3281 12.2383 10.1523L0.675781 1.08984C0.460938 0.933594 0.421875 0.640625 0.597656 0.445312C0.753906 0.230469 1.04688 0.191406 1.24219 0.367188L3.42969 2.06641ZM4.19141 2.67188L5.08984 3.375C5.53906 2.98438 6.10547 2.75 6.75 2.75C8.11719 2.75 9.25 3.88281 9.25 5.25C9.25 5.67969 9.13281 6.07031 8.95703 6.40234L10.0117 7.22266C10.6758 6.55859 11.1445 5.79688 11.3984 5.25C11.125 4.66406 10.6172 3.84375 9.85547 3.14062C9.05469 2.39844 8.01953 1.8125 6.75 1.8125C5.75391 1.8125 4.89453 2.16406 4.19141 2.67188ZM8.19531 5.81641C8.27344 5.64062 8.3125 5.44531 8.3125 5.25C8.3125 4.39062 7.60938 3.6875 6.75 3.6875C6.73047 3.6875 6.71094 3.6875 6.69141 3.6875C6.73047 3.80469 6.75 3.90234 6.75 4C6.75 4.21484 6.69141 4.39062 6.61328 4.56641L8.19531 5.81641ZM8.39062 8.35547L9.21094 9C8.48828 9.39062 7.66797 9.625 6.75 9.625C5.16797 9.625 3.89844 8.92188 2.98047 8.0625C2.0625 7.20312 1.45703 6.1875 1.16406 5.50391C1.10547 5.34766 1.10547 5.17188 1.16406 5.01562C1.35938 4.56641 1.67188 4 2.12109 3.41406L2.84375 4C2.49219 4.44922 2.23828 4.89844 2.08203 5.25C2.35547 5.83594 2.86328 6.67578 3.625 7.37891C4.42578 8.12109 5.46094 8.6875 6.75 8.6875C7.33594 8.6875 7.88281 8.57031 8.39062 8.35547ZM4.25 5.25C4.25 5.21094 4.25 5.15234 4.25 5.09375L5.34375 5.95312C5.53906 6.36328 5.92969 6.67578 6.39844 6.77344L7.49219 7.65234C7.25781 7.71094 7.00391 7.75 6.73047 7.75C5.36328 7.75 4.23047 6.63672 4.23047 5.25H4.25Z"
                                                fill="white"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                  {!userHasReachedMaxGalleryLimit() && (
                                    <button
                                      type="button"
                                      onClick={handleGalleryFilesButtonClick}
                                      className={`relative w-full h-[133px] rounded-[16px] bg-black-alt ${userHasReachedGalleryLimit() ? 'opacity-60 cursor-not-allowed' : ''}`}
                                    >
                                      <div className="pt-[35px] pl-[46px] ">
                                        <svg
                                          width="9"
                                          height="9"
                                          viewBox="0 0 9 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.6875 4.25C8.6875 4.52344 8.47266 4.71875 8.21875 4.71875H5.09375V7.84375C5.09375 8.11719 4.87891 8.33203 4.625 8.33203C4.35156 8.33203 4.15625 8.11719 4.15625 7.84375V4.71875H1.03125C0.757812 4.71875 0.5625 4.52344 0.5625 4.26953C0.5625 3.99609 0.757812 3.78125 1.03125 3.78125H4.15625V0.65625C4.15625 0.402344 4.35156 0.207031 4.625 0.207031C4.87891 0.207031 5.09375 0.402344 5.09375 0.65625V3.78125H8.21875C8.47266 3.78125 8.6875 3.99609 8.6875 4.25Z"
                                            fill="#ECECEC"
                                          />
                                        </svg>
                                        <h6 className="text-gray-light font-avenir mt-[28px] text-left">
                                          {userHasReachedGalleryLimit()
                                            ? 'Upgrade to add more images'
                                            : 'Add Image'}
                                        </h6>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
