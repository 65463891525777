import { CalendarSettings } from '@calcom/atoms';
import { Card, CardContent, Collapse } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { FormattedTask, useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';

export const LinkedCalendarsV2 = () => {
  const { bgColor } = useAppExperience();
  const { markTaskComplete } = useQuickStart();
  const { user } = useContext(AuthContext);
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    'linked-calendars': true,
    main: true,
  });

  const toggleCard = (card: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [card]: !prev[card],
    }));
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onboardingContext = queryParams.get('onboardingContext');
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    undefined,
  );
  const { selectedTask, remainingFormattedTasks } = useQuickStart();

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    }
  }, [selectedTask]);

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full">
        <div className="flex w-full h-auto items-start pl-4">
          {onboardingContext && selectedFormattedTask ? (
            <div className="flex flex-col items-start">
              <span className="text-[14px] font-avenir text-white">
                Step {selectedFormattedTask?.number}
              </span>
              <span className="text-[24px] font-inter text-white">
                {selectedFormattedTask?.title}
              </span>
            </div>
          ) : (
            <span className="text-[16px] font-avenir text-white">Manage your linked calendars</span>
          )}
        </div>
        <div className="flex flex-col w-full h-auto items-start gap-y-1 px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden "
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('linked-calendars')}
            >
              <div className="flex items-center w-full">
                <div className="flex items-center">{<></>}</div>
                <div className="flex pl-4 justify-between items-start w-full">
                  <div className="flex w-full">
                    <span className="font-avenir text-[16px] text-[#727272]">Linked Calendars</span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={expandedCards['linked-calendars']} unmountOnExit>
              <CardContent
                sx={{
                  padding: '8px',
                  backgroundColor: 'white',
                  '&:last-child': {
                    paddingBottom: '0px',
                  },
                }}
              >
                <div className="my-5">
                  <CalendarSettings />
                </div>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
};
