import {
  OnboardingTaskNames,
  USER_PERMISSIONS,
  ValidateSlugMessageEnum,
} from '@book-nestor-monorepo/shared-types';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ExpandableCard } from '../../components/common/ExpandableCard/ExpandableCard';
import { SaveButton } from '../../components/formLibrary/formSaveButton';
import { AuthContext } from '../../contexts/authContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { environment } from '../../environments/environment';
import { getUserById } from '../../libs/services/user';
import { getUserSlug, updateUserSlug, validateSlug } from '../../libs/services/user-slug';
import { hasAccess } from '../../libs/utils/hasAccess';
import { useQuickStart } from '../../contexts/quickStartContext';
import { debounce } from 'lodash';

export const PersonalSettingsPageV2 = () => {
  const { markTaskComplete } = useQuickStart();
  const { bgColor } = useAppExperience();
  const slugRegex = /^[a-z0-9-_]+$/;
  const [validationState, setValidationState] = useState<{
    message: string;
    isError: boolean;
    isValidating: boolean;
  }>({
    message: '',
    isError: false,
    isValidating: false,
  });

  const latestValidationRequest = useRef<string>('');

  const validateSlugField = (value: string) => {
    if (value.length < 4) return 'Minimum of 4 characters';
    if (!slugRegex.test(value))
      return "Please use lowercase only 'a-z', '0-9', '-', or '_' characters";
    if (value.length > 254) return 'Maximum of 255 characters';
  };

  const debouncedValidateSlug = useCallback(
    debounce(async (value: string, setFieldError: (field: string, message: string) => void) => {
      if (value === latestValidationRequest.current) {
        try {
          const res = await validateSlug(value);
          if (value === latestValidationRequest.current) {
            if (res.message !== ValidateSlugMessageEnum.avaliable) {
              setFieldError('slug', 'Slug is unavailable');
              setValidationState({
                message: 'Slug is unavailable',
                isError: true,
                isValidating: false,
              });
            } else {
              setFieldError('slug', '');
              setValidationState({
                message: 'Available',
                isError: false,
                isValidating: false,
              });
            }
          }
        } catch (error) {
          if (value === latestValidationRequest.current) {
            setFieldError('slug', 'Error validating slug');
            setValidationState({
              message: 'Error validating slug',
              isError: true,
              isValidating: false,
            });
          }
        }
      }
    }, 300),
    [],
  );

  const [copied, setCopied] = useState(false);
  const { user, token, updateLoggedInUser } = useContext(AuthContext);

  const formikRef = useRef<FormikProps<{ slug: string }>>(null);
  const initialValues = {
    slug: user?.user_slug?.slug || '',
  };

  const canUseCustomSlug = (): boolean => {
    if (!token) return true;
    return hasAccess(token, USER_PERMISSIONS.CAN_USE_CUSTOM_SLUG);
  };

  useEffect(() => {
    const fetchSlugIfNotExists = async () => {
      if (user?.id && !user?.user_slug?.slug) {
        const session: AuthSession = await fetchAuthSession();
        if (!session) return;
        const slug = await getUserSlug(user?.id);
        if (slug && formikRef.current) {
          formikRef.current.setFieldValue('slug', slug.slug);
        }
      }
    };
    fetchSlugIfNotExists();
  }, [user]);

  const handleSubmit = async (values: { slug: string }, { setSubmitting }: any) => {
    await saveSettingsAndContinue(values.slug);
    setSubmitting(false);
  };

  const saveSettingsAndContinue = async (slugInput: string): Promise<void> => {
    const session: AuthSession = await fetchAuthSession();
    if (!session) return;

    await updateUserSlug(session?.tokens?.accessToken?.payload?.sub || '', slugInput);
    await markTaskComplete(OnboardingTaskNames.updateBookingLink);
    const user = await getUserById(session?.tokens?.accessToken?.payload?.sub || '');
    if (user && updateLoggedInUser) {
      await updateLoggedInUser(user);
    }
  };

  const copyToClipboard = (slug: string) => {
    const fullUrl = `${environment.baseBookingUrl}/${slug}`;
    navigator.clipboard.writeText(fullUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  const nestorSlugDescription = () => {
    if (canUseCustomSlug()) {
      return (
        <span className="text-xs text-gray-400 leading-0 font-inter text-left mt-2">
          Changing your Nestor URL will mean that all of your copied links will no longer work and
          will need to be updated.
        </span>
      );
    }
    return (
      <span className="text-xs text-gray-400 leading-0 font-inter text-left mt-2">
        Upgrade to a paid plan to customize your Nestor URL
      </span>
    );
  };

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full h-auto items-start pl-4">
          <span className="text-[16px] font-avenir text-white">
            Customize your personal settings
          </span>
        </div>
        <ExpandableCard
          id="update-slug"
          title="Update Link"
          isExpanded={true}
          isActive={true}
          onToggle={() => {
            console.log('toggle');
          }}
          content={
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              innerRef={formikRef}
            >
              {({ values, errors, isSubmitting, setFieldError, setFieldValue, submitForm }) => (
                <Form>
                  <div className="mb-3 w-full">
                    <div className="flex">
                      <h4 className="text-sm text-left font-bold flex-shrink-0 mt-3 mr-3 mb-3 font-inter">
                        mynestor.com/book/
                      </h4>
                      <div className="w-full">
                        <Field name="slug">
                          {({ field }: any) => (
                            <input
                              {...field}
                              disabled={!canUseCustomSlug()}
                              className={`block w-full min-h-11 rounded-xl text-base border border-solid border-gray-400 p-[10px] placeholder:text-sm font-inter focus:outline-none focus:border-gray-400 focus:ring-0 ${
                                canUseCustomSlug() ? '' : 'opacity-50'
                              }`}
                              placeholder="your link"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setFieldValue('slug', newValue);

                                latestValidationRequest.current = newValue;

                                const error = validateSlugField(newValue);
                                if (error) {
                                  setFieldError('slug', error);
                                  setValidationState({
                                    message: error,
                                    isError: true,
                                    isValidating: false,
                                  });
                                } else {
                                  setValidationState({
                                    message: 'Checking availability...',
                                    isError: false,
                                    isValidating: true,
                                  });
                                  debouncedValidateSlug(newValue, setFieldError);
                                }
                              }}
                            />
                          )}
                        </Field>

                        <div className="flex min-h-8">
                          <div className="mt-1 ml-auto">
                            <SlugValidationMessage
                              message={validationState.message || errors.slug || 'Available'}
                              isError={validationState.isError || !!errors.slug}
                              isValidating={validationState.isValidating}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex justify-start items-center">
                      <SaveButton
                        bgColor="#E8E8E8"
                        onClick={submitForm}
                        defaultText="Update"
                        loadingText="Updating"
                        successText="Updated"
                        disabled={!!errors.slug || isSubmitting || !canUseCustomSlug()}
                        color="black"
                      />
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-600 ml-4 flex"
                        onClick={() => copyToClipboard(values.slug)}
                        title="Copy full URL"
                      >
                        <FontAwesomeIcon icon={faCopy} size="xl" color="#9E9E9E" />
                      </button>
                      {copied && (
                        <span className="text-xs text-[#038164] font-inter ml-2">Copied!</span>
                      )}
                    </div>
                    <div className="flex flex-col">{nestorSlugDescription()}</div>
                  </div>
                </Form>
              )}
            </Formik>
          }
          sxProps={{
            border: 'none',
          }}
        />
      </div>
    </div>
  );
};

const SlugValidationMessage: React.FC<{
  message: string;
  isError: boolean;
  isValidating?: boolean;
}> = ({ message, isError, isValidating }) => {
  const className = isError ? 'text-[#C84545]' : isValidating ? 'text-gray-400' : 'text-[#038164]';

  const iconPath = isError
    ? 'M5 0a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm-.625 2.5a.625.625 0 0 1 1.25 0v2a.625.625 0 0 1-1.25 0ZM5 8a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z'
    : 'M10 0a10 10 0 1 0 10 10A10.012 10.012 0 0 0 10 0Zm4.884 8.384-5.5 5.5a1.251 1.251 0 0 1-1.768 0l-2.5-2.5a1.25 1.25 0 0 1 1.768-1.768L8.5 11.232l4.616-4.616a1.25 1.25 0 1 1 1.768 1.768Z';

  return (
    <div className={`flex gap-1 align-items-center ${className}`}>
      <span className="mt-1 h-4 w-4 inline-block align-top flex-shrink-0">
        {!isValidating && (
          <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <path d={iconPath} fill="currentColor"></path>
          </svg>
        )}
      </span>
      {message}
    </div>
  );
};
