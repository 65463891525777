import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { EventCategory } from '@book-nestor-monorepo/shared-types';

export const getEventCategories = async (): Promise<EventCategory[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/event-categories`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data.items || [];
  } catch (error) {
    throw new Error('Failed to fetch events categories');
  }
};

export const createEventCategory = async ({ name }: { name: string }) => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/event-categories`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export const deleteEventCategory = async (id: string): Promise<void> => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;

  try {
    const response = await axios.delete(`${apiBaseUrl}/event-categories/${id}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete event category');
  }
};
