import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export function ConfirmDeleteButton({ onClick }: { onClick: () => void }) {
  const [confirmState, setConfirmState] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (confirmState) {
      onClick();
      setConfirmState(false);
    } else {
      setConfirmState(true);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex font-avenir items-center h-11 border text-[12px] rounded-full w-auto leading-4 ${
        confirmState ? 'bg-black-alt/10 pl-4' : ''
      }`}
    >
      {confirmState && 'Delete?'}
      <div
        className={`h-11 w-11 border bg-black-alt/10 flex items-center justify-center rounded-full ${
          confirmState ? 'ml-2' : ''
        }`}
      >
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </button>
  );
}
