import {
  faBuilding,
  faCalendar,
  faChevronLeft,
  faCog,
  faCreditCard,
  faEnvelope,
  faMoneyBill,
  faQuestionCircle,
  faSignOut,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Drawer, styled, Switch, Theme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import { signOut } from 'aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { getServicePhoneDetailsByUser, updateUserServicePhone } from '../../../libs/services/voice';
import { USER_PERMISSIONS, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import { hasAccess } from '../../../libs/utils/hasAccess';

export const MobileMenuDrawer = ({
  isOpen,
  toggleMenu,
}: {
  isOpen: boolean;
  toggleMenu: () => void;
}) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone | undefined>(undefined);

  useEffect(() => {
    const fetchUserServicePhone = async () => {
      if (!user) return;
      const userId = user.id;
      const userServicePhone = await getServicePhoneDetailsByUser(userId as string);
      setUserServicePhone(userServicePhone);
    };
    fetchUserServicePhone();
  }, [user]);

  const toggleVoiceAssistant = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!userServicePhone) return;

    try {
      // Create a new object with the updated value
      const updatedServicePhone = {
        ...userServicePhone,
        voice_assistant_enabled: !userServicePhone.voice_assistant_enabled,
      };

      // Update the backend
      await updateUserServicePhone(updatedServicePhone);

      // Update local state after successful API call
      setUserServicePhone(updatedServicePhone);
    } catch (error) {
      // Optionally handle error (e.g., show toast message)
      console.error('Failed to toggle voice assistant:', error);

      // You might want to revert the switch state here if the API call fails
      // by not updating the state
    }
  };

  const onDrawerClose = () => {
    setShowSettingsMenu(false);
    toggleMenu();
  };

  const handleClick = (path: string) => {
    if (path) navigate(path);
    toggleMenu();
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const StyledSwitch = styled(Switch)(({ theme }: { theme: Theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#ffffff',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3FAB4A',
    },
  }));

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#E5E8E8',
        },
      }}
    >
      <Box component="div" sx={{ width: 300 }} role="presentation">
        <div className={`flex justify-start items-center mt-2 gap-2 ml-2`}>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2 w-full">
              <button
                onClick={toggleMenu}
                className="w-10 h-10 rounded-full bg-white flex items-center justify-center"
              >
                <FontAwesomeIcon className="text-[14px]" icon={faChevronLeft} />
              </button>
              <NavLink onClick={toggleMenu} to="/personal-settings">
                <div className="relative">
                  {user?.picture ? (
                    <img
                      src={user.picture}
                      alt="profile"
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 bg-blue-status rounded-full flex items-center justify-center">
                      <span className="text-white font-bold">{user?.first_name?.[0]}</span>
                    </div>
                  )}
                  <button className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-[#FFFFFFB0] border-[0.5px] border-[#D9DAD6] flex items-center justify-center">
                    <svg
                      width="9"
                      height="3"
                      viewBox="0 0 9 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.8125 1.25C6.8125 0.742188 7.22266 0.3125 7.75 0.3125C8.25781 0.3125 8.6875 0.742188 8.6875 1.25C8.6875 1.77734 8.25781 2.1875 7.75 2.1875C7.22266 2.1875 6.8125 1.77734 6.8125 1.25ZM3.6875 1.25C3.6875 0.742188 4.09766 0.3125 4.625 0.3125C5.13281 0.3125 5.5625 0.742188 5.5625 1.25C5.5625 1.77734 5.13281 2.1875 4.625 2.1875C4.09766 2.1875 3.6875 1.77734 3.6875 1.25ZM2.4375 1.25C2.4375 1.77734 2.00781 2.1875 1.5 2.1875C0.972656 2.1875 0.5625 1.77734 0.5625 1.25C0.5625 0.742188 0.972656 0.3125 1.5 0.3125C2.00781 0.3125 2.4375 0.742188 2.4375 1.25Z"
                        fill="#989898"
                      />
                    </svg>
                  </button>
                </div>
              </NavLink>
            </div>
            {hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT) && (
              <div className="flex items-center justify-end  gap-2 w-full">
                <div className="inline-flex items-center gap-1">
                  <span className="text-[12px] font-avenir">Ni Agent</span>
                  <StyledSwitch
                    checked={userServicePhone?.voice_assistant_enabled}
                    onChange={toggleVoiceAssistant}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex pt-8 pl-4">
          <span className="text-[32px] font-avenir">{showSettingsMenu ? 'Settings' : 'Menu'}</span>
        </div>
        {showSettingsMenu ? (
          <div>
            <div className="flex pt-8 pl-4">
              <div
                className="flex items-center  justify-start"
                onClick={() => setShowSettingsMenu(false)}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-[12px] pb-[4px]"
                  color="#13ACD4"
                />
                <span className="text-[16px] font-avenir pl-2 text-[#13ACD4]">
                  Back to main menu
                </span>
              </div>
            </div>
            <div className="flex pt-12 pl-2">
              <span className="text-[14px] font-avenir font-bold">Client Engagement</span>
            </div>
            <div className="flex flex-col gap-2 pl-2 pt-2">
              <div
                className="flex items-center gap-2"
                onClick={() => handleClick('/communication')}
              >
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Communication</span>
              </div>
              <div
                className="flex items-center gap-2"
                onClick={() => handleClick('/business-profile')}
              >
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faBuilding} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Business Profile</span>
              </div>
            </div>
            <div className="flex pt-12 pl-2">
              <span className="text-[14px] font-avenir font-bold">Booking Management</span>
            </div>
            <div className="flex flex-col gap-2 pl-2 pt-2">
              <div className="flex items-center gap-2" onClick={() => handleClick('/services')}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Services & Pricing</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => handleClick('/availability')}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faCalendar} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Calendar & Availability</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => handleClick('/payments')}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faMoneyBill} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Payments</span>
              </div>
            </div>
            <div className="flex pt-12 pl-2">
              <span className="text-[14px] font-avenir font-bold">Nestor</span>
            </div>
            <div className="flex flex-col gap-2 pl-2 pt-2">
              <div className="flex items-center gap-2" onClick={() => handleClick('/subscription')}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faCreditCard} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Subscription</span>
              </div>
              <div className="flex items-center gap-2" onClick={() => window.Intercom?.('show')}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Help & Support</span>
              </div>
              <div className="flex items-center gap-2" onClick={handleLogout}>
                <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon icon={faSignOut} size="xs" />
                </div>
                <span className="text-[14px] font-avenir pl-6">Log Out</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex pt-8 pl-2">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2" onClick={() => handleClick('/')}>
                  <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon icon={faHome} size="xs" />
                  </div>
                  <span className="text-[14px] font-avenir pl-6">Home</span>
                </div>
                <div className="flex items-center gap-2" onClick={() => handleClick('/bookings')}>
                  <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon icon={faCalendar} size="xs" />
                  </div>
                  <span className="text-[14px] font-avenir pl-6">Bookings</span>
                </div>
                <div className="flex items-center gap-2" onClick={() => handleClick('/contacts')}>
                  <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon icon={faUsers} size="xs" />
                  </div>
                  <span className="text-[14px] font-avenir pl-6">Contacts</span>
                </div>
              </div>
            </div>
            <div className="flex pt-12 pl-2">
              <span className="text-[14px] font-avenir font-bold">MyNestor</span>
            </div>
            <div className="flex pt-8 pl-2" onClick={() => setShowSettingsMenu(true)}>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon icon={faCog} size="xs" />
                  </div>
                  <span className="text-[14px] font-avenir pl-6">Settings</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Drawer>
  );
};
