import { Booking, BookingStatus, ContactBookingMethod } from '@book-nestor-monorepo/shared-types';
import {
  faArrowRight,
  faChevronRight,
  faChevronDown,
  faUsers,
  faSpinner,
  faAngleDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { getBookings } from '../../libs/services/bookings';
import { convertToMomentTimeZone, durationString } from '../../libs/utils/date.util';
import { truncateText } from '../../libs/utils/string.util';
import { Card, CardContent, Collapse, IconButton } from '@mui/material';
import { faX, faCheck, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { AgentAiBorder } from '../../components/v2/Agent/AgentAiBorder';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { BookingDetailDrawer } from './right-drawer-components/booking-detail-drawer';

type TSelectedStatus = BookingStatus.UPCOMING | BookingStatus.PAST | BookingStatus.CANCELED;

interface BookingsByStatus {
  [BookingStatus.UPCOMING]: Booking[];
  [BookingStatus.PAST]: Booking[];
  [BookingStatus.CANCELED]: Booking[];
}

interface PaginationState {
  hasMore: boolean;
  take: number;
  skip: number;
  isLoading: boolean;
}

interface PaginationByStatus {
  [BookingStatus.UPCOMING]: PaginationState;
  [BookingStatus.PAST]: PaginationState;
  [BookingStatus.CANCELED]: PaginationState;
}

export const BookingsPageV2 = () => {
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const [selectedStatus, setSelectedStatus] = useState<TSelectedStatus>(BookingStatus.UPCOMING);
  const [bookingsByStatus, setBookingsByStatus] = useState<BookingsByStatus>({
    [BookingStatus.UPCOMING]: [],
    [BookingStatus.PAST]: [],
    [BookingStatus.CANCELED]: [],
  });
  const [paginationByStatus, setPaginationByStatus] = useState<PaginationByStatus>({
    [BookingStatus.UPCOMING]: { hasMore: true, take: 10, skip: 0, isLoading: false },
    [BookingStatus.PAST]: { hasMore: true, take: 10, skip: 0, isLoading: false },
    [BookingStatus.CANCELED]: { hasMore: true, take: 10, skip: 0, isLoading: false },
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(true);

  const handleBookingDetailClick = (bookingId: string) => {
    if (isOpen) {
      setDrawerContent(<BookingDetailDrawer bookingId={bookingId} />, {
        darkMode: true,
      });
    } else {
      setDrawerContent(<BookingDetailDrawer bookingId={bookingId} />, {
        darkMode: true,
      });
      toggleDrawer();
    }
  };

  const handleFetchNextBookings = async (status: TSelectedStatus) => {
    if (!authContext.user?.id || paginationByStatus[status].isLoading) return;

    const newPaginationState = { ...paginationByStatus };
    newPaginationState[status].isLoading = true;
    setPaginationByStatus(newPaginationState);

    try {
      const bookingsPaginatedResponse = await getBookings(authContext.user.id, status, {
        take: paginationByStatus[status].take,
        skip: paginationByStatus[status].skip,
        sortStart: status === BookingStatus.UPCOMING ? 'asc' : 'desc',
      });

      const newBookings = bookingsPaginatedResponse.items;

      // Update pagination state
      setPaginationByStatus((prev) => ({
        ...prev,
        [status]: {
          ...prev[status],
          hasMore: bookingsPaginatedResponse.meta.hasMore,
          skip: prev[status].skip + bookingsPaginatedResponse.meta.take,
          isLoading: false,
        },
      }));

      // Sort function
      const sortByStartTime = (bookings: Booking[], reverse = true) => {
        return bookings.sort((a, b) => {
          const diff = new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
          return reverse ? -diff : diff;
        });
      };

      // Merge and sort bookings
      setBookingsByStatus((prev) => ({
        ...prev,
        [status]: sortByStartTime(
          [...prev[status], ...newBookings],
          status !== BookingStatus.UPCOMING,
        ),
      }));
    } catch (error) {
      Sentry.captureException(error);
      setPaginationByStatus((prev) => ({
        ...prev,
        [status]: { ...prev[status], isLoading: false },
      }));
    }
  };

  const handleStatusChange = async (status: TSelectedStatus) => {
    setSelectedStatus(status);
    // If no bookings have been loaded for this status yet, fetch them
    if (bookingsByStatus[status].length === 0 && paginationByStatus[status].hasMore) {
      await handleFetchNextBookings(status);
    }
  };

  useEffect(() => {
    const fetchInitialBookings = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user?.id) return;

        // Only fetch upcoming bookings initially
        const bookingsPaginatedResponse = await getBookings(user.id, BookingStatus.UPCOMING, {
          take: 10,
          sortStart: 'asc',
        });

        // Update pagination state for upcoming
        setPaginationByStatus((prev) => ({
          ...prev,
          [BookingStatus.UPCOMING]: {
            ...prev[BookingStatus.UPCOMING],
            hasMore: bookingsPaginatedResponse.meta.hasMore,
            skip: bookingsPaginatedResponse.meta.take,
          },
        }));

        // Sort and set upcoming bookings
        const sortByStartTime = (bookings: Booking[], reverse = true) => {
          return bookings.sort((a, b) => {
            const diff = new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
            return reverse ? -diff : diff;
          });
        };

        setBookingsByStatus((prev) => ({
          ...prev,
          [BookingStatus.UPCOMING]: sortByStartTime(bookingsPaginatedResponse.items, false),
        }));
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };
    fetchInitialBookings();
  }, [authContext.user]);

  const handleToggleExpand = (bookingId: string) => {
    setExpandedId(expandedId === bookingId ? null : bookingId);
  };

  const createdByString = (booking: Booking) => {
    if (!booking.contact_booking) return 'N/A';
    if (booking.contact_booking.booking_method === ContactBookingMethod.PUBLIC_PAGE) {
      return booking.attendees[0]?.name || 'N/A';
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.AI_AGENT) {
      return `${authContext.servicePhone?.agent?.name} (agent)`;
    } else if (booking.contact_booking.booking_method === ContactBookingMethod.MANUAL_USER) {
      return `${authContext.user?.first_name} (you)`;
    }
    return `${authContext.user?.first_name} (you)` || 'N/A';
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#FFFFFF" />;
  }

  const toggleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const dateIsInFuture = (date: string) => {
    let dateInUserTimezone = date;
    if (authContext.user?.time_zone) {
      dateInUserTimezone = convertToMomentTimeZone(new Date(date), authContext.user?.time_zone);
    }
    return new Date(dateInUserTimezone) > new Date();
  };

  return (
    <div className={`flex flex-col h-auto bg-[#BCC8C7] w-full`}>
      <div className="flex h-full w-full">
        <div className="flex-1 flex flex-col h-full pt-2 ml-0">
          <div className="flex-col ">
            <div className="bg-transparent w-full h-full ">
              <h1 className="text-black text-left pb-4 pl-4 font-avenir text-[16px] font-medium">
                Your bookings
              </h1>
              <div className="flex overflow-x-auto mb-2 hide-scrollbar">
                <button
                  onClick={toggleFilters}
                  className="flex items-center justify-between text-black font-avenir text-[16px] cursor-pointer font-medium"
                >
                  Filters
                  {isFiltersOpen ? (
                    <FontAwesomeIcon icon={faChevronUp} className="ml-1" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
                  )}
                </button>
              </div>
              {isFiltersOpen && (
                <div className="flex">
                  <button
                    className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                      selectedStatus === BookingStatus.UPCOMING
                        ? 'bg-black-alt text-white'
                        : '!border-black-alt text-black-alt'
                    }`}
                    onClick={() => handleStatusChange(BookingStatus.UPCOMING)}
                  >
                    Upcoming
                  </button>
                  <button
                    className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                      selectedStatus === BookingStatus.PAST
                        ? 'bg-black-alt text-white'
                        : '!border-black-alt text-black-alt'
                    }`}
                    onClick={() => handleStatusChange(BookingStatus.PAST)}
                  >
                    Past
                  </button>
                  <button
                    className={`px-[18px] text-[14px] flex items-center py-[6px] mr-1 font-avenir font-light rounded-full border border-transparent ${
                      selectedStatus === BookingStatus.CANCELED
                        ? 'bg-black-alt text-white'
                        : '!border-black-alt text-black-alt'
                    }`}
                    onClick={() => handleStatusChange(BookingStatus.CANCELED)}
                  >
                    Canceled
                  </button>
                </div>
              )}
              <div className="overflow-y-auto w-full h-full bg-transparent hide-scrollbar pt-6 px-1 pb-1">
                {bookingsByStatus[selectedStatus]?.length === 0 &&
                !paginationByStatus[selectedStatus].isLoading ? (
                  <div className="text-center text-black">
                    No {selectedStatus.toLowerCase()} bookings...
                  </div>
                ) : (
                  bookingsByStatus[selectedStatus]?.map((booking) => (
                    <Card
                      key={booking.id}
                      sx={{
                        bgcolor: 'white',
                        boxShadow: 'none',
                        borderRadius: '24px',
                        marginBottom: '4px',
                      }}
                    >
                      <div className="flex flex-col p-4">
                        <div className="flex items-center justify-between">
                          <h3 className="font-avenir text-[16px] font-bold text-black-alt text-left leading-6">
                            {truncateText(booking.attendees.map((a) => a.name).join(', '), 50) ||
                              truncateText(
                                `${booking.contact?.name} ${booking.contact?.last_name}`,
                                50,
                              )}
                          </h3>
                          {selectedStatus === BookingStatus.CANCELED &&
                            dateIsInFuture(booking.start_time) && (
                              <div className="flex items-center">
                                <AgentAiBorder styles={{ bgColor: 'white' }}>
                                  <button
                                    className="flex items-center text-[12px] font-avenir text-black-alt px-4 py-1"
                                    onClick={() => navigate(`/campaigns/fill-slot/${booking.id}`)}
                                  >
                                    Fill Canceled Slot
                                    <FontAwesomeIcon
                                      icon={faSparkles}
                                      className="text-[12px] text-black-alt pl-1 pb-[2px]"
                                    />
                                  </button>
                                </AgentAiBorder>
                              </div>
                            )}
                          {/* {booking.attendees.length > 1 && (
                            <FontAwesomeIcon
                              icon={faUsers}
                              className="text-[16px] text-black-alt"
                            />
                          )} */}
                        </div>
                        <div className="flex justify-between items-start pt-1">
                          <h3 className="font-avenir text-[14px] text-black-alt text-left leading-6">
                            {new Date(booking.start_time).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })}
                          </h3>
                          <h3 className="text-[14px] font-avenir text-black-alt">
                            {new Date(booking.start_time).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}{' '}
                            -{' '}
                            {new Date(booking.end_time).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}
                          </h3>
                        </div>

                        <div className="flex gap-2 mt-6 justify-between">
                          <div className="flex gap-2">
                            <div className="h-10 rounded-full bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir capitalize">
                              {booking.event_type.slug}
                            </div>
                            <div className="h-10 rounded-full bg-[#F5F6F6] px-[18px] text-[14px] flex items-center text-black font-avenir">
                              {durationString(booking)}
                            </div>
                          </div>

                          <div
                            className="h-12 w-12 rounded-full bg-[#BCC8C7] flex items-center justify-center cursor-pointer"
                            onClick={() => handleBookingDetailClick(booking.id)}
                          >
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="text-[12px] -rotate-45"
                            />
                          </div>
                        </div>

                        <div
                          className="flex justify-end items-center mt-2 cursor-pointer"
                          onClick={() => handleToggleExpand(booking.id)}
                        >
                          <span className="text-[14px] font-avenir text-black pt-[2px]">
                            Details
                          </span>
                          <IconButton className="ml-[-8px]">
                            <FontAwesomeIcon
                              icon={expandedId === booking.id ? faChevronDown : faChevronRight}
                              className="text-[12px] text-black"
                            />
                          </IconButton>
                        </div>

                        <Collapse in={expandedId === booking.id} timeout="auto" unmountOnExit>
                          <CardContent className="border-t mt-4 pt-4" sx={{ paddingX: '0px' }}>
                            {/* Add your expanded content here */}
                            <div className="flex-col w-full h-full">
                              <div className="w-full flex justify-between">
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Has Paid:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={booking.contact_booking?.client_has_paid ? faCheck : faX}
                                    className={
                                      booking.contact_booking?.client_has_paid
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Reminder Sent:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={
                                      booking.contact_booking?.reminder_window_sent?.length || 0 > 0
                                        ? faCheck
                                        : faX
                                    }
                                    className={
                                      booking.contact_booking?.reminder_window_sent?.length || 0 > 0
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                  <label className="text-[16px] font-avenir text-black-alt font-bold">
                                    Is Difficult:{' '}
                                  </label>
                                  <FontAwesomeIcon
                                    icon={booking.contact?.is_difficult ? faCheck : faX}
                                    className={
                                      booking.contact?.is_difficult
                                        ? 'text-[16px] text-[#43CA51]'
                                        : 'text-[16px] text-black-alt'
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex w-full justify-start items-start pt-8">
                                <label className="text-[16px] font-avenir text-black-alt font-bold mr-2 leading-6">
                                  Created:{' '}
                                </label>
                                <span className="text-[14px] font-avenir text-black-alt leading-6">
                                  {new Date(
                                    booking.contact_booking?.created_at as string,
                                  ).toLocaleTimeString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })}
                                </span>
                              </div>
                              <div className="flex w-full justify-start items-start pt-1">
                                <label className="text-[16px] font-avenir text-black-alt font-bold mr-2 leading-6">
                                  Created By:{' '}
                                </label>
                                <span className="text-[14px] font-avenir text-black-alt leading-6">
                                  {createdByString(booking)}
                                </span>
                              </div>
                            </div>
                          </CardContent>
                        </Collapse>
                      </div>
                    </Card>
                  ))
                )}
                <div className="flex justify-center items-center w-full py-4 cursor-pointer">
                  {paginationByStatus[selectedStatus].hasMore &&
                    !paginationByStatus[selectedStatus].isLoading &&
                    bookingsByStatus[selectedStatus]?.length > 0 && (
                      <button
                        onClick={() => handleFetchNextBookings(selectedStatus)}
                        className="text-white text-[16px] font-avenir font-medium cursor-pointer"
                      >
                        Load more bookings
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="text-white pl-2 pt-1 text-[16px]"
                        />
                      </button>
                    )}
                  {paginationByStatus[selectedStatus].isLoading && (
                    <FontAwesomeIcon icon={faSpinner} className="text-black animate-spin" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
