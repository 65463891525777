import { faArrowRightToBracket, faSpinner, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Cache, Hub } from 'aws-amplify/utils';
import { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { AuthContext } from '../../contexts/authContext';
import { useValidEmail } from '../../hooks/useAuthHooks';
import { validateCaptcha } from '../../libs/services/connect';
import { AuthLayout } from '../layouts/auth-layout';

function Login() {
  const { email, setEmail, emailIsValid } = useValidEmail('');
  const [loginWithGoogleIsLoading, setLoginWithGoogleIsLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const username = authContext?.username || '';
      setEmail(username);
    };

    fetchData();
  }, [authContext?.username]);

  const handleGoogleLogin = async (event: any) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    try {
      setLoginWithGoogleIsLoading(true);
      const recaptchaResponse = await executeRecaptcha('google_login');
      // The token is typically the first element in the response array
      const token = Array.isArray(recaptchaResponse) ? recaptchaResponse[0] : recaptchaResponse;

      if (typeof token !== 'string') {
        throw new Error('Invalid reCAPTCHA token');
      }

      const captchaResult = await validateCaptcha(token);

      if (captchaResult.success) {
        await signInWithRedirect({ provider: 'Google' });
      } else {
        alert('reCAPTCHA validation failed. Please try again.');
      }
    } catch (error: any) {
      setLoginWithGoogleIsLoading(false);

      if (
        error.name === 'UserAlreadyAuthenticatedException' ||
        error.message?.includes('There is already a signed in user.')
      ) {
        try {
          await signOut();
          await signInWithRedirect({
            provider: 'Google',
          });
        } catch (signOutError) {
          Sentry.captureException(signOutError);
        }
      }
    }
  };

  const history = useNavigate();

  const isValid = !emailIsValid || email?.length === 0;

  const enterEmailClicked = async (event: any) => {
    event.preventDefault();

    await Cache.setItem('login-username-set', email);
    Hub.dispatch('auth', {
      event: 'login-username-set',
      data: { loginUserName: email },
    });

    history('/enterpassword');
    setEmail('');
  };

  const signUpClicked = async (event: any) => {
    history('/signup');
    setEmail('');
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center bg-mid-gray overflow-hidden ">
        <div className=" md:max-w-sm mx-auto min-h-screen  md:h-[640px] md:min-h-[720px] h-full  overflow-hidden relative shadow-md w-full ">
          <div className="absolute top-0 right-0 w-80 h-18 bg-black rounded-full transform translate-x-1/2 -translate-y-1/2 -rotate-45">
            <div className="pt-7 pl-6 ">
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="rotate-45"
              >
                <circle cx="23.3513" cy="8.64866" r="8.14866" stroke="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.2947 31.8271C20.1631 31.8271 17.5977 29.4029 17.3728 26.3287L17.3568 26.346C17.3568 25.2041 16.5194 20.7126 14.6162 18.5811C11.5542 15.1516 8.45091 14.7425 6.74778 14.518L6.74753 14.518C6.6221 14.5015 6.50427 14.4859 6.39461 14.4703L6.4118 14.4517C6.25544 14.464 6.09738 14.4703 5.93785 14.4703C2.65846 14.4703 -1.40382e-07 11.8118 2.96496e-09 8.53245C1.46312e-07 5.25307 2.65846 2.5946 5.93785 2.5946C9.21723 2.5946 11.8757 5.25307 11.8757 8.53245V8.53267C11.8757 8.64597 11.8725 8.75852 11.8662 8.87024C11.8682 10.3798 12.3914 14.0366 15.7581 16.9824C18.9073 19.5017 20.8984 19.7051 22.7961 19.899C22.9628 19.916 23.1287 19.9329 23.2946 19.9514L23.2946 19.9514H23.2947C26.574 19.9514 29.2325 22.6099 29.2325 25.8893C29.2325 29.1687 26.574 31.8271 23.2947 31.8271ZM23.2947 14.4703C20.0153 14.4703 17.3568 11.8118 17.3568 8.53245C17.3568 5.25307 20.0153 2.59461 23.2947 2.59461C26.574 2.59461 29.2325 5.25307 29.2325 8.53245C29.2325 11.8118 26.574 14.4703 23.2947 14.4703ZM5.93785 31.8271C2.65846 31.8271 -1.43347e-07 29.1687 0 25.8893C1.43347e-07 22.6099 2.65846 19.9514 5.93785 19.9514C9.21723 19.9514 11.8757 22.6099 11.8757 25.8893C11.8757 29.1687 9.21723 31.8271 5.93785 31.8271Z"
                  fill="#E1E0E1"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-16  pt-16 pb-8 bg-light-gray rounded-bl-[3rem] rounded-br-[3rem]">
            <div className="mb-8 w-full text-left">
              <p className="text-md leading-4 text-left text-black">
                Welcome to <br />
                My Nestor
              </p>
            </div>
            <div className="mb-8 w-full text-left">
              <p className="text-3xl leading-12 font-bold text-black tracking-tighter">
                The scheduler completely designed for you
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mb-0">
              <div className="w-full h-auto rounded-3xl p-0.5 bg-white">
                <div className="flex items-center justify-between">
                  <button className="p-3.5 bg-black rounded-3xl flex items-center">
                    <div className="text-white text-base font-medium leading-none">Sign in</div>
                    <div className="text-white text-xs font-black leading-none px-4">
                      <FontAwesomeIcon icon={faArrowRightToBracket} />
                    </div>
                  </button>
                  <button
                    onClick={signUpClicked}
                    className="p-3.5 bg-white rounded-3xl flex items-center"
                  >
                    <div className="text-black text-base font-medium leading-none">Register</div>
                    <div className="text-stone-900 text-xs font-black leading-none pl-4">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-8 pt-12 pb-8  md:mb-12 space-y-4">
            <form>
              <div className="w-full pb-4">
                <PrimaryTextField
                  label="Email"
                  type="email"
                  value={email}
                  fullWidth={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  variant="filled"
                  size="small"
                />
              </div>

              <div className="flex justify-between items-center pt-6">
                <button
                  disabled={isValid}
                  onClick={enterEmailClicked}
                  className={`bg-white text-black py-2 w-full px-4 rounded-full ${
                    isValid ? 'opacity-50' : ''
                  }`}
                >
                  Sign In
                </button>
              </div>
            </form>
            <div className="pt-4 items-center justify-center flex">
              <button
                onClick={handleGoogleLogin}
                className="bg-black w-full text-base relative inline-flex align-middle justify-center min-h-11 rounded-full m-0 text-center px-4 py-2"
              >
                <div className="gap-2 flex justify-center align-center text-white">
                  {loginWithGoogleIsLoading ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} color="white" spin />
                    </div>
                  ) : (
                    <>
                      <div className="pt-1">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 8.1875C16 12.625 12.9688 15.75 8.5 15.75C4.1875 15.75 0.75 12.3125 0.75 8C0.75 3.71875 4.1875 0.25 8.5 0.25C10.5625 0.25 12.3438 1.03125 13.6875 2.28125L11.5625 4.3125C8.8125 1.65625 3.6875 3.65625 3.6875 8C3.6875 10.7188 5.84375 12.9062 8.5 12.9062C11.5625 12.9062 12.7188 10.7188 12.875 9.5625H8.5V6.90625H15.875C15.9375 7.3125 16 7.6875 16 8.1875Z"
                            fill="#DCDCDC"
                          />
                        </svg>
                      </div>
                      Log in with Google
                    </>
                  )}
                </div>
              </button>
            </div>
            <div className="text-xs text-center text-black font-inter">
              This site is protected by reCAPTCHA and the Google
              <a className="underline" href="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a className="underline" href="https://policies.google.com/terms">
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Login;
