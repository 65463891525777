import { PaginationMeta } from '@book-nestor-monorepo/nestor-dtos';
import {
  PaginatedBookingConversationItem,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { useContext, useEffect, useState } from 'react';
import LoadingDots from '../../components/loading';
import { AuthContext } from '../../contexts/authContext';
import { useAgent } from '../../contexts/v2/agentContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { useDrawer } from '../../contexts/v2/rightDrawerContext';
import { getAgentConversations } from '../../libs/services/agent-conversations';
import { hasAccess } from '../../libs/utils/hasAccess';
import { ConversationsBrochure } from './conversations-brochure';
import { ConversationsTable } from './conversations-table';
import { AgentConversationDrawer } from './right-drawer-components/agent-conversation-drawer';
export const ConversationsListV2 = () => {
  const { toggleDrawer, setDrawerContent, isOpen } = useDrawer();
  const { conversationMessages } = useAgent();
  const { bgColor } = useAppExperience();
  const [conversations, setConversations] = useState<PaginatedBookingConversationItem>({
    items: [],
    meta: {
      take: 0,
      hasMore: false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const user = useContext(AuthContext);
  const userCanUseCampaigns = hasAccess(user.token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT);

  const handleToggleContactDrawerClick = () => {
    if (isOpen) {
      toggleDrawer();
    } else {
      toggleDrawer();
      toggleDrawer();
    }
  };
  const handleConversationClick = (conversationId: string) => {
    if (isOpen) {
      setDrawerContent(
        <AgentConversationDrawer
          conversationId={conversationId}
          toggleContactDrawer={handleToggleContactDrawerClick}
        />,
        {
          darkMode: false,
        },
      );
    } else {
      setDrawerContent(
        <AgentConversationDrawer
          conversationId={conversationId}
          toggleContactDrawer={handleToggleContactDrawerClick}
        />,
        {
          darkMode: false,
        },
      );
      toggleDrawer();
    }
  };

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      const conversations = await getAgentConversations(user?.user?.id as string, 10, undefined);
      setConversations(conversations);
      setIsLoading(false);
    }

    loadData();
  }, [userCanUseCampaigns]);

  const handleNextConversations = async (meta: PaginationMeta) => {
    setIsLoading(true);
    const conversations = await getAgentConversations(
      user?.user?.id as string,
      10,
      meta.nextCursor,
    );
    setConversations(conversations);
    setIsLoading(false);
  };

  return (
    <div className={`flex flex-col h-auto bg-[${bgColor}]`}>
      <div className="flex flex-col h-auto w-full overflow-hidden">
        {isLoading ? (
          <LoadingDots skeletonProps={{ count: 4 }} />
        ) : (
          <>
            {conversations.items.length > 0 && userCanUseCampaigns ? (
              <ConversationsTable
                conversations={conversations.items}
                conversationsMeta={conversations.meta}
                handleNextConversations={handleNextConversations}
                conversationMessages={conversationMessages}
                handleConversationClick={handleConversationClick}
              />
            ) : (
              <ConversationsBrochure />
            )}
          </>
        )}
      </div>
    </div>
  );
};
