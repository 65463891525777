export function formatPhoneForAgentBooking(phoneNumber: string) {
  if (!phoneNumber) return phoneNumber;

  let formattedPhoneNumber = phoneNumber;

  if (formattedPhoneNumber.startsWith('+1')) {
    formattedPhoneNumber = formattedPhoneNumber.substring(2);
  } else if (formattedPhoneNumber.startsWith('+')) {
    formattedPhoneNumber = formattedPhoneNumber.substring(1);
  }
  formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, '');

  return `${formattedPhoneNumber}`;
}
