import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import axios from 'axios';

export const sendEmail = async (toAddress: string, bookingUrl: string): Promise<Event[]> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return [];

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/emails/booking-link-email`,
      { to_address: toAddress, booking_link: bookingUrl },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data.items || [];
  } catch (error) {
    throw new Error('Failed to send email');
  }
};
