import { Insight, InsightsMetricType, ValueType } from '@book-nestor-monorepo/shared-types';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { environment } from '../../environments/environment';
import { getAllInsights } from '../../libs/services/insights';
import { formatCurrency, formatPercentage } from '../../libs/utils/currency.util';
import LoadingDots from '../loading';
import Card from './card';
import * as Sentry from '@sentry/react';

type InsightCardsType = {
  openInfoModal: (type: InsightsMetricType) => void;
};

export default function InsightCards({ openInfoModal }: InsightCardsType) {
  const authContext = useContext(AuthContext);
  const [insights, setInsights] = useState<Insight[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const user = authContext.user;
        try {
          if (!user) {
            return;
          }
          const insights = await getAllInsights(
            user?.id as string,
            `${environment.baseBookingUrl}/${authContext.user?.user_slug?.slug}`,
          );

          setInsights(insights || []);
        } catch (error) {
          Sentry.captureException(error);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [authContext.user]);

  const mapMetricToColor = (metricType: InsightsMetricType) => {
    switch (metricType) {
      case InsightsMetricType.TOTAL_REVENUE:
        return 'bg-[#333333]';
      case InsightsMetricType.BOOKING_COUNT:
        return 'bg-[#333333]';
      case InsightsMetricType.CONVERSION_RATE:
        return 'bg-[#333333]';
      default:
        return 'bg-[#333333]';
    }
  };

  const mapMetricToHelpColor = (metricType: InsightsMetricType) => {
    switch (metricType) {
      case InsightsMetricType.CONVERSION_RATE:
        return '#DADADA';
      default:
        return '#FFFFFF33';
    }
  };

  const mapMetricToTextColor = (metricType: InsightsMetricType) => {
    switch (metricType) {
      case InsightsMetricType.CONVERSION_RATE:
        return 'text-black';
      default:
        return 'text-white';
    }
  };

  const mapMetricToTitle = (metricType: InsightsMetricType) => {
    switch (metricType) {
      case InsightsMetricType.CONVERSION_RATE:
        return 'Conversion Rate';
      case InsightsMetricType.TOTAL_REVENUE:
        return 'Total Revenue';
      case InsightsMetricType.BOOKING_COUNT:
        return 'Total Bookings';
      case InsightsMetricType.SITE_VISIT:
        return 'Booking Page Visits';
      default:
        return metricType;
    }
  };

  const mapMetricToSize = (metricType: InsightsMetricType) => {
    switch (metricType) {
      case InsightsMetricType.SITE_VISIT:
        return 'lg';
      case InsightsMetricType.CONVERSION_RATE:
        return 'lg';
      default:
        return 'lg';
    }
  };

  if (isLoading) {
    return (
      <LoadingDots
        skeletonProps={{
          count: 2,
          height: 140,
          borderRadius: 10,
          style: { marginBottom: '4px' },
        }}
      />
    );
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-row  gap-2 w-full">
        {insights.slice(0, 2).map((item, index) => (
          <Card
            key={`insight-key-${index}`}
            title={
              item.value_type === ValueType.CURRENCY
                ? formatCurrency((item.value / 100) as number)
                : item.value_type === ValueType.PERCENTAGE
                  ? formatPercentage(item.value as number)
                  : item.value.toString()
            }
            helpColor={mapMetricToHelpColor(item.metric_type)}
            name={mapMetricToTitle(item.metric_type)}
            size={mapMetricToSize(item.metric_type)}
            color={mapMetricToColor(item.metric_type)}
            textColor={mapMetricToTextColor(item.metric_type)}
            openInfoModal={() => openInfoModal(item.metric_type)}
            rawValue={item.value?.toString()}
          />
        ))}
      </div>
      <div className="flex flex-row  gap-2 w-full">
        {insights.slice(2, 4).map((item, index) => (
          <Card
            key={`insight-key-${index}`}
            title={
              item.value_type === ValueType.CURRENCY
                ? formatCurrency((item.value / 100) as number)
                : item.value_type === ValueType.PERCENTAGE
                  ? formatPercentage(item.value as number)
                  : item.value.toString()
            }
            helpColor={mapMetricToHelpColor(item.metric_type)}
            name={mapMetricToTitle(item.metric_type)}
            size={mapMetricToSize(item.metric_type)}
            color={mapMetricToColor(item.metric_type)}
            textColor={mapMetricToTextColor(item.metric_type)}
            openInfoModal={() => openInfoModal(item.metric_type)}
            rawValue={item.value?.toString()}
          />
        ))}
      </div>
    </div>
  );
}
