import { Contact, Review } from '@book-nestor-monorepo/shared-types';
import { faCheck, faEnvelope, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { environment } from '../../environments/environment';
import { createReview } from '../../libs/services/reviews';
import PrimaryTextFieldLight from '../formLibrary/primaryTextInputLight';
import { createTextMessage } from '../../libs/services/text.service';
import { getServicePhoneDetailsByUser } from '../../libs/services/voice';
import { formatPhoneForTextMessage } from '../../libs/utils/phone.util';
import * as Sentry from '@sentry/react';

interface SendReviewType {
  title: string;
  onClose: () => void;
  contact: Contact;
}

interface FormData {
  email: string;
  phoneNumber?: string;
}

const SendReviewModal = ({ title, onClose, contact }: SendReviewType) => {
  const authContext = useContext(AuthContext);
  const history = useNavigate();
  const [contactReview, setContactReview] = useState<Review | undefined>(undefined);
  const [emailSent, setEmailSent] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: contact.email || '',
    phoneNumber: contact.phone_number,
  });

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const sendReviewRequestEmail = async (e: any) => {
    try {
      e.preventDefault();
      const review = await createReview(authContext.user?.id as string, {
        contact_email: contact?.email,
        contact_name: contact?.name,
        reviewee_name: authContext.user?.first_name,
        deliver_email: true,
      });
      setEmailSent(true);
      setContactReview(review);
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const sendReviewRequestPhone = async (e: any) => {
    try {
      e.preventDefault();
      const review = await createReview(authContext.user?.id as string, {
        contact_email: contact?.email,
        contact_name: contact?.name,
        reviewee_name: authContext.user?.first_name,
        deliver_email: false,
      });
      let userServicePhoneResponse;
      try {
        userServicePhoneResponse = await getServicePhoneDetailsByUser(authContext.user?.id || '');
      } catch (error) {
        Sentry.captureException(error);
      }

      if (userServicePhoneResponse?.phone_number && contact.phone_number) {
        try {
          const message = `Leave a review for ${authContext.user?.first_name} using this link: ${environment.reactAppBaseUrl}/book/${authContext.user?.user_slug?.slug}/reviews/${review.id}`;
          await createTextMessage(
            formatPhoneForTextMessage(userServicePhoneResponse.phone_number),
            formatPhoneForTextMessage(contact.phone_number),
            message,
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      setMessageSent(true);
      setContactReview(review);
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="flex flex-col w-full pt-8">
      <div
        className={`flex flex-row items-center ${title ? 'justify-between' : 'justify-end'} h-12 mb-4`}
      >
        {title && <p className="font-inter font-medium text-base leading-4 text-white">{title}</p>}
        <span
          className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-white"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9  mb-2">
        <p className="text-[41.89px] leading-[48px] text-white font-extralight">
          Send Review Request?
        </p>
      </div>
      <div className="flex gap-2 mb-4">
        {contact.phone_number ? (
          <>
            <ReactInputMask
              mask="(999) 999-9999"
              maskChar="_"
              minLength={10}
              value={formData.phoneNumber}
              onChange={onChangeField}
              name="phoneNumber"
            >
              <PrimaryTextFieldLight
                label="Mobile"
                type="tel"
                value={formData.phoneNumber}
                onChange={onChangeField}
                name="phoneNumber"
              />
            </ReactInputMask>
            <button
              onClick={sendReviewRequestPhone}
              className="rounded-full border border-white flex items-center justify-center h-12 w-12 cursor-pointer"
            >
              {messageSent ? (
                <FontAwesomeIcon icon={faCheck} color="#22C55E" />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} color="white" />
              )}
            </button>
          </>
        ) : (
          <p className="text-white pb-2">No phone number</p>
        )}
      </div>
      <div className="flex">
        <div className="flex w-full items-center gap-2">
          <div className="flex items-center relative">
            <PrimaryTextFieldLight
              label="Email"
              color="primary"
              onChange={onChangeField}
              type="text"
              name="email"
              value={formData.email}
            />
          </div>
          <button
            onClick={sendReviewRequestEmail}
            className="rounded-full border border-white flex items-center justify-center h-12 w-12 cursor-pointer"
          >
            {emailSent ? (
              <FontAwesomeIcon icon={faCheck} color="#22C55E" />
            ) : (
              <FontAwesomeIcon icon={faEnvelope} color="white" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendReviewModal;
