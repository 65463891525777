import { useModal } from '../../../contexts/modalContext';

const Modal: React.FC = () => {
  const { isOpen, modalContent, closeModal } = useModal();

  return isOpen ? (
    <div className="modal modal-container w-full flex absolute h-full top-0 left-0 z-10 backdrop-blur-sm">
      <div className="z-20 h-full w-full top-0 left-0 bg-transparent fixed" onClick={closeModal} />
      <div className="z-50 w-full">{modalContent}</div>
    </div>
  ) : null;
};

export default Modal;
