import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { EventType, EventCategory } from '@book-nestor-monorepo/shared-types';
import { Card, CardContent, Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus } from '@fortawesome/pro-light-svg-icons';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { AuthContext } from '../../contexts/authContext';
import {
  createEventCategory,
  getEventCategories,
} from '../../libs/services/event-category.service';
import { getEventTypes } from '../../libs/services/event-type';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { FormattedTask, useQuickStart } from '../../contexts/quickStartContext';

export const ServicesPageV2 = () => {
  const { bgColor } = useAppExperience();
  const navigate = useNavigate();
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    services: true,
    categories: true,
  });
  const authContext = useContext(AuthContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onboardingContext = queryParams.get('onboardingContext');
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    undefined,
  );
  const { selectedTask, remainingFormattedTasks } = useQuickStart();

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    }
  }, [selectedTask]);

  const [isLoading, setIsLoading] = useState(false);

  const toggleCard = (card: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [card]: !prev[card],
    }));
  };

  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [eventCategories, setEventCategories] = useState<EventCategory[]>([]);
  const [newCategoryName, setNewCategoryName] = useState<string>('');

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = authContext.user;
      if (!user?.id) return;

      const [eventTypes, eventCategories] = await Promise.all([
        getEventTypes(),
        getEventCategories(),
      ]);

      setEventTypes(eventTypes);
      setEventCategories(eventCategories);
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user]);

  const handleAddCategory = async () => {
    if (!newCategoryName) return;

    const newCategory = await createEventCategory({ name: newCategoryName });
    setEventCategories([...eventCategories, newCategory]);
    setNewCategoryName('');
  };

  if (isLoading) {
    return <MultiPartSkeleton color="#DADCDC" />;
  }

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}]`}>
      <div className="flex flex-col h-full w-full overflow-y-scroll hide-scrollbar">
        <div className="flex w-full h-auto items-start pl-4">
          {onboardingContext && selectedFormattedTask ? (
            <div className="flex flex-col items-start">
              <span className="text-[14px] font-avenir text-white">
                Step {selectedFormattedTask?.number}
              </span>
              <span className="text-[24px] font-inter text-white">
                {selectedFormattedTask?.title}
              </span>
            </div>
          ) : (
            <span className="text-[16px] font-avenir text-white">Your services</span>
          )}
        </div>
        {/* Services */}
        <div className="flex w-full items-start px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden"
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('services')}
            >
              <div className="flex items-center w-full ">
                <div className="flex pl-4 justify-between items-start w-full">
                  <div className="flex w-full">
                    <span className="font-avenir text-[16px] text-[#727272]">Current Services</span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={expandedCards.services} unmountOnExit>
              <CardContent
                sx={{
                  padding: '8px',
                  backgroundColor: 'white',
                  '&:last-child': {
                    paddingBottom: '8px',
                  },
                }}
              >
                <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full p-2">
                  <div className="flex flex-col h-full ">
                    <div className="flex flex-col items-center text-center bg-white rounded-3xl h-full">
                      {eventTypes.map((eventType, index) => (
                        <div
                          key={eventType.id}
                          className="w-full h-12 my-1 justify-between items-center gap-1 inline-flex"
                        >
                          <div className="flex-grow items-center gap-1">
                            <div
                              className={`px-4 py-3 rounded-full w-auto justify-start items-center gap-2.5 flex ${
                                index % 2 === 0 ? 'bg-[#f5f6f6]' : 'bg-white'
                              }`}
                            >
                              <div className="text-black text-[14px] leading-6 font-normal font-avenir capitalize">
                                {eventType.title}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-1">
                            <div
                              className={`px-4 py-3 rounded-full w-auto  justify-center items-center gap-2.5 flex ${
                                index % 2 === 0 ? 'bg-[#f5f6f6] ' : 'bg-white'
                              }`}
                            >
                              <div className="text-black text-[14px] leading-6 font-normal font-avenir capitalize">
                                {eventType.duration_mins} min
                              </div>
                            </div>

                            <div
                              className={`px-4 py-3 rounded-full w-auto  justify-center items-center gap-2.5 flex ${
                                index % 2 === 0 ? 'bg-[#f5f6f6]' : 'bg-white'
                              }`}
                            >
                              <div className="text-black text-[14px] leading-6 font-normal font-avenir capitalize">
                                ${eventType.price}
                              </div>
                            </div>

                            <div
                              className="w-12 h-12 justify-center items-center flex cursor-pointer"
                              onClick={() => navigate(`/services/${eventType.id}`)}
                            >
                              <div className="w-12 h-12 py-4 bg-black rounded-[59px] border border-black/20 justify-center items-center inline-flex">
                                <div className="w-3 h-4 p-0.5 flex-col justify-center items-center inline-flex cursor-pointer">
                                  <div className="text-center text-black text-xs font-normal font-['Font Awesome 6 Pro'] capitalize">
                                    <FontAwesomeIcon
                                      icon={faArrowRight}
                                      color="white"
                                      className="text-[12px] -rotate-45"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* Add new service button */}
                      <div className="w-full h-12 my-1 justify-end items-center gap-1 inline-flex">
                        <div className="flex items-center gap-1">
                          <button
                            className="h-12 justify-center items-center inline-flex"
                            onClick={() => navigate('/services/new')}
                          >
                            <div className="w-12 h-12 py-4 bg-[#222222] rounded-[59px] border justify-center items-center inline-flex">
                              <div className="w-3 h-4 pl-px flex-col justify-center items-center inline-flex">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color="white"
                                  className="text-[12px]"
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Collapse>
          </Card>
        </div>
        {/* Categories */}
        <div className="flex w-full items-start px-1 pt-4">
          <Card
            className="mb-4 shadow-md rounded-3xl overflow-hidden"
            sx={{
              borderRadius: '30px',
              backgroundColor: 'white',
              marginBottom: '8px',
              minHeight: '60px',
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                padding: '8px',
                backgroundColor: 'white',
                '&:last-child': {
                  paddingBottom: '8px',
                },
              }}
              className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
              onClick={() => toggleCard('categories')}
            >
              <div className="flex items-center w-full">
                <div className="flex pl-4 justify-between items-start w-full">
                  <div className="flex w-full">
                    <span className="font-avenir text-[16px] text-[#727272]">Categories</span>
                  </div>
                </div>
              </div>
            </CardContent>
            <Collapse in={expandedCards.categories} unmountOnExit>
              <CardContent
                sx={{
                  padding: '8px',
                  backgroundColor: 'white',
                  '&:last-child': {
                    paddingBottom: '8px',
                  },
                }}
              >
                <div className="bg-white rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full p-2">
                  <div className="flex flex-col h-full ">
                    {/* Add new category section */}
                    <div className="justify-start items-center gap-1 inline-flex">
                      <PrimaryTextField
                        label="Enter category name"
                        color="primary"
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        type="text"
                        name="categoryName"
                        variant="filled"
                        fullWidth={true}
                        required={true}
                        value={newCategoryName}
                      />
                      <button
                        className="w-12 justify-center items-center flex"
                        onClick={handleAddCategory}
                      >
                        <div className="w-12 h-12 py-4 bg-black rounded-[59px] border justify-center items-center inline-flex">
                          <div className="w-3 h-4 pl-px flex-col justify-center items-center inline-flex">
                            <FontAwesomeIcon icon={faPlus} color="white" className="text-[12px]" />
                          </div>
                        </div>
                      </button>
                    </div>
                    {/* Categories list section */}
                    <div className="mt-4 justify-start items-center gap-1 inline-flex">
                      {eventCategories
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((eventCategory) => (
                          <div key={eventCategory.id} className="justify-start items-start flex">
                            <div className="px-6 py-3 bg-black rounded-[34px] border justify-start items-center gap-2.5 flex">
                              <div className="text-white text-sm font-normal font-['Avenir Next'] capitalize leading-normal">
                                {eventCategory.name}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
};
