'use client';

import {
  PaymentMethod,
  SubscriptionPlan,
  SubscriptionProduct,
  SubscriptionProductPrice,
} from '@book-nestor-monorepo/shared-types';
import { faArrowRightFromBracket, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingDots from '../../components/loading';
import { createPortalSession } from '../../libs/services/subscription.service';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const productContents: Record<SubscriptionPlan, any> = {
  [SubscriptionPlan.BASIC]: {
    description: "All the same goodies for free. Don't let us go broke, pay for PRO.",
    features: [
      'Beautiful booking site',
      'Scheduler app',
      'Contact management',
      'Insights',
      'Payments with Stripe',
    ],
    footer: 'Free Forever',
  },
  [SubscriptionPlan.INACTIVE]: {
    description: "All the same goodies for free. Don't let us go broke, pay for PRO.",
    features: ['Beautiful booking site', 'Scheduler App', 'Insights', 'Payments with Stripe'],
    footer: 'Free Forever',
  },
  [SubscriptionPlan.PRO]: {
    description: 'Ideal for growing businesses.  Includes everything from Basic and more.',
    features: ['Customize booking link', 'Remove Nestor branding', 'Customer reviews'],
  },
  [SubscriptionPlan.STUDIO]: {
    description: 'For individuals and businesses who are looking to scale.',
    features: [
      'Nestor Intelligence booking assistant',
      'Lost booking recovery',
      'Nestor Intelligence contact management',
    ],
  },
};

export const SubscriptionV3 = ({
  products,
  selectedProduct,
  selectedPrice,
  changeSubscriptionAction,
  isLoading,
  handleChangeSubscriptionClick,
}: {
  products: SubscriptionProduct[];
  selectedProduct: SubscriptionProduct | undefined;
  selectedPrice: SubscriptionProductPrice | undefined;
  changeSubscriptionAction: (
    product: SubscriptionProductPrice,
    paymentMethod?: PaymentMethod,
    promoCode?: string,
  ) => void;
  isLoading: boolean;
  handleChangeSubscriptionClick: (product: SubscriptionProduct) => void;
}) => {
  const isActiveProduct = (product: SubscriptionProduct) => {
    return product.id === selectedProduct?.id;
  };

  const formatPriceToDisplay = (priceObject?: SubscriptionProductPrice): string => {
    if (!priceObject) return '';

    const _isMonthly = priceObject.recurringInterval === 'month';
    const price = _isMonthly ? priceObject.price / 100 : priceObject.price / 100 / 12;
    if (price === 0) return '$0';
    return `$${price.toFixed(2)}`;
  };

  const handleManageBillingClick = async () => {
    const portalSession = await createPortalSession();
    window.open(portalSession.url, '_blank');
  };

  return (
    <div className="bg-white py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-green-500">Pricing</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl font-avenir">
            Your Nestor Subscription
          </p>
          <span className="text-sm text-gray-600 bg-orange-100 border border-orange-300 rounded-full px-3 py-1 inline-flex items-center mt-2">
            All paid plans include a 30-day free trial.
          </span>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
          Choose the plan that best fits your needs and budget. Remember you can upgrade or cancel
          at any time.
        </p>
        <span className="text-[16px] font-avenir text-white">
          <button
            className="h-10  text-black px-4 text-[14px] font-avenir underline"
            onClick={handleManageBillingClick}
          >
            Your Billing Portal{' '}
            <FontAwesomeIcon icon={faArrowRightFromBracket} className="mt-1 pl-1" />
          </button>
        </span>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {isLoading ? (
            <LoadingDots
              skeletonProps={{
                count: 4,
                height: 30,
                borderRadius: 10,
                style: { marginBottom: '10px' },
              }}
            />
          ) : (
            products.map((product) => (
              <div
                key={product.id}
                className={classNames(
                  isActiveProduct(product) ? 'ring-2 ring-green-500' : 'ring-1 ring-gray-200',
                  'rounded-3xl p-8 xl:p-10',
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={product.id}
                    className={classNames(
                      isActiveProduct(product) ? 'text-green-500' : 'text-gray-900',
                      'text-lg/8 font-semibold',
                    )}
                  >
                    {product.name}
                  </h3>
                  {isActiveProduct(product) ? (
                    <p className="rounded-full bg-green-500/10 px-2.5 py-1 text-xs/5 font-semibold text-black">
                      Current Plan
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm/6 text-gray-600 text-left">
                  {productContents[product.type].description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-semibold tracking-tight text-gray-900">
                    {formatPriceToDisplay(product.yearlyPrice)}
                  </span>
                  <span className="text-sm/6 font-semibold text-gray-600">/month</span>
                </p>
                <button
                  onClick={() => handleChangeSubscriptionClick(product)}
                  aria-describedby={product.id}
                  className={` text-white bg-black ring-1 ring-inset mt-6 block rounded-full px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                    isActiveProduct(product)
                      ? 'opacity-50 pointer-events-none cursor-not-allowed'
                      : ''
                  }`}
                >
                  {isActiveProduct(product) ? 'Current Plan' : 'Switch Plan'}
                </button>
                <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600 xl:mt-10">
                  {productContents[product.type].features.map((feature: string) => (
                    <li key={feature} className="flex gap-x-3">
                      <FontAwesomeIcon
                        icon={faCheck}
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-green-500"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
