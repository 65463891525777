import { InsightsMetricType, USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../../../components/loading';
import { AgentAiBorder } from '../../../components/v2/Agent/AgentAiBorder';
import { AuthContext } from '../../../contexts/authContext';
import { useAgent } from '../../../contexts/v2/agentContext';
import { useDrawer } from '../../../contexts/v2/rightDrawerContext';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { AgentBrochure } from '../agent-brochure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faBullhorn, faCalendar, faMessages } from '@fortawesome/pro-solid-svg-icons';

export const AgentSummaryDrawer = () => {
  const { toggleDrawer } = useDrawer();
  const { agentMetrics, isLoading: isLoadingAgentMetrics, fetchConversationMetrics } = useAgent();
  const navigate = useNavigate();
  const user = useContext(AuthContext);
  const userCanUseAgent = hasAccess(user.token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT);
  const pfp_url = user.servicePhone?.agent?.pfp_url;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('24h');
  const agentPills = {
    Brian: ['American', 'Young', 'Male'],
    Zuri: ['American', 'Older', 'Female'],
    Marissa: ['American', 'Young', 'Female'],
    Andrew: ['American', 'Young', 'Male'],
  };

  const stats = [
    {
      id: 1,
      name: 'Total Bookings',
      value: agentMetrics?.find(
        (metric) => metric.metric_type === InsightsMetricType.NI_BOOKING_COUNT_LAST_24_HOURS,
      )?.value,
    },
    {
      id: 2,
      name: 'Total Conversations',
      value: agentMetrics?.find(
        (metric) => metric.metric_type === InsightsMetricType.NI_CONVERSATION_COUNT,
      )?.value,
    },
    {
      id: 3,
      name: 'Total Messages',
      value: agentMetrics?.find(
        (metric) => metric.metric_type === InsightsMetricType.NI_MESSAGE_COUNT,
      )?.value,
    },
    {
      id: 4,
      name: 'Total Recovery Conversations',
      value: agentMetrics?.find(
        (metric) => metric.metric_type === InsightsMetricType.NI_RECOVERY_CONVERSATION_COUNT,
      )?.value,
    },
  ];

  const handleSwitchAgent = () => {
    toggleDrawer();
    navigate(`/communication`);
  };

  const handleSelectedTimePeriodChange = async (period: '24h' | '7d' | '30d') => {
    setSelectedTimePeriod(period);
    await fetchConversationMetrics(true, period);
  };

  return (
    <div className={`flex flex-col h-full bg-black`}>
      <div className="flex flex-col h-full w-full overflow-hidden">
        {isLoading ? (
          <LoadingDots skeletonProps={{ count: 4 }} />
        ) : (
          <div className="flex flex-col h-full">
            {agentMetrics && userCanUseAgent ? (
              <div className="flex flex-col px-4 py-2 font-avenir flex-grow">
                <div className="flex flex-col border-b border-gray-100/10 mb-8">
                  <span className="flex min-w-0 items-center gap-3 mb-2">
                    {pfp_url && (
                      <AgentAiBorder>
                        <img src={pfp_url} height={50} width={50} alt="Connector black" />
                      </AgentAiBorder>
                    )}
                    <span className="min-w-0">
                      <span className="block truncate text-[16px] font-medium text-white ">
                        {user.servicePhone?.agent?.name}
                      </span>
                      <span className="block truncate text-[12px] font-normal text-white">
                        <span
                          className="text-[12px] font-inter text-white underline cursor-pointer"
                          onClick={handleSwitchAgent}
                        >
                          Switch Agent
                        </span>
                      </span>
                    </span>
                  </span>
                  <div className="flex mb-4 ">
                    {agentPills[user.servicePhone?.agent?.name as keyof typeof agentPills]?.map(
                      (pill) => (
                        <div key={pill} className="flex flex-row">
                          <div className="flex h-[19px] w-auto items-center p-2 bg-white rounded-full mr-1">
                            <span className="text-[10px] font-avenir">{pill}</span>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <h2 className="text-balance text-[28px] font-semibold  text-white font-inter">
                  Agent Activity Summary
                </h2>

                <span className="isolate inline-flex rounded-md shadow-sm mt-4">
                  <button
                    type="button"
                    className={`relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-white  focus:z-10 ${
                      selectedTimePeriod === '24h' ? 'bg-black text-white' : 'bg-white text-black'
                    }`}
                    onClick={() => handleSelectedTimePeriodChange('24h')}
                  >
                    24h
                  </button>
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center  px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-white  focus:z-10 ${
                      selectedTimePeriod === '7d' ? 'bg-black text-white' : 'bg-white text-black'
                    }`}
                    onClick={() => handleSelectedTimePeriodChange('7d')}
                  >
                    7d
                  </button>
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-white  focus:z-10 ${
                      selectedTimePeriod === '30d' ? 'bg-black text-white' : 'bg-white text-black'
                    }`}
                    onClick={() => handleSelectedTimePeriodChange('30d')}
                  >
                    30d
                  </button>
                </span>

                <dl className="mt-8 grid max-w-xl grid-cols-1 gap-8  xl:mt-16">
                  {stats?.map((stat) => (
                    <div
                      key={stat.id}
                      className="flex flex-col gap-y-3 border-l border-gray-100/10 pl-6"
                    >
                      <dt className="text-sm/6 text-white">{stat.name}</dt>
                      <dd className="order-first text-3xl font-semibold tracking-tight text-gray-200">
                        {isLoadingAgentMetrics ? (
                          <LoadingDots skeletonProps={{ count: 1, width: '20px' }} />
                        ) : (
                          stat.value
                        )}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            ) : (
              <div className="flex w-full h-full justify-center items-center">
                <AgentBrochure />
              </div>
            )}
            <div className="flex justify-around items-center bg-black p-4 mt-auto border-t border-gray-100/10">
              <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                  navigate('/conversations');
                  toggleDrawer();
                }}
              >
                <FontAwesomeIcon icon={faMessages} className="text-white mb-1" />
                <button className="text-white font-avenir text-[12px]">Conversations</button>
              </div>
              <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                  navigate('/campaigns');
                  toggleDrawer();
                }}
              >
                <FontAwesomeIcon icon={faBullhorn} className="text-white mb-1" />
                <button className="text-white font-avenir text-[12px]">Campaigns</button>
              </div>
              <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                  navigate('/bookings');
                  toggleDrawer();
                }}
              >
                <FontAwesomeIcon icon={faCalendar} className="text-white mb-1" />
                <button className="text-white font-avenir text-[12px]">Fill a Slot</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
