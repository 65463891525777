import { Notification, NotificationType } from '@book-nestor-monorepo/shared-types';
import { faBellRing, faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import NotificationModalContent from './NotificationModal';

const NotificationIcon = ({
  messages: initialMessages,
  height = 48,
  width = 48,
}: {
  messages: Notification[];
  height?: number;
  width?: number;
}) => {
  const [messages, setMessages] = useState(initialMessages);
  const authContext = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleModalOpen = () => {
    openMuiModal(<NotificationModalContent messages={messages} onClose={closeMuiModal} />, 3);
  };

  useEffect(() => {
    if (
      authContext.user &&
      authContext.user.payment_connect_data?.connected_account_id &&
      !authContext.user.payment_connect_data?.payment_requirements_complete
    ) {
      const newMessage: Notification = {
        title: 'Stripe Setup Required',
        type: NotificationType.SYSTEM_ALERT,
        content: 'Please complete your Stripe setup to accept payments.',
        createdAt: Date.now(),
        link: authContext.user.payment_connect_data.complete_requirements_link,
      };
      const existingSystemAlert = messages.some(
        (message) => message.type === NotificationType.SYSTEM_ALERT,
      );
      if (!existingSystemAlert) {
        setMessages([...messages, newMessage]);
      }
    }
  }, []);

  return (
    <button
      type="button"
      className="-m-2.5 p-2.5 text-[#6b6b6b] hover:text-gray-500 cursor-pointer flex"
      onClick={() => handleModalOpen()}
    >
      <span className="sr-only">View notifications</span>
      {messages.length > 0 ? (
        <FontAwesomeIcon icon={faBellRing} className="size-5 lg:size-6 " />
      ) : (
        <FontAwesomeIcon icon={faBell} className="size-5 lg:size-6 " />
      )}
    </button>
  );
};

export default NotificationIcon;
