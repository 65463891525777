interface AuthLayoutTypes {
  children: React.ReactNode;
}

export function AuthLayout({ children }: AuthLayoutTypes) {
  return (
    <>
      {/* tablet */}
      <div className="hidden md:flex lg:hidden relative bg-white items-center w-auto h-screen gap-2 overflow-none">
        {/* <div className="flex w-full h-full items-end justify-center bg-white bg-center   max-h-[900px]"> */}
        {/* <div className="w-[700px] h-full overflow-hidden "> */}
        <div className="w-full bg-[url('/assets/onboarding/auth-bg.jpg')] bg-cover bg-center flex flex-col items-center overflow-hidden h-full">
          <div className="flex  w-[388px] h-auto overflow-hidden  rounded-bl-3xl rounded-br-3xl justify-center bg-black ">
            <div className="w-full h-auto overflow-y-auto ">{children}</div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>

      {/* desktop */}
      <div className="hidden lg:flex relative bg-white md:py-[10px] 2xl:py-[40px] md:px-2 2xl:px-[14px] items-center w-auto h-screen gap-2 ">
        <div className="flex w-full h-full items-end justify-center bg-white bg-center  pl-[28px] max-h-[900px]">
          <h2 className="flex flex-col   text-black text-left items-left justify-center h-full max-w-[750px]">
            <div className="flex flex-row text-[58.7px] leading-[68.8px] tracking-tighter">
              <span>
                <span className=" font-inter font-light">We invite you to </span>

                <span className="font-normal font-lora italic">grow your business </span>
                <span className=" font-inter font-light">and </span>
                <span className="font-inter font-extrabold">try </span>
                <span className="font-normal font-lora italic">MyNestor </span>
                <div className="flex flex-row relative h-[60px]">
                  <img
                    src="/assets/onboarding/black circle.jpg"
                    alt="Person 1"
                    className="w-[60px] h-[60px] rounded-full border-2 border-white -mr-6"
                  />
                  <img
                    src="/assets/onboarding/auth-man-professional.jpg"
                    alt="Person 2"
                    className="w-[90px] h-[60px] rounded-full border-2 border-white -mr-8"
                  />
                  <img
                    src="/assets/onboarding/auth-woman-professional.jpg"
                    alt="Person 3"
                    className="w-[90px] h-[60px] rounded-full border-2 border-white"
                  />
                  <span className="font-inter font-extrabold pl-2">for free.</span>
                </div>
              </span>
            </div>
          </h2>
          <div className="w-[700px] min-w-[500px] h-full overflow-hidden ml-16">
            <div className="w-full h-full bg-[url('/assets/onboarding/auth-bg.jpg')] bg-cover bg-center rounded-3xl flex flex-col items-center ">
              <div className="flex  w-[388px] h-auto overflow-hidden  rounded-bl-3xl rounded-br-3xl justify-center bg-black">
                <div className="w-full h-full overflow-y-auto ">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="md:hidden">{children}</div>
    </>
  );
}
