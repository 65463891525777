import { TaskNames } from '@book-nestor-monorepo/shared-types';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useOnboarding } from '../../contexts/onboardingContext';
import { environment } from '../../environments/environment';
import { OnboardingLayout } from '../layouts/onboarding-layout';

export default function OnboardingOutro() {
  const [error, setError] = useState<string | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [debugInfo, setDebugInfo] = useState<string>('');
  const { completeTask, setCurrentRoute, onboardingState } = useOnboarding();
  const playerRef = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) return;
    const timer = setTimeout(() => {
      try {
        completeTask(TaskNames.onboardingOutro);
      } catch (err) {
        setError('Failed to complete onboarding task. Please try again later.');
        console.error('Error completing task:', err);
      }
    }, 7100);
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    setCurrentRoute();
  }, [onboardingState]);

  useEffect(() => {
    const handleResize = () => {
      if (playerRef.current && containerRef.current) {
        const player = playerRef.current.getInternalPlayer();
        const container = containerRef.current;
        const videoRatio = 16 / 9;
        const containerRatio = container.offsetWidth / container.offsetHeight;

        if (videoRatio > containerRatio) {
          player.style.width = '100%';
          player.style.height = 'auto';
        } else {
          player.style.width = 'auto';
          player.style.height = '100%';
        }
      }
    };

    window.addEventListener('resize', handleResize);
    if (isVideoReady) handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [isVideoReady]);

  const handleVideoError = (error: any) => {
    console.error('Video error:', error);
    setDebugInfo((prev) => prev + '\nVideo error: ' + JSON.stringify(error));
    completeTask(TaskNames.onboardingOutro);
  };

  const handleVideoReady = () => {
    setIsVideoReady(true);
    setIsLoading(false);
    setDebugInfo((prev) => prev + '\nVideo is ready');
  };

  const handleVideoBuffer = () => {
    setDebugInfo((prev) => prev + '\nVideo is buffering');
  };

  const handleVideoStart = () => {
    setDebugInfo((prev) => prev + '\nVideo has started playing');
  };

  const handleVideoPlay = () => {
    setDebugInfo((prev) => prev + '\nVideo is playing');
  };

  const videoUrl = `${environment.s3.bucketUrl}/MyNestor_Splash_Mobile.mp4`;

  return (
    <OnboardingLayout hideFlag>
      <div
        ref={containerRef}
        className={`relative w-full h-full overflow-hidden ${isLoading ? 'bg-[#ffffff]' : 'bg-[#000000]'}`}
      >
        {isLoading && (
          <div className="flex items-center h-full justify-center">
            <img
              className="w-[70px] h-[70px] object-contain "
              alt=""
              src="/assets/nestor-logo-dark.gif"
            />
          </div>
        )}
        {!error && (
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            playing
            loop
            muted
            playsinline
            width="100%"
            height="100%"
            onReady={handleVideoReady}
            onError={handleVideoError}
            onBuffer={handleVideoBuffer}
            onStart={handleVideoStart}
            onPlay={handleVideoPlay}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'anonymous',
                },
                forceVideo: true,
                forceHLS: false,
                forceDASH: false,
              },
            }}
          />
        )}
      </div>
    </OnboardingLayout>
  );
}
