import { FC, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookingCardItem from './BookingCardItem';
import { EventType } from '@book-nestor-monorepo/shared-types';

interface IProps {
  label: string;
  eventTypes: EventType[];
  contactId?: string;
}

const services = [
  '/assets/bookings/img-2.jpg',
  '/assets/bookings/img-3.jpg',
  '/assets/bookings/img-4.jpg',
  '/assets/bookings/img-5.jpg',
  '/assets/bookings/img-2.jpg',
  '/assets/bookings/img-3.jpg',
];

const BookingService: FC<IProps> = ({ label, eventTypes, contactId }) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="mt-8">
      <button onClick={() => setOpen(!open)} className="flex items-center gap-[10px]">
        <h3 className="text-2xl">{label}</h3>
        <FontAwesomeIcon
          className={`w-3 h-4 transition-all duration-200 ${!open ? '-rotate-90' : ''}`}
          icon={faChevronDown}
          color="#000"
        />
      </button>
      <div
        className={`grid grid-cols-4 gap-3 lg:gap-5 mt-6 overflow-hidden transition-all duration-200 ease-in-out`}
        style={{
          maxHeight: open ? `${services.length * 200}px` : '0',
        }}
      >
        {eventTypes.map((eventType) => (
          <BookingCardItem eventType={eventType} key={eventType.id} contactId={contactId} />
        ))}
      </div>
    </div>
  );
};

export default BookingService;
