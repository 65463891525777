import { ContactBooking, ContactNote } from '@book-nestor-monorepo/shared-types';
import { faCalendar, faMoneyBill, faNote } from '@fortawesome/pro-light-svg-icons';
import { Booking } from '@book-nestor-monorepo/shared-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { getContactBooking, getContactNotes } from '../../libs/services/contacts';
import { BookingDetailCard } from '../../components/v2/BookingControl/BookingDetailCard';
import { getBookingById } from '../../libs/services/bookings';
import { ContactBookingPaymentAction } from '../../components/v2/Contact/ContactBookingPaymentAction';
import { BookingPaymentCard } from '../../components/v2/BookingControl/BookingPaymentCard';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { ContactNotesCard } from '../../components/v2/Contact/ContactNotesCard';
import * as Sentry from '@sentry/react';
import { cleanCalcomEventTitleToJustEventType } from '../../libs/utils/string.util';

export const BookingControlV2 = () => {
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({
    details: true,
    'booking payment': true,
    notes: true,
  });
  const { contactId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get('bookingId');
  const { bgColor } = useAppExperience();
  const { user } = useContext(AuthContext);
  const [booking, setBooking] = useState<ContactBooking>();
  const [bookingNote, setBookingNote] = useState<ContactNote>();
  const [bookingDetails, setBookingDetails] = useState<Booking>();
  const [loading, setLoading] = useState(true);

  const { openMuiModal, closeMuiModal } = useMuiModal();
  const toggleCard = (cardName: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [cardName]: !prev[cardName],
    }));
  };

  const fetchBooking = async () => {
    setLoading(true);
    try {
      if (bookingId && contactId && user) {
        const fetchedBooking = await getContactBooking(
          user.id as string,
          contactId as string,
          bookingId as string,
        );
        setBooking(fetchedBooking);

        const booking = await getBookingById(user.id as string, bookingId as string);
        setBookingDetails(booking);

        let contactNotesRes: ContactNote[] = [];
        try {
          contactNotesRes = await getContactNotes(user.id as string, contactId);

          const note = contactNotesRes?.find((note) => note.appointment_id === booking.id);
          if (note) {
            setBookingNote(note);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooking();
  }, [bookingId, contactId, user]);

  if (loading) {
    return <MultiPartSkeleton color="#DADCDC" />;
  }

  if (!booking || !bookingDetails || !bookingDetails.contact) {
    return (
      <div className={`flex flex-col h-full bg-[${bgColor}] justify-start`}>
        <div className="flex flex-col h-full w-full">
          <div className="flex w-full h-auto items-start pl-4">
            <span className="text-[16px] font-avenir text-white">Booking not found</span>
          </div>
        </div>
      </div>
    );
  }

  interface CardProps {
    title: string;
    content: React.ReactNode;
    icon?: React.ReactNode;
    bgColor?: string;
    contentAction?: string;
    contentActionCallback?: () => void;
  }

  const renderCard = ({
    title,
    content,
    icon,
    bgColor = '#F5F5F7',
    contentAction,
    contentActionCallback,
  }: CardProps) => {
    const cardKey = title?.toLowerCase();
    const handleContentAction = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (contentActionCallback) {
        contentActionCallback();
      }
    };

    return (
      <Card
        className="mb-4 shadow-md rounded-3xl overflow-hidden"
        sx={{
          borderRadius: '30px',
          backgroundColor: bgColor,
          marginBottom: '8px',
          minHeight: '60px',
        }}
      >
        <CardContent
          sx={{
            padding: '8px',
            backgroundColor: bgColor,
            '&:last-child': {
              paddingBottom: '8px',
            },
          }}
          className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
          onClick={() => toggleCard(cardKey)}
        >
          <div className="flex items-center w-full">
            <div className="flex items-center">{icon ? icon : <></>}</div>
            <div className="flex pl-4 justify-between items-center w-full">
              <div className="flex">
                <span className="font-avenir font-bold text-[16px]">{title}</span>
              </div>
              {contentAction && (
                <div
                  className="flex pr-6 h-11 w-auto px-6 py-[2px] items-center rounded-full bg-[#000000]/10"
                  onClick={handleContentAction}
                >
                  <span className="font-inter text-[12px]">{contentAction}</span>
                </div>
              )}
            </div>
          </div>
        </CardContent>
        <Collapse in={expandedCards[cardKey]} unmountOnExit>
          <CardContent>{content}</CardContent>
        </Collapse>
      </Card>
    );
  };

  return (
    <div className={`flex flex-col h-full bg-[${bgColor}] justify-start`}>
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full h-auto items-start pl-4">
          <span className="text-[16px] font-avenir text-white">
            Appointment controls{' '}
            {bookingDetails.contact?.name ? `for ${bookingDetails.contact.name}` : ''}
          </span>
        </div>
        <div className="flex flex-col w-full pt-8">
          {booking &&
            bookingDetails &&
            renderCard({
              title: 'Details',
              content: <BookingDetailCard booking={bookingDetails} />,
              icon: (
                <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                  <FontAwesomeIcon icon={faCalendar} color="#000" className="text-[12px]" />
                </div>
              ),
              bgColor: '#E5E5E5',
            })}

          {booking &&
            renderCard({
              title: 'Booking Payment',
              content: <BookingPaymentCard booking={bookingDetails} />,
              icon: (
                <div className="h-11 w-11 bg-[#E5E5E5] rounded-full flex items-center justify-center">
                  <FontAwesomeIcon icon={faMoneyBill} color="#000" className="text-[12px]" />
                </div>
              ),
              ...(!bookingDetails.contact_booking?.client_has_paid && {
                contentAction: bookingDetails.contact_booking?.client_has_paid
                  ? 'View Payment'
                  : 'Take Payment',
                contentActionCallback: () => {
                  openMuiModal(
                    <ContactBookingPaymentAction
                      onClose={(res) => {
                        closeMuiModal();
                        if (res) {
                          fetchBooking();
                        }
                      }}
                      booking={bookingDetails}
                      contact={bookingDetails.contact}
                    />,
                  );
                },
              }),
              bgColor: '#F5F5F7',
            })}

          {renderCard({
            title: 'Notes',
            content: (
              <ContactNotesCard
                contactId={contactId as string}
                note={bookingNote}
                appointmentId={bookingDetails.id}
                noteTitle={cleanCalcomEventTitleToJustEventType(bookingDetails.title || '')}
              />
            ),
            icon: (
              <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                <FontAwesomeIcon icon={faNote} color="#000" className="text-[12px]" />
              </div>
            ),
            bgColor: '#D1DEDD',
          })}
        </div>
      </div>
    </div>
  );
};
