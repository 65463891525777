import { FC } from 'react';

type TItem = {
  label: string;
  value: string;
};

interface IProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  items: TItem[];
  value?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const Select: FC<IProps> = ({ items, className, defaultValue, ...props }) => {
  return (
    <div
      className={`w-max bg-white cursor-pointer rounded-full px-[14px] flex items-center ${className}`}
    >
      <select
        className="font-inter cursor-pointer font-light focus:outline-none pr-2 border-0 bg-transparent"
        {...props}
      >
        <option disabled value="">
          {defaultValue}
        </option>
        {items.map(({ label, value }) => (
          <option value={value}>{label}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
