import { FC } from 'react';

interface IProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: boolean;
}

const Textarea: FC<IProps> = ({ className, error, ...props }) => {
  return (
    <textarea
      {...props}
      className={`${className} w-full  resize-none font-avenir focus:outline-0 placeholder:text-[#00000040]
      
      `}
    />
  );
};

export default Textarea;
