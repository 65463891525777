export default function NestorFlag({
  backgroundColor,
  powered,
  className,
  darkLogo,
}: {
  backgroundColor?: string;
  powered?: boolean;
  className?: string;
  darkLogo?: boolean;
}) {
  const styles = `absolute top-0 right-0 w-80 h-18 ${
    backgroundColor ? backgroundColor : 'bg-black'
  } rounded-full transform translate-x-1/2 -translate-y-1/2 -rotate-45`;
  return (
    <div className={`${styles} ${className}`}>
      <div className="pt-7 pl-6 ">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-45"
        >
          <circle cx="23.3513" cy="8.64866" r="8.14866" stroke="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2947 31.8271C20.1631 31.8271 17.5977 29.4029 17.3728 26.3287L17.3568 26.346C17.3568 25.2041 16.5194 20.7126 14.6162 18.5811C11.5542 15.1516 8.45091 14.7425 6.74778 14.518L6.74753 14.518C6.6221 14.5015 6.50427 14.4859 6.39461 14.4703L6.4118 14.4517C6.25544 14.464 6.09738 14.4703 5.93785 14.4703C2.65846 14.4703 -1.40382e-07 11.8118 2.96496e-09 8.53245C1.46312e-07 5.25307 2.65846 2.5946 5.93785 2.5946C9.21723 2.5946 11.8757 5.25307 11.8757 8.53245V8.53267C11.8757 8.64597 11.8725 8.75852 11.8662 8.87024C11.8682 10.3798 12.3914 14.0366 15.7581 16.9824C18.9073 19.5017 20.8984 19.7051 22.7961 19.899C22.9628 19.916 23.1287 19.9329 23.2946 19.9514L23.2946 19.9514H23.2947C26.574 19.9514 29.2325 22.6099 29.2325 25.8893C29.2325 29.1687 26.574 31.8271 23.2947 31.8271ZM23.2947 14.4703C20.0153 14.4703 17.3568 11.8118 17.3568 8.53245C17.3568 5.25307 20.0153 2.59461 23.2947 2.59461C26.574 2.59461 29.2325 5.25307 29.2325 8.53245C29.2325 11.8118 26.574 14.4703 23.2947 14.4703ZM5.93785 31.8271C2.65846 31.8271 -1.43347e-07 29.1687 0 25.8893C1.43347e-07 22.6099 2.65846 19.9514 5.93785 19.9514C9.21723 19.9514 11.8757 22.6099 11.8757 25.8893C11.8757 29.1687 9.21723 31.8271 5.93785 31.8271Z"
            fill={darkLogo ? '#000' : '#E1E0E1'}
          />
        </svg>
      </div>
      {powered && (
        <div className="absolute rotate-45 -bottom-[40px] -left-[40px]">
          <p className="text-[#00000033] leading-4">
            Powered
            <br />
            by Nestor
          </p>
        </div>
      )}
    </div>
  );
}
