import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface SwipeUpModalProps {
  bgColor?: string;
  isOpen?: boolean;
  children?: React.ReactNode;
  variant?: boolean;
  title?: string;
  useContainer?: boolean;
  onClose: () => void;
}

const SwipeUpModal: React.FC<SwipeUpModalProps> = ({
  bgColor = 'bg-gray-modal',
  isOpen,
  children,
  onClose,
  variant,
  useContainer,
  title,
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen === undefined) {
      setOpen(true);
    } else {
      setOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={`${bgColor} z-40 p-4 items-center justify-center rounded-t-3xl  max-h-[625px] min-h-[625px] md:pl-[250px] md:pr-[250px] md:mt-[100px] md:ml-[80px]  md:mr-[80px] overflow-y-scroll ${variant ? (useContainer ? ' mx-2' : 'h-full') : ''}`}
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '-100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          style={{
            position: `${variant ? (useContainer ? 'fixed' : 'absolute') : 'absolute'}`,
            bottom: 0,
            left: 0,
            right: 0,
            padding: '20px',
          }}
        >
          {!variant && (
            <div
              className={`flex flex-row items-center ${title ? 'justify-between' : 'justify-end'} mb-4 h-12`}
            >
              {title && (
                <p
                  className={`font-inter font-medium text-base leading-4 ${bgColor.includes('black') ? 'text-white' : 'text-black-text'}`}
                >
                  {title}
                </p>
              )}
              <span
                className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 bg-[#090909]/10 rounded-full"
                onClick={onClose}
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          )}

          <div className="md:flex md:items-center md:justify-center">
            <div className="md:px-[100px]">{children}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SwipeUpModal;
