import { Insight, InsightsMetricType } from '@book-nestor-monorepo/shared-types';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const MetricsDisplay = ({
  isLargeScreen,
  isLoadingAgentMetrics,
  agentMetrics,
}: {
  isLargeScreen: boolean;
  isLoadingAgentMetrics: boolean;
  agentMetrics: Insight[];
}) => {
  const MetricValue = ({ color, name }: { color: string; name: InsightsMetricType }) => {
    const value = agentMetrics.find((metric) => metric.metric_type === name)?.value;
    return (
      <span className={`text-[${color}] inline-flex`}>
        {isLoadingAgentMetrics ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: '1rem',
              borderRadius: '10px',
              bgcolor: '#DADCDC',
            }}
          />
        ) : (
          value
        )}
      </span>
    );
  };

  const TooltipIcon = () => (
    <Tooltip
      title="Data from the last 24 hours"
      enterTouchDelay={0}
      leaveTouchDelay={1500}
      disableFocusListener
      disableTouchListener
      disableHoverListener={false}
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        sx={{
          minWidth: 'auto',
          padding: 0,
          marginLeft: '4px',
          verticalAlign: 'text-top',
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} color="#222222" size="sm" />
      </Button>
    </Tooltip>
  );

  const content = (
    <>
      You have{' '}
      <MetricValue color="#13ACD4" name={InsightsMetricType.NI_BOOKING_COUNT_LAST_24_HOURS} /> new
      bookings & <MetricValue color="#43CA51" name={InsightsMetricType.NI_CONVERSATION_COUNT} /> new
      conversations
      <TooltipIcon />
    </>
  );

  const textClass = isLargeScreen
    ? 'font-avenir font-bold text-[43px] leading-[50px] text-left'
    : 'font-avenir text-[24px] leading-[30px] text-left';

  return (
    <div className="flex-grow flex items-center justify-start p-4">
      <div className="flex flex-col">
        <p className={textClass}>{content}</p>
      </div>
    </div>
  );
};
