import { Contact } from '@book-nestor-monorepo/shared-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwipeUpModal from '../components/common/SwipeModal/SwipeModal';
import EditContactModal from '../components/modals/editContact';
import { AuthContext } from '../contexts/authContext';
import { useModal } from '../contexts/modalContext';
import { getContact, getContacts } from '../libs/services/contacts';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';
import { truncateText } from '../libs/utils/string.util';
import * as Sentry from '@sentry/react';
import { StoreContext } from '../contexts/storeContext';

export default function Contacts() {
  const [isLoading, setIsLoading] = useState(false);
  const [unsortedContacts, setUnsortedContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);

  const [activeTab, setActiveTab] = useState<{
    regular: boolean;
    favorite: boolean;
    difficult: boolean;
  }>({
    regular: false,
    favorite: false,
    difficult: false,
  });
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
  const [searchString, setSearchString] = useState('');
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const authContext = useContext(AuthContext);

  const { openModal, closeModal } = useModal();
  const history = useNavigate();
  const { state } = useContext(StoreContext);
  const contactListRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchString(value);
  };

  const handleLetterClick = (letter: string) => {
    setSelectedLetter(letter);
  };

  const handleButtonClick = (buttonType: 'regular' | 'favorite' | 'difficult') => {
    setActiveTab((prevState) => ({
      ...prevState,
      [buttonType]: !prevState[buttonType],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isModalOpen && event.target instanceof Element && !event.target.closest('.absolute')) {
        setIsModalOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedLetter) {
      const letterElement = document.getElementById(selectedLetter);
      if (letterElement && contactListRef.current) {
        const letterTop = letterElement.offsetTop;
        const contactListTop = contactListRef.current.offsetTop;
        const scrollTop = letterTop - contactListTop;
        contactListRef.current.scrollTop = scrollTop;
      }
    }
  }, [selectedLetter]);

  const sortContacts = (contacts: Contact[]) => {
    const sortedContacts: Record<string, Contact[]> = {};
    for (const contact of contacts) {
      const firstLetter = contact.name?.[0]?.toUpperCase();
      if (sortedContacts[firstLetter]) {
        sortedContacts[firstLetter].push(contact);
      } else {
        sortedContacts[firstLetter] = [contact];
      }
    }

    // Sort the contacts within each letter group in ascending order (A-Z)
    for (const letter in sortedContacts) {
      sortedContacts[letter].sort((a, b) => (a?.name || '').localeCompare(b?.name || ''));
    }

    return sortedContacts;
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchString.length >= 3) {
        setIsLoading(true);
        try {
          const user = authContext.user;
          if (user?.id) {
            const contacts = await getContacts(user.id, {
              searchTerm: searchString,
              // email: searchString,
              // phoneNumber: searchString,
            });
            setUnsortedContacts(contacts);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
        setIsLoading(false);
      } else if (searchString.length === 0) {
        setUnsortedContacts(state.contacts); // from the store
      }
    };

    fetchSearchResults();
  }, [authContext.user, state.contacts, searchString]);

  useEffect(() => {
    const filterContacts = () => {
      let filtered = unsortedContacts;

      if (activeTab.regular) {
        filtered = filtered.filter((contact) => contact.is_regular);
      }
      if (activeTab.favorite) {
        filtered = filtered.filter((contact) => contact.is_favorite);
      }
      if (activeTab.difficult) {
        filtered = filtered.filter((contact) => contact.is_difficult);
      }

      setFilteredContacts(filtered);
    };

    filterContacts();
  }, [unsortedContacts, activeTab]);

  const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i));

  const mergeContact = (newContact: Contact) => {
    const existingContacts = unsortedContacts;
    const existingContactsPlusNewContact = [...existingContacts, newContact];
    setUnsortedContacts(existingContactsPlusNewContact);
  };

  const onCloseContactModal = async (contactId?: string) => {
    closeModal();
    if (contactId && contactId !== 'new') {
      const contact = await getContact(authContext.user?.id || '', contactId);
      if (contact) {
        mergeContact(contact);
      }
    }
  };

  const openContactModal = () => {
    openModal(
      <SwipeUpModal variant={true} useContainer={true} bgColor="!bg-[#F1F1F1]" onClose={closeModal}>
        <EditContactModal title="New Client Details" onClose={onCloseContactModal} />
      </SwipeUpModal>,
    );
  };

  return (
    <ExperimentalAppLayout
      className="bg-[#272727]"
      bookings
      pageTitle="Clients"
      insightCardsStyles="hidden md:flex"
    >
      <div className="bg-[#272727] flex flex-col justify-between rounded-t-3xl md:rounded-br-3xl md:rounded-bl-3xl w-full md:w-[395px] h-full px-2 pt-4 md:pt-32">
        <div className="relative w-full h-12 min-h-12">
          <input
            placeholder="Search"
            onChange={handleSearchChange}
            className="w-full h-full font-avenir rounded-full bg-black-alt text-white placeholder:text-gray-temporal pl-[60px] focus:outline-none"
          />
          <div className="absolute top-1/2 -translate-y-1/2 left-[24px]">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8125 11.5391C12.0469 11.7734 12.0469 12.125 11.8125 12.3359C11.7188 12.4531 11.5781 12.5 11.4375 12.5C11.2734 12.5 11.1328 12.4531 11.0156 12.3359L7.875 9.19531C7.03125 9.875 5.97656 10.25 4.85156 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.15625 0.5 4.85156 0.5C7.52344 0.5 9.72656 2.70312 9.72656 5.375C9.72656 6.52344 9.35156 7.57812 8.67188 8.39844L11.8125 11.5391ZM1.125 5.375C1.125 7.46094 2.78906 9.125 4.875 9.125C6.9375 9.125 8.625 7.46094 8.625 5.375C8.625 3.3125 6.9375 1.625 4.875 1.625C2.78906 1.625 1.125 3.3125 1.125 5.375Z"
                fill="#737371"
              />
            </svg>
          </div>
        </div>
        <div className="flex gap-2 mt-2 font-avenir">
          <div
            className={`h-10 min-h-10 px-[18px] flex items-center border ${
              activeTab.regular ? 'bg-white text-black' : 'border-white text-white'
            } rounded-full text-[14px] cursor-pointer`}
            onClick={() => handleButtonClick('regular')}
          >
            Regulars
          </div>
          <div
            className={`h-10 min-h-10 px-[18px] flex items-center border ${
              activeTab.favorite ? 'bg-[#43CA51] text-white' : 'border-[#43CA51] text-white'
            } rounded-full text-[14px] cursor-pointer`}
            onClick={() => handleButtonClick('favorite')}
          >
            Favorites
          </div>
          <div
            className={`h-10 min-h-10 px-[18px] flex items-center border ${
              activeTab.difficult ? 'bg-[#FD5901] text-white' : 'border-[#FD5901] text-white'
            } rounded-full text-[14px] cursor-pointer`}
            onClick={() => handleButtonClick('difficult')}
          >
            Difficult
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-t-[24px] md:rounded-b-3xl gap-[22px] mt-4 flex items-start font-avenir px-[10px] py-2">
          <div className="w-full h-full">
            <div className="w-full flex justify-end">
              <button
                onClick={openContactModal}
                className="w-10 h-10 rounded-full bg-gray-btn flex items-center justify-center"
              >
                <svg
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.625 5C10.625 5.21094 10.4375 5.375 10.25 5.375H6.125V9.5C6.125 9.71094 5.9375 9.89844 5.75 9.89844C5.53906 9.89844 5.375 9.71094 5.375 9.5V5.375H1.25C1.03906 5.375 0.875 5.21094 0.875 5.02344C0.875 4.8125 1.03906 4.625 1.25 4.625H5.375V0.5C5.375 0.3125 5.53906 0.148438 5.75 0.148438C5.9375 0.148438 6.125 0.3125 6.125 0.5V4.625H10.25C10.4375 4.625 10.625 4.8125 10.625 5Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
            <div
              ref={contactListRef}
              className="overflow-auto h-full pb-[180px] hide-scrollbar mt-2"
            >
              {Object.keys(sortContacts(filteredContacts))
                .sort()
                .map((letter) => {
                  const sortedContacts = sortContacts(filteredContacts)[letter];
                  return (
                    <div key={letter} id={letter} className="mt-2 first:mt-0">
                      <div className="w-full h-[18px] bg-[#F9F9F9] border-b-[0.5px] border-[#DFDFDF]">
                        <p className="font-avenir text-[14px] text-[#8C8C8C] leading-[17px] font-semibold">
                          {letter}
                        </p>
                      </div>
                      {sortedContacts.map(({ name, last_name, id }) => (
                        <button
                          key={id}
                          onClick={() => history(`/contacts/${id}`)}
                          className="w-full h-[18px] flex justify-between items-start border-b-[0.5px] border-[#DADADA] mt-[22px]"
                        >
                          <p className="font-avenir text-[14px] leading-[17px]">
                            {last_name
                              ? truncateText(`${name} ${last_name}`, 30)
                              : truncateText(`${name}`, 30)}
                          </p>
                          <div className="w-2 h-2 rounded-full bg-blue-light mt-[2px]"></div>
                        </button>
                      ))}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="flex flex-col rounded-full gap-[6px] bg-black-alt w-[38px] py-3">
            {alphabet.map((letter) => (
              <button
                key={letter}
                className="text-[14px] leading-[11px] text-white"
                onClick={() => handleLetterClick(letter)}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>
      </div>
    </ExperimentalAppLayout>
  );
}
