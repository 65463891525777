import {
  BookingConversationItem,
  BookingConversationMessageItem,
  BookingConversationMessageResponseStatus,
  BookingConversationMessageStatus,
} from '@book-nestor-monorepo/shared-types';
import {
  faArrowRight,
  faCheck,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent, Collapse } from '@mui/material';
import { useState } from 'react';
import {
  acknowledgeAgentConversationMessageResponse,
  updateAgentConversationMessageResponse,
} from '../../../libs/services/agent-conversations';
import {
  formatClientTimeFromDate,
  formatIsoDateString,
  getAbbreviatedMonthAndDay,
} from '../../../libs/utils/date.util';
import { AgentMessageView } from '../AgentMenuDrawer/AgentMenuDrawerTabPanels/AgentMenuDrawerMessagesTabPanel';
import { useAgent } from '../../../contexts/v2/agentContext';

interface AgentMessageCardProps {
  agentMessage: BookingConversationItem;
  onNavigate: (
    view: AgentMessageView,
    agentMessage?: BookingConversationItem,
    agentConversationMessage?: BookingConversationMessageItem,
  ) => void;
  agentConversationMessage?: BookingConversationMessageItem;
}

export const AgentMessageCard = ({
  agentMessage,
  onNavigate,
  agentConversationMessage,
}: AgentMessageCardProps) => {
  const [expanded, setExpanded] = useState(false);
  const { fetchConversationMessages } = useAgent();
  const contactName = agentMessage.contact
    ? `${agentMessage.contact.name} ${agentMessage.contact.last_name}`
    : 'Unknown Contact';
  const { month, day } = getAbbreviatedMonthAndDay(new Date(agentMessage.created_at));
  const bookedAppointment = agentMessage.booking_id;
  const [responseSent, setResponseSent] = useState(
    agentConversationMessage?.response?.status === BookingConversationMessageResponseStatus.SENT,
  );
  const [response, setResponse] = useState(agentConversationMessage?.response?.content || '');
  const [isAcknowledged, setIsAcknowledged] = useState(
    agentConversationMessage?.status === BookingConversationMessageStatus.ACKNOWLEDGED,
  );
  const [isRecoveryConversation, setIsRecoveryConversation] = useState(agentMessage.has_recovery);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSendResponse = () => {
    if (agentConversationMessage) {
      updateAgentConversationMessageResponse(
        agentMessage.user_id,
        agentConversationMessage.conversation_id,
        response,
      );
      setResponseSent(true);
      fetchConversationMessages();
    }
  };

  const handleAcknowledgeResponse = () => {
    if (agentConversationMessage) {
      acknowledgeAgentConversationMessageResponse(
        agentMessage.user_id,
        agentConversationMessage.conversation_id,
      );
      setIsAcknowledged(true);
    }
  };

  return (
    <div className="flex cursor-pointer w-full" key={`message-${agentMessage.id}`}>
      <Card sx={{ borderRadius: '20px', width: '100%', padding: '8px' }}>
        <CardContent>
          <div className="flex items-center justify-between w-full h-[48px]">
            {/* left */}
            <div className="flex flex-col items-start justify-start border-r-2 border-[#000000] w-[62px] ">
              <div className="w-full flex items-start justify-start">
                <span className="text-[16px] font-inter text-start">{month}</span>
              </div>
              <div className="w-full flex items-start justify-start">
                <span className="text-[32px] font-avenir font-semibold text-start">{day}</span>
              </div>
            </div>

            {/* center */}
            <div className="flex flex-col justify-between flex-grow mx-5">
              <div className="w-full flex items-start justify-start">
                <span className="text-2xl font-inter font-bold truncate max-w-[200px] inline-block">
                  {contactName}
                </span>
              </div>
              <div className="flex flex-wrap items-center gap-1 mt-2">
                <div className="flex h-[19px] items-center px-2 bg-[#DADCDC] rounded-full">
                  <span className="text-xs font-avenir">
                    {formatClientTimeFromDate(new Date(agentMessage.created_at))}
                  </span>
                </div>
                <div className="flex h-[19px] items-center px-2 bg-[#222222] rounded-full">
                  <span className="text-xs font-avenir text-white">
                    {agentMessage.channel_type === 'sms'
                      ? 'SMS'
                      : agentMessage.channel_type === 'voice'
                        ? 'Voice'
                        : agentMessage.channel_type === 'text'
                          ? 'Text'
                          : 'Unknown'}
                  </span>
                </div>
                {isRecoveryConversation && (
                  <FontAwesomeIcon icon={faReply} className="text-[#43CA51] text-[20px] pr-1" />
                )}
                {agentConversationMessage &&
                  !agentConversationMessage.response &&
                  agentConversationMessage.status !==
                    BookingConversationMessageStatus.ACKNOWLEDGED && (
                    <div className="flex h-[19px] items-center px-2 bg-[#B8E7F4] rounded-full">
                      <span className="text-xs font-avenir">Action Required</span>
                    </div>
                  )}
                {agentConversationMessage &&
                  !agentConversationMessage.response &&
                  agentConversationMessage.status ===
                    BookingConversationMessageStatus.ACKNOWLEDGED && (
                    <div className="flex h-[19px] items-center px-2 bg-[#B8E7F4] rounded-full">
                      <span className="text-xs font-avenir">Acknowledged</span>
                    </div>
                  )}
                {agentConversationMessage &&
                  agentConversationMessage.response?.status ===
                    BookingConversationMessageResponseStatus.SENT && (
                    <div className="flex h-[19px] items-center px-2 bg-[#B8E7F4] rounded-full">
                      <span className="text-xs font-avenir">Responded</span>
                    </div>
                  )}
                {bookedAppointment && (
                  <div className="flex h-[19px] items-center px-2 bg-[#43CA51] rounded-full">
                    <span className="text-xs font-avenir text-white">Booked</span>
                  </div>
                )}
                {agentMessage.summary?.sentiment === 'negative' && (
                  <div className="flex h-[19px] items-center px-2 bg-[#DB1B24] rounded-full">
                    <span className="text-xs font-avenir text-white">Negative</span>
                  </div>
                )}
              </div>
            </div>

            {/* right */}
            <div className="flex items-center justify-end">
              <Button
                onClick={() =>
                  onNavigate(AgentMessageView.DETAIL, agentMessage, agentConversationMessage)
                }
                disabled={!agentMessage.is_idle}
                size="large"
                sx={{
                  backgroundColor: '#F0F0F0',
                  borderRadius: '100%',
                  height: '48px',
                  width: '48px',
                  minWidth: '48px',
                  padding: '0',
                  opacity: agentMessage.is_idle ? 1 : 0.5,
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} className="text-black transform -rotate-45" />
              </Button>
            </div>
          </div>
        </CardContent>
        <CardActions>
          {agentConversationMessage && (
            <Button
              size="small"
              onClick={handleExpandClick}
              sx={{
                fontSize: '10px',
                fontFamily: 'Avenir',
                color: '#000000',
                backgroundColor: '#B8E7F4',
                borderRadius: '35px',
                paddingX: '10px',
                textTransform: 'none',
              }}
            >
              {expanded ? (
                <div className="flex flex-row items-center justify-center">
                  <span className="text-[10px] font-avenir font-normal">Hide Action</span>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </div>
              ) : (
                <div className="flex flex-row items-center justify-center">
                  <span className="text-[10px] font-avenir font-normal">Show Action</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </div>
              )}
            </Button>
          )}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div className="flex flex-row">
              <div className="flex flex-col h-full w-auto items-center justify-start">
                <div className="w-12 h-12 bg-[#202020] rounded-full flex items-center justify-center mb-[5px]">
                  <img src="/assets/nestor-ai.svg" height={20} width={20} alt="Connector black" />
                </div>
              </div>
              <div className="flex flex-col h-full w-full ml-2">
                <div className="flex flex-col bg-[#202020] rounded-xl items-start justify-start px-[10px] pt-4 pb-4">
                  <div className="w-full">
                    <span className="text-left block font-avenir text-[14px] text-[#FFFFFF] font-bold">
                      NOTE
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="font-normal text-left block font-avenir text-[14px] text-[#FFFFFF] leading-5">
                      {agentMessage.summary?.summary}
                    </span>
                  </div>
                  <div className="w-full pt-5">
                    <textarea
                      value={response}
                      onChange={(e) => setResponse(e.target.value)}
                      className="w-full h-24 p-2 border border-white rounded-lg text-white bg-[#202020] font-avenir text-[14px]"
                      placeholder={`Respond to ${agentMessage.contact?.name || 'Unknown Contact'} here...`}
                    />
                  </div>
                  <div className="flex flex-row  items-start justify start w-auto pt-4">
                    {isAcknowledged && !responseSent && (
                      <Button
                        size="small"
                        onClick={handleSendResponse}
                        sx={{
                          fontSize: '12px',
                          fontFamily: 'Avenir',
                          color: '#000000',
                          backgroundColor: '#B7BABA',
                          borderRadius: '35px',
                          height: '48px',
                          padding: '20px ',
                          marginLeft: '5px',
                        }}
                      >
                        Reply
                      </Button>
                    )}
                    {!responseSent && !isAcknowledged && (
                      <>
                        <Button
                          size="small"
                          onClick={handleAcknowledgeResponse}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Avenir',
                            color: '#ffffff',
                            border: '1px solid #ffffff',
                            borderRadius: '35px',
                            height: '48px',
                            padding: '20px ',
                          }}
                        >
                          Acknowledge
                        </Button>
                        <Button
                          size="small"
                          onClick={handleSendResponse}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Avenir',
                            color: '#000000',
                            backgroundColor: '#B7BABA',
                            borderRadius: '35px',
                            height: '48px',
                            padding: '20px ',
                            marginLeft: '5px',
                          }}
                        >
                          & Send
                        </Button>
                      </>
                    )}
                    {responseSent && (
                      <div className="flex flex-row items-center justify-center">
                        <FontAwesomeIcon icon={faCheck} className="text-white text-[10px] pr-1" />
                        <span className="text-[10px] font-avenir font-normal text-white">
                          Response Sent on{' '}
                          {formatIsoDateString(
                            new Date(
                              agentConversationMessage?.response?.updated_at || new Date(),
                            ).toISOString(),
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
