import { useContext, useState, useRef } from 'react';
import { createContactNote } from '../../libs/services/contacts';
import { AuthContext } from '../../contexts/authContext';
import Textarea from '../../components/onboarding/Textarea';
import Input from '../../components/onboarding/Input';
import { useFormik } from 'formik';
import { ContactNote } from '@book-nestor-monorepo/shared-types';
import * as Yup from 'yup';
import { title } from 'process';
import { SaveButton } from '../formLibrary/formSaveButton';
import * as Sentry from '@sentry/react';

interface NewNoteType {
  onClose: () => void;
  contactId: string;
  appointmentId?: string;
}

interface FormData {
  title: string;
  note: string;
  appointment_id?: string;
}

const NewNoteModal = ({ onClose, contactId, appointmentId }: NewNoteType) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const galleryInputRef = useRef<any>(null);
  const [galleryFiles, setGalleryFiles] = useState<any[]>([]);

  const [formData, setFormData] = useState<FormData>({
    title: '',
    note: '',
    appointment_id: appointmentId, // TODO: discuss with Ben if we need to add a new endpoint for appointment notes
  });

  const handleGalleryFilesButtonClick = () => {
    galleryInputRef?.current?.click();
  };

  const handleGalleryFilesChange = (event: any) => {
    const files = Array.from(event.target.files);
    if (files.length + galleryFiles.length > 4) {
      alert('You can only upload up to 4 images.');
      return;
    }
    setGalleryFiles([...galleryFiles, ...files]);
  };

  const deleteUploadedImage = (idx: number) => {
    const newGalleries = [...galleryFiles];
    newGalleries.splice(idx, 1);
    setGalleryFiles(newGalleries);
  };

  const initialValues: ContactNote = {
    title: '',
    body: '',
    appointment_id: appointmentId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required(),
      body: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await createContactNote(authContext?.user?.id as string, contactId, values, galleryFiles);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
        onClose();
      }
    },
  });

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={formik.handleSubmit}>
        <div
          className={`flex flex-row items-center ${
            title ? 'justify-between' : 'justify-end'
          } h-12 mb-9`}
        >
          <p className="font-inter font-medium text-base leading-4 text-black-text">Notes</p>
          <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-row gap-1 items-center">
              <SaveButton
                onClick={async () => {
                  if (formik.isValid) {
                    await formik.submitForm();
                  }
                }}
                disabled={!formik.isValid || !formik.dirty}
              />
              <span
                className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-black-solid"
                onClick={onClose}
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col pt-1.5 px-2 gap-2">
          <div className="flex items-center relative">
            <Input
              className={`bg-gray-modals font-avenir italic  text-black-text ${
                formik.values.title.length <= 8
                  ? 'text-[67.778px] leading-[72px]'
                  : formik.values.title.length <= 20
                    ? 'text-[50px] leading-[60px]'
                    : 'text-[40px] leading-[48px]'
              } mb-4`}
              type="text"
              placeholder="Title"
              onChange={formik.handleChange}
              name="title"
              value={formik.values.title}
            />
          </div>

          <div
            className={`pt-[45px] bg-[#DCDFE1] rounded-[20px] 2xl:pt-[69px] pb-[57px] 2xl:pb-[80px] pl-[36px] 2xl:pl-[52px] pr-[21px] 2xl:pr-[30px] h-[calc(100vh-584px)] border transition-all duration-200 overflow-y-scroll hide-scrollbar`}
          >
            <Textarea
              placeholder={'Note:'}
              maxLength={1500}
              className="h-full"
              name="body"
              onChange={formik.handleChange}
              value={formik.values.body}
            />
            <div className="absolute left-0  gap-[6px] pl-[52px] md:pl-[230px] pb-[30px] font-avenir">
              <p className="italic text-black-alt">{formik.values.body.length}/1500 Characters</p>
            </div>
          </div>

          <div className="mb-10">
            <h5 className="font-inter font-medium">Gallery</h5>

            <input
              type="file"
              style={{ display: 'none' }}
              ref={galleryInputRef}
              onChange={handleGalleryFilesChange}
              accept="image/jpeg,image/png,image/heic"
              multiple
            />
            <div className="grid grid-cols-2 gap-2 mt-5">
              {galleryFiles.map((file, index) => (
                <div className="relative w-full h-[133px] rounded-[16px] bg-black-alt overflow-hidden">
                  <img
                    src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                    alt={`Gallery Preview ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute flex flex-col gap-[6px] right-2 top-2">
                    <button
                      type="button"
                      onClick={() => deleteUploadedImage(index)}
                      className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-sm flex items-center justify-center"
                    >
                      <svg
                        width="9"
                        height="11"
                        viewBox="0 0 9 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.53125 1.8125C8.78516 1.8125 9 2.02734 9 2.28125C9 2.55469 8.78516 2.75 8.53125 2.75H8.29688L7.82812 9.09766C7.76953 9.76172 7.24219 10.25 6.57812 10.25H2.65234C1.98828 10.25 1.46094 9.76172 1.40234 9.09766L0.933594 2.75H0.71875C0.445312 2.75 0.25 2.55469 0.25 2.28125C0.25 2.02734 0.445312 1.8125 0.71875 1.8125H2.06641L2.78906 0.738281C2.98438 0.445312 3.33594 0.25 3.70703 0.25H5.52344C5.89453 0.25 6.24609 0.445312 6.44141 0.738281L7.16406 1.8125H8.53125ZM3.70703 1.1875C3.64844 1.1875 3.58984 1.22656 3.57031 1.26562L3.19922 1.8125H6.03125L5.66016 1.26562C5.64062 1.22656 5.58203 1.1875 5.52344 1.1875H3.70703ZM7.35938 2.75H1.87109L2.33984 9.03906C2.35938 9.19531 2.49609 9.3125 2.65234 9.3125H6.57812C6.73438 9.3125 6.87109 9.19531 6.89062 9.03906L7.35938 2.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="w-8 h-8 rounded-full bg-[#D9D9D91A] border-[0.5px] border-white backdrop-blur-md flex items-center justify-center"
                    >
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.42969 2.06641C4.30859 1.40234 5.40234 0.875 6.75 0.875C8.3125 0.875 9.58203 1.59766 10.5 2.45703C11.418 3.29688 12.0234 4.3125 12.3164 5.01562C12.375 5.17188 12.375 5.34766 12.3164 5.50391C12.0625 6.12891 11.5352 7.02734 10.7539 7.80859L12.8047 9.42969C13.0195 9.58594 13.0586 9.87891 12.8828 10.0742C12.7266 10.2891 12.4336 10.3281 12.2383 10.1523L0.675781 1.08984C0.460938 0.933594 0.421875 0.640625 0.597656 0.445312C0.753906 0.230469 1.04688 0.191406 1.24219 0.367188L3.42969 2.06641ZM4.19141 2.67188L5.08984 3.375C5.53906 2.98438 6.10547 2.75 6.75 2.75C8.11719 2.75 9.25 3.88281 9.25 5.25C9.25 5.67969 9.13281 6.07031 8.95703 6.40234L10.0117 7.22266C10.6758 6.55859 11.1445 5.79688 11.3984 5.25C11.125 4.66406 10.6172 3.84375 9.85547 3.14062C9.05469 2.39844 8.01953 1.8125 6.75 1.8125C5.75391 1.8125 4.89453 2.16406 4.19141 2.67188ZM8.19531 5.81641C8.27344 5.64062 8.3125 5.44531 8.3125 5.25C8.3125 4.39062 7.60938 3.6875 6.75 3.6875C6.73047 3.6875 6.71094 3.6875 6.69141 3.6875C6.73047 3.80469 6.75 3.90234 6.75 4C6.75 4.21484 6.69141 4.39062 6.61328 4.56641L8.19531 5.81641ZM8.39062 8.35547L9.21094 9C8.48828 9.39062 7.66797 9.625 6.75 9.625C5.16797 9.625 3.89844 8.92188 2.98047 8.0625C2.0625 7.20312 1.45703 6.1875 1.16406 5.50391C1.10547 5.34766 1.10547 5.17188 1.16406 5.01562C1.35938 4.56641 1.67188 4 2.12109 3.41406L2.84375 4C2.49219 4.44922 2.23828 4.89844 2.08203 5.25C2.35547 5.83594 2.86328 6.67578 3.625 7.37891C4.42578 8.12109 5.46094 8.6875 6.75 8.6875C7.33594 8.6875 7.88281 8.57031 8.39062 8.35547ZM4.25 5.25C4.25 5.21094 4.25 5.15234 4.25 5.09375L5.34375 5.95312C5.53906 6.36328 5.92969 6.67578 6.39844 6.77344L7.49219 7.65234C7.25781 7.71094 7.00391 7.75 6.73047 7.75C5.36328 7.75 4.23047 6.63672 4.23047 5.25H4.25Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleGalleryFilesButtonClick}
                className="relative w-full h-[133px] rounded-[16px] bg-black-alt"
              >
                <div className="pt-[35px] pl-[46px] ">
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.6875 4.25C8.6875 4.52344 8.47266 4.71875 8.21875 4.71875H5.09375V7.84375C5.09375 8.11719 4.87891 8.33203 4.625 8.33203C4.35156 8.33203 4.15625 8.11719 4.15625 7.84375V4.71875H1.03125C0.757812 4.71875 0.5625 4.52344 0.5625 4.26953C0.5625 3.99609 0.757812 3.78125 1.03125 3.78125H4.15625V0.65625C4.15625 0.402344 4.35156 0.207031 4.625 0.207031C4.87891 0.207031 5.09375 0.402344 5.09375 0.65625V3.78125H8.21875C8.47266 3.78125 8.6875 3.99609 8.6875 4.25Z"
                      fill="#ECECEC"
                    />
                  </svg>
                  <h6 className="text-gray-light font-avenir mt-[28px] text-left">
                    Add
                    <br />
                    Photo
                  </h6>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewNoteModal;
