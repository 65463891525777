import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaledBooker } from '../../components/v2/Booker/ScaledBooker';
import { SearchAutocompleteServices } from '../../components/v2/App/SearchAutoCompleteServices';
import { Skeleton } from '@mui/material';
import { SearchAutocompleteContacts } from '../../components/v2/App/SearchAutoCompleteContacts';
import { Contact } from '@book-nestor-monorepo/shared-types';
import { useContacts } from '../../contexts/v2/contactsContext';
import { useServices } from '../../contexts/v2/eventTypesContext';
import { EventType } from '@book-nestor-monorepo/shared-types';
import { AuthContext } from '../../contexts/authContext';
import { bookingWebhook } from '../../libs/services/bookings';
import { useToast } from '../../contexts/v2/toastContext';

export const BookerMobilePage = () => {
  const [bookerKey, setBookerKey] = useState(0);
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const { contacts, isLoading: isLoadingContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [selectedService, setSelectedService] = useState<EventType | null>();

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
  }, [services, contacts]);

  useEffect(() => {
    setBookerKey((prevKey) => prevKey + 1);
  }, [selectedContact, selectedService]);

  const handleBookingSuccess = async (data: any) => {
    showToast('Booking created successfully!', 'success');

    if (user?.user_slug?.slug && data?.data?.uid) {
      // FIXME: Do we need to do anything here? We have webhooks for each user now
      await bookingWebhook({
        user_slug: user?.user_slug?.slug,
        booking_id: data?.data?.uid,
      });
    }
    navigate('/');
  };

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        {/* Container 2 */}
        <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
          <div className="flex w-full items-start justify-between">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Clients</p>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingContacts ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteContacts
                contacts={contacts}
                label="Book a Client"
                onSelectContact={(contact: Contact | null) => {
                  setSelectedContact(contact);
                }}
              />
            )}
          </div>
        </div>

        {/* Container 3 */}
        <div className="bg-[#E5E8E8] rounded-xl shadow-sm p-4">
          <div className="flex w-full items-start justify-start">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Services</p>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingServices ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteServices
                services={services}
                label="Book a Service"
                onSelectService={(service: EventType | null) => {
                  setSelectedService(service);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {services.length > 0 && selectedService && selectedContact && (
          <ScaledBooker
            key={bookerKey}
            scale={1}
            eventTypeSlug={selectedService?.slug as string}
            username={user?.connection_data?.cal_provider_user_name as string}
            contact={selectedContact as Contact}
            onSuccess={handleBookingSuccess}
          />
        )}
      </div>
    </>
  );
};
