import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../contexts/appContext';
import { useModal } from '../../contexts/modalContext';
import { MenuBoxProps } from './data';

const MenuBox = ({ menuStatistics }: MenuBoxProps) => {
  const { toggleMenu } = useContext(AppContext);
  const { closeModal } = useModal();
  const history = useNavigate();

  const openLink = (url: string) => {
    history(url);
    closeModal();
    toggleMenu();
  };

  return (
    <div className="grid gap-2 grid-cols-2 mt-6 mb-4">
      {menuStatistics.map(({ icon, count, title, url, subTitle }) => (
        <button
          onClick={() => openLink(url)}
          key={title}
          className="w-full h-[128px] bg-white rounded-[16px] p-2 flex flex-col justify-between text-left"
        >
          <div className="flex w-full items-center justify-between">
            {icon}
            <div className="h-6 px-[12.5px] rounded-full bg-[#F3F3F3] font-inter text-black-text flex items-center justify-between text-[10px]">
              {count}
            </div>
          </div>
          <div className="text-black-alt leading-4">
            <h5 className="font-bold">{title}</h5>
            <h5>{subTitle}</h5>
          </div>
        </button>
      ))}
    </div>
  );
};

export default MenuBox;
