import { EventCategory, EventType, UserSlug } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingDots from '../components/loading';
import CategoriesBox from '../components/services/CategoriesBox';
import ServiceBox from '../components/services/ServiceBox';
import { AuthContext } from '../contexts/authContext';
import {
  createEventCategory,
  deleteEventCategory,
  getEventCategories,
} from '../libs/services/event-category.service';
import { deleteEventType, getEventTypes } from '../libs/services/event-type';
import { getUserSlug } from '../libs/services/user-slug';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

export default function Services() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const authContext = useContext(AuthContext);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [userSlug, setUserSlug] = useState<UserSlug | undefined>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEventTypeId, setSelectedEventTypeId] = useState<string | null>(null);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [eventCategories, setEventCategories] = useState<EventCategory[]>([]);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = authContext.user;
      if (!user?.id) return;

      const [eventTypes, userSlug, eventCategories] = await Promise.all([
        getEventTypes(),
        getUserSlug(user.id),
        getEventCategories(),
      ]);

      setUserSlug(userSlug);
      setEventTypes(eventTypes);
      setEventCategories(eventCategories);
    } catch (error) {
      Sentry.captureException(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [authContext.user]);

  const closeDeleteModal = () => {
    setSelectedEventTypeId(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    // Implement the delete functionality here

    await deleteEventType(selectedEventTypeId as string);
    setEventTypes(eventTypes.filter((eventType) => eventType.id !== selectedEventTypeId));
    closeDeleteModal();
  };
  const handleAddCategory = async () => {
    if (!newCategoryName) return;

    await createEventCategory({ name: newCategoryName });

    fetchData();
    setNewCategoryName('');
  };

  const handleDeleteCategory = async (selectedCategoryId: string) => {
    await deleteEventCategory(selectedCategoryId as string);
    setEventCategories(
      eventCategories.filter((eventCategory) => eventCategory.id !== selectedCategoryId),
    );
  };

  return (
    <ExperimentalAppLayout
      bookings={true}
      pageTitle="Your Services"
      insightCardsStyles="hidden md:flex"
      componentName="Your services"
    >
      {isLoading ? (
        <LoadingDots
          skeletonProps={{
            count: 4,
            height: 70,
            borderRadius: 10,
            style: { marginBottom: '10px' },
          }}
        />
      ) : (
        <div className="bg-white w-full rounded-t-[32px] py-[25px] px-2 md:pt-32 max-h-screen overflow-y-auto">
          <ServiceBox services={eventTypes} title="Current Services" />
          <CategoriesBox
            newCategoryName={newCategoryName}
            handleAddCategory={handleAddCategory}
            setNewCategoryName={setNewCategoryName}
            eventCategories={eventCategories}
            handleDeleteCategory={handleDeleteCategory}
          />
        </div>
      )}
      {deleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this event type?</p>
            <div className="flex justify-end">
              <button
                onClick={closeDeleteModal}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white rounded-lg">
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </ExperimentalAppLayout>
  );
}
