import { faArrowRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { signInWithRedirect, signUp } from 'aws-amplify/auth';
import { Cache, Hub } from 'aws-amplify/utils';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import PrimaryTextField from '../../components/formLibrary/primaryTextInput';
import { useValidEmail, useValidFirstName, useValidPassword } from '../../hooks/useAuthHooks';
import { validateCaptcha } from '../../libs/services/connect';
import { addPixel } from '../../libs/utils/pixel';
import { AuthLayout } from '../layouts/auth-layout';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

function Signup() {
  const { email, setEmail, emailIsValid } = useValidEmail('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const { firstName, setFirstName, firstNameIsValid } = useValidFirstName('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [created, setCreated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginWithGoogleIsLoading, setLoginWithGoogleIsLoading] = useState(false);

  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowHideConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const confirmPasswordIsValid = password === confirmPassword;

  const isValid =
    !emailIsValid ||
    email?.length === 0 ||
    !passwordIsValid ||
    password?.length === 0 ||
    !confirmPasswordIsValid ||
    confirmPassword?.length === 0 ||
    !firstNameIsValid ||
    firstName?.length === 0;

  const history = useNavigate();

  const showPasswordHint = password?.length > 0 && !passwordIsValid;
  const showPasswordsDoNotMatch = confirmPassword?.length > 0 && confirmPassword !== password;

  const signUpClicked = async (event: any) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email,
            name: firstName,
          },
        },
      });
      addPixel('https://tag.simpli.fi/sifitag/c2c03913-e5cb-440e-bd1e-7dcdfc396748');
      await Cache.setItem('login-first-name-set', firstName);
      await Cache.setItem('login-username-set', email);
      await Cache.setItem('nestor-first', password);
      Hub.dispatch('auth', {
        event: 'login-username-set',
        data: { loginUserName: email },
      });
      Hub.dispatch('auth', {
        event: 'login-first-name-set',
        data: { firstName },
      });

      setCreated(true);
      history('/verify');
    } catch (err) {
      if (err instanceof Error) {
        switch (err.name) {
          case 'UsernameExistsException':
            setError('A user with this email address already exists');
            break;
          default:
            setError(err.message);
            break;
        }
      }
    }
  };

  const handleGoogleLogin = async (event: any) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    try {
      setLoginWithGoogleIsLoading(true);
      const recaptchaResponse = await executeRecaptcha('google_login');

      // The token is typically the first element in the response array
      const token = Array.isArray(recaptchaResponse) ? recaptchaResponse[0] : recaptchaResponse;

      if (typeof token !== 'string') {
        throw new Error('Invalid reCAPTCHA token');
      }

      const captchaResult = await validateCaptcha(token);

      if (captchaResult.success) {
        addPixel('https://tag.simpli.fi/sifitag/c2c03913-e5cb-440e-bd1e-7dcdfc396748');
        await signInWithRedirect({ provider: 'Google' });
      } else {
        alert('reCAPTCHA validation failed. Please try again.');
        setLoginWithGoogleIsLoading(false);
      }
    } catch (error: any) {
      setLoginWithGoogleIsLoading(false);
      // ... handle other errors ...
    }
  };

  return (
    <AuthLayout>
      {/* <div className="flex flex-col items-center justify-center bg-mid-gray  overflow-hidden ">
        <div className=" md:max-w-sm mx-auto min-h-screen  md:h-[640px] md:min-h-[760px] h-full  overflow-hidden relative shadow-md w-full ">
          <div className="flex flex-col px-10 pt-10 md:pt-6 pb-4  bg-light-gray rounded-bl-[3rem] rounded-br-[3rem]">
            <div className="w-10 h-10 flex items-center justify-start mb-6 md:mb-2">
              <img src="/assets/onboarding/nestor-logo.png" alt="logo" />
            </div>

            <div className="flex flex-col items-center justify-center pt-8 md:pt-6">
              <div className="mb-2 w-full text-left">
                <p className="text-[41.89px] md:text-[31.89px] leading-[48px] md:leading-[38px] font-semibold text-black  font-inter">
                  Ready to book? <br />
                  Let’s get you <br />
                  setup quickly!
                </p>
              </div>

              <div className="w-full pt-8 md:pt-6">
                <div className="w-16 h-2 justify-start gap-1 inline-flex">
                  <div className="w-8 h-2 bg-black rounded-3xl" />
                  <div className="w-2 h-2 bg-[#CECECE]  rounded-full" />
                  <div className="w-2 h-2 bg-[#CECECE]  rounded-full" />
                </div>
              </div>
            </div>
          </div>
          <div className="px-8 pt-12 pb-8  md:mb-4 space-y-4">
            <form>
              <div className="w-full pb-4">
                <PrimaryTextField
                  label="Email"
                  type="email"
                  fullWidth={true}
                  value={email}
                  required={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  variant="filled"
                  size="small"
                />
              </div>
              <div className="w-full pb-4">
                <PrimaryTextField
                  label="First Name"
                  type="text"
                  fullWidth={true}
                  value={firstName}
                  required={true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="firstName"
                  variant="filled"
                  size="small"
                />
              </div>
              <div className="w-full pb-4">
                <div className="flex">
                  <PrimaryTextField
                    label="Create Password"
                    type={`${showPassword ? 'text' : 'password'}`}
                    value={password}
                    fullWidth={true}
                    required={true}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    name="password"
                    variant="filled"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="cursor-pointer " onClick={toggleShowHidePassword}>
                            <FontAwesomeIcon
                              className={`${showPassword ? 'block' : 'hidden'} text-black`}
                              icon={faEye}
                            />
                            <FontAwesomeIcon
                              className={`${showPassword ? 'hidden' : 'block'} text-black`}
                              icon={faEyeSlash}
                            />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {showPasswordHint && (
                  <p className="text-black text-left text-xs mt-1">
                    8+ characters, with special character
                  </p>
                )}
              </div>
              <div className="w-full pb-4">
                <div className="flex">
                  <PrimaryTextField
                    label="Confirm Password"
                    type={`${showConfirmPassword ? 'text' : 'password'}`}
                    value={confirmPassword}
                    fullWidth={true}
                    required={true}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    name="confirmPassword"
                    variant="filled"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="cursor-pointer " onClick={toggleShowHideConfirmPassword}>
                            <FontAwesomeIcon
                              className={`${showConfirmPassword ? 'block' : 'hidden'} text-black`}
                              icon={faEye}
                            />
                            <FontAwesomeIcon
                              className={`${showConfirmPassword ? 'hidden' : 'block'} text-black`}
                              icon={faEyeSlash}
                            />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {error && <p className="text-red-500 text-left text-xs mt-1 pt-1">{error}</p>}
              </div>

              <div className="flex justify-between items-center pt-2">
                <button
                  disabled={isValid}
                  onClick={signUpClicked}
                  className={`bg-white text-black py-2 w-full px-4 rounded-full ${
                    isValid ? 'opacity-50' : ''
                  }`}
                >
                  Get Started
                </button>
              </div>
            </form>
            <div className="items-center justify-center flex">
              <button
                onClick={handleGoogleLogin}
                className="bg-black w-full text-base relative inline-flex align-middle justify-center min-h-11 rounded-full m-0 text-center px-4 py-2"
              >
                <div className="gap-2 flex justify-center align-center text-white">
                  {loginWithGoogleIsLoading ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} color="white" spin />
                    </div>
                  ) : (
                    <>
                      <div className="pt-1">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 8.1875C16 12.625 12.9688 15.75 8.5 15.75C4.1875 15.75 0.75 12.3125 0.75 8C0.75 3.71875 4.1875 0.25 8.5 0.25C10.5625 0.25 12.3438 1.03125 13.6875 2.28125L11.5625 4.3125C8.8125 1.65625 3.6875 3.65625 3.6875 8C3.6875 10.7188 5.84375 12.9062 8.5 12.9062C11.5625 12.9062 12.7188 10.7188 12.875 9.5625H8.5V6.90625H15.875C15.9375 7.3125 16 7.6875 16 8.1875Z"
                            fill="#DCDCDC"
                          />
                        </svg>
                      </div>
                      Sign up with Google
                    </>
                  )}
                </div>
              </button>
            </div>
            <div className="text-xs text-center text-black font-inter">
              This site is protected by reCAPTCHA and the Google
              <a className="underline" href="https://policies.google.com/privacy">
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a className="underline" href="https://policies.google.com/terms">
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </div>
          </div>
        </div>
      </div> */}
      <div className="flex flex-col items-center justify-start bg-[#E5E8E8] w-full overflow-hidden p-4 min-h-screen md:h-[840px] md:min-h-[720px] h-full">
        <div className="flex w-full justify-start items-center">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.5002 6.47899C24.5002 9.76153 21.719 12.458 18.2435 12.458C14.768 12.458 11.9868 9.76153 11.9868 6.47899C11.9868 3.19646 14.768 0.5 18.2435 0.5C21.719 0.5 24.5002 3.19646 24.5002 6.47899ZM25.0002 6.47899C25.0002 10.0572 21.9751 12.958 18.2435 12.958C14.5119 12.958 11.4868 10.0572 11.4868 6.47899C11.4868 2.90074 14.5119 0 18.2435 0C21.9751 0 25.0002 2.90074 25.0002 6.47899ZM13.5726 19.7236C13.7484 22.0264 15.7525 23.8422 18.1989 23.8422C20.7609 23.8422 22.8378 21.8507 22.8378 19.394C22.8378 16.9373 20.7609 14.9458 18.1989 14.9458H18.198C18.0689 14.932 17.9397 14.9193 17.81 14.9066L17.8098 14.9066L17.8097 14.9066C16.3272 14.7614 14.7716 14.609 12.3113 12.7218C9.68246 10.516 9.27274 7.77835 9.27081 6.64641C9.27578 6.56207 9.2783 6.47711 9.2783 6.39159C9.2783 3.93489 7.20139 1.94335 4.63939 1.94335C2.0774 1.94335 0.000487892 3.93489 0.000487682 6.39158C0.000487472 8.84828 2.0774 10.8398 4.63939 10.8398C4.76399 10.8398 4.88744 10.8351 5.00956 10.8259L4.99613 10.8398C5.08169 10.8515 5.17361 10.8632 5.27146 10.8755L5.27187 10.8756L5.27204 10.8756C6.60259 11.0438 9.02704 11.3502 11.4192 13.9194C12.9061 15.5162 13.5603 18.8808 13.5603 19.7363L13.5726 19.7236ZM13.56 6.39183C13.56 8.84852 15.6369 10.8401 18.1989 10.8401C20.7609 10.8401 22.8378 8.84852 22.8378 6.39183C22.8378 3.93514 20.7609 1.9436 18.1989 1.9436C15.6369 1.9436 13.56 3.93514 13.56 6.39183ZM0 19.3942C0 21.8509 2.07691 23.8425 4.63891 23.8425C7.2009 23.8425 9.27781 21.8509 9.27781 19.3942C9.27781 16.9375 7.2009 14.946 4.63891 14.946C2.07691 14.946 0 16.9375 0 19.3942Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="flex  justify-center items-center h-[170px] w-[200px]">
          <img
            src="/assets/auth_branding_mobile_app.png"
            alt="Connector black"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex w-full justify-start items-center pt-2">
          <span className="text-black font-avenir text-[42px] leading-[50px]">
            Let's get you setup
          </span>
        </div>
        <div className="flex w-full justify-start items-center align-center gap-4 mt-2">
          <span className="text-black font-avenir text-[14px] leading-[20px] flex items-center">
            Already have an account?
            <a
              className="underline pl-2 font-avenir text-[14px] leading-[20px] cursor-pointer flex items-center"
              href="/login"
            >
              Login
              <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
            </a>
          </span>
        </div>
        <div className="flex-col w-full h- justify-start items-center gap-y-4 mt-8">
          <div className="mt-2">
            <PrimaryTextField
              label="Email"
              type="email"
              fullWidth={true}
              value={email}
              required={true}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="email"
              variant="filled"
              size="small"
            />
          </div>
          <div className="mt-2">
            <PrimaryTextField
              label="First Name"
              type="text"
              fullWidth={true}
              value={firstName}
              required={true}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              name="firstName"
              variant="filled"
              size="small"
            />
          </div>
          <div className="mt-2">
            <PrimaryTextField
              label="Create Password"
              type={`${showPassword ? 'text' : 'password'}`}
              value={password}
              fullWidth={true}
              required={true}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="password"
              variant="filled"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span className="cursor-pointer " onClick={toggleShowHidePassword}>
                      <FontAwesomeIcon
                        className={`${showPassword ? 'block' : 'hidden'} text-black text-[12px]`}
                        icon={faEye}
                      />
                      <FontAwesomeIcon
                        className={`${showPassword ? 'hidden' : 'block'} text-black text-[12px]`}
                        icon={faEyeSlash}
                      />
                    </span>
                  </InputAdornment>
                ),
              }}
            />
            {showPasswordHint && (
              <p className="text-black text-left text-xs mt-1">
                8+ characters, with special character
              </p>
            )}
          </div>
          <div className="mt-2">
            <PrimaryTextField
              label="Confirm Password"
              type={`${showConfirmPassword ? 'text' : 'password'}`}
              value={confirmPassword}
              fullWidth={true}
              required={true}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              name="confirmPassword"
              variant="filled"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span className="cursor-pointer " onClick={toggleShowHideConfirmPassword}>
                      <FontAwesomeIcon
                        className={`${showConfirmPassword ? 'block' : 'hidden'} text-black text-[12px]`}
                        icon={faEye}
                      />
                      <FontAwesomeIcon
                        className={`${showConfirmPassword ? 'hidden' : 'block'} text-black text-[12px]`}
                        icon={faEyeSlash}
                      />
                    </span>
                  </InputAdornment>
                ),
              }}
            />
            {showPasswordsDoNotMatch && (
              <p className="text-black text-left text-xs mt-1">Passwords do not match</p>
            )}
          </div>
          {error && <p className="text-red-500 text-left text-xs mt-1 pt-1">{error}</p>}
        </div>
        <div className="flex w-full justify-start items-start gap-4 mt-2">
          <span className="text-[10px]  text-black font-avenir">
            This site is protected by reCAPTCHA and the Google
            <a className="underline" href="https://policies.google.com/privacy">
              {' '}
              Privacy Policy
            </a>{' '}
            and
            <a className="underline" href="https://policies.google.com/terms">
              {' '}
              Terms of Service
            </a>{' '}
            apply.
          </span>
        </div>
        <div className="flex w-full h-auto justify-end items-center gap-4 mt-8">
          <button
            disabled={isValid}
            onClick={signUpClicked}
            className={`bg-transparent border border-black text-black py-2 w-full px-4 h-11 rounded-full font-inter text-[12px] font-medium ${
              isValid ? 'opacity-50' : ''
            }`}
          >
            Sign In
          </button>
          <button
            onClick={handleGoogleLogin}
            className="bg-black w-full text-base relative inline-flex align-middle justify-center h-11 rounded-full m-0 text-center px-4 py-2"
          >
            <div className="gap-2 flex justify-center align-center items-center text-white">
              {loginWithGoogleIsLoading ? (
                <div>
                  <FontAwesomeIcon icon={faSpinner} color="white" spin />
                </div>
              ) : (
                <span className="font-inter text-[12px] font-medium ">
                  Log in with
                  <FontAwesomeIcon icon={faGoogle} className="pl-[6px] text-[12px]" />
                </span>
              )}
            </div>
          </button>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Signup;
