import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardActions, CardContent, IconButton } from '@mui/material';
import React from 'react';

interface DesktopNumberedServiceCardProps {
  number?: number;
  title: string;
  description?: string;
  buttonText: string;
  disabled?: boolean;
  onButtonClick?: () => void;
  canDismiss?: boolean;
  onDismiss?: () => void;
  onSelectTask?: (task: string) => void;
}

const DesktopNumberedServiceCard: React.FC<DesktopNumberedServiceCardProps> = ({
  number,
  title,
  description,
  buttonText,
  disabled,
  onButtonClick,
  canDismiss,
  onDismiss,
  onSelectTask,
}) => {
  return (
    <Box display="flex" alignItems="center" mb={1} sx={{ textAlign: 'left' }}>
      <Card
        sx={{
          flexGrow: 1,
          opacity: disabled ? 0.5 : 1,
          transition: 'opacity 0.3s',
          position: 'relative',
          borderRadius: '24px',
          boxShadow: 'none',
          bgcolor: '#F7F7F7',
          marginBottom: '2px',
        }}
      >
        {canDismiss && (
          <IconButton
            size="small"
            onClick={onDismiss}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#000000',
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
        <CardContent sx={{ paddingY: 3, paddingX: 2 }}>
          <div className="flex w-full h-full items-center justify-start">
            <div className="flex-col w-auto h-full items-center justify-center mr-4">
              <div className="flex w-auto items-center justify-center">
                {!number && (
                  <div className="flex h-[48px] w-[48px] bg-[#000000] rounded-full items-center justify-center">
                    <FontAwesomeIcon icon={faCheck} color="#ffffff" className="text-[14px]" />
                  </div>
                )}
                {number && (
                  <div className="flex h-[48px] w-[48px] border-[2px] border-[#DADADA] rounded-full items-center justify-center"></div>
                )}
              </div>
            </div>
            <div
              className={`flex flex-col ${number ? 'cursor-pointer' : 'cursor-default'}`}
              onClick={number ? () => onSelectTask?.(title) : undefined}
            >
              {number && (
                <span className="text-[14px] font-avenir leading-4 mb-1">Step {number}</span>
              )}
              <span className="text-[24px] font-inter font-semibold leading-6">{title}</span>
              {description && (
                <span className="text-[14px] text-black font-avenir leading-4 mt-4">
                  {description}
                </span>
              )}
            </div>
          </div>
        </CardContent>
        {/* <CardActions>
          <div className="flex justify-end w-full">
            <button
              className={`text-[12px] text-white bg-[#000] h-10 px-4 font-avenir font-normal leading-4 rounded-3xl ${
                disabled ? 'bg-[#4caf50]' : 'bg-[#000]'
              }`}
              onClick={onButtonClick}
              disabled={disabled}
            >
              {buttonText}
            </button>
          </div>
        </CardActions> */}
      </Card>
    </Box>
  );
};

export default DesktopNumberedServiceCard;
