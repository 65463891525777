import { isGoogleImage } from '@book-nestor-monorepo/nestor-utils';
import { environment } from '../../../environments/environment';

interface ProfilePictureProps {
  picture?: string;
  firstName?: string;
  height?: number;
  width?: number;
  fontSize?: number;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  picture,
  firstName,
  height = 80,
  width = 80,
  fontSize = 38,
}) => {
  if (picture && isGoogleImage(picture)) {
    return (
      <img src={picture} alt="profile" className={`h-[${height}px] w-[${width}px] rounded-full `} />
    );
  } else if (picture) {
    return (
      <img
        src={`${environment.s3.bucketUrl}/${picture}`}
        alt="profile"
        className={`h-[${height}px] w-[${width}px] rounded-full `}
      />
    );
  }
  return (
    <div
      className={`h-[${height}px] w-[${width}px] bg-blue-status rounded-full flex items-center justify-center`}
    >
      <span className={`text-white font-bold text-[${fontSize}px] pt-1`}>{firstName?.[0]}</span>
    </div>
  );
};
