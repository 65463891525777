import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { signOut } from 'aws-amplify/auth';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/appContext';
import { useModal } from '../../contexts/modalContext';
import { TItem } from './data';
import { FormattedTask } from '../../contexts/quickStartContext';

interface IProps {
  items: TItem[];
  title: string;
  formattedTasks?: FormattedTask[];
}

const SubMenu: FC<IProps> = ({ items, title, formattedTasks }) => {
  const { toggleMenu } = useContext(AppContext);
  const { closeModal } = useModal();
  const history = useNavigate();

  const handleLogout = async (event: any) => {
    try {
      await signOut();
      history('/login');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const openLink = (url?: string, type?: string) => {
    if (type === 'logout') {
      handleLogout('');
    } else if (type === 'support' && window.Intercom) {
      window.Intercom('show');
    } else if (url) {
      history(url);
      closeModal();
    }

    toggleMenu();
  };

  const formattedItems = items.map((item) => {
    const matchingTask = formattedTasks?.find((task) => task.url === item.url);
    return {
      ...item,
      todo: matchingTask ? true : false,
    };
  });

  const [open, setOpen] = useState(true);

  return (
    <div className="mt-2 w-full bg-white rounded-[16px] py-[19px] px-[14px]">
      <button onClick={() => setOpen(!open)} className="flex items-center justify-between w-full">
        <h3 className="text-[#9B9B9B]">{title}</h3>
        <FontAwesomeIcon
          className={`w-3 h-4 transition-all duration-200 ${!open ? '-rotate-90' : ''}`}
          icon={faChevronDown}
          color="#A8A8A8"
        />
      </button>
      <div
        className={`flex flex-col gap-6 overflow-hidden transition-all duration-200 ease-in-out`}
        style={{
          maxHeight: open ? `${items.length * 50}px` : '0',
        }}
      >
        {formattedItems.map(({ icon, title, type, url, todo }) => (
          <button
            key={title}
            onClick={() => openLink(url, type)}
            className="flex items-center gap-3 first:mt-7"
          >
            {icon}
            <h6 className="font-semibold text-black-text">{title}</h6>
            {todo && <div className="w-[11px] h-[11px] rounded-full bg-[#FF0000] mb-1"></div>}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubMenu;
