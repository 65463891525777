import {
  Contact,
  ContactBooking,
  ContactNote,
  ContactPayment,
  Review,
  User,
} from '@book-nestor-monorepo/shared-types';
import {
  faCalendar,
  faCalendarPlus,
  faFile,
  faNote,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Collapse } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ContactDetailCard } from '../../components/v2/Contact/ContactDetailCard';
import { ContactNotesCard } from '../../components/v2/Contact/ContactNotesCard';
import { ContactPaymentsCard } from '../../components/v2/Contact/ContactPaymentsCard';
import { ContactReviewCard } from '../../components/v2/Contact/ContactReviewCard';
import { ContactPaymentAction } from '../../components/v2/Contact/ContentPaymentAction';
import { MultiPartSkeleton } from '../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../contexts/authContext';
import { useMuiModal } from '../../contexts/muiFlyupModal';
import { environment } from '../../environments/environment';
import {
  createStripeCustomer,
  deleteContact,
  getContact,
  getContactNotes,
  getPaymentHistory,
} from '../../libs/services/contacts';
import { getContactBookings, getReviewByContactId } from '../../libs/services/reviews';
import { formatIsoDateString } from '../../libs/utils/date.util';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { useContacts } from '../../contexts/v2/contactsContext';
import { ConfirmDeleteButton } from '../../components/formLibrary/confirmDeleteButton';
import { cancelBooking } from '../../libs/services/bookings';

export const ContactDetailV2 = () => {
  const [expandedCard, setExpandedCard] = useState('details');
  const { contactId } = useParams();
  const [contact, setContact] = useState<Contact>();
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [contactReview, setContactReview] = useState<Review | undefined>(undefined);
  const [contactPayments, setContactPayments] = useState<ContactPayment[]>([]);
  const [userSlug, setUserSlug] = useState<string | undefined>(authContext.user?.user_slug?.slug);
  const [contactNotes, setContactNotes] = useState<ContactNote[] | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useAppExperience();
  const [upcomingAppointments, setUpcomingAppointments] = useState<ContactBooking[] | undefined>(
    undefined,
  );
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const navigate = useNavigate();
  const { fetchContacts } = useContacts();

  const bookingLink = () => {
    if (contactId) {
      return `${environment.baseBookingUrl}/${userSlug}?name=${contactId}`;
    }
    return `${environment.baseBookingUrl}/${userSlug}`;
  };

  const getContactReference = useCallback((contact: Contact | undefined): string => {
    if (!contact) return 'Unknown Contact';
    if (contact?.name && contact?.last_name) return `${contact.name} ${contact.last_name}`;
    if (contact?.name) return contact.name;
    return 'Unknown Contact';
  }, []);

  const contactReference = useMemo(
    () => getContactReference(contact),
    [contact, getContactReference],
  );

  const toggleCard = (cardName: string) => {
    setExpandedCard(expandedCard === cardName ? '' : cardName);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (contactId && authContext.user?.id) {
          const contact = await getContact(authContext.user?.id, contactId);
          setContact(contact);
          setUserSlug(authContext.user?.user_slug?.slug);
          if (contact) {
            try {
              const contactReview = await getReviewByContactId(authContext.user?.id, contactId);
              setContactReview(contactReview);
            } catch (error) {
              Sentry.captureException(error);
            }

            let contactNotesRes: ContactNote[] = [];
            try {
              contactNotesRes = await getContactNotes(authContext.user?.id, contactId);
              setContactNotes(contactNotesRes);
            } catch (error) {
              Sentry.captureException(error);
            }

            try {
              const contactPayments = await getPaymentHistory(authContext.user?.id, contactId);
              setContactPayments(contactPayments);
            } catch (error) {
              Sentry.captureException(error);
            }

            const contactBookings = await getContactBookings(authContext.user?.id, contactId);
            const now = new Date();
            const upcoming = [];
            const past = [];

            for (const booking of contactBookings) {
              const note = contactNotesRes?.find(
                (note) => note.appointment_id === booking.booking_id,
              );
              if (note) {
                booking.note = note;
              }

              const bookingDate = new Date(booking.booking_start_time);
              if (bookingDate > now) {
                upcoming.push(booking);
              } else {
                past.push(booking);
              }
            }

            setUpcomingAppointments(upcoming);
          }
        }
      } catch (error) {
        setIsLoading(false);
        Sentry.captureException(error);
      }
      setIsLoading(false);
    };

    if (contactId !== 'new') {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [contactId]);

  interface CardProps {
    title: string;
    content: React.ReactNode;
    icon?: React.ReactNode;
    bgColor?: string;
    contentAction?: React.ReactNode;
  }

  const renderCard = ({ title, content, icon, bgColor = '#F5F5F7', contentAction }: CardProps) => {
    const isExpanded = expandedCard === title?.toLowerCase();

    return (
      <Card
        className="mb-4 shadow-md rounded-3xl overflow-hidden"
        sx={{
          borderRadius: '30px',
          backgroundColor: bgColor,
          marginBottom: '8px',
          minHeight: '60px',
        }}
      >
        <CardContent
          sx={{
            padding: '8px',
            backgroundColor: bgColor,
            '&:last-child': {
              paddingBottom: '8px',
            },
          }}
          className="flex justify-start items-start cursor-pointer bg-gray-100 h-auto"
          onClick={() => toggleCard(title?.toLowerCase())}
        >
          <div className="flex items-center w-full">
            <div className="flex items-center">{icon ? icon : <></>}</div>
            <div className="flex pl-4 justify-between items-center w-full">
              <div className="flex">
                <span className="font-avenir font-bold text-[16px]">{title}</span>
              </div>
              {contentAction && contentAction}
            </div>
          </div>
        </CardContent>
        <Collapse in={isExpanded} unmountOnExit>
          <CardContent>{content}</CardContent>
        </Collapse>
      </Card>
    );
  };

  const onReviewSuccess = async () => {
    if (contactId && authContext.user?.id) {
      const contactReview = await getReviewByContactId(authContext.user?.id, contactId);
      setContactReview(contactReview);
    }
  };

  const onContactDetailSuccess = async () => {
    if (contactId && authContext.user?.id) {
      const contact = await getContact(authContext.user?.id, contactId);
      setContact(contact);
    }
  };

  const onPaymentSuccess = async () => {
    if (contactId && authContext.user?.id) {
      const contact = await getContact(authContext.user?.id, contactId);
      setContact(contact);
    }
  };

  const nextAppointmentString = () => {
    if (
      upcomingAppointments?.[0]?.booking_start_time &&
      upcomingAppointments?.[0]?.booking_status !== 'cancelled'
    ) {
      return formatIsoDateString(upcomingAppointments?.[0]?.booking_start_time);
    }
    return 'No Upcoming';
  };

  const handleDelete = async () => {
    if (contactId && authContext.user?.id) {
      await deleteContact(authContext.user?.id, contactId);
      await fetchContacts(true);
      navigate('/contacts');
    }
  };

  if (isLoading) return <MultiPartSkeleton color="#DADCDC" />;

  const cancelBookingClick = async (booking: ContactBooking) => {
    if (authContext.user?.id && booking.booking_id) {
      await cancelBooking(authContext.user?.id, booking.booking_id);
      navigate('/');
    }
  };

  const setRescheduleUidInUrl = (rescheduleUid: string) => {
    setSearchParams((prev) => {
      prev.set('rescheduleUid', rescheduleUid);
      return prev;
    });
  };

  const rescheduleBookingClick = () => {
    if (isMobile) {
      navigate(`/booking-reschedule/${upcomingAppointments?.[0]?.booking_id}`);
    } else {
      setRescheduleUidInUrl(upcomingAppointments?.[0]?.booking_id as string);
    }
  };

  return (
    <div className="flex flex-col p-1  justify-start h-full overflow-y-auto scrollbar-hide">
      <div className="flex flex-col w-full">
        <div className="flex pl-4 md:pt-8">
          <span
            onClick={() => {
              navigate('/contacts');
            }}
            className="text-white text-[16px] font-avenir font-normal cursor-pointer"
          >
            {'Clients >'}
          </span>
          <span className="text-white text-[16px] font-avenir ml-2 cursor-auto font-bold">
            {contactReference}
          </span>
        </div>
      </div>

      <div className="flex flex-col w-full pt-8">
        {contact &&
          renderCard({
            title: 'Details',
            content: (
              <ContactDetailCard
                contact={contact}
                onSuccess={onContactDetailSuccess}
                onDelete={handleDelete}
              />
            ),
            icon: (
              <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                <FontAwesomeIcon icon={faUser} color="#000" className="text-[12px]" />
              </div>
            ),
            bgColor: '#E5E5E5',
          })}

        {contact &&
          renderCard({
            title: 'Reviews',
            content: (
              <ContactReviewCard
                contact={contact}
                review={contactReview}
                onSuccess={onReviewSuccess}
              />
            ),
            icon: (
              <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
                <span className="font-inter text-[16px]">{contactReview ? 1 : 0}</span>
              </div>
            ),
            bgColor: '#E5E5E5',
          })}

        {contact &&
          renderCard({
            title: 'Payments',
            content: (
              <ContactPaymentsCard
                user={authContext.user as User}
                contact={contact}
                contactPayments={contactPayments}
                onSuccess={onPaymentSuccess}
                onCreateStripeCustomer={async () => {
                  const updatedContact = await createStripeCustomer(
                    authContext.user?.id as string,
                    contact?.id as string,
                  );
                  setContact(updatedContact);
                  return updatedContact;
                }}
              />
            ),
            icon: (
              <div className="h-11 w-11 bg-[#E5E5E5] rounded-full flex items-center justify-center">
                <span className="font-inter text-[16px]">{contactPayments?.length}</span>
              </div>
            ),
            contentAction: (
              <div
                className="flex pr-6 h-11 w-auto px-6 py-[2px] items-center rounded-full bg-[#000000]/10"
                onClick={() => {
                  openMuiModal(<ContactPaymentAction onClose={closeMuiModal} contact={contact} />);
                }}
              >
                <span className="font-inter text-[12px]">Take Payment</span>
              </div>
            ),
            bgColor: '#F5F5F7',
          })}

        {renderCard({
          title: nextAppointmentString(),
          content: <div></div>,
          icon: (
            <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
              <FontAwesomeIcon icon={faCalendar} color="#000" className="text-[12px]" />
            </div>
          ),
          ...(!upcomingAppointments?.[0] || upcomingAppointments?.[0]?.is_cancelled
            ? {
                contentAction: (
                  <div
                    className="flex pr-6 h-11 w-auto px-6 py-[2px] items-center rounded-full bg-[#000000]/10 xl:hidden"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate('/booking-mobile', {
                        state: {
                          contactId: contactId,
                        },
                      });
                    }}
                  >
                    <span className="font-inter text-[12px]">Book Appointment</span>
                  </div>
                ),
              }
            : {
                contentAction: (
                  <div className="flex h-full w-auto">
                    <div className="flex items-center justify-end w-full">
                      <ConfirmDeleteButton
                        text="Cancel?"
                        onClick={() => {
                          cancelBookingClick(upcomingAppointments?.[0]);
                        }}
                      />
                      <button
                        onClick={rescheduleBookingClick}
                        className="text-[12px] font-avenir flex items-center"
                      >
                        <div className="h-11 w-11 border bg-black-alt/10 flex items-center justify-center rounded-full">
                          <FontAwesomeIcon icon={faCalendarPlus} />
                        </div>
                      </button>
                    </div>
                  </div>
                ),
              }),
          bgColor: '#E5E5E5',
        })}

        {renderCard({
          title: 'Notes',
          content: (
            <ContactNotesCard
              contactId={contactId as string}
              note={contactNotes?.find((note) => !note.appointment_id)}
              noteTitle={contact?.name}
            />
          ),
          icon: (
            <div className="h-11 w-11 bg-[#000000]/10 rounded-full flex items-center justify-center">
              <FontAwesomeIcon icon={faNote} color="#000" className="text-[12px]" />
            </div>
          ),
          bgColor: '#D1DEDD',
        })}
      </div>
    </div>
  );
};
