import * as Sentry from '@sentry/react';
import { Field, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingDots from '../components/loading';
import { AuthContext } from '../contexts/authContext';
import { createCheckoutSession } from '../libs/services/connect';
import { AppLayout } from './layouts/app-layout';

export default function Checkout() {
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const initialAmount = searchParams.get('amount') || '';
  const initialProductName = searchParams.get('productName') || '';
  const customerEmail = searchParams.get('customerEmail') || '';

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      // Handle form submission logic here
      const { url } = await createCheckoutSession({
        connected_account_id: authContext.user?.payment_connect_data
          ?.connected_account_id as string,
        amount: parseInt(values.amount),
        product_name: values.productName,
        customer_email: customerEmail,
      });
      window.location.href = url;
      // Reset form after submission
      setSubmitting(false);
    } catch (error) {
      Sentry.captureException(error);
      setSubmitting(false);
    }
  };

  return (
    <AppLayout>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col items-left md:items-center text-center px-4">
          <div className="flex overflow-x-auto mb-4 hide-scrollbar">
            <Formik
              initialValues={{
                amount: initialAmount,
                productName: initialProductName,
                customerEmail: customerEmail,
              }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <label htmlFor="amount" className="block mb-2">
                      Amount
                    </label>
                    <Field
                      type="number"
                      id="amount"
                      name="amount"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="productName" className="block mb-2">
                      Product Name
                    </label>
                    <Field
                      type="text"
                      id="productName"
                      name="productName"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="customerEmail" className="block mb-2">
                      Customer Email
                    </label>
                    <Field
                      type="email"
                      id="customerEmail"
                      name="customerEmail"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </AppLayout>
  );
}
