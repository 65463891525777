import { faArrowLeftLong, faBars } from '@fortawesome/pro-light-svg-icons';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { useQuickStart } from '../../../contexts/quickStartContext';
import { StackedCircularProgress } from '../../common/CircularProgress/CircularProgress';

type ToggleMenuFunction = () => void;

export const MobileAppMenu = ({
  toggleMenu,
  isOpen,
}: {
  toggleMenu: ToggleMenuFunction;
  isOpen: boolean;
}) => {
  const navigate = useNavigate();
  const { mobileAppMenuBackArrowFunction } = useAppExperience();
  const { totalTasksCount, completedTaskCountForPlan, allTasksCompleted, totalTaskCountForPlan } =
    useQuickStart();

  const navigateHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const renderQuickStartProgress = () =>
    !allTasksCompleted && (
      <div className="mr-3 cursor-pointer pt-2" onClick={() => navigate('/quick-start')}>
        <StackedCircularProgress
          size="48px"
          thickness={3}
          completedValue={completedTaskCountForPlan}
          completedTotal={totalTaskCountForPlan}
          completedColor="#4caf50"
          totalValue={totalTasksCount}
          totalTotal={totalTasksCount}
          totalColor="#DDDDDD"
        />
      </div>
    );

  return (
    <div>
      <div className="flex justify-between flex-grow gap-0">
        <div className="flex items-center gap-2 ml-4">
          <Button
            sx={{ paddingX: '0px', width: 'auto', minWidth: 'auto', paddingBottom: '0px' }}
            onClick={toggleMenu}
          >
            <div className="w-12 h-12 bg-[#ffffff] rounded-full flex items-center justify-center">
              <FontAwesomeIcon icon={faBars} size="sm" color="black" />
            </div>
          </Button>
          <Button
            sx={{ paddingX: '0px', paddingBottom: '0px', width: 'auto', minWidth: 'auto' }}
            onClick={navigateHome}
          >
            <div className="w-12 h-12 bg-[#ffffff] rounded-full flex items-center justify-center">
              <FontAwesomeIcon icon={faHome} size="sm" color="black" />
            </div>
          </Button>
          {/* {renderQuickStartProgress()} */}
          <MobileMenuDrawer isOpen={isOpen} toggleMenu={toggleMenu} />
        </div>
      </div>
      {/* <div className="flex items-center space-x-2 pt-[30px]">
        <Button onClick={mobileAppMenuBackArrowFunction}>
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faArrowLeftLong} size="xl" color="white" />
          </div>
        </Button>
      </div> */}
    </div>
  );
};
