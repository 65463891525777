import React from 'react';
import { Contact } from '@book-nestor-monorepo/shared-types';
import { Booker } from '@calcom/atoms';

export interface ScaledBookerProps {
  scale: number;
  eventTypeSlug: string;
  username: string;
  contact: Contact;
  rescheduleUid?: string;
  onSuccess: (data: any) => void; // Add this line
}

export const ScaledBooker: React.FC<ScaledBookerProps> = ({ scale = 0.8, onSuccess, ...props }) => {
  return (
    <div
      className="scaled-booker-wrapper"
      style={{
        fontSize: `${scale}rem`,
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }}
    >
      <Booker
        eventSlug={props.eventTypeSlug as string}
        username={props.username as string}
        defaultFormValues={{
          firstName: props.contact?.name || ' ',
          lastName: props.contact?.last_name || ' ',
          name: props.contact ? `${props.contact?.name} ${props.contact?.last_name}` : ' ',
          email: props.contact?.email || ' ',
        }}
        onCreateBookingSuccess={async (data) => {
          if (onSuccess) {
            onSuccess(data);
          }
        }}
      />
    </div>
  );
};
