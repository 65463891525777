import {
  OnboardingData,
  OnboardingTask,
  OnboardingTaskNames,
  SubscriptionPlan,
} from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { patchUser } from '../libs/services/user';
import { AuthContext } from './authContext';
import { useNavigate } from 'react-router-dom';

export interface FormattedTask {
  url?: string;
  number?: number;
  title: string;
  description: string;
  buttonText: string;
  disabled: boolean;
  buttonOnClick: () => void;
  onDismiss?: () => void;
  canBeSkipped: boolean;
  selectedTask?: OnboardingTaskNames;
  name: OnboardingTaskNames;
  desktopParagraphs?: string[];
  desktopHeader?: string;
  confirmDeleteText?: string;
}

interface TaskInfo {
  title: string;
  description: string;
  buttonText: string;
  url: string;
  desktopParagraphs?: string[];
  desktopHeader?: string;
  confirmDeleteText?: string;
}

const taskInfoMap: Record<OnboardingTaskNames, TaskInfo> = {
  createAccount: {
    title: 'Create Your Account',
    description: 'Creating your Nestor Account is the first step to growing your business.',
    buttonText: 'Start',
    url: '/',
  },
  verifyYourTimeZone: {
    title: 'Verify Your Time Zone',
    description:
      'Verifying your time zone ensures your availability is accurate and your bookings are scheduled correctly.',
    buttonText: 'Start',
    url: '/personal-settings?onboardingContext=true',
    desktopHeader: 'Verify Your Time Zone',
    desktopParagraphs: [
      'Verifying your time zone ensures your availability is accurate and your bookings are scheduled correctly.',
    ],
  },
  updateBusinessInfo: {
    title: 'Update Your Business Profile',
    description:
      'Ensure clients can find and book your business easily.  Accurate details build trust, enhance your image, and streamline bookings.',
    buttonText: 'Start',
    url: '/business-profile?onboardingContext=true',
    desktopHeader: 'Build Your Business',
    desktopParagraphs: [
      'The information you enter here will be included on your booking page for clients to see. First impressions matter—a strong profile can boost credibility. In fact, statistics show that 75% of clients judge a business based on its online presence. ',
      'This step helps make your business look professional, trustworthy, and welcoming, encouraging clients to engage and book with you. Let’s make sure your first impression is a great one!',
    ],
  },
  addFirstBusinessImage: {
    title: 'Add Your First Business Image',
    description:
      'All images added to your business profile are displayed on your booking page.  Images help your clients understand your business and increase the chances of booking.',
    buttonText: 'Start',
    url: '/business-profile?onboardingContext=true',
    desktopHeader: 'Show Off Your Business',
    desktopParagraphs: [
      'Adding photos to your gallery lets clients see the quality of your work and get a feel for your style. A strong gallery builds trust, helping clients feel confident in booking with you.',
      'Showcase your space, services, or any results that represent your brand best. Highlighting the details that make you unique helps clients connect with your business, giving them a visual sense of what to expect.',
      'Visuals are powerful—businesses with photo galleries often see higher engagement and more bookings. Let your photos do the talking, creating a strong first impression that draws clients in!',
    ],
  },
  createAService: {
    title: 'Create Your Services',
    description:
      'Add your services, set prices, and specify durations.  Clear descriptions help clients choose the right option.  Organize for easy navigation and streamlined booking.',
    buttonText: 'Start',
    url: '/services?onboardingContext=true',
    desktopHeader: 'Create Your Services',
    desktopParagraphs: [
      'Adding your services here lets clients easily see what you offer and choose what best fits their needs. ',
      'This step organizes your services, shows pricing, and highlights any specialties. A clear list helps clients understand what to book, setting expectations from the start.',
    ],
  },
  verifyAvailability: {
    title: 'Verify Your Availability',
    description:
      "Set your business hours so clients know when you're available.  Clear hours help streamline bookings and avoid scheduling conflicts.",
    buttonText: 'Start',
    url: '/availability?onboardingContext=true',
    desktopHeader: 'Verify Your Availability',
    desktopParagraphs: [
      'Setting your availability here ensures clients can book you at the right times. Accurate hours prevent scheduling conflicts and keep your calendar organized, making it easy for clients to find a time that works for them.',
      'This step helps you avoid double bookings and maintain a smooth workflow. By offering a reliable booking experience, you’ll build trust with clients, who can count on your schedule to be accurate and convenient.',
    ],
    confirmDeleteText:
      'Skipping this step means you will keep the default 9-5, M-F availability. You can always change your availability later in your Availability settings.',
  },
  verifyAIAssistant: {
    title: 'Create Your AI Assistant',
    description:
      'Setup your AI Assistant to handle client inquiries, manage bookings, and streamline your scheduling process automatically.',
    buttonText: 'Start',
    url: '/communication',
    desktopHeader: 'Create Your AI Assistant',
    desktopParagraphs: [
      'Setting up your AI Assistant lets it handle client inquiries, manage bookings, and streamline your scheduling process automatically. This ensures clients receive quick responses and can book services without needing your direct involvement.',
      'The AI Assistant not only saves you time but also keeps your schedule organized and up-to-date. By automating these tasks, you’re providing a smoother, more professional booking experience that allows you to focus more on your clients and less on admin work.',
    ],
  },
  linkStripeAccount: {
    title: 'Take Payments With Stripe',
    description: 'Link Stripe to start accepting payments easily and securely from your clients.',
    buttonText: 'Start',
    url: '/payments?onboardingContext=true',
    desktopHeader: 'Take Payments With Stripe',
    desktopParagraphs: [
      'Adding Stripe to your booking page makes payment fast, easy, and secure for you and your clients. When clients pay online at the time of booking, it helps reduce no-shows and guarantees your income, streamlining your payment process.',
      'Stripe is a trusted platform, relied upon by millions, and gives clients peace of mind when booking. By offering secure online payments, you’re showing clients that your business is professional and convenient, making it easier for them to book with confidence.',
    ],
    confirmDeleteText:
      'Skipping this step means you will only be able to accept cash payments for now. You can change this later in your Payments settings.',
  },
  updateBookingLink: {
    title: 'Customize Booking Link',
    description:
      'Customize your booking link to share with clients.  This makes it easy for them to shcedule appointments directly with you.',
    buttonText: 'Start',
    url: '/personal-settings',
    desktopHeader: 'Customize Your Booking Link',
    desktopParagraphs: [
      'Customizing your booking link makes it easy for clients to find and book with you directly. A personalized link is memorable and shareable, helping clients reach your booking page with just one click.',
      'This setup streamlines the booking process, giving clients a simple way to access your services. A unique link also reinforces your brand, showing clients a professional, organized, and user-friendly booking experience.',
    ],
  },
};

interface QuickStartContextType {
  totalTasksCount: number;
  completedTasksCount: number;
  totalTaskCountForPlan: number;
  completedTaskCountForPlan: number;
  allTasksCompleted: boolean;
  markTaskComplete: (taskName: OnboardingTaskNames) => Promise<void>;
  isLoading: boolean;
  tasks: OnboardingTask[];
  completedFormattedTasks: FormattedTask[];
  remainingFormattedTasks: FormattedTask[];
  subscriptionTierFormattedTasks: FormattedTask[];
  selectedTask?: string;
}

const defaultQuickStartContext: QuickStartContextType = {
  totalTasksCount: 0,
  completedTasksCount: 0,
  allTasksCompleted: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markTaskComplete: async () => {},
  isLoading: true,
  tasks: [],
  completedFormattedTasks: [],
  remainingFormattedTasks: [],
  subscriptionTierFormattedTasks: [],
  totalTaskCountForPlan: 0,
  completedTaskCountForPlan: 0,
  selectedTask: undefined,
};

const QuickStartContext = createContext<QuickStartContextType>(defaultQuickStartContext);

export const QuickStartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { user, updateLoggedInUser } = useContext(AuthContext);
  const [quickStartState, setQuickStartState] =
    useState<QuickStartContextType>(defaultQuickStartContext);

  const updateQuickStartState = (onboardingData: OnboardingData | undefined) => {
    if (!onboardingData || !onboardingData.onboarding_tasks) {
      setQuickStartState({ ...defaultQuickStartContext, isLoading: false });
      return;
    }

    const tasks = onboardingData.onboarding_tasks;
    const userPlan = user?.subscription as SubscriptionPlan;
    const totalTaskCountForPlan = tasks.filter((task) => {
      if (!task.exclusion_product_tiers) {
        return true;
      }
      return !task.exclusion_product_tiers.includes(userPlan);
    }).length;
    const completedTaskCountForPlan = tasks.filter((task) => {
      if (task.completed_at) {
        if (!task.exclusion_product_tiers) {
          return true;
        }
        return !task.exclusion_product_tiers.includes(userPlan);
      }
      return false;
    }).length;
    const totalTasksCount = tasks.length;
    const completedTasksCount = tasks.filter((task) => task.completed_at).length;
    const allTasksCompleted = totalTaskCountForPlan === completedTaskCountForPlan;

    setQuickStartState((prevState) => ({
      ...prevState,
      totalTasksCount,
      completedTasksCount,
      allTasksCompleted,
      totalTaskCountForPlan,
      completedTaskCountForPlan,
      isLoading: false,
      tasks,
    }));
  };

  const markTaskComplete = async (taskName: OnboardingTaskNames) => {
    if (!user) return;

    try {
      const session = await fetchAuthSession();
      const userId = session?.tokens?.accessToken?.payload?.sub;
      if (!userId) throw new Error('User ID not found');

      // Check if the task is already complete
      const taskToComplete = user.onboarding_data?.onboarding_tasks?.find(
        (task) => task.name === taskName,
      );
      if (taskToComplete?.completed_at) {
        return;
      }

      const updatedTasks =
        user.onboarding_data?.onboarding_tasks?.map((task) =>
          task.name === taskName ? { ...task, completed_at: new Date().toISOString() } : task,
        ) || [];

      const updatedUser = await patchUser(userId, {
        onboarding_data: {
          ...user.onboarding_data,
          onboarding_tasks: updatedTasks,
        },
      });

      if (updateLoggedInUser) {
        updateLoggedInUser(updatedUser);
      }

      updateQuickStartState(updatedUser.onboarding_data);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const selectTask = (task: OnboardingTaskNames) => {
    setQuickStartState((prevState) => ({ ...prevState, selectedTask: task }));
  };

  useEffect(() => {
    if (user) {
      updateQuickStartState(user.onboarding_data);
    }
  }, [user]);

  const formatTask = (task: OnboardingTask, index: number): FormattedTask => {
    const taskInfo = taskInfoMap[task.name];
    return {
      title: taskInfo.title,
      description: taskInfo.description,
      buttonText: task.completed_at ? 'Completed' : taskInfo.buttonText,
      disabled: !!task.completed_at,
      buttonOnClick: () => {
        selectTask(task.name);
        navigate(taskInfo.url);
      },
      canBeSkipped: task.can_dismiss,
      onDismiss: () => markTaskComplete(task.name),
      url: taskInfo.url,
      name: task.name,
      desktopParagraphs: taskInfo.desktopParagraphs,
      desktopHeader: taskInfo.desktopHeader,
      confirmDeleteText: taskInfo.confirmDeleteText,
    };
  };

  const { completedFormattedTasks, remainingFormattedTasks, subscriptionTierFormattedTasks } =
    useMemo(() => {
      const completed: FormattedTask[] = [];
      const remaining: FormattedTask[] = [];
      const subscriptionTier: FormattedTask[] = [];
      let completedTaskNumber = 0;

      quickStartState.tasks.forEach((task, index) => {
        const formattedTask = formatTask(task, index);

        if (task.completed_at) {
          completed.push(formattedTask);
        } else if (
          task.exclusion_product_tiers &&
          user &&
          task.exclusion_product_tiers.includes(user.subscription as SubscriptionPlan)
        ) {
          subscriptionTier.push(formattedTask);
        } else {
          completedTaskNumber++;
          formattedTask.number = completedTaskNumber;
          remaining.push(formattedTask);
        }
      });

      return {
        completedFormattedTasks: completed,
        remainingFormattedTasks: remaining,
        subscriptionTierFormattedTasks: subscriptionTier,
      };
    }, [quickStartState.tasks, user]);

  const contextValue = useMemo(
    () => ({
      ...quickStartState,
      markTaskComplete,
      completedFormattedTasks,
      remainingFormattedTasks,
      subscriptionTierFormattedTasks,
    }),
    [
      quickStartState,
      completedFormattedTasks,
      remainingFormattedTasks,
      subscriptionTierFormattedTasks,
    ],
  );

  return <QuickStartContext.Provider value={contextValue}>{children}</QuickStartContext.Provider>;
};

export const useQuickStart = () => useContext(QuickStartContext);
