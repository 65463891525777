import { Organization } from '@book-nestor-monorepo/shared-types';
import { environment } from '../../environments/environment';

const BookingSliderMobile = ({
  organization,
  userUploadPhotoLimit,
}: {
  organization: Organization;
  userUploadPhotoLimit: number;
}) => {
  const galleryImages = organization.gallery_image_keys || [];

  if (galleryImages.length === 0) {
    return <div />;
  }

  const getGalleryImageUrl = (imageKey: string) => {
    return `${environment.s3.bucketUrl}/${imageKey}`;
  };

  const items = [];

  if (galleryImages.length === 0) {
    // If no images, use a placeholder or default image
    items.push(...Array(4).fill('/assets/bookings/booking-main-coming-soon-desktop.jpg'));
  } else if (galleryImages.length < 4) {
    // If less than 4 images, use available images and repeat to fill 4 slots
    const availableImages = galleryImages.map(getGalleryImageUrl);
    items.push(...availableImages);
    while (items.length < 4) {
      items.push(...availableImages);
    }
    items.splice(4); // Ensure we only have 4 items
  } else {
    // Use up to userUploadPhotoLimit images
    const imageUrls = galleryImages.slice(0, userUploadPhotoLimit).map(getGalleryImageUrl);
    items.push(...imageUrls);

    // If we have less than 4 items, repeat to fill at least 4 slots
    while (items.length < 4) {
      items.push(...imageUrls);
    }
  }

  return (
    <div className="w-full flex items-center overflow-y-hidden overflow-x-auto hide-scrollbar md:hidden">
      {items.map((image, index) => (
        <img
          className="w-[184px] min-w-[184px] h-[240px] object-cover"
          alt=""
          src={image}
          key={index}
        />
      ))}
    </div>
  );
};

export default BookingSliderMobile;
