import { Booking, Contact } from '@book-nestor-monorepo/shared-types';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatIsoDateString } from '../../libs/utils/date.util';
import { useContext, useEffect, useState } from 'react';
import { faArrowRight, faRotate } from '@fortawesome/pro-light-svg-icons';
import { AuthContext } from '../../contexts/authContext';
import { Link } from 'react-router-dom';
import PrimaryTextFieldLight from '../formLibrary/primaryTextInputLight';
import PrimaryTextField from '../formLibrary/primaryTextInput';
import { InputAdornment } from '@mui/material';
import { cleanCalcomEventTitleToJustEventType } from '../../libs/utils/string.util';

interface PaymentType {
  title: string;
  onClose: () => void;
  contact: Contact;
  onSubmit: (amount: number, service: string) => void;
  onMarkAsPaid: (amount: number, service: string) => void;
  booking?: Booking;
}

const SendPaymentModal = ({
  title,
  onClose,
  contact,
  onSubmit,
  booking,
  onMarkAsPaid,
}: PaymentType) => {
  const authContext = useContext(AuthContext);
  const [useBooking, setUseBooking] = useState(true);
  const [service, setService] = useState('');
  const [amount, setAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (booking) {
      setService(useBooking ? cleanCalcomEventTitleToJustEventType(booking.title) : '');
    }
  }, [useBooking, booking]);

  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-row items-center ${title ? 'justify-between' : 'justify-end'} h-12 mb-6`}
      >
        <div className="flex flex-col">
          {title && (
            <p className="font-inter font-medium text-base leading-4 text-white pb-2">Payment</p>
          )}
          {booking && (
            <button onClick={() => setUseBooking(!useBooking)} className="text-[#5f4cfe] text-sm ">
              {useBooking ? 'Switch to General Payment' : 'Switch to Appointment Payment'}
              <FontAwesomeIcon icon={faRotate} className="pl-2" />
            </button>
          )}
        </div>

        <span
          className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-white"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
      {useBooking && booking?.contact_booking?.client_has_paid ? (
        <div className="flex flex-col justify-between pt-1.5 px-2 pb-4  mb-2">
          <p className="text-[41.89px] leading-[48px] text-white font-extralight">Booking paid</p>
          {booking && useBooking && (
            <div className="flex flex-col">
              <p className="text-xs text-white">
                Payment for Appointment on: {formatIsoDateString(booking.start_time)}
              </p>
              {booking?.contact_booking?.booking_paid_date && (
                <p className="text-xs text-white">
                  Booking Paid on: {booking?.contact_booking?.booking_paid_date}
                </p>
              )}
              {booking?.contact_booking?.booking_paid_amount && (
                <p className="text-xs text-white">
                  Payment Amount: $
                  {!isNaN(booking?.contact_booking?.booking_paid_amount) &&
                    Number(booking?.contact_booking?.booking_paid_amount / 100)}
                </p>
              )}
              {booking?.contact_booking?.payment_session_id ? (
                <p className="text-xs text-white line-clamp-1">Payment Method: Stripe</p>
              ) : (
                <p className="text-xs text-white">Payment Method: Manual</p>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-between pt-1.5 px-2 pb-4  mb-2">
            <p className="text-[41.89px] leading-[48px] text-white font-extralight">
              Begin Payment Process
            </p>
            {booking && useBooking && (
              <div className="flex flex-col">
                <p className="text-xs text-white">
                  Payment for Appointment on {formatIsoDateString(booking.start_time)}
                </p>
              </div>
            )}
          </div>
          <form className="flex flex-col justify-start gap-4">
            <PrimaryTextFieldLight
              label="Amount"
              color="primary"
              onChange={(e) => {
                const value = Number(e.target.value);
                setAmount(value === 0 ? undefined : value);
              }}
              type="number"
              name="amount"
              value={amount}
              className="w-full"
              variant="filled"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />

            <PrimaryTextFieldLight
              label="Service"
              color="primary"
              onChange={(e) => setService(e.target.value)}
              type="text"
              name="service"
              value={service}
              className="w-full"
              variant="filled"
            />

            <div className="flex flex-row justify-start gap-2">
              <button
                disabled={
                  !amount ||
                  !service ||
                  !authContext.user?.payment_connect_data?.payment_requirements_complete
                }
                onClick={(e) => {
                  if (!amount || !service) return;
                  e.preventDefault();
                  onSubmit(amount, service);
                }}
                className={`bg-white text-black font-bold py-2 px-3 rounded-full ${
                  !amount ||
                  !service ||
                  !authContext.user?.payment_connect_data?.payment_requirements_complete
                    ? 'opacity-50'
                    : ''
                }`}
              >
                <span className="text-[#5f4cfe] pt-4">
                  <FontAwesomeIcon color="black" size="2xl" className="px-1" icon={faStripe} />
                </span>
                Checkout
              </button>

              {booking && useBooking && (
                <button
                  onClick={(e) => {
                    if (!amount || !service) return;
                    e.preventDefault();
                    onMarkAsPaid(amount, service);
                  }}
                  disabled={!amount || !service}
                  className={`bg-white  text-black font-bold py-2 px-3 rounded-full ${
                    !amount || !service ? 'opacity-50' : ''
                  }`}
                >
                  Mark as Paid
                </button>
              )}
            </div>
            {!authContext.user?.payment_connect_data?.payment_requirements_complete && (
              <Link
                to={authContext.user?.payment_connect_data?.complete_requirements_link as string}
                target="_blank"
                rel="noopener noreferrer"
                className=" text-white text-xs"
              >
                Complete Stripe setup to accept payments
                <FontAwesomeIcon className="pl-2" icon={faArrowRight} size="sm" />
              </Link>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default SendPaymentModal;
