import { Booking, BookingStatus } from '@book-nestor-monorepo/shared-types';
import { faCalendarEdit, faCalendarXmark, faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/authContext';
import { useDrawer } from '../../../contexts/v2/rightDrawerContext';
import { cancelBooking } from '../../../libs/services/bookings';
import { formatClientTimeFromDate, formatMonthDay } from '../../../libs/utils/date.util';
import { cleanCalcomEventTitleToJustEventType } from '../../../libs/utils/string.util';
import { CreateBookingDrawer } from '../../../pages/v2/right-drawer-components/create-booking-drawer';
import { ConfirmDeleteButton } from '../../formLibrary/confirmDeleteButton';

export const BookingDetailCard = ({
  booking,
  refreshCard,
}: {
  booking: Booking;
  refreshCard: () => void;
}) => {
  const { user } = useContext(AuthContext);
  const { toggleDrawer, setDrawerContent } = useDrawer();

  const cancelBookingClick = async (booking: Booking) => {
    if (user?.id && booking.id) {
      await cancelBooking(user.id, booking.id);
      refreshCard();
    }
  };

  const rescheduleBookingClick = () => {
    if (!booking) return;

    setDrawerContent(
      <CreateBookingDrawer
        onSuccessfulBooking={async () => await refreshCard()}
        preLoadContactId={booking.contact?.id}
        preLoadServiceId={booking.event_type?.id}
        rescheduleFromBooking={booking}
      />,
      {},
    );
  };

  if (!booking) {
    return null;
  }

  const bookingText =
    booking.status === BookingStatus.CANCELED
      ? `${booking.contact?.name || booking.attendees[0]?.name} was booked on`
      : `${booking.contact?.name || booking.attendees[0]?.name} is booked on`;

  const cancelledText = booking.rescheduled ? 'Booking Rescheduled' : 'Booking Cancelled';

  return (
    <div>
      <div className="flex h-full w-full">
        {booking.status === BookingStatus.CANCELED ? (
          <div className="flex items-center justify-end w-full pb-2">
            <span className="text-[14px] font-avenir flex items-center pr-2 pt-[4px]">
              {cancelledText}
            </span>
            {booking.rescheduled ? (
              <FontAwesomeIcon icon={faSync} className="text-[14px]" />
            ) : (
              <FontAwesomeIcon icon={faCalendarXmark} className="text-[14px]" />
            )}
          </div>
        ) : (
          <div className="flex items-center justify-end w-full">
            <button
              onClick={rescheduleBookingClick}
              className="text-[12px] font-avenir flex items-center pr-2"
            >
              <div className="h-11 w-11 border bg-black-alt/10 flex items-center justify-center rounded-full">
                <FontAwesomeIcon icon={faCalendarEdit} className="text-[14px]" />
              </div>
            </button>
            <ConfirmDeleteButton text="Cancel?" onClick={() => cancelBookingClick(booking)} />
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start leading-10">
        <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
          {bookingText}{' '}
          <div className="rounded-full bg-[#BCC8C7] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              {formatMonthDay(new Date(booking.start_time))}
            </span>
          </div>
          <span className="text-[42px] font-avenir text-[#000000] text-left leading-10"> for </span>
          <div className="rounded-full bg-[#CED0D0] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10 ">
              {cleanCalcomEventTitleToJustEventType(booking.title || '')}
            </span>
          </div>
          <div className="rounded-full bg-[#B8E7F4] px-1 inline-flex ">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              @ {formatClientTimeFromDate(new Date(booking.start_time))}
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};
