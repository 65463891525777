import React from 'react';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const PrimaryTextFieldLight = styled(TextField)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: 'white',
  },
  '& .MuiInputBase-root': {
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    '& input': {
      fontSize: '14px',
      fontFamily: 'Inter',
      color: 'white',
    },
  },
  // Styles for Outlined variant
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '34px',
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderRadius: '34px',
      borderColor: 'white',
    },
    '&.Mui-error fieldset': {
      borderColor: '#ef4444',
    },
  },

  // Styles for Filled variant
  '& .MuiFilledInput-root': {
    '&::before': {
      borderBottom: '1px solid white',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid white',
    },
    '&::after': {
      borderBottom: '2px solid white',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '0px 28px',
  },
  '& .MuiFormHelperText-root': {
    color: 'white',
    '&.Mui-error': {
      color: '#ef4444',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
    marginLeft: '18px',
    marginRight: '8px',
    fontFamily: 'Inter',
    '&.Mui-focused': {
      color: 'white',
    },
    '&.Mui-error': {
      color: '#ef4444',
      '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
        color: '#ef4444',
      },
    },
    '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': {
      color: '#BDBDBD',
    },
    '&.MuiInputLabel-shrink': {
      color: 'white',
    },
  },
}));

export default PrimaryTextFieldLight;
