import {
  Booking,
  Contact,
  ContactBooking,
  ContactNote,
  ContactPayment,
  Review,
} from '@book-nestor-monorepo/shared-types';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NewBookingForm from '../components/app/newBookingForm';
import PowerToggle from '../components/common/HorizontalToggle/PowerToggle';
import SwipeUpModal from '../components/common/SwipeModal/SwipeModal';
import { AuthContext } from '../contexts/authContext';
import { useModal } from '../contexts/modalContext';
import {
  createContact,
  createStripeCustomer,
  getContact,
  getContactNotes,
  getPaymentHistory,
  patchContactBooking,
  updateContact,
} from '../libs/services/contacts';
import { getContactBookings, getReviewByContactId } from '../libs/services/reviews';

import { ReviewStatus } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import AppointmentModal from '../components/modals/appointment';
import EditContactModal from '../components/modals/editContact';
import EditReviewModal from '../components/modals/editReview';
import NewNoteModal from '../components/modals/newNote';
import NotesModal from '../components/modals/notes';
import PaymentHistoryModal from '../components/modals/paymentHistory';
import SendPaymentModal from '../components/modals/sendPayment';
import SendReviewModal from '../components/modals/sendReview';
import { getBookingById } from '../libs/services/bookings';
import { createCheckoutSession } from '../libs/services/connect';
import {
  formatDate,
  formatDateString,
  formatIsoDateString,
  formatYYYYMMDDDate,
} from '../libs/utils/date.util';
import { formatPhoneForTextMessage } from '../libs/utils/phone.util';
import { truncateText } from '../libs/utils/string.util';
import { ExperimentalAppLayout } from './layouts/experimental-app-layout';

const messageSvg = (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5.125C13 7.82031 10.3047 10 7 10C6.10938 10 5.28906 9.85938 4.53906 9.60156C4.28125 9.78906 3.8125 10.0703 3.27344 10.3047C2.71094 10.5625 2.03125 10.75 1.375 10.75C1.21094 10.75 1.07031 10.6797 1.02344 10.5391C0.953125 10.3984 1 10.2344 1.09375 10.1172C1.11719 10.1172 1.11719 10.0938 1.14062 10.0703C1.16406 10.0469 1.21094 10 1.25781 9.95312C1.35156 9.83594 1.46875 9.64844 1.60938 9.4375C1.84375 9.0625 2.05469 8.54688 2.10156 7.96094C1.39844 7.16406 1 6.20312 1 5.125C1 2.45312 3.67188 0.25 7 0.25C10.3047 0.25 13 2.45312 13 5.125Z"
      fill="white"
    />
  </svg>
);

const plusSvg = (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 1.375V4.75H9.875C10.2734 4.75 10.625 5.10156 10.625 5.5C10.625 5.92188 10.2734 6.25 9.875 6.25H6.5V9.625C6.5 10.0469 6.14844 10.375 5.75 10.375C5.32812 10.375 5 10.0469 5 9.625V6.25H1.625C1.20312 6.25 0.875 5.92188 0.875 5.5C0.875 5.10156 1.20312 4.75 1.625 4.75H5V1.375C5 0.976562 5.32812 0.625 5.75 0.625C6.14844 0.625 6.5 0.976562 6.5 1.375Z"
      fill="white"
    />
  </svg>
);

const creditSvg = (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.875 9.8125C2.875 9.59375 3.06641 9.375 3.3125 9.375H5.0625C5.28125 9.375 5.5 9.59375 5.5 9.8125C5.5 10.0586 5.28125 10.25 5.0625 10.25H3.3125C3.06641 10.25 2.875 10.0586 2.875 9.8125ZM6.375 9.8125C6.375 9.59375 6.56641 9.375 6.8125 9.375H10.3125C10.5312 9.375 10.75 9.59375 10.75 9.8125C10.75 10.0586 10.5312 10.25 10.3125 10.25H6.8125C6.56641 10.25 6.375 10.0586 6.375 9.8125ZM0.25 2.375C0.25 1.41797 1.01562 0.625 2 0.625H14.25C15.207 0.625 16 1.41797 16 2.375V11.125C16 12.1094 15.207 12.875 14.25 12.875H2C1.01562 12.875 0.25 12.1094 0.25 11.125V2.375ZM1.125 2.375V3.25H15.125V2.375C15.125 1.91016 14.7148 1.5 14.25 1.5H2C1.50781 1.5 1.125 1.91016 1.125 2.375ZM1.125 5.875H15.125V4.125H1.125V5.875ZM1.125 6.75V11.125C1.125 11.6172 1.50781 12 2 12H14.25C14.7148 12 15.125 11.6172 15.125 11.125V6.75H1.125Z"
      fill="white"
    />
  </svg>
);

const filterSvg = (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4375 6.5625C11.7422 6.5625 12 6.82031 12 7.125C12 7.45312 11.7422 7.6875 11.4375 7.6875H3.65625C3.42188 8.46094 2.69531 9 1.875 9C0.820312 9 0 8.17969 0 7.125C0 6.09375 0.820312 5.25 1.875 5.25C2.69531 5.25 3.42188 5.8125 3.65625 6.5625H11.4375ZM1.125 7.125C1.125 7.54688 1.45312 7.875 1.875 7.875C2.27344 7.875 2.625 7.54688 2.625 7.125C2.625 6.72656 2.27344 6.375 1.875 6.375C1.45312 6.375 1.125 6.72656 1.125 7.125ZM8.32031 1.3125C8.55469 0.5625 9.28125 0 10.125 0C11.1562 0 12 0.84375 12 1.875C12 2.92969 11.1562 3.75 10.125 3.75C9.28125 3.75 8.55469 3.21094 8.32031 2.4375H0.5625C0.234375 2.4375 0 2.20312 0 1.875C0 1.57031 0.234375 1.3125 0.5625 1.3125H8.32031ZM10.125 2.625C10.5234 2.625 10.875 2.29688 10.875 1.875C10.875 1.47656 10.5234 1.125 10.125 1.125C9.70312 1.125 9.375 1.47656 9.375 1.875C9.375 2.29688 9.70312 2.625 10.125 2.625Z"
      fill="black"
    />
  </svg>
);

const historySvg = (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.8125 2.125V7.125C2.8125 7.30078 2.94922 7.4375 3.125 7.4375H6.875V6.5C6.875 6.16797 7.14844 5.875 7.5 5.875H8.4375V2.125C8.4375 1.96875 8.28125 1.8125 8.125 1.8125H3.125C2.94922 1.8125 2.8125 1.96875 2.8125 2.125ZM1.875 2.125C1.875 1.44141 2.42188 0.875 3.125 0.875H8.125C8.80859 0.875 9.375 1.44141 9.375 2.125V5.99219C9.375 6.32422 9.23828 6.63672 9.00391 6.87109L7.85156 8.02344C7.61719 8.25781 7.30469 8.375 6.97266 8.375H3.125C2.42188 8.375 1.875 7.82812 1.875 7.125V2.125ZM6.25 9.78125C6.25 10.0547 6.03516 10.25 5.78125 10.25H2.65625C1.17188 10.25 0 9.07812 0 7.59375V3.21875C0 2.96484 0.195312 2.75 0.46875 2.75C0.722656 2.75 0.9375 2.96484 0.9375 3.21875V7.59375C0.9375 8.55078 1.69922 9.3125 2.65625 9.3125H5.78125C6.03516 9.3125 6.25 9.52734 6.25 9.78125Z"
      fill="white"
    />
  </svg>
);

export default function ContactDetail() {
  const { openModal, closeModal, modalContent } = useModal();
  const history = useNavigate();
  const { contactId } = useParams();
  const authContext = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const target = queryParams.get('target');
  const bookingId = queryParams.get('bookingId');
  const appointmentId = queryParams.get('appointmentId') || '';

  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<Contact>();
  const [contactBookings, setContactBookings] = useState<ContactBooking[] | undefined>(undefined);
  const [contactPayments, setContactPayments] = useState<ContactPayment[] | undefined>(undefined);
  const [contactNotes, setContactNotes] = useState<ContactNote[] | undefined>(undefined);
  const [upcomingAppointments, setUpcomingAppointments] = useState<ContactBooking[] | undefined>(
    undefined,
  );
  const [pastAppointments, setPastAppointments] = useState<ContactBooking[] | undefined>(undefined);
  const [contactReview, setContactReview] = useState<Review | undefined>(undefined);
  const [isSwipeOpen, setIsSwipeOpen] = useState(false);
  // const [modalColor, setModalColor] = useState('');
  const [booking, setBooking] = useState<Booking | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (contactId && authContext.user?.id) {
          const contact = await getContact(authContext.user?.id, contactId);
          setContact(contact);
          if (contact) {
            formik.setValues({
              name: contact.name,
              email: contact.email || '',
              phone_number: contact.phone_number || '',
            });

            try {
              const contactReview = await getReviewByContactId(authContext.user?.id, contactId);
              setContactReview(contactReview);
            } catch (error) {
              Sentry.captureException(error);
            }

            let contactNotesRes: ContactNote[] = [];
            try {
              contactNotesRes = await getContactNotes(authContext.user?.id, contactId);
              setContactNotes(contactNotesRes);
            } catch (error) {
              Sentry.captureException(error);
            }

            try {
              const contactPayments = await getPaymentHistory(authContext.user?.id, contactId);
              setContactPayments(contactPayments);
            } catch (error) {
              Sentry.captureException(error);
            }

            try {
              if (bookingId) {
                const booking = await getBookingById(authContext.user?.id, bookingId);
                setBooking(booking);
              }
            } catch (error) {
              Sentry.captureException(error);
            }

            try {
              const contactBookings = await getContactBookings(authContext.user?.id, contactId);
              const now = new Date();
              const upcoming = [];
              const past = [];

              for (const booking of contactBookings) {
                // add appointment notes from contactNotes because we don;t store appointment notes in the backend
                const note = contactNotesRes?.find(
                  (note) => note.appointment_id === booking.booking_id,
                );
                if (note) {
                  booking.note = note;
                }

                const bookingDate = new Date(booking.booking_start_time);
                if (bookingDate > now) {
                  upcoming.push(booking);
                } else {
                  past.push(booking);
                }
              }

              setUpcomingAppointments(upcoming);
              past.sort(
                (a, b) =>
                  new Date(b.booking_start_time).getTime() -
                  new Date(a.booking_start_time).getTime(),
              );
              setPastAppointments(past);

              setContactBookings(contactBookings);
            } catch (error) {
              Sentry.captureException(error);
            }
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        Sentry.captureException(error);
      }
    };
    if (contactId !== 'new') {
      fetchData();
    } else {
      setIsLoading(false);
      handleModal('editContact');
    }
  }, [contactId]);

  useEffect(() => {
    if (target && target === 'newPayment' && booking) {
      handleModal(target);
    } else if (target && target === 'newNote' && contact) {
      handleModal(target);
    }
  }, [target, booking, contact]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
    },
    onSubmit: async (values) => {
      if (contact) {
        await updateContact(authContext.user?.id as string, { ...contact, ...values });
      } else {
        await createContact(authContext.user?.id as string, { ...values });
      }

      history('/contacts');
    },
  });

  const renderStars = (rating: number) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <button
          type="button"
          key={i}
          className={`text-[15px] focus:outline-none cursor-auto ${
            i <= rating ? 'text-yellow-400' : 'text-gray-400'
          }`}
        >
          &#9733;
        </button>,
      );
    }
    return stars;
  };

  const openBooking = () => {
    openModal(
      <SwipeUpModal onClose={closeModal}>
        <NewBookingForm
          onClose={closeModal}
          email={contact?.email}
          contactId={contact?.id}
          phone={contact?.phone_number}
        />
      </SwipeUpModal>,
    );
  };

  const closing = () => {
    setIsSwipeOpen(false);
    closeModal();
  };

  const renderContent = (type: string, options?: { appointmentNote?: ContactNote }) => {
    const isFullModal = true;
    let content = null;
    let modalColor = 'bg-gray-modals';
    switch (type) {
      case 'notes':
        content = (
          <NotesModal
            title={'Notes'}
            onClose={async () => {
              setIsSwipeOpen(false);
              closeModal();
              const notes = await getContactNotes(
                authContext.user?.id as string,
                contact?.id as string,
              );
              setContactNotes(notes);
            }}
            contactNotes={contactNotes || []}
            contactId={contact?.id as string}
          />
        );
        break;
      case 'paymentHistory':
        modalColor = 'bg-black-modal';
        content = (
          <PaymentHistoryModal
            title={'Payment History'}
            contactPayments={contactPayments || []}
            onClose={() => {
              setIsSwipeOpen(false);
              closeModal();
            }}
            onCreateStripeCustomer={async () => {
              const updatedContact = await createStripeCustomer(
                authContext.user?.id as string,
                contact?.id as string,
              );
              setContact(updatedContact);
              return updatedContact;
            }}
            contact={contact}
          />
        );
        break;
      case 'newPayment':
        modalColor = 'bg-black-modal';
        content = (
          <SendPaymentModal
            title={'Collect Payment'}
            onClose={() => {
              history(`/contacts/${contact?.id}`);
              setBooking(undefined);
              setIsSwipeOpen(false);
              closeModal();
            }}
            contact={contact as Contact}
            booking={booking}
            onMarkAsPaid={async (amount: number, service: string) => {
              try {
                if (bookingId && contact?.email && !isNaN(amount)) {
                  await patchContactBooking(
                    authContext.user?.id as string,
                    contact?.email as string,
                    bookingId,
                    {
                      client_has_paid: true,
                      booking_paid_date: formatYYYYMMDDDate(new Date()),
                      booking_paid_amount: Number(amount * 100),
                    },
                  );
                }
                history(`/contacts/${contact?.id}`);
                setBooking(undefined);
                setIsSwipeOpen(false);
                closeModal();
              } catch (e) {
                Sentry.captureException(e);
              }
            }}
            onSubmit={async (amount: number, service: string) => {
              try {
                const url = await createCheckoutSession({
                  connected_account_id: authContext.user?.payment_connect_data
                    ?.connected_account_id as string,
                  amount: amount * 100,
                  product_name: service,
                  customer_email: contact?.email as string,
                  customer_id: contact?.payment_customer_id,
                  ...(booking?.id && { booking_id: booking.id }),
                });
                window.location.href = url.url;
              } catch (e) {
                Sentry.captureException(e);
              }
            }}
          />
        );
        break;
      case 'editReview':
        modalColor = 'bg-black-modal';
        content = (
          <EditReviewModal
            title={'Edit Review Message'}
            onClose={() => {
              setIsSwipeOpen(false);
              closeModal();
            }}
          />
        );
        break;
      case 'sendReview':
        modalColor = 'bg-black-modal';
        content = (
          <SendReviewModal
            title={'Review'}
            onClose={async () => {
              setIsSwipeOpen(false);
              closeModal();
              try {
                const review = await getReviewByContactId(
                  authContext.user?.id as string,
                  contact?.id as string,
                );
                setContactReview(review);
              } catch (error) {
                Sentry.captureException(error);
              }
            }}
            contact={contact as Contact}
          />
        );
        break;
      case 'editContact':
        content = (
          <EditContactModal
            title={'Client Details'}
            onClose={async (contactId?: string) => {
              setIsSwipeOpen(false);
              closeModal();
              if (contactId === 'new') {
                history('/contacts');
                return;
              }
              if (contactId) {
                history(`/contacts/${contactId}`);
                return;
              }
              const updatedContact = await getContact(
                authContext.user?.id as string,
                contact?.id as string,
              );
              setContact(updatedContact);
            }}
            contact={contact as Contact}
          />
        );
        break;
      case 'appointment':
        content = (
          <AppointmentModal
            onClose={closing}
            contactName={contact?.name || ''}
            appointmentNote={options?.appointmentNote}
          />
        );
        break;
      case 'newNote':
        content = (
          <NewNoteModal
            onClose={async () => {
              setIsSwipeOpen(false);
              closeModal();
              const notes = await getContactNotes(
                authContext.user?.id as string,
                contact?.id as string,
              );
              setContactNotes(notes);
              const contactBookings = await getContactBookings(
                authContext.user?.id as string,
                contactId as string,
              );
              const now = new Date();
              const upcoming = [];
              const past = [];

              for (const booking of contactBookings) {
                // add appointment notes from contactNotes because we don;t store appointment notes in the backend
                const note = notes?.find((note) => note.appointment_id === booking.booking_id);
                if (note) {
                  booking.note = note;
                }

                const bookingDate = new Date(booking.booking_start_time);
                if (bookingDate > now) {
                  upcoming.push(booking);
                } else {
                  past.push(booking);
                }
              }

              setUpcomingAppointments(upcoming);
              past.sort(
                (a, b) =>
                  new Date(b.booking_start_time).getTime() -
                  new Date(a.booking_start_time).getTime(),
              );
              setPastAppointments(past);

              setContactBookings(contactBookings);
              // remove the query params - appointmentId and target
              history(`/contacts/${contact?.id}`);
            }}
            contactId={contact?.id as string}
            appointmentId={appointmentId}
          />
        );
        break;
      default:
        content = <div />;
        break;
    }

    if (isFullModal && !modalContent) {
      openModal(
        <SwipeUpModal
          variant={true}
          useContainer={isFullModal}
          bgColor={modalColor}
          onClose={() => closeModal()}
        >
          {content}
        </SwipeUpModal>,
      );
      return <div />;
    } else {
      return (
        <SwipeUpModal
          variant={true}
          useContainer={isFullModal}
          bgColor={modalColor}
          onClose={() => setIsSwipeOpen(false)}
        >
          {content}
        </SwipeUpModal>
      );
    }
  };

  const handleModal = (section: string, options?: { appointmentNote?: ContactNote }) => {
    setIsSwipeOpen(true);
    renderContent(section, options);
  };

  return (
    <ExperimentalAppLayout insightCardsStyles="hidden md:flex">
      <div
        className={`flex flex-col justify-center pt-16 md:pt-24 px-2 w-full h-full ${isSwipeOpen ? 'overflow-y-hidden' : 'overflow-y-auto'}`}
      >
        <div className="flex flex-col mb-[57px]">
          {(contact?.name || contact?.last_name) && (
            <h2
              className={`font-avenir text-black-text ${
                (contact?.name?.length || 0) + (contact?.last_name?.length || 0) + 1 <= 8
                  ? 'text-[67.778px] leading-[72px]'
                  : (contact?.name?.length || 0) + (contact?.last_name?.length || 0) + 1 <= 20
                    ? 'text-[50px] leading-[60px]'
                    : 'text-[40px] leading-[48px]'
              } mb-4`}
            >
              {truncateText(`${contact?.name || ''} ${contact?.last_name || ''}`.trim(), 30)}
            </h2>
          )}

          <div className="flex flex-row items-center">
            <div className="flex gap-2.5 mr-6">
              <span
                className={`flex items-center justify-center rounded-full w-12 h-12 bg-black-solid/80 ${!contact?.phone_number && 'pointer-events-none opacity-50'}`}
                onClick={() =>
                  history(
                    `/text-conversations/${formatPhoneForTextMessage(contact?.phone_number || '')}`,
                  )
                }
              >
                {messageSvg}
              </span>
              <span
                className="flex items-center justify-center rounded-full w-12 h-12 bg-black-solid/80"
                onClick={openBooking}
              >
                {plusSvg}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="font-inter text-[9.889px] capitalize leading-4 text-gray-appointment">
                Next Appointment
              </p>
              <div className="flex">
                <p className="font-avenir text-black-text text-[25.888px] leading-8 font-semibold">
                  {upcomingAppointments?.[0]?.booking_start_time
                    ? formatIsoDateString(upcomingAppointments?.[0]?.booking_start_time)
                    : 'No upcoming appointments'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col rounded-t-3xl bg-white w-full py-4 px-2 flex-grow relative ${
            isSwipeOpen ? 'overflow-y-hidden' : 'overflow-y-auto'
          }`}
        >
          <div className="flex justify-start items-center mb-2 ">
            <p className="font-inter text-black-text text-base leading-4 p-2.5 font-medium">
              Notes
            </p>
            <button
              className="cursor-pointer rounded-3xl bg-gray-light h-10 w-24 font-avenir text-black-solid font-medium ml-auto"
              onClick={() => handleModal('notes')}
            >
              See All
            </button>
            <button
              className="cursor-pointer flex items-center justify-center rounded-full w-10 h-10 bg-black-solid ml-1.5"
              onClick={() => handleModal('newNote')}
            >
              {plusSvg}
            </button>
          </div>
          <div className="flex items-center justify-start pl-6 py-3 pr-3 rounded-full bg-gray-temporal w-full h-[66px] mb-2">
            <p className="font-inter text-white text-base leading-4 p-2.5 font-medium">Payments</p>
            <button
              className="cursor-pointer rounded-3xl bg-black-solid h-[42px] w-[153px] font-avenir text-white font-medium ml-auto"
              onClick={() => handleModal('paymentHistory')}
            >
              Payment History
            </button>
            <button
              className="cursor-pointer flex items-center justify-center rounded-full w-[42px] h-[42px] bg-black-solid ml-1.5"
              onClick={() => handleModal('newPayment')}
            >
              {creditSvg}
            </button>
          </div>
          <div className="flex flex-col items-center justify-start pl-6 pt-3 pr-3 pb-7 rounded-3xl bg-gray-light w-full mb-2">
            <div className="flex flex-row items-center justify-between w-full mb-[30px]">
              <p className="font-inter text-black-text text-base leading-4 py-2.5 font-medium">
                Client Details
              </p>
              <button
                className="cursor-pointer flex items-center justify-center rounded-full w-[42px] h-[42px] bg-transparent border border-black-solid ml-1.5"
                onClick={() => handleModal('editContact')}
              >
                {filterSvg}
              </button>
            </div>
            <div className="flex justify-start items-start w-full">
              <div className="mr-[22px]">
                <p className="uppercase text-[9.889px] text-black-text font-inter leading-4 font-bold">
                  ADDRESS
                </p>
                {contact?.street_address || contact?.city || contact?.state || contact?.zip ? (
                  <p className="text-[9.889px] text-black-text font-inter leading-4 font-normal">
                    {contact?.street_address}
                  </p>
                ) : null}
              </div>
              <div>
                <p className="uppercase text-[9.889px] text-black-text font-inter leading-4 font-bold">
                  COMMUNICATION
                </p>
                <p className="text-[9.889px] text-black-text font-inter leading-4 font-normal">
                  PHONE - {contact?.phone_number}
                </p>
                <p className="text-[9.889px] text-black-text font-inter leading-4 font-normal">
                  EMAIL - {contact?.email}
                </p>
                {/* <p className="text-[9.889px] text-green-card font-inter leading-4 font-normal mt-4">
                  opted-in
                </p> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start pl-6 pt-3 pr-3 pb-7 rounded-3xl bg-black w-full mb-2">
            <div className="flex flex-row items-center justify-between w-full mb-[13px]">
              <p className="font-inter text-gray-light text-base leading-4 py-2.5 font-medium">
                Reviews
              </p>
              {contactReview?.status === ReviewStatus.PENDING && (
                <p className="text-[9.889px] text-gray-light font-inter leading-4 font-normal">
                  Review requested on{' '}
                  {contactReview?.created_at && formatDate(new Date(contactReview?.created_at))}
                </p>
              )}
            </div>
            <div className="flex justify-start items-start w-full mb-[22px]">
              <p className="text-[9.889px] text-gray-light font-inter leading-4 font-normal max-w-[200px] mr-5">
                {contactReview && contactReview?.status !== ReviewStatus.PENDING ? (
                  contactReview?.review_text
                ) : (
                  <>
                    No reviews from this client yet.
                    <br />
                    Click to request a review and get their thoughts.
                  </>
                )}
              </p>
              {contactReview && contactReview?.status !== ReviewStatus.PENDING ? (
                <div>{renderStars(contactReview?.rating || 5)}</div>
              ) : null}
            </div>
            <div className="flex justify-start items-center w-full">
              {contactReview && contactReview?.status !== ReviewStatus.PENDING ? (
                // FIXME: add an action
                <PowerToggle title="Active" />
              ) : (
                <PowerToggle title="N/A" />
              )}

              {!contactReview || contactReview?.status === ReviewStatus.PENDING ? (
                <>
                  <span className="flex items-center px-2">
                    <span className="border-l border-white h-6"></span>
                  </span>
                  <button
                    disabled={contactReview && contactReview?.status === ReviewStatus.PENDING}
                    className="font-avenir text-sm capitalize text-white h-10 rounded-full py-2 px-[18px] border border-white"
                    onClick={() => handleModal('sendReview')}
                  >
                    {contactReview?.status === ReviewStatus.PENDING
                      ? 'Review Pending'
                      : 'Request Review'}
                  </button>
                </>
              ) : null}
            </div>
          </div>
          {/* Appointment History START */}
          <div className="flex flex-col items-center justify-start pl-6 pt-3 pr-3 pb-7 rounded-3xl bg-black w-full">
            <div className="flex flex-row w-full mb-7">
              <p className="font-inter text-gray-light text-base leading-4 py-2.5 font-medium">
                Appointment History
              </p>
            </div>

            {pastAppointments && pastAppointments.length > 0 && (
              <div className="flex justify-between items-start w-full border-b border-white pb-7">
                <div className="flex flex-col pt-3">
                  <h3 className="text-white font-avenir text-base leading-4 font-bold mb-2.5">
                    {formatDateString(pastAppointments[0].booking_start_time)}
                  </h3>
                  {pastAppointments[0].internal_event_type_price && (
                    <p className="font-avenir text-[9.89px] text-white leading-4 font-medium">
                      ${pastAppointments[0].internal_event_type_price?.toFixed(2)}
                    </p>
                  )}
                  <p className="font-avenir text-[9.89px] text-white leading-4">
                    {/* {pastAppointments[0].services.join(', ')} */}
                    {pastAppointments[0].internal_event_type_name}
                  </p>
                </div>
                {pastAppointments[0].note && (
                  <div
                    className="flex items-center justify-center rounded-full bg-transparent border border-white w-10 h-10"
                    onClick={() =>
                      handleModal('appointment', { appointmentNote: pastAppointments[0].note })
                    }
                  >
                    {historySvg}
                  </div>
                )}
              </div>
            )}
            {pastAppointments && pastAppointments.length === 0 && (
              <div className="flex justify-between items-start w-full border-b border-white pb-7">
                <div className="flex flex-col pt-3">
                  <h3 className="text-white font-avenir text-base leading-4 font-bold mb-2.5">
                    No Appointment History
                  </h3>
                </div>
              </div>
            )}
          </div>
          {/* Appointment History END */}
        </div>
      </div>
    </ExperimentalAppLayout>
  );
}
