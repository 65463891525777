import { faSpinner, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface ConfirmDeleteButtonProps {
  text?: string;
  onClick: () => Promise<void>;
  shadow?: boolean;
}

export function ConfirmDeleteButton({
  text = 'Delete?',
  onClick,
  shadow = false,
}: ConfirmDeleteButtonProps) {
  const [confirmState, setConfirmState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (confirmState) {
      setIsLoading(true);
      await onClick();
      setIsLoading(false);
      setConfirmState(false);
    } else {
      setConfirmState(true);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex font-avenir items-center h-11 text-[12px] rounded-full w-auto leading-4 ${
        confirmState ? 'bg-[#DEDEDE] pl-4' : ''
      } ${shadow ? 'shadow-gray-500 shadow-lg' : ''}`}
    >
      {confirmState && text}
      {isLoading && (
        <div className="h-11 w-11 bg-[#DEDEDE] flex items-center justify-center rounded-full ml-2">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </div>
      )}
      {!isLoading && (
        <div
          className={`h-11 w-11 bg-[#DEDEDE] flex items-center justify-center rounded-full ${
            confirmState ? 'ml-2' : ''
          }`}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
    </button>
  );
}
