import React, { createContext, useContext, useState, useEffect } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { AuthContext } from './authContext';
import { environment } from '../environments/environment'; // Adjust this import as needed
import * as Sentry from '@sentry/react';

interface StripePaymentContextValue {
  connectedAccountId: string | undefined;
  stripe: Stripe | null;
  isLoading: boolean;
  error: Error | null;
}

const StripePaymentContext = createContext<StripePaymentContextValue>({
  connectedAccountId: undefined,
  stripe: null,
  isLoading: true,
  error: null,
});

export const StripePaymentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [connectedAccountId, setConnectedAccountId] = useState<string | undefined>();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (user && user.payment_connect_data && user.payment_connect_data.connected_account_id) {
      setConnectedAccountId(user.payment_connect_data.connected_account_id);
    }
  }, [user]);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeInstance = await loadStripe(environment.stripe.publicKey, {
          apiVersion: environment.stripe.apiVersion,
        });
        setStripe(stripeInstance);
      } catch (err) {
        Sentry.captureException(err);
        setError(err instanceof Error ? err : new Error('Failed to load Stripe'));
      } finally {
        setIsLoading(false);
      }
    };

    initializeStripe();
  }, []);

  return (
    <StripePaymentContext.Provider value={{ connectedAccountId, stripe, isLoading, error }}>
      {children}
    </StripePaymentContext.Provider>
  );
};

export const useStripePayment = () => {
  const context = useContext(StripePaymentContext);
  if (context === undefined) {
    throw new Error('useStripePayment must be used within a StripePaymentProvider');
  }
  return context;
};
