import { ContactNote } from '@book-nestor-monorepo/shared-types';

interface AppointmentType {
  onClose: () => void;
  contactName: string;
  appointmentNote?: ContactNote;
}

const AppointmentModal = ({ onClose, contactName, appointmentNote }: AppointmentType) => {
  return (
    <div className="flex flex-col w-full">
      <div className={`flex flex-row items-center justify-between mb-24`}>
        <button
          className="flex items-center justify-center right-1 top-2 w-10 h-10 cursor-pointer z-20 rounded-full border border-black-solid"
          onClick={onClose}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.03125 8.00391C9.27734 8.27734 9.27734 8.6875 9.03125 8.93359C8.75781 9.20703 8.34766 9.20703 8.10156 8.93359L4.875 5.67969L1.62109 8.93359C1.34766 9.20703 0.9375 9.20703 0.691406 8.93359C0.417969 8.6875 0.417969 8.27734 0.691406 8.00391L3.94531 4.75L0.691406 1.49609C0.417969 1.22266 0.417969 0.8125 0.691406 0.566406C0.9375 0.292969 1.34766 0.292969 1.59375 0.566406L4.875 3.84766L8.12891 0.59375C8.375 0.320312 8.78516 0.320312 9.03125 0.59375C9.30469 0.839844 9.30469 1.25 9.03125 1.52344L5.77734 4.75L9.03125 8.00391Z"
              fill="black"
            />
          </svg>
        </button>
        <div className="flex gap-2">
          <button className="flex items-center justify-center right-1 top-2 w-10 h-10 cursor-pointer z-20 rounded-full border border-black-solid">
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.75C0 0.929688 0.65625 0.25 1.5 0.25H10.5C11.3203 0.25 12 0.929688 12 1.75V9.25C12 10.0938 11.3203 10.75 10.5 10.75H1.5C0.65625 10.75 0 10.0938 0 9.25V1.75ZM7.57031 4.25781C7.47656 4.09375 7.28906 4 7.125 4C6.9375 4 6.75 4.09375 6.65625 4.25781L4.61719 7.25781L3.98438 6.46094C3.89062 6.34375 3.72656 6.25 3.5625 6.25C3.375 6.25 3.21094 6.34375 3.11719 6.46094L1.61719 8.33594C1.47656 8.52344 1.45312 8.75781 1.54688 8.94531C1.64062 9.13281 1.82812 9.25 2.0625 9.25H4.3125H5.0625H9.9375C10.125 9.25 10.3359 9.15625 10.4297 8.96875C10.5234 8.78125 10.5 8.54688 10.3828 8.38281L7.57031 4.25781ZM2.625 4C3.23438 4 3.75 3.50781 3.75 2.875C3.75 2.26562 3.23438 1.75 2.625 1.75C1.99219 1.75 1.5 2.26562 1.5 2.875C1.5 3.50781 1.99219 4 2.625 4Z"
                fill="black"
              />
            </svg>
          </button>
          <button className="flex items-center justify-center p-4 h-10 cursor-pointer z-20 rounded-full text-white bg-black-solid text-xs font-avenir">
            Add
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-between pt-1.5 px-2 pb-9 mb-11 max-w-[192px]">
        <p className="text-base leading-6 text-black-solid font-bold font-avenir">
          {contactName} - Appointment Note
        </p>
      </div>
      <div className="flex flex-row justify-start pb-2">
        <p className="">
          <strong>{appointmentNote?.title}</strong>
          <br />
          {appointmentNote?.body}
          <br />
        </p>
      </div>
    </div>
  );
};

export default AppointmentModal;
