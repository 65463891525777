import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEllipsisVertical,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import * as dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import { CalendarDay, CalendarError, CalendarView } from './calendar-container';
import CalendarErrorView from './calendar-error-view';
import CalendarWeekEventsView from './calendar-week-events-view';

interface CalendarWeekViewProps {
  setCurrentView: (view: CalendarView) => void;
  onEventClick: (bookingId: string) => void;
  currentDateRange: { start: string; end: string };
  calendarDays: CalendarDay[];
  handleBackwardWeek: () => void;
  handleForwardWeek: () => void;
  handleDayClick: (day: CalendarDay) => void;
  selectedDay: CalendarDay | null;
  currentView: CalendarView;
  handleTodayClick: () => void;
  error: CalendarError | null;
  handleAddEventClick: () => void;
}

export const CalendarWeekView = ({
  setCurrentView,
  onEventClick,
  currentDateRange,
  calendarDays,
  handleBackwardWeek,
  handleForwardWeek,
  handleDayClick,
  selectedDay,
  currentView,
  handleTodayClick,
  error,
  handleAddEventClick,
}: CalendarWeekViewProps) => {
  const { isMobile } = useAppExperience();
  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef<HTMLDivElement>(null);
  const containerOffset = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!container.current || !containerNav.current || !containerOffset.current) {
        return;
      }

      const currentMinute = new Date().getHours() * 60;

      // Calculate the height of one hour (48 rows total for 24 hours)
      const hourHeight = container.current.scrollHeight / 48; // Changed to 48 for half-hour divisions

      // Calculate scroll position based on current time
      const scrollPosition = (currentMinute / 30) * hourHeight; // Adjusted for half-hour divisions

      // Adjust for header heights
      const navHeight = containerNav.current.offsetHeight;
      const offsetHeight = containerOffset.current.offsetHeight;

      if (isMobile) {
        requestAnimationFrame(() => {
          if (container.current) {
            // On mobile, scroll to the current time minus one hour for better visibility
            const targetHour = Math.max(0, (currentMinute - 60) / 30) * hourHeight;
            container.current.scrollTop = targetHour;
          }
        });
      } else {
        // Desktop view: account for both nav and offset heights
        container.current.scrollTop = scrollPosition - (navHeight + offsetHeight);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [calendarDays, isMobile]); // Added isMobile to dependencies

  if (error) {
    return <CalendarErrorView error={error} />;
  }

  return (
    <div className="bg-gray-50 lg:h-0 lg:min-h-[768px]  font-inter rounded-2xl pb-6">
      <div className="flex h-full flex-col font-inter ">
        {/* header */}
        <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4 font-avenir">
          <h1 className="text-base font-semibold text-gray-900">
            <time dateTime={currentDateRange.start}>
              {dayjs(currentDateRange.start).format('MMMM YYYY')}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-full bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-full border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                onClick={handleBackwardWeek}
              >
                <span className="sr-only">Previous week</span>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                type="button"
                className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block border border-gray-300"
                onClick={handleTodayClick}
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-full border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={handleForwardWeek}
              >
                <span className="sr-only">Next week</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <Menu as="div" className="relative">
                <MenuButton
                  type="button"
                  className="flex items-center gap-x-2 rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Week view
                  <FontAwesomeIcon icon={faChevronDown} className="-mr-1 size-5 text-gray-400" />
                </MenuButton>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      <span
                        className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'week' ? 'font-bold' : ''}`}
                      >
                        Week view
                      </span>
                    </MenuItem>
                    <MenuItem>
                      <span
                        onClick={() => setCurrentView('month')}
                        className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'month' ? 'font-bold' : ''}`}
                      >
                        Month view
                      </span>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                type="button"
                className="ml-6 rounded-full bg-green-600 px-3 py-2 text-[12px] text-white shadow-sm  items-center flex justify-center cursor-pointer"
                onClick={handleAddEventClick}
              >
                <FontAwesomeIcon icon={faPlus} className="font-[10px]" />
                <span className="ml-2">Add event</span>
              </button>
            </div>
            <button
              className="ml-6 rounded-full bg-green-600 px-3 py-2 h-9 w-9 text-[12px] text-white shadow-sm  items-center flex justify-center cursor-pointer md:hidden"
              onClick={handleAddEventClick}
            >
              <FontAwesomeIcon icon={faPlus} className="font-[10px]" />
            </button>
            <Menu as="div" className="relative ml-6 md:hidden">
              <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <FontAwesomeIcon icon={faEllipsisVertical} className="size-5" />
              </MenuButton>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {/* <div className="py-1">
                  <MenuItem>
                    <span
                      onClick={handleAddEventClick}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
                    >
                      Create event
                    </span>
                  </MenuItem>
                </div> */}
                <div className="py-1">
                  <MenuItem>
                    <span
                      onClick={handleTodayClick}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Today
                    </span>
                  </MenuItem>
                </div>
                <div className="py-1">
                  {/* <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      Day view
                    </a>
                  </MenuItem> */}
                  <MenuItem>
                    <span
                      onClick={() => setCurrentView('week')}
                      className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'week' ? 'font-bold' : ''}`}
                    >
                      Week view
                    </span>
                  </MenuItem>
                  <MenuItem>
                    <span
                      onClick={() => setCurrentView('month')}
                      className={`block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer ${currentView === 'month' ? 'font-bold' : ''}`}
                    >
                      Month view
                    </span>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </header>
        <div
          ref={container}
          className="isolate flex flex-auto flex-col overflow-auto bg-white h-[calc(100vh-200px)]"
          style={{
            WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
            overscrollBehavior: 'contain', // Prevent parent scroll while scrolling this element
          }}
        >
          <div
            style={{ width: '165%' }}
            className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
          >
            {/* week dates bar */}
            <div
              ref={containerNav}
              className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black/5 sm:pr-8"
            >
              <div className="grid grid-cols-7 text-sm/6 text-gray-500 sm:hidden">
                {calendarDays.map((day) => (
                  <button
                    key={day.date}
                    type="button"
                    className="flex flex-col items-center pb-3 pt-2 cursor-pointer"
                    onClick={() => handleDayClick(day)}
                  >
                    {day.dayOfWeekName.slice(0, 1)}{' '}
                    <span
                      className={`flex size-8 items-center justify-center  font-semibold font-avenir ${
                        day.date === selectedDay?.date
                          ? 'text-white rounded-full bg-green-600'
                          : day.isToday
                            ? 'text-white rounded-full  bg-green-600/50'
                            : 'text-gray-900'
                      }`}
                    >
                      {day.date.split('-').pop()?.replace(/^0/, '')}
                    </span>
                  </button>
                ))}
              </div>

              <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm/6 text-gray-500 sm:grid">
                <div className="col-end-1 w-14" />
                {calendarDays.map((day) => (
                  <div
                    key={day.date}
                    className="flex items-center justify-center py-3 cursor-pointer"
                    onClick={() => handleDayClick(day)}
                  >
                    <span className="flex items-baseline">
                      {day.dayOfWeekName.slice(0, 3)}{' '}
                      <span
                        className={`flex size-8 items-center justify-center ml-1.5  font-semibold ${
                          day.date === selectedDay?.date
                            ? 'text-white rounded-full bg-green-600'
                            : day.isToday
                              ? 'text-white rounded-full  bg-green-600/50'
                              : 'text-gray-900'
                        }`}
                      >
                        {day.date.split('-').pop()?.replace(/^0/, '')}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                  style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                >
                  <div ref={containerOffset} className="row-end-1 h-7"></div>
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      12AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      1AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      2AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      3AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      4AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      5AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      6AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      7AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      8AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      9AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      10AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      11AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      12PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      1PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      2PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      3PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      4PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      5PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      6PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      7PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      8PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      9PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      10PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                      11PM
                    </div>
                  </div>
                  <div />
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8" />
                </div>
                <CalendarWeekEventsView
                  days={calendarDays}
                  onEventClick={onEventClick}
                  selectedDay={selectedDay}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
