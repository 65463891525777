export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const formatPercentage = (value: number): string => {
  if (!value) {
    return '0%';
  }
  const percentage = value * 100;
  return `${percentage.toFixed(2)}%`;
};

export const formatStripeAmount = (value: number): string => {
  const dollars = value / 100;

  // Format the amount with 2 decimal places
  const formattedAmount = dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedAmount;
};
