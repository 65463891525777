import { UserSlug, ValidateSlugResponse } from '@book-nestor-monorepo/shared-types';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { fetchAuthSession } from 'aws-amplify/auth';

export const getUserSlug = async (id: string): Promise<UserSlug | undefined> => {
  try {
    const session = await fetchAuthSession();
    if (!session) return;

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.get(`${apiBaseUrl}/user-slug/${id}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 422) {
      return;
    }
    throw new Error('Failed to fetch user slug');
  }
};

export const updateUserSlug = async (id: string, slug: string): Promise<UserSlug> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.put(
      `${apiBaseUrl}/user-slug/${id}`,
      { slug },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to update user slug');
  }
};

export const validateSlug = async (slug: string): Promise<ValidateSlugResponse> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const response = await axios.post(
      `${apiBaseUrl}/user-slug/validate`,
      { slug },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to validating user slug');
  }
};
