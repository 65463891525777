import { Review } from '@book-nestor-monorepo/shared-types';
import { faStar, faStarHalfStroke } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReviewsTab = ({ reviews }: { reviews: Review[] }) => {
  const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStar} color="#FFC107" />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStarHalfStroke} color="#FFC107" />);
      } else {
        stars.push(<FontAwesomeIcon className="w-4" icon={faStarRegular} color="#FFC107" />);
      }
    }

    return stars;
  };
  return (
    <div className="py-[40px]">
      <h4 className="text-2xl text-[#211D1D] ml-[24px]">Reviews</h4>
      <div className="mt-[31px] pl-[24px] flex w-full items-center overflow-y-hidden overflow-x-auto hide-scrollbar">
        {reviews.length === 0 && (
          <div className="w-[216px] min-w-[216px] h-[184px] rounded-[8px] bg-[#333333] px-[15px] py-[26px] text-[#D7D8D3] first:ml-0 last:mr-4 ml-4 flex items-center justify-center">
            <p className="text-[16px]">No reviews yet</p>
          </div>
        )}
        {reviews.map(({ rating, contact_name, review_text }) => (
          <div className="w-[216px] min-w-[216px] h-[184px] rounded-[8px] bg-[#333333] px-[15px] py-[26px] text-[#D7D8D3] first:ml-0 last:mr-4 ml-4 overflow-y-scroll">
            {renderStars(rating as number)}
            <h4 className="font-semibold mt-[12px]">{contact_name}</h4>
            <p className="text-[10px]">{review_text}</p>
          </div>
        ))}
      </div>
      {/* <button className="ml-[24px] mt-[40px] capitalize py-3 px-[35px] bg-black-alt text-white rounded-full">
        Leave a Review
      </button> */}
    </div>
  );
};

export default ReviewsTab;
