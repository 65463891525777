import { Booking } from '@book-nestor-monorepo/shared-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfirmDeleteButton } from '../../formLibrary/confirmDeleteButton';
import { formatClientTimeFromDate, formatMonthDay } from '../../../libs/utils/date.util';
import { cleanCalcomEventTitleToJustEventType } from '../../../libs/utils/string.util';
import { cancelBooking } from '../../../libs/services/bookings';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';

export const BookingDetailCard = ({ booking }: { booking: Booking }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { isDesktop, isTablet } = useAppExperience();
  const [searchParams, setSearchParams] = useSearchParams();

  const cancelBookingClick = async (booking: Booking) => {
    if (user?.id && booking.id) {
      await cancelBooking(user.id, booking.id);
      navigate('/');
    }
  };

  const setRescheduleUidInUrl = (rescheduleUid: string) => {
    setSearchParams((prev) => {
      prev.set('rescheduleUid', rescheduleUid);
      return prev;
    });
  };

  const rescheduleBookingClick = () => {
    if (isDesktop) {
      setRescheduleUidInUrl(booking.id);
    } else if (isTablet) {
      setRescheduleUidInUrl(booking.id);
    } else {
      navigate(`/booking-reschedule/${booking.id}`);
    }
  };

  if (!booking) {
    return null;
  }

  return (
    <div>
      <div className="flex h-full w-full">
        <div className="flex items-center justify-end w-full">
          <button
            onClick={rescheduleBookingClick}
            className="text-[12px] font-avenir flex items-center"
          >
            <div className="h-11 w-11 border bg-black-alt/10 flex items-center justify-center rounded-full">
              <FontAwesomeIcon icon={faCalendarPlus} />
            </div>
          </button>
          <ConfirmDeleteButton text="Cancel?" onClick={() => cancelBookingClick(booking)} />
        </div>
      </div>
      <div className="flex flex-col items-start justify-start leading-10">
        <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
          {booking.contact.name} is booked on{' '}
          <div className="rounded-full bg-[#D1DEDD] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              {formatMonthDay(new Date(booking.start_time))}
            </span>
          </div>
          <span className="text-[42px] font-avenir text-[#000000] text-left leading-10"> for </span>
          <div className="rounded-full bg-[#CED0D0] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10 ">
              {cleanCalcomEventTitleToJustEventType(booking.title || '')}
            </span>
          </div>
          <div className="rounded-full bg-[#B8E7F4] px-1 inline-flex ">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              @ {formatClientTimeFromDate(new Date(booking.start_time))}
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};
