import { USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import {
  faArrowRight,
  faBullhorn,
  faCalendarCirclePlus,
  faComments,
  faDollarCircle,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { hasAccess } from '../../libs/utils/hasAccess';

export const ConversationsBrochure = () => {
  const user = useContext(AuthContext);
  const userCanUseCampaigns = hasAccess(user.token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT);
  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    navigate('/bookings');
  };

  const handleUpgrade = () => {
    navigate('/subscription');
  };

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="min-h-80 flex w-full flex-col items-center justify-center rounded-md ">
        {!userCanUseCampaigns && (
          <div
            onClick={handleUpgrade}
            className="w-full flex bg-black-alt border-2 border-white rounded-lg p-2 items-center justify-center mb-8 cursor-pointer lg:max-w-[420px]"
          >
            <FontAwesomeIcon icon={faDollarCircle} className="text-white text-[16px] mr-2" />
            <span className="text-[14px] font-normal font-avenir text-white">
              Upgrade to use this feature
            </span>
            <FontAwesomeIcon icon={faArrowRight} className="text-white text-[16px] ml-2" />
          </div>
        )}
        <div className="bg-black-alt flex h-[72px] w-[72px] items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faComments} className="text-white text-[32px]" />
        </div>
        <div className="max-w-[420px] text-center mt-6">
          <span className="text-[22px] font-medium font-avenir ">Conversations</span>
          <div className="flex flex-col items-center justify-center ">
            <p className="text-[14px] font-normal font-avenir text-black-alt mt-2">
              Ever dream of having your own AI Agent that can book appointments and handle all of
              your businesses calls and text messages? Now you can!
            </p>
            {/* <div className="flex items-center justify-between w-full">
              <button
                onClick={() => {
                  navigate('/campaigns/general');
                }}
                disabled={!userCanUseCampaigns}
                className="bg-black-alt text-white text-[14px] font-medium font-avenir rounded-md px-4 py-2 mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon icon={faPlus} className="text-white text-[14px] mr-2" />
                Create Campaign
              </button>

              <button
                onClick={handleCreateCampaign}
                disabled={!userCanUseCampaigns}
                className="bg-black-alt text-white text-[14px] font-medium font-avenir rounded-md px-4 py-2 mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon
                  icon={faCalendarCirclePlus}
                  className="text-white text-[14px] mr-2"
                />
                Find Canceled Bookings
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
