import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../authContext';
import { EventType } from '@book-nestor-monorepo/shared-types'; // Assuming you have this type defined
import { getEventTypes } from '../../libs/services/event-type';

interface ServicesContextType {
  isLoading: boolean;
  services: EventType[];
  fetchServices: (forceRefresh?: boolean) => Promise<EventType[]>;
}

interface ServicesProviderProps {
  children: ReactNode;
}

const ServicesContext = createContext<ServicesContextType | undefined>(undefined);

export const useServices = (): ServicesContextType => {
  const context = useContext(ServicesContext);

  if (!context) {
    throw new Error('useServices must be used within a ServicesProvider');
  }

  return context;
};

export const ServicesProvider: FunctionComponent<ServicesProviderProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [services, setServices] = useState<EventType[]>([]);

  const servicesFetched = useRef<boolean>(false);

  const handleFetchServices = useCallback(
    async (forceRefresh = false): Promise<EventType[]> => {
      if (servicesFetched.current && !forceRefresh) {
        return services;
      }

      setIsLoading(true);
      try {
        const user = authContext.user;
        if (!user) return [];
        const result = await getEventTypes(user.id as string);
        setServices(result);
        servicesFetched.current = true;
        return result;
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [services, authContext.user],
  );

  useEffect(() => {
    handleFetchServices();
  }, [handleFetchServices]);

  return (
    <ServicesContext.Provider
      value={{
        isLoading,
        services,
        fetchServices: handleFetchServices,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
