import { Booking, ContactBooking } from '@book-nestor-monorepo/shared-types';
import { useCancelBooking } from '@calcom/atoms';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeleteButton } from '../../formLibrary/confirmDeleteButton';
import { formatClientTimeFromDate, formatMonthDay } from '../../../libs/utils/date.util';
import { cleanCalcomEventTitleToJustEventType } from '../../../libs/utils/string.util';

export const BookingDetailCard = ({
  contactBooking,
  booking,
}: {
  contactBooking: ContactBooking;
  booking: Booking;
}) => {
  const navigate = useNavigate();
  const { mutate: cancelBooking } = useCancelBooking({
    onSuccess: async () => {
      navigate('/');
    },
  });
  const cancelBookingClick = async (booking: Booking) => {
    cancelBooking({
      id: booking.external_id,
      uid: booking.id,
      cancellationReason: 'User request',
      allRemainingBookings: true,
    });
  };

  if (!booking) {
    return null;
  }

  return (
    <div>
      <div className="flex h-full w-full">
        <div className="flex items-center justify-end w-full">
          <ConfirmDeleteButton onClick={() => cancelBookingClick(booking)} />
        </div>
      </div>
      <div className="flex flex-col items-start justify-start leading-10">
        <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
          {booking.contact.name} is booked on{' '}
          <div className="rounded-full bg-[#D1DEDD] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              {formatMonthDay(new Date(booking.start_time))}
            </span>
          </div>
          <span className="text-[42px] font-avenir text-[#000000] text-left leading-10"> for </span>
          <div className="rounded-full bg-[#CED0D0] px-1 inline-flex">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10 ">
              {cleanCalcomEventTitleToJustEventType(booking.title || '')}
            </span>
          </div>
          <div className="rounded-full bg-[#B8E7F4] px-1 inline-flex ">
            <span className="text-[42px] font-avenir text-[#000000] text-left leading-10">
              @ {formatClientTimeFromDate(new Date(booking.start_time))}
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};
