import { OnboardingTaskNames, UserServicePhone } from '@book-nestor-monorepo/shared-types';
import * as Sentry from '@sentry/react';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import SwipeUpModal from '../components/common/SwipeModal/SwipeModal';
import { SaveButton } from '../components/formLibrary/formSaveButton';
import PrimaryTextFieldLight from '../components/formLibrary/primaryTextInputLight';
import LoadingDots from '../components/loading';
import UnregisteredPhoneNumberModal from '../components/modals/unregistered-phone-number';
import { AuthContext } from '../contexts/authContext';
import { useModal } from '../contexts/modalContext';
import {
  assignUserServicePhone,
  getServicePhoneDetailsByUser,
  updateUserServicePhone,
} from '../libs/services/voice';
import { phoneNumberAddressSchema } from '../schemas/phone-number-location.schema';
import { userServicePhoneSchema } from '../schemas/user-service-phone.schema';
import FormikWrapper from './../components/form-wrapper';
import { AppLayout } from './layouts/app-layout';
import { useQuickStart } from '../contexts/quickStartContext';

export default function Communication() {
  const [userServicePhone, setUserServicePhone] = useState<UserServicePhone>();
  const { markTaskComplete } = useQuickStart();
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const { openModal, closeModal, modalContent } = useModal();

  const [isSwipeOpen, setIsSwipeOpen] = useState(false);

  const fetchCommunication = async () => {
    if (!authContext.user) return;
    const userServicePhoneResponse = await getServicePhoneDetailsByUser(authContext.user.id || '');
    setUserServicePhone(userServicePhoneResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCommunication();
  }, [authContext.user]);

  const initialValuesAi: Partial<UserServicePhone> = {
    friendly_business_name: userServicePhone?.friendly_business_name || '',
    friendly_user_name: userServicePhone?.friendly_user_name || '',
    ai_assistant_name: userServicePhone?.ai_assistant_name || '',
    voice_assistant_enabled: userServicePhone?.voice_assistant_enabled || false,
  };

  const initialValuesLocation = {
    city: '',
    state: '',
  };

  const handleSubmitAi = async (values: Partial<UserServicePhone>) => {
    try {
      await updateUserServicePhone({ ...mapToUpdateUserServicePhone(values) });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSubmitAddress = async (values: { city: string; state: string }) => {
    try {
      await assignUserServicePhone(values);
      await markTaskComplete(OnboardingTaskNames.verifyAIAssistant);

      fetchCommunication();
      closeModal();
    } catch (error) {
      alert(
        'No available phone numbers found in your area. Please try again with a different area.',
      );
      Sentry.captureException(error);
    }
  };

  const mapToUpdateUserServicePhone = (values: Partial<UserServicePhone>): UserServicePhone => {
    if (!userServicePhone) {
      throw new Error('User Service Phone not found');
    }
    return {
      ...userServicePhone,
      ...values,
    };
  };

  const FormFieldsAi = ({ dirty }: any) => {
    const { values, handleChange, submitForm, isSubmitting, setFieldValue } =
      useFormikContext<Partial<UserServicePhone>>();

    return (
      <div className="w-full pt-3">
        <div className="grid gap-2 grid-cols-2">
          <div className="w-full h-[133px] flex flex-col justify-between rounded-[16px] bg-black p-2">
            <div className="flex w-[105px] h-10 rounded-full bg-white p-[2px]">
              <div className="h-full w-[67px] min-w-[67px] rounded-full bg-black flex items-center justify-center">
                <p
                  className={`text-[12px] font-avenir font-medium ${values.voice_assistant_enabled ? 'text-book-green' : 'text-red-notification'}`}
                >
                  {values.voice_assistant_enabled ? 'Active' : 'Inactive'}
                </p>
              </div>
              <button
                onClick={() => {
                  if (userServicePhone) {
                    setFieldValue('voice_assistant_enabled', !values.voice_assistant_enabled);
                    submitForm();
                  }
                }}
                className="w-full flex items-center justify-center"
              >
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                    fill="#303030"
                  />
                </svg>
              </button>
            </div>
            <div>
              <h4 className="font-avenir text-left text-white leading-[16px]">
                Activate
                <br />
                <span className="font-bold">AI Assistant</span>
              </h4>
            </div>
          </div>
          <div className="w-full h-[133px] flex flex-col justify-between rounded-[16px] bg-[#ECECEC] p-2">
            <div className="flex justify-between w-full">
              <p className="text-[10px] leading-[16px] text-[#959595] text-left">
                Choose the name of
                <br />
                your AI Assistant
              </p>
              <button className="w-8 min-w-8 ml-2 h-8 flex items-center justify-center rounded-full border border-[#00000080]">
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
            <div>
              <input
                name="ai_assistant_name"
                type="text"
                onChange={(e) => {
                  if (userServicePhone) {
                    handleChange(e);
                  }
                }}
                value={values.ai_assistant_name}
                onBlur={submitForm}
                placeholder="Samantha"
                className="w-full appearance-none text-black-alt placeholder:text-black-alt font-avenir focus:outline-none focus:shadow-outline bg-transparent border-0"
              />
            </div>
          </div>
          <div className="w-full h-[133px] flex flex-col justify-between rounded-[16px] bg-[#ECECEC] p-2">
            <div className="flex justify-between w-full">
              <p className="text-[10px] leading-[16px] text-[#959595] text-left">
                Create a short and friendly version of your company name.
              </p>
              <button className="w-8 min-w-8 ml-2 h-8 flex items-center justify-center rounded-full border border-[#00000080]">
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
            <div>
              <input
                name="friendly_business_name"
                type="text"
                onChange={(e) => {
                  if (userServicePhone) {
                    handleChange(e);
                  }
                }}
                onBlur={submitForm}
                value={values.friendly_business_name}
                placeholder="Fresh Bodywork"
                className="w-full appearance-none text-black-alt placeholder:text-black-alt font-avenir focus:outline-none focus:shadow-outline bg-transparent border-0"
              />
            </div>
          </div>
          <div className="w-full h-[133px] flex flex-col justify-between rounded-[16px] bg-[#ECECEC] p-2">
            <div className="flex justify-between w-full">
              <p className="text-[10px] leading-[16px] text-[#959595] text-left">
                How should the AI Assistant refer to you. Choose a short and friendly name.
              </p>
              <button className="w-8 min-w-8 ml-2 h-8 flex items-center justify-center rounded-full border border-[#00000080]">
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.53125 5.96875C9.78516 5.96875 10 6.18359 10 6.4375C10 6.71094 9.78516 6.90625 9.53125 6.90625H3.04688C2.85156 7.55078 2.24609 8 1.5625 8C0.683594 8 0 7.31641 0 6.4375C0 5.57812 0.683594 4.875 1.5625 4.875C2.24609 4.875 2.85156 5.34375 3.04688 5.96875H9.53125ZM0.9375 6.4375C0.9375 6.78906 1.21094 7.0625 1.5625 7.0625C1.89453 7.0625 2.1875 6.78906 2.1875 6.4375C2.1875 6.10547 1.89453 5.8125 1.5625 5.8125C1.21094 5.8125 0.9375 6.10547 0.9375 6.4375ZM6.93359 1.59375C7.12891 0.96875 7.73438 0.5 8.4375 0.5C9.29688 0.5 10 1.20312 10 2.0625C10 2.94141 9.29688 3.625 8.4375 3.625C7.73438 3.625 7.12891 3.17578 6.93359 2.53125H0.46875C0.195312 2.53125 0 2.33594 0 2.0625C0 1.80859 0.195312 1.59375 0.46875 1.59375H6.93359ZM8.4375 2.6875C8.76953 2.6875 9.0625 2.41406 9.0625 2.0625C9.0625 1.73047 8.76953 1.4375 8.4375 1.4375C8.08594 1.4375 7.8125 1.73047 7.8125 2.0625C7.8125 2.41406 8.08594 2.6875 8.4375 2.6875Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
            <div>
              <input
                name="friendly_user_name"
                type="text"
                onChange={(e) => {
                  if (userServicePhone) {
                    handleChange(e);
                  }
                }}
                onBlur={submitForm}
                value={values.friendly_user_name}
                placeholder="Dr. Johnson"
                className="w-full appearance-none text-black-alt placeholder:text-black-alt font-avenir focus:outline-none focus:shadow-outline bg-transparent border-0"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FormFieldsLocation = ({ dirty }: any) => {
    const { values, handleChange, submitForm, isSubmitting, isValid } = useFormikContext<{
      city: string;
      state: string;
    }>();

    return (
      <div className="p-4 mt-10 ml-5 bg-black-modal">
        <p className="text-base font-semibold font-avenir mb-2 text-white">
          Let’s get your phone
          <br />
          number set up.
        </p>
        <div className="flex w-full items-center mb-4">
          <div className="flex items-center relative">
            <PrimaryTextFieldLight
              label="City"
              color="primary"
              fullWidth={true}
              name="city"
              type="text"
              onChange={handleChange}
              value={values.city}
              helperText="e.g. New York"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex items-center relative">
            <PrimaryTextFieldLight
              label="State Abbrev."
              color="primary"
              fullWidth={true}
              name="state"
              type="text"
              onChange={handleChange}
              value={values.state}
              helperText="e.g. NY"
            />
          </div>
        </div>
        <div className="w-full pt-12">
          <p className="text-base font-semibold font-avenir text-white mb-2">Find a number</p>
          <SaveButton
            onClick={async () => {
              if (isValid) {
                await submitForm();
              }
            }}
            defaultText="Get my number"
            loadingText="Searching"
            successText="Number found"
            disabled={!isValid || !dirty}
            color="black"
            bgColor="white"
          />
        </div>
      </div>
    );
  };

  const NoServicePhone = ({ onClose }: { onClose: () => void }) => {
    return (
      <div className="w-full">
        <div className={`flex flex-row items-center justify-between h-12 mb-9`}>
          <p className="font-inter font-medium text-base leading-4 text-white "></p>
          <div className="flex flex-row gap-1 items-center">
            <span
              className="flex items-center justify-center right-1 top-2 w-12 h-12 cursor-pointer z-20 rounded-full border border-white-solid"
              onClick={onClose}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#F7F7F7" />
                <path
                  d="M28.0312 27.0039C28.2773 27.2773 28.2773 27.6875 28.0312 27.9336C27.7578 28.207 27.3477 28.207 27.1016 27.9336L23.875 24.6797L20.6211 27.9336C20.3477 28.207 19.9375 28.207 19.6914 27.9336C19.418 27.6875 19.418 27.2773 19.6914 27.0039L22.9453 23.75L19.6914 20.4961C19.418 20.2227 19.418 19.8125 19.6914 19.5664C19.9375 19.293 20.3477 19.293 20.5938 19.5664L23.875 22.8477L27.1289 19.5938C27.375 19.3203 27.7852 19.3203 28.0312 19.5938C28.3047 19.8398 28.3047 20.25 28.0312 20.5234L24.7773 23.75L28.0312 27.0039Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <FormikWrapper<{ city: string; state: string }>
          initialValues={initialValuesLocation}
          validationSchema={phoneNumberAddressSchema}
          onSubmit={handleSubmitAddress}
        >
          {({ dirty }) => <FormFieldsLocation dirty={dirty} />}
        </FormikWrapper>
      </div>
    );
  };

  const AiForm = () => {
    return (
      <FormikWrapper<Partial<UserServicePhone>>
        initialValues={initialValuesAi}
        validationSchema={userServicePhoneSchema}
        onSubmit={handleSubmitAi}
      >
        {({ dirty }) => <FormFieldsAi dirty={dirty} />}
      </FormikWrapper>
    );
  };

  const openServicePhone = () => {
    openModal(
      <SwipeUpModal onClose={closeModal} variant={true} bgColor={'bg-black-modal'}>
        <NoServicePhone onClose={closeModal} />
      </SwipeUpModal>,
    );
  };

  const renderContent = (type: string) => {
    const isFullModal = true;
    let content = null;
    let modalColor = 'bg-gray-modals';
    switch (type) {
      case 'unregisteredPhoneNumber':
        modalColor = 'bg-black-modal';
        content = (
          <UnregisteredPhoneNumberModal
            phoneNumber={userServicePhone?.phone_number}
            onClose={closing}
          />
        );
        break;

      default:
        content = <div />;
        break;
    }

    if (isFullModal && !modalContent) {
      openModal(
        <SwipeUpModal
          variant={true}
          useContainer={isFullModal}
          bgColor={modalColor}
          onClose={() => closeModal()}
        >
          {content}
        </SwipeUpModal>,
      );
      return <div />;
    } else {
      return (
        <SwipeUpModal
          variant={true}
          useContainer={isFullModal}
          bgColor={modalColor}
          onClose={() => setIsSwipeOpen(false)}
        >
          {content}
        </SwipeUpModal>
      );
    }
  };

  const closing = () => {
    setIsSwipeOpen(false);
    closeModal();
  };

  const handleModal = (section: string) => {
    setIsSwipeOpen(true);
    renderContent(section);
  };

  return (
    <AppLayout settings>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col h-full pt-8 md:pt-2 font-avenir justify-between">
          <div className="pb-4 px-2">
            <span className="text-[42px] leading-[48px]">
              Communication
              <br />
              Settings
            </span>
          </div>
          <div className="px-2">
            {userServicePhone?.phone_number ? (
              <>
                <div className="bg-white h-12 rounded-full px-[18px] inline-flex items-center">
                  <p className="text-[14px] text-black-alt ">
                    AI Phone #
                    <span className="font-bold ml-[10px]">{userServicePhone?.phone_number}</span>
                  </p>
                </div>
                {userServicePhone?.external_status !== 'registered' && (
                  <div>
                    <div className="h-12 rounded-full px-[10px] inline-flex items-center">
                      <p className="text-sm text-gray-400 text-left break-words">
                        Your Phone Number is Pending
                        <svg
                          className="ml-2 inline-block align-top cursor-pointer"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleModal('unregisteredPhoneNumber')}
                        >
                          <circle cx="12" cy="12" r="12" fill="#DCDDD7" />
                          <text
                            x="12"
                            y="16"
                            textAnchor="middle"
                            fontSize="16"
                            fontWeight="bold"
                            fill="#000"
                          >
                            i
                          </text>
                        </svg>
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <button
                onClick={openServicePhone}
                className="w-max h-12 px-[18px] rounded-full bg-black-alt flex items-center gap-[10px] text-[14px] text-gray-modal mb-10"
              >
                <p>Get my number</p>
                <p>|</p>
                <svg
                  width="9"
                  height="13"
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.125 0.5C7.94531 0.5 8.625 1.17969 8.625 2V11C8.625 11.8438 7.94531 12.5 7.125 12.5H1.875C1.03125 12.5 0.375 11.8438 0.375 11V2C0.375 1.17969 1.03125 0.5 1.875 0.5H7.125ZM7.5 11V2C7.5 1.8125 7.3125 1.625 7.125 1.625H1.875C1.66406 1.625 1.5 1.8125 1.5 2V11C1.5 11.2109 1.66406 11.375 1.875 11.375H7.125C7.3125 11.375 7.5 11.2109 7.5 11ZM5.25 9.875C5.4375 9.875 5.625 10.0625 5.625 10.25C5.625 10.4609 5.4375 10.625 5.25 10.625H3.75C3.53906 10.625 3.375 10.4609 3.375 10.25C3.375 10.0625 3.53906 9.875 3.75 9.875H5.25Z"
                    fill="#DCDDD7"
                  />
                </svg>
              </button>
            )}
            <div className="flex flex-col text-center bg-white rounded-tl-3xl rounded-tr-3xl py-6 px-4 pb-[100px] h-full mt-10">
              <div className="flex flex-row items-baseline pb-2">
                <div className="flex justify-start flex-grow-0">
                  <span>Settings</span>
                </div>
              </div>
              <AiForm />
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}
