import { Notification, NotificationType } from '@book-nestor-monorepo/shared-types';
import { faArrowUpRightFromSquare, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { formatYYYYMMDDDate } from '../../libs/utils/date.util';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';

const NotificationIcon = ({ messages: initialMessages }: { messages: Notification[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const navigate = useNavigate();
  const ref = useRef(null);
  const authContext = useContext(AuthContext);

  useOutsideClick(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  const toggleNotifications = () => {
    if (messages.length > 0) setIsOpen(!isOpen);
  };

  const deleteNotification = (index: number) => {
    setMessages(messages.filter((_, i) => i !== index));
  };

  const handleMessageClick = (index: number) => {
    setIsOpen(false);
    deleteNotification(index);
    if (messages[index].type === NotificationType.TEXT_MESSAGE) {
      navigate(`/text-conversations}`);
    } else if (messages[index].type === NotificationType.VOICE_MESSAGE) {
      navigate(`/voice-messages}`);
    }
  };

  useEffect(() => {
    if (
      authContext.user &&
      authContext.user.payment_connect_data?.connected_account_id &&
      !authContext.user.payment_connect_data?.payment_requirements_complete
    ) {
      const newMessage: Notification = {
        type: NotificationType.SYSTEM_ALERT,
        content: 'Please complete your Stripe setup to accept payments.',
        createdAt: Date.now(),
        link: authContext.user.payment_connect_data.complete_requirements_link,
      };
      const existingSystemAlert = messages.some(
        (message) => message.type === NotificationType.SYSTEM_ALERT,
      );
      if (!existingSystemAlert) {
        setMessages([...messages, newMessage]);
      }
    }
  }, []);

  return (
    <div ref={ref} className="relative">
      <button
        onClick={toggleNotifications}
        className="bg-white rounded-full h-12 w-12 flex items-center justify-center relative"
      >
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 1.25V1.67188C8.1875 1.95312 9.5 3.42969 9.5 5.1875V5.98438C9.5 7.03906 9.85156 8.07031 10.5078 8.91406L10.8594 9.33594C11 9.52344 11.0234 9.75781 10.9297 9.94531C10.8359 10.1328 10.6484 10.25 10.4375 10.25H1.0625C0.828125 10.25 0.640625 10.1328 0.546875 9.94531C0.453125 9.75781 0.476562 9.52344 0.617188 9.33594L0.96875 8.91406C1.625 8.07031 2 7.03906 2 5.98438V5.1875C2 3.42969 3.28906 1.95312 5 1.67188V1.25C5 0.851562 5.32812 0.5 5.75 0.5C6.14844 0.5 6.5 0.851562 6.5 1.25ZM5.5625 2.75C4.20312 2.75 3.125 3.85156 3.125 5.1875V5.98438C3.125 7.10938 2.79688 8.1875 2.1875 9.125H9.28906C8.67969 8.1875 8.375 7.10938 8.375 5.98438V5.1875C8.375 3.85156 7.27344 2.75 5.9375 2.75H5.5625ZM7.25 11C7.25 11.3984 7.08594 11.7969 6.80469 12.0781C6.52344 12.3594 6.125 12.5 5.75 12.5C5.35156 12.5 4.95312 12.3594 4.67188 12.0781C4.39062 11.7969 4.25 11.3984 4.25 11H7.25Z"
            fill="#333333"
          />
        </svg>
        {messages.length > 0 && (
          <span className="absolute top-0 right-0 bg-black-alt text-white text-[9px] font-semibold rounded-full w-4 h-4 flex items-center justify-center">
            {messages.length}
          </span>
        )}
      </button>
      {isOpen ? (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50">
          {messages.map((message, index) => (
            <div
              key={index}
              className="text-gray-700 text-sm px-4 py-2 hover:bg-gray-100 border-b last:border-b-0"
            >
              {message.type === NotificationType.SYSTEM_ALERT ? (
                <Link
                  to={message.link as string}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col cursor-pointer"
                >
                  <p className="font-semibold text-xs">
                    {formatYYYYMMDDDate(new Date(message.createdAt))}
                  </p>
                  <div className="flex flex-row">
                    <p className="text-xs">{message.content}</p>
                    <button>
                      <FontAwesomeIcon size="xs" color="black" icon={faArrowUpRightFromSquare} />
                    </button>
                  </div>
                </Link>
              ) : (
                <>
                  <div
                    className="flex flex-col cursor-pointer"
                    onClick={() => handleMessageClick(index)}
                  >
                    <p className="font-semibold text-xs">
                      {formatYYYYMMDDDate(new Date(message.createdAt))}
                    </p>
                    <p className="text-xs">{message.content}</p>
                  </div>
                  <button onClick={() => deleteNotification(index)}>
                    <FontAwesomeIcon size="xs" color="black" icon={faXmark} />
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default NotificationIcon;
