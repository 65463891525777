export interface Contact {
  id?: string;
  name: string;
  last_name?: string;
  is_favorite?: boolean;
  is_regular?: boolean;
  is_difficult?: boolean;
  email?: string;
  phone_number?: string;
  last_meeting?: string;
  birth_date?: string;
  next_meeting?: string;
  created_at?: string;
  updated_at?: string;
  street_address?: string;
  city?: string;
  state?: string;
  zip?: string;
  payment_customer_id?: string;
}

export interface ContactBooking {
  contact_email: string;
  booking_id: string;
  description: string;
  booking_start_time: string;
  booking_status?: string;
  client_checked_in: boolean;
  client_has_paid: boolean;
  is_cancelled: boolean;
  booking_paid_date?: string;
  booking_paid_amount?: number;
  payment_session_id?: string;
  internal_event_type_id?: string;
  internal_event_type_name?: string;
  internal_event_type_duration?: number;
  internal_event_type_price?: number;
  note?: ContactNote;
  reminder_window_sent?: ReminderWindow[];
}

export enum ReminderWindow {
  THREE_HOURS = '3hours',
  TWENTY_FOUR_HOURS = '24hours',
  FOURTY_EIGHT_HOURS = '48hours',
}

export interface ContactNote {
  id?: string;
  contact_id?: string;
  appointment_id?: string;
  title: string;
  body: string;
  gallery_image_keys?: string[];
  created_at?: string;
  updated_at?: string;
}

export interface ContactPayment {
  id: string;
  amount: number;
  currency: string;
  created: number;
  customerEmail: string;
  receipt_url: string;
  product_name?: string;
}
