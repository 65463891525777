import React, { useState } from 'react';
import { ExpandableCard, ExpandableCardProps } from './ExpandableCard';

export interface ExpandableCardActiveAdornmentProps {
  shouldShow: boolean;
  activeText?: string;
  inactiveText?: string;
}

export interface ExpandableCardListWithActiveProps {
  cards: ExpandableCardProps[];
  activeCardId: string;
  activeAdornment?: ExpandableCardActiveAdornmentProps;
}

const ExpandableCardListWithActive: React.FC<ExpandableCardListWithActiveProps> = ({
  cards,
  activeCardId,
  activeAdornment = { shouldShow: false, activeText: 'Active', inactiveText: 'Inactive' },
}) => {
  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>({});

  const handleToggle = (id: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="space-y-4">
      {cards.map((card) => (
        <ExpandableCard
          key={card.id}
          {...card}
          isExpanded={!!expandedCards[card.id]}
          onToggle={() => handleToggle(card.id)}
          isActive={card.id === activeCardId}
          activeAdornment={activeAdornment}
          content={card.content}
        />
      ))}
    </div>
  );
};

export default ExpandableCardListWithActive;
