'use client';

import { EventType } from '@book-nestor-monorepo/shared-types';
import { faCheck, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react';
import { useState } from 'react';

export default function ServiceComboBox({
  services,
  selectedService,
  onSelect,
}: {
  services: EventType[];
  selectedService: EventType | null;
  onSelect: (service: EventType) => void;
}) {
  const [query, setQuery] = useState('');

  const filteredServices =
    query === ''
      ? services
      : services.filter((service) => {
          return service.title.toLowerCase().includes(query.toLowerCase());
        });

  const serviceDisplayName = (service: EventType) => {
    if (!service) return '';
    return `${service?.title}`;
  };

  const handleSelect = (service: EventType) => {
    setQuery('');
    onSelect(service);
  };

  return (
    <Combobox
      as="div"
      value={selectedService}
      onChange={(service) => {
        setQuery('');
        handleSelect(service as EventType);
      }}
    >
      <Label className="block text-sm/6 font-medium text-gray-900 font-inter">Select Service</Label>
      <div className="relative mt-2">
        <ComboboxInput
          className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-300 sm:text-sm/6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(service) => serviceDisplayName(service as EventType)}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <FontAwesomeIcon
            icon={faChevronDown}
            className="size-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredServices.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredServices.map((service) => (
              <ComboboxOption
                key={service.id}
                value={service}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                <div className="flex">
                  <span className="truncate group-data-[selected]:font-semibold">
                    {serviceDisplayName(service)}
                  </span>
                  <span className="ml-2 truncate text-gray-500 group-data-[focus]:text-gray-400">
                    {service.duration_mins}
                  </span>
                </div>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-600 group-data-[selected]:flex group-data-[focus]:text-gray-900">
                  <FontAwesomeIcon icon={faCheck} className="size-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
