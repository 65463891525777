import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StackedCircularProgress } from '../../components/common/CircularProgress/CircularProgress';
import DesktopNumberedServiceCard from '../../components/quickStartTasks/desktop-numbered-service-card';
import { FormattedTask, useQuickStart } from '../../contexts/quickStartContext';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import HomeSchedule from './home-schedule';

export const HomeV3 = () => {
  const {
    allTasksCompleted,
    completedTasksCount,
    totalTasksCount,
    totalTaskCountForPlan,
    remainingFormattedTasks,
    subscriptionTierFormattedTasks,
    completedFormattedTasks,
    selectedTask,
  } = useQuickStart();
  const { isDesktop } = useAppExperience();
  const { bgColor } = useAppExperience();
  const navigate = useNavigate();
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    remainingFormattedTasks[0],
  );

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    } else {
      setSelectedFormattedTask(remainingFormattedTasks[0]);
    }
  }, [selectedTask]);

  useEffect(() => {
    setSelectedFormattedTask(remainingFormattedTasks[0]);
  }, [remainingFormattedTasks]);

  useEffect(() => {
    if (remainingFormattedTasks.length > 0) {
      setSelectedFormattedTask(remainingFormattedTasks[0]);
      if (isDesktop) {
        remainingFormattedTasks[0].buttonOnClick();
      } else {
        navigate('/quick-start');
      }
    }
  }, [remainingFormattedTasks]);

  return (
    <div className={`flex flex-col h-auto w-full bg-[${bgColor}] overflow-hidden`}>
      <div className="flex flex-col h-full w-full">
        <main className="flex-1 overflow-hidden flex justify-center w-full">
          <div className="w-full max-w-screen-2xl flex justify-center">
            <div className="w-full bg-transparent  rounded-2xl  h-full flex flex-col ">
              {allTasksCompleted ? (
                <div className=" flex-col w-full h-full ">
                  <HomeSchedule />
                </div>
              ) : (
                <div className="flex w-full h-full gap-2">
                  <div className="flex-1 bg-[#E5E8E8] p-4 rounded-xl">
                    <div className="flex justify-between">
                      <div className="flex-col items-start gap-4 h-auto">
                        <div className="flex h-[19px] w-[74px]  bg-[#909090] rounded-full text-[10px] font-avenir px-2 items-center justify-center cursor-pointer">
                          <p className="text-white">Onboarding</p>
                        </div>
                        <div className="flex items-start justify-start">
                          <span className="font-inter text-[24px] pt-2">Let's get you setup</span>
                        </div>
                      </div>
                      <div className="flex-col items-end">
                        <StackedCircularProgress
                          completedValue={completedTasksCount}
                          completedTotal={totalTaskCountForPlan}
                          totalValue={totalTasksCount}
                          totalTotal={totalTaskCountForPlan}
                        />
                      </div>
                    </div>
                    <div className="flex-col items-start w-full h-full pt-12 pb-96 overflow-y-auto hide-scrollbar">
                      {remainingFormattedTasks.map((task, index) => (
                        <DesktopNumberedServiceCard
                          key={task.number || index}
                          title={task.title}
                          buttonText={task.buttonText}
                          onSelectTask={task.buttonOnClick}
                          number={task.number}
                          canDismiss={task.canBeSkipped}
                          onDismiss={task.onDismiss}
                          confirmDeleteText={task.confirmDeleteText}
                        />
                      ))}
                      {subscriptionTierFormattedTasks.map((task, index) => (
                        <div className="flex flex-row" key={task.number || index}>
                          <div className="flex flex-row justify-between w-full bg-black rounded-3xl items-center text-center mb-2 pl-4 pr-2 py-2">
                            <div className="flex flex-row justify-start items-start text-left">
                              <span className="text-[16px] font-avenir font-semibold leading-4  text-white">
                                {task.title}
                              </span>
                            </div>
                            <button
                              className="text-[12px] text-black bg-[#ffffff] h-10 px-4 font-avenir font-normal leading-4 rounded-full"
                              onClick={() => {
                                navigate('/subscription');
                              }}
                            >
                              {'Upgrade'}
                            </button>
                          </div>
                        </div>
                      ))}
                      {completedFormattedTasks.map((task, index) => (
                        <DesktopNumberedServiceCard
                          key={task.number || index}
                          title={task.title}
                          buttonText={task.buttonText}
                          onButtonClick={task.buttonOnClick}
                          number={task.number}
                          disabled={true}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="flex-1 bg-[#E5E8E8] px-5 pt-14 rounded-xl items-start justify-start">
                    {/* Content for the second half */}
                    <h2 className="text-[24px] font-inter text-left  pb-10">Information</h2>
                    <h2 className="text-[14px] font-avenir font-semibold text-left  pb-2 uppercase">
                      {selectedFormattedTask?.desktopHeader || selectedFormattedTask?.title}
                    </h2>
                    {selectedFormattedTask?.desktopParagraphs?.map((paragraph, index) => (
                      <p key={index} className="text-[14px] font-avenir  text-left  pb-4 leading-4">
                        {paragraph}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
