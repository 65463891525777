import {
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgentMenuDrawer } from '../../../components/v2/AgentMenuDrawer/AgentMenuDrawer';
import DesktopMenuDrawer from '../../../components/v2/DesktopMenuDrawer';
import { LayoutProps } from '../../../models';
import { getLeftMenuItems } from './menu-items';
import { ProfilePicture } from '../../../components/v2/App/ProfilePicture';
import { AuthContext } from '../../../contexts/authContext';
import { formatClientTimeFromDate, getAbbreviatedMonthAndDay } from '../../../libs/utils/date.util';
import {
  Contact,
  EventType,
  InsightsMetricType,
  USER_PERMISSIONS,
} from '@book-nestor-monorepo/shared-types';
import { SearchAutocompleteContacts } from '../../../components/v2/App/SearchAutoCompleteContacts';
import { useContacts } from '../../../contexts/v2/contactsContext';
import { useServices } from '../../../contexts/v2/eventTypesContext';
import { Button, ClickAwayListener, Skeleton, Tooltip } from '@mui/material';
import { SearchAutocompleteServices } from '../../../components/v2/App/SearchAutoCompleteServices';
import { ScaledBooker } from '../../../components/v2/Booker/ScaledBooker';
import { MultiPartSkeleton } from '../../../components/v2/Skeleton/MultiPartSkeleton';
import { useToast } from '../../../contexts/v2/toastContext';
import { bookingWebhook } from '../../../libs/services/bookings';
import { MetricsDisplay } from '../../../components/v2/Agent/AgentMetricDisplay';
import { useAgent } from '../../../contexts/v2/agentContext';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { ContactDetailCard } from '../../../components/v2/Contact/ContactDetailCard';
import { useBookings } from '../../../contexts/v2/bookingsContext';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { DesktopAppMenu } from '../../../components/v2/Menu/DesktopAppMenu';

const ExpandedRightContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { bgColor } = useAppExperience();
  return (
    <div
      className={`bg-[#E5E8E8] rounded-2xl w-3/4 h-[80%] max-h-[910px] overflow-y-scroll hide-scrollbar`}
    >
      <div
        className={`w-full h-full text-2xl items-center justify-center text-center font-avenir bg-[${bgColor}]`}
      >
        <DesktopAppMenu />
        <div className="flex flex-col h-full w-full p-2">{children}</div>
      </div>
    </div>
  );
};

const CollapsedRightContainer: React.FC<{ handleContainerToggle: () => void }> = ({
  handleContainerToggle,
}) => {
  const { bookings: upcomingBookings } = useBookings();

  const todaysBookings = upcomingBookings.filter((booking) => {
    return booking.start_time.split('T')[0] === new Date().toISOString().split('T')[0];
  });

  return (
    <div className={`bg-[#E5E8E8] rounded-2xl w-1/3 h-[273px]`}>
      {/* <div className="w-full h-full text-2xl items-center justify-center text-center font-avenir">
        V2 App Views
      </div> */}
      <div className="w-full h-full bg-[#F5F5F7] rounded-2xl overflow-hidden flex flex-col">
        <div className="bg-[#E5E8E8] h-[160px] rounded-b-2xl p-4 flex flex-col items-center justify-center">
          <div className="text-[#13ACD4] text-[36px] font-bold font-avenir">
            {todaysBookings.length}
          </div>
          <div className="text-black text-[24px] leading-[30px] font-semibold font-avenir">
            {todaysBookings.length === 1 ? 'booking' : 'bookings'}
          </div>
          <div className="text-black text-[24px] leading-[30px] font-semibold font-avenir">
            today
          </div>
        </div>
        <div className="flex-1 p-4 overflow-y-scroll hide-scrollbar">
          {todaysBookings.map((booking, index) => (
            <div key={index} className="flex items-center justify-start mb-1 last:mb-0">
              <div className="bg-[#E5E8E8] h-[19px] w-auto rounded-full flex items-center justify-start px-2">
                <span className="text-[10px] text-left ">
                  {booking.attendees[0].name} |{' '}
                  {formatClientTimeFromDate(new Date(booking.start_time))}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center mt-2">
        <button
          className="rounded-full w-full h-[40px] p-2 bg-[#333333] text-white flex justify-center items-center"
          onClick={handleContainerToggle}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        </button>
      </div>
    </div>
  );
};

const ExpandedLeftContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { user } = useContext(AuthContext);
  const dateParts = getAbbreviatedMonthAndDay(new Date());
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const { agentMetrics, isLoading: isLoadingAgentMetrics } = useAgent();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [selectedService, setSelectedService] = useState<EventType | null>();
  const [latestCreatedContactId, setLatestCreatedContactId] = useState<string | null>(null);
  const latestContactRef = useRef<string | null>(null);
  const [bookerKey, setBookerKey] = useState(0);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const updateUrlWithoutNavigation = (date: string, url: string) => {
    const newUrl = `${url}?date=${date}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };
  const handleBookingSuccess = async (data: any) => {
    console.log('Booking success data:', data);
    showToast('Booking created successfully!', 'success');
    setSelectedContact(null);
    setSelectedService(services[0]);
    const currentUrl = new URL(window.location.href);
    const dateParam = currentUrl.searchParams.get('date');
    if (dateParam) {
      // Update the URL without navigating
      updateUrlWithoutNavigation(dateParam, currentUrl.pathname);
    }
    if (user?.user_slug?.slug && data?.data?.uid) {
      await bookingWebhook({
        user_slug: user?.user_slug?.slug,
        booking_id: data?.data?.uid,
      });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
  }, [services, contacts]);

  useEffect(() => {
    setBookerKey((prevKey) => prevKey + 1);
  }, [selectedContact, selectedService]);

  function scaledBookerOrSplash() {
    if (services.length > 0 && selectedService) {
      return (
        <ScaledBooker
          key={bookerKey}
          scale={0.6} // Adjust this value as needed
          eventTypeSlug={selectedService?.slug as string}
          username={user?.connection_data?.cal_provider_user_name as string}
          contact={selectedContact as Contact}
          onSuccess={handleBookingSuccess}
        />
      );
    } else {
      return (
        <div className="flex-grow flex items-center justify-center p-4 h-full">
          <div className="flex flex-col items-center justify-center">
            <p className="font-avenir font-bold text-[43px] leading-[50px] ">
              Create your first service to start booking!
            </p>
          </div>
        </div>
      );
    }
  }

  const openContactModal = () => {
    openMuiModal(
      <ContactDetailCard
        onSuccess={async (contactId?: string) => {
          if (contactId) {
            latestContactRef.current = contactId;
            setLatestCreatedContactId(contactId);
          }
          await fetchContacts(true);
          closeMuiModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const handleNewContact = async () => {
      if (
        contacts.length > 0 &&
        latestCreatedContactId &&
        latestContactRef.current === latestCreatedContactId
      ) {
        const newContact = contacts.find((contact) => contact.id === latestCreatedContactId);
        if (newContact) {
          setSelectedContact(newContact);
          latestContactRef.current = null;
          setLatestCreatedContactId(null);
        }
      }
    };

    handleNewContact();
  }, [contacts, latestCreatedContactId]);

  return (
    <div className="mr-2 block rounded-2xl  w-2/3 h-full">
      <div
        className={`bg-[#F5F5F7] rounded-2xl  mb-2 h-[280px] text-center font-avenir text-2xl pt-2`}
      >
        <div className="bg-[#F5F5F7] rounded-xl p-4 mb-2">
          <div className="flex w-full h-auto">
            <div className="w-2/3 flex flex-col">
              <div className="flex items-start justify-start p-4 mt-4">
                <ProfilePicture picture={user?.picture} firstName={user?.first_name} />
                <div className="flex flex-col items-start pl-4 pt-4">
                  <div className="mb-2">
                    <p className="text-[24px] font-avenir">{user?.first_name}</p>
                  </div>

                  <div className="flex items-center justify-start w-full">
                    <div
                      className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer"
                      onClick={() => navigate('/bookings')}
                    >
                      <p className="text-black">Bookings</p>
                    </div>
                    <div
                      className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer ml-1"
                      onClick={() => navigate('/contacts')}
                    >
                      <p className="text-black">Contacts</p>
                    </div>
                    <div
                      className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer ml-1"
                      onClick={() => navigate('/payments')}
                    >
                      <p className="text-black">Payments</p>
                    </div>
                  </div>
                </div>
              </div>
              <MetricsDisplay
                isLargeScreen={false}
                isLoadingAgentMetrics={isLoadingAgentMetrics}
                agentMetrics={agentMetrics}
              />
            </div>

            {/* Right Column */}
            <div className="w-1/3 flex flex-col">
              <div className="flex-grow flex items-start justify-end p-4">
                <div className="flex">
                  <div className="flex flex-col">
                    <p className="text-[16px] font-inter">{dateParts.month}</p>
                    <p className="text-[32px] font-avenir font-bold">{dateParts.day}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* First middle container - narrow */}
      <div className="bg-[#E5E8E8] rounded-2xl  mb-2  h-[140px]">
        <div className="bg-[#E5E8E8] rounded-xl flex-1 p-4">
          <div className="flex w-full items-start justify-between">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Clients</p>
            </div>
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={openContactModal}
            >
              <div className="w-[20px] h-[20px]  flex items-center justify-center">
                <FontAwesomeIcon icon={faPlus} className="text-black text-[16px]" />
              </div>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingContacts ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteContacts
                contacts={contacts}
                label="Book a Client"
                onSelectContact={(contact: Contact | null) => {
                  setSelectedContact(contact);
                }}
                selectedContact={selectedContact}
              />
            )}
          </div>
        </div>
      </div>
      {/* Second middle container - narrow */}
      <div className="bg-[#E5E8E8] rounded-2xl  mb-2 h-[140px]">
        <div className="bg-[#E5E8E8] rounded-xl flex-1 p-4">
          <div className="flex w-full items-start justify-start">
            <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
              <p className="text-white text-[10px] font-avenir">Services</p>
            </div>
          </div>
          <div className="flex w-full  items-start justify-start pt-4">
            {isLoadingServices ? (
              <Skeleton
                variant="rounded"
                width={'100%'}
                height={30}
                sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
              />
            ) : (
              <SearchAutocompleteServices
                services={services}
                label="Book a Service"
                onSelectService={(service: EventType | null) => {
                  setSelectedService(service);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* Bottom container - taller */}
      <div className="bg-[#F5F5F7] rounded-2xl  h-[300px]">
        <div
          id="in-app-booker"
          className="bg-white rounded-xl shadow-sm flex-grow  flex w-full relative overflow-hidden h-full"
        >
          <div className="absolute inset-0 overflow-auto hide-scrollbar">
            {isLoadingServices || isLoadingContacts ? (
              <div className="p-4">
                <MultiPartSkeleton color="#DADCDC" />
              </div>
            ) : (
              scaledBookerOrSplash()
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CollapsedLeftContainer: React.FC<{ handleContainerToggle: () => void }> = ({
  handleContainerToggle,
}) => {
  const dateParts = getAbbreviatedMonthAndDay(new Date());
  const { user } = useContext(AuthContext);
  const { agentMetrics, isLoading: isLoadingAgentMetrics } = useAgent();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className="mr-2 block rounded-2xl  w-1/3 h-[20%]">
      <div
        className={`bg-[#F5F5F7] rounded-2xl  mb-2 h-[280px] text-center font-avenir text-2xl pt-2`}
      >
        <div className="flex flex-col items-center justify-start">
          <div className="flex-grow flex items-start justify-end p-4">
            <div className="flex">
              <div className="flex flex-col">
                <p className="text-[16px] font-inter">{dateParts.month}</p>
                <p className="text-[32px] font-avenir font-bold">{dateParts.day}</p>
              </div>
            </div>
          </div>
          <div className="flex-grow flex flex-col items-center justify-end p-4">
            <div className="flex flex-col">
              <ProfilePicture picture={user?.picture} firstName={user?.first_name} />
              <div className="my-4">
                <p className="text-[24px] font-avenir">{user?.first_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* First middle container - narrow */}
      <div className="bg-[#E5E8E8] rounded-2xl  mb-2  h-auto">
        <div className="flex flex-col p-8 items-center justify-center">
          <div className="flex">
            <span className="font-avenir text-[30px] text-[#13ACD4]  leading-[30px]">
              {agentMetrics.find(
                (metric) =>
                  metric.metric_type === InsightsMetricType.NI_BOOKING_COUNT_LAST_24_HOURS,
              )?.value || 0}
            </span>
          </div>
          <div className="flex">
            <span className="font-avenir text-[30px] pt-1">new </span>
          </div>
          <div className="flex">
            <span className="font-avenir text-[30px] pt-1"> bookings</span>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Data from the last 24 hours"
              >
                <Button
                  onClick={handleTooltipOpen}
                  sx={{
                    minWidth: 'auto',
                    padding: 0,
                    marginLeft: '4px',
                    verticalAlign: 'text-top',
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} color="#222222" size="sm" />
                </Button>
              </Tooltip>
            </ClickAwayListener>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center mt-2">
        <button
          className="rounded-full w-full p-2  h-[40px]  bg-[#333333] text-white flex justify-center items-center"
          onClick={handleContainerToggle}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export const TabletLayout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { token, bookingLink } = useContext(AuthContext);
  const [leftOpen, setLeftOpen] = useState(false);
  const [rightOpen, setRightOpen] = useState(false);
  const [rightContainerExpanded, setRightContainerExpanded] = useState(false);

  const handleLeftDrawerToggle = () => {
    if (rightOpen) {
      setRightOpen(false);
    }
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    if (!hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT)) {
      navigate('/subscription');
      return;
    }

    if (leftOpen) {
      setLeftOpen(false);
    }
    setRightOpen(!rightOpen);
  };

  const handleContainerToggle = () => {
    setRightContainerExpanded(!rightContainerExpanded);
  };

  return (
    <div className="bg-[#DEDEDE] flex justify-center items-center h-screen w-screen p-2">
      <div className="w-full h-full flex flex-col justify-between">
        <div className="flex flex-row w-full h-full">
          <div className="w-[58px] relative mr-2">
            <DesktopMenuDrawer
              open={leftOpen}
              onToggle={handleLeftDrawerToggle}
              anchor="left"
              menuItems={getLeftMenuItems(navigate, bookingLink)}
              behindOverlay={rightOpen}
            />
          </div>
          <main className="flex-1 overflow-hidden flex justify-center w-full">
            <div className="w-full flex justify-center">
              {/* Left container */}
              {rightContainerExpanded ? (
                <CollapsedLeftContainer handleContainerToggle={handleContainerToggle} />
              ) : (
                <ExpandedLeftContainer>{children}</ExpandedLeftContainer>
              )}

              {/* Right container */}
              {rightContainerExpanded ? (
                <ExpandedRightContainer>{children}</ExpandedRightContainer>
              ) : (
                <CollapsedRightContainer handleContainerToggle={handleContainerToggle} />
              )}
            </div>
          </main>
          <AgentMenuDrawer
            open={rightOpen}
            onToggle={handleRightDrawerToggle}
            anchor="right"
            behindOverlay={leftOpen}
          />
        </div>
      </div>
    </div>
  );
};
