import {
  Booking,
  BookingStatus,
  BookingWebhook,
  Schedule,
} from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { environment } from '../../environments/environment';

export const getBookings = async (
  userId: string,
  bookingStatus: BookingStatus = BookingStatus.UPCOMING,
): Promise<Booking[]> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/bookings?bookingStatus=${bookingStatus}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data.items;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch bookings');
  }
};

export const getDefaultSchedule = async (userId: string): Promise<Schedule> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/schedules/default`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch default schedule');
  }
};

export const getBookingById = async (userId: string, bookingId: string): Promise<Booking> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/bookings/${bookingId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch booking by id');
  }
};

export const cancelBooking = async (userId: string, bookingId: string): Promise<Booking> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/bookings/${bookingId}/cancel`;

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to cancel booking');
  }
};

export const bookingWebhook = async (bookingWebhook: BookingWebhook): Promise<void> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/bookings/webhook`;

    return axios.post(url, bookingWebhook, {});
  } catch (error) {
    throw new Error('Failed to process webhook');
  }
};
