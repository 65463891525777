import { Booking } from '@book-nestor-monorepo/shared-types';
import { durationStringM, formatClientTimeFromDate } from '../../libs/utils/date.util';

type AppointmentProps = {
  appointment: Booking;
  onHandleAppointment: (appointment: Booking) => void;
  selectedIdx: number;
  idx: number;
};

export default function Appointment({
  appointment,
  selectedIdx,
  idx,
  onHandleAppointment,
}: AppointmentProps) {
  return (
    <div
      id={'appointment-' + appointment.id}
      key={'appointment-' + appointment.id}
      className={`appointment left-0 w-full mb-8 cursor-pointer`}
      data-appointment-id={appointment.id}
      onClick={() => onHandleAppointment(appointment)}
    >
      <div className={`flex items-center gap-4 px-4 ${selectedIdx >= idx ? 'invisible' : ''}`}>
        <span className="text-gray-400 text-[14px] font-medium w-16 shrink-0 leading-4 tracking-wide">
          {formatClientTimeFromDate(new Date(appointment?.start_time))}
        </span>

        <div className="flex items-center w-[52px] shrink-0 justify-center">
          <span
            className={`h-1.5 w-4 rounded-full ${appointment.contact_booking?.is_cancelled ? 'bg-red-500' : 'bg-black'}`}
          />
          <span
            className={`h-1.5 w-1.5 ml-1 rounded-full ${appointment.contact_booking?.client_has_paid ? 'bg-[#43CA51]' : 'bg-gray-400'}`}
          />
          <span className="h-1.5 w-1.5 rounded-full ml-0.5" />
        </div>

        <p className="text-gray-400 text-[14px] font-medium truncate tracking-wide leading-4">
          <span>
            {appointment?.contact?.name} {appointment?.contact?.last_name} |
          </span>{' '}
          <span>{durationStringM(appointment)}</span>
        </p>
      </div>
    </div>
  );
}
