import { SubscriptionPlan, USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { AppProvider } from './contexts/appContext';
import { ModalProvider } from './contexts/modalContext';
import { OnboardingProvider } from './contexts/onboardingContext';
import { StripePaymentProvider } from './contexts/paymentContext';
import { hasAccess } from './libs/utils/hasAccess';

// SignInRoute routes
import EnterPassword from './pages/auth/enterPassword';
import ForgotPassword from './pages/auth/forgotPassword';
import Landing from './pages/auth/landing';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import VerifyCode from './pages/auth/verifyCode';
import Welcome from './pages/auth/welcome';
import Home from './pages/home';

import { MUIFlyUpModalProvider } from './contexts/muiFlyupModal';
import { AgentProvider } from './contexts/v2/agentContext';
import { ContactsProvider } from './contexts/v2/contactsContext';
import { ServicesProvider } from './contexts/v2/eventTypesContext';
import AgentConversationDetail from './pages/agent-conversation-detail';
import AgentConversations from './pages/agent-conversations';
import AgentConversationMessages from './pages/agent-messages';
import Availability from './pages/availability';
import BookingDetail from './pages/booking-detail';
import BookingNew from './pages/booking-new';
import Bookings from './pages/bookings';
import BusinessProfile from './pages/business.profile';
import Checkout from './pages/checkout';
import CheckoutDetail from './pages/checkout-detail';
import Communication from './pages/communication';
import ContactDetail from './pages/contact-detail';
import Contacts from './pages/contacts';
import OnboardingOutro from './pages/intro/outro';
import IntroSplash from './pages/intro/splash';
import AuthenticatedAppLayout from './pages/layouts/v2/authenticated-app-layout';
import OauthExchange from './pages/oauth/exchange';
import Payments from './pages/payments';
import PersonalSettings from './pages/personal/personal-settings';
import QuickStartTasks from './pages/personal/quick-start';
import ReviewDetail from './pages/review-detail';
import ScheduleEventType from './pages/scheduleEventType';
import SearchPage from './pages/search';
import ServiceDetail from './pages/service-detail';
import Services from './pages/services';
import Subscription from './pages/subscription';
import TextConversationPage from './pages/text-conversation';
import TextConversationsPage from './pages/text-conversations';
import UpgradePage from './pages/upgrade';
import { AgentPage } from './pages/v2/agent';
import VoiceMessagesPage from './pages/voice-messages';
import { ToastProvider } from './contexts/v2/toastContext';
import { HomePageV2 } from './pages/v2/home';
import { ContactsPageV2 } from './pages/v2/contacts';
import { BookingsPageV2 } from './pages/v2/bookings';
import { ServicesPageV2 } from './pages/v2/services';
import { ServiceDetailV2 } from './pages/v2/service-detail';
import { ContactDetailV2 } from './pages/v2/contact-detail';
import { CommunicationPageV2 } from './pages/v2/communication';
import { BusinessProfileV2 } from './pages/v2/business-profile';
import { AvailabilityV2 } from './pages/v2/availability';
import { PaymentsV2 } from './pages/v2/payments';
import { SubscriptionV2 } from './pages/v2/subscription';
import { BookingControlV2 } from './pages/v2/booking-control';
import { QuickStartPageV2 } from './pages/v2/quick-start';
import { BookingsProvider } from './contexts/v2/bookingsContext';
import { PersonalSettingsPageV2 } from './pages/v2/personal-settings';
import AuthGuard from './guards/authGuard';
import { BookerMobilePage } from './pages/v2/booker-mobile';

export const SignInRoute: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/enterPassword" element={<EnterPassword />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/" element={<Landing />} />
      <Route path="/book/:userSlug" element={<BookingNew />} />
      <Route path="/book/:userSlug/:eventTypeSlug" element={<ScheduleEventType />} />
      <Route path="/book/:userSlug/booking/:bookingId" element={<BookingDetail />} />
      <Route path="/book/:userSlug/reviews/:reviewId" element={<ReviewDetail />} />
      <Route path="/oauth">
        <Route path="exchange" element={<OauthExchange />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export const BookingRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/book/:userSlug" element={<BookingNew />} />
      <Route path="/book/:userSlug/:eventTypeSlug" element={<ScheduleEventType />} />
      <Route path="/book/:userSlug/booking/:bookingId" element={<BookingDetail />} />
      <Route path="/book/:userSlug/reviews/:reviewId" element={<ReviewDetail />} />
      <Route path="/oauth">
        <Route path="exchange" element={<OauthExchange />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export const V1Route: React.FunctionComponent<{ token: string }> = (props: { token: string }) => {
  return (
    <Routes>
      <Route path="/v1" element={<Outlet />}>
        <Route path="" element={<Home />} />
        <Route
          path="communication"
          element={
            props.token && hasAccess(props.token, USER_PERMISSIONS.CAN_USE_VOICE) ? (
              <Communication />
            ) : (
              <UpgradePage />
            )
          }
        />
        <Route path="contacts" element={<Contacts />} />
        <Route path="contacts/:contactId" element={<ContactDetail />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="agent-conversations" element={<AgentConversations />} />
        <Route path="agent-conversations/:conversationId" element={<AgentConversationDetail />} />
        <Route path="agent-messages" element={<AgentConversationMessages />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="payments" element={<Payments />} />
        <Route path="payments/checkout" element={<Checkout />} />
        <Route path="payments/checkout/:checkoutState" element={<CheckoutDetail />} />
        <Route path="availability" element={<Availability />} />
        <Route path="services" element={<Services />} />
        <Route path="services/:id" element={<ServiceDetail />} />
        <Route path="intro">
          <Route path="splash" element={<IntroSplash />} />
          <Route path="onboardingoutro" element={<OnboardingOutro />} />
        </Route>

        <Route path="personal">
          <Route path="settings" element={<PersonalSettings />} />
        </Route>

        <Route path="quick-start" element={<QuickStartTasks />} />

        <Route
          path="text-conversations"
          element={
            props.token && hasAccess(props.token, USER_PERMISSIONS.CAN_USE_TEXT) ? (
              <TextConversationsPage />
            ) : (
              <UpgradePage />
            )
          }
        />
        <Route
          path="text-conversations/:recipientPhoneNumber"
          element={
            props.token && hasAccess(props.token, USER_PERMISSIONS.CAN_USE_TEXT) ? (
              <TextConversationPage />
            ) : (
              <UpgradePage />
            )
          }
        />
        <Route
          path="voice-messages"
          element={
            props.token && hasAccess(props.token, USER_PERMISSIONS.CAN_USE_VOICE) ? (
              <VoiceMessagesPage />
            ) : (
              <UpgradePage />
            )
          }
        />

        <Route path="oauth">
          <Route path="exchange" element={<OauthExchange />} />
        </Route>
        <Route path="search" element={<SearchPage />} />
        <Route path="business-profile" element={<BusinessProfile />} />
        <Route path="book/:userSlug" element={<BookingNew />} />
        <Route path="book/:userSlug/:eventTypeSlug" element={<ScheduleEventType />} />
        <Route path="book/:userSlug/booking/:bookingId" element={<BookingDetail />} />
        <Route path="book/:userSlug/reviews/:reviewId" element={<ReviewDetail />} />
      </Route>
    </Routes>
  );
};

export const V2Route: React.FunctionComponent<{ token: string }> = (props: { token: string }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedAppLayout>
            <Outlet />
          </AuthenticatedAppLayout>
        }
      >
        <Route index element={<HomePageV2 />} />
        <Route path="agent" element={<AgentPage />} />
        <Route path="contacts" element={<ContactsPageV2 />} />
        <Route path="contacts/:contactId" element={<ContactDetailV2 />} />
        <Route path="bookings" element={<BookingsPageV2 />} />
        <Route path="booking-control/:contactId" element={<BookingControlV2 />} />
        <Route path="services" element={<ServicesPageV2 />} />
        <Route path="services/:serviceId" element={<ServiceDetailV2 />} />
        <Route
          path="communication"
          element={
            <AuthGuard permission={USER_PERMISSIONS.CAN_USE_VOICE}>
              <CommunicationPageV2 />
            </AuthGuard>
          }
        />
        <Route path="business-profile" element={<BusinessProfileV2 />} />
        <Route path="availability" element={<AvailabilityV2 />} />
        <Route path="payments" element={<PaymentsV2 />} />
        <Route path="payments/checkout" element={<Checkout />} />
        <Route path="payments/checkout/:checkoutState" element={<CheckoutDetail />} />
        <Route path="subscription" element={<SubscriptionV2 />} />
        <Route path="quick-start" element={<QuickStartPageV2 />} />
        <Route path="personal-settings" element={<PersonalSettingsPageV2 />} />
      </Route>
    </Routes>
  );
};

interface MainRouteProps {
  token: string;
  subscription?: SubscriptionPlan;
}

export const MainRoute: React.FunctionComponent<MainRouteProps> = (props: MainRouteProps) => {
  const isInactiveSubscription = props.subscription === SubscriptionPlan.INACTIVE;
  const navigate = useNavigate();

  useEffect(() => {
    if (isInactiveSubscription) {
      navigate('/subscription');
    }
  }, [isInactiveSubscription, navigate]);

  return (
    <OnboardingProvider>
      <AppProvider>
        <StripePaymentProvider>
          <ModalProvider>
            <MUIFlyUpModalProvider>
              <AgentProvider>
                <ContactsProvider>
                  <ServicesProvider>
                    <ToastProvider>
                      <BookingsProvider>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <AuthenticatedAppLayout>
                                <Outlet />
                              </AuthenticatedAppLayout>
                            }
                          >
                            <Route index element={<HomePageV2 />} />
                            <Route path="agent" element={<AgentPage />} />
                            <Route path="contacts" element={<ContactsPageV2 />} />
                            <Route path="contacts/:contactId" element={<ContactDetailV2 />} />
                            <Route path="bookings" element={<BookingsPageV2 />} />
                            <Route
                              path="booking-control/:contactId"
                              element={<BookingControlV2 />}
                            />
                            <Route path="services" element={<ServicesPageV2 />} />
                            <Route path="services/:serviceId" element={<ServiceDetailV2 />} />
                            <Route
                              path="communication"
                              element={
                                <AuthGuard permission={USER_PERMISSIONS.CAN_USE_VOICE}>
                                  <CommunicationPageV2 />
                                </AuthGuard>
                              }
                            />
                            <Route path="business-profile" element={<BusinessProfileV2 />} />
                            <Route path="availability" element={<AvailabilityV2 />} />
                            <Route path="payments" element={<PaymentsV2 />} />
                            <Route path="subscription" element={<SubscriptionV2 />} />
                            <Route path="quick-start" element={<QuickStartPageV2 />} />
                            <Route path="personal-settings" element={<PersonalSettingsPageV2 />} />
                            <Route path="booking-mobile" element={<BookerMobilePage />} />
                          </Route>
                          <Route path="/book/:userSlug" element={<BookingNew />} />
                          <Route
                            path="/book/:userSlug/:eventTypeSlug"
                            element={<ScheduleEventType />}
                          />
                          <Route
                            path="/book/:userSlug/booking/:bookingId"
                            element={<BookingDetail />}
                          />
                          <Route
                            path="/book/:userSlug/reviews/:reviewId"
                            element={<ReviewDetail />}
                          />
                          <Route path="/oauth">
                            <Route path="exchange" element={<OauthExchange />} />
                          </Route>
                          <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                      </BookingsProvider>
                    </ToastProvider>
                  </ServicesProvider>
                </ContactsProvider>
              </AgentProvider>
            </MUIFlyUpModalProvider>
          </ModalProvider>
        </StripePaymentProvider>
      </AppProvider>
    </OnboardingProvider>
  );
};
