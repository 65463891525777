export enum SearchResourceType {
  CONTACT = 'contact',
  MESSAGES = 'messages',
}

export interface SearchResponse {
  totalResults: number;
  resourceCounts: ResourceCount[];
  groupedResults: GroupedSearchResults;
}

export interface ResourceCount {
  resourceType: SearchResourceType;
  count: number;
}

export interface GroupedSearchResults {
  [resourceType: string]: any[];
}
