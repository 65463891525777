import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { exchangeCodeForToken } from '../../libs/services/connect';
import LoadingDots from '../../components/loading';

export default function OauthExchange() {
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    async function exchange() {
      // Parse the query parameters from the URL
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');

      if (code) {
        // If the code exists, make an API call with it
        try {
          await exchangeCodeForToken(code);
          history('/');
        } catch (e) {
          history('/');
        }
      }
    }

    exchange();
  }, [location]);

  return <LoadingDots />;
}
