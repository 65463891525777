import { EventType } from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { resizeImage } from '../utils/resizeImage.util';
import * as Sentry from '@sentry/react';

export const createEventType = async (
  createEventType: EventType,
  file?: { file: File; uploadName: string },
  existingEventType?: EventType,
): Promise<EventType> => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;
  const userId = session?.tokens?.accessToken?.payload?.sub;
  const remoteFileName = `${userId}/events/${file?.uploadName}`;

  let fileUploadResponseCode;
  if (file?.file) {
    const response = await axios.post(
      `${apiBaseUrl}/file/upload`,
      { file_name: remoteFileName, file_type: file.file.type },
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );
    const upload_url = response.data.upload_url;
    if (upload_url) {
      try {
        const smallerImage = await resizeImage(file.file);
        const uploadRes = await axios.put(upload_url, smallerImage, {
          headers: {
            'Content-Type': file.file.type,
          },
        });
        fileUploadResponseCode = uploadRes.status;
      } catch (e) {
        Sentry.captureException(e);
      } // empty block
    }
  }

  try {
    const requestBody = { ...createEventType };
    if (fileUploadResponseCode === 200) {
      requestBody.image_key = remoteFileName;
    } else {
      requestBody.image_key = existingEventType?.image_key;
    }
    let response;
    if (existingEventType) {
      requestBody.id = existingEventType.id;
      requestBody.user_id = existingEventType.user_id;
      response = await axios.put(
        `${apiBaseUrl}/user/${userId}/event-types/${existingEventType.id}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${cognitoToken}`,
          },
        },
      );
    } else {
      response = await axios.post(`${apiBaseUrl}/user/${userId}/event-types`, requestBody, {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      });
    }

    return response.data;
  } catch (error) {
    throw new Error('Failed to create event type');
  }
};

export const getEventTypes = async (userId?: string): Promise<EventType[]> => {
  const session = await fetchAuthSession();

  const cognitoToken = session?.tokens?.accessToken?.toString();
  const apiBaseUrl = environment.apiBaseUrl;
  const eventTypeUserId = userId || session?.tokens?.accessToken?.payload?.sub;
  try {
    const response = await axios.get(`${apiBaseUrl}/user/${eventTypeUserId}/event-types`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data.items || [];
  } catch (error) {
    throw new Error('Failed to get event types');
  }
};

export const deleteEventType = async (id: string): Promise<EventType | undefined> => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;
  const userId = session?.tokens?.accessToken?.payload?.sub;
  try {
    const response = await axios.delete(`${apiBaseUrl}/user/${userId}/event-types/${id}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete event type');
  }
};

export const getEventType = async (id: string): Promise<EventType | undefined> => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;
  const userId = session?.tokens?.accessToken?.payload?.sub;
  try {
    const response = await axios.get(`${apiBaseUrl}/user/${userId}/event-types/${id}`, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to get event type');
  }
};
