import {
  BookingConversationItem,
  BookingConversationMessageItem,
} from '@book-nestor-monorepo/shared-types';
import { faArrowRight, faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useAgent } from '../../../../../contexts/v2/agentContext';
import {
  AgentMessageScoreContainer,
  AgentMessageSentimentScoreContainer,
} from '../../../Agent/AgentMessageScoreContainer';
import { AgentMessageSummaryCard } from '../../../Agent/AgentMessageSummaryCard';
import { MultiPartSkeleton } from '../../../Skeleton/MultiPartSkeleton';
import { AgentMessageView } from '../AgentMenuDrawerMessagesTabPanel';

export const AgentMessageDetailView: React.FC<{
  agentMessage?: BookingConversationItem | null;
  onNavigate: (view: AgentMessageView) => void;
  onBackNavigate: (view: AgentMessageView) => void;
}> = ({ agentMessage, onNavigate, onBackNavigate }) => {
  const { fetchConversation, conversationMessages, isLoading } = useAgent();
  const [conversation, setConversation] = useState<BookingConversationItem | null>(null);
  const [conversationMessage, setConversationMessage] = useState<
    BookingConversationMessageItem | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (agentMessage) {
        const conversation = await fetchConversation(agentMessage.id as string);
        setConversation(conversation);
        setConversationMessage(
          conversationMessages.find(
            (message) => message.conversation_id === agentMessage?.id,
          ) as BookingConversationMessageItem,
        );
      }
    };
    fetchData();
  }, [fetchConversation, agentMessage]);

  const getContactReference = useCallback(
    (agentMessage: BookingConversationItem | null): string => {
      if (!agentMessage?.contact) return 'Unknown Contact';
      if (agentMessage?.contact?.name && agentMessage?.contact?.last_name)
        return `${agentMessage.contact.name} ${agentMessage.contact.last_name}`;
      if (agentMessage?.contact?.name) return agentMessage.contact.name;
      return 'Unknown Contact';
    },
    [],
  );

  const contactReference = useMemo(
    () => getContactReference(conversation),
    [conversation, getContactReference],
  );

  if (isLoading) {
    return <MultiPartSkeleton />;
  }

  return (
    <>
      <div className="flex flex-col h-full w-full ml-4 pb-[60px]">
        <div className="flex-row w-full items-start justify-start">
          <div className="flex">
            <span
              onClick={() => onNavigate(AgentMessageView.LIST)}
              className="text-white text-[16px] font-avenir font-normal cursor-pointer"
            >
              {'Ni Conversations >'}
            </span>
            <span className="text-white text-[16px] font-avenir font-bold ml-2">
              {contactReference}
            </span>
          </div>
        </div>
      </div>
      {conversation && (
        <AgentMessageSummaryCard
          agentMessage={conversation}
          onNavigate={onNavigate}
          onBackNavigate={onBackNavigate}
          agentConversationMessage={conversationMessage}
        />
      )}
      <div className="flex flex-col w-full">
        {conversation?.summary?.sentiment && (
          <div className="flex-row pt-2 w-full">
            <AgentMessageSentimentScoreContainer
              sentiment={conversation?.summary?.sentiment}
              label="Sentiment"
            />
          </div>
        )}
        {conversation?.summary?.complexity_score && (
          <div className="flex-row pt-2 w-full">
            <AgentMessageScoreContainer
              score={conversation?.summary?.complexity_score * 10}
              label="Complexity Score"
            />
          </div>
        )}
      </div>
      <div className="flex w-full pt-2">
        <div className="flex items-center justify-start">
          <Button
            size="large"
            sx={{
              backgroundColor: '#DADCDC',
              borderRadius: '100%',
              height: '48px',
              width: '48px',
              minWidth: '48px',
              padding: '0',
            }}
          >
            <FontAwesomeIcon icon={faCheckSquare} className="text-black" />
          </Button>
          <Button
            onClick={() => onNavigate(AgentMessageView.CONVERSATION)}
            size="large"
            sx={{
              backgroundColor: '#DADCDC',
              borderRadius: '100%',
              height: '48px',
              width: '48px',
              minWidth: '48px',
              padding: '0',
              marginLeft: '4px',
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} className="text-black transform -rotate-45" />
          </Button>
        </div>
      </div>
    </>
  );
};
