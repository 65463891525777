import { User } from './user';

export type BookingCreatedEmailTemplateInputs = {
  subject: string;
  booking_created_title: string;
  booking_created_formatted_date: string;
  bookee_name: string;
  bookee_email: string;
  attendee_name: string;
  attendee_email: string;
  event_location: string;
  appBaseUrl: string;
  bookingId: string;
  source: EMAIL_DELIVERY_SOURCE;
  template: BOOKING_CREATED_EMAIL_TEMPLATE;
  destinationEmailAddresses: string[];
  startTime: string;
  endTime: string;
  rescheduleLink: string;
  cancelLink: string;
};

export type BookingCanceledEmailTemplateInputs = {
  subject: string;
  booking_canceled_title: string;
  booking_canceled_formatted_date: string;
  bookee_name: string;
  bookee_email: string;
  attendee_name: string;
  attendee_email: string;
  event_location: string;
  appBaseUrl: string;
  bookingId: string;
  source: string;
  template: BOOKING_CANCELED_EMAIL_TEMPLATE;
  destinationEmailAddresses: string[];
  startTime: string;
  endTime: string;
};

export enum BOOKING_CANCELED_EMAIL_TEMPLATE {
  BOOKING_CANCELED = 'BookingCanceled',
}

export enum BOOKING_CREATED_EMAIL_TEMPLATE {
  BOOKING_CREATED = 'BookingCreated',
}

export enum CONTACT_REVIEW_REQUEST_EMAIL_TEMPLATE {
  CONTACT_REVIEW_REQUEST = 'ContactReviewRequested',
}

export enum EMAIL_DELIVERY_SOURCE {
  NO_REPLY = 'no-reply@mynestor.com',
}

export interface ContactReviewRequestEmailTemplateInputs {
  subject: string;
  attendee_name: string;
  attendee_email: string;
  appBaseUrl: string;
  source: EMAIL_DELIVERY_SOURCE;
  template: CONTACT_REVIEW_REQUEST_EMAIL_TEMPLATE;
  destinationEmailAddresses: string[];
  reviewLink: string;
  user: User;
}

export interface BookingLinkEmailTemplateInputs {
  subject: string;
  provider_name: string;
  source: string;
  destinationEmailAddresses: string[];
  booking_link: string;
}
