export interface UserSlug {
  user_id: string;
  slug: string;
  created_at?: string;
  updated_at?: string;
}

export interface ValidateSlug {
  slug: string;
}

export interface UpdateSlug {
  slug: string;
}

export enum ValidateSlugMessageEnum {
  avaliable = 'available',
  unavailable = 'unavailable',
}

export interface ValidateSlugResponse {
  message: ValidateSlugMessageEnum;
}
