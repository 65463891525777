import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface ToggleTypes {
  title: string;
}

const powerSvg = (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 6.5C4.08594 6.5 2.75 5.16406 2.75 3.5C2.75 1.85938 4.08594 0.5 5.75 0.5C7.39062 0.5 8.75 1.85938 8.75 3.5C8.75 5.16406 7.39062 6.5 5.75 6.5ZM4.67188 7.625H6.80469C9.125 7.625 11 9.5 11 11.8203C11 12.1953 10.6719 12.5 10.2969 12.5H1.17969C0.804688 12.5 0.5 12.1953 0.5 11.8203C0.5 9.5 2.35156 7.625 4.67188 7.625Z"
      fill="white"
    />
  </svg>
);

const PowerToggle = ({ title }: ToggleTypes) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={`relative flex flex-row items-center h-10 bg-gray-alt rounded-full p-0.5 ${isActive ? 'justify-end' : 'justify-start'}`}
      onClick={handleToggle}
    >
      {isActive ? (
        <>
          <span className="text-black font-avenir text-xs mr-2 ml-4">{title}</span>
          <button className="flex items-center justify-center z-10 p-2.5 bg-black rounded-full cursor-pointer focus:outline-none">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                fill="#FF0000"
              />
            </svg>
          </button>
        </>
      ) : (
        <>
          <button className="flex items-center justify-center z-10 p-2.5 bg-black rounded-full cursor-pointer focus:outline-none text-center">
            <span className="text-green-card font-avenir text-xs mr-2">{title}</span>
          </button>
          <div className="w-8 h-10 flex items-center justify-center">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 1.25V6.5C6.75 6.92188 6.39844 7.25 6 7.25C5.57812 7.25 5.25 6.92188 5.25 6.5V1.25C5.25 0.851562 5.57812 0.5 6 0.5C6.39844 0.5 6.75 0.851562 6.75 1.25ZM3.35156 3.33594C2.4375 4.08594 1.85156 5.23438 1.85156 6.5C1.85156 8.79688 3.70312 10.625 5.97656 10.625C8.27344 10.625 10.1016 8.79688 10.1016 6.5C10.1016 5.23438 9.53906 4.08594 8.625 3.33594C8.29688 3.07812 8.27344 2.58594 8.53125 2.28125C8.78906 1.95312 9.25781 1.92969 9.58594 2.1875C10.8281 3.21875 11.625 4.76562 11.625 6.5C11.625 9.61719 9.09375 12.125 6 12.125C2.88281 12.125 0.375 9.61719 0.375 6.5C0.375 4.76562 1.14844 3.21875 2.39062 2.1875C2.71875 1.92969 3.1875 1.95312 3.44531 2.28125C3.70312 2.60938 3.67969 3.07812 3.35156 3.33594Z"
                fill="#303030"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default PowerToggle;
