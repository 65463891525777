import { useRef, useState } from 'react';
import TodayCalendar from '../../components/app/todayCalendar';
import SwipeContainer from '../../components/common/SwipeContainer/SwipeContainer';
import { useAppExperience } from '../../contexts/v2/appExperienceContext';
import SideButtons from '../../components/app/sideButtons';
import InsightCards from '../../components/app/insightCards';
import { InsightsMetricType } from '@book-nestor-monorepo/shared-types';

export const HomePageV2 = () => {
  const { bgColor } = useAppExperience();
  const [swipeOpen, setSwipeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(8, 10));
  const selectedDateRef = useRef(new Date().toISOString().slice(8, 10));

  const openInfoModal = (type: InsightsMetricType) => {
    console.log('openInfoModal', type);
  };

  const handleSwipeUp = () => {
    setSwipeOpen(!swipeOpen);
  };

  const handleDateSelect = (date: string) => {
    if (date.length === 1) {
      date = '0' + date;
    }
    setSelectedDate(date);
    selectedDateRef.current = date;
  };

  return (
    <div className={`flex flex-col h-full w-full bg-[${bgColor}] overflow-hidden`}>
      <div className="flex flex-col h-full w-full">
        <div className="flex-shrink-0 w-full h-full">
          <div className="flex flex-row h-auto gap-1 w-full">
            <InsightCards openInfoModal={openInfoModal} />
            {/* <SideButtons /> */}
          </div>
          <div className="w-full h-full overflow-hidden">
            <SwipeContainer transparentBg defaultHeight={500} maxHeight={800} isOpened={swipeOpen}>
              <TodayCalendar
                swipeUp={handleSwipeUp}
                gray-secondary
                selectedDate={selectedDate}
                handleDateSelect={handleDateSelect}
                selectedDateRef={selectedDateRef}
              />
            </SwipeContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
