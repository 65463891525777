import { useLocation } from 'react-router-dom';
import Header from '../../components/app/header';
import Menu from '../../components/menu';
import Modal from '../../components/common/Modal/Modal';
import { useModal } from '../../contexts/modalContext';
import SettingsHeader from '../../components/app/settingsHeader';

interface AppLayoutTypes {
  children: React.ReactNode;
  settings?: boolean;
}

export function AppLayout({ children, settings }: AppLayoutTypes) {
  const location = useLocation();
  const { isOpen } = useModal();

  const isDark =
    location.pathname === '/voice-messages' ||
    location.pathname.includes('/text-conversation') ||
    location.pathname.includes('/personal/');

  return (
    <div className="flex flex-col  h-full md:h-auto md:py-8">
      <div className="w-full flex flex-1 md:flex-none flex-col md:flex-row md:max-w-7xl mx-auto my-auto md:h-[800px] overflow-scroll no-scrollbar relative app-shadow py-0 md:py-4 px-0 md:px-4">
        <div className="flex h-auto">
          <Menu />
        </div>
        <div className={`${isDark ? 'bg-black-back' : ''} flex flex-col w-full h-full`}>
          <div className="flex w-full md:w-full items-stretch justify-center px-2">
            <div className="w-full flex flex-col h-full">
              {settings ? <SettingsHeader /> : <Header />}
            </div>
          </div>
          {children}
          {isOpen && <Modal />}
        </div>
      </div>
    </div>
  );
}
