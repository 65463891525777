'use client';

import React, { RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Booking, Contact } from '@book-nestor-monorepo/shared-types';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '../../contexts/modalContext';
import { durationStringM, formatClientTimeFromDate } from '../../libs/utils/date.util';
import { cleanCalcomEventTitleToJustEventType } from '../../libs/utils/string.util';

type AppointmentProps = {
  appointment: Booking;
  frameRef: React.RefObject<HTMLDivElement> | null;
  handleCheck: (arg0: Booking) => void;
  contact: Contact;
  listRef: RefObject<HTMLDivElement>;
};

let startY = 0;
let startScrollTop = 0;

export default function SelectedAppointment({
  appointment,
  frameRef,
  handleCheck,
  listRef,
  contact,
}: AppointmentProps) {
  const history = useNavigate();
  const { openModal, closeModal } = useModal();

  const [openMenu, setOpenMenu] = useState(false);

  const handleCheckAppointment = () => handleCheck(appointment);

  const scrollAppointmentsListTouchStart: React.TouchEventHandler<HTMLDivElement> = (event) => {
    if (listRef.current) {
      startY = event.touches[0].pageY;
      startScrollTop = listRef.current.scrollTop;
    }
  };

  const scrollAppointmentsList = (
    ev: React.WheelEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    if (listRef.current) {
      if ('deltaY' in ev) {
        listRef.current.scrollBy({
          top: ev.deltaY,
          behavior: 'auto',
        });
      } else if ('touches' in ev) {
        const deltaY = ev.touches[0].pageY - startY;
        listRef.current.scrollTop = startScrollTop - deltaY;
      }
    }
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength - 3) + '...';
    }
  };

  const handleContact = () => {
    history(`/contacts/${appointment.contact.email}`);
  };

  return (
    <div
      onWheel={scrollAppointmentsList}
      onTouchMove={scrollAppointmentsList}
      onTouchStart={scrollAppointmentsListTouchStart}
      className="flex flex-row items-center min-w-full absolute left-0 top-28 px-2"
    >
      <div className="flex flex-row items-center relative w-full">
        <div className="w-full absolute left-0 h-4 z-0" ref={frameRef} />
        <div
          className="flex justify-center relative z-40 cursor-pointer"
          // onClick={() => setOpenMenu(!openMenu)}
        >
          <div className="flex justify-center items-center w-[74px] h-[74px] rounded-full bg-black-gray border-black-dark border-[6px] border-solid z-10">
            <div className="flex flex-row items-center">
              <span className="text-sm font-bold text-white">
                {formatClientTimeFromDate(new Date(appointment?.start_time))}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center relative flex-grow">
          <div className="absolute -left-3 z-0">
            <img src="/assets/Subtract.svg" height={32} width={32} alt="Connector black" />
          </div>
          <div className="flex flex-row items-center justify-between rounded-full border-[6px] border-solid bg-black-gray border-black-dark relative z-10 w-full ml-2 p-0.5">
            {openMenu ? (
              <>
                <div
                  className="flex justify-center items-center h-[66px]  rounded-full bg-black-dark px-6 flex-grow relative z-10 cursor-pointer"
                  // onClick={handleContact}
                >
                  <div className="flex flex-col items-start justify-start w-full">
                    <p className="text-base text-white font-bold leading-5 line-clamp-1">
                      {`${truncateText(
                        `${appointment?.contact.name} ${appointment?.contact.last_name}`,
                        20,
                      )}`}
                    </p>
                    <p className="text-sm text-white leading-4 text-wrap font-avenir">
                      {`${truncateText(
                        cleanCalcomEventTitleToJustEventType(appointment?.title || ''),
                        23,
                      )}`}
                    </p>
                  </div>
                </div>
                {/* <div
                  className="flex items-center justify-center rounded-full h-[62px] relative w-[62px] min-w-[62px] bg-black-dark/45 cursor-pointer"
                  onClick={() =>
                    openModal(<MenuOption appointment={appointment} onCloseMenu={closeModal} />)
                  }
                >
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 9C15.75 9 16 9.25 16 9.5C16 9.78125 15.75 10 15.5 10H4.9375C4.6875 11.1562 3.6875 12 2.5 12C1.09375 12 0 10.9062 0 9.5C0 8.125 1.09375 7 2.5 7C3.6875 7 4.6875 7.875 4.9375 9H15.5ZM1 9.5C1 10.3438 1.65625 11 2.5 11C3.3125 11 4 10.3438 4 9.5C4 8.6875 3.3125 8 2.5 8C1.65625 8 1 8.6875 1 9.5ZM11.0312 2C11.2812 0.875 12.2812 0 13.5 0C14.875 0 16 1.125 16 2.5C16 3.90625 14.875 5 13.5 5C12.2812 5 11.2812 4.15625 11.0312 3H0.5C0.21875 3 0 2.78125 0 2.5C0 2.25 0.21875 2 0.5 2H11.0312ZM13.5 4C14.3125 4 15 3.34375 15 2.5C15 1.6875 14.3125 1 13.5 1C12.6562 1 12 1.6875 12 2.5C12 3.34375 12.6562 4 13.5 4Z"
                      fill="white"
                    />
                  </svg>
                </div> */}
                {/* {contact.phone_number ? (
                  <div
                    className="flex items-center justify-center rounded-full h-[62px] w-[62px] min-w-[62px] bg-black-dark/45 cursor-pointer"
                    onClick={() => history('/text-conversations/')}
                  >
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 6.5C17 10.0938 13.4062 13 9 13C7.8125 13 6.71875 12.8125 5.71875 12.4688C5.375 12.7188 4.75 13.0938 4.03125 13.4062C3.28125 13.75 2.375 14 1.5 14C1.28125 14 1.09375 13.9062 1.03125 13.7188C0.9375 13.5312 1 13.3125 1.125 13.1562C1.15625 13.1562 1.15625 13.125 1.1875 13.0938C1.21875 13.0625 1.28125 13 1.34375 12.9375C1.46875 12.7812 1.625 12.5312 1.8125 12.25C2.125 11.75 2.40625 11.0625 2.46875 10.2812C1.53125 9.21875 1 7.9375 1 6.5C1 2.9375 4.5625 0 9 0C13.4062 0 17 2.9375 17 6.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="flex items-center justify-center rounded-full h-[62px] w-[62px] min-w-[62px] bg-black-dark/45 cursor-pointer opacity-30">
                    <svg
                      width="17"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 6.5C17 10.0938 13.4062 13 9 13C7.8125 13 6.71875 12.8125 5.71875 12.4688C5.375 12.7188 4.75 13.0938 4.03125 13.4062C3.28125 13.75 2.375 14 1.5 14C1.28125 14 1.09375 13.9062 1.03125 13.7188C0.9375 13.5312 1 13.3125 1.125 13.1562C1.15625 13.1562 1.15625 13.125 1.1875 13.0938C1.21875 13.0625 1.28125 13 1.34375 12.9375C1.46875 12.7812 1.625 12.5312 1.8125 12.25C2.125 11.75 2.40625 11.0625 2.46875 10.2812C1.53125 9.21875 1 7.9375 1 6.5C1 2.9375 4.5625 0 9 0C13.4062 0 17 2.9375 17 6.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )} */}
              </>
            ) : (
              <div className="flex justify-between items-center h-[66px] rounded-full bg-black-dark pl-6 pr-1 grow relative z-10">
                <div className="flex flex-row items-center ">
                  <div className="flex flex-col">
                    <p className="text-base text-white font-bold leading-5 text-left">
                      {truncateText(
                        `${appointment?.contact?.name || ''} ${appointment?.contact?.last_name || ''}`,
                        20,
                      )}
                    </p>
                    <p className="text-sm text-white text-left leading-4 text-wrap font-avenir">
                      {`${truncateText(
                        cleanCalcomEventTitleToJustEventType(appointment?.title || ''),
                        23,
                      )}`}
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() =>
                    history(
                      `/booking-control/${appointment?.contact?.id}?bookingId=${appointment.id}`,
                    )
                  }
                >
                  <div className="h-14 w-14 rounded-full bg-[#333333] flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="text-[#E7E7E7] text-[14px] -rotate-45 animate-pulse"
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className="flex justify-center items-center h-[62px] w-[62px] min-w-[62px]  rounded-full mr-0.5 cursor-pointer"
              onClick={handleCheckAppointment}
            >
              <p className="text-sm text-white leading-4 text-wrap font-avenir">
                {durationStringM(appointment)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
