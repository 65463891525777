import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActions, CardContent, IconButton } from '@mui/material';
import React from 'react';
import { useMuiModal } from '../../contexts/muiFlyupModal';

interface NumberedServiceCardProps {
  number?: number;
  title: string;
  description?: string;
  buttonText: string;
  disabled?: boolean;
  onButtonClick: () => void;
  canDismiss?: boolean;
  onDismiss?: () => void;
  confirmDeleteText?: string;
}

const NumberedServiceCard: React.FC<NumberedServiceCardProps> = ({
  number,
  title,
  description,
  buttonText,
  disabled,
  onButtonClick,
  canDismiss,
  onDismiss,
  confirmDeleteText,
}) => {
  const { openMuiModal, closeMuiModal } = useMuiModal();

  const handleConfirmDelete = () => {
    if (confirmDeleteText || onDismiss) {
      openMuiModal(
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Box sx={{ mb: 3 }}>
            {confirmDeleteText || 'Are you sure you want to skip this step?'}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                onDismiss?.();
                closeMuiModal();
              }}
              sx={{
                bgcolor: '#000000',
                '&:hover': { bgcolor: '#333333' },
                borderRadius: '24px',
                textTransform: 'none',
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => closeMuiModal()}
              sx={{
                color: '#000000',
                borderColor: '#000000',
                '&:hover': { borderColor: '#333333', color: '#333333' },
                borderRadius: '24px',
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>,
      );
    }
  };

  return (
    <Box display="flex" alignItems="center" mb={1} sx={{ textAlign: 'left' }}>
      <span className="text-[16px] mr-2 text-black font-avenir font-semibold leading-4">
        {number ? (
          number.toString().padStart(2, '0')
        ) : (
          <FontAwesomeIcon icon={faCheck} color="#4caf50" size="lg" />
        )}
      </span>
      <Card
        sx={{
          flexGrow: 1,
          opacity: disabled ? 0.5 : 1,
          transition: 'opacity 0.3s',
          position: 'relative',
          borderRadius: '24px',
          boxShadow: 'none',
          bgcolor: '#F7F7F7',
          marginBottom: '2px',
        }}
      >
        {canDismiss && (
          <IconButton
            size="small"
            onClick={handleConfirmDelete}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#000000',
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
        <CardContent sx={{ paddingY: 3, paddingX: 2 }}>
          <div className="flex flex-col">
            <span className="text-base font-avenir font-semibold leading-4 mb-4">{title}</span>
            {description && (
              <span className="text-[14px] text-black font-avenir leading-4">{description}</span>
            )}
          </div>
        </CardContent>
        <CardActions>
          <div className="flex justify-end w-full">
            <button
              className={`text-[12px] text-white bg-[#000] h-10 px-4 font-avenir font-normal leading-4 rounded-3xl ${
                disabled ? 'bg-[#4caf50]' : 'bg-[#000]'
              }`}
              onClick={onButtonClick}
              disabled={disabled}
            >
              {buttonText}
            </button>
          </div>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NumberedServiceCard;
