import { faCheck, faChevronLeft, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useDrawer } from '../../../contexts/v2/rightDrawerContext';
import { BookingSummaryCard } from './booking-summary-card';
import { Booking } from '@book-nestor-monorepo/shared-types';

export const ConfirmBooking = ({
  bookingData,
  onConfirm,
  onBack,
  isLoading,
  rescheduleFromBooking,
}: {
  bookingData: any;
  onConfirm: () => Promise<void>;
  onBack: () => void;
  isLoading: boolean;
  rescheduleFromBooking?: Booking;
}) => {
  const { toggleDrawer } = useDrawer();
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Track the window size for Confetti
  const [confettiDimensions, setConfettiDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  // On mount, capture window size
  useEffect(() => {
    setConfettiDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  const handleConfirm = async () => {
    // Clear error in case user tries again
    setErrorMessage(null);
    try {
      await onConfirm();
      setIsSuccess(true);
      setShowConfetti(true);

      // Hide confetti after 5 seconds
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    } catch (err) {
      // Here we can capture the error message or use a fallback
      setErrorMessage('Something went wrong. Please review and try again.');
    }
  };

  // If booking was a success, show success UI
  if (isSuccess) {
    return (
      <>
        {/* Place confetti in a fixed container so it doesn't shift layout */}
        {showConfetti && (
          <div className="fixed inset-0 pointer-events-none z-50">
            <Confetti
              width={confettiDimensions.width}
              height={confettiDimensions.height}
              recycle={false}
              numberOfPieces={500}
              gravity={0.3}
              colors={['#34D399', '#10B981', '#059669', '#047857']}
            />
          </div>
        )}

        <div className="flex flex-col items-center justify-center space-y-4 py-8">
          <div className="flex h-16 w-16 items-center justify-center rounded-full bg-green-500">
            <FontAwesomeIcon icon={faCheck} className="h-10 w-10 text-white" />
          </div>
          <h2 className="text-xl font-medium text-gray-900">
            {rescheduleFromBooking
              ? 'Booking Rescheduled Successfully!'
              : 'Booking Created Successfully!'}
          </h2>
          <button
            onClick={toggleDrawer}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Close
          </button>
        </div>
      </>
    );
  }

  // If there's an error, display error message & "go back" button
  if (errorMessage) {
    return (
      <div className="mt-8 space-y-6">
        <h2 className="text-lg font-medium text-red-600">Error</h2>
        <p className="text-sm text-gray-700">{errorMessage}</p>
        <div className="flex items-center justify-end pt-6">
          <button
            onClick={onBack}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2 h-4 w-4" />
            Go Back &amp; Edit
          </button>
        </div>
      </div>
    );
  }

  // Normal "Confirm" screen
  return (
    <div className="mt-8 space-y-6">
      <h2 className="text-lg font-medium">Confirm Booking Details</h2>
      <BookingSummaryCard bookingData={bookingData} />

      <div className="flex items-center justify-between pt-6">
        <button
          onClick={onBack}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2 h-4 w-4" />
          Back
        </button>

        <button
          onClick={handleConfirm}
          disabled={isLoading}
          className="inline-flex items-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {rescheduleFromBooking ? 'Reschedule Booking' : 'Create Booking'}
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} className="ml-2 h-4 w-4 animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faCheck} className="ml-2 h-4 w-4" />
          )}
        </button>
      </div>
    </div>
  );
};
