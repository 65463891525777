import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './tailwind.css';
import * as Sentry from '@sentry/react';

import App from './app/app';
import AuthProvider from './app/contexts/authContext';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://b2a2d8abb51e19684d51dc4541a6f469@o4507374393360384.ingest.us.sentry.io/4507374394998784',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // Exclude localhost
    // /^(?!.*localhost).*$/,

    /^https:\/\/dev\.mynestor\.com/,
    /^https:\/\/app\.mynestor\.com/,
    // TODO: Do we need to watch the API?
    /^https:\/\/api\.mynestor\.com/,
    /^https:\/\/api\.dev\.mynestor\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
    // Check if the request is from localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return null; // Exclude events from localhost
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>,
);
