import {
  AvailableTimeSlots,
  Booking,
  BookingStatus,
  BookingWebhook,
  PaginatedBookingItem,
  Schedule,
  CreateBookingRequest,
} from '@book-nestor-monorepo/shared-types';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { environment } from '../../environments/environment';

interface GetBookingsParams {
  afterStart?: string;
  beforeEnd?: string;
  take?: number;
  skip?: number;
  sortStart?: string;
}

export const getBookings = async (
  userId: string,
  bookingStatus?: BookingStatus,
  { afterStart, beforeEnd, take = 10, skip = 0, sortStart = 'desc' }: GetBookingsParams = {},
): Promise<PaginatedBookingItem> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const afterStartParam = afterStart ? `&afterStart=${afterStart}` : '';
    const beforeEndParam = beforeEnd ? `&beforeEnd=${beforeEnd}` : '';
    const skipParam = skip ? `&skip=${skip}` : '';
    const statusParam = bookingStatus ? `&bookingStatus=${bookingStatus}` : '';
    const url = `${apiBaseUrl}/user/${userId}/bookings?take=${take}&sortStart=${sortStart}${skipParam}${statusParam}${afterStartParam}${beforeEndParam}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch bookings');
  }
};

export const createBooking = async (
  userId: string,
  bookingData: CreateBookingRequest,
): Promise<Booking> => {
  try {
    const session = await fetchAuthSession();
    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/bookings`;

    const response = await axios.post(url, bookingData, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to create booking');
  }
};

export const getAvailableSlots = async (
  userId: string,
  eventTypeId: number,
  startDate: string,
  endDate: string,
): Promise<AvailableTimeSlots> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/slots/available?eventTypeId=${eventTypeId}&startDate=${startDate}&endDate=${endDate}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch available slots');
  }
};

export const getDefaultSchedule = async (userId: string): Promise<Schedule> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/schedules/default`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Failed to fetch default schedule');
  }
};

export const getBookingById = async (userId: string, bookingId: string): Promise<Booking> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/bookings/${bookingId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch booking by id');
  }
};

export const cancelBooking = async (userId: string, bookingId: string): Promise<Booking> => {
  try {
    const session = await fetchAuthSession();

    const cognitoToken = session.tokens?.accessToken.toString();
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/user/${userId}/bookings/${bookingId}/cancel`;

    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${cognitoToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to cancel booking');
  }
};

// used only for in-app bookings
export const bookingWebhook = async (bookingWebhook: BookingWebhook): Promise<void> => {
  try {
    const apiBaseUrl = environment.apiBaseUrl;
    const url = `${apiBaseUrl}/bookings/webhook`;

    return axios.post(url, bookingWebhook, {});
  } catch (error) {
    throw new Error('Failed to process webhook');
  }
};
