import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';

interface CalendarDay {
  date: Date;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  hasAvailableSlots?: boolean;
}

interface TimeSlot {
  time: string;
}

interface ServerResponse {
  slots: {
    [key: string]: TimeSlot[];
  };
}

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CalendarDaySlot({
  selectedDate,
  onDateSelect,
  fetchAvailableSlots,
}: {
  selectedDate?: Date;
  onDateSelect?: (date: Date) => void;
  fetchAvailableSlots: (startDate: string, endDate: string) => Promise<ServerResponse>;
}) {
  const [currentMonth, setCurrentMonth] = useState(() => {
    // If there's a selected date, initialize currentMonth to that date's month
    if (selectedDate) {
      return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    }
    return new Date();
  });
  const today = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<{ [key: string]: TimeSlot[] }>({});
  const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([]);

  const formatDateForAPI = (date: Date): string => {
    return date.toISOString().slice(0, 23) + 'Z';
  };

  const getFirstDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  const canGoToPreviousMonth = (): boolean => {
    const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
    return (
      previousMonth.getMonth() >= today.getMonth() &&
      previousMonth.getFullYear() >= today.getFullYear()
    );
  };

  const loadAvailableSlots = async (monthDate: Date) => {
    try {
      setIsLoading(true);
      const firstDay = getFirstDayOfMonth(monthDate);
      const firstDayOfNextMonth = getFirstDayOfMonth(
        new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 1),
      );

      firstDay.setUTCHours(5, 0, 0, 0);
      firstDayOfNextMonth.setUTCHours(4, 59, 59, 999);

      const response = await fetchAvailableSlots(
        formatDateForAPI(firstDay),
        formatDateForAPI(firstDayOfNextMonth),
      );

      setAvailableSlots(response.slots);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSlotAvailable = (date: Date): boolean => {
    const dateString = date.toISOString().split('T')[0];
    return !!availableSlots[dateString]?.length;
  };

  const isSameDay = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const getDaysInMonth = (date: Date): CalendarDay[] => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let firstDayOfWeek = firstDayOfMonth.getDay();
    firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    const days: CalendarDay[] = [];

    // Add days from previous month
    const daysInPreviousMonth = new Date(year, month, 0).getDate();
    for (let i = firstDayOfWeek - 1; i >= 0; i--) {
      const date = new Date(year, month - 1, daysInPreviousMonth - i);
      days.push({
        date,
        isCurrentMonth: false,
        isToday: isSameDay(date, today),
        isSelected: selectedDate ? isSameDay(date, selectedDate) : false,
        hasAvailableSlots: isSlotAvailable(date),
      });
    }

    // Add days of current month
    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const date = new Date(year, month, day);
      days.push({
        date,
        isCurrentMonth: true,
        isToday: isSameDay(date, today),
        isSelected: selectedDate ? isSameDay(date, selectedDate) : false,
        hasAvailableSlots: isSlotAvailable(date),
      });
    }

    // Add days from next month
    const remainingDays = 42 - days.length;
    for (let day = 1; day <= remainingDays; day++) {
      const date = new Date(year, month + 1, day);
      days.push({
        date,
        isCurrentMonth: false,
        isToday: isSameDay(date, today),
        isSelected: selectedDate ? isSameDay(date, selectedDate) : false,
        hasAvailableSlots: isSlotAvailable(date),
      });
    }

    return days;
  };

  // Effect to update current month when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      setCurrentMonth(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
    }
  }, [selectedDate]);

  // Effect to load available slots when current month changes
  useEffect(() => {
    loadAvailableSlots(currentMonth);
  }, [currentMonth]);

  // Effect to update calendar days when either currentMonth or availableSlots changes
  useEffect(() => {
    setCalendarDays(getDaysInMonth(currentMonth));
  }, [currentMonth, availableSlots, selectedDate]);

  const handlePreviousMonth = () => {
    if (canGoToPreviousMonth()) {
      setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1));
    }
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const handleDateClick = (date: Date) => {
    if (isSlotAvailable(date)) {
      onDateSelect?.(date);
    }
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className="relative">
      <div className="mt-10 text-center">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            onClick={handlePreviousMonth}
            disabled={!canGoToPreviousMonth()}
            className={classNames(
              '-m-1.5 flex flex-none items-center justify-center p-1.5',
              canGoToPreviousMonth()
                ? 'text-gray-400 hover:text-gray-500'
                : 'text-gray-200 cursor-not-allowed',
            )}
          >
            <span className="sr-only">Previous month</span>
            <FontAwesomeIcon icon={faChevronLeft} className="size-5" aria-hidden="true" />
          </button>
          <div className="flex-auto text-sm font-semibold font-inter">
            {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
          </div>
          <button
            type="button"
            onClick={handleNextMonth}
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <FontAwesomeIcon icon={faChevronRight} className="size-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-xs/6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {calendarDays.map((day, dayIdx) => (
            <button
              key={day.date.toISOString()}
              type="button"
              onClick={() => handleDateClick(day.date)}
              disabled={!day.hasAvailableSlots}
              className={classNames(
                'py-1.5 hover:bg-gray-100 focus:z-10 bg-gray-100',
                (day.isSelected || day.isToday) && 'font-semibold',
                day.isSelected && 'text-white',
                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                day.isToday && !day.isSelected && 'text-black',
                !day.hasAvailableSlots && 'cursor-not-allowed opacity-50',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === calendarDays.length - 7 && 'rounded-bl-lg',
                dayIdx === calendarDays.length - 1 && 'rounded-br-lg',
              )}
            >
              <time
                dateTime={day.date.toISOString()}
                className={classNames(
                  'mx-auto flex size-7 items-center justify-center rounded-full',
                  day.isSelected && 'bg-gray-900',
                  !day.isSelected &&
                    day.hasAvailableSlots &&
                    (day.isToday ? 'bg-green-600 text-white' : 'bg-green-600 text-white'),
                )}
              >
                {day.date.getDate()}
              </time>
            </button>
          ))}
        </div>
      </div>
      {isLoading && (
        <div className="absolute inset-0 bg-gray-50/80 flex items-center justify-center rounded-2xl">
          <FontAwesomeIcon icon={faSpinner} className="text-black animate-spin" />
        </div>
      )}
    </div>
  );
}
