import { Contact, EventType, USER_PERMISSIONS } from '@book-nestor-monorepo/shared-types';
import { BookingResponse } from '@calcom/atoms/dist/packages/features/bookings/types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotificationIcon from '../../../components/app/notificationIcon';
import { StackedCircularProgress } from '../../../components/common/CircularProgress/CircularProgress';
import DesktopNumberedServiceCard from '../../../components/quickStartTasks/desktop-numbered-service-card';
import { MetricsDisplay } from '../../../components/v2/Agent/AgentMetricDisplay';
import { ProfilePicture } from '../../../components/v2/App/ProfilePicture';
import { SearchAutocompleteContacts } from '../../../components/v2/App/SearchAutoCompleteContacts';
import { SearchAutocompleteServices } from '../../../components/v2/App/SearchAutoCompleteServices';
import { ScaledBooker } from '../../../components/v2/Booker/ScaledBooker';
import { ContactDetailCard } from '../../../components/v2/Contact/ContactDetailCard';
import DesktopMenuDrawer from '../../../components/v2/DesktopMenuDrawer';
import { DesktopAppMenu } from '../../../components/v2/Menu/DesktopAppMenu';
import { MultiPartSkeleton } from '../../../components/v2/Skeleton/MultiPartSkeleton';
import { AuthContext } from '../../../contexts/authContext';
import { useMuiModal } from '../../../contexts/muiFlyupModal';
import { FormattedTask, useQuickStart } from '../../../contexts/quickStartContext';
import { useAgent } from '../../../contexts/v2/agentContext';
import { useAppExperience } from '../../../contexts/v2/appExperienceContext';
import { useBookings } from '../../../contexts/v2/bookingsContext';
import { useContacts } from '../../../contexts/v2/contactsContext';
import { useServices } from '../../../contexts/v2/eventTypesContext';
import { useOrganization } from '../../../contexts/v2/organizationContext';
import { useToast } from '../../../contexts/v2/toastContext';
import { bookingWebhook } from '../../../libs/services/bookings';
import { getAbbreviatedMonthAndDay } from '../../../libs/utils/date.util';
import { hasAccess } from '../../../libs/utils/hasAccess';
import { formatPhoneNumber } from '../../../libs/utils/phone.util';
import { LayoutProps } from '../../../models';
import { getLeftMenuItems } from './menu-items';
import { AgentAiBorder } from '../../../components/v2/Agent/AgentAiBorder';

export const DesktopLayout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [leftOpen, setLeftOpen] = useState(false);
  const [rightOpen, setRightOpen] = useState(false);
  const {
    allTasksCompleted,
    completedTasksCount,
    totalTasksCount,
    totalTaskCountForPlan,
    remainingFormattedTasks,
    subscriptionTierFormattedTasks,
    completedFormattedTasks,
    selectedTask,
  } = useQuickStart();
  const { token, bookingLink, servicePhone } = useContext(AuthContext);
  const { openMuiModal, closeMuiModal } = useMuiModal();
  const { bgColor, isLargeScreen } = useAppExperience();
  const navigate = useNavigate();
  const [bookerKey, setBookerKey] = useState(0);
  const { user } = useContext(AuthContext);
  const dateParts = getAbbreviatedMonthAndDay(new Date());
  const { contacts, isLoading: isLoadingContacts, fetchContacts } = useContacts();
  const { services, isLoading: isLoadingServices } = useServices();
  const { fetchUpcomingBookings } = useBookings();
  const { organization } = useOrganization();
  const { showToast } = useToast();
  const [selectedService, setSelectedService] = useState<EventType | null>();
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const [latestCreatedContactId, setLatestCreatedContactId] = useState<string | null>(null);
  const latestContactRef = useRef<string | null>(null);
  const [selectedFormattedTask, setSelectedFormattedTask] = useState<FormattedTask | undefined>(
    remainingFormattedTasks[0],
  );
  const [searchParams] = useSearchParams();
  const [rescheduleUid, setRescheduleUid] = useState<string | undefined>(undefined);

  useEffect(() => {
    const rescheduleUid = searchParams.get('rescheduleUid') ?? undefined;
    setRescheduleUid(rescheduleUid);
  }, [searchParams]);

  const handleLeftDrawerToggle = () => {
    if (rightOpen) {
      setRightOpen(false);
    }
    setLeftOpen(!leftOpen);
  };
  const { agentMetrics, isLoading: isLoadingAgentMetrics } = useAgent();

  const updateUrlWithoutNavigation = (date: string, url: string) => {
    const newUrl = `${url}?date=${date}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const userServicePhone = hasAccess(token, USER_PERMISSIONS.CAN_USE_VOICE)
    ? servicePhone?.phone_number
    : null;

  useEffect(() => {
    if (selectedTask) {
      setSelectedFormattedTask(remainingFormattedTasks.find((task) => task.name === selectedTask));
    } else {
      setSelectedFormattedTask(remainingFormattedTasks[0]);
    }
  }, [selectedTask]);

  useEffect(() => {
    setSelectedFormattedTask(remainingFormattedTasks[0]);
  }, [remainingFormattedTasks]);

  const handleBookingSuccess = async (data: BookingResponse) => {
    showToast('Booking created successfully!', 'success');
    setSelectedContact(null);
    setSelectedService(services[0]);
    const currentUrl = new URL(window.location.href);
    const dateParam = currentUrl.searchParams.get('date');
    if (dateParam) {
      // Update the URL without navigating
      updateUrlWithoutNavigation(dateParam, currentUrl.pathname);
    }
    if (user?.user_slug?.slug && data?.uid) {
      window.scrollTo(0, 0);
    }
    await bookingWebhook({
      booking_id: data?.uid as string,
      user_id: user?.id as string,
      rescheduleUid: null,
      is_cancelled: false,
    });
    await fetchUpcomingBookings(true);
  };

  function scaledBookerOrSplash() {
    if (services.length > 0 && selectedService) {
      return (
        <ScaledBooker
          key={bookerKey}
          rescheduleUid={rescheduleUid}
          scale={isLargeScreen ? 0.9 : 0.8} // Adjust this value as needed
          eventTypeSlug={selectedService?.slug as string}
          username={user?.connection_data?.cal_provider_user_name as string}
          contact={selectedContact as Contact}
          onSuccess={handleBookingSuccess}
        />
      );
    } else {
      return (
        <div className="flex-grow flex items-center justify-center p-4 h-full">
          <div className="flex flex-col items-center justify-center">
            <p className="font-avenir font-bold text-[43px] leading-[50px] ">
              Create your first service to start booking!
            </p>
          </div>
        </div>
      );
    }
  }

  const handleRightDrawerToggle = () => {
    if (!hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT)) {
      navigate('/subscription');
      return;
    }

    if (leftOpen) {
      setLeftOpen(false);
    }
    setRightOpen(!rightOpen);
  };

  useEffect(() => {
    if (services.length > 0) {
      setSelectedService(services[0]);
    }
  }, [services]);

  useEffect(() => {
    setBookerKey((prevKey) => prevKey + 1);
  }, [selectedContact, selectedService]);

  const openContactModal = () => {
    openMuiModal(
      <ContactDetailCard
        onSuccess={async (contactId?: string) => {
          if (contactId) {
            latestContactRef.current = contactId; // Set the ref before fetching
            setLatestCreatedContactId(contactId);
          }
          await fetchContacts(true);
          closeMuiModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const handleNewContact = async () => {
      if (contacts.length > 0 && latestCreatedContactId) {
        const newContact = contacts.find((contact) => contact.id === latestCreatedContactId);
        if (newContact && latestContactRef.current === latestCreatedContactId) {
          setSelectedContact(newContact);
          // Clear refs after successful selection
          latestContactRef.current = null;
          setLatestCreatedContactId(null);
        }
      }
    };

    handleNewContact();
  }, [contacts, latestCreatedContactId]);

  const renderNotificationIcon = () => {
    return (
      <div className="flex items-center justify-center p-4">
        {<NotificationIcon messages={[]} />}
      </div>
    );
  };

  const onClickNavigateAgent = () => {
    if (hasAccess(token, USER_PERMISSIONS.CAN_USE_AI_ASSISTANT)) {
      navigate('/agent');
    } else {
      navigate('/subscription');
    }
  };

  const renderAgentPfPIcon = () => {
    return (
      <div
        className="flex items-center justify-center py-4 cursor-pointer "
        onClick={onClickNavigateAgent}
      >
        {servicePhone?.agent?.pfp_url ? (
          <AgentAiBorder>
            <img src={servicePhone.agent.pfp_url} height={48} width={48} alt="Connector black" />
          </AgentAiBorder>
        ) : (
          <img src="/assets/nestor-ai.svg" height={48} width={48} alt="Connector black" />
        )}
      </div>
    );
  };

  return (
    <div className="bg-[#DEDEDE] flex justify-center items-center h-screen w-screen p-2">
      <div className="w-full h-full flex flex-col overflow-hidden max-w-screen-2xl max-h-[1036px] portrait:max-h-screen">
        {/* Main content area */}
        <div className="flex-1 flex overflow-hidden h-full">
          {/* Left drawer toggle area */}
          <div className="w-[58px] relative mr-2">
            <DesktopMenuDrawer
              open={leftOpen}
              onToggle={handleLeftDrawerToggle}
              anchor="left"
              menuItems={getLeftMenuItems(navigate, bookingLink)}
              behindOverlay={rightOpen}
            />
          </div>

          {/* Content */}
          <main className="flex-1 overflow-hidden flex justify-center w-full">
            <div className="w-full max-w-screen-2xl flex justify-center">
              {/* Left container (2/3 width) with a minimum width of 430px */}
              <div className="w-2/3 bg-transparent  mr-2 rounded-2xl  h-full flex flex-col ">
                {/* Container 1 */}
                <div className="bg-[#F5F5F7] rounded-xl p-4 shadow-sm mb-2">
                  <div className="flex w-full h-auto">
                    <div className="w-2/3 flex flex-col">
                      <div className="flex items-start justify-start p-4 pt-0 mt-4">
                        <ProfilePicture picture={user?.picture} firstName={user?.first_name} />
                        <div className="flex flex-col items-start pl-4 pt-2">
                          <div className="mb-2 flex-col items-start justify-start w-full">
                            <p className="text-[24px] font-avenir text-left leading-6">
                              {organization?.name || user?.first_name}
                            </p>

                            {userServicePhone && (
                              <p className="text-[16px] font-avenir text-left leading-6">
                                {userServicePhone ? ` ${formatPhoneNumber(userServicePhone)}` : ''}
                              </p>
                            )}
                            {organization?.name && (
                              <p className="text-[16px] font-avenir text-left leading-6">
                                {user?.first_name}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <MetricsDisplay
                        isLargeScreen={isLargeScreen}
                        isLoadingAgentMetrics={isLoadingAgentMetrics}
                        agentMetrics={agentMetrics}
                      />
                    </div>

                    {/* Right Column */}
                    <div className="w-1/3 flex flex-col">
                      <div className="flex flex-grow items-start justify-end px-4">
                        {renderAgentPfPIcon()}
                        {renderNotificationIcon()}
                        <div className="flex flex-col">
                          <p className="text-[16px] font-inter">{dateParts.month}</p>
                          <p className="text-[32px] font-avenir font-bold">{dateParts.day}</p>
                        </div>
                      </div>
                      <div className="flex flex-grow items-end justify-end p-4 h-full">
                        <div
                          className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer"
                          onClick={() => navigate('/bookings')}
                        >
                          <p className="text-black">Bookings</p>
                        </div>
                        <div
                          className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer ml-1"
                          onClick={() => navigate('/contacts')}
                        >
                          <p className="text-black">Contacts</p>
                        </div>
                        <div
                          className="h-[19px] bg-[#DEDEDE] rounded-full text-[10px] font-avenir px-2 flex items-center justify-center cursor-pointer ml-1"
                          onClick={() => navigate('/payments')}
                        >
                          <p className="text-black">Payments</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {allTasksCompleted ? (
                  <>
                    {/* Row for Container 2 and 3 */}
                    <div className="flex space-x-4 mb-2 flex-grow min-h-[140px] max-h-[140px]">
                      {/* Container 2 */}
                      <div className="bg-[#E5E8E8] rounded-xl shadow-sm flex-1 p-4">
                        <div className="flex w-full items-start justify-between">
                          <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                            <p className="text-white text-[10px] font-avenir">Clients</p>
                          </div>
                          <div
                            className="flex items-center justify-center cursor-pointer"
                            onClick={openContactModal}
                          >
                            <div className="w-[20px] h-[20px]  flex items-center justify-center">
                              <FontAwesomeIcon icon={faPlus} className="text-black text-[16px]" />
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full  items-start justify-start pt-4">
                          {isLoadingContacts ? (
                            <Skeleton
                              variant="rounded"
                              width={'100%'}
                              height={30}
                              sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                            />
                          ) : (
                            <SearchAutocompleteContacts
                              contacts={contacts}
                              label="Book a Client"
                              onSelectContact={(contact: Contact | null) => {
                                setSelectedContact(contact);
                              }}
                              selectedContact={selectedContact}
                            />
                          )}
                        </div>
                      </div>

                      {/* Container 3 */}
                      <div className="bg-[#E5E8E8] rounded-xl shadow-sm flex-1 p-4">
                        <div className="flex w-full items-start justify-start">
                          <div className="h-[19px] w-[48px] rounded-full bg-[#909090] flex items-center justify-center">
                            <p className="text-white text-[10px] font-avenir">Services</p>
                          </div>
                        </div>
                        <div className="flex w-full  items-start justify-start pt-4">
                          {isLoadingServices ? (
                            <Skeleton
                              variant="rounded"
                              width={'100%'}
                              height={30}
                              sx={{ borderRadius: '10px', bgcolor: '#DADCDC', marginTop: '22px' }}
                            />
                          ) : (
                            <SearchAutocompleteServices
                              services={services}
                              label="Book a Service"
                              onSelectService={(service: EventType | null) => {
                                setSelectedService(service);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Container 4 */}
                    <div
                      id="in-app-booker"
                      className="bg-white rounded-xl shadow-sm flex-grow  flex w-full relative overflow-hidden"
                    >
                      <div className="absolute inset-0 overflow-auto hide-scrollbar">
                        {isLoadingServices || isLoadingContacts ? (
                          <div className="p-4">
                            <MultiPartSkeleton color="#DADCDC" />
                          </div>
                        ) : (
                          scaledBookerOrSplash()
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex w-full h-full gap-2">
                    <div className="flex-1 bg-[#E5E8E8] p-4 rounded-xl">
                      {/* Content for the first half */}
                      <div className="flex justify-between">
                        <div className="flex-col items-start gap-4 h-auto">
                          <div className="flex h-[19px] w-[74px]  bg-[#909090] rounded-full text-[10px] font-avenir px-2 items-center justify-center cursor-pointer">
                            <p className="text-white">Onboarding</p>
                          </div>
                          <div className="flex items-start justify-start">
                            <span className="font-inter text-[24px] pt-2">Let's get you setup</span>
                          </div>
                        </div>
                        <div className="flex-col items-end">
                          <StackedCircularProgress
                            completedValue={completedTasksCount}
                            completedTotal={totalTaskCountForPlan}
                            totalValue={totalTasksCount}
                            totalTotal={totalTaskCountForPlan}
                          />
                        </div>
                      </div>
                      <div className="flex-col items-start w-full h-full pt-12 pb-96 overflow-y-auto hide-scrollbar">
                        {remainingFormattedTasks.map((task, index) => (
                          <DesktopNumberedServiceCard
                            key={task.number || index}
                            title={task.title}
                            buttonText={task.buttonText}
                            onSelectTask={task.buttonOnClick}
                            number={task.number}
                            canDismiss={task.canBeSkipped}
                            onDismiss={task.onDismiss}
                            confirmDeleteText={task.confirmDeleteText}
                          />
                        ))}
                        {subscriptionTierFormattedTasks.map((task, index) => (
                          <div className="flex flex-row" key={task.number || index}>
                            <div className="flex flex-row justify-between w-full bg-black rounded-3xl items-center text-center mb-2 pl-4 pr-2 py-2">
                              <div className="flex flex-row justify-start items-start text-left">
                                <span className="text-[16px] font-avenir font-semibold leading-4  text-white">
                                  {task.title}
                                </span>
                              </div>
                              <button
                                className="text-[12px] text-black bg-[#ffffff] h-10 px-4 font-avenir font-normal leading-4 rounded-full"
                                onClick={() => {
                                  navigate('/subscription');
                                }}
                              >
                                {'Upgrade'}
                              </button>
                            </div>
                          </div>
                        ))}
                        {completedFormattedTasks.map((task, index) => (
                          <DesktopNumberedServiceCard
                            key={task.number || index}
                            title={task.title}
                            buttonText={task.buttonText}
                            onButtonClick={task.buttonOnClick}
                            number={task.number}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="flex-1 bg-[#E5E8E8] px-5 pt-14 rounded-xl items-start justify-start">
                      {/* Content for the second half */}
                      <h2 className="text-[24px] font-inter text-left  pb-10">Information</h2>
                      <h2 className="text-[14px] font-avenir font-semibold text-left  pb-2 uppercase">
                        {selectedFormattedTask?.desktopHeader || selectedFormattedTask?.title}
                      </h2>
                      {selectedFormattedTask?.desktopParagraphs?.map((paragraph, index) => (
                        <p
                          key={index}
                          className="text-[14px] font-avenir  text-left  pb-4 leading-4"
                        >
                          {paragraph}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Right container (1/3 width) with a minimum width of 400px */}
              <div
                className={`w-1/3 bg-[${bgColor}] px-2 pt-2 rounded-2xl  overflow-y-scroll hide-scrollbar `}
              >
                <DesktopAppMenu />
                {children}
              </div>
            </div>
          </main>

          {/* Right drawer toggle area */}
          {/* <AgentMenuDrawer
            open={rightOpen}
            onToggle={handleRightDrawerToggle}
            anchor="right"
            behindOverlay={leftOpen}
          /> */}
        </div>
      </div>
    </div>
  );
};
