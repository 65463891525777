export function toPascalCase(string: string): string {
  if (!string) {
    return '';
  }
  // Split the string into words
  const words = string.split(' ');

  // Convert each word to Pascal case
  const pascalWords = words.map((word) => {
    if (word.length === 0) {
      return '';
    }

    const firstChar = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);

    return firstChar + restOfWord;
  });

  // Join the Pascal case words together with spaces
  const pascalString = pascalWords.join(' ');

  return pascalString;
}

export const truncateText = (text: string, maxLength: number): string => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength - 3) + '...';
  }
};

export const cleanCalcomEventTitleToJustEventType = (title: string): string => {
  if (!title) {
    return '';
  }
  const index = title.indexOf(' between');
  return index !== -1 ? title.substring(0, index) : title;
};
