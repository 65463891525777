import { InsightsMetricType } from '@book-nestor-monorepo/shared-types';
import MobileModal from '../common/MobileModal/MobileModal';
import Select from '../common/Select/Select';

interface EditReviewType {
  open: boolean;
  onClose: () => void;
  type: string;
}

const data: Record<string, any> = {
  site_visit: {
    name: 'Booking Page Visits',
    type: 'Month to Date',
    title:
      'This metric counts how many clients click on your unique booking page, revealing the impact of your marketing efforts.',
    description:
      'This metric counts how many clients click on your unique booking page, providing insights into the effectiveness of your marketing strategies and user engagement. By understanding these patterns, you can optimize your marketing efforts and improve the user interface, potentially increasing conversion rates and enhancing the quality of customer interactions. Insights from this metric are crucial for tailoring the user experience and improving access to your services.',
  },
  booking_count: {
    name: 'Total Bookings',
    type: 'Month to Date',
    title:
      'This metric tracks how many people actually booked with you, indicating the success of your conversion strategies.',
    description:
      'This metric measures how many people have made bookings with you, providing insights into the effectiveness of your conversion strategies and overall service appeal. By understanding these booking patterns, you can refine your approach to promotions and client interactions, potentially increasing overall bookings and enhancing customer satisfaction. Insights from this metric are crucial for optimizing your service offerings and boosting your business performance.',
  },
  conversion_rate: {
    name: 'Conversion Rate',
    type: 'Month to Date',
    title:
      'This metric calculates your conversion rate, showing how many visitors to your booking page actually make a booking.',
    description:
      'This metric evaluates your conversion rate by comparing the number of visitors to your booking page with the number of actual bookings. This insight helps you understand the effectiveness of your booking process and promotional tactics. By analyzing these conversion patterns, you can identify areas for improvement in your user interface and marketing strategies, potentially increasing bookings and enhancing user experience. Insights from this metric are essential for refining your business practices and improving overall client engagement.',
  },
  total_revenue: {
    name: 'Total Revenue',
    type: 'Month to Date',
    title: 'This metric estimates potential revenue from bookings through your booking link',
    description:
      'This metric calculates the expected revenue from all bookings made through your booking link, providing insights into the economic impact of your booking process. It helps you understand not only the potential earnings but also the losses due to no-shows, cancellations, or uncollected payments. By tracking these discrepancies, you can identify trends in client behavior that may be affecting your financial performance, such as frequent cancellations or payment issues. This analysis is crucial for refining your booking strategies, improving client follow-through, and enhancing overall revenue collection.',
  },
};

const InsightInfoModal = ({ type, open, onClose }: EditReviewType) => {
  const item = type ? data[type] : null;

  return (
    <MobileModal
      containerClassName="h-[84vh]"
      className="px-2 pt-2 font-avenir"
      onClose={onClose}
      open={open}
    >
      <Select
        className="!bg-[#FFFFFF1A] !text-white !px-6 !h-[48px]"
        defaultValue="MTD"
        items={[]}
        value=""
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
      />
      <div className="text-white pb-5 max-w-[376px]">
        <h6 className="font-semibold mt-[47px] mb-[57px]">
          {item?.name || ''} <span className="block italic font-normal">{item?.type || ''}</span>
        </h6>
        <h3 className="text-2xl mb-[87px]">{item?.title || ''}</h3>
        <p>{item?.description || ''}</p>
      </div>
    </MobileModal>
  );
};

export default InsightInfoModal;
