import axios from 'axios';
import { environment } from '../../environments/environment';
import { fetchAuthSession } from 'aws-amplify/auth';
import { SearchResponse } from '@book-nestor-monorepo/shared-types';

export const searchResources = async (searchTerm?: string): Promise<SearchResponse> => {
  const session = await fetchAuthSession();

  const cognitoToken = session.tokens?.accessToken.toString();
  const apiBaseUrl = environment.apiBaseUrl;
  const url = `${apiBaseUrl}/search`;

  const response = await axios.post(
    url,
    { search_string: searchTerm },
    {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    },
  );

  return response.data;
};
